import PropTypes from 'prop-types';

export const heightVariants = {
    1: 'border-t-1',
    2: 'border-t-2',
    4: 'border-t-4',
    8: 'border-t-8',
};

export const colorVariants = {
    'off-white': 'border-off-white',
    'grey-light': 'border-grey-light',
    'brand-secondary': 'border-lime if:border-strawberry',
    oat: 'border-oat',
};

export function Divider({ color, height, mx, my, width }) {
    const borderHeightClass = heightVariants[height];
    const colorClass = colorVariants[color];

    return <hr className={`${my} ${mx} ${borderHeightClass} ${colorClass} ${width}`} />;
}

Divider.propTypes = {
    color: PropTypes.oneOf(Object.keys(colorVariants)),
    height: PropTypes.oneOf(Object.keys(heightVariants).map((key) => parseInt(key, 10))),
    mx: PropTypes.string,
    my: PropTypes.string,
    width: PropTypes.string,
};

Divider.defaultProps = {
    color: 'off-white',
    height: 2,
    mx: 'mx-0',
    my: 'my-20',
    width: 'w-full',
};
