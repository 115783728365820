import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { IconButton, InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';

import Button from 'honeydew/Button';
import TextField from 'honeydew/TextField';

function LoginForm({ email, loading, loginSubmit, password, setEmail, setPassword, setShowPassword, showPassword }) {
    return (
        <form onSubmit={loginSubmit}>
            <div className="mb-20">
                <TextField
                    id="email-field"
                    name="email"
                    label="Email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </div>

            <div className="mb-20">
                <TextField
                    id="password-field"
                    name="password"
                    label="Password"
                    placeholder="Password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Toggle password visibility"
                                    disableRipple
                                    onClick={() => setShowPassword(!showPassword)}
                                    onMouseDown={(e) => e.preventDefault()}
                                >
                                    {showPassword ? (
                                        <EyeSlashIcon className="h-20 w-20" />
                                    ) : (
                                        <EyeIcon className="h-20 w-20" />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>

            <a href="/login/forgot-password" className="link text-body-sm">
                Forgot password?
            </a>

            <div className="mb-25 mt-10">
                <Button size="md" type="submit" disabled={loading} data-optimizely-id="login-cta">
                    Sign In
                </Button>
            </div>
        </form>
    );
}

LoginForm.propTypes = {
    email: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    loginSubmit: PropTypes.func.isRequired,
    password: PropTypes.string.isRequired,
    setEmail: PropTypes.func.isRequired,
    setPassword: PropTypes.func.isRequired,
    setShowPassword: PropTypes.func.isRequired,
    showPassword: PropTypes.bool.isRequired,
};

export default LoginForm;
