/**
 * Returns the original price for a product.
 * @param {object} product - The product to get loyalty points for.
 * @param {boolean} usePlusMemberPrice - Should the plus member price be used?
 * @example
 * // returns 5.98
 * const productBadgeData = getProductLoyaltyPoints({
 *     ...product,
 *     msrp: 2.99,
 *     portion: 2,
 * });
 * @returns {string} The original price for a product.
 */

const getProductOriginalPrice = (product, usePlusMemberPrice = false) => {
    if (product.bundleID) {
        return product.msrpPrice;
    }

    if (usePlusMemberPrice) {
        return product.msrp ? product.msrp * product.portion : product.nonPlusMemberPrice;
    }

    return product.msrp * product.portion;
};

/**
 * Calculates the percent off the original price.
 * @param {object} product - The product to get the percent off for.
 * @param {boolean} usePlusMemberPrice - Should the plus member price be used?
 * @example
 * // returns 18
 * const percentOffOriginalPrice = getPercentOffOriginalPrice({
 *     ...product,
 *     msrp: 7.99,
 *     portion: 1
 *     price: 6.59,
 * });
 * @returns {number} The percent off original price.
 */
const getPercentOffOriginalPrice = (product, usePlusMemberPrice = false) => {
    let actualPrice = product.price;

    if (usePlusMemberPrice) {
        actualPrice = product.plusMemberPrice ?? product.price;
    }

    const originalPrice = getProductOriginalPrice(product);

    if (originalPrice > actualPrice) {
        return Math.round(100 - (actualPrice / originalPrice) * 100);
    }

    return 0;
};

/**
 * Determines if the original price for a product should be shown, derived by
 * whether or not its percent off is greater than the provided threshold.
 * @param {object} product - The product to show the original price for.
 * @param {number} threshold - The minimum [exclusive] percent off threshold.
 * @param {boolean} usePlusMemberPrice - Should the plus member price be used?
 * @example
 * // returns true
 * const showOriginalPrice = getShowOriginalPrice({
 *     ...product,
 *     msrp: 7.99,
 *     portion: 1
 *     price: 3.99,
 * });
 * @returns {boolean} Boolean describing whether or not the original price should be be shown.
 */
const getShowOriginalPrice = (product, threshold = 0, usePlusMemberPrice = false) =>
    getPercentOffOriginalPrice(product, usePlusMemberPrice) > threshold;

/**
 * Returns the loyalty points for a product.
 * @param {object} product - The product to get loyalty points for.
 * @param {string} subscriptionVariant - The subscription variant.
 * @param {boolean} usePlusMemberPrice - Should the plus member price be used?
 * @example
 * // returns 100
 * const productLoyaltyPoints = getProductLoyaltyPoints(
 *     {
 *         ...product,
 *         potentialLoyaltyPoints: 50,
 *         potentialLoyaltyPointsAutoship: 100,
 *     },
 *     'autoship'
 * );
 * @returns {number} The loyalty points for a product.
 */
const getProductLoyaltyPoints = (product, subscriptionVariant, usePlusMemberPrice = false) => {
    if (usePlusMemberPrice) {
        return subscriptionVariant === 'autoship'
            ? product.plusMemberPotentialLoyaltyPointsAutoship
            : product.plusMemberPotentialLoyaltyPoints;
    }

    return subscriptionVariant === 'autoship' ? product.potentialLoyaltyPointsAutoship : product.potentialLoyaltyPoints;
};

/**
 * Returns a list of photo src strings for a product.
 * @param {object} product - The product to get photo src strings for.
 * @example
 * // returns ["src-1", "src-2", "src-3"]
 * const productPhotos = getProductPhotos({
 *     ...product,
 *     photoLarge: "src-1",
 *     photoTwoLarge: "src-2",
 *     photoThreeLarge: "src-3",
 *     photoFourLarge: "",
 * });
 * @returns {string[]} List of photo src strings.
 */
const getProductPhotos = (product) =>
    [
        product.photoLarge,
        product.photoTwoLarge,
        product.photoThreeLarge,
        product.photoFourLarge,
        product.photoFiveLarge,
    ].filter((photo) => photo && photo !== '');

/**
 * getDietaryPreferencesIconName - returns the icon component name for a given dietary preference
 *
 * @param {string} aisleName – the name of the preferences icon
 * @example
 * const aisleIconName = getDietaryPreferencesIconName('Organic');
 * @returns {string}
 */
const getDietaryPreferencesIconName = (preferenceName) => {
    switch (preferenceName) {
        case 'Organic':
            return 'CO2ESavedIcon';
        case 'Non-GMO':
            return 'NonGMOIcon';
        case 'Gluten-Free':
            return 'GlutenFreeIcon';
        case 'Kosher':
            return 'CertifiedKosherIcon';
        case 'Vegan':
            return 'VeganIcon';
        case 'Vegetarian':
            return 'VegetarianIcon';
        case 'Plant-Based':
            return 'PlantBasedIcon';
        case 'Dairy-Free':
            return 'DairyFreeIcon';
        case 'Major Allergen Free':
            return 'MajorAllergenFreeIcon';
        case 'Keto-Friendly':
            return 'KetoIcon';
        case 'Paleo':
            return 'PaleoIcon';
        case 'Whole30-Friendly':
            return 'Whole30Icon';
        default:
            return 'CO2ESavedIcon';
    }
};

export {
    getDietaryPreferencesIconName,
    getPercentOffOriginalPrice,
    getProductLoyaltyPoints,
    getProductOriginalPrice,
    getProductPhotos,
    getShowOriginalPrice,
};
