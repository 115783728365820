import PropTypes from 'prop-types';

import IFSpiceUpYourRoutineActive from './IFSpiceUpYourRoutineActive';
import IFSpiceUpYourRoutineInactive from './IFSpiceUpYourRoutineInactive';

function IFSpiceUpYourRoutine({ className, isActive, width }) {
    return isActive ? (
        <IFSpiceUpYourRoutineActive className={className} width={width} />
    ) : (
        <IFSpiceUpYourRoutineInactive className={className} width={width} />
    );
}

export default IFSpiceUpYourRoutine;

IFSpiceUpYourRoutine.propTypes = {
    className: PropTypes.string,
    isActive: PropTypes.bool,
    width: PropTypes.number,
};

IFSpiceUpYourRoutine.defaultProps = {
    className: '',
    isActive: true,
    width: 24,
};
