import { useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import useAccountQuery from 'api/queries/useAccountQuery';
import useSelectionsQuery from 'api/queries/useSelectionsQuery';
import { CART_SAVINGS_BANNER_DISMISSED } from 'constants/plusMembership';
import { JoinModal } from 'features/paid-membership/JoinModal';
import Button from 'honeydew/Button';
import { useAccountPlusMembershipHook } from 'hooks/useAccountPlusMembershipHook';
import { trackEvent } from 'utils/analyticsUtils';
import { getCartMemberSavings, getGroceryMinMet } from 'utils/priceUtils';

function PlusMembershipProspectBanner({ hide, intersectionEntry, intersectionRef, sticky }) {
    const [modalOpen, setModalOpen] = useState(false);
    const [bannerDismissed, setBannerDismissed] = useState(localStorage.getItem(CART_SAVINGS_BANNER_DISMISSED));
    const [belowBanner, setBelowBanner] = useState(false);

    const { data: account = {} } = useAccountQuery();
    const { data: selections = {} } = useSelectionsQuery();
    const {
        availableCredits,
        availableTrialMembership,
        averageMembershipSavings,
        averageMembershipSavingsPeriod,
        plusMembershipBranding,
    } = useAccountPlusMembershipHook();

    const groceryMinMet = getGroceryMinMet({ account, selections });
    const cartMemberSavings = getCartMemberSavings({ account, selections });

    useEffect(() => {
        setBelowBanner(intersectionEntry?.boundingClientRect?.top < 0);
    }, [intersectionEntry]);

    if (!groceryMinMet || cartMemberSavings <= 0 || (sticky && (bannerDismissed || belowBanner))) {
        return false;
    }

    function handleClick(e) {
        if (e.type !== 'keydown' || e.code === 'Enter' || e.key === 'Enter' || e.keyCode === 13) {
            setModalOpen(true);
        }
    }

    function dismissBanner() {
        setBannerDismissed(true);
        localStorage.setItem(CART_SAVINGS_BANNER_DISMISSED, true);
        trackEvent('plus membership prospect banner dismissed', {
            averageMembershipSavingsPeriod,
        });
    }

    const bannerContent = (
        <div
            className="flex items-center gap-10 bg-plus-membership-gradient p-10"
            onClick={handleClick}
            onKeyDown={handleClick}
            role="button"
            tabIndex={0}
        >
            <p className="text-body-sm !leading-[20px]">
                <span className="text-body-sm block font-grotesk-bold">
                    Save an avg. of ${averageMembershipSavings} a {averageMembershipSavingsPeriod}
                </span>
                <span className="text-body-xs">when shopping weekly with {plusMembershipBranding}</span>
                {availableCredits?.credits > 0 && (
                    <span className="text-body-xs block" data-testid="credits-body-copy">
                        Plus, <span className="font-grotesk-bold text-cranberry">get ${availableCredits.credits}</span>{' '}
                        when you join.
                    </span>
                )}
            </p>
            <div className="ml-auto shrink-0">
                <Button size="sm" rounded="full" variant="plusMembership" as="span">
                    {availableTrialMembership ? 'Try it free' : 'Join'}
                </Button>
            </div>
        </div>
    );

    if (sticky) {
        return (
            <div className={clsx('z-10', hide ? 'overflow-hidden' : 'sticky bottom-0')}>
                <div className={clsx('transition', hide ? 'translate-y-full' : 'translate-y-0')}>
                    <button
                        type="button"
                        onClick={dismissBanner}
                        className="absolute -top-15 left-10 rounded-full bg-off-white"
                        aria-label="Close banner"
                    >
                        <XMarkIcon className="h-25 w-25 text-black" />
                    </button>
                    {bannerContent}
                    <JoinModal onClose={() => setModalOpen(false)} open={modalOpen} />
                </div>
            </div>
        );
    }

    return (
        <div ref={intersectionRef} className="mt-10 overflow-hidden rounded-lg">
            {bannerContent}
            <JoinModal onClose={() => setModalOpen(false)} open={modalOpen} />
        </div>
    );
}

PlusMembershipProspectBanner.propTypes = {
    hide: PropTypes.bool,
    intersectionEntry: PropTypes.shape({}),
    intersectionRef: PropTypes.shape({}),
    sticky: PropTypes.bool,
};

PlusMembershipProspectBanner.defaultProps = {
    hide: false,
    intersectionEntry: undefined,
    intersectionRef: undefined,
    sticky: false,
};

export { PlusMembershipProspectBanner };
