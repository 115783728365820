import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';

const baseClasses = 'text-body-xs px-[10px] pt-[1px] pb-[2px] mr-5 whitespace-nowrap';

const pillTheme = {
    default: 'border border-black font-grotesk-bold',
    active: 'bg-lime if:bg-strawberry',
    disabled: 'border border-grey-light bg-grey-light font-grotesk-bold',
    offWhite: 'bg-off-white',
    offWhiteBorder: 'bg-white border border-off-white',
};

const roundedClasses = {
    md: 'rounded-md',
    full: 'rounded-full',
};

const componentTheme = {
    button: '!leading-normal',
};

function Pill({ as, children, rounded, variant, ...props }) {
    const variantClasses = pillTheme[variant];
    const componentClasses = as ? componentTheme[as] : '';

    // Allow arbitrary attributes on the span, except className
    // eslint-disable-next-line no-unused-vars
    const { className, ...attributes } = props;

    const isDisabled = variant === 'disabled';

    if (as === 'button') {
        return (
            <button
                type="button"
                disabled={isDisabled}
                className={clsx(baseClasses, roundedClasses[rounded], variantClasses, componentClasses, {
                    'cursor-not-allowed': isDisabled,
                })}
                {...attributes}
            >
                {children}
            </button>
        );
    }
    if (as === 'link' && !isDisabled) {
        return (
            <Link
                className={clsx(baseClasses, roundedClasses[rounded], variantClasses, componentClasses)}
                {...attributes}
            >
                {children}
            </Link>
        );
    }
    if (as === 'navlink' && !isDisabled) {
        return (
            <NavLink
                className={clsx(baseClasses, roundedClasses[rounded], variantClasses, componentClasses)}
                {...attributes}
            >
                {children}
            </NavLink>
        );
    }
    // Allow arbitrary attributes on the span, except className
    // eslint-disable-next-line no-unused-vars
    const { to, ...strippedProps } = props;

    return (
        <span className={clsx(baseClasses, roundedClasses[rounded], variantClasses, 'inline-block')} {...strippedProps}>
            {children}
        </span>
    );
}

Pill.propTypes = {
    as: PropTypes.oneOf(['link', 'navlink', 'button']),
    children: PropTypes.node.isRequired,
    rounded: PropTypes.oneOf(Object.keys(roundedClasses)),
    variant: PropTypes.oneOf(Object.keys(pillTheme)),
};

Pill.defaultProps = {
    as: null, // default will render as a span
    rounded: 'full',
    variant: 'default',
};

export default Pill;
