/**
 * Transform <Bold> tags and their attributes to display text as bold.
 *
 * Attributes:
 *     - None
 *
 * @param {string} copy - The copy string to transform.
 * @returns {string} The transformed copy.
 */
function transformBold(copy) {
    return copy.replace(/<Bold>(.*?)<\/Bold>/g, '<span class="font-grotesk-bold">$1</span>');
}

/**
 * Transform <Italic> tags and their attributes to display text as italic.
 *
 * Attributes:
 *     - None
 *
 * @param {string} copy - The copy string to transform.
 * @returns {string} The transformed copy.
 */
function transformItalic(copy) {
    return copy.replace(/<Italic>(.*?)<\/Italic>/g, '<span class="italic">$1</span>');
}

/**
 * Transform <Link> tags and their attributes to turn text into a link.
 *
 * Attributes:
 *     - to=""
 *         - Required
 *         - Specifies where the link should direct to
 *     - newTab
 *         - Optional
 *         - Opens the link in a new tab.
 *
 * @param {string} copy - The copy string to transform.
 * @returns {string} The transformed copy.
 */
function transformLink(copy) {
    return copy.replace(/<Link ([^>]*)>(.*?)<\/Link>/g, (_, attributes, innerContent) => {
        let transformedLink = `<a class="link">${innerContent}</a>`;
        let transformedAttributes = attributes;

        if (transformedAttributes.includes('to')) {
            transformedAttributes = transformedAttributes.replace(/\bto=(['"])(.*?)\1/, 'href="$2"');
        }

        if (transformedAttributes.includes('newTab')) {
            transformedAttributes = transformedAttributes.replace(
                'newTab',
                'target="_blank" rel="noopener noreferrer"'
            );
        }

        transformedLink = transformedLink.replace('>', ` ${transformedAttributes}>`);

        return transformedLink;
    });
}

/**
 * Transform <Strikethrough> tags and their attributes to display text with a strikethrough.
 *
 * Attributes:
 *     - None
 *
 * @param {string} copy - The copy string to transform.
 * @returns {string} The transformed copy.
 */
function transformStrikethrough(copy) {
    return copy.replace(/<Strikethrough>(.*?)<\/Strikethrough>/g, '<span class="line-through">$1</span>');
}

/**
 * Transform <Underline> tags and their attributes to display text with an underline.
 *
 * Attributes:
 *     - None
 *
 * @param {string} copy - The copy string to transform.
 * @returns {string} The transformed copy.
 */
function transformUnderline(copy) {
    return copy.replace(/<Underline>(.*?)<\/Underline>/g, '<span class="underline">$1</span>');
}

/**
 * Transforms a list of banners containing custom tags into a list of banners
 * containing HTML tags.
 *
 * @param {ServiceDelayBanner[]} banners - The banners to transform.
 * @returns
 */
function transformCustomTags(banners = []) {
    return banners.map((banner) => {
        let transformedCopy = banner.copy;

        transformedCopy = transformBold(transformedCopy);
        transformedCopy = transformItalic(transformedCopy);
        transformedCopy = transformLink(transformedCopy);
        transformedCopy = transformStrikethrough(transformedCopy);
        transformedCopy = transformUnderline(transformedCopy);

        return {
            ...banner,
            copy: transformedCopy,
        };
    });
}

export {
    transformBold,
    transformItalic,
    transformLink,
    transformStrikethrough,
    transformUnderline,
    transformCustomTags,
};
