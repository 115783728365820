import PropTypes from 'prop-types';

import IFDeliveryDayIcon from './IFDeliveryDayIcon';
import MMDeliveryDayIcon from './MMDeliveryDayIcon';

function DeliveryDayIcon({ isMisfits, width }) {
    return isMisfits ? <MMDeliveryDayIcon width={width} /> : <IFDeliveryDayIcon width={width} />;
}

DeliveryDayIcon.propTypes = {
    isMisfits: PropTypes.bool,
    width: PropTypes.number,
};

DeliveryDayIcon.defaultProps = {
    isMisfits: true,
    width: 54,
};

export default DeliveryDayIcon;
