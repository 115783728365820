import { ChevronRightIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { NANO_BANNER_SCROLL_Y } from 'constants';
import { ifLinks, mmLinks } from 'constants/links';
import { IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN } from 'constants/theme';
import { usePromoCodeContext } from 'contexts/PromoCodeContext';
import Button from 'honeydew/Button';
import { ClosedWindowIcon, ContactUsIcon, OrderIssueIcon } from 'honeydew/icons/Account';
import { Featured } from 'honeydew/icons/Aisles/Icons/Featured';
import useGlobalStore from 'hooks/useGlobalStore';
import useValidReferralsCampaign from 'hooks/useValidReferralsCampaign';
import useTrackAcquisitionPDPCTAClick from 'pages/AcquisitionPDP/useTrackAcquisitionPDPCTAClick';
import AccountLinks from 'shared/AccountLinks';
import { formatDateString, getDay, localizeTimeZone } from 'utils/dateUtils';

const helpLinkBaseClasses = 'whitespace-nowrap rounded-full bg-white shadow-base py-10 px-[12px]';

export function MobileNavigationModal({
    account,
    inactiveSubscription,
    isAcquisitionPDPRoute,
    isLoggedIn,
    toggleMenu,
}) {
    const navigate = useNavigate();
    const { marketOpenDate, marketplaceOpen } = account ?? {};
    const { isMisfitsTheme } = useGlobalStore();
    const validReferralsCampaign = useValidReferralsCampaign();
    const referralNanoBannerVisible = validReferralsCampaign && window.scrollY <= NANO_BANNER_SCROLL_Y;
    const trackAcquisitionPDPCTAClick = useTrackAcquisitionPDPCTAClick({ location: 'navbar' });

    const { renderSignupNanoBanner } = usePromoCodeContext();

    const handleMobileLinkClick = (path) => {
        navigate(path);
        toggleMenu();
    };

    const getShopContent = () => {
        if (inactiveSubscription) return null;

        if (marketplaceOpen) {
            return (
                <button
                    type="button"
                    className="m-20 w-[calc(100%_-_40px)] rounded-md bg-lime p-10 if:bg-strawberry"
                    onClick={() => handleMobileLinkClick('/shop')}
                >
                    <div className="flex items-center justify-between gap-10">
                        <div className="flex items-center gap-10">
                            <Featured
                                width={40}
                                brand={isMisfitsTheme ? MISFITS_THEME_DOMAIN : IMPERFECT_THEME_DOMAIN}
                            />
                            <span className="font-grotesk-bold">Shop</span>
                        </div>
                        <ChevronRightIcon width={20} />
                    </div>
                </button>
            );
        }
        return (
            <div className="m-20 flex gap-10 rounded-md border-2 border-lime px-30 py-20 if:border-strawberry">
                <ClosedWindowIcon />
                <div>
                    <p className="mb-5 font-grotesk-bold">Shop Window Closed</p>
                    <p>Come back to shop</p>
                    <p className="rounded-md bg-lime p-5 if:bg-strawberry">
                        {getDay(marketOpenDate?.date)}, {formatDateString(marketOpenDate?.date)} at{' '}
                        {localizeTimeZone(marketOpenDate?.date, true)}
                    </p>
                    <p>for your next order</p>
                </div>
            </div>
        );
    };

    return (
        <div
            className={clsx(
                'fixed z-[100] h-full w-full overflow-x-hidden bg-white text-body-mobile-md',
                referralNanoBannerVisible || renderSignupNanoBanner ? 'top-[152px] pb-[155px]' : 'top-50 pb-[85px]'
            )}
            id="mobile-navigation-dropdown-menu"
        >
            {isLoggedIn ? (
                <>
                    {account && getShopContent()}
                    <div className="px-30">
                        <AccountLinks clickableLink clickHandler={handleMobileLinkClick} hideLogOut />
                    </div>
                    <div className="px-30 pb-20 pt-40">
                        <button type="button" onClick={() => handleMobileLinkClick('/logout')}>
                            Log Out
                        </button>
                    </div>
                    <div className="bg-malt py-20 pl-30 if:bg-oat">
                        <p className="font-grotesk-bold">Need Help?</p>
                        <div className="-ml-30 flex w-[112%] gap-10 overflow-scroll px-30 py-10">
                            <button
                                type="button"
                                className={clsx(helpLinkBaseClasses, 'flex gap-10 pr-[28px]')}
                                onClick={() => handleMobileLinkClick('/account/orders')}
                            >
                                <OrderIssueIcon width={25} />
                                <span>Report Order Issue</span>
                            </button>
                            <a
                                href={isMisfitsTheme ? mmLinks.helpCenter : ifLinks.helpCenter}
                                target="_blank"
                                className={clsx(helpLinkBaseClasses, 'flex gap-10 pr-[28px]')}
                                rel="noopener noreferrer"
                            >
                                <ContactUsIcon width={25} />
                                Help Center
                            </a>
                            <a
                                href={isMisfitsTheme ? mmLinks.faqs : ifLinks.faqs}
                                className={helpLinkBaseClasses}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                FAQs
                            </a>
                            <a
                                href={isMisfitsTheme ? mmLinks.customerCare : ifLinks.customerCare}
                                className={helpLinkBaseClasses}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Contact Us
                            </a>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    {/* LOGGED OUT */}
                    <div className="space-y-20 p-25">
                        <Button
                            as="a"
                            href="/join"
                            onClick={() => {
                                if (isAcquisitionPDPRoute) {
                                    trackAcquisitionPDPCTAClick();
                                }
                            }}
                        >
                            Get Started
                        </Button>
                        <Button as="a" href="/sign-in" variant="secondary">
                            Sign In
                        </Button>
                        <ul className="space-y-10">
                            <li>
                                <a className="font-grotesk-bold hover:underline" href="/about-us">
                                    About Us
                                </a>
                            </li>
                            <li>
                                <a className="font-grotesk-bold hover:underline" href="/how-it-works">
                                    How It Works
                                </a>
                            </li>
                            <li>
                                <a className="font-grotesk-bold hover:underline" href="/misfits-perks">
                                    Perks Program
                                </a>
                            </li>
                            <li>
                                <a className="font-grotesk-bold hover:underline" href="/whats-in-your-box">
                                    Our Food
                                </a>
                            </li>
                            <li>
                                <a className="font-grotesk-bold hover:underline" href="/sourcing">
                                    Our Sourcing
                                </a>
                            </li>
                            <li>
                                <a className="font-grotesk-bold hover:underline" href="/packaging">
                                    Packaging Return
                                </a>
                            </li>
                            <li>
                                <a className="font-grotesk-bold hover:underline" href="https://blog.misfitsmarket.com/">
                                    Our Blog
                                </a>
                            </li>
                            <li>
                                <a className="font-grotesk-bold hover:underline" href="/faq">
                                    FAQs
                                </a>
                            </li>
                        </ul>
                    </div>
                </>
            )}
        </div>
    );
}

MobileNavigationModal.propTypes = {
    account: PropTypes.shape({
        marketOpenDate: PropTypes.shape({
            date: PropTypes.string,
        }),
        marketplaceOpen: PropTypes.bool,
    }),
    inactiveSubscription: PropTypes.bool.isRequired,
    isAcquisitionPDPRoute: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    toggleMenu: PropTypes.func.isRequired,
};

MobileNavigationModal.defaultProps = {
    account: {},
    isAcquisitionPDPRoute: false,
    isLoggedIn: false,
};
