import PropTypes from 'prop-types';

function YouTubeIcon({ dark }) {
    return (
        <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M30.9686 4.74701C30.9686 4.74701 30.6631 2.59039 29.7223 1.64343C28.531 0.397112 27.1991 0.391002 26.5882 0.317689C22.2138 -1.74792e-07 15.6462 0 15.6462 0H15.634C15.634 0 9.06637 -1.74792e-07 4.69203 0.317689C4.08109 0.391002 2.74924 0.397112 1.5579 1.64343C0.617051 2.59039 0.317689 4.74701 0.317689 4.74701C0.317689 4.74701 0 7.28242 0 9.81172V12.1822C0 14.7115 0.31158 17.2469 0.31158 17.2469C0.31158 17.2469 0.617051 19.4035 1.55179 20.3505C2.74313 21.5968 4.30714 21.554 5.00361 21.6884C7.50847 21.9267 15.6401 22 15.6401 22C15.6401 22 22.2138 21.9878 26.5882 21.6762C27.1991 21.6029 28.531 21.5968 29.7223 20.3505C30.6631 19.4035 30.9686 17.2469 30.9686 17.2469C30.9686 17.2469 31.2802 14.7176 31.2802 12.1822V9.81172C31.2802 7.28242 30.9686 4.74701 30.9686 4.74701ZM12.4082 15.0597V6.26826L20.8575 10.6793L12.4082 15.0597Z"
                fill={dark ? '#2D2D2D' : '#D9291F'}
            />
        </svg>
    );
}

export default YouTubeIcon;

YouTubeIcon.propTypes = {
    dark: PropTypes.bool,
};

YouTubeIcon.defaultProps = {
    dark: false,
};
