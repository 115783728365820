import PropTypes from 'prop-types';

function BackToSchoolActive({ className, width }) {
    return (
        <svg width={width} className={className} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5540_4796)">
                <path
                    d="M8.12254 27.2974C7.54921 27.3202 6.9737 27.3035 6.41178 27.2539C5.15335 27.1421 3.86301 26.8367 3.12443 26.1147C2.41002 25.4173 2.33836 24.4673 2.29214 23.5732C2.14626 20.8245 2.00037 18.0758 1.8566 15.3269C1.80408 14.3161 1.7533 13.2821 2.18241 12.294C2.81047 10.8462 5.39745 8.68526 6.95001 8.14657C6.95001 8.14657 6.37726 9.19643 6.22292 9.59268C6.06858 9.98893 5.8405 10.9487 5.8405 10.9487C4.65923 11.5621 3.99297 12.4838 3.71433 13.5706C3.43569 14.6575 3.60168 15.4484 3.69959 16.5402C3.90325 18.8151 4.05753 20.6386 4.2612 22.9135C4.30412 23.3846 4.35355 23.8759 4.65265 24.2729C5.01669 24.7529 5.6993 25.022 6.39176 25.1454C6.62407 25.1871 6.87603 25.2119 7.13937 25.2229C7.13937 25.2229 7.27277 25.8141 7.50217 26.2417C7.73157 26.6694 8.12322 27.3036 8.12322 27.3036L8.12254 27.2974Z"
                    className="fill-[#F8E1A4] if:fill-[#ECE7E4]"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.57609 8.7953C5.75702 10.2452 5.87164 11.922 6.00412 13.5068C6.29247 16.9294 6.50157 19.4113 6.78992 22.834C6.92761 24.4664 7.11924 26.2186 8.33219 27.4274C9.95736 29.046 12.7826 29.1227 15.2858 29.0837C17.6067 29.0479 19.9717 29.003 22.1942 28.3429C24.4168 27.6827 26.512 26.2903 27.2877 24.3463C27.9486 22.6874 27.5765 20.8962 27.1561 19.2129C26.5337 16.7239 25.8232 14.2549 25.0209 11.8107C24.4505 10.0726 23.9962 8.00115 22.7557 6.52079C20.6901 4.05751 15.6359 4.18894 12.4939 4.69392C10.1522 5.06949 7.70251 6.79619 6.57396 8.79553L6.57609 8.7953Z"
                    className="fill-[#F1C34A] if:fill-[#F6A685]"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M24.2302 15.6427C21.1257 15.5463 17.9977 16.1985 15.1945 17.5217C14.5551 17.8242 13.8953 18.1961 13.5739 18.8139C13.084 19.7562 13.6414 23.8516 14.3195 24.4524C14.8143 24.8908 15.5516 24.9129 16.222 24.8941C19.6521 24.804 23.0652 24.3029 26.4159 23.5655C26.9741 23.4437 27.5719 23.2903 27.935 22.8586C28.3402 22.3784 28.3385 21.6952 28.2819 21.0789C28.1496 19.6136 27.8599 18.0649 27.281 16.7008C26.8293 15.6352 25.2693 15.6737 24.23 15.6407L24.2302 15.6427Z"
                    className="fill-[#F1C34A] if:fill-[#F6A685]"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M13.4558 21.2498C17.7043 21.5901 26.5039 19.7915 27.8632 19.0769"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.87321 6.28251C8.58823 14.0898 10.8199 24.418 11.8162 28.9307"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19.5492 4.70629C18.9073 2.94249 18.2419 2.30052 17.3062 1.74051C16.619 1.33148 15.7697 1.26716 14.9566 1.27853C13.5941 1.29548 12.2319 1.49265 10.9181 1.85641C9.78791 2.17074 8.60872 2.68536 8.06284 3.68616C7.52978 4.66669 7.74662 5.84399 7.97594 6.91931L9.86082 5.62746C9.69928 5.05405 9.69434 4.4195 10.1937 4.01486C10.693 3.61022 11.0285 3.47503 11.6617 3.3911C12.5003 3.27844 13.3367 3.16601 14.1755 3.05542C14.7408 2.97893 15.3301 2.9061 15.8738 3.08349C16.4175 3.26088 17.011 3.83526 16.9709 4.41185L19.5447 4.70468L19.5492 4.70629Z"
                    className="fill-[#F8E1A4] if:fill-[#ECE7E4]"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_5540_4796">
                    <rect width="30" height="30" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default BackToSchoolActive;

BackToSchoolActive.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number.isRequired,
};

BackToSchoolActive.defaultProps = {
    className: null,
};
