import React, { Suspense, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Outlet, useMatch, useNavigate, useParams } from 'react-router-dom';

import useAccountQuery from 'api/queries/useAccountQuery';
import useAislesQuery from 'api/queries/useAislesQuery';
import { WINE_AISLE_NAME } from 'constants/shop';
import CartSheet from 'features/shop/cart/CartSheet';
import { ProductCardProvider } from 'features/shop/ProductCardV2/ProductCardProvider';
import WineFaqs from 'features/shop/WineFaqs';
import { LinearAisleNav } from 'features/site/header/navigation/ShopNavigation/LinearAisleNav';
import Loader from 'honeydew/Loader';
import { FEATURE_FLAG_NAMES, useAmplitudeFeatureFlag } from 'hooks/useFeatureFlag';
import useGlobalStore from 'hooks/useGlobalStore';
import useInactiveSubscription from 'hooks/useInactiveSubscription';
import useTailwindTheme from 'hooks/useTailwindTheme';
import UpSellsCarousel from 'shared/UpSellsCarousel';
import { trackExistingUserRedirect } from 'utils/analyticsUtils';
import { getDecodedPathSegment } from 'utils/pathUtils';

function ShopLayout() {
    const navigate = useNavigate();
    const { data: { aisles = [] } = {}, isLoading: isAislesLoading } = useAislesQuery();
    const { data: account = {} } = useAccountQuery();
    const tailwindTheme = useTailwindTheme();
    const mobile = useMediaQuery({ maxWidth: tailwindTheme?.screens?.md });
    const { autocompleteOpen, searchQuery } = useGlobalStore();

    const params = useParams();
    const isSearchRoute = !!useMatch('/shop/search/*');
    const aisleDisplayName = getDecodedPathSegment(params?.category);
    const currentAisle = aisles?.find((aisle) => aisle.name.toLowerCase() === aisleDisplayName?.toLowerCase());
    const onWineAisle = currentAisle?.name === WINE_AISLE_NAME;
    const inactiveSubscription = useInactiveSubscription();

    const { isAssignedVariant: hasUpSellsCarousel } = useAmplitudeFeatureFlag({
        flagName: FEATURE_FLAG_NAMES.UP_SELLS_CAROUSEL_PLP,
        variant: 'on',
    });

    const searchAutocompleteOpen = autocompleteOpen && searchQuery !== '';

    useEffect(() => {
        if (!account.subID) return;

        if (inactiveSubscription || !account.marketplaceOpen || (aisles?.length === 0 && !isAislesLoading)) {
            trackExistingUserRedirect({ destination: '/account' });
            navigate('/account');
        }
    }, [inactiveSubscription, account.subID, aisles.length, isAislesLoading]);

    if (aisles?.length === 0) {
        return (
            <div className="mt-[140px] flex justify-center">
                <Loader />
            </div>
        );
    }

    return (
        <>
            {!mobile && !searchAutocompleteOpen && <CartSheet orderMinOnly />}
            <main>
                <div className="min-h-[calc(100vh-80px)] bg-white pb-20 md:ml-[250px] md:px-20 md:pt-20">
                    <div className="lg:mx-auto">
                        <Suspense fallback={<Loader />}>
                            <ProductCardProvider>
                                <Outlet context={aisles} />
                                {!isSearchRoute && <LinearAisleNav aisles={aisles} />}
                                {onWineAisle && <WineFaqs />}
                            </ProductCardProvider>
                        </Suspense>
                    </div>
                </div>
            </main>
            {hasUpSellsCarousel && !mobile && (
                <UpSellsCarousel name="Nice pick! Bet you'd like these too..." position="fixed" />
            )}
        </>
    );
}
export default ShopLayout;
