import PropTypes from 'prop-types';

function DeliveryScheduleIcon({ width }) {
    return (
        <svg width={width} className="inline-block" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.7833 17.3468C12.7833 17.3468 9.80995 17.1002 8.83855 16.9227C6.96972 16.6071 4.34146 16.4296 4.34146 16.4296C3.84411 16.375 3.38867 16.1257 3.07453 15.7363C2.76039 15.3468 2.61313 14.8489 2.66499 14.3512C2.66499 14.3512 2.94603 11.9227 3.01506 11.1116C3.1408 9.56328 3.35286 7.95332 3.35286 7.95332L3.63878 5.30786L8.126 5.80096C9.82497 5.8924 11.5178 6.07428 13.1974 6.34583C14.2502 6.52087 17.4406 6.80194 17.4406 6.80194C17.4406 6.80194 17.0585 9.80983 17.0092 10.8182C16.9278 12.4282 16.4667 15.8453 16.4667 15.8453"
                fill="white"
            />
            <path
                d="M12.7833 17.3468C12.7833 17.3468 9.80995 17.1002 8.83855 16.9227C6.96972 16.6071 4.34146 16.4296 4.34146 16.4296C3.84411 16.375 3.38867 16.1257 3.07453 15.7363C2.76039 15.3468 2.61313 14.8489 2.66499 14.3512C2.66499 14.3512 2.94603 11.9227 3.01506 11.1116C3.1408 9.56328 3.35286 7.95332 3.35286 7.95332L3.63878 5.30786L8.126 5.80096C9.82497 5.8924 11.5178 6.07428 13.1974 6.34583C14.2502 6.52087 17.4406 6.80194 17.4406 6.80194C17.4406 6.80194 17.0585 9.80983 17.0092 10.8182C16.9278 12.4282 16.4667 15.8453 16.4667 15.8453"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.018 9.39494C11.9595 9.51451 11.8934 9.64155 11.8287 9.75863C10.9207 11.4115 10.339 12.2447 10.2057 12.4913C10.055 12.7703 9.69259 13.3121 9.4385 13.6534C9.15826 14.0308 8.9241 13.9486 8.70115 13.8091C8.60026 13.7468 7.8355 13.0929 7.47555 12.723C7.41078 12.657 7.37092 12.6246 7.30989 12.5548C6.88267 12.0666 7.5154 11.5572 7.91148 11.9508C8.01984 12.0591 8.7273 12.723 8.86307 12.7143C8.94029 12.7093 10.9904 9.25668 11.2221 8.95526C11.6381 8.41097 12.231 8.96398 12.018 9.39618V9.39494Z"
                fill="#2D2D2D"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.8115 14.4845C13.7659 15.5206 13.4074 16.5186 12.7834 17.3469C12.7834 17.3469 16.2967 16.8218 16.457 15.843C16.5778 15.1083 13.8485 13.5896 13.8115 14.4845Z"
                className="fill-mango if:fill-[#E95CA2]"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.4307 6.802L3.62402 5.32271L3.73743 4.27242C3.79204 3.77524 4.04131 3.31995 4.43084 3.0062C4.82037 2.69246 5.31835 2.54581 5.81575 2.59836C5.81575 2.59836 9.49423 3.09146 10.7319 3.12597C12.6155 3.16295 15.8626 3.67824 15.8626 3.67824C16.3599 3.73287 16.8155 3.98215 17.1296 4.37159C17.4438 4.76103 17.591 5.25899 17.5392 5.75664L17.4307 6.802Z"
                className="fill-mango if:fill-[#E95CA2]"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.42965 1.15601L6.10425 4.16143"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.5792 2.14233L15.2537 5.14775"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default DeliveryScheduleIcon;

DeliveryScheduleIcon.propTypes = {
    width: PropTypes.number,
};

DeliveryScheduleIcon.defaultProps = {
    width: 20,
};
