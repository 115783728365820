import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';

import { GlobalContext } from 'contexts/GlobalContext';
import CartOrderCard from 'features/shop/cart/CartOrderCard';
import CartProductCard from 'features/shop/cart/CartProductCard';
import { Alert } from 'honeydew/Alert';
import Button from 'honeydew/Button';
import { useAccountPlusMembershipHook } from 'hooks/useAccountPlusMembershipHook/useAccountPlusMembershipHook';
import {
    getColdPackItems,
    getGroceryItems,
    getItemsTotalCost,
    getOrderMin,
    getTotalProductQty,
    individualizePerkSelections,
} from 'utils/orderUtils';
import { getProductsSubtotal, PriceType } from 'utils/priceUtils';

function CartGroceryOrderCard({ account, coldPackSectionRef, selections }) {
    const navigate = useNavigate();
    const { setCartOpen } = useContext(GlobalContext);

    const { inPlusMembershipTreatment, plusMembershipEligibleOrder, plusMembershipShortBranding } =
        useAccountPlusMembershipHook();

    const { nextOrder, proteinMinimum } = account;
    const { coldKeeperFee } = nextOrder.cartExperience;

    const individualizedPerkSelections = individualizePerkSelections(selections);
    const orderMin = getOrderMin(account);
    const groceryItems = getGroceryItems(selections);
    const groceryItemsWithoutColdPack = getGroceryItems(selections, coldKeeperFee > 0);
    // distinguish product source
    const allGroceryItems = coldKeeperFee > 0 ? groceryItemsWithoutColdPack : groceryItems;
    const recurringProducts = allGroceryItems.filter((product) => product.source === 'recurring');
    const nonRecurringProducts = allGroceryItems.filter((product) => product.source !== 'recurring');
    // combine products so that recurring items are first
    const sortedProducts = [...recurringProducts, ...nonRecurringProducts];

    const coldPackItems = getColdPackItems(individualizedPerkSelections);
    const coldPackItemsQty = getTotalProductQty(coldPackItems);

    const priceType = plusMembershipEligibleOrder ? PriceType.PLUS_MEMBER : PriceType.DEFAULT;

    const coldPackItemsTotalCost = inPlusMembershipTreatment
        ? getProductsSubtotal({
              options: { priceType },
              products: coldPackItems,
          })
        : getItemsTotalCost(coldPackItems);

    const diffToColdPackMin = proteinMinimum - coldPackItemsTotalCost;
    const groceryItemQty = getTotalProductQty(coldKeeperFee > 0 ? groceryItemsWithoutColdPack : groceryItems);

    const groceryItemsTotalCost = inPlusMembershipTreatment
        ? getProductsSubtotal({
              options: { priceType },
              products: groceryItems,
          })
        : getItemsTotalCost(groceryItems);

    // To-Do: Clarify with Product where this should really link to.
    const navigateToMeatAndSeafoodAisle = () => {
        if (window.isNativeApp) {
            window.ReactNativeWebView?.postMessage(JSON.stringify({ navigate: 'Meat' }));
        } else {
            setCartOpen(false);
            navigate('/shop/aisle/meat+%26+seafood/all');
        }
    };

    const navigateToPDP = ({ isPerk, productId }) => {
        // TODO: open PDP in modal instead
        if (window.isNativeApp) {
            window.ReactNativeWebView?.postMessage(JSON.stringify({ navigate: 'PDP', pdpId: productId }));
        } else {
            setCartOpen(false);
            navigate(`/shop/product/${productId}${isPerk ? '?perk=true' : ''}`);
        }
    };

    return (
        <CartOrderCard title={`Groceries (${groceryItemQty})`} willShip={groceryItemsTotalCost >= orderMin}>
            <div className="mt-10 flex flex-col gap-30">
                {sortedProducts.map((product, index) => {
                    const key = `${product.id}-${index}`;
                    return (
                        <CartProductCard
                            key={key}
                            inPlusMembershipTreatment={inPlusMembershipTreatment}
                            plusMembershipEligibleOrder={plusMembershipEligibleOrder}
                            plusMembershipShortBranding={plusMembershipShortBranding}
                            onClick={() => navigateToPDP({ isPerk: product.isPerk, productId: product.id })}
                            product={product}
                            usePlusMemberPrice
                        />
                    );
                })}
            </div>
            {coldKeeperFee > 0 && coldPackItems.length > 0 && (
                <>
                    <p className="text-body-md mt-10 scroll-mt-10 font-grotesk-bold" ref={coldPackSectionRef}>
                        Cold Pack ({coldPackItemsQty})
                    </p>
                    <div className="mt-10 flex flex-col rounded-lg border border-off-white p-5">
                        {coldPackItemsTotalCost < proteinMinimum ? (
                            <Alert>
                                Add <span className="font-grotesk-bold">${diffToColdPackMin.toFixed(2)}</span> to waive
                                Cold Pack Fee
                            </Alert>
                        ) : (
                            <Alert variant="success">Your Cold Pack Fee is waived!</Alert>
                        )}
                        <p className="text-body-xs mt-10 text-grey">
                            ${coldKeeperFee.toFixed(2)} Cold Pack packaging fee applies; purchase at least $
                            {proteinMinimum.toFixed(2)} of Cold Pack items to waive the fee
                        </p>
                        <div className="my-10 flex flex-col gap-5">
                            {coldPackItems.map((product) => (
                                <CartProductCard
                                    key={product.id}
                                    inPlusMembershipTreatment={inPlusMembershipTreatment}
                                    plusMembershipEligibleOrder={plusMembershipEligibleOrder}
                                    plusMembershipShortBranding={plusMembershipShortBranding}
                                    onClick={() => navigateToPDP({ productId: product.id })}
                                    product={product}
                                    usePlusMemberPrice
                                />
                            ))}
                        </div>
                        {coldPackItemsTotalCost < proteinMinimum && (
                            <Button onClick={navigateToMeatAndSeafoodAisle} size="md" variant="secondary">
                                Shop Cold Pack
                            </Button>
                        )}
                        <p className="text-body-xs mt-10 text-grey">
                            Cold Pack items require specialized packaging to keep items refrigerator-cold in transit.
                        </p>
                    </div>
                </>
            )}
        </CartOrderCard>
    );
}

export default CartGroceryOrderCard;

CartGroceryOrderCard.propTypes = {
    account: PropTypes.shape({
        cartMinimum: PropTypes.number,
        nextOrder: PropTypes.shape({
            cartExperience: PropTypes.shape({
                coldKeeperFee: PropTypes.number,
                orderMinimum: PropTypes.number,
                orderMinimumOverridden: PropTypes.bool,
            }),
        }),
        proteinMinimum: PropTypes.number,
    }).isRequired,
    coldPackSectionRef: PropTypes.shape({
        current: PropTypes.instanceOf(Element),
    }),
    selections: PropTypes.shape({
        selected: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
};

CartGroceryOrderCard.defaultProps = {
    coldPackSectionRef: undefined,
};
