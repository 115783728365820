import PropTypes from 'prop-types';

import { BOX_WITH_HEART_ICON_URL } from 'honeydew/icons/PlusMembership';
import { getResizedCDNAsset } from 'utils/globalUtils';

import BenefitBase from './shared/BenefitBase';

function ImpactBenefit({ bgColor, layout }) {
    const horizontalImage = (
        <img
            src={getResizedCDNAsset({ url: BOX_WITH_HEART_ICON_URL, width: 300 })}
            width={150}
            alt="box of groceries with heart"
        />
    );

    const verticalImage = (
        <img
            src={getResizedCDNAsset({ url: BOX_WITH_HEART_ICON_URL, width: 350 })}
            width={175}
            className="mt-auto"
            alt="box of groceries with heart"
        />
    );

    return (
        <BenefitBase
            bgColor={bgColor}
            headline="Save food, fight hunger"
            subheadline="Help people facing hunger with every order"
            verticalImage={verticalImage}
            horizontalImage={horizontalImage}
            horizontalImagePosition="bottom-[-20px] left-0 right-[-30px] top-[-20px]"
            layout={layout}
        />
    );
}

ImpactBenefit.propTypes = {
    bgColor: PropTypes.string,
    layout: PropTypes.oneOf(['horizontal', 'responsive']),
};

ImpactBenefit.defaultProps = {
    bgColor: 'bg-white',
    layout: 'responsive',
};

export default ImpactBenefit;
