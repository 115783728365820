import { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useLocation, useRouteError } from 'react-router-dom';

import { ifLinks, mmLinks } from 'constants/links';
import useGlobalStore from 'hooks/useGlobalStore';
import logError from 'utils/errorUtils';

function Error({ fullScreen }) {
    const error = useRouteError();
    const location = useLocation();
    const { isMisfitsTheme } = useGlobalStore();

    const contactUsLink = isMisfitsTheme ? mmLinks.customerCare : ifLinks.customerCare;

    useEffect(() => {
        logError(error);
    }, []);

    return (
        <div
            className={clsx({
                'flex h-screen grow flex-col items-center justify-center text-center': fullScreen,
            })}
        >
            <section className="space-y-30">
                <h2 className="text-body-lg font-grotesk-bold">Sorry an unexpected error has occurred</h2>
                <p className="text-body-sm font-grotesk-bold">{error.statusText || error.message}</p>
                <p>
                    Please{' '}
                    <a href={location.pathname} className="link">
                        try again
                    </a>{' '}
                    or{' '}
                    <a href={contactUsLink} className="link" target="_blank" rel="noreferrer">
                        contact us
                    </a>
                    .
                </p>
            </section>
        </div>
    );
}

Error.propTypes = {
    fullScreen: PropTypes.bool,
};

Error.defaultProps = {
    fullScreen: false,
};

export { Error as Component };
