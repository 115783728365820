import { useQuery } from '@tanstack/react-query';

import { getReferralCredits } from 'api/jalapeno';

export const REFERRAL_CREDITS_QUERY_KEY = 'referral-credits';

const useReferralCreditsQuery = () => {
    const query = useQuery({
        queryKey: [REFERRAL_CREDITS_QUERY_KEY],
        queryFn: async () => {
            const response = await getReferralCredits();
            return response.data;
        },
    });

    return query;
};

export default useReferralCreditsQuery;
