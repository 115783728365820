import { useMemo } from 'react';

import { useAmplitudeExperiment } from 'contexts/AmplitudeExperimentContext';

/**
 * Data for consuming Amplitude Experiment feature flags
 * @typedef {Object} FeatureFlagData
 * @property {Boolean} experimentClientIsReady - Whether or not the Amplitude Experiment SDK is ready to use
 * @property {Boolean} experimentClientIsTimedOut - Whether or not the Amplitude Experiment SDK has timed out
 * @property {Boolean} isAssignedVariant - Whether the variant assignment matches the provided variant
 * @property {String} variant - The variant assignment by Amplitude Experiment
 */

/**
 * Hook for consuming Amplitude Experiment feature flags, including SDK readiness and variant assignment.
 *
 * @param {object} params
 * @param {string} params.flagName - the flag to query
 * @param {boolean} params.isEnabled - optionally disable hook (even if client is ready)
 * @param {string} params.variant - The variant to evaluate for returned isAssignedVariant
 * @returns {FeatureFlagData}
 */
function useAmplitudeFeatureFlag({ fallback = 'off', flagName, isEnabled = true, variant }) {
    const {
        experimentClientIsReady,
        experimentClientIsTimedOut,
        getFeatureFlagVariant: getVariantAssignment,
    } = useAmplitudeExperiment();

    let isAssignedVariant;

    const variantAssignment = useMemo(() => {
        if (experimentClientIsReady && flagName && isEnabled) {
            return getVariantAssignment(flagName, fallback);
        }

        return undefined;
    }, [experimentClientIsReady, flagName, isEnabled]);

    if (variantAssignment && variant) {
        isAssignedVariant = variantAssignment === variant;
    }

    return {
        experimentClientIsReady,
        experimentClientIsTimedOut,
        isAssignedVariant,
        variantAssignment,
    };
}

export { useAmplitudeFeatureFlag };
