import { Accordion } from 'honeydew/Accordion';
import { useAccountPlusMembershipHook } from 'hooks/useAccountPlusMembershipHook';
import useGlobalStore from 'hooks/useGlobalStore';

import { activeMemberFaqList, nonMemberFaqList, trialActiveFaqList, trialAvailableFaqList } from './constants';

export function PlusMembershipFaq() {
    const { availableTrialMembership, membership, plusMembershipBranding, trial } = useAccountPlusMembershipHook();
    const { isMisfitsTheme } = useGlobalStore();
    const faqList = [];

    faqList.push(
        ...(membership || trial
            ? activeMemberFaqList(plusMembershipBranding)
            : nonMemberFaqList(plusMembershipBranding))
    );
    if (trial) faqList.push(...trialActiveFaqList(plusMembershipBranding, isMisfitsTheme));
    if (availableTrialMembership) faqList.push(...trialAvailableFaqList(plusMembershipBranding, isMisfitsTheme));

    return (
        <div className="space-y-60 py-40">
            <h3 className="text-heading-sm mb-40 font-grotesk-bold">FAQs</h3>
            <Accordion items={faqList} />
        </div>
    );
}
