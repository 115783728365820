import { useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';

import { getRedirectInfo } from 'utils/optimizelyUtils';
import { filterUtmParams, queryStringToObject } from 'utils/queryParamUtils';

const getReferrerData = () => {
    const params = queryStringToObject(window.location.search);

    const utmParams = filterUtmParams(params);

    const referrerData = {
        ...utmParams,
        user_agent: navigator.userAgent,
        referral_url: document.referrer,
        timestamp: +new Date(),
        gclid: params.gclid,
        irclickid: params.irclickid,
        token: params.token,
        user_id: window.dataLayer?.[0]?.customer_id,
    };

    const originalReferrer = getRedirectInfo();

    if (originalReferrer) {
        referrerData.referral_url = originalReferrer;
    }

    return referrerData;
};

/**
 * Captures referrer data and stores it in a utm_params localStorage item and a cookie.
 */
function useCaptureReferrerData() {
    const mountRef = useRef(false);

    // eslint-disable-next-line no-unused-vars
    const [cookies, setCookie] = useCookies(['utm_params']);

    useEffect(() => {
        if (mountRef.current) return;

        const referrerData = getReferrerData();

        const referrerURL = referrerData.referral_url ? new URL(referrerData.referral_url) : null;
        const referrerHostname = referrerURL?.hostname;

        // Prevent referrer data from being overwritten for users who initially land
        // on a page that's not powered by `boysenberry`. (ex. CMS pages)
        if (referrerHostname === window.location.hostname) {
            return;
        }

        if (
            referrerData.utm_source ||
            referrerData.utm_medium ||
            referrerData.utm_campaign ||
            referrerData.utm_content ||
            referrerData.utm_term ||
            referrerData.irclickid ||
            referrerData.gclid ||
            referrerData.token ||
            referrerData.referral_url
        ) {
            const stringifiedReferrerData = JSON.stringify(referrerData);

            let cookieDomain = '';

            if (window.location.hostname.endsWith('.misfitsmarket.com')) {
                cookieDomain = '.misfitsmarket.com';
            } else if (window.location.hostname.endsWith('.imperfectfoods.com')) {
                cookieDomain = '.imperfectfoods.com';
            }

            setCookie('utm_params', stringifiedReferrerData, {
                domain: cookieDomain,
                maxAge: 2419200,
            });

            localStorage.setItem('utm_params', stringifiedReferrerData);
        }

        mountRef.current = true;
    }, [setCookie]);
}

export { getReferrerData, useCaptureReferrerData };
