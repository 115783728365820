import { useEffect, useState } from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import { useFetcher } from 'react-router-dom';

import useAccountQuery, { ACCOUNT_QUERY_KEY } from 'api/queries/useAccountQuery';
import useDiscountLadderQuery, { DISCOUNT_LADDER_QUERY_KEY } from 'api/queries/useDiscountLadderQuery';
import useSelectionsQuery from 'api/queries/useSelectionsQuery';
import TextField from 'honeydew/TextField';
import useAuth from 'hooks/useAuth';
import { filterPerkSelections, getAppliedDiscounts } from 'utils/orderUtils';

function CartDiscountCodeForm() {
    const { authUser } = useAuth();

    const queryClient = useQueryClient();
    const { data: account } = useAccountQuery();
    const { data: discounts, isLoading: discountsIsLoading } = useDiscountLadderQuery();
    const { data: selections, isLoading: selectionsIsLoading } = useSelectionsQuery();

    const [showForm, setShowForm] = useState(false);
    const [discountCode, setDiscountCode] = useState('');

    const fetcher = useFetcher();

    useEffect(() => {
        if (fetcher.state === 'loading') {
            queryClient.invalidateQueries({ queryKey: [ACCOUNT_QUERY_KEY, authUser?.username] });
            queryClient.invalidateQueries({ queryKey: [DISCOUNT_LADDER_QUERY_KEY, account?.subID] });
        }
    }, [fetcher.state]);

    if (discountsIsLoading || selectionsIsLoading) {
        return false;
    }

    const handleDiscountCodeChange = (e) => {
        setDiscountCode(e.target.value.toUpperCase());
    };

    const filteredSelections = filterPerkSelections(selections);
    const appliedDiscounts = getAppliedDiscounts(discounts, filteredSelections, account);

    return showForm ? (
        <div className="relative">
            <fetcher.Form action="/account/promotions" method="post">
                <TextField
                    error={fetcher?.data?.error}
                    errorMsg={fetcher?.data?.message}
                    id="discount-code"
                    label="Code or gift card"
                    name="discountCode"
                    onChange={handleDiscountCodeChange}
                    value={discountCode}
                />
                <input type="hidden" defaultValue={account.subID} name="subscriptionID" />
                <input type="hidden" defaultValue={account.customerID} name="customerID" />
                <button
                    className="text-brand-primary text-body-md absolute right-20 top-[28px] -translate-y-1/2 transform font-grotesk-bold disabled:text-grey"
                    disabled={discountCode === '' || fetcher.state === 'submitting'}
                    type="submit"
                >
                    Apply
                </button>
            </fetcher.Form>
            {appliedDiscounts.map((discount) => (
                <div
                    className="text-body-md text-brand-primary flex items-center gap-10 font-grotesk-bold"
                    key={discount.discountCode}
                >
                    <CheckIcon className="w-15 stroke-[3px]" />
                    {discount.discountCode}
                </div>
            ))}
        </div>
    ) : (
        <button className="text-body-sm link" onClick={() => setShowForm(true)} type="button">
            + Apply code or gift card
        </button>
    );
}

export default CartDiscountCodeForm;
