import PropTypes from 'prop-types';

import { IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN } from 'constants/theme';

export function Vegetables({ brand, isActive, width }) {
    const pepperColor = brand === MISFITS_THEME_DOMAIN ? '#2E8540' : '#547420';
    const stemColor = brand === MISFITS_THEME_DOMAIN ? '#B0C472' : '#C5DB66';
    const activeColor = brand === MISFITS_THEME_DOMAIN ? '#D7E1B8' : '#F0D3E3';

    return isActive ? (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.6505 28.7652C21.902 26.9646 29 22.6358 29 14.9992C29 7.3625 22.9452 2.7363 14.6505 1.1728C7.31233 -0.213857 2.48978 6.96057 1.13553 14.967C-0.132106 22.475 7.73356 30.4815 14.6505 28.7652Z"
                fill={activeColor}
            />
            <g clipPath="url(#clip0_10935_21388)">
                <path
                    d="M24.324 11.581C23.7431 12.5524 23.8098 13.981 24.5431 14.8381L25.0098 15.3143C25.0098 15.3143 23.9621 15.8952 24.3431 17.781L20.5526 22.0571C19.2193 21.0571 18.4669 19.4381 18.124 17.8095C17.7526 16.0571 17.8193 14.1714 18.5431 12.5333C19.124 11.2 21.4002 9.62857 22.7336 9.00952C22.7336 9.00952 22.3907 9.40952 22.7621 10.219C23.0574 10.8762 23.6383 11.4095 24.3336 11.6L24.324 11.581Z"
                    fill="white"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.8094 8.21902C16.0665 8.59998 16.5618 8.82855 16.9999 8.70474C17.0379 9.29521 17.5427 9.79045 18.1237 9.91426C18.7046 10.0381 19.3141 9.82855 19.7618 9.44759C20.0665 9.19045 20.2951 8.85712 20.476 8.49521C20.4284 8.50474 20.3808 8.50474 20.3332 8.52379C20.0951 8.22855 19.8189 7.99045 19.5141 7.79045C19.3808 7.22855 19.4475 6.6095 19.7237 6.13331C20.1713 5.36188 20.7141 5.56188 21.4189 5.46664C21.5999 5.43807 21.7427 5.31426 21.7618 5.15236C21.8094 4.89521 21.9046 4.49521 21.9808 4.22855C22.0284 4.05712 21.9427 3.86664 21.7618 3.76188C21.476 3.59997 21.1903 3.46664 20.9141 3.38093C19.8379 3.04759 18.8189 3.29521 17.9618 4.08569C17.3522 4.64759 16.9522 5.29521 16.4475 5.91426C16.2475 6.15236 16.0475 6.29521 15.8284 6.4095C15.7999 6.36188 15.7713 6.31426 15.7427 6.26664C15.6665 6.47617 15.6094 6.67617 15.5713 6.89521C15.4951 7.34283 15.5522 7.83807 15.8094 8.2095V8.21902Z"
                    fill={stemColor}
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M22.9523 17.2476C22.4476 16.7048 22.3523 15.8381 22.7142 15.2C21.8666 15.0762 21.1904 14.2476 21.2285 13.4C21.2666 12.5429 22.0952 11.9905 22.5523 11.7715C22.1047 11.3238 21.9238 10.7238 22.019 10.0667C22.0857 9.6286 22.3523 9.24765 22.7142 9.00003C21.9999 8.54289 21.1999 8.27622 20.4666 8.49527C20.2857 8.85717 20.0571 9.1905 19.7523 9.44765C19.3047 9.8286 18.6857 10.0381 18.1142 9.91431C17.5428 9.7905 17.038 9.29527 16.9904 8.70479C16.5523 8.83812 16.0571 8.60003 15.7999 8.21908C15.5428 7.83812 15.4952 7.35241 15.5618 6.90479C15.5999 6.68574 15.6571 6.48574 15.7333 6.27622C15.2285 5.4286 14.1142 4.83812 13.0761 4.51431C13.019 4.49527 12.9619 4.47622 12.9142 4.4667C8.40947 3.17146 6.1428 8.07622 5.3809 11.0762C4.9428 12.8286 5.59042 14.5905 6.04756 16.3619C6.83804 19.3905 6.49518 22.6286 9.9428 24.5048C11.2095 25.2 12.7142 25.5619 13.819 24.6857C17.3238 27.6096 21.0666 23.9429 22.619 21.5048C23.3523 20.3524 23.8952 19.0762 24.3428 17.7905C23.8285 17.8191 23.3047 17.6381 22.9523 17.2572V17.2476Z"
                    fill={pepperColor}
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_10935_21388">
                    <rect width="20.2476" height="23" fill="white" transform="translate(5 3)" />
                </clipPath>
            </defs>
        </svg>
    ) : (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10935_20996)">
                <path
                    d="M15.8094 8.21902C16.0665 8.59997 16.5618 8.82855 16.9999 8.70474C17.038 9.29521 17.5427 9.79045 18.1237 9.91426C18.7046 10.0381 19.3141 9.82855 19.7618 9.44759C20.0665 9.19045 20.2951 8.85712 20.476 8.49521C20.4284 8.50474 20.3808 8.50474 20.3332 8.52378C20.0951 8.22855 19.8189 7.99045 19.5141 7.79045C19.3808 7.22855 19.4475 6.6095 19.7237 6.13331C20.1713 5.36188 20.7141 5.56188 21.4189 5.46664C21.5999 5.43807 21.7427 5.31426 21.7618 5.15236C21.8094 4.89521 21.9046 4.49521 21.9808 4.22855C22.0284 4.05712 21.9427 3.86664 21.7618 3.76188C21.476 3.59997 21.1903 3.46664 20.9141 3.38093C19.8379 3.04759 18.8189 3.29521 17.9618 4.08569C17.3522 4.64759 16.9522 5.29521 16.4475 5.91426C16.2475 6.15236 16.0475 6.29521 15.8284 6.4095C15.7999 6.36188 15.7713 6.31426 15.7427 6.26664C15.6665 6.47617 15.6094 6.67617 15.5713 6.89521C15.4951 7.34283 15.5522 7.83807 15.8094 8.2095V8.21902Z"
                    fill={stemColor}
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M24.619 10.9524C23.9904 9.77143 22.0571 8 20.4666 8.47619C20.2857 8.83809 20.0571 9.17143 19.7523 9.42857C19.3047 9.80952 18.6857 10.019 18.1142 9.89524C17.5428 9.77143 17.038 9.27619 16.9904 8.68571C16.5523 8.81905 16.0571 8.58095 15.7999 8.2C15.5428 7.81905 15.4952 7.33333 15.5619 6.88571C15.5999 6.66666 15.6571 6.46666 15.7333 6.25714C15.2285 5.40952 14.1142 4.81905 13.0761 4.49524C13.019 4.47619 12.9619 4.45714 12.9142 4.44762C8.40947 3.17143 6.1428 8.07619 5.3809 11.0762C4.9428 12.8286 5.59042 14.5905 6.04756 16.3619C6.83804 19.3905 6.49518 22.6286 9.9428 24.5048C11.2095 25.2 12.7142 25.5619 13.819 24.6857C17.3238 27.6095 21.0666 23.9429 22.619 21.5048C23.6857 19.8286 24.3523 17.9238 24.9047 16.0381C25.3809 14.4 25.4952 12.619 24.6095 10.9619L24.619 10.9524Z"
                    fill={pepperColor}
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_10935_20996">
                    <rect width="20.5143" height="23" fill="white" transform="translate(5 3)" />
                </clipPath>
            </defs>
        </svg>
    );
}

Vegetables.propTypes = {
    brand: PropTypes.oneOf([IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN]).isRequired,
    isActive: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Vegetables.defaultProps = {
    isActive: false,
    width: 30,
};
