import { useQuery } from '@tanstack/react-query';

import { getDiscounts } from 'api/jalapeno';
import useAccountQuery from 'api/queries/useAccountQuery';

export const DISCOUNT_LADDER_QUERY_KEY = 'discount-ladder';

const useDiscountLadderQuery = () => {
    const { data: account } = useAccountQuery();

    const query = useQuery({
        queryKey: [DISCOUNT_LADDER_QUERY_KEY, account?.subID],
        queryFn: async () => {
            const res = await getDiscounts(account?.subID);
            return res?.data;
        },
        enabled: !!account?.subID,
        placeholderData: { thresholdDiscounts: [] },
    });

    return query;
};

export default useDiscountLadderQuery;
