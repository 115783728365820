import { matchPath } from 'react-router';

import {
    AISLE_PATH_PREFIX,
    BUY_IT_AGAIN_AISLE,
    FEATURED_AISLE,
    MEMBER_DEALS_AISLE,
    SHOP_ALL_AISLE,
    SHOP_WITH_POINTS_AISLE,
} from 'constants/shop';
import { getEncodedPathSegment } from 'utils/pathUtils';

/**
 * getAisleIconName - returns the aisle icon component name for a given aisle name, with optional brand theming
 *
 * @param {string} aisleName – the name of the aisle, derived from /v1/aisles endpoint
 * @param {string} theme='Misfits' - the theme of the desired aisle icon (Imperfect or Misfits)
 * @example
 * const aisleIconName = getAisleIconName('Deli', 'Misfits');
 * @returns {string}
 */
export const getAisleIconName = (aisleName, isMisfitsTheme = true) => {
    switch (aisleName) {
        case 'Curated':
        case 'Featured':
            return 'Featured';
        case 'Shop All':
            return 'ShopAll';
        case 'Fruit':
            return 'Fruit';
        case 'Vegetables':
            return 'Vegetables';
        case 'Meat & Seafood':
            return 'MeatAndSeafood';
        case 'Deli':
            return 'Deli';
        case 'Dairy & Eggs':
            return 'Dairy';
        case 'Our Brands':
            return 'OurBrands';
        case 'Pantry':
            return 'Pantry';
        case 'Beverages':
            return 'Beverages';
        case 'Wine Shop':
            return 'Wine';
        case 'Bakery':
            return 'Bakery';
        case 'Pet':
            return 'Pet';
        case 'Household':
            return 'Household';
        case 'Gluten-Free':
        case 'Gluten-Free Shop':
            return 'GlutenFree';
        case 'Cold Pack':
            return isMisfitsTheme ? 'MMColdPack' : 'IFColdPack';
        case 'Plant-Based':
        case 'Plant-Based Shop':
            return 'PlantBased';
        case 'Snacks':
            return 'Snacks';
        case 'Produce':
            return isMisfitsTheme ? 'MMProduce' : 'IFProduce';
        case 'Black Friday Sale':
        case 'Summer Sale':
            return 'Sale';
        case 'Prepared':
        case 'Prepared Foods':
            return 'PreparedFoods';
        case 'Buy It Again':
            return isMisfitsTheme ? 'MMBuyItAgain' : 'IFBuyItAgain';
        case 'Earth Friendly':
        case 'Rescued':
        case 'Saved & Rescued':
            return 'Rescued';
        case 'Wellness':
        case 'Vitamins & Supplements':
            return 'VitaminsAndSupplements';
        case 'Member Deals':
            return 'MemberDeals';
        case 'Back to School':
            return 'BackToSchool';
        case 'Spice Up Your Routine':
            return isMisfitsTheme ? 'MMSpiceUpYourRoutine' : 'IFSpiceUpYourRoutine';
        case 'Fall Flavors':
            return isMisfitsTheme ? 'MMFallFlavors' : 'IFFallFlavors';
        case 'Thanksgiving Essentials':
            return isMisfitsTheme ? 'MMThanksgivingEssentials' : 'IFThanksgivingEssentials';
        case 'Holiday Must Haves':
            return isMisfitsTheme ? 'MMHolidayMustHaves' : 'IFHolidayMustHaves';
        case 'New Picks':
        case 'Discover Something New':
            return isMisfitsTheme ? 'MMDiscoverSomethingNew' : 'IFDiscoverSomethingNew';
        case 'Staff Picks':
            return 'StaffPicks';
        default:
            return isMisfitsTheme ? 'MMProduce' : 'IFProduce';
    }
};

/**
 * @typedef {Object} Aisle - (impartial typedef, these are just the fields utilized)
 * @property {number} id - The aisle ID
 * @property {string} name - The aisle name
 */

/**
 * @typedef {Object} AisleNav
 * @property {Aisle|undefined}  prevAisle - The previous aisle object (if exists)
 * @property {Aisle}            currentAisle - The current aisle object
 * @property {Aisle|undefined}  nextAisle - The next aisle object (if exists)
 */

/**
 * getPrevAndNextAisles - given an array of "real" aisles, and an optional current aisle index,
 *                        it returns the previous and next aisles for use in navigation
 *
 * @param {Aisle[]} aisles - an array of "real" aisles, doesn't include the Curated/home page
 * @param {number|undefined} currentAisleIndex - 0-based index of current "real" aisle, undefined on Curated/home page
 * @param {boolean|undefined} showBuyItAgainAisle - boolean flag conditioning the display of the "Buy It Again" aisle
 * @param {boolean|undefined} showMemberDealsAisle - boolean flag conditioning the display of the "Member Deals" aisle
 * @returns {AisleNav} - the previous and next aisle objects, if they exist
 */
export function getPrevAndNextAisles(aisles, currentAisleIndex, pathname, showBuyItAgainAisle, showMemberDealsAisle) {
    let prevAisle;
    let currentAisle;
    let nextAisle;

    // currentAisleInde of -1 means we are on a hard-coded aisle
    if (currentAisleIndex === -1) {
        if (matchPath(FEATURED_AISLE.url, pathname)) {
            prevAisle = undefined;
            currentAisle = FEATURED_AISLE;
            nextAisle = showBuyItAgainAisle ? BUY_IT_AGAIN_AISLE : SHOP_WITH_POINTS_AISLE;
        } else if (matchPath(BUY_IT_AGAIN_AISLE.url, pathname)) {
            prevAisle = FEATURED_AISLE;
            currentAisle = BUY_IT_AGAIN_AISLE;
            nextAisle = SHOP_WITH_POINTS_AISLE;
        } else if (matchPath(SHOP_WITH_POINTS_AISLE.url, pathname)) {
            prevAisle = showBuyItAgainAisle ? BUY_IT_AGAIN_AISLE : FEATURED_AISLE;
            currentAisle = SHOP_WITH_POINTS_AISLE;
            nextAisle = showMemberDealsAisle ? MEMBER_DEALS_AISLE : SHOP_ALL_AISLE;
        } else if (matchPath(MEMBER_DEALS_AISLE.url, pathname)) {
            prevAisle = SHOP_WITH_POINTS_AISLE;
            currentAisle = MEMBER_DEALS_AISLE;
            nextAisle = SHOP_ALL_AISLE;
        } else {
            // eslint-disable-next-line prefer-destructuring
            nextAisle = aisles[0];
            currentAisle = SHOP_ALL_AISLE;
            prevAisle = showMemberDealsAisle ? MEMBER_DEALS_AISLE : SHOP_WITH_POINTS_AISLE;
        }
    } else {
        const isFirstAisle = currentAisleIndex === 0;
        const isLastAisle = currentAisleIndex === aisles.length - 1;

        nextAisle = isLastAisle ? undefined : aisles[currentAisleIndex + 1];
        currentAisle = aisles[currentAisleIndex];
        prevAisle = isFirstAisle ? SHOP_ALL_AISLE : aisles[currentAisleIndex - 1];
    }
    return {
        prevAisle,
        currentAisle,
        nextAisle,
    };
}

export function isAisleActive(aisleName, location) {
    return location.pathname.includes(`${AISLE_PATH_PREFIX}/${getEncodedPathSegment(aisleName).toLowerCase()}`);
}

/**
 * Used for filtering out banners that shouldn't be shown
 *
 * @param {Object} banner - the banner object, initially hard-coded in /src/data/banners.js
 * @param {Array} aisles - an array of shop aisle objects, as returned from useAislesQuery()
 * @param {number} curatedListCount - count of carousels of type LIST
 * @param {boolean} isWineEligible=false - whether the customer is eligible for wine
 * @returns {boolean} - false if the banner should be hidden, else true
 */
export function shouldShowBanner(banner, aisles, curatedListCount, isWineEligible = false) {
    const hideWine = banner.id === 1 && !isWineEligible;
    const oddsAndEndsAisle = aisles?.find((a) => a.name === 'Odds & Ends');
    const hideOddsAndEnds = banner.id === 2 && (!oddsAndEndsAisle || oddsAndEndsAisle.inStockCount === 0);
    return !(hideWine || hideOddsAndEnds);
}

/**
 * Builds a list of breadcrumb data for a given aisle ID.
 * @param {string} [customAisle] - Breadcrumb data for a custom aisle.
 * @param {(string|null)} aisleID - ID of the parent aisle.
 * @param {Object} aisles - Aisles data from the /aisles endpoint.
 * @example
 * // Returns:
 * // [
 * //     { id: 1, title: "Home", url: "/shop" },
 * //     { id: 2, title: "Dairy & Eggs", url: "/shop/aisle/dairy+%26+eggs/all" },
 * //     { id: 3, title: "Milk", url: "/shop/aisle/dairy+%26+eggs/milk" },
 * // ]
 * const aisleBreadcrumbs = getAisleBreadcrumbs({
 *     aisles: [
 *         { id: 123, name: "Vegetables", aisles: [] },
 *         {
 *             id: 456,
 *             name: "Dairy & Eggs",
 *             aisles: [
 *                 { id: 789, name: "Milk", aisles: [] }
 *             ]
 *         },
 *     ],
 *     aisleID: 789,
 * });
 * @returns {number} The percent off original price.
 */
export const getAisleBreadcrumbs = ({ aisleID, aisles = [], customAisle } = {}) => {
    let breadcrumbs = [{ id: 1, title: 'Home', url: '/shop' }];

    if (customAisle) {
        return [...breadcrumbs, { id: 2, ...customAisle }];
    }

    aisles.forEach((aisle) => {
        aisle.aisles.forEach((subAisle) => {
            if (subAisle.id === aisleID) {
                breadcrumbs = [
                    ...breadcrumbs,
                    {
                        id: 2,
                        title: aisle.name,
                        url: `/shop/aisle/${getEncodedPathSegment(aisle.name.toLowerCase())}/all`,
                    },
                    {
                        id: 3,
                        title: subAisle.name,
                        url: `/shop/aisle/${getEncodedPathSegment(aisle.name.toLowerCase())}/${getEncodedPathSegment(
                            subAisle.name.toLowerCase()
                        )}`,
                    },
                ];
            }
        });
        if (aisle.id === aisleID) {
            breadcrumbs = [
                ...breadcrumbs,
                {
                    id: 2,
                    title: aisle.name,
                    url: `/shop/aisle/${getEncodedPathSegment(aisle.name.toLowerCase())}/all`,
                },
            ];
        }
    });

    return breadcrumbs;
};

/**
 * Sorts sold out products to the end of the array.
 * @param {array} products - An array of products with sold out products interspersed
 * @returns {array} An array of products with sold out products at the end of the array
 * const sortedProducts = sortSoldOutProductsLast([
 *      { name: "Cantaloupe", soldOut: true },
 *      { name: "Pink Lady Apples", soldOut: false },
 *      { name: "Asian Pears", soldOut: false },
 *      { name: "Lemons", soldOut: false },
 * ]);
 * // Returns:
 * //   [
 * //     { name: "Pink Lady Apples", soldOut: false },
 * //     { name: "Asian Pears", soldOut: false },
 * //     { name: "Lemons", soldOut: false },
 * //     { name: "Cantaloupe", soldOut: true },
 * //   ]
 */
export const sortSoldOutProductsLast = (products = []) => {
    const oosProducts = [];
    const inStockProducts = [];
    products
        .filter((product) => product)
        .forEach((product) => (product?.soldOut ? oosProducts.push(product) : inStockProducts.push(product)));
    return inStockProducts.concat(oosProducts);
};

/**
 * Scroll to an L2 <section /> element on an aisle page.
 * @param {boolean} mobile - Whether or not the screen is mobile-sized (sm). This is used to calculate necessary offsets.
 * @param {string} selector - A querySelector selector. For example `#aisle-250`
 */
export const scrollToL2AisleElement = ({ mobile = true, selector }) => {
    const aisleElement = document.querySelector(selector);

    if (!aisleElement) return;

    const aisleElementTopPosition = aisleElement?.getBoundingClientRect().top;
    const documentTopPosition = document.body.getBoundingClientRect().top;

    let offset = 160;

    if (mobile) {
        offset = aisleElementTopPosition > 0 ? 20 : 140; // TODO: less when scrolling up
    }

    window.scrollTo({
        top: aisleElementTopPosition - documentTopPosition - offset,
    });
};

/**
 * format response from /items endpoint to match data structure
 * of sub aisle with array of all items in aisle
 * @param {Object} response - response from the /items endpoint.
 * @example
 * // Returns:
 * // {
    "msg": "Success",
    "aisle": {
        "id": 283,
        "name": "Meat & Seafood",
        "level": 1
    },
    "items": [
        {
            "id": 416824,
            "name": "Plant-Based Patties, 8 Oz",
            "new": false,
            "price": 5.19,
            "pointPrice": null,
            "msrp": 4.99,
            "soldOut": false,
            "sale": false,
            "exitSku": false,
            "bestSeller": false,
            "maxQuantity": 10,
            "portion": 1,
            "desc": "The Beyond Burger is a plant-based burger that looks, cooks, and satisfies like beef. It has all the juicy, meaty deliciousness of a traditional burger, but comes with the upsides of a plant-based meal. [Description provided by Beyond Meat.]",
            ...
        },
        ...
    ],
    "bundles": [],
    "packGroupID": 1071,
    "subscriptionID": 774204,
    "chargeID": 152142764,
    "customerID": 873583,
    "banners": [],
    "subAisles": [],
    "carousel": null,
    "id": 283,
    "name": "Meat & Seafood"
}
 */
export const handleBuildAllItemsAisle = ({ aisleResponse }) => {
    if (!aisleResponse) return {};

    const { aisle: aisleData, items, subAisles } = aisleResponse?.data ?? {};
    const { id, name } = aisleData ?? {};

    const subAisle = {
        ...aisleResponse.data,
        carousel: null,
        id,
        name,
    };

    let allItems = [];
    if (subAisles?.length) {
        subAisles.map((a) => a.items.forEach((i) => allItems.push(i)));
    } else {
        allItems = items;
    }

    // filter out duplicate items in the same subaisle
    const allItemsFiltered = allItems?.reduce((acc, currItem) => {
        if (!acc.some((item) => item.id === currItem.id)) {
            acc.push(currItem);
        }
        return acc;
    }, []);

    subAisle.items = allItemsFiltered;
    return subAisle;
};
