import { useQuery } from '@tanstack/react-query';

import { getCredits } from 'api/jalapeno';

import useAccountQuery from './useAccountQuery';

export const CREDITS_QUERY_KEY = 'credits';

const useCreditsQuery = () => {
    const { data: account } = useAccountQuery();

    const query = useQuery({
        queryKey: [CREDITS_QUERY_KEY, account?.subID],
        queryFn: async () => {
            const response = await getCredits(account?.subID);
            return response.data;
        },
        enabled: !!account?.subID,
    });

    return query;
};

export default useCreditsQuery;
