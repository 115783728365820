import clsx from 'clsx';
import PropTypes from 'prop-types';

function OrderBanner({ centered, children, marginClasses, paddingClasses, variant }) {
    return (
        <span
            className={clsx(
                'w-100 text-body-xs block rounded-tl-lg rounded-tr-lg font-grotesk-bold',
                { 'bg-error bg-opacity-50': variant === 'error' },
                { 'bg-lime': variant === 'lime' },
                { 'bg-mango': variant === 'mango' },
                { 'bg-grey-light': variant === 'grey' },
                marginClasses,
                paddingClasses
            )}
        >
            <span className={clsx('flex items-center gap-5', { 'justify-center ': centered })}>{children}</span>
        </span>
    );
}

OrderBanner.propTypes = {
    centered: PropTypes.bool,
    children: PropTypes.node.isRequired,
    marginClasses: PropTypes.string,
    paddingClasses: PropTypes.string,
    variant: PropTypes.oneOf(['error', 'lime', 'mango', 'grey']),
};

OrderBanner.defaultProps = {
    centered: false,
    marginClasses: '-mx-20 -mt-30 mb-20 lg:-mx-30',
    paddingClasses: 'px-20 py-5 lg:px-30',
    variant: 'mango',
};

export default OrderBanner;
