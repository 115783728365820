import PropTypes from 'prop-types';

function MMSpiceUpYourRoutineActive({ className, width }) {
    return (
        <svg width={width} className={className} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="FallFlavors" clipPath="url(#clip0_5617_4184)">
                <g id="Transforming Apple">
                    <path
                        id="Vector"
                        d="M10.6549 9.24192C8.61978 9.59662 6.71349 10.7696 5.62466 12.5642C3.03999 16.8224 3.29417 22.308 4.4431 24.5803C6.40951 28.4715 11.1856 30.6243 15.3227 29.4829C16.0853 29.2722 16.8237 28.9649 17.6034 28.8437C18.9619 28.633 20.3495 29.0913 21.7166 28.9579C28.1894 28.3275 28.3646 21.4072 28.186 18.2307C28.0074 15.0542 25.9242 10.0936 23.1523 8.64489C20.0456 7.02062 18.0311 8.38852 15.7521 8.91355C13.4731 9.43859 12.9236 8.84683 10.6549 9.24192Z"
                        fill="#DD8154"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Vector_2"
                        d="M15.2951 11.9612L14.997 9.54309C14.631 7.81129 13.7634 6.74446 12.7272 6.09788C10.2862 4.57083 6.9076 5.38145 6.9076 5.38145C7.51935 10.356 10.0069 11.8455 12.1156 12.1797C13.8306 12.4519 15.2951 11.9612 15.2951 11.9612Z"
                        fill="#4C884B"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Vector_3"
                        d="M3.20928 13.8095C1.71875 14.1094 0.686447 15.6763 1.08676 17.0344C1.50581 18.4568 3.09856 19.2686 4.57716 19.8275C9.07431 21.5263 13.9496 22.3678 18.8096 22.2846"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Vector_4"
                        d="M22.2019 22.2906C23.7299 22.3054 25.2579 21.6947 26.4423 20.5947"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Vector_5"
                        d="M12.8531 24.6714C25.3179 28.2922 30.7226 22.933 29.5754 20.1673"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Vector_6"
                        d="M24.0368 4.02631C24.0196 6.44076 24.2995 7.48029 25.2097 8.66381C26.12 9.84733 27.2723 10.1845 28.7441 10.1476C27.0834 10.0633 25.6202 11.433 25.0122 13.0151C24.4043 14.5972 24.5674 16.4146 24.5331 18.0512C24.3545 16.3145 24.4129 14.3004 23.4254 12.778C22.6251 11.5436 21.3697 10.8869 19.9648 10.713C23.9406 10.4408 23.9217 5.90168 24.0368 4.02806V4.02631Z"
                        fill="#F2EBD1"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Vector_7"
                        d="M19.7621 12.462C19.7518 14.0933 19.9287 14.139 20.504 14.9379C21.0794 15.7369 21.8093 15.9652 22.7401 15.9406C21.689 15.8844 20.7634 16.8098 20.3787 17.8792C19.994 18.9486 20.097 19.5175 20.0747 20.6238C19.9613 19.4508 19.9991 18.7466 19.374 17.7176C18.8674 16.8836 18.0722 16.4393 17.1843 16.3216C19.7003 16.1373 19.6883 13.7281 19.7621 12.462Z"
                        fill="#F2EBD1"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Vector_8"
                        d="M12.6776 2.02484C12.6405 2.78591 13.2957 4.04229 13.5762 4.77177C14.4668 7.09548 14.9838 9.5628 15.0944 12.0082C15.4544 11.9779 15.7364 11.6733 15.8754 11.3124C16.0144 10.9515 16.0361 10.5343 16.0444 10.1257C16.0913 7.95195 15.8579 5.73636 15.3529 3.57302C15.2035 2.93223 14.8961 0.829586 14.2715 1.19733C13.647 1.56508 12.7087 1.39302 12.6782 2.02649L12.6776 2.02484Z"
                        fill="#2D2D2D"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeMiterlimit="10"
                        strokeLinejoin="round"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_5617_4184">
                    <rect width="30" height="30" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default MMSpiceUpYourRoutineActive;

MMSpiceUpYourRoutineActive.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number.isRequired,
};

MMSpiceUpYourRoutineActive.defaultProps = {
    className: null,
};
