import PropTypes from 'prop-types';

function ContactUsIcon({ width }) {
    return (
        <svg width={width} className="inline-block" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.8062 14.2447C16.8669 14.1181 17.7021 13.1839 17.7021 12.0915C17.7021 12.0915 17.7338 9.87094 17.7021 8.65182C17.6705 7.39708 17.7021 6.33629 17.7021 6.33629C17.7021 5.14487 16.7047 4.15137 15.5172 4.15137H7.47813C6.28672 4.15137 5.29321 5.14883 5.29321 6.33629V12.0598C5.29321 13.2512 6.29068 14.2447 7.47813 14.2447C7.47813 14.2447 8.85954 14.2764 9.34244 14.2447C10.5655 14.1497 11.7846 14.2447 11.7846 14.2447L14.872 16.4969C15.1926 16.7226 15.6439 16.4969 15.6439 16.113V14.2487H15.8062V14.2447Z"
                className="fill-[#F2EBD1] if:fill-oat"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.61622 12.0596V13.9239C4.61622 14.3078 5.06745 14.5334 5.38807 14.3078L8.47545 12.0556C8.47545 12.0556 10.1458 12.0873 10.696 12.0556C11.8874 11.9606 12.7859 12.0556 12.7859 12.0556C13.9773 12.0556 14.9392 11.0898 14.9392 9.8707C14.9392 9.8707 14.8442 9.13052 14.9392 6.75165C14.9708 6.0748 14.9392 4.1155 14.9392 4.1155C14.9392 2.92408 13.9417 1.93058 12.7542 1.93058C12.7542 1.93058 9.57187 2.02557 7.288 1.93058C6.64677 1.89891 4.71518 1.93058 4.71518 1.93058C3.52376 1.93058 2.53026 2.92804 2.53026 4.1155C2.53026 4.1155 2.43526 5.23962 2.53026 6.3677C2.62525 7.23455 2.53026 9.87466 2.53026 9.87466C2.53026 11.0304 3.42877 11.9646 4.55685 12.0596H4.61622Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.83122 8.11288C6.57221 8.11288 6.86298 7.88988 6.86298 7.11869C6.86298 6.32891 6.56283 6.13379 5.83122 6.13379C5.09961 6.13379 4.80884 6.32891 4.80884 7.11869C4.80884 7.91776 5.04333 8.11288 5.83122 8.11288Z"
                fill="#2D2D2D"
            />
            <path
                d="M8.8448 8.11288C9.58579 8.11288 9.87656 7.88988 9.87656 7.11869C9.87656 6.32891 9.57641 6.13379 8.8448 6.13379C8.11318 6.13379 7.82241 6.32891 7.82241 7.11869C7.82241 7.91776 8.05691 8.11288 8.8448 8.11288Z"
                fill="#2D2D2D"
            />
            <path
                d="M11.8584 8.11288C12.5994 8.11288 12.8901 7.88988 12.8901 7.11869C12.8901 6.32891 12.59 6.13379 11.8584 6.13379C11.1268 6.13379 10.836 6.32891 10.836 7.11869C10.836 7.91776 11.0705 8.11288 11.8584 8.11288Z"
                fill="#2D2D2D"
            />
        </svg>
    );
}

export default ContactUsIcon;

ContactUsIcon.propTypes = {
    width: PropTypes.number,
};

ContactUsIcon.defaultProps = {
    width: 20,
};
