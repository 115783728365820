import PropTypes from 'prop-types';

export function EatItAllIconIF({ className, width }) {
    return (
        <svg width={width} height={width} fill="none" className={className}>
            <path
                fill="#fff"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.053"
                d="M15.767 38.761c.56 1.291 2.736 5.598 2.84 6.544.102.946-1.939 1.678-.954 3.08.985 1.404 3.247.8 3.616-.273 1.139-.018 3.582-.185 3.325-2.36-.256-2.174-2.62-1.312-3.058-1.77-.438-.46-2.939-6.407-2.939-6.407l-2.83 1.186Z"
            />
            <path
                fill="#F6A685"
                stroke="#2D2D2D"
                strokeMiterlimit="10"
                strokeWidth="1.053"
                d="M19.71 24.18c1.762 4.055.976 7.585.894 9.496-.142 3.195-.466 5.077-2.637 6.02-1.883.828-4.115-.334-6.54-2.412-1.456-1.243-4.396-3.234-5.99-6.904-2.375-5.454-1.102-11.272 2.84-12.984 3.942-1.711 9.06 1.345 11.434 6.783Z"
            />
            <path
                fill="#547420"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.053"
                d="M25.667 2.73c.634-.36 2.823-2.013 4.84-.198 2.292 2.057.5 6.404.687 8.843.188 2.438 1.281 5.37-.886 9.111-1.463 2.533-6.287 4.45-10.128 3.185-3.842-1.265 5.487-20.942 5.487-20.942Z"
            />
            <path
                fill="#C5DB66"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.053"
                d="M24.85 3.284c-.616.56-4.685 3.703-6.288 4.7-1.604.998-6.169 3.77-5.85 7.865.32 4.096 2.484 6.5 5.649 7.463 3.164.962 6.835.258 8.74-3.271 1.905-3.53 1.407-6.061 1.54-8.943.094-1.915 1.473-4.334 1.555-6.267C30.278 2.9 27.56.935 24.85 3.284Z"
            />
            <path
                fill="#F6A685"
                stroke="#2D2D2D"
                strokeMiterlimit="10"
                strokeWidth="1.053"
                d="M20.12 11.302c-.719.19-4.353 2.071-3.375 5.18.977 3.11 3.978 3.617 5.705 2.623 1.728-.994 2.899-3.083 2.107-5.792-.792-2.708-2.648-2.484-4.437-2.01Z"
            />
            <path
                fill="#F7D46D"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.053"
                d="M14.775 24.41c.762-.659 6.713-4.155 12.21-1.816 5.496 2.339 7.86 5.439 9.773 10.267 2.745-1.186 5.38-1.406 8.418 1.64-1.796-.414-4.557 1.369-5.413 2.972-1.033 1.891-.56 3.917.768 6.04-2.871-.36-6.365-1.824-5.988-6.121a12.808 12.808 0 0 1-14.527-1.063c-5.956-4.707-5.24-11.918-5.24-11.918Z"
            />
            <path
                fill="#ECE7E4"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.053"
                d="M23.42 21.808s1.546-.617 3.458-1.132c1.912-.514 4.735.842 6.487 2.253 1.752 1.41 2.927 2.557 3.069 3.119.141.562-.931 3.794-.931 3.794s-2.68-4.536-6.186-6.087a26.105 26.105 0 0 0-5.896-1.947v0Z"
            />
            <path
                fill="#fff"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.053"
                d="M17.619 33.883c.535-.114 3.756-.413 6.5-3.957 2.744-3.543 1.203-7.665 1.203-7.665s-4.944-1.585-10.545 2.145c-.44 5.16 2.842 9.477 2.842 9.477Z"
            />
            <path
                fill="#F7D46D"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.053"
                d="M20.825 26.212a.787.787 0 1 0 .496-1.494.787.787 0 0 0-.496 1.494Z"
            />
            <path
                fill="#ECE7E4"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.053"
                d="M25.548 26.886s2.65.915 3.398 5.038c-3.745 2.054-6.562-.189-6.562-.189s1.713-.558 3.164-4.85Z"
            />
            <path
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.053"
                d="M31.75 29.258c.856.648.052 2.383-.843 2.264.725.783-.006 2.56-1.209 2.516a1.355 1.355 0 0 1-1.164 2.135M33.444 31.676c.806.581.048 2.386-1.06 2.223.578.894-.03 2.27-1.165 2.135"
            />
        </svg>
    );
}

EatItAllIconIF.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number.isRequired,
};

EatItAllIconIF.defaultProps = {
    className: null,
};
