import PropTypes from 'prop-types';

import IFWelcomeIcon from './IFWelcomeIcon';
import MMWelcomeIcon from './MMWelcomeIcon';

function WelcomeIcon({ isMisfits, width }) {
    return isMisfits ? <MMWelcomeIcon width={width} /> : <IFWelcomeIcon width={width} />;
}

export default WelcomeIcon;

WelcomeIcon.propTypes = {
    isMisfits: PropTypes.bool,
    width: PropTypes.number,
};

WelcomeIcon.defaultProps = {
    isMisfits: true,
    width: 28,
};
