import { ALCOHOL_SERVICE_FEE, MIN_WINE_BOTTLE_QTY_TO_SHIP, MIN_WINE_BOTTLE_QTY_TO_WAIVE_FEE } from 'constants/shop';

import { isDayAfter, parseTime } from './dateUtils';
import { plulurizeWord } from './globalUtils';

/** Functions Index
 * displayReportIssue
 * filterPerkSelections
 * generateUpcomingOrderObject
 * getAppliedCredits - DEPRECATED
 * getAppliedDiscounts - DEPRECATED
 * getCartSubTotal - DEPRECATED
 * getCartTotal - DEPRECATED
 * getColdPackItems
 * getDiscountDollarValue
 * getGroceryItems
 * getMSRP
 * getNextActiveCharge
 * getOrderMin
 * getRefundAmount
 * getRemainingTimeToEditOrderFormatted
 * getTotalProductQty
 * getTotalWineBottleQty
 * getWineItems
 * hasAnyIssueReported
 * hasItemIssueReported
 * hasOrderIssueReported
 * hasOtherIssueReported
 * hasShipmentIssueReported
 * individualizePerkSelections
 * itemHasIssueReported,
 */

/**
 * Returns selections data without perk products.
 * @param {object} selections -  Selections data from the /selections endpoint.
 * @returns {number} Selections data stripped of perk products.
 */
export const filterPerkSelections = (selections) => ({
    ...selections,
    selected: selections?.selected?.filter((selection) => !selection.isPerk),
});

/**
 * Returns existing selections with perk products expanded into individual list items.
 * @param {object} selections -  Selections data from the /selections endpoint.
 * @returns {number} Selections data with individualized perk products.
 */
export const individualizePerkSelections = (selections) => ({
    ...selections,
    selected: selections.selected.reduce((acc, selection) => {
        if (selection.isPerk) {
            return [
                ...acc,
                ...[...Array(selection.qty)].map(() => ({
                    ...selection,
                    qty: 1,
                })),
            ];
        }
        return [...acc, selection];
    }, []),
});

/**
 * Calculates the subtotal for a list of products.
 * @param {Selection[]} selections - A list of products.
 * @param {boolean} filterPerks - True if filtering out perk items from total.
 * @returns {number} The subtotal for a list of products.
 *
 * @deprecated Migrate to `getProductPrice` in `priceUtils`.
 */
export const getItemsTotalCost = (selections = [], filterPerks = true) => {
    const products = filterPerks ? selections.filter((product) => !product.isPerk) : selections;
    return products.reduce((total, product) => total + product.price * product.qty, 0);
};

/**
 * Calculates the total MSRP for a list of products.
 * @param {Selection[]} selections - A list of products.
 * @param {boolean} filterPerks - True if filtering out perk items from total.
 * @returns {number} The total MSRP for a list of products.
 */
export const getMSRP = (selections = [], filterPerks = true) => {
    const products = filterPerks ? selections.filter((product) => !product.isPerk) : selections;

    return products.reduce(
        (total, product) =>
            total +
            (product.msrp * product.portion > product.price
                ? product.msrp * product.qty * product.portion
                : product.price * product.qty),
        0
    );
};

/**
 * Calculates the total quantity of products.
 * @param {Selection[]} products - A list of products.
 * @returns {number} The total quantity of products.
 */
export const getTotalProductQty = (products) =>
    products?.length > 0 ? products.reduce((total, product) => total + product.qty, 0) : 0;

/**
 * Returns the order minimum (minimum order value).
 * @param {object} account - Account data from the /account endpoint.
 * @returns {number} The order minimum (minimum order value).
 */
export const getOrderMin = (account) =>
    account?.nextOrder?.cartExperience?.orderMinimumOverridden
        ? account?.nextOrder?.cartExperience?.orderMinimum
        : account?.cartMinimum || 0;

/**
 * Returns all cold packed products for an order.
 * @param {object} selections - Selections data from the /selections endpoint.
 * @returns {Selection[]} A list of cold packed products for an order.
 */
export const getColdPackItems = (selections) => selections?.selected?.filter((product) => product.coldPacked) || [];

/**
 * Returns all grocery products for an order.
 * @param {object} selections - Selections data from the /selections endpoint.
 * @param {bool} filterColdPackItems - filter out cold pack items
 * @returns {Selection[]} A list of grocery products for an order.
 */
export const getGroceryItems = (selections, filterColdPackItems = false) =>
    selections?.selected?.filter((selection) =>
        filterColdPackItems ? !selection.coldPacked && !selection.isDrinksItem : !selection.isDrinksItem
    );

/**
 * Returns all wine products for an order.
 * @param {object} selections - Selections data from the /selections endpoint.
 * @returns {Selection[]} A list of wine products for an order.
 */
export const getWineItems = (selections) =>
    selections?.selectedBundles?.concat(selections?.selected?.filter((selection) => selection.isDrinksItem));

/**
 * Calculates the total number of wine bottles in an order.
 * @param {object} selections - Selections data from the /selections endpoint.
 * @returns {number} The total number of wine bottles in an order.
 */
export const getTotalWineBottleQty = (selections) => {
    if (selections?.selectedBundles?.length > 0 || selections?.selected?.filter((sel) => sel.isDrinksItem).length > 0) {
        return (
            selections.selectedBundles.reduce((total, product) => total + product.numDrinksItems * product.qty, 0) +
            selections.selected.filter((sel) => sel.isDrinksItem).reduce((total, product) => total + product.qty, 0)
        );
    }

    return 0;
};

/**
 * Get the dollar value for a discount.
 * @param {object} discount - The discount to get a dollar value for.
 * @param {number} subtotal - The subtotal that the discount may be applied to.
 * @param {number} shipping - The shipping that the discount may be applied to.
 * @returns {number} The dollar value of the discount.
 */
export const getDiscountDollarValue = (discount, subtotal, shipping) => {
    if (discount.dollarDiscount > 0) {
        return discount.dollarDiscount;
    }

    if (discount.percentDiscount > 0) {
        const projectedValue = subtotal * (discount.percentDiscount / 100);

        if (projectedValue <= discount.percentDiscountDollarMaximum) {
            return projectedValue;
        }
        return discount.percentDiscountDollarMaximum;
    }

    if (discount.percentShippingDiscount > 0) {
        return shipping * (discount.percentShippingDiscount / 100);
    }

    if (discount.creditDollars > 0) {
        return 0;
    }

    return 0;
};

/**
 * Calculates the subtotal price for all groceries in the cart.
 * @param {object} account - Account data from the /account endpoint.
 * @param {object} selections - Selections data from the /selections endpoint.
 * @returns {number} The subtotal price for all groceries in the cart.
 */
export const getGrocerySubTotal = (account, selections) => {
    const groceryItems = getGroceryItems(selections);
    const grocerySubtotal = getItemsTotalCost(groceryItems);
    const groceryMinMet = account?.cartMinimum <= grocerySubtotal;

    return groceryMinMet ? grocerySubtotal : 0;
};

/**
 * Calculates the subtotal price for all wine in the cart.
 * @param {object} selections - Selections data from the /selections endpoint.
 * @returns {number} The subtotal price for all wine in the cart.
 */
export const getWineSubTotal = (selections) => {
    const wineItems = getWineItems(selections);
    const totalWineBottleQty = getTotalWineBottleQty(selections);
    const wineSubtotal = getItemsTotalCost(wineItems);

    return totalWineBottleQty >= MIN_WINE_BOTTLE_QTY_TO_SHIP ? wineSubtotal : 0;
};

/**
 * Calculates the subtotal price for all items in the cart.
 * @param {object} account - Account data from the /account endpoint.
 * @param {object} selections - Selections data from the /selections endpoint.
 * @returns {number} The subtotal price for all items in the cart.
 *
 * @deprecated Migrate to `getCartSubtotal` from `priceUtils`.
 */
export const getCartSubTotal = (account, selections) =>
    getGrocerySubTotal(account, selections) + getWineSubTotal(selections);

/**
 * Get discounts that applied to the current cart, as well as their dollar values.
 * @param {object} discounts - Discount data from the /ladder endpoint.
 * @param {object} selections - Selections data from the /selections endpoint.
 * @param {object} account - Account data from the /account endpoint.
 * @returns {Discount[]} A list of applied discounts, including their dollar value.
 *
 * @deprecated Migrate to `getCartAppliedCredits` in `priceUtils`.
 */
export const getAppliedDiscounts = (discounts, selections, account) => {
    const { thresholdDiscounts } = discounts;
    const { shipping } = selections;
    const { nextOrder } = account;
    const freeShippingEligible = nextOrder?.cartExperience?.freeShippingEligible;
    const freeShippingThreshold = nextOrder?.cartExperience?.freeShippingThreshold;

    const cartSubtotal = getCartSubTotal(account, selections);
    const grocerySubtotal = getGrocerySubTotal(account, selections);
    const hasFreeShipping = freeShippingEligible && grocerySubtotal >= freeShippingThreshold;

    // Filter discounts, keeping discounts with a threshold less than the subtotal && don't include discounts that are shipping discounts where the customer already has free shipping
    const validDiscounts =
        thresholdDiscounts?.filter(
            (discount) =>
                discount.thresholdDollars <= cartSubtotal && !(discount.percentShippingDiscount > 0 && hasFreeShipping)
        ) ?? [];

    return validDiscounts.map((discount) => ({
        ...discount,
        dollarValue: getDiscountDollarValue(discount, cartSubtotal, shipping),
    }));
};

/**
 * Calculates the total amount of credits applied to the cart.
 * @param {object} account - Account data from the /account endpoint.
 * @param {object} discounts - Discount data from the /ladder endpoint.
 * @param {object} credits - Credits data from the /credits endpoint.
 * @param {object} selections - Selections data from the /selections endpoint.
 * @returns {number} The total amount of credits applied to the cart.
 *
 * @deprecated Migrate to `getCartAppliedCredits` in `priceUtils`.
 */
export const getAppliedCredits = (account, credits, discounts, selections) => {
    const { nextOrder } = account;
    const { freeShippingEligible, freeShippingThreshold } = nextOrder.cartExperience;
    const { sumCreditBalance } = credits;
    const { estimatedGroceryTax, shipping } = selections;

    const groceryItems = getGroceryItems(selections);
    const grocerySubtotal = getGrocerySubTotal(account, selections);
    const diffToFreeShipping = freeShippingThreshold - grocerySubtotal;
    const appliedDiscounts = getAppliedDiscounts(discounts, selections, account);

    let creditValidTotal = 0;

    if (grocerySubtotal) {
        creditValidTotal += grocerySubtotal;
    }

    if (estimatedGroceryTax) {
        creditValidTotal += estimatedGroceryTax;
    }

    if (groceryItems?.length > 0 && (!freeShippingEligible || (freeShippingEligible && diffToFreeShipping > 0))) {
        creditValidTotal += shipping;
    }

    if (appliedDiscounts?.length > 0) {
        appliedDiscounts.forEach((discount) => {
            creditValidTotal -= discount.dollarValue;
        });
    }

    return Math.min(creditValidTotal, sumCreditBalance);
};

/**
 * Calculates the total price for all grocery in the cart including tax, shipping, and discounts.
 * @param {object} account - Account data from the /account endpoint.
 * @param {object} credits - Credits data from the /credits endpoint.
 * @param {object} discounts - Discount data from the /ladder endpoint.
 * @param {object} selections - Selections data from the /selections endpoint.
 * @returns {number} The total price for all grocery in the cart.
 */
export const getGroceryTotal = (account, credits, discounts, selections) => {
    const { nextOrder, proteinMinimum } = account;
    const { coldKeeperFee, freeShippingEligible, freeShippingThreshold } = nextOrder?.cartExperience ?? {};
    const { sumCreditBalance } = credits;
    const { chargeTipAmount, estimatedGroceryTax, shipping } = selections;

    const groceryItems = getGroceryItems(selections);
    const coldPackItems = getColdPackItems(selections);
    const grocerySubtotal = getItemsTotalCost(groceryItems);
    const coldPackSubtotal = getItemsTotalCost(coldPackItems);
    const groceryMinMet = account?.cartMinimum <= grocerySubtotal;
    const diffToColdPackMin = proteinMinimum - coldPackSubtotal;
    const diffToFreeShipping = freeShippingThreshold - grocerySubtotal;
    const appliedDiscounts = getAppliedDiscounts(discounts, selections, account);

    let total = getGrocerySubTotal(account, selections);

    if (estimatedGroceryTax && groceryMinMet) {
        total += estimatedGroceryTax;
    }

    if (coldKeeperFee > 0 && coldPackItems?.length > 0 && diffToColdPackMin > 0) {
        total += coldKeeperFee;
    }

    if (groceryMinMet && (!freeShippingEligible || (freeShippingEligible && diffToFreeShipping > 0))) {
        total += shipping;
    }

    if (chargeTipAmount > 0) {
        total += chargeTipAmount;
    }

    if (appliedDiscounts?.length > 0) {
        appliedDiscounts.forEach((discount) => {
            total -= discount.dollarValue;
        });
    }

    if (sumCreditBalance > 0) {
        total -= getAppliedCredits(account, credits, discounts, selections);
    }

    if (total > 0) {
        return Number(total.toFixed(2));
    }

    return 0;
};

/**
 * Calculates the total price for wine including tax, shipping, and discounts.
 * @param {object} account - Account data from the /account endpoint.
 * @param {object} credits - Credits data from the /credits endpoint.
 * @param {object} discounts - Discount data from the /ladder endpoint.
 * @param {object} selections - Selections data from the /selections endpoint.
 * @returns {number} The total price for all orders in the cart.
 */
export const getWineTotal = (selections) => {
    const { estimatedAlcoholTax } = selections;

    const totalWineBottleQty = getTotalWineBottleQty(selections);
    const wineDiscount = selections?.alcoholDiscountAmount;

    let total = getWineSubTotal(selections);

    if (estimatedAlcoholTax && totalWineBottleQty >= MIN_WINE_BOTTLE_QTY_TO_SHIP) {
        total += estimatedAlcoholTax;
    }

    if (totalWineBottleQty >= MIN_WINE_BOTTLE_QTY_TO_SHIP && totalWineBottleQty < MIN_WINE_BOTTLE_QTY_TO_WAIVE_FEE) {
        total += ALCOHOL_SERVICE_FEE;
    }

    if (wineDiscount > 0) {
        total -= wineDiscount;
    }

    return total;
};

/**
 * Calculates the total price for all items in the cart.
 * @param {object} account - Account data from the /account endpoint.
 * @param {object} credits - Credits data from the /credits endpoint.
 * @param {object} discounts - Discount data from the /ladder endpoint.
 * @param {object} selections - Selections data from the /selections endpoint.
 * @returns {number} The total price for all items in the cart.
 *
 * @deprecated Migrate to `getCartTotal` located in `priceUtils`.
 */
export const getCartTotal = (account, credits, discounts, selections) =>
    getGroceryTotal(account, credits, discounts, selections) + getWineTotal(selections);

/**
 * Returns a formatted string describing the time remaining for a user to edit
 * their order.
 * @param {object} timeObject - object containing hours, minutes and seconds.
 * @returns {string} Display string describing the time remaining for a user
 *                   to edit their order.
 */
export const getRemainingTimeToEditOrderFormatted = (timeObject) => {
    if (!timeObject) return undefined;

    const { days, hours, minutes } = timeObject ?? {};

    // Less than an hour left: only report minutes
    if (days === 0 && hours === 0) return `${minutes} ${plulurizeWord('Minute', minutes)}`;

    // Less than a day left: report hours and minutes
    if (days === 0 && hours >= 1)
        return `${hours} ${plulurizeWord('Hour', hours)} ${minutes} ${plulurizeWord('Minute', minutes)}`;

    // A day or more left, report days and hours
    return `${days} ${plulurizeWord('Day', days)} ${hours} ${plulurizeWord('Hour', hours)} ${minutes} ${plulurizeWord(
        'Minute',
        minutes
    )}`;
};

/**
 * check if order is less than 14 days old
 * @param {orderDate} - Date string ex: '2023-04-12 16:00:00.000000'
 * @example
 * const displayReportIssue = displayReportIssue('2020-08-17 17:20:32.402937');
 * @returns {boolean} True if order fall within 14 day range, false otherwise
 */
export const displayReportIssue = (orderDate) =>
    orderDate ? isDayAfter(new Date().toISOString(), orderDate, -14) : false;

/**
 * return item automated refund amount
 * @param {item} - item from the order endpoint
 * @param {orderFeedback} - customerOrderFeedback from the order endpoint
 * @example
 * const itemRefund = getRefundAmount(item, orderFeedback);
 * @returns {number} returns automated refund amount
 */
export const getRefundAmount = (item, orderFeedback) => {
    const itemRefund = orderFeedback?.itemsReported?.filter(
        (feedback) => feedback?.model?.sellableItemID === item?.id && feedback?.automatedRefunds?.type === 'MM_CREDIT'
    );
    return itemRefund?.length > 0 ? itemRefund[0].automatedRefunds.value : 0;
};

/**
 * check if order has item issue reported
 * @param {orderFeedback} - customerOrderFeedback from the order endpoint
 * @param {drinkOrderFeedback} - customerDrinksOrderFeedback from the order endpoint
 * @example
 * const itemIssueReported = hasItemIssueReported(orderFeedback, drinksOrderFeedback);
 * @returns {boolean} True if order has item issue reported
 */
export const hasItemIssueReported = (orderFeedback, drinksOrderFeedback) =>
    orderFeedback?.hasItemsReported || drinksOrderFeedback?.hasItemsReported;

/**
 * check if order has shipping issue reported
 * @param {orderFeedback} - customerOrderFeedback from the order endpoint
 * @param {drinkOrderFeedback} - customerDrinksOrderFeedback from the order endpoint
 * @example
 * const shipmentIssueReported = hasShipmentIssueReported(orderFeedback, drinksOrderFeedback);
 * @returns {boolean} True if order has shipping issue reported
 */
export const hasShipmentIssueReported = (orderFeedback, drinksOrderFeedback) =>
    orderFeedback?.shipmentIssueReported || drinksOrderFeedback?.deliveryIssueReported;

/**
 * check if order has other issue reported
 * @param {orderFeedback} - customerOrderFeedback from the order endpoint
 * @param {drinkOrderFeedback} - customerDrinksOrderFeedback from the order endpoint
 * @example
 * const otherIssueReported = hasOtherIssueReported(orderFeedback, drinksOrderFeedback);
 * @returns {boolean} True if order has other issue reported
 */
export const hasOtherIssueReported = (orderFeedback, drinksOrderFeedback) =>
    orderFeedback?.hasIssueOtherReported || drinksOrderFeedback?.hasIssueOtherReported;

/**
 * check if order has any issue reported
 * @param {orderFeedback} - customerOrderFeedback from the order endpoint
 * @param {drinkOrderFeedback} - customerDrinksOrderFeedback from the order endpoint
 * @example
 * const anyIssueReported = hasAnyIssueReported(orderFeedback, drinksOrderFeedback);
 * @returns {boolean} True if order has any issue reported
 */
export const hasAnyIssueReported = (orderFeedback, drinksOrderFeedback) =>
    hasItemIssueReported(orderFeedback, drinksOrderFeedback) ||
    hasShipmentIssueReported(orderFeedback, drinksOrderFeedback) ||
    hasOtherIssueReported(orderFeedback, drinksOrderFeedback);

/**
 * check if single order has any issue reported (hasAnyIssueReported will check entire order)
 * @param {orderFeedback} - customerOrderFeedback from the order endpoint
 * @example
 * const orderIssueReported = hasOrderIssueReported(orderFeedback);
 * const drinksOrderIssueReported = hasOrderIssueReported(drinksOrderFeedback);
 * @returns {boolean} True if order has any issue reported
 */
export const hasOrderIssueReported = (orderFeedback) =>
    orderFeedback?.hasItemsReported ||
    orderFeedback?.shipmentIssueReported ||
    orderFeedback?.deliveryIssueReported ||
    orderFeedback?.hasIssueOtherReported;

/**
 * check if item has issue reported
 * @param {item} - item from the order endpoint
 * @param {orderFeedback} - customerOrderFeedback from the order endpoint
 * @param {drinkOrderFeedback} - customerDrinksOrderFeedback from the order endpoint
 * @example
 * const itemIssueReported = itemHasIssueReported(item, orderFeedback, drinksOrderFeedback);
 * @returns {boolean} True item has issue reported
 */
export const itemHasIssueReported = (item, orderFeedback, drinksOrderFeedback) =>
    (item.refundHistory?.length && item.refundHistory[0]?.qty > 0) ||
    orderFeedback?.itemsReported?.filter(
        (feedback) =>
            feedback.model.sellableItemID === item.id &&
            ((item.isPerk && item.totalQty === feedback.model.quantity) || !item.isPerk)
    ).length > 0 ||
    drinksOrderFeedback?.itemsReported?.filter((feedback) => feedback.drinksOrderDetailID === item.drinksOrderDetailID)
        .length > 0 ||
    item?.items?.filter(
        (wineBottle) =>
            drinksOrderFeedback?.itemsReported?.filter(
                (feedback) => feedback.drinksOrderDetailID === wineBottle.drinksOrderDetailID
            )?.length > 0
    ).length > 0;

/**
 * Builds upcoming order with data structure to match past orders.
 * @param {object} account - Account data from the /account endpoint.
 * @param {object} credits - Credits data from the /credits endpoint.
 * @param {object} discounts - Discount data from the /ladder endpoint.
 * @param {object} selections - Selections data from the /selections endpoint.
 * @returns {object} Order data
 */
export const generateUpcomingOrderObject = (account, credits, discounts, selections) => ({
    id: 1,
    charge: {
        coldKeeperFee: selections?.coldKeeperFee || 0,
        coldKeeperFeeTaxAmount: 0,
        credits: credits?.sumCreditBalance || 0,
        discountAmount: 0,
        discountCode: '',
        shipping: selections?.shipping || 0,
        subtotal: getCartSubTotal(account, selections) || 0,
        taxes: selections.estimatedTax || 0,
        total: getCartTotal(account, credits, discounts, selections) || 0,
    },
    productID: 6,
    shipments: [
        {
            items: getGroceryItems(selections) || [],
        },
    ],
    drinksShipments: [
        {
            items: getWineItems(selections) || [],
        },
    ],
    drinksBundles: [],
    refundHistory: {
        totalRefund: 0,
        creditsForLoyaltyRefunds: 0,
        totalStoreCreditFromAutomatedRefunds: 0,
    },
    loyaltyRewardInfo: {
        pointsEarned: selections?.estimatedLoyaltyPoints || 0,
    },
});

/**
 * Returns the next active charge for a user.
 * @param {object} array - Array of charges to filter
 * @returns {object} The next active charge in the array.
 */
export const getNextActiveCharge = (charges = []) =>
    charges?.filter((charge) => charge.status === 'SCHEDULED')[0] ?? {};

/**
 * Returns bool for if an order has been delivered for more than 24 hours.
 * @param {string} status - Current order status ex ('DELIVERED', 'PENDING')
 * @param {string} deliverDate - Order delivery date
 * @returns {boolean} Whether or not the order has been delivered for 24 hours
 */
export const hasBeenDeliveredForOneDay = (status, deliverDate) => {
    if (!deliverDate) return false;

    const deliveryDate = parseTime(deliverDate);
    const oneDay = 1000 * 60 * 60 * 24;
    const dayAgo = new Date().getTime() - oneDay;
    const isDeliveryWithinPreviousDay = deliveryDate.getTime() > dayAgo;

    return status === 'DELIVERED' && !isDeliveryWithinPreviousDay;
};

/**
 * Returns the sum of all taxes on the order
 * @param {object} order charges - Charges data from /api/order/v4/
 * @param {object} order.charge - Charge (non-drink) data for the order
 * @param {object} order.drinksCharge - Drinks charge data for the order
 * @returns {number} orderTax
 */
export const getOrderTax = ({ charge = {}, drinksCharge = {} } = {}) => {
    /*
        orders/v4/ tax data notes:

        It is believed that order.charge.taxes and order.drinksCharge.taxes
        are inclusive of all order taxes (shipping tax, cold keeper tax, etc.).

        However, this behavior is also believed to have changed at some unknown time
        in the past which may lead to "older" orders where charges.taxes does not include all taxes
        and this summation may not be accurate for those orders.
    */
    const orderTax = (charge.taxes ?? 0) + (drinksCharge.taxes ?? 0);
    return orderTax;
};

/**
 * Returns whether or not the operations fee should be shown
 * @param {Object} cartExperience - Cart experience data from the `api/customer/v2/account` endpoint.
 * @returns {boolean} Whether or not the operations fee should be shown
 */
export const getShowOperationsFee = ({ cartExperience }) => {
    const { operationsFee = 0, operationsFeeEnabled = false } = cartExperience ?? {};
    return operationsFee > 0 && operationsFeeEnabled;
};
