import PropTypes from 'prop-types';

function MMThanksgivingEssentialsInactive({ className, width }) {
    return (
        <svg width={width} className={className} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24.1559 17.0507C23.5186 17.6212 22.9739 18.3705 22.7413 18.8356C22.315 19.6861 21.9727 21.1244 21.5851 21.4323C21.1975 21.7402 11.2221 24.0117 10.7699 24.0483C10.3178 24.0849 10.0249 23.5833 10.0249 23.5833C9.89576 22.8081 9.62662 20.7433 10.2338 19.2167C10.8431 17.688 12.0984 16.465 13.485 16.0129C14.8716 15.5607 17.9205 15.8019 18.8592 15.6339C19.475 15.5219 22.0265 13.9179 22.0265 13.9179C23.1224 16.4758 24.1559 17.0507 24.1559 17.0507Z"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.7696 24.0477C10.2809 24.1661 3.75474 25.7292 3.30689 25.7809C2.85904 25.8326 1.48965 25.8757 1.11931 24.5838C0.748973 23.2919 1.29802 21.7933 1.98056 20.5337C2.73631 19.1385 5.22318 15.3878 8.21388 13.7212C11.2046 12.0547 14.1651 11.3657 16.9254 11.9234C19.7568 12.494 21.4535 13.2023 22.4181 13.62"
                fill="#FFF9EA"
            />
            <path
                d="M10.7696 24.0477C10.2809 24.1661 3.75474 25.7292 3.30689 25.7809C2.85904 25.8326 1.48965 25.8757 1.11931 24.5838C0.748973 23.2919 1.29802 21.7933 1.98056 20.5337C2.73631 19.1385 5.22318 15.3878 8.21388 13.7212C11.2046 12.0547 14.1651 11.3657 16.9254 11.9234C19.7568 12.494 21.4535 13.2023 22.4181 13.62"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.223 21.5703C22.3017 21.3098 24.6164 20.7586 25.3743 20.5648C26.2032 20.3538 27.2755 20.1514 26.9245 18.9909C26.5671 17.8109 26.0633 17.081 25.2967 16.3834"
                fill="#FFF9EA"
            />
            <path
                d="M21.223 21.5703C22.3017 21.3098 24.6164 20.7586 25.3743 20.5648C26.2032 20.3538 27.2755 20.1514 26.9245 18.9909C26.5671 17.8109 26.0633 17.081 25.2967 16.3834"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M25.6069 16.6856L25.2796 16.3325C25.2796 16.3325 21.0896 18.4663 21.5849 21.429" fill="#FFF9EA" />
            <path
                d="M25.6069 16.6856L25.2796 16.3325C25.2796 16.3325 21.0896 18.4663 21.5849 21.429"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M23.1761 10.6815C23.1201 10.324 22.5517 10.3693 22.2524 10.4425C22.0586 9.21087 21.3717 8.51756 19.9744 8.71995C18.577 8.92235 18.676 10.7224 18.8095 11.2757C18.7557 11.4544 18.3961 11.7752 17.9138 12.1241L22.4203 13.6183C22.4203 13.6183 23.6562 13.6743 23.1782 10.6815H23.1761Z"
                fill="#FFF9EA"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.0249 23.5826C9.89576 22.8075 9.62662 20.7426 10.2338 19.2161C10.8431 17.6873 12.0984 16.4644 13.485 16.0122C14.8716 15.56 17.9205 15.8012 18.8592 15.6332C19.798 15.4653 22.8533 13.549 23.0256 12.9741C22.877 12.3605 22.7672 10.3602 24.3196 10.1341C25.872 9.90807 26.6342 10.6789 26.8517 12.0483C27.9928 11.7727 29.5194 13.8031 28.6431 14.9356C27.7668 16.0682 26.9679 16.3179 25.8009 16.2555C25.633 16.2469 25.4564 16.277 25.2799 16.3352C24.2313 16.6904 23.1052 18.1072 22.7413 18.8349C22.315 19.6854 21.9727 21.1237 21.5851 21.4316C21.5485 21.4618 21.4215 21.5091 21.2234 21.5716C19.3631 22.1572 11.179 24.0154 10.7699 24.0477C10.3178 24.0843 10.0249 23.5826 10.0249 23.5826Z"
                fill="#FFF9EA"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.5441 14.2442C22.3559 13.7145 23.0427 13.1655 23.1224 12.9006C22.9738 12.287 22.864 10.2867 24.4164 10.0606C25.971 9.83457 26.731 10.6054 26.9485 11.9748C28.0897 11.6992 29.6162 13.7296 28.7399 14.8621C27.8636 15.9947 27.0648 16.2444 25.8978 16.182C25.7298 16.1734 25.5533 16.2035 25.3767 16.2617C24.9095 16.4188 24.4293 16.787 24.0052 17.2112L21.542 14.242L21.5441 14.2442Z"
                fill="#FFF9EA"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.5219 17.0555C15.1602 16.6162 13.2805 17.0662 12.4795 18.0373C11.6785 19.0084 11.4352 19.7856 11.474 20.0634C11.5128 20.3411 12.0855 20.9225 12.4128 20.6124C12.74 20.3024 12.542 19.3722 13.1987 18.8124C13.8554 18.2504 14.8609 18.0071 15.242 17.9146C15.6231 17.822 15.8083 17.4021 15.5219 17.0533V17.0555Z"
                fill="#FFF9EA"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.60241 12.1582C7.07274 11.8266 6.31268 11.4175 6.99307 10.0503C7.67346 8.68306 6.86604 8.61847 6.50216 8.13616C6.13828 7.65386 6.40312 6.82275 6.40312 6.82275"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.8172 10.4181C12.3198 10.0391 11.6028 9.56111 12.4081 8.26278C13.2134 6.96444 12.4145 6.82448 12.098 6.31204C11.7815 5.79959 12.1217 4.99432 12.1217 4.99432"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.111 10.9752C9.56625 10.4628 8.78897 9.82758 9.42199 7.82086C10.055 5.81414 9.24113 5.68711 8.85787 4.9572C8.47462 4.22728 8.70931 3 8.70931 3"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default MMThanksgivingEssentialsInactive;

MMThanksgivingEssentialsInactive.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number.isRequired,
};

MMThanksgivingEssentialsInactive.defaultProps = {
    className: null,
};
