import PropTypes from 'prop-types';

import { IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN } from 'constants/theme';

export function Pantry({ brand, isActive, width }) {
    const activeColor = brand === MISFITS_THEME_DOMAIN ? '#D7E1B8' : '#F0D3E3';

    return isActive ? (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.6505 28.7652C21.902 26.9646 29 22.6358 29 14.9992C29 7.3625 22.9452 2.7363 14.6505 1.1728C7.31233 -0.213857 2.48978 6.96057 1.13553 14.967C-0.132106 22.475 7.73356 30.4815 14.6505 28.7652Z"
                fill={activeColor}
            />
            <path
                d="M7.05128 13.64C6.50917 13.4403 5.93853 13.3452 5.35838 13.3737C4.78774 13.3927 4.2171 13.5354 3.69401 13.7732C2.94267 14.1631 1.80139 14.9715 2.02964 15.3234C2.2579 15.6753 3.43722 15.837 3.04728 16.75C2.65735 17.6535 3.04728 18.0054 3.67499 18.1956C3.93178 18.2717 4.16003 18.4334 4.30269 18.6712C4.44535 18.8994 4.49291 19.1752 4.43584 19.4415C4.28367 19.8885 4.53095 21.0393 5.34887 21.0964C6.16679 21.1534 6.74694 22.0855 6.77547 22.542C6.804 22.9985 6.93715 23.5121 7.54583 23.5977C8.15452 23.6833 10.4561 23.7499 10.4751 19.7649C10.4751 16.5027 13.3759 17.6916 14.1367 18.11C14.9071 18.5285 17.6176 18.7567 18.3785 17.2255C19.1393 15.7038 17.3228 16.0747 17.1801 15.5707C17.0375 15.0666 17.9505 14.6101 17.6747 13.9063C17.3989 13.212 16.5905 13.678 16.3813 12.7936C16.172 11.9091 17.0375 10.8058 16.134 10.6251C15.221 10.4349 14.8786 10.7202 14.5647 10.2637C14.2509 9.8072 14.4221 9.0939 13.8419 8.65641C13.2618 8.21892 10.9031 9.30313 10.5227 11.2623C10.1422 13.2215 10.5988 14.1155 9.66672 14.5245C8.74418 14.9239 8.42082 14.125 7.03226 13.659L7.05128 13.64Z"
                fill="#F8E1A4"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M7.375 16.8165C7.375 16.8165 7.62228 15.6752 10.6847 16.0747C13.9088 16.4931 14.099 15.3899 14.099 15.3899"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.967 13.64C12.967 13.64 11.8828 13.2406 10.5893 16.0557C9.23881 19.0135 8.20215 18.5951 8.20215 18.5951"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M24.3989 9.90233L14.9833 2.80737C14.7075 2.51254 14.3652 2.29379 13.9942 2.15113C13.6138 2.01798 13.2144 1.97043 12.8149 2.01798C11.2932 2.24624 10.2946 3.96767 10.5799 5.86981C10.6465 6.40241 10.8272 6.91598 11.103 7.3725C11.3788 7.82901 11.7592 8.22846 12.1967 8.5328L21.8025 15.7704C22.0688 16.0367 22.4017 16.2364 22.7536 16.3506C23.1055 16.4742 23.4859 16.5123 23.8663 16.4647C25.388 16.2364 26.4057 14.515 26.1013 12.6129C26.0347 12.0613 25.854 11.5287 25.5592 11.0626C25.2644 10.5871 24.8744 10.1877 24.3989 9.89282V9.90233Z"
                fill="#F8E1A4"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M13.8518 8.90369C15.3735 8.67544 16.3722 6.954 16.0868 5.05187C15.8015 3.14973 14.3464 1.79922 12.8247 2.02747C11.303 2.25573 10.3043 3.97716 10.5897 5.8793C10.875 7.78143 12.3301 9.13195 13.8518 8.90369Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.1338 5.40369L26.1105 12.6128"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.9346 6.93494L26.092 14.0109"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.373 7.99072L25.616 15.2569"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.6025 8.87512L24.6078 16.0557"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.5437 18.072C23.0763 18.4334 24.598 20.2214 24.7597 22.019C24.9214 23.826 24.4934 26.3653 22.6388 27.1642C20.7842 27.9726 18.1403 28.9047 15.3727 26.2037C14.6974 25.5855 14.0792 24.9102 13.5371 24.1874C13.8985 24.1874 17.1607 21.7431 17.1607 21.7431C17.1607 21.7431 18.6729 23.5502 19.7 23.2934C20.7177 23.0271 20.0995 21.4293 19.1484 20.3165C19.9188 19.432 22.5627 18.072 22.5627 18.072H22.5437Z"
                fill="#F8E1A4"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.4412 20.2404C22.4208 19.6698 22.9724 18.7853 22.6681 18.2527C22.3637 17.7201 21.3176 17.7581 20.338 18.3193C19.3584 18.8899 18.8067 19.7744 19.1111 20.307C19.4154 20.8396 20.4616 20.8015 21.4412 20.2404Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.906 23.9401C16.9331 23.341 17.5228 22.4374 17.2185 21.9048C16.9141 21.3722 15.8299 21.4293 14.8027 22.0285C13.7756 22.6277 13.1859 23.5312 13.4903 24.0638C13.7946 24.5964 14.8788 24.5393 15.906 23.9401Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ) : (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.8506 11.0831C17.5562 10.5894 17.1575 10.1622 16.6923 9.82991C16.2271 9.48813 15.6954 9.25077 15.1447 9.11786C14.3093 8.95646 12.9231 8.89 12.8852 9.29824C12.8472 9.71598 13.6732 10.5799 12.7997 11.0452C11.9263 11.5104 12.0212 12.023 12.401 12.5737C12.5624 12.7921 12.6288 13.0579 12.6003 13.3332C12.5719 13.5991 12.4389 13.8459 12.2301 14.0168C11.8408 14.2731 11.3186 15.327 11.9263 15.8776C12.5339 16.4283 12.4105 17.5201 12.1541 17.8904C11.8978 18.2701 11.6889 18.7448 12.1066 19.1911C12.5339 19.6373 14.2998 21.1089 16.7682 17.9948C18.781 15.4409 20.319 18.1562 20.6703 18.9632C21.0121 19.7607 22.9963 21.6215 24.5439 20.9C26.0819 20.1784 24.4299 19.3525 24.6293 18.8588C24.8287 18.3746 25.8256 18.5834 26.0439 17.8619C26.2528 17.1403 25.3319 17.0169 25.7211 16.1909C26.1104 15.365 27.468 15.0422 26.8699 14.3301C26.2718 13.618 25.8256 13.6275 25.8635 13.0769C25.9015 12.5262 26.4712 12.0705 26.2908 11.368C26.1104 10.6654 23.585 10.0578 22.0754 11.368C20.5659 12.6686 20.3665 13.656 19.3886 13.3902C18.4107 13.1338 18.667 12.3079 17.86 11.0831H17.8506Z"
                fill="#F8E1A4"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M16.1416 13.7795C16.1416 13.7795 17.0435 13.039 19.1987 15.2416C21.4678 17.5581 22.2938 16.8176 22.2938 16.8176"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.4931 14.7478C22.4931 14.7478 21.8854 13.7699 19.1322 15.1751C16.2365 16.6561 15.6953 15.6877 15.6953 15.6877"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.6794 21.3272L6.8657 16.6941C6.53341 16.4758 6.14416 16.3428 5.7454 16.2954C5.34665 16.2574 4.9479 16.2954 4.56814 16.4473C3.15352 17.0264 2.59337 18.9347 3.31492 20.7101C3.5048 21.2133 3.79912 21.669 4.17888 22.0488C4.55865 22.4285 5.01436 22.7229 5.51755 22.9127L16.5497 27.6503C16.8725 27.8402 17.2332 27.9541 17.613 27.9921C17.9833 28.0205 18.363 27.9731 18.7143 27.8402C20.1289 27.261 20.7081 25.3432 19.9675 23.5773C19.7776 23.0551 19.4738 22.5899 19.0751 22.2007C18.6763 21.8114 18.2016 21.5171 17.6794 21.3462V21.3272Z"
                fill="#F8E1A4"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M7.20776 22.8653C8.62238 22.2862 9.18253 20.3779 8.46098 18.6025C7.73943 16.8271 5.99252 15.8587 4.57791 16.4378C3.16329 17.017 2.60314 18.9253 3.32469 20.7007C4.05573 22.4761 5.79315 23.4445 7.20776 22.8653Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.59375 18.9347L19.9771 23.5488"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.76465 20.4633L20.2904 24.9065"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.46973 21.6216L20.1284 26.2262"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.92871 22.6564L19.3406 27.2326"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.9108 2.18719C14.4425 2.54797 15.9615 4.33285 16.1229 6.12723C16.2843 7.93111 15.8571 10.466 14.0057 11.2635C12.1544 12.0705 9.51505 13.0009 6.75228 10.3046C6.0782 9.68751 5.46108 9.01343 4.91992 8.29188C5.2807 8.29188 8.53716 5.8519 8.53716 5.8519C8.53716 5.8519 10.0467 7.65578 11.0721 7.39944C12.0879 7.1336 11.4708 5.5386 10.5214 4.42779C11.2904 3.54485 13.9298 2.18719 13.9298 2.18719H13.9108Z"
                fill="#F8E1A4"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.8091 4.3614C13.7869 3.79175 14.3376 2.90881 14.0338 2.37714C13.73 1.84547 12.6856 1.88345 11.7077 2.4436C10.7299 3.01324 10.1792 3.89619 10.483 4.42786C10.7868 4.95952 11.8312 4.92155 12.8091 4.3614Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.29323 8.04507C8.31859 7.44694 8.90722 6.54501 8.60341 6.01334C8.2996 5.48167 7.21727 5.53864 6.19192 6.13676C5.16656 6.73489 4.57792 7.63683 4.88173 8.16849C5.18554 8.70016 6.26787 8.6432 7.29323 8.04507Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

Pantry.propTypes = {
    brand: PropTypes.oneOf([IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN]).isRequired,
    isActive: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Pantry.defaultProps = {
    isActive: false,
    width: 30,
};
