/**
 * Get an object of query params from a string.
 * @example
 * // returns { redirect: true, promo: 'WELCOME30' }
 * queryStringToObject('?redirect=true&promo=WELCOME30')
 * @param {string} url
 * @returns {object}
 */
const queryStringToObject = (url = '') => Object.fromEntries([...new URLSearchParams(url.split('?')[1])]);

/**
 * Filter & keep key/value pairs that start with utm_
 * @example
 * // returns { utm_source: 'facebook', utm_medium: 'email' }
 * filterUtmParams({ utm_source: 'facebook', utm_medium: 'email', redirect: true, promo: 'WELCOME30' })
 * @param {params} key/value pairs to filter
 * @returns {object} key/value pairs that start with utm_
 */

const filterUtmParams = (params = {}) => {
    const result = {};

    Object.keys(params).forEach((key) => {
        if (key.startsWith('utm_')) {
            result[key] = params[key];
        }
    });

    return result;
};

/**
 * Get an object of utm params from a string.
 * @example
 * // returns { utm_source: 'facebook' }
 * queryStringToObject('?redirect=true&promo=WELCOME30&utm_source=facebook')
 * @param {string} url
 * @returns {object}
 */
const getUTMParams = (url = '') => {
    const params = queryStringToObject(url);
    const utms = filterUtmParams(params);
    return utms;
};

export { filterUtmParams, getUTMParams, queryStringToObject };
