import { useEffect, useRef } from 'react';
import { ArrowSmallLeftIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import useGlobalStore from 'hooks/useGlobalStore';
import useTailwindTheme from 'hooks/useTailwindTheme';
import { enableBodyScroll } from 'utils/globalUtils';

export function SearchInput() {
    const navigate = useNavigate();
    const tailwindTheme = useTailwindTheme();
    const mobile = useMediaQuery({ maxWidth: tailwindTheme?.screens?.md });
    const { autocompleteOpen, searchQuery, setAutocompleteOpen, setSearchOpen, setSearchQuery } = useGlobalStore();
    const { pathname } = window.location ?? {};
    const searchInputRef = useRef(null);

    const clearSearchQuery = () => {
        setSearchQuery('');
        setAutocompleteOpen(false);
        enableBodyScroll(true);
    };

    const handleSubmitSearch = (e) => {
        e.preventDefault();
        if (pathname.includes('search') || pathname.includes('brand')) {
            window.location.href = `/shop/search?query=${searchQuery}`;
        } else {
            navigate(`/shop/search?query=${searchQuery}`);
            setAutocompleteOpen(false);
            enableBodyScroll(true);
        }
    };

    const handleUpdateQuery = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        if (mobile && !window.isNativeApp) {
            searchInputRef?.current?.focus();
        }
    }, []);

    return (
        <div
            className={clsx('bg-white px-15 py-10', {
                'fixed top-0 w-full overflow-hidden': window.isNativeApp && autocompleteOpen && searchQuery !== '',
            })}
        >
            <div
                className={clsx('relative !z-[1300] overflow-hidden rounded-full bg-off-white px-10', {
                    'w-[200px] p-10 py-10 xl:w-[300px]': !mobile,
                    'px-10 py-5': mobile,
                })}
            >
                <div className="flex items-center gap-5">
                    <div>
                        {mobile && !window.isNativeApp ? (
                            <button
                                onClick={() => {
                                    setSearchOpen(false);
                                    setAutocompleteOpen(false);
                                    enableBodyScroll(true);
                                }}
                                type="button"
                            >
                                <ArrowSmallLeftIcon width={20} className="relative top-[3px] mr-10" />
                            </button>
                        ) : (
                            <button onClick={() => searchInputRef?.current?.focus()} type="button">
                                <MagnifyingGlassIcon width={20} className="relative top-[3px]" />
                            </button>
                        )}
                    </div>
                    <form onSubmit={handleSubmitSearch} className="w-full overflow-hidden">
                        <input
                            className={clsx(
                                'w-full bg-off-white placeholder-grey outline-none hover:placeholder-black',
                                {
                                    'py-5': window.isNativeApp,
                                }
                            )}
                            ref={searchInputRef}
                            name="search"
                            id="search"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={handleUpdateQuery}
                            onFocus={() => {
                                setAutocompleteOpen(true);
                            }}
                        />
                    </form>
                    {searchQuery !== '' && (
                        <button type="button" className="cursor-pointer" onClick={clearSearchQuery}>
                            <XMarkIcon width={20} />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}
