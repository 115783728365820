import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { BOYSENBERRY_S3_IMAGES } from 'constants';
import Card from 'honeydew/Card';
import Tag from 'honeydew/Tag';
import { useAccountPlusMembershipHook } from 'hooks/useAccountPlusMembershipHook';
import useGlobalStore from 'hooks/useGlobalStore';
import { formatDateString } from 'utils/dateUtils';

export function MemberWelcomeCard({ trialExpirationDate }) {
    const { plusMembershipBranding } = useAccountPlusMembershipHook();
    const formattedExpirationDate = formatDateString(trialExpirationDate);
    const { isImperfectTheme } = useGlobalStore();
    const titleText = trialExpirationDate
        ? `Welcome to ${plusMembershipBranding}`
        : `Thanks for being ${
              isImperfectTheme ? 'an' : 'a'
          } ${plusMembershipBranding} member! Look at you, getting the most bang for your buck.`;
    const subtitleText = trialExpirationDate ? `Your free trial ends on ${formattedExpirationDate}` : null;
    const linkText = trialExpirationDate ? 'Learn more' : 'View my membership';

    return (
        <Card bgColor="bg-oat" topGradientBorder>
            <div className="relative flex w-full justify-center">
                <img
                    src={`${BOYSENBERRY_S3_IMAGES}paid-membership/onion.png`}
                    width={145}
                    height={180}
                    alt=""
                    role="presentation"
                    className="absolute -bottom-[100px] -left-20 z-10 -rotate-45"
                />
                <div className="z-20 flex flex-col items-center gap-5 pb-50 md:pb-20 lg:pb-0">
                    <Tag bgColor="plusMembership">
                        <span className="text-body-desktop-xs uppercase">{plusMembershipBranding} Member</span>
                    </Tag>
                    <p className="max-w-[450px] text-center font-grotesk-bold">{titleText}</p>
                    {subtitleText && <p>{subtitleText}</p>}
                    <Link className="link-blueberry text-body-sm block" to="/account/membership">
                        {linkText}
                    </Link>
                </div>
                <img
                    src={`${BOYSENBERRY_S3_IMAGES}paid-membership/blueberries.png`}
                    width={215}
                    height={235}
                    alt=""
                    role="presentation"
                    className="absolute -bottom-[190px] -right-80 -scale-x-100"
                />
            </div>
        </Card>
    );
}

MemberWelcomeCard.propTypes = {
    trialExpirationDate: PropTypes.string,
};

MemberWelcomeCard.defaultProps = {
    trialExpirationDate: null,
};
