import PropTypes from 'prop-types';

function MisfitsPlusLinkTitle({
    availableCredits,
    availableTrialMembership,
    membership,
    plusMembershipBranding,
    trial,
}) {
    let caption = null;

    if (availableCredits?.credits > 0) {
        caption = (
            <span className="text-plus-membership text-body-sm block font-grotesk">
                Get ${availableCredits.credits}
            </span>
        );
    }

    if (availableTrialMembership?.trialDurationDays) {
        caption = (
            <span className="text-plus-membership text-body-sm block font-grotesk">
                Try it free for {availableTrialMembership.trialDurationDays} days
            </span>
        );
    }

    return (
        <span className="block text-left">
            {!membership && !trial && 'Join '}
            {plusMembershipBranding}
            {caption}
        </span>
    );
}

export default MisfitsPlusLinkTitle;

MisfitsPlusLinkTitle.propTypes = {
    availableCredits: PropTypes.shape({
        credits: PropTypes.number.isRequired,
        creditsExpirationDays: PropTypes.number.isRequired,
    }),
    availableTrialMembership: PropTypes.shape({
        trialDurationDays: PropTypes.number.isRequired,
    }),
    membership: PropTypes.shape({}),
    plusMembershipBranding: PropTypes.string.isRequired,
    trial: PropTypes.shape({}),
};

MisfitsPlusLinkTitle.defaultProps = {
    availableCredits: undefined,
    availableTrialMembership: undefined,
    membership: undefined,
    trial: undefined,
};
