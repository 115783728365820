export const SIGNUP_PATH = '/join';

export const QUIZ_PATH = `${SIGNUP_PATH}/quiz`;

export const SignupQuizSteps = Object.freeze({
    AISLE_SELECTION: 'aisle-selection',
    DIETARY_PREFERENCE_NEEDS: 'dietary-preference-needs',
    HOUSEHOLD_MEALS: 'household-meals',
    SHOPPING_PLAN: 'shopping-plan',
});

export const SignupQuizStepsOrdered = Object.freeze([
    SignupQuizSteps.HOUSEHOLD_MEALS,
    SignupQuizSteps.DIETARY_PREFERENCE_NEEDS,
    SignupQuizSteps.AISLE_SELECTION,
    SignupQuizSteps.SHOPPING_PLAN,
]);

export const AccountCreationSteps = Object.freeze({
    ACCOUNT: `${SIGNUP_PATH}/account`,
    DELIVERY: `${SIGNUP_PATH}/delivery`,
    PAYMENT: `${SIGNUP_PATH}/payment`,
});

export const FirstDeliveryWeekdays = [
    { fullDay: 'monday', shortHand: 'Mon' },
    { fullDay: 'tuesday', shortHand: 'Tue' },
    { fullDay: 'wednesday', shortHand: 'Wed' },
    { fullDay: 'thursday', shortHand: 'Thu' },
    { fullDay: 'friday', shortHand: 'Fri' },
    { fullDay: 'saturday', shortHand: 'Sat' },
    { fullDay: 'sunday', shortHand: 'Sun' },
];

export const HOUSEHOLD_SIZE = [
    {
        value: '1',
        label: 'Just Me',
    },
    {
        value: '2',
        label: 'Two',
    },
    {
        value: '3',
        label: 'Three',
    },
    {
        value: '4',
        label: 'Four',
    },
    {
        value: '5+',
        label: 'Five+',
    },
];

export const COOKING_FREQUENCY = [
    {
        value: 3,
        label: 'Every day of the week',
        shortLabel: 'Every day',
    },
    {
        value: 2,
        label: 'Most days of the week',
        shortLabel: 'Most days',
    },
    {
        value: 1,
        label: 'A few days each week',
        shortLabel: 'A few days',
    },
];

export const DIETARY_PREFERENCES_V2 = [
    {
        value: 'vegan',
        label: 'Vegan',
        icon: 'CO2ESavedIcon',
    },
    {
        value: 'vegetarian',
        label: 'Vegetarian',
        icon: 'Vegetables',
    },
    {
        value: 'pescatarian',
        label: 'Pescatarian',
        icon: 'PescatarianIcon',
    },
    {
        value: 'all',
        label: 'I eat it all',
        icon: 'AllPreferencesIcon',
    },
];

export const ADDITIONAL_DIETARY_PREFERENCES = [
    {
        value: 'gluten-free',
        label: 'Gluten-Free',
        icon: 'PlantBased',
    },
    {
        value: 'kosher',
        label: 'Kosher',
        icon: 'Vegetables',
    },
    {
        value: 'non-GMO',
        label: 'Non-GMO',
        icon: 'MeatAndSeafood',
    },
    {
        value: 'organic',
        label: 'Organic',
        icon: 'Deli',
    },
];

export const ORGANIC_OPTIONS = [
    {
        value: 20,
        label: 'A bit of organic, but mostly conventional',
        shortLabel: 'A bit',
    },
    {
        value: 50,
        label: 'Some organic, and some conventional',
        shortLabel: 'Some',
    },
    {
        value: 80,
        label: 'Mostly organic, and a bit of conventional',
        shortLabel: 'Mostly',
    },
];

export const DIETARY_PREFERENCES = [
    {
        value: 'vegan',
        label: 'Vegan',
        icon: 'PlantBased',
    },
    {
        value: 'vegetarian',
        label: 'Vegetarian',
        icon: 'Vegetables',
    },
    {
        value: 'all',
        label: 'I eat it all',
        icon: 'Deli',
    },
];

export const CURATED_PRODUCTS_DATA = [
    {
        id: 1,
        name: 'Organic Strawberries',
        percent: 25,
        image: 'https://img-cdn.misfitsmarket.com/item_images/item_info_360_md_2020-07-08_20-04-17.jpg',
    },
    {
        id: 2,
        name: '100% Grass-Fed Boneless Beef Ribeye Steak, 10 oz',
        brand: 'Meadow Reserve',
        percent: 27,
        image: 'https://img-cdn.misfitsmarket.com/item_images/item_info_2729_md_2021-07-07_13-28-43.jpg',
    },
    {
        id: 3,
        name: 'Organic Brussels Sprouts, 1 Lb',
        percent: 20,
        image: 'https://img-cdn.misfitsmarket.com/item_images/item_info_1209_md_2020-11-11_09-20-34.jpg',
    },
    {
        id: 4,
        name: 'Organic Oat Milk, 32 Fl Oz',
        brand: 'Minor Figures',
        percent: 25,
        image: 'https://img-cdn.misfitsmarket.com/item_images/item_info_5034_md_2022-04-26_19-24-59.jpg',
    },
    {
        id: 5,
        name: 'Organic Mini Seedless Watermelon',
        percent: 17,
        image: 'https://img-cdn.misfitsmarket.com/item_images/item_info_425_md_2022-08-19_14-44-48.jpg',
    },
    {
        id: 6,
        name: 'Antibiotic-Free Oven Roasted Turkey, 6 Oz',
        brand: 'True Story',
        percent: 28,
        image: 'https://img-cdn.misfitsmarket.com/item_images/item_info_4789_md_2022-08-25_10-57-03.jpg',
    },
    {
        id: 7,
        name: 'Take and Bake Bread, French, 16 Oz',
        brand: 'Essential Baking Co',
        percent: 25,
        image: 'https://img-cdn.misfitsmarket.com/item_images/item_info_2531_md_2021-07-12_15-27-56.jpg',
    },
    {
        id: 8,
        name: 'Goldfish Cheddar Cheese Crackers, 14 Oz',
        brand: 'Goldfish',
        percent: 20,
        image: 'https://img-cdn.misfitsmarket.com/item_images/item_info_4533_md_2022-03-15_19-38-48.jpg',
    },
    {
        id: 9,
        name: 'Organic Zucchini',
        percent: 50,
        image: 'https://img-cdn.misfitsmarket.com/item_images/item_info_192_md_2020-07-06_21-28-21.jpg',
    },
    {
        id: 10,
        name: 'Organic Gala Apples',
        percent: 27,
        image: 'https://img-cdn.misfitsmarket.com/item_images/item_info_12_md_2020-07-06_18-01-16.jpg',
    },
    {
        id: 11,
        name: 'Flatbread, Traditional Pita, 12oz',
        brand: 'Atorias',
        percent: 28,
        image: 'https://img-cdn.misfitsmarket.com/item_images/item_info_2671_md_2021-07-12_16-02-41.jpg',
    },
    {
        id: 12,
        name: 'Red Hot Blues Party Size Tortilla Chips, 16oz',
        brand: "Garden of Eatin'",
        percent: 78,
        image: 'https://img-cdn.misfitsmarket.com/item_images/item_info_1450_md_2021-01-19_18-52-34.jpg',
    },
];

export const CUSTOMER_REVIEW_DATA = [
    {
        mmImage: 'https://img-cdn.misfitsmarket.com/images/MM_signup-LIP-ig1-mobile.jpg',
        ifImage: 'https://img-cdn.misfitsmarket.com/boysenberry/images/block-of-tofu.jpg',
        mmHeight: 240,
        ifHeight: 180,
        ifCopy: '“First order delivered from @imperfectfoods! I can’t wait to create some vegan goodness with all these ingredients this week!”',
        mmCopy: '“First shipment from @misfitsmarket !! Super happy with the amount of veggies and fruit that came with the box. Those plums are deeeelicious. Ugly produce deserves love too. 🍎 🍅 🥬”',
        mmSocialHandle: '@leepfund',
        ifSocialHandle: '@justablockoftofu',
    },
    {
        mmImage: 'https://img-cdn.misfitsmarket.com/images/MM_signup-rockerbyedestash.jpg',
        ifImage: 'https://img-cdn.misfitsmarket.com/boysenberry/images/vgalactic.jpg',
        mmHeight: 180,
        ifHeight: 180,
        ifCopy: '“Found this amazing company! Imperfect Foods! They are literally changing our broken food system, I love their mission and vision. They partner with grocery stores and farmers to ensure excess foods don’t go to waste and deliver right to your door! Figured I would share with all my friends and fam ✌🏼️💗🌎”',
        mmCopy: '“My @misfitsmarket haul this week. Yummmmmmm!! Have I sold you yet? All of this for a great price, mostly all organic, and dropped at my door this morning.”',
        mmSocialHandle: '@rockerbyedestash',
        ifSocialHandle: '@vgalactic322',
    },
    {
        mmImage: 'https://img-cdn.misfitsmarket.com/images/MM_signup-theorganicprepper.jpg',
        ifImage: 'https://img-cdn.misfitsmarket.com/boysenberry/images/miss-kate.jpg',
        mmHeight: 174,
        ifHeight: 180,
        ifCopy: '“Cut down on food waste, save the planet, and feel good about conscious consumerism. My box came with chocolate toffees, dried mango, raw almonds, chocolate covered pretzels, and more...”',
        mmCopy: '“@misfitsmarket isn’t just fruits and veggies. You can also get high quality, organic pantry items and organic meat at great prices delivered right to your door.”',
        mmSocialHandle: '@theorganicprepper',
        ifSocialHandle: '@misskateabate',
    },
];
