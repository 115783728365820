import useSelectionsQuery from 'api/queries/useSelectionsQuery';

/**
 * Get selection data for a given product.
 * @param {boolean} isPerk - Optionally find a perk product.
 * @param {string} productID - The ID of the product to get data for.
 * @returns {Selection} - Selection data.
 */
function useProductSelectionData({ isPerk = false, productID }) {
    const { data: selections } = useSelectionsQuery();
    const { selected = [], selectedBundles = [] } = selections ?? {};

    if (!selections) return { data: undefined };

    const productSelectionData = [...selected, ...selectedBundles].find(
        (selection) => selection.id === productID && !!selection?.isPerk === isPerk
    );

    return { data: productSelectionData };
}

export default useProductSelectionData;
