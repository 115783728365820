import PropTypes from 'prop-types';

import imgUrl from './loader-apple.gif';

function Loader({ height, width }) {
    return <img src={imgUrl} alt="Loading..." width={width} height={height} />;
}

Loader.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string,
};

Loader.defaultProps = {
    height: '78',
    width: '70',
};

export default Loader;
