import { useQuery } from '@tanstack/react-query';

import { getProducts } from 'api/jalapeno';
import useAccountQuery from 'api/queries/useAccountQuery';

export const PRODUCTS_QUERY_KEY = 'products';

const useProductsQuery = () => {
    const { data: account } = useAccountQuery();

    const query = useQuery({
        queryKey: [PRODUCTS_QUERY_KEY],
        queryFn: async () => {
            const response = await getProducts(account?.nextOrder?.chargeID);
            return response?.data;
        },
        enabled: !!account?.nextOrder?.chargeID,
    });

    return query;
};

export default useProductsQuery;
