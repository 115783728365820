const FEATURE_FLAG_ON_VALUE = 'on';

const FEATURE_FLAG_NAMES = {
    AVERAGE_MEMBERSHIP_SAVINGS: 'average-membership-savings',
    FREE_TRIAL_CANCEL: 'free-trial-cancel',
    OPTIONAL_TIPS_COPY_TREATMENT: 'driver-tips-optional-copy',
    REMOVE_EMAIL_FROM_LEAD: 'remove-email-from-lead',
    UP_SELLS_CAROUSEL_PLP: 'upsells-carousel-plp',
    UP_SELLS_CAROUSEL_PDP: 'upsells-carousel-pdp',
    PLUS_MEMBERSHIP: 'membership',
    MOBILE_PAYMENT_CTA: 'mobile-payment-cta',
};

export { FEATURE_FLAG_NAMES, FEATURE_FLAG_ON_VALUE };
