import PropTypes from 'prop-types';

import { IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN } from 'constants/theme';

export function OurBrands({ brand, isActive, width }) {
    if (brand === MISFITS_THEME_DOMAIN) {
        return isActive ? (
            <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14.6505 28.7652C21.902 26.9646 29 22.6358 29 14.9992C29 7.3625 22.9452 2.7363 14.6505 1.1728C7.31233 -0.213857 2.48978 6.96057 1.13553 14.967C-0.132106 22.475 7.73356 30.4815 14.6505 28.7652Z"
                    fill="#D7E1B8"
                />
                <path
                    d="M22.6275 5.50576L14.0187 3.48902C13.2851 3.31717 12.5511 3.77254 12.3793 4.50613L9.13891 18.3381C8.96706 19.0717 9.42243 19.8057 10.156 19.9776L18.7648 21.9943C19.4984 22.1661 20.2324 21.7108 20.4042 20.9772L23.6446 7.1452C23.8164 6.41162 23.3611 5.67762 22.6275 5.50576Z"
                    fill="#F1C34A"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19.5472 16.3367C19.5664 16.427 19.51 16.7536 19.4011 16.7348C19.3567 16.7233 16.5623 16.0591 16.1627 15.9553C16.1405 15.9496 16.1241 15.9216 16.1299 15.8994L16.2121 15.5463C16.2121 15.5463 16.268 15.5135 16.3024 15.5271C16.3769 15.5323 16.9707 15.5633 17.2443 14.7291C17.5178 13.8949 18.109 11.0531 18.109 11.0531C18.109 11.0531 18.0071 11.1167 17.885 11.3315C17.3771 12.247 15.9389 14.755 15.7047 15.1823C15.681 15.2188 15.643 15.2374 15.5986 15.2259L15.3888 15.1761C15.3888 15.1761 15.3179 15.1387 15.3094 15.0986C15.1942 14.5568 14.4259 10.5989 14.4274 10.5566C14.4389 10.5122 13.9817 12.4551 13.8209 13.129C13.7235 13.5587 13.6363 14.3323 13.8542 14.7159C14.0536 15.0616 14.4589 15.1431 14.5943 15.1878C14.6387 15.1993 14.6508 15.2072 14.6372 15.2416C14.6278 15.296 14.5723 15.528 14.5723 15.528C14.5723 15.528 14.5571 15.6047 14.4905 15.5874C14.1697 15.5088 12.3198 15.0947 11.9224 15.0009C11.878 14.9894 11.8594 14.9514 11.863 14.9192C11.8861 14.8304 11.9222 14.6549 11.9474 14.5761C11.9431 14.5561 11.9711 14.5396 11.9912 14.5354C12.0915 14.514 12.3846 14.4622 12.5265 14.39C12.7044 14.2893 12.9955 14.0806 13.2524 13.3653C13.5266 12.5834 13.8848 10.7664 13.9266 10.5688C14.0146 10.1935 13.7657 9.91085 13.5747 9.75223C13.4466 9.64313 13.3378 9.62434 13.269 9.597C13.2225 9.57544 13.2018 9.52741 13.2133 9.48301L13.3432 8.91009C13.3432 8.91009 13.4085 8.82279 13.463 8.83218L13.6284 8.87041L15.9365 9.41777C15.9365 9.41777 16.0074 9.45513 16.0059 9.4974L16.4871 12.8452C16.4871 12.8452 17.8456 10.4065 17.9289 10.3049C18.0043 10.2155 18.2002 9.95359 18.4402 9.99695C18.6579 10.0345 20.1568 10.3765 20.3545 10.4183C20.4533 10.4392 20.54 10.4523 20.5965 10.4717C20.651 10.4811 20.6838 10.537 20.6723 10.5814C20.6435 10.6924 20.6095 10.8779 20.5864 10.9667C20.5691 11.0333 20.0622 10.8685 19.8986 11.48C19.7372 12.1016 19.2712 14.1513 19.1623 14.6253C19.0245 15.2104 18.9954 15.9612 19.205 16.1578C19.3617 16.3028 19.5344 16.2765 19.5435 16.369"
                    fill="#2D2D2D"
                />
                <path
                    d="M9.98952 19.8165C10.9497 19.6962 11.7789 19.0584 12.3468 18.277C12.9147 17.4956 13.1425 17.0381 13.4956 16.1346C13.646 15.7565 14.5289 13.2512 16.2226 14.0655C17.1919 14.5306 15.1845 18.7012 15.0422 19.906C14.9275 20.8952 15.0907 21.9092 14.9115 22.8911C14.6325 24.4395 13.5437 25.7302 12.345 26.7507C11.1463 27.7711 10.4737 27.952 9.25957 28.444C7.86312 28.0908 6.58776 27.2714 5.66679 26.1459C5.34001 25.7435 5.04421 25.2401 5.14672 24.7359C5.21891 24.385 5.45295 24.385 5.6848 24.0945C6.3904 23.2209 6.39357 23.2238 6.57514 22.1051C6.82612 20.5731 6.29199 18.9983 6.52291 17.4705C7.01934 14.2819 10.3071 12.3348 10.6117 12.2385C10.6991 13.6357 9.78109 14.7432 9.64923 16.145C9.54193 17.4158 10.4842 18.6416 9.97736 19.8086L9.98952 19.8165Z"
                    fill="#F8E1A4"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeMiterlimit="10"
                    strokeLinejoin="round"
                />
            </svg>
        ) : (
            <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M26.5895 8.62103L19.0449 4.0104C18.402 3.61751 17.5624 3.82018 17.1695 4.46308L9.76144 16.5852C9.36855 17.2281 9.57123 18.0677 10.2141 18.4606L17.7587 23.0713C18.4016 23.4641 19.2413 23.2615 19.6342 22.6186L27.0422 10.4965C27.4351 9.85359 27.2324 9.01392 26.5895 8.62103Z"
                    fill="#F1C34A"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M20.2736 17.9432C20.2636 18.035 20.1078 18.3275 20.0103 18.2756C19.9717 18.2507 17.5258 16.7451 17.1788 16.5214C17.1595 16.5089 17.1526 16.4772 17.1651 16.458L17.3537 16.1484C17.3537 16.1484 17.4171 16.1347 17.4455 16.1584C17.5146 16.1866 18.0689 16.402 18.5899 15.6954C19.1108 14.9888 20.562 12.475 20.562 12.475C20.562 12.475 20.4454 12.5035 20.2621 12.6692C19.4931 13.3796 17.342 15.3113 16.9858 15.6438C16.9519 15.6711 16.91 15.6768 16.8714 15.652L16.6877 15.539C16.6877 15.539 16.6321 15.4813 16.6366 15.4406C16.6968 14.8899 17.2063 10.8905 17.221 10.8508C17.2458 10.8122 16.2033 12.5143 15.8396 13.104C15.6125 13.4816 15.2875 14.189 15.3744 14.6215C15.4555 15.0122 15.8149 15.2166 15.9295 15.3013C15.968 15.3262 15.9771 15.3375 15.9534 15.3659C15.9274 15.4146 15.802 15.6176 15.802 15.6176C15.802 15.6176 15.7637 15.6856 15.7058 15.6483C15.4257 15.4733 13.7985 14.5008 13.4504 14.2873C13.4119 14.2625 13.4061 14.2206 13.4197 14.1911C13.4694 14.114 13.5586 13.9587 13.6072 13.8917C13.6094 13.8713 13.6411 13.8645 13.6615 13.8667C13.7635 13.8779 14.0581 13.9204 14.2155 13.8963C14.4159 13.8563 14.7578 13.7493 15.2257 13.1504C15.7309 12.4936 16.6399 10.8801 16.7415 10.7055C16.9426 10.3767 16.7947 10.0303 16.663 9.81986C16.5755 9.67615 16.478 9.62422 16.4213 9.57674C16.3838 9.54169 16.3792 9.48959 16.4041 9.45104L16.7068 8.94757C16.7068 8.94757 16.7961 8.88511 16.8449 8.91108L16.99 8.99918L19.0107 10.2417C19.0107 10.2417 19.0664 10.2993 19.0517 10.339L18.4606 13.6692C18.4606 13.6692 20.5143 11.7784 20.6252 11.708C20.7248 11.6467 20.9929 11.4593 21.2072 11.5756C21.4022 11.6794 22.7187 12.4735 22.8933 12.5751C22.9806 12.6259 23.0589 12.6654 23.1065 12.7016C23.1553 12.7276 23.169 12.791 23.1441 12.8295C23.082 12.9259 22.9917 13.0915 22.9419 13.1686C22.9047 13.2264 22.4748 12.9111 22.128 13.4407C21.78 13.9805 20.6958 15.7812 20.4439 16.1974C20.1299 16.7099 19.8672 17.4138 20.0047 17.6662C20.1081 17.8529 20.2803 17.882 20.2601 17.9727"
                    fill="#2D2D2D"
                />
                <path
                    d="M10.1065 18.2555C11.056 18.4419 12.0432 18.0958 12.8272 17.5314C13.6112 16.9671 13.9708 16.604 14.5891 15.8564C14.8502 15.5445 16.4731 13.4415 17.8267 14.7452C18.6017 15.4903 15.3894 18.8228 14.8771 19.9225C14.4584 20.826 14.296 21.8401 13.8183 22.7165C13.0686 24.0998 11.6305 24.9847 10.1725 25.5785C8.7146 26.1724 8.0192 26.1336 6.71204 26.2208C5.49639 25.4481 4.54169 24.2706 4.01939 22.9134C3.83504 22.4289 3.7117 21.8582 3.96692 21.4114C4.14535 21.1008 4.36762 21.174 4.67877 20.9707C5.62241 20.3619 5.62451 20.3657 6.14721 19.36C6.86522 17.9836 6.85098 16.3208 7.5486 14.9422C9.0184 12.0692 12.7505 11.2494 13.0699 11.2534C12.7155 12.6076 11.4969 13.3721 10.9327 14.6621C10.433 15.8354 10.9441 17.2946 10.0974 18.2442L10.1065 18.2555Z"
                    fill="#F8E1A4"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeMiterlimit="10"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }

    return isActive ? (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.6505 28.7652C21.902 26.9646 29 22.6358 29 14.9992C29 7.3625 22.9452 2.7363 14.6505 1.1728C7.31233 -0.213857 2.48978 6.96057 1.13553 14.967C-0.132106 22.475 7.73356 30.4815 14.6505 28.7652Z"
                fill="#F0D3E3"
            />
            <path
                d="M22.6275 5.50576L14.0187 3.48902C13.2851 3.31717 12.5511 3.77254 12.3793 4.50613L9.13891 18.3381C8.96706 19.0717 9.42243 19.8057 10.156 19.9776L18.7648 21.9943C19.4984 22.1661 20.2324 21.7108 20.4042 20.9772L23.6446 7.1452C23.8164 6.41162 23.3611 5.67762 22.6275 5.50576Z"
                fill="#ECE7E4"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.8074 11.3022C16.6275 10.9643 16.4354 10.3099 15.5329 10.2446C14.6339 10.1769 13.7063 10.8435 13.5992 11.8521C13.4917 12.8637 13.8924 13.8486 14.5076 14.6425C15.1228 15.4365 15.6061 15.8424 15.8378 15.9567C16.1009 15.9356 17.6167 15.4813 18.361 14.8152C19.1087 14.1466 19.8396 13.6371 19.6858 12.2071C19.532 10.7771 17.8608 10.7814 17.6293 10.9268C17.3978 11.0721 16.8079 11.2993 16.8079 11.2993L16.8074 11.3022Z"
                fill="#DD3D96"
                stroke="#453D3D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
                strokeLinejoin="round"
            />
            <path
                d="M9.98952 19.8165C10.9497 19.6962 11.7789 19.0584 12.3468 18.277C12.9147 17.4956 13.1425 17.0381 13.4956 16.1346C13.646 15.7565 14.5289 13.2512 16.2226 14.0655C17.1919 14.5306 15.1845 18.7012 15.0422 19.906C14.9275 20.8952 15.0907 21.9092 14.9115 22.8911C14.6325 24.4395 13.5437 25.7302 12.345 26.7507C11.1463 27.7711 10.4737 27.952 9.25957 28.444C7.86312 28.0908 6.58776 27.2714 5.66679 26.1459C5.34001 25.7435 5.04421 25.2401 5.14672 24.7359C5.21891 24.385 5.45295 24.385 5.6848 24.0945C6.3904 23.2209 6.39357 23.2238 6.57514 22.1051C6.82612 20.5731 6.29199 18.9983 6.52291 17.4705C7.01934 14.2819 10.3071 12.3348 10.6117 12.2385C10.6991 13.6357 9.78109 14.7432 9.64923 16.145C9.54193 17.4158 10.4842 18.6416 9.97736 19.8086L9.98952 19.8165Z"
                fill="#F8E1A4"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
                strokeLinejoin="round"
            />
        </svg>
    ) : (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M26.5895 8.62109L19.0449 4.01046C18.402 3.61757 17.5624 3.82024 17.1695 4.46314L9.76144 16.5852C9.36855 17.2281 9.57123 18.0678 10.2141 18.4607L17.7587 23.0713C18.4016 23.4642 19.2413 23.2615 19.6342 22.6186L27.0422 10.4965C27.4351 9.85365 27.2324 9.01398 26.5895 8.62109Z"
                fill="#ECE7E4"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.981 12.1828C18.9098 11.7995 18.9237 11.105 18.0672 10.7651C17.2148 10.4239 16.1096 10.7872 15.6966 11.734C15.2822 12.6836 15.3696 13.7629 15.7239 14.7225C16.0782 15.6822 16.4233 16.2245 16.6133 16.4065C16.8753 16.4666 18.4867 16.4898 19.4137 16.0708C20.3448 15.6505 21.2107 15.3796 21.4995 13.9436C21.7883 12.5075 20.1637 11.9997 19.8943 12.07C19.625 12.1402 18.9824 12.1801 18.9824 12.1801L18.981 12.1828Z"
                fill="#DD3D96"
                stroke="#453D3D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
                strokeLinejoin="round"
            />
            <path
                d="M10.1065 18.2555C11.056 18.4419 12.0432 18.0958 12.8272 17.5315C13.6112 16.9672 13.9708 16.604 14.5891 15.8565C14.8502 15.5445 16.4731 13.4416 17.8267 14.7453C18.6017 15.4904 15.3894 18.8229 14.8771 19.9225C14.4584 20.826 14.296 21.8401 13.8183 22.7166C13.0686 24.0998 11.6305 24.9848 10.1725 25.5786C8.7146 26.1724 8.0192 26.1336 6.71204 26.2208C5.49639 25.4482 4.54169 24.2707 4.01939 22.9134C3.83504 22.4289 3.7117 21.8582 3.96692 21.4115C4.14535 21.1008 4.36762 21.1741 4.67877 20.9708C5.62241 20.362 5.62451 20.3658 6.14721 19.3601C6.86522 17.9837 6.85098 16.3209 7.5486 14.9422C9.0184 12.0693 12.7505 11.2495 13.0699 11.2534C12.7155 12.6077 11.4969 13.3721 10.9327 14.6622C10.433 15.8354 10.9441 17.2947 10.0974 18.2442L10.1065 18.2555Z"
                fill="#F8E1A4"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
                strokeLinejoin="round"
            />
        </svg>
    );
}

OurBrands.propTypes = {
    brand: PropTypes.oneOf([IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN]).isRequired,
    isActive: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

OurBrands.defaultProps = {
    isActive: false,
    width: 30,
};
