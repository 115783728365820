import PropTypes from 'prop-types';

function Whole30Icon({ className, width }) {
    return (
        <svg width={width} className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.17423 3.83015C7.46676 3.64931 8.84508 3.78494 9.95677 4.46772C11.0685 5.1505 11.8498 6.44883 11.6817 7.74252C11.4707 9.36775 10.2037 11.1031 7.39721 11.641C9.86635 11.8821 11.7826 12.7341 12.1628 14.3768C12.543 16.0194 11.8451 17.822 10.5526 18.9047C9.26008 19.9874 7.45169 20.3665 5.79516 20.0546C4.13863 19.7428 2.64092 18.783 1.53038 17.5136C1.87004 17.3919 2.88436 16.9317 3.22401 16.8111C4.33454 17.6342 5.22483 18.2254 6.60314 18.339C7.98146 18.4526 9.18241 18.2799 9.72493 17.0094C10.2002 15.8953 10.3602 14.495 8.95752 13.6708C7.91306 13.0564 5.44508 12.9973 4.23485 12.9567C4.24181 12.4026 4.21514 11.3013 4.22094 10.7472C5.15643 10.6023 7.07147 10.3218 7.87829 9.82681C8.68511 9.33182 9.81535 8.51225 9.76318 7.56748C9.70638 6.56243 8.85899 5.71388 7.88524 5.45538C6.9115 5.19687 5.86008 5.4461 4.97328 5.92254C4.08647 6.39898 3.33529 7.08872 2.59455 7.7715C2.17607 7.61037 1.75759 7.4504 1.33911 7.28927C2.67801 4.51293 5.17962 3.97041 6.17423 3.8313V3.83015Z"
                className="fill-[#AEC271] if:fill-[#C5DB66]"
                stroke="#2D2D2D"
                strokeWidth="0.579612"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.5831 9.61923C22.4371 8.35568 22.2632 7.05851 21.6975 5.95493C20.2647 3.15772 16.212 3.05339 14.5114 5.56195C13.5864 6.92519 13.2989 8.69765 13.1552 10.4075C12.9697 12.6147 12.9963 14.9308 13.8472 16.9281C14.6981 18.9255 16.5598 19.934 18.5142 19.694C20.4223 19.4587 21.9119 18.1372 22.4684 16.0645C23.0248 13.9918 22.8312 11.7673 22.5831 9.61923ZM20.9243 14.9911C20.558 16.5131 19.5761 17.699 18.3195 17.8717C17.0328 18.0491 15.8063 17.0939 15.2452 15.6263C14.6842 14.1587 14.6679 12.4582 14.7897 10.8364C14.8836 9.58098 15.1595 8.3522 15.6834 7.2776C16.7789 5.03102 19.5042 4.96379 20.4177 7.56625C20.7144 8.41132 20.9046 9.32942 21.0008 10.258C21.1642 11.8357 21.2917 13.4702 20.9254 14.9922L20.9243 14.9911Z"
                className="fill-[#AEC271] if:fill-[#C5DB66]"
                stroke="#2D2D2D"
                strokeWidth="0.579612"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default Whole30Icon;

Whole30Icon.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number.isRequired,
};

Whole30Icon.defaultProps = {
    className: null,
};
