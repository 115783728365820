import PropTypes from 'prop-types';

function MemberDealsAisleBannerIcon({ width }) {
    return (
        <svg width={width} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse
                cx="35"
                cy="35"
                rx="35"
                ry="35"
                transform="rotate(-90 35 35)"
                fill="url(#paint0_linear_2854_58524)"
            />
            <path
                d="M24.8592 23.063C23.1832 26.5889 22.4465 32.3207 21.1717 36.0102C21.0059 36.4911 20.8381 36.9761 20.8054 37.4836C20.7378 38.4925 21.2044 39.4542 21.6628 40.3567C25.2807 47.4554 29.2957 54.3496 33.683 61.0002L53.6083 49.4977C53.9848 49.2808 54.1301 48.806 53.9377 48.4152C51.1486 42.7161 48.431 38.9652 43.88 31.1277C43.6467 30.7245 41.3977 26.8283 41.0355 26.5336C40.5546 26.1407 25.5959 21.5098 24.8571 23.063H24.8592Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="1.5"
                strokeMiterlimit="10"
            />
            <path
                d="M22.0229 22.3002C20.4186 20.3193 19.8272 19.0015 18.7979 16.7505C18.1164 15.2587 17.5332 13.3802 18.5441 12.0869C18.9104 11.6162 19.4527 11.2991 20.0257 11.1374C21.3456 10.7629 22.7944 11.1906 23.9178 11.9764C25.0413 12.7642 25.8905 13.8774 26.6701 15.005C28.4505 17.5875 29.9668 20.3664 31.0043 23.3254C31.3604 24.3384 31.6612 25.4209 31.4565 26.4727C31.2519 27.5266 30.3904 28.5252 29.3181 28.5375"
                stroke="#2D2D2D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M45.2446 42.6972C45.1505 43.8052 44.8259 44.8803 44.226 45.7531C43.4073 46.9411 42.1393 47.6798 40.8761 48.3314C36.4793 50.6062 31.6991 51.0344 28.5256 45.7413C26.6177 42.5584 26.3942 39.0909 28.455 36.2891C33.0941 29.9821 46.0586 33.085 45.2446 42.6972Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M33.4976 39.8552C33.4795 40.0669 33.416 40.2725 33.3011 40.4418C33.1439 40.6686 32.902 40.8107 32.6601 40.9377C31.8165 41.3731 30.9003 41.4547 30.2926 40.4418C29.9267 39.831 29.8844 39.1658 30.2774 38.6306C31.1664 37.4212 33.6518 38.0168 33.4976 39.8582V39.8552Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M41.4976 42.8552C41.4795 43.0669 41.416 43.2725 41.3011 43.4418C41.1439 43.6686 40.902 43.8107 40.6601 43.9377C39.8165 44.3731 38.9003 44.4547 38.2926 43.4418C37.9267 42.831 37.8844 42.1658 38.2774 41.6306C39.1664 40.4212 41.6518 41.0168 41.4976 42.8582V42.8552Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M37.4556 35.4424C36.6511 37.2561 36.31 39.2346 35.9736 41.1895C35.6113 43.3067 35.249 45.4239 34.8867 47.5412"
                stroke="#2D2D2D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_2854_58524"
                    x1="67.5"
                    y1="-0.999965"
                    x2="9.00001"
                    y2="71.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F594A4" />
                    <stop offset="0.464323" stopColor="#A13051" />
                    <stop offset="1" stopColor="#6784AC" />
                </linearGradient>
            </defs>
        </svg>
    );
}

MemberDealsAisleBannerIcon.propTypes = {
    width: PropTypes.number,
};

MemberDealsAisleBannerIcon.defaultProps = {
    width: 50,
};

export { MemberDealsAisleBannerIcon };
