export const dietaryFilterData = [
    {
        name: 'New',
    },
    {
        name: 'Sale',
    },
    {
        name: 'Rescued',
    },
    {
        name: 'Best Sellers',
    },
    {
        name: 'Last Chance',
    },
    {
        name: 'Diet',
        options: [
            {
                name: 'Non-GMO',
            },
            {
                name: 'Gluten-Free',
            },
            {
                name: 'Organic',
            },
            {
                name: 'Vegan',
            },
            {
                name: 'Kosher',
            },
            {
                name: 'Plant-Based',
            },
            {
                name: 'Vegetarian',
            },
            {
                name: 'Keto-Friendly',
            },
            {
                name: 'Paleo-Friendly',
            },
            {
                name: 'Whole30-Friendly',
            },
        ],
    },
    {
        name: 'Impact',
        options: [
            {
                name: 'Certified B Corporation',
            },
            {
                name: 'Upcycled Certified',
            },
            {
                name: 'Fair Trade',
            },
            {
                name: 'Direct Trade',
            },
            {
                name: 'Minority Owned or Founded',
            },
            {
                name: 'Woman Owned or Founded',
            },
        ],
    },
    {
        name: 'In Stock',
    },
];
