import { useContext, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useInView } from 'react-intersection-observer';
import { useLocation, useNavigate } from 'react-router';

import useAccountQuery from 'api/queries/useAccountQuery';
import useCreditsQuery from 'api/queries/useCreditsQuery';
import useDiscountLadderQuery from 'api/queries/useDiscountLadderQuery';
import useSelectionsQuery from 'api/queries/useSelectionsQuery';
import useSubscriptionQuery from 'api/queries/useSubscriptionQuery';
import useClearAllSelections from 'api/useClearAllSelections';
import { MIN_WINE_BOTTLE_QTY_TO_SHIP } from 'constants/shop';
import { GlobalContext } from 'contexts/GlobalContext';
import CartCTAs from 'features/shop/cart/CartCTAs';
import CartEmpty from 'features/shop/cart/CartEmpty';
import CartGroceryOrderCard from 'features/shop/cart/CartGroceryOrderCard';
import CartHeader from 'features/shop/cart/CartHeader';
import CartPrices from 'features/shop/cart/CartPrices';
import { CartPricesPlusMembership } from 'features/shop/cart/CartPricesPlusMembership';
import CartWineOrderCard from 'features/shop/cart/CartWineOrderCard';
import { PlusMembershipProspectBanner } from 'features/shop/cart/plusMembershipBanners';
import { Accordion } from 'honeydew/Accordion';
import { Drawer } from 'honeydew/Drawer';
import AutoFilledIcon from 'honeydew/icons/FoodPreferences/AutoFilledIcon';
import NeverIcon from 'honeydew/icons/FoodPreferences/NeverIcon';
import RecurringIcon from 'honeydew/icons/FoodPreferences/RecurringIcon';
import Loader from 'honeydew/Loader';
import Tag from 'honeydew/Tag';
import { useAccountPlusMembershipHook } from 'hooks/useAccountPlusMembershipHook/useAccountPlusMembershipHook';
import UpSellsCarousel from 'shared/UpSellsCarousel';
import { trackEvent } from 'utils/analyticsUtils';
import { getOrderSessionStorage } from 'utils/orderSessionStorageUtils';
import { getGroceryItems, getItemsTotalCost, getOrderMin, getTotalWineBottleQty } from 'utils/orderUtils';
import { getProductsSubtotal, PriceType } from 'utils/priceUtils';

import { AddToNeverListSection } from './AddToNeverListSection';
import FaqInfoLine from './FaqInfoLine';

function Cart() {
    const navigate = useNavigate();
    const location = useLocation();
    const { cartOpen, setCartOpen } = useContext(GlobalContext);

    const { data: account = {}, isLoading: accountIsLoading } = useAccountQuery();
    const { data: credits = {}, isLoading: creditsIsLoading } = useCreditsQuery();
    const { data: discounts, isLoading: discountsIsLoading } = useDiscountLadderQuery();
    const { data: selections = {}, isLoading: selectionsIsLoading } = useSelectionsQuery();
    const { data: subscription, isLoading: subscriptionIsLoading } = useSubscriptionQuery();

    const isCartPage = location.pathname === '/cart';

    useEffect(() => {
        if (!window.isNativeApp && isCartPage) {
            setCartOpen(true);
            navigate('/shop');
        } else if (window.isNativeApp) {
            trackEvent('view cart drawer');
        }
    }, []);

    useEffect(() => {
        if (cartOpen && !isCartPage) {
            trackEvent('view cart drawer');
        }
    }, [cartOpen]);

    if (accountIsLoading || creditsIsLoading || discountsIsLoading || selectionsIsLoading || subscriptionIsLoading) {
        return window.isNativeApp && isCartPage ? (
            <CartLoader />
        ) : (
            <Drawer anchor="right" open={cartOpen} toggleDrawer={setCartOpen}>
                <CartLoader />
            </Drawer>
        );
    }

    return window.isNativeApp && isCartPage ? (
        <CartContents
            account={account}
            credits={credits}
            discounts={discounts}
            subscription={subscription}
            selections={selections}
        />
    ) : (
        <Drawer anchor="right" open={cartOpen} toggleDrawer={setCartOpen}>
            <CartContents
                account={account}
                credits={credits}
                discounts={discounts}
                subscription={subscription}
                selections={selections}
            />
        </Drawer>
    );
}

function CartLoader() {
    return (
        <div
            className={clsx('flex grow flex-col items-center justify-center', {
                'w-[375px] bg-white': !window.isNativeApp,
                'h-[360px] w-screen': window.isNativeApp,
            })}
        >
            <Loader />
        </div>
    );
}

export function CartContents({ account, credits, discounts, selections, subscription }) {
    const coldPackSectionRef = useRef();
    const wineOrderCardRef = useRef();

    const { clearAllSelections } = useClearAllSelections();

    const { inPlusMembershipTreatment, plusMembershipEligibleOrder, plusMembershipShortBranding, shippingFees } =
        useAccountPlusMembershipHook();
    const { plusMembershipShippingFee } = shippingFees ?? {};

    const { freeShippingEligible, freeShippingThreshold } = account.nextOrder.cartExperience ?? {};
    const { selected, selectedBundles } = selections;
    const fullSelectionsList = [...selected, ...selectedBundles];
    const groceryItems = getGroceryItems(selections);

    const priceType = plusMembershipEligibleOrder ? PriceType.PLUS_MEMBER : PriceType.DEFAULT;

    const groceryItemsTotalCost = inPlusMembershipTreatment
        ? getProductsSubtotal({
              options: { priceType },
              products: groceryItems,
          })
        : getItemsTotalCost(groceryItems);

    const totalWineBottleQty = getTotalWineBottleQty(selections);
    const orderMin = getOrderMin(account);
    const orderMinMet = groceryItemsTotalCost >= orderMin;

    const {
        entry: plusMembershipStaticBannerEntry,
        inView: plusMembershipStaticBannerInView,
        ref: plusMembershipStaticBannerRef,
    } = useInView();

    const { cartMinimum, featureFlags } = account ?? {};

    const { hideCuratedCartBanner } = getOrderSessionStorage();
    const allSelectionsCurated =
        fullSelectionsList.length > 0 &&
        fullSelectionsList.every(
            (selection) =>
                selection.source === 'autopilot_risk' ||
                selection.source === 'personalization' ||
                selection.source === 'recurring'
        );

    const cartFAQs = [
        {
            id: 'how-do-i-check-out',
            headline: 'How do I check out?',
            content: "You don't! After your shopping window closes, your cart will be auto-processed and shipped.",
        },
        {
            id: 'what-is-the-order-minimum',
            headline: 'What is the order minimum?',
            content: `$${cartMinimum} for groceries (does not include wine). If your cart has a subtotal of at least $${cartMinimum} when your shopping window closes, we'll ship your items.`,
        },
        {
            id: 'why-tip-our-drivers',
            headline: 'Why tip our drivers?',
            content:
                'Our mission is to make shopping for sustainable, high-quality groceries easier—and our drivers are critical in providing the best customer experience. We introduced our optional tipping feature as a way for you to show your appreciation to the folks doing the heavy lifting.',
        },
    ];

    const filteredCartFAQs = featureFlags?.featureCanAddDriverTips
        ? cartFAQs
        : cartFAQs.filter((faq) => faq.id !== 'why-tip-our-drivers');

    const getCartUpSellsCarouselType = () => {
        if (groceryItemsTotalCost === 0) return 'empty_cart';
        if (!orderMinMet) return 'reach_order_minimum';
        if (orderMinMet && groceryItemsTotalCost < freeShippingThreshold && freeShippingEligible)
            return 'reach_free_shipping_threshold';
        return 'top_sellers';
    };

    const cartUpSellCarouselType = getCartUpSellsCarouselType();

    const handleClearAllSelections = () => {
        clearAllSelections();
        trackEvent('autofill cart clear');
    };

    const showCartPrices =
        (groceryItems.length > 0 && groceryItemsTotalCost >= orderMin) ||
        totalWineBottleQty >= MIN_WINE_BOTTLE_QTY_TO_SHIP;

    return (
        <div
            className={clsx('relative flex max-w-[100vw] grow flex-col bg-off-white', {
                'w-[375px]': !window.isNativeApp,
                'w-screen': window.isNativeApp,
            })}
        >
            <CartHeader account={account} selections={selections} />
            <div className="flex flex-col gap-10 px-15 py-10">
                {subscription?.variant === 'autoship' && !hideCuratedCartBanner && allSelectionsCurated && (
                    <div className="text-body-xs flex gap-5 rounded-md border border-success bg-success/5 px-10 py-5">
                        We&apos;ve curated your order for you.
                        <button
                            onClick={handleClearAllSelections}
                            className="font-grotesk-bold underline"
                            type="button"
                        >
                            Start from Scratch
                        </button>
                    </div>
                )}
                {groceryItems.length <= 0 && totalWineBottleQty <= 0 && <CartEmpty />}
                {groceryItems.length > 0 && (
                    <CartGroceryOrderCard
                        account={account}
                        coldPackSectionRef={coldPackSectionRef}
                        selections={selections}
                    />
                )}
                {totalWineBottleQty > 0 && (
                    <CartWineOrderCard selections={selections} wineOrderCardRef={wineOrderCardRef} />
                )}
            </div>
            <UpSellsCarousel name="Missing something?" position="cart" type={cartUpSellCarouselType} />

            {inPlusMembershipTreatment && showCartPrices && (
                <CartPricesPlusMembership
                    account={account}
                    coldPackSectionRef={coldPackSectionRef}
                    credits={credits}
                    discounts={discounts}
                    plusMembershipEligibleOrder={plusMembershipEligibleOrder}
                    plusMembershipShippingFee={plusMembershipShippingFee}
                    plusMembershipShortBranding={plusMembershipShortBranding}
                    plusMembershipStaticBannerRef={plusMembershipStaticBannerRef}
                    selections={selections}
                    wineOrderCardRef={wineOrderCardRef}
                />
            )}
            {!inPlusMembershipTreatment && showCartPrices && (
                <CartPrices
                    account={account}
                    coldPackSectionRef={coldPackSectionRef}
                    credits={credits}
                    discounts={discounts}
                    selections={selections}
                    wineOrderCardRef={wineOrderCardRef}
                />
            )}
            <AddToNeverListSection />
            {(groceryItems.length > 0 || totalWineBottleQty > 0) && (
                <CartCTAs account={account} selections={selections} />
            )}
            {subscription?.variant === 'autoship' && (
                <div className="mb-10 flex grow flex-col gap-10 bg-white px-20 py-40">
                    <div className="flex">
                        <Tag bgColor="default" variant="dark">
                            <span>NEW</span>
                        </Tag>
                        <h2 className="text-body-lg ml-10 font-grotesk-bold">Food Preferences</h2>
                    </div>
                    <FaqInfoLine
                        icon={<AutoFilledIcon width={24} brandColors />}
                        title="Pre-filled"
                        subtitle="Items we pick just for you each week."
                        backgroundClasses="bg-lime if:bg-strawberry"
                    />
                    <FaqInfoLine
                        icon={<RecurringIcon width={23} />}
                        title="Recurring"
                        subtitle="Items we’ll always add to your cart if in-stock, in addition to pre-filled items."
                        backgroundClasses="bg-[#FAEAC0]"
                    />
                    <FaqInfoLine
                        icon={<NeverIcon width={21} />}
                        title="Never"
                        subtitle="Items we’ll never add to your cart—pre-filled or otherwise!"
                        backgroundClasses="bg-error bg-opacity-50"
                    />
                </div>
            )}
            <div className="grow bg-white px-20 py-40">
                <h2 className="text-body-lg font-grotesk-bold">How Cart Works</h2>
                <Accordion defaultValue="how-do-i-check-out" items={filteredCartFAQs} textSize="sm" />
            </div>
            {inPlusMembershipTreatment && !plusMembershipEligibleOrder && (
                <PlusMembershipProspectBanner
                    sticky
                    hide={plusMembershipStaticBannerInView}
                    intersectionEntry={plusMembershipStaticBannerEntry}
                />
            )}
        </div>
    );
}

export default Cart;
