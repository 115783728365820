import PropTypes from 'prop-types';

function ReorderIcon({ width }) {
    return (
        <svg width={width} viewBox="0 0 68 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M37.2483 58.6071C52.7868 54.8296 68.0038 45.7101 68.0038 29.7162C68.0038 13.7223 55.0265 3.98125 37.2483 0.691374C21.5223 -2.21272 11.1919 12.8382 8.28779 29.6519C5.56587 45.4101 22.4278 62.2131 37.243 58.6071"
                className="fill-[#F0C24A] if:fill-[#FFDE7C]"
            />
            <g clipPath="url(#clip0_7527_69078)">
                <path
                    d="M18.3965 25.1788L17.1758 21.196C18.4612 20.7848 45.4149 18.2613 47.719 18.2372C50.0231 18.213 50.9528 19.4626 51.066 20.938C51.1792 22.4134 49.2066 34.9341 48.77 36.0951C48.3334 37.2561 47.4361 38.804 45.9323 39.1023C44.4286 39.4006 27.9039 40.997 25.9555 41.126C24.0072 41.255 18.7523 26.8719 18.3965 25.1788Z"
                    fill="white"
                    stroke="#2D2D2D"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M22.8269 20.6236C22.8269 20.6236 23.9749 23.3406 26.5215 29.8791C29.0681 36.4176 30.7093 40.6987 30.7093 40.6987"
                    fill="white"
                />
                <path
                    d="M22.8269 20.6236C22.8269 20.6236 23.9749 23.3406 26.5215 29.8791C29.0681 36.4176 30.7093 40.6987 30.7093 40.6987"
                    stroke="#2D2D2D"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M33.9431 19.4949C33.9431 19.4949 34.3312 22.5827 34.8728 28.5972C35.4145 34.6116 35.8591 36.2483 36.0774 40.1504"
                    fill="white"
                />
                <path
                    d="M33.9431 19.4949C33.9431 19.4949 34.3312 22.5827 34.8728 28.5972C35.4145 34.6116 35.8591 36.2483 36.0774 40.1504"
                    stroke="#2D2D2D"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M43.5556 18.5435C43.5556 18.5435 43.7982 17.9147 43.6769 26.114C43.5362 30.6387 43.0252 35.1443 42.1489 39.5861"
                    fill="white"
                />
                <path
                    d="M43.5556 18.5435C43.5556 18.5435 43.7982 17.9147 43.6769 26.114C43.5362 30.6387 43.0252 35.1443 42.1489 39.5861"
                    stroke="#2D2D2D"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19.3911 28.1538C20.4664 27.7506 25.7132 26.638 32.9892 26.2591C40.2653 25.8802 47.3796 24.9127 50.7751 24.6467"
                    fill="white"
                />
                <path
                    d="M19.3911 28.1538C20.4664 27.7506 25.7132 26.638 32.9892 26.2591C40.2653 25.8802 47.3796 24.9127 50.7751 24.6467"
                    stroke="#2D2D2D"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M22.5278 35.9822C23.8618 35.7081 29.8686 34.9422 39.0768 34.3053C48.285 33.6684 49.4007 33.1362 49.4007 33.1362"
                    fill="white"
                />
                <path
                    d="M22.5278 35.9822C23.8618 35.7081 29.8686 34.9422 39.0768 34.3053C48.285 33.6684 49.4007 33.1362 49.4007 33.1362"
                    stroke="#2D2D2D"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.3967 25.1788C18.3967 25.1788 16.3351 17.3019 14.8233 16.5683C13.3115 15.8346 10.9994 16.3667 9.34204 16.8101"
                    stroke="#2D2D2D"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M25.9556 41.126C25.2514 41.2696 24.5384 41.3666 23.8213 41.4162C21.396 41.6661 21.7436 46.0036 24.2822 45.9633C26.8207 45.923 44.453 44.4718 45.415 44.2622"
                    stroke="#2D2D2D"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M45.7303 50.5104C47.4627 50.5104 48.8671 49.1099 48.8671 47.3823C48.8671 45.6546 47.4627 44.2541 45.7303 44.2541C43.9979 44.2541 42.5935 45.6546 42.5935 47.3823C42.5935 49.1099 43.9979 50.5104 45.7303 50.5104Z"
                    className="fill-[#B0C472] if:fill-[#F6A685]"
                    stroke="#2D2D2D"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M28.8741 52.2116C30.6065 52.2116 32.0109 50.8111 32.0109 49.0834C32.0109 47.3558 30.6065 45.9553 28.8741 45.9553C27.1417 45.9553 25.7373 47.3558 25.7373 49.0834C25.7373 50.8111 27.1417 52.2116 28.8741 52.2116Z"
                    className="fill-[#B0C472] if:fill-[#F6A685]"
                    stroke="#2D2D2D"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.92015 14.1205L2.76452 14.6427C1.44078 14.7768 0.476678 15.9557 0.611139 17.2758L0.638099 17.5405C0.77256 18.8606 1.95466 19.822 3.27841 19.6879L8.43404 19.1657C9.75778 19.0316 10.7219 17.8527 10.5874 16.5326L10.5605 16.2679C10.426 14.9478 9.2439 13.9864 7.92015 14.1205Z"
                    className="fill-[#B0C472] if:fill-[#F6A685]"
                    stroke="#2D2D2D"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M56.5567 29.2588C60.7804 23.1761 59.2596 14.8361 53.1651 10.6286C47.0706 6.4212 38.7166 7.94405 34.4928 14.0268"
                    stroke="#2D2D2D"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M34.8743 9.46578L34.1394 14.3696L39.3104 14.2313"
                    stroke="#2D2D2D"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_7527_69078">
                    <rect width="62" height="58" fill="white" transform="translate(-0.00366211 3.33002)" />
                </clipPath>
            </defs>
        </svg>
    );
}

ReorderIcon.propTypes = {
    width: PropTypes.number,
};

ReorderIcon.defaultProps = {
    width: 61,
};

export default ReorderIcon;
