import { useContext } from 'react';
import { useMatch, useNavigate } from 'react-router';

import { GlobalContext } from 'contexts/GlobalContext';
import Button from 'honeydew/Button';

function CartEmpty() {
    const navigate = useNavigate();
    const isShopRoute = useMatch('/shop/*');
    const { setCartOpen } = useContext(GlobalContext);

    const navigateToShop = () => {
        if (window.isNativeApp) {
            window.ReactNativeWebView?.postMessage(JSON.stringify({ navigate: 'Shop' }));
        } else {
            setCartOpen(false);
            if (!isShopRoute) {
                navigate('/shop');
            }
        }
    };

    return (
        <div className="flex flex-col items-center rounded-lg bg-white px-20 py-25">
            <img
                alt="Empty cart"
                className="w-[200px]"
                src="https://img-cdn.misfitsmarket.com/boysenberry/images/empty-cart.png"
            />
            <p className="text-body-lg mt-20 font-grotesk-bold">Your cart is empty</p>
            <p className="text-body-sm mb-20 mt-5">Shop our inventory for the week.</p>
            <Button onClick={navigateToShop} size="md">
                Start Shopping
            </Button>
        </div>
    );
}

export default CartEmpty;
