import { Suspense } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Outlet } from 'react-router';

import useAccountQuery from 'api/queries/useAccountQuery';
import CartSheet from 'features/shop/cart/CartSheet';
import Loader from 'honeydew/Loader';
import useGlobalStore from 'hooks/useGlobalStore';
import useTailwindTheme from 'hooks/useTailwindTheme';

function ProductDetailPageLayout() {
    const { data: account } = useAccountQuery();
    const { autocompleteOpen, searchQuery } = useGlobalStore();

    const tailwindTheme = useTailwindTheme();
    const mobile = useMediaQuery({ maxWidth: tailwindTheme?.screens?.md });
    const searchAutocompleteOpen = autocompleteOpen && searchQuery !== '';

    if (!account?.nextOrder?.chargeID) {
        return (
            <div className="mt-[140px] flex justify-center">
                <Loader />
            </div>
        );
    }

    return (
        <Suspense fallback={<Loader />}>
            {!mobile && !searchAutocompleteOpen && <CartSheet orderMinOnly />}
            <Outlet />
        </Suspense>
    );
}

export default ProductDetailPageLayout;
