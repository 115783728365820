import PropTypes from 'prop-types';

const sizeClasses = {
    50: 'min-w-50',
};

function PlusBenefitsWeeklyDealsIcon({ width }) {
    return (
        <svg
            width={width}
            height={width}
            className={sizeClasses[width]}
            viewBox="0 0 51 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse
                cx="25.5"
                cy="25"
                rx="25"
                ry="25"
                transform="rotate(-90 25.5 25)"
                fill="url(#paint0_linear_2218_88251)"
            />
            <path
                d="M26.0758 39.6498C32.0604 39.6498 36.9119 34.7983 36.9119 28.8137C36.9119 22.8291 32.0604 17.9777 26.0758 17.9777C20.0912 17.9777 15.2397 22.8291 15.2397 28.8137C15.2397 34.7983 20.0912 39.6498 26.0758 39.6498Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="1.07143"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M23.7418 41.1575C29.7264 41.1575 34.5779 36.306 34.5779 30.3214C34.5779 24.3368 29.7264 19.4854 23.7418 19.4854C17.7572 19.4854 12.9058 24.3368 12.9058 30.3214C12.9058 36.306 17.7572 41.1575 23.7418 41.1575Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="1.07143"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M25.9167 27.7817C25.8931 24.9993 20.9219 25.0431 20.9388 27.7817C20.9556 30.4022 22.1833 30.2335 23.5019 30.1323C26.1427 29.93 26.979 31.5421 26.3821 33.4172C25.6975 35.5689 21.2018 35.4273 20.9253 31.994"
                fill="white"
            />
            <path
                d="M25.9167 27.7817C25.8931 24.9993 20.9219 25.0431 20.9388 27.7817C20.9556 30.4022 22.1833 30.2335 23.5019 30.1323C26.1427 29.93 26.979 31.5421 26.3821 33.4172C25.6975 35.5689 21.2018 35.4273 20.9253 31.994"
                stroke="#2D2D2D"
                strokeWidth="1.07143"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.9897 23.4446L24.3287 37.1979"
                stroke="#2D2D2D"
                strokeWidth="1.07143"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26.4199 19.809L28.5581 18.2441"
                stroke="#2D2D2D"
                strokeWidth="1.07143"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M30.1162 20.9792L31.8497 19.7043"
                stroke="#2D2D2D"
                strokeWidth="1.07143"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M32.3115 23.7312L34.4632 21.9875"
                stroke="#2D2D2D"
                strokeWidth="1.07143"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M34.3691 26.5L36.0588 24.8508"
                stroke="#2D2D2D"
                strokeWidth="1.07143"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M34.5815 31.1779L36.9154 28.8137"
                stroke="#2D2D2D"
                strokeWidth="1.07143"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.9136 15.6201C17.1682 14.1834 16.3892 13.0704 16.3892 13.0704"
                stroke="white"
                strokeWidth="1.07143"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26.5308 13.5932L26.5948 8.57141"
                stroke="white"
                strokeWidth="1.07143"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M34.3823 16.5983L36.045 14.6388"
                stroke="white"
                strokeWidth="1.07143"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_2218_88251"
                    x1="48.7143"
                    y1="-0.71428"
                    x2="6.92858"
                    y2="51.0714"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F594A4" />
                    <stop offset="0.464323" stopColor="#A13051" />
                    <stop offset="1" stopColor="#6784AC" />
                </linearGradient>
            </defs>
        </svg>
    );
}

PlusBenefitsWeeklyDealsIcon.propTypes = {
    width: PropTypes.number,
};

PlusBenefitsWeeklyDealsIcon.defaultProps = {
    width: 100,
};

export default PlusBenefitsWeeklyDealsIcon;
