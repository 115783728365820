import { referFriend } from 'api/jalapeno';

const action = async ({ request }) => {
    const formData = await request.formData();
    const { customerID, email } = Object.fromEntries(formData);

    try {
        const response = await referFriend(customerID, { email });
        if (response.msg === 'Success') {
            return { success: true, message: '' };
        }
        throw new Error(response);
    } catch {
        return {
            error: true,
            message: 'Oops! The entered email cannot be referred.',
        };
    }
};

export default action;
