import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useQueries } from '@tanstack/react-query';

import { checkDiscountCode } from 'api/jalapeno';
import { setSignupLocalStorage } from 'utils/signupUtils';

import { useApplyOptimizelyPromo } from './useApplyOptimizelyPromo';
import { useIsSignupNanoBannerRoute } from './useIsSignupNanoBannerRoute';
import { getPrioritizedPromoCodes, persistAppliedPromoCode } from './utils';

const PromoCodeContext = createContext(null);

/**
 * Use the promo code context.
 * You can:
 * 1. Get the currently applied promo data with appliedPromoCodeData
 * 2. Set an applied promo's data with setAppliedPromoCodeData(), like after a form submission. You should validate the promo before setting it.
 * 3. Persist the promo code (to local storage) with persistAppliedPromoCode(). You'll generally want to do this alongside setAppliedPromoCodeData().
 * @example const { appliedPromoCodeData, setAppliedPromoCodeData, persistAppliedPromoCode, renderSignupNanoBanner } = usePromoCodeContext();
 * @returns {PromoCodeContext}
 */
export function usePromoCodeContext() {
    return useContext(PromoCodeContext);
}

function PromoCodeProvider({ children }) {
    const [appliedPromoCodeData, setAppliedPromoCodeData] = useState({});
    const [renderSignupNanoBanner, setRenderSignupNanoBanner] = useState(false);
    const isSignupNanoBannerRoute = useIsSignupNanoBannerRoute();

    const prioritizedPromoCodes = getPrioritizedPromoCodes();

    const queries = useQueries({
        queries: prioritizedPromoCodes?.map((code) => ({
            queryKey: ['promo', code],
            queryFn: () => checkDiscountCode(null, code),
            enabled: isSignupNanoBannerRoute && prioritizedPromoCodes?.length > 0,
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 60, // 1 hour
        })),
    });

    const queriesFetching = queries.some((result) => result.isFetching);

    useApplyOptimizelyPromo({
        queriedPromoCodes: queries,
        queriedPromoCodesFetching: queriesFetching,
        setAppliedPromoCodeData,
    });

    useEffect(() => {
        if (queriesFetching) return;

        const priorityPromo = queries.find((query) => query?.data?.data?.disc?.valid);

        if (priorityPromo) {
            setAppliedPromoCodeData(priorityPromo.data.data);
            persistAppliedPromoCode(priorityPromo.data.data.disc.code);
        } else {
            setSignupLocalStorage({ discountCode: '' });
        }
    }, [queriesFetching]);

    useEffect(() => {
        setRenderSignupNanoBanner(isSignupNanoBannerRoute && !!appliedPromoCodeData?.disc?.valid);
    }, [appliedPromoCodeData?.disc?.valid, isSignupNanoBannerRoute]);

    const value = useMemo(
        () => ({ appliedPromoCodeData, setAppliedPromoCodeData, persistAppliedPromoCode, renderSignupNanoBanner }),
        [appliedPromoCodeData, setAppliedPromoCodeData, persistAppliedPromoCode, renderSignupNanoBanner]
    );

    return <PromoCodeContext.Provider value={value}>{children}</PromoCodeContext.Provider>;
}

export default PromoCodeProvider;
