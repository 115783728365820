import { useQuery } from '@tanstack/react-query';

import { getSubscription } from 'api/jalapeno';

import useAccountQuery from './useAccountQuery';

export const SUBSCRIPTION_QUERY_KEY = 'subscription';

export const subscriptionQueryFn = async (account) => {
    const response = await getSubscription(account?.customerID);

    if (response?.msg !== 'Success') {
        throw new Error('Unable to fetch subscription.');
    }

    return response?.data?.subscriptions?.[0];
};

const useSubscriptionQuery = () => {
    const { data: account } = useAccountQuery();

    const query = useQuery({
        queryKey: [SUBSCRIPTION_QUERY_KEY, account?.customerID],
        queryFn: async () => subscriptionQueryFn(account),
        enabled: !!account?.customerID,
    });

    return query;
};

export default useSubscriptionQuery;
