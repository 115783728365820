import { logError as sentryLogError } from './sentryUtils';

/**
 * Logs an error to available error handlers
 * @param {Error} error - The error object to log
 * @param {Object} [context=null] - Additional context information for the error
 * @example
 * const error = new Error('Something went wrong');
 * const context = { page: 'home', action: 'click' };
 * logError(error, context);
 */
function logError(error, context = null) {
    sentryLogError(error, context);
}

export default logError;
