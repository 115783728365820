import PropTypes from 'prop-types';

function DairyFreeIcon({ className, width }) {
    return (
        <svg width={width} className={className} viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.2778 3.23027C1.20841 3.235 1.13587 3.24131 1.08225 3.28389C1.00182 3.34855 0.995515 3.46524 1.00182 3.56775C1.0791 4.74103 2.15303 5.70142 3.31843 5.85912C4.48383 6.01682 5.66815 5.49011 6.51657 4.6748C6.7547 4.44613 7.73086 3.75699 7.68197 3.36905C7.61101 2.80764 6.41565 3.05838 6.00878 3.05838C4.43021 3.06311 2.85321 3.11988 1.27937 3.2287L1.2778 3.23027Z"
                className="fill-[#DEA455] if:fill-[#F6A685]"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.6735 3.23027C19.7429 3.235 19.8155 3.24131 19.8691 3.28389C19.9495 3.34855 19.9558 3.46524 19.9495 3.56775C19.8722 4.74103 18.7983 5.70142 17.6329 5.85912C16.4675 6.01682 15.2832 5.49011 14.4348 4.6748C14.1966 4.44613 13.2205 3.75699 13.2694 3.36905C13.3403 2.80764 14.5357 3.05838 14.9425 3.05838C16.5211 3.06311 18.0981 3.11988 19.6719 3.2287L19.6735 3.23027Z"
                className="fill-[#DEA455] if:fill-[#F6A685]"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.0524 12.1639C16.7386 10.1296 16.4248 8.09687 16.1094 6.06255C16.0006 5.35448 15.9139 4.3042 15.4171 3.73963C14.9267 3.18138 13.9805 3.21923 13.2692 3.1388C11.3138 2.91644 9.32675 2.96533 7.38547 3.28704C7.10003 3.33435 6.80513 3.39112 6.57174 3.56301C6.26422 3.78852 6.11914 4.16858 5.99298 4.52813C5.12248 7.04186 4.50745 9.64548 4.15894 12.2838C8.38213 13.0628 12.8797 13.1291 17.0524 12.1639Z"
                className="fill-[#FFF9EA] if:fill-[#F8F2ED]"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.0526 12.1639C12.8799 13.1275 8.38074 13.0628 4.15913 12.2838C4.14651 12.38 4.13074 12.4762 4.1197 12.5724C4.01405 13.424 3.93993 14.3213 4.2711 15.1145C4.67796 16.0891 5.64623 16.7404 6.66182 17.029C7.67898 17.3176 8.75291 17.2939 9.8095 17.2702C10.8251 17.2466 11.8422 17.2229 12.8578 17.1993C13.6148 17.1819 14.3828 17.163 15.1114 16.9533C15.8399 16.7451 16.5385 16.3225 16.9202 15.668C17.4295 14.7944 17.2908 13.7031 17.1362 12.7033C17.1078 12.5235 17.081 12.3453 17.0526 12.1655V12.1639Z"
                className="fill-[#DEA455] if:fill-[#F6A685]"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.0121 14.9535C12.9774 14.4363 13.3354 13.9332 13.8164 13.744C14.6995 13.397 15.663 14.5025 15.1568 15.321C14.6222 16.1883 13.0878 16.1 13.0105 14.9535H13.0121Z"
                className="fill-[#FFF9EA] if:fill-[#F8F2ED]"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.01038 14.9535C5.97569 14.4363 6.33366 13.9332 6.81465 13.744C7.69776 13.397 8.66131 14.5025 8.15509 15.321C7.62049 16.1883 6.08607 16.1 6.0088 14.9535H6.01038Z"
                className="fill-[#FFF9EA] if:fill-[#F8F2ED]"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.33535 2.46382C5.24388 1.94342 5.15241 1.42301 5.06095 0.902599C5.04676 0.822172 5.03256 0.737015 5.06726 0.661319C5.11772 0.549352 5.25334 0.505196 5.37477 0.500465C5.75325 0.487849 6.086 0.733861 6.37774 0.975141C7.14731 1.61225 7.87431 2.30139 8.55084 3.03627C8.58869 3.07727 8.62811 3.11985 8.64073 3.17505C8.6628 3.25863 8.61865 3.34536 8.56976 3.41633C8.29852 3.809 7.84434 4.02347 7.41067 4.22217C7.12208 4.35464 6.83349 4.48553 6.5449 4.618C5.95353 4.88766 5.86679 4.56911 5.72959 4.09759C5.5719 3.55353 5.43312 3.02366 5.33535 2.46382Z"
                className="fill-[#FFF9EA] if:fill-[#F8F2ED]"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.3207 2.46382C16.4122 1.94342 16.5037 1.42301 16.5951 0.902599C16.6093 0.822172 16.6235 0.737015 16.5888 0.661319C16.5384 0.549352 16.4027 0.505196 16.2813 0.500465C15.9028 0.487849 15.5701 0.733861 15.2784 0.975141C14.5088 1.61225 13.7818 2.30139 13.1053 3.03627C13.0674 3.07727 13.028 3.11985 13.0154 3.17505C12.9933 3.25863 13.0374 3.34536 13.0863 3.41633C13.3576 3.809 13.8117 4.02347 14.2454 4.22217C14.534 4.35464 14.8226 4.48553 15.1112 4.618C15.7026 4.88766 15.7893 4.56911 15.9265 4.09759C16.0842 3.55353 16.223 3.02366 16.3207 2.46382Z"
                className="fill-[#FFF9EA] if:fill-[#F8F2ED]"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.59863 8.80014C6.95346 8.40905 7.48648 8.18827 8.0132 8.2135C8.53991 8.23873 9.05086 8.51155 9.36468 8.93419"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.8848 8.80014C12.2396 8.40905 12.7726 8.18827 13.2993 8.2135C13.826 8.23873 14.337 8.51155 14.6508 8.93419"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle cx="18" cy="15.5" r="5.72" fill="#DC6B31" stroke="#2D2D2D" strokeWidth="0.56" />
            <path
                d="M18.0315 16.6089L16.8546 18.5H15L17.0467 15.329L15.2114 12.5H16.9771L18.0138 14.165L19.0498 12.5H20.8331L18.9617 15.3282L21 18.4992H19.2075L18.0306 16.6081L18.0315 16.6089Z"
                fill="white"
            />
        </svg>
    );
}

export default DairyFreeIcon;

DairyFreeIcon.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number.isRequired,
};

DairyFreeIcon.defaultProps = {
    className: null,
};
