import { useCallback, useEffect, useState } from 'react';

import { NextIcon, PreviousIcon } from 'honeydew/icons/Carousels';
import twclsx from 'utils/twclsx';

export const usePrevNextButtons = (emblaApi) => {
    const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
    const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

    const onPrevButtonClick = useCallback(() => {
        if (!emblaApi) return;
        emblaApi.scrollPrev();
    }, [emblaApi]);

    const onNextButtonClick = useCallback(() => {
        if (!emblaApi) return;
        emblaApi.scrollNext();
    }, [emblaApi]);

    const onSelect = useCallback(() => {
        setPrevBtnDisabled(!emblaApi.canScrollPrev());
        setNextBtnDisabled(!emblaApi.canScrollNext());
    }, [emblaApi]);

    useEffect(() => {
        if (!emblaApi) return;
        onSelect(emblaApi);
        emblaApi.on('reInit', onSelect).on('select', onSelect);
    }, [emblaApi, onSelect]);

    return {
        prevBtnDisabled,
        nextBtnDisabled,
        onPrevButtonClick,
        onNextButtonClick,
    };
};

export function PrevButton({ className, disabled, onClick, size = 40 }) {
    return (
        <button
            className={twclsx('embla__prev hidden md:block', { 'cursor-not-allowed': disabled }, className)}
            type="button"
            onClick={onClick}
            disabled={disabled}
            aria-label="Previous"
        >
            <PreviousIcon disabled={disabled} height={size} width={size} />
        </button>
    );
}

export function NextButton({ className, disabled, onClick, size = 40 }) {
    return (
        <button
            className={twclsx('embla__next hidden md:block', { 'cursor-not-allowed': disabled }, className)}
            type="button"
            onClick={onClick}
            disabled={disabled}
            aria-label="Next"
        >
            <NextIcon disabled={disabled} height={size} width={size} />
        </button>
    );
}
