import PropTypes from 'prop-types';

import { RIBBON_WITH_COIN_ICON_URL } from 'honeydew/icons/PlusMembership';
import { getResizedCDNAsset } from 'utils/globalUtils';

import BenefitBase from './shared/BenefitBase';

function ExclusiveDealsBenefit({ bgColor, layout }) {
    const horizontalImage = (
        <img
            src={getResizedCDNAsset({ url: RIBBON_WITH_COIN_ICON_URL, width: 300 })}
            alt="ribbon with coin"
            width={150}
        />
    );

    const verticalImage = (
        <img
            src={getResizedCDNAsset({ url: RIBBON_WITH_COIN_ICON_URL, width: 200 })}
            alt="ribbon with coin"
            width={100}
        />
    );

    return (
        <BenefitBase
            bgColor={bgColor}
            headline="Exclusive weekly deals"
            subheadline="Save up to 50% with big, members-only discounts"
            verticalImage={verticalImage}
            horizontalImage={horizontalImage}
            horizontalImagePosition="bottom-[-20px] left-0 right-[-30px] top-[-20px]"
            layout={layout}
        />
    );
}

ExclusiveDealsBenefit.propTypes = {
    bgColor: PropTypes.string,
    layout: PropTypes.oneOf(['horizontal', 'responsive']),
};

ExclusiveDealsBenefit.defaultProps = {
    bgColor: 'bg-white',
    layout: 'responsive',
};

export default ExclusiveDealsBenefit;
