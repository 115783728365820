import PropTypes from 'prop-types';

function AppleAppStoreBadge({ width }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 156 45" fill="none">
            <path
                d="M150.222 0.892818C152.897 0.892818 155.074 2.99186 155.074 5.57143V39C155.074 41.5796 152.897 43.6786 150.222 43.6786H5.77778C3.10267 43.6786 0.925889 41.5796 0.925889 39V5.57143C0.925889 2.99186 3.10267 0.892818 5.77778 0.892818H150.222ZM150.222 0H5.77778C2.60144 0 0 2.50854 0 5.57143V39C0 42.0629 2.60144 44.5714 5.77778 44.5714H150.222C153.399 44.5714 156 42.0629 156 39V5.57143C156 2.50854 153.399 0 150.222 0Z"
                fill="#C3C3C3"
            />
            <path
                d="M34.8141 22.045C34.7806 18.4536 37.8636 16.7064 38.0046 16.6251C36.2585 14.1703 33.5522 13.8349 32.6012 13.8082C30.3282 13.5775 28.1234 15.1197 26.9655 15.1197C25.7846 15.1197 24.0015 13.8304 22.0798 13.8683C19.607 13.9051 17.2935 15.2857 16.0247 17.4296C13.4062 21.8009 15.3591 28.2248 17.8678 31.7582C19.1228 33.4887 20.5892 35.4208 22.5086 35.3529C24.3863 35.2782 25.0878 34.1985 27.3538 34.1985C29.599 34.1985 30.2577 35.3529 32.2152 35.3094C34.2305 35.2782 35.4993 33.5711 36.7103 31.825C38.1606 29.8416 38.743 27.8883 38.7661 27.788C38.7187 27.7724 34.8522 26.3494 34.8141 22.045Z"
                fill="#2D2D2D"
            />
            <path
                d="M31.1163 11.4838C32.1262 10.2658 32.8173 8.6089 32.6254 6.92744C31.1637 6.98984 29.3356 7.90244 28.2829 9.09362C27.3515 10.1433 26.5195 11.8637 26.7344 13.4817C28.3765 13.5998 30.0624 12.6827 31.1163 11.4838Z"
                fill="#2D2D2D"
            />
            <path
                d="M56.6807 11.1529C56.6807 12.4644 56.2728 13.4517 55.4581 14.1147C54.7035 14.7264 53.6312 15.0328 52.2422 15.0328C51.5535 15.0328 50.9641 15.0039 50.4707 14.9459V7.77996C51.1143 7.67967 51.8077 7.62842 52.5565 7.62842C53.8796 7.62842 54.8768 7.90587 55.5494 8.46079C56.3028 9.08813 56.6807 9.98513 56.6807 11.1529ZM55.4038 11.1852C55.4038 10.335 55.1704 9.68316 54.7035 9.22853C54.2367 8.77502 53.5549 8.5477 52.657 8.5477C52.2757 8.5477 51.951 8.57222 51.6817 8.62347V14.0712C51.8308 14.0935 52.1035 14.1035 52.4999 14.1035C53.4266 14.1035 54.1419 13.855 54.6457 13.3581C55.1496 12.8611 55.4038 12.1368 55.4038 11.1852Z"
                fill="#2D2D2D"
            />
            <path
                d="M63.4514 12.2983C63.4514 13.1062 63.2122 13.7681 62.7338 14.2873C62.2323 14.8211 61.5679 15.0874 60.7382 15.0874C59.9385 15.0874 59.3018 14.8322 58.8269 14.3196C58.3531 13.8082 58.1162 13.163 58.1162 12.3852C58.1162 11.5718 58.36 10.9044 58.85 10.3862C59.3399 9.86807 59.9986 9.60844 60.8283 9.60844C61.6279 9.60844 62.2704 9.86362 62.7569 10.3751C63.2191 10.872 63.4514 11.5139 63.4514 12.2983ZM62.1953 12.3362C62.1953 11.8515 62.0867 11.4359 61.8706 11.0893C61.6164 10.6704 61.2547 10.4609 60.7844 10.4609C60.2979 10.4609 59.9281 10.6704 59.6739 11.0893C59.4567 11.4359 59.3492 11.8582 59.3492 12.3574C59.3492 12.8421 59.4578 13.2577 59.6739 13.6043C59.9362 14.0232 60.3014 14.2327 60.7728 14.2327C61.2351 14.2327 61.5979 14.0199 61.8591 13.5931C62.0832 13.2399 62.1953 12.8209 62.1953 12.3362Z"
                fill="#2D2D2D"
            />
            <path
                d="M72.5292 9.71547L70.8247 14.9682H69.7154L69.0093 12.6873C68.8302 12.1179 68.6846 11.5518 68.5714 10.9902H68.5494C68.4443 11.5674 68.2987 12.1324 68.1115 12.6873L67.3615 14.9682H66.2395L64.6367 9.71547H65.8813L66.4972 12.2126C66.6462 12.8032 66.7687 13.3659 66.8669 13.8985H66.8889C66.979 13.4595 67.1281 12.9001 67.3384 12.2237L68.1115 9.71658H69.0983L69.839 12.1702C70.0181 12.7686 70.1637 13.3447 70.2758 13.8996H70.3093C70.3914 13.3592 70.515 12.7831 70.6791 12.1702L71.3401 9.71658H72.5292V9.71547Z"
                fill="#2D2D2D"
            />
            <path
                d="M78.807 14.9682H77.596V11.9596C77.596 11.0325 77.2308 10.569 76.4982 10.569C76.1388 10.569 75.8488 10.696 75.6234 10.9512C75.4004 11.2064 75.2872 11.5072 75.2872 11.8515V14.9671H74.0761V11.2164C74.0761 10.7551 74.0611 10.2548 74.0322 9.71323H75.0965L75.1531 10.5345H75.1866C75.3276 10.2793 75.5379 10.0687 75.8141 9.90043C76.1423 9.70432 76.5097 9.60515 76.9119 9.60515C77.4203 9.60515 77.8432 9.76338 78.1795 10.0809C78.5978 10.4698 78.807 11.0504 78.807 11.8215V14.9682Z"
                fill="#2D2D2D"
            />
            <path d="M82.1474 14.9682H80.9375V7.30527H82.1474V14.9682Z" fill="#2D2D2D" />
            <path
                d="M89.2766 12.2983C89.2766 13.1062 89.0374 13.7681 88.559 14.2873C88.0575 14.8211 87.3919 15.0874 86.5634 15.0874C85.7626 15.0874 85.1258 14.8322 84.6521 14.3196C84.1783 13.8082 83.9414 13.163 83.9414 12.3852C83.9414 11.5718 84.1852 10.9044 84.6752 10.3862C85.1651 9.86807 85.8238 9.60844 86.6523 9.60844C87.4531 9.60844 88.0945 9.86362 88.5821 10.3751C89.0443 10.872 89.2766 11.5139 89.2766 12.2983ZM88.0194 12.3362C88.0194 11.8515 87.9107 11.4359 87.6947 11.0893C87.4416 10.6704 87.0787 10.4609 86.6096 10.4609C86.1219 10.4609 85.7522 10.6704 85.4991 11.0893C85.2819 11.4359 85.1744 11.8582 85.1744 12.3574C85.1744 12.8421 85.283 13.2577 85.4991 13.6043C85.7614 14.0232 86.1266 14.2327 86.598 14.2327C87.0603 14.2327 87.4219 14.0199 87.6831 13.5931C87.9084 13.2399 88.0194 12.8209 88.0194 12.3362Z"
                fill="#2D2D2D"
            />
            <path
                d="M95.137 14.9682H94.0497L93.9595 14.3631H93.926C93.5539 14.8456 93.0235 15.0874 92.3348 15.0874C91.8206 15.0874 91.4046 14.928 91.0914 14.6116C90.8072 14.3241 90.665 13.9664 90.665 13.5419C90.665 12.9 90.9424 12.4109 91.5005 12.0721C92.0575 11.7334 92.841 11.5674 93.8497 11.5752V11.4771C93.8497 10.7851 93.473 10.4397 92.7185 10.4397C92.1811 10.4397 91.7074 10.5701 91.2983 10.8286L91.0521 10.062C91.5583 9.75999 92.1834 9.60844 92.9207 9.60844C94.3443 9.60844 95.0585 10.3327 95.0585 11.7813V13.7157C95.0585 14.2405 95.085 14.6584 95.137 14.9682ZM93.8798 13.163V12.3529C92.544 12.3306 91.8761 12.6839 91.8761 13.4115C91.8761 13.6856 91.9523 13.8906 92.1083 14.0277C92.2643 14.1648 92.4631 14.2327 92.7 14.2327C92.9657 14.2327 93.2142 14.1514 93.4407 13.9898C93.6683 13.8271 93.8081 13.621 93.8601 13.368C93.8729 13.3112 93.8798 13.2421 93.8798 13.163Z"
                fill="#2D2D2D"
            />
            <path
                d="M102.019 14.9682H100.945L100.888 14.1247H100.855C100.511 14.7665 99.9266 15.0874 99.105 15.0874C98.4487 15.0874 97.9021 14.839 97.4688 14.342C97.0354 13.845 96.8193 13.1998 96.8193 12.4076C96.8193 11.5574 97.0539 10.8688 97.5254 10.3428C97.9818 9.85252 98.5411 9.60738 99.2067 9.60738C99.9382 9.60738 100.45 9.84472 100.741 10.3205H100.764V7.30527H101.977V13.5531C101.977 14.0645 101.99 14.5359 102.019 14.9682ZM100.764 12.753V11.8772C100.764 11.7256 100.753 11.6031 100.731 11.5095C100.663 11.2287 100.516 10.9924 100.293 10.8019C100.068 10.6114 99.796 10.5155 99.4829 10.5155C99.0311 10.5155 98.6775 10.6882 98.4175 11.0348C98.1598 11.3813 98.0292 11.8237 98.0292 12.3641C98.0292 12.8834 98.1528 13.3046 98.4013 13.6288C98.6636 13.9743 99.0172 14.147 99.4598 14.147C99.8573 14.147 100.175 14.0032 100.417 13.7146C100.65 13.4483 100.764 13.1274 100.764 12.753Z"
                fill="#2D2D2D"
            />
            <path
                d="M112.376 12.2984C112.376 13.1062 112.137 13.7681 111.659 14.2874C111.157 14.8211 110.494 15.0874 109.663 15.0874C108.864 15.0874 108.228 14.8323 107.752 14.3197C107.278 13.8082 107.041 13.1631 107.041 12.3853C107.041 11.5719 107.285 10.9044 107.775 10.3863C108.265 9.86813 108.923 9.60851 109.754 9.60851C110.553 9.60851 111.196 9.86368 111.682 10.3751C112.144 10.8721 112.376 11.5139 112.376 12.2984ZM111.121 12.3363C111.121 11.8516 111.013 11.4359 110.797 11.0894C110.541 10.6704 110.181 10.4609 109.709 10.4609C109.224 10.4609 108.854 10.6704 108.599 11.0894C108.381 11.4359 108.274 11.8582 108.274 12.3574C108.274 12.8422 108.383 13.2578 108.599 13.6043C108.861 14.0233 109.226 14.2328 109.698 14.2328C110.16 14.2328 110.524 14.02 110.785 13.5932C111.008 13.24 111.121 12.821 111.121 12.3363Z"
                fill="#2D2D2D"
            />
            <path
                d="M118.888 14.9682H117.678V11.9596C117.678 11.0325 117.313 10.569 116.579 10.569C116.22 10.569 115.93 10.696 115.705 10.9512C115.481 11.2064 115.369 11.5072 115.369 11.8515V14.9671H114.157V11.2164C114.157 10.7551 114.143 10.2548 114.114 9.71323H115.177L115.234 10.5345H115.268C115.41 10.2793 115.62 10.0687 115.895 9.90043C116.224 9.70432 116.591 9.60515 116.994 9.60515C117.501 9.60515 117.924 9.76338 118.26 10.0809C118.68 10.4698 118.888 11.0504 118.888 11.8215V14.9682Z"
                fill="#2D2D2D"
            />
            <path
                d="M127.038 10.5902H125.705V13.1419C125.705 13.7905 125.942 14.1147 126.411 14.1147C126.628 14.1147 126.808 14.0969 126.95 14.0601L126.982 14.946C126.742 15.0329 126.428 15.0763 126.041 15.0763C125.563 15.0763 125.191 14.9359 124.921 14.6551C124.651 14.3743 124.517 13.9019 124.517 13.2389V10.5902H123.721V9.71552H124.517V8.75278L125.704 8.40735V9.71441H127.037V10.5902H127.038Z"
                fill="#2D2D2D"
            />
            <path
                d="M133.449 14.9682H132.236V11.9819C132.236 11.0404 131.871 10.569 131.14 10.569C130.578 10.569 130.195 10.842 129.984 11.388C129.948 11.5028 129.928 11.6432 129.928 11.8081V14.9671H128.718V7.30527H129.928V10.471H129.951C130.332 9.89487 130.879 9.60738 131.587 9.60738C132.089 9.60738 132.503 9.76561 132.833 10.0832C133.243 10.4788 133.449 11.0671 133.449 11.8449V14.9682Z"
                fill="#2D2D2D"
            />
            <path
                d="M140.063 12.0933C140.063 12.3028 140.046 12.4788 140.018 12.6226H136.386C136.402 13.1418 136.575 13.5374 136.911 13.8115C137.219 14.0567 137.615 14.1792 138.101 14.1792C138.638 14.1792 139.128 14.0968 139.569 13.9308L139.759 14.742C139.242 14.9581 138.634 15.0662 137.931 15.0662C137.087 15.0662 136.423 14.8266 135.942 14.3475C135.459 13.8684 135.22 13.2254 135.22 12.4187C135.22 11.6264 135.443 10.9668 135.892 10.4408C136.361 9.87922 136.996 9.59842 137.797 9.59842C138.58 9.59842 139.175 9.87922 139.577 10.4408C139.902 10.8865 140.063 11.4381 140.063 12.0933ZM138.907 11.7913C138.916 11.4448 138.837 11.1462 138.673 10.8943C138.462 10.5701 138.142 10.4074 137.709 10.4074C137.314 10.4074 136.991 10.5656 136.745 10.8832C136.544 11.1361 136.425 11.4381 136.386 11.7913H138.907Z"
                fill="#2D2D2D"
            />
            <path
                d="M61.9895 35.1044H59.3652L57.9277 30.7487H52.9311L51.5618 35.1044H49.0068L53.9572 20.2755H57.0148L61.9895 35.1044ZM57.4944 28.9213L56.1944 25.0491C56.0569 24.6536 55.7992 23.722 55.419 22.2556H55.3728C55.2214 22.8863 54.9776 23.8178 54.6425 25.0491L53.3656 28.9213H57.4944Z"
                fill="#2D2D2D"
            />
            <path
                d="M74.7205 29.6266C74.7205 31.4451 74.2109 32.8825 73.1917 33.9378C72.2788 34.8771 71.1452 35.3462 69.792 35.3462C68.3314 35.3462 67.2822 34.8403 66.6431 33.8286H66.5969V39.4613H64.1333V27.9318C64.1333 26.7885 64.1021 25.6152 64.042 24.4117H66.2087L66.3462 26.1066H66.3924C67.214 24.8296 68.4608 24.1922 70.1341 24.1922C71.4422 24.1922 72.5342 24.6903 73.4078 25.6876C74.2837 26.686 74.7205 27.9986 74.7205 29.6266ZM72.2106 29.7135C72.2106 28.6728 71.9679 27.8148 71.4803 27.1395C70.9476 26.4353 70.2323 26.0832 69.3356 26.0832C68.7278 26.0832 68.1754 26.2793 67.682 26.6659C67.1874 27.0559 66.8639 27.5652 66.7125 28.1959C66.6362 28.49 66.5981 28.7307 66.5981 28.9201V30.703C66.5981 31.4808 66.8454 32.1371 67.3399 32.6731C67.8345 33.209 68.477 33.4765 69.2674 33.4765C70.1953 33.4765 70.9175 33.131 71.4341 32.4424C71.9518 31.7527 72.2106 30.8434 72.2106 29.7135Z"
                fill="#2D2D2D"
            />
            <path
                d="M87.4732 29.6266C87.4732 31.4451 86.9636 32.8825 85.9433 33.9378C85.0315 34.8771 83.8979 35.3462 82.5448 35.3462C81.0842 35.3462 80.0349 34.8403 79.3971 33.8286H79.3508V39.4613H76.8872V27.9318C76.8872 26.7885 76.856 25.6152 76.7959 24.4117H78.9626L79.1001 26.1066H79.1463C79.9667 24.8296 81.2136 24.1922 82.888 24.1922C84.1949 24.1922 85.2869 24.6903 86.1628 25.6876C87.0353 26.686 87.4732 27.9986 87.4732 29.6266ZM84.9634 29.7135C84.9634 28.6728 84.7195 27.8148 84.2319 27.1395C83.6992 26.4353 82.9862 26.0832 82.0883 26.0832C81.4794 26.0832 80.9282 26.2793 80.4336 26.6659C79.939 27.0559 79.6166 27.5652 79.4652 28.1959C79.3901 28.49 79.3508 28.7307 79.3508 28.9201V30.703C79.3508 31.4808 79.5981 32.1371 80.0904 32.6731C80.585 33.2079 81.2275 33.4765 82.0202 33.4765C82.9481 33.4765 83.6703 33.131 84.1868 32.4424C84.7045 31.7527 84.9634 30.8434 84.9634 29.7135Z"
                fill="#2D2D2D"
            />
            <path
                d="M101.734 30.9459C101.734 32.2073 101.279 33.2336 100.368 34.0258C99.3659 34.8916 97.9711 35.324 96.1789 35.324C94.5241 35.324 93.1975 35.0164 92.1934 34.4002L92.7642 32.4201C93.8458 33.0508 95.0326 33.3673 96.3256 33.3673C97.2535 33.3673 97.9758 33.1645 98.4946 32.7611C99.0111 32.3577 99.2688 31.8162 99.2688 31.1409C99.2688 30.5392 99.0562 30.0322 98.6298 29.6211C98.2057 29.2099 97.4974 28.8277 96.5082 28.4745C93.8158 27.5061 92.4707 26.0877 92.4707 24.2223C92.4707 23.0033 92.9422 22.0038 93.8863 21.226C94.8269 20.4471 96.0818 20.0583 97.651 20.0583C99.0504 20.0583 100.213 20.2934 101.141 20.7625L100.525 22.6991C99.6582 22.2445 98.6783 22.0172 97.5817 22.0172C96.7151 22.0172 96.0379 22.2233 95.5526 22.6334C95.1423 23 94.9366 23.4468 94.9366 23.9761C94.9366 24.5622 95.1712 25.0469 95.6427 25.428C96.0529 25.7801 96.7983 26.1612 97.8799 26.5724C99.203 27.0861 100.175 27.6867 100.8 28.3753C101.423 29.0617 101.734 29.9208 101.734 30.9459Z"
                fill="#2D2D2D"
            />
            <path
                d="M109.879 26.1946H107.163V31.3861C107.163 32.7065 107.642 33.3662 108.601 33.3662C109.041 33.3662 109.406 33.3294 109.695 33.2558L109.763 35.0599C109.278 35.2348 108.639 35.3228 107.848 35.3228C106.875 35.3228 106.114 35.0365 105.565 34.4648C105.019 33.8921 104.744 32.9316 104.744 31.5822V26.1924H103.126V24.4095H104.744V22.4517L107.163 21.7475V24.4095H109.879V26.1946Z"
                fill="#2D2D2D"
            />
            <path
                d="M122.132 29.6701C122.132 31.3137 121.644 32.6631 120.671 33.7183C119.651 34.8047 118.296 35.3463 116.608 35.3463C114.981 35.3463 113.686 34.8259 112.72 33.7851C111.754 32.7444 111.271 31.4307 111.271 29.8473C111.271 28.1903 111.767 26.8331 112.765 25.7779C113.76 24.7215 115.102 24.1934 116.791 24.1934C118.418 24.1934 119.727 24.7137 120.715 25.7556C121.66 26.7663 122.132 28.0711 122.132 29.6701ZM119.575 29.747C119.575 28.7608 119.357 27.9151 118.915 27.2097C118.398 26.3562 117.66 25.9305 116.703 25.9305C115.712 25.9305 114.96 26.3573 114.444 27.2097C114.001 27.9162 113.783 28.7753 113.783 29.7915C113.783 30.7777 114.001 31.6234 114.444 32.3277C114.976 33.1812 115.721 33.6069 116.681 33.6069C117.621 33.6069 118.36 33.1723 118.893 32.3054C119.347 31.5867 119.575 30.732 119.575 29.747Z"
                fill="#2D2D2D"
            />
            <path
                d="M130.14 26.5011C129.896 26.4576 129.636 26.4353 129.363 26.4353C128.496 26.4353 127.826 26.7507 127.355 27.3825C126.945 27.9396 126.739 28.6438 126.739 29.494V35.1045H124.276L124.299 27.7791C124.299 26.5467 124.268 25.4247 124.207 24.4129H126.353L126.443 26.4587H126.511C126.771 25.7556 127.181 25.1895 127.743 24.765C128.292 24.3828 128.885 24.1923 129.524 24.1923C129.751 24.1923 129.957 24.2079 130.14 24.2357V26.5011Z"
                fill="#2D2D2D"
            />
            <path
                d="M141.157 29.2522C141.157 29.6779 141.128 30.0367 141.067 30.3297H133.676C133.705 31.3861 134.062 32.1939 134.749 32.7511C135.371 33.2492 136.177 33.4988 137.166 33.4988C138.26 33.4988 139.259 33.3305 140.157 32.9929L140.543 34.642C139.493 35.0833 138.255 35.3028 136.825 35.3028C135.106 35.3028 133.756 34.8147 132.774 33.8397C131.794 32.8647 131.303 31.5554 131.303 29.913C131.303 28.3006 131.759 26.9579 132.673 25.8871C133.63 24.7438 134.923 24.1722 136.55 24.1722C138.148 24.1722 139.358 24.7438 140.18 25.8871C140.83 26.7952 141.157 27.9184 141.157 29.2522ZM138.808 28.636C138.824 27.9318 138.664 27.3234 138.33 26.8097C137.903 26.1489 137.248 25.8191 136.366 25.8191C135.561 25.8191 134.906 26.1411 134.405 26.7874C133.995 27.3011 133.751 27.9173 133.676 28.6349H138.808V28.636Z"
                fill="#2D2D2D"
            />
        </svg>
    );
}

AppleAppStoreBadge.propTypes = {
    width: PropTypes.number,
};

AppleAppStoreBadge.defaultProps = {
    width: 100,
};

export default AppleAppStoreBadge;
