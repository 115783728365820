import PropTypes from 'prop-types';

function PlantBasedIcon({ className, width }) {
    return (
        <svg width={width} className={className} viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_759_23856)">
                <g clipPath="url(#clip1_759_23856)">
                    <path
                        d="M4.04582 23.2058C6.12004 22.0012 7.41148 19.8417 8.49916 17.6161C9.58683 15.3905 10.0469 12.8047 9.80738 10.2553C9.59898 8.03168 8.87288 5.83403 8.95904 3.61529C0.325154 9.47842 0.837593 16.2988 0.996667 18.0516C1.15574 19.8044 2.2551 21.8524 3.62522 22.9045L4.04582 23.2058Z"
                        className="fill-[#B0C472] if:fill-[#C5DB66]"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeMiterlimit="10"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M6.11186 10.1378C7.72066 8.01135 9.33015 5.88523 10.9389 3.75874C11.5143 2.99785 12.093 2.23436 12.7786 1.56475C13.0081 1.34034 13.6644 0.480558 14.5848 0.992655C15.3855 0.429223 16.5802 0.465159 17.3426 1.07677C18.2814 1.82918 18.4314 3.1496 18.4409 4.32932C18.4729 8.277 14.774 17.6375 11.2717 22.1796C10.3245 23.4089 8.76615 24.1217 7.18167 24.0873C5.59718 24.053 4.05087 23.2458 3.1511 21.9837C2.13447 20.557 1.97574 18.6812 2.3574 16.9884C2.73905 15.2956 3.60805 13.7459 4.52113 12.2548C4.93298 11.5826 5.63402 10.7677 6.11117 10.1375L6.11186 10.1378Z"
                        className="fill-[#F1C34A] if:fill-[#F7D46D]"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeMiterlimit="10"
                    />
                    <path
                        d="M17.2127 0.967972C17.2731 2.17232 16.3046 5.34424 15.9153 6.44043C13.2361 13.9864 10.3542 18.2298 6.17568 23.9613"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeMiterlimit="10"
                    />
                    <path
                        d="M14.5847 0.992703C10.4987 7.53042 6.26933 15.2768 3.52302 22.4404"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeMiterlimit="10"
                    />
                    <path
                        d="M18.4727 4.95805C17.8466 4.63494 17.3295 4.14505 16.7296 3.77755C15.2412 2.86611 13.3787 2.78149 11.6173 2.82776"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeMiterlimit="10"
                    />
                    <path
                        d="M17.8546 8.14043C17.1021 7.74964 16.4613 7.19355 15.7358 6.75875C13.9346 5.67977 11.7759 5.4365 9.74508 5.33855"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeMiterlimit="10"
                    />
                    <path
                        d="M17.0018 10.8455C16.163 10.4085 15.438 9.80728 14.6261 9.32625C12.6115 8.13301 10.2498 7.78101 8.03466 7.58432"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeMiterlimit="10"
                    />
                    <path
                        d="M15.8495 13.8151C14.9333 13.3367 14.1323 12.6948 13.2432 12.1723C11.0373 10.8767 8.49324 10.427 6.11306 10.1419"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeMiterlimit="10"
                    />
                    <path
                        d="M12.1333 20.9533C11.2171 20.4749 10.4162 19.833 9.52702 19.3105C7.32113 18.0149 4.66854 17.6162 2.28836 17.3311"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeMiterlimit="10"
                    />
                    <path
                        d="M14.1279 17.5446C13.2117 17.0662 12.1191 16.0875 11.2299 15.565C9.02404 14.2693 6.14452 13.8242 3.76434 13.5391"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeMiterlimit="10"
                    />
                    <path
                        d="M3.51228 23.0159C3.57427 20.5679 4.9551 18.4628 6.50542 16.5222C8.05574 14.5816 10.2773 13.0831 12.8303 12.2578C15.0567 11.5375 17.5293 11.315 19.597 10.3196C18.5305 20.8594 11.7678 23.1881 10.0155 23.7607C8.26318 24.3334 5.73792 24.143 4.01931 23.2875L3.51228 23.0159Z"
                        className="fill-[#B0C472] if:fill-[#C5DB66]"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeMiterlimit="10"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M16.0559 14.9584C10.3993 21.8812 7.11225 22.2782 3.84945 23.1965"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_759_23856">
                    <rect width="21" height="25" fill="white" />
                </clipPath>
                <clipPath id="clip1_759_23856">
                    <rect width="21" height="25" fill="white" transform="translate(-0.310059 -0.285278)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default PlantBasedIcon;

PlantBasedIcon.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number.isRequired,
};

PlantBasedIcon.defaultProps = {
    className: null,
};
