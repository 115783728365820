import PropTypes from 'prop-types';

function AutoFilledIcon({ brandColors, width }) {
    return (
        <svg width={width} viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.645 7.10547C18.4756 7.67325 18.3059 13.0006 17.6282 14.1904C17.1862 14.9658 15.9901 15.5635 14.5776 15.5635C13.1652 15.5635 11.5834 15.4753 11.527 15.7024C11.4707 15.9295 14.9194 16.0177 16.2175 16.9827C17.5156 17.9478 18.0706 19.1879 18.2068 21.2267C18.3484 23.3554 18.749 25.8949 18.749 25.8949C18.749 25.8949 19.1537 22.5459 19.6621 19.6508C20.1704 16.7556 25.0288 15.9208 25.8196 15.8843C26.6103 15.8475 20.9228 15.8256 20.1815 14.1907C19.3794 12.4207 18.645 7.10547 18.645 7.10547Z"
                fill="white"
                className={
                    brandColors ? 'fill-kale stroke-kale if:fill-beet if:stroke-beet' : 'fill-black stroke-black'
                }
                strokeWidth="1.10526"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.83314 6C9.74194 6.30069 9.65074 9.12085 9.2856 9.75089C9.04765 10.1614 8.40332 10.4779 7.64299 10.4779C6.88265 10.4779 6.03077 10.4313 6.00037 10.5515C5.96997 10.6716 7.82713 10.7182 8.52597 11.2292C9.2248 11.7401 9.52391 12.3964 9.59693 13.4759C9.67346 14.6031 9.88905 15.9474 9.88905 15.9474C9.88905 15.9474 10.1071 14.1743 10.3807 12.6415C10.6543 11.1087 13.2704 10.6668 13.6963 10.6474C14.1223 10.6281 11.0596 10.6164 10.6606 9.75089C10.2283 8.81394 9.83314 6 9.83314 6Z"
                fill="white"
                className={
                    brandColors ? 'fill-kale stroke-kale if:fill-beet if:stroke-beet' : 'fill-black stroke-black'
                }
                strokeWidth="1.10526"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.0436 17.0527C11.9524 17.3534 11.8612 20.1736 11.4961 20.8036C11.2581 21.2141 10.6138 21.5307 9.85343 21.5307C9.0931 21.5307 8.24122 21.4841 8.21082 21.6042C8.18042 21.7243 10.0376 21.771 10.7364 22.2819C11.4353 22.7928 11.7344 23.4491 11.8074 24.5286C11.8839 25.6558 12.0995 27.0001 12.0995 27.0001C12.0995 27.0001 12.3175 25.227 12.5911 23.6942C12.8647 22.1614 15.4808 21.7195 15.9068 21.7002C16.3327 21.6808 13.27 21.6691 12.871 20.8036C12.4388 19.8667 12.0436 17.0527 12.0436 17.0527Z"
                fill="white"
                className={
                    brandColors ? 'fill-kale stroke-kale if:fill-beet if:stroke-beet' : 'fill-black stroke-black'
                }
                strokeWidth="1.10526"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

AutoFilledIcon.propTypes = {
    brandColors: PropTypes.bool,
    width: PropTypes.number,
};

AutoFilledIcon.defaultProps = {
    brandColors: false,
    width: 28,
};

export default AutoFilledIcon;
