import { useEffect, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useRevalidator } from 'react-router';

import { updateOrderTip } from 'api/jalapeno';
import useAccountQuery, { ACCOUNT_QUERY_KEY } from 'api/queries/useAccountQuery';
import { ORDER_QUERY_KEY } from 'api/queries/useOrderQuery';
import { ORDERS_INFINITE_QUERY_KEY } from 'api/queries/useOrdersInfiniteQuery';
import { ORDERS_QUERY_KEY } from 'api/queries/useOrdersQuery';
import useSelectionsQuery, { SELECTIONS_QUERY_KEY } from 'api/queries/useSelectionsQuery';
import useTipsQuery from 'api/queries/useTipsQuery';
import { Alert } from 'honeydew/Alert';
import Button from 'honeydew/Button';
import Modal from 'honeydew/Modal';
import QuantityButton from 'honeydew/QuantityButton';
import useTailwindTheme from 'hooks/useTailwindTheme';
import { trackEvent } from 'utils/analyticsUtils';

function EditDriverTipModal({
    chargeID,
    closeEditDriverTipsModal,
    driverTipsModal,
    orderSubtotal,
    orderTotal,
    tipAmount: orderTipAmount,
}) {
    const { data: account = {} } = useAccountQuery();
    const { data: selections = {} } = useSelectionsQuery();
    const { data: tips = {} } = useTipsQuery();
    const queryClient = useQueryClient();
    const location = useLocation();
    const revalidator = useRevalidator();
    const tailwindTheme = useTailwindTheme();
    const mobile = useMediaQuery({ maxWidth: tailwindTheme?.screens?.md });

    const { preferredTipVal } = account;
    const { chargeTipAmount } = selections;
    const { defaultTipAmount } = tips?.tipsSuggestions ?? {};

    const [updateTipButtonDisabled, setUpdateTipButtonDisabled] = useState(false);
    const [newDriverTip, setNewDriverTip] = useState(0);
    const [error, setError] = useState('');

    // Note: maxQuantity we want to allow for tip is 100
    const driverTipMaxQty = orderTipAmount > 0 ? orderTipAmount : 100;

    const hasCurrentDriverTip = orderTipAmount > 0 || chargeTipAmount > 0 || preferredTipVal > 0;

    const tipAmountToEditRef = useRef(0);

    /**
     * There are several tip amounts that can be edited in this modal.
     * This function returns the tip amount that will be edited, based on priority.
     * @returns {number} the tip amount to edit
     */
    const getTipAmountToEdit = () => {
        if (orderTipAmount > 0) {
            return orderTipAmount;
        }

        if (chargeTipAmount > 0) {
            return chargeTipAmount;
        }

        if (preferredTipVal > 0) {
            return preferredTipVal;
        }

        if (defaultTipAmount > 0) {
            return defaultTipAmount;
        }

        return 0;
    };

    const handleUpdateDriverTip = async (e) => {
        e.preventDefault();

        if (tipAmountToEditRef.current === newDriverTip) {
            closeEditDriverTipsModal();
            return;
        }

        setUpdateTipButtonDisabled(true);
        setError('');

        // tipType will always default to 'DOLLARS' for now
        // FE support for percent to be added in the future
        const sendParams = {
            tipAmount: newDriverTip,
            tipType: 'DOLLARS',
        };

        try {
            const response = await updateOrderTip(chargeID, sendParams);

            if (response?.msg !== 'Success') {
                throw new Error(response?.msg);
            }

            await Promise.all([
                queryClient.invalidateQueries({
                    queryKey: [ACCOUNT_QUERY_KEY],
                }),
                queryClient.invalidateQueries({
                    queryKey: [ORDERS_QUERY_KEY],
                }),
                queryClient.invalidateQueries({ queryKey: [ORDERS_INFINITE_QUERY_KEY] }),
                queryClient.invalidateQueries({
                    queryKey: [ORDER_QUERY_KEY],
                }),
                queryClient.invalidateQueries({
                    queryKey: [SELECTIONS_QUERY_KEY],
                }),
                revalidator.revalidate(),
            ]);

            trackEvent('custom driver tip set', {
                orderTipValue: newDriverTip,
                orderTotal,
                orderSubtotal,
                path: location.pathname,
            });

            closeEditDriverTipsModal();
            setUpdateTipButtonDisabled(false);
        } catch (err) {
            setError(err?.message || 'Unable to update driver tip');
            setUpdateTipButtonDisabled(false);
        }
    };

    const handleCloseFormButton = () => {
        setUpdateTipButtonDisabled(false);
        closeEditDriverTipsModal();
        setNewDriverTip(preferredTipVal);
    };

    useEffect(() => {
        const tipAmountToEdit = getTipAmountToEdit();
        setNewDriverTip(tipAmountToEdit);
        tipAmountToEditRef.current = tipAmountToEdit;
    }, [driverTipsModal]);

    return (
        <Modal open={driverTipsModal} onClose={closeEditDriverTipsModal} flush>
            <div className="grid-cols-[260px_1fr] flex-row-reverse md:grid">
                <div className="text-body-md pb-20 md:order-2 md:pb-0 md:pl-50">
                    <div className="text-center">
                        <h2 className="text-heading-md mb-30">
                            {hasCurrentDriverTip ? 'Edit driver tip' : 'Care to add a tip?'}{' '}
                        </h2>
                        {orderTipAmount > 0 ? (
                            <p className="mb-20">
                                Your updated tip will only apply to this order—it won’t change your default tip amount.
                            </p>
                        ) : (
                            <div className="mb-20">
                                <p className="font-grotesk-bold">100% of tips go directly to drivers.</p>
                                <p>
                                    Choose your tip below! You can adjust the amount for up to 24 hours after delivery.
                                </p>
                                <p>
                                    {' '}
                                    <span className="font-grotesk-bold">Note:</span> This is a one-time tip and won’t
                                    change the default tip amount set in your account
                                </p>
                            </div>
                        )}
                    </div>

                    <form>
                        <QuantityButton
                            maxQuantity={driverTipMaxQty}
                            onAdd={() => setNewDriverTip(Math.floor(newDriverTip + 1))}
                            onRemove={() => setNewDriverTip(Math.ceil(newDriverTip - 1))}
                            quantity={newDriverTip}
                            variant="secondary"
                            size="lg"
                            account
                            priceStepper
                            noAdd
                        />
                        {orderTipAmount > 0 && (
                            <p className="mt-10 text-center text-grey">
                                For now, you can only decrease your tip after delivery. Don’t worry, we’ll be
                                introducing the ability to increase soon!
                            </p>
                        )}
                        {error !== '' && (
                            <div className="mt-20">
                                <Alert variant="error">Error: {error}</Alert>
                            </div>
                        )}
                        <div className="mt-20 text-center">
                            <Button onClick={handleUpdateDriverTip} disabled={updateTipButtonDisabled}>
                                Update my tip
                            </Button>
                            <button type="button" className="link text-body-sm mt-15" onClick={handleCloseFormButton}>
                                Nevermind, keep my tip as is
                            </button>
                        </div>
                    </form>
                </div>
                <div className="-mx-30 h-auto w-[calc(100%_+_60px)] overflow-hidden md:order-1 md:-mb-30 md:-mt-[100px] md:flex">
                    <img
                        src={`https://img-cdn.misfitsmarket.com/boysenberry/images/driver-tips-modal-${
                            mobile ? 'm' : 'd'
                        }.png`}
                        className="block object-cover md:mt-0"
                        alt="imperfect foods driver"
                    />
                </div>
            </div>
        </Modal>
    );
}

EditDriverTipModal.propTypes = {
    chargeID: PropTypes.number.isRequired,
    closeEditDriverTipsModal: PropTypes.func.isRequired,
    driverTipsModal: PropTypes.bool.isRequired,
    orderSubtotal: PropTypes.number,
    orderTotal: PropTypes.number.isRequired,
    tipAmount: PropTypes.number,
};

EditDriverTipModal.defaultProps = {
    orderSubtotal: null,
    tipAmount: 0,
};

export default EditDriverTipModal;
