import { logMessage } from 'utils/sentryUtils';

/**
 * Format a promo code's discount value for display to the user.
 *
 * @param {string} code - The promo code's code
 * @param {string} type - The promo code's type
 * @param {boolean} valid - Whether or not the promo code is valid
 * @param {number} value - The promo code's value
 * @returns {string} The promo code's discount value formatted for display.
 */
export const getFormattedDiscount = ({ code = '', type, valid, value }) => {
    let formattedDiscount;

    switch (type) {
        case 'dollars':
            formattedDiscount = `$${value}`;
            break;
        case 'percentage':
            formattedDiscount = `${value}%`;
            break;
        default:
            logMessage('getFormattedDiscount() hit default case', {
                level: 'warning',
                extra: { discount_type: type, value, code, valid },
            });
    }

    return formattedDiscount;
};

/**
 * Get formatted text for referral promo codes.
 *
 * @param {number} newCustValue - The credits rewarded to the referee
 * @returns {string|null} Formatted referral text or null if values are missing
 */
export const getReferralText = ({ newCustValue }) => {
    if (!newCustValue) return null;
    return `Congrats! Your referral bonus of $${newCustValue} off your first order has been applied.`;
};

/**
 * Get the nanobar text for a specified promo code.
 *
 * @param {Object} appliedPromoCodeData - The promo code's data.
 * @param {string} appliedPromoCodeData.code - The promo code's code.
 * @param {string} appliedPromoCodeData.nanobarText - The promo code's nanobar text from the API.
 * @param {string} appliedPromoCodeData.type - The promo code's type
 * @param {number} appliedPromoCodeData.valid - Whether or not the promo code is valid
 * @param {string} appliedPromoCodeData.value - The promo code's value
 * @param {Object} referralRewards - Information about referrer and referee credits.
 * @param {number} referralRewards.newCustValue - The credits rewarded to the referee.
 * @param {number} referralRewards.referrerValue - The credits rewarded to the referrer.
 * @returns {string} The nanobar text for the specified promo code.
 */
export const getNanobarText = ({ appliedPromoCodeData, referralRewards }) => {
    const { code, nanobarText: nanobarTextFromAPI, type, valid, value } = appliedPromoCodeData?.disc ?? {};
    const { newCustValue } = referralRewards ?? {};

    if (nanobarTextFromAPI) {
        return nanobarTextFromAPI;
    }

    if (type === 'REFERRAL') {
        return getReferralText({ newCustValue });
    }

    const formattedDiscount = getFormattedDiscount({ code, type, valid, value });

    return `Limited-time offer: ${formattedDiscount} off your first order with code ${code}`;
};
