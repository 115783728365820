import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import Button from 'honeydew/Button';
import { Drawer } from 'honeydew/Drawer';
import Modal from 'honeydew/Modal';
import useTailwindTheme from 'hooks/useTailwindTheme';

import { createEstimatedTaxBlockData, createOperationsFeeBlockData } from './utils';

/**
 * @typedef {Object} EstimatedTax
 * @property {boolean} display - Whether or not the estimated tax should be displayed.
 * @property {number} value - The estimated tax value (USD).
 */
const EstimatedTax = PropTypes.exact({
    display: PropTypes.bool,
    value: PropTypes.number,
});

/**
 * @typedef {Object} OperationsFee
 * @property {boolean} display - Whether or not the opreations fee should be displayed.
 * @property {number} value - The operations fee value (USD).
 */
const OperationsFee = PropTypes.exact({
    display: PropTypes.bool,
    value: PropTypes.number,
});

function TaxAndFeesModal({ estimatedTax, handleClose, open, operationsFee }) {
    const tailwindTheme = useTailwindTheme();
    const mobile = useMediaQuery({ maxWidth: tailwindTheme?.screens?.md });

    const headingCopy = operationsFee?.display ? 'Estimated Tax & Fees' : 'Estimated Tax';

    return mobile ? (
        <Drawer
            anchor="bottom"
            open={open}
            PaperProps={{ sx: { borderRadius: '20px 20px 0 0' } }}
            toggleDrawer={() => handleClose()}
        >
            <div className="flex flex-col gap-20 rounded-t-[20px] p-20">
                <h2 className="text-heading-xs">{headingCopy}</h2>
                <ModalContent estimatedTax={estimatedTax} operationsFee={operationsFee} />
                <Button onClick={() => handleClose()}>Got it</Button>
            </div>
        </Drawer>
    ) : (
        <Modal headline={headingCopy} open={open} onClose={() => handleClose()}>
            <ModalContent estimatedTax={estimatedTax} operationsFee={operationsFee} />
        </Modal>
    );
}

TaxAndFeesModal.propTypes = {
    estimatedTax: EstimatedTax,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    operationsFee: OperationsFee,
};

TaxAndFeesModal.defaultProps = {
    estimatedTax: undefined,
    operationsFee: undefined,
};

function ModalContent({ estimatedTax, operationsFee }) {
    const estimatedTaxBlockData = createEstimatedTaxBlockData({ value: estimatedTax?.value });
    const operationsFeeBlockData = createOperationsFeeBlockData({ value: operationsFee?.value });

    return (
        <>
            {estimatedTax?.display && <ContentBlock blockData={estimatedTaxBlockData} />}
            {operationsFee?.display && <ContentBlock blockData={operationsFeeBlockData} />}
        </>
    );
}

ModalContent.propTypes = {
    estimatedTax: EstimatedTax,
    operationsFee: OperationsFee,
};

ModalContent.defaultProps = {
    estimatedTax: undefined,
    operationsFee: undefined,
};

function ContentBlock({ blockData }) {
    const { body, heading, value } = blockData;

    const tailwindTheme = useTailwindTheme();
    const mobile = useMediaQuery({ maxWidth: tailwindTheme?.screens?.md });

    return (
        <div
            className={clsx('flex flex-col gap-10 ', {
                'border-t border-off-white py-20 first:border-t-0 first:pt-0 last:pb-0': !mobile,
            })}
        >
            <div className="text-body-md flex items-baseline justify-between font-grotesk-bold">
                <h3>{heading}</h3>
                {value != null && <p>${value?.toFixed(2)}</p>}
            </div>
            <p className="text-body-sm">{body}</p>
        </div>
    );
}

ContentBlock.propTypes = {
    blockData: PropTypes.shape({
        body: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]).isRequired,
        heading: PropTypes.string.isRequired,
        value: PropTypes.number,
    }).isRequired,
};

export { TaxAndFeesModal, ModalContent, ContentBlock };
