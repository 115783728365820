export const LoginErrors = Object.freeze({
    NoError: '',
    Invalid: 'invalid',
    NoAccount: 'noaccount',
    NoCode: 'nocode',
    InvalidCode: 'invalidcode',
    NoEmail: 'noemail',
    NoPassword: 'nopassword',
    InvalidPassword: 'invalidpassword',
    PasswordMatch: 'passwordmatch',
    PasswordSubmit: 'passwordsubmit',
    CodeSubmit: 'codesubmit',
    WrongSite: 'wrongsite',
});
