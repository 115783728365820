import PropTypes from 'prop-types';

function ChangePlanIcon({ width }) {
    return (
        <svg width={width} viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5262_62492)">
                <mask
                    id="mask0_5262_62492"
                    style={{ maskType: 'luminance' }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="61"
                    height="60"
                >
                    <path d="M60.5 0H0.5V59.47H60.5V0Z" fill="white" />
                </mask>
                <g mask="url(#mask0_5262_62492)">
                    <path
                        d="M35.6097 57.7201C50.1397 50.75 63.2497 38.55 59.9997 22.59C56.7397 6.64005 41.9897 -0.379951 23.8197 0.0200493C7.74971 0.360049 0.669715 17.49 1.18971 34.84C1.71971 51.1101 21.7397 64.37 35.5897 57.7401"
                        className="fill-mango if:fill-[#FFCBDC]"
                    />
                    <path
                        d="M10.3193 16.7303C10.3193 16.7303 17.0593 13.4803 20.2093 12.3003C22.5093 11.4403 29.1493 8.28027 29.1493 8.28027C29.1493 8.28027 35.6293 11.4103 39.1493 12.5003C41.7393 13.3003 49.1493 16.7203 49.1493 16.7203C49.1493 16.7203 42.8393 19.4703 39.1493 20.5503C36.5893 21.3103 29.1493 24.3803 29.1493 24.3803L18.6293 20.1203L10.3193 16.7103V16.7303Z"
                        fill="white"
                        stroke="#2D2D2D"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M10.3202 16.73C10.3202 16.73 10.7102 23.65 10.3202 27.51C9.96016 31.05 10.3202 41.76 10.3202 41.76L29.1402 49.39V24.42L10.3202 16.74V16.73Z"
                        fill="white"
                        stroke="#2D2D2D"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M49.1299 16.7302C49.1299 16.7302 49.2699 25.7302 49.1299 28.7202C48.9299 33.0502 49.1299 41.3702 49.1299 41.3702C49.1299 41.3702 44.2899 43.0702 41.0699 44.6002C38.1699 45.9702 29.1499 49.3902 29.1499 49.3902C29.1499 49.3902 29.2699 40.3802 29.1499 37.3502C28.9599 32.3302 29.1499 24.3902 29.1499 24.3902L49.1299 16.7202V16.7302Z"
                        className="fill-[#F2D79A] if:fill-oat"
                        stroke="#2D2D2D"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <path
                    d="M14.7697 48.4901C20.7344 48.4901 25.5697 43.6548 25.5697 37.6901C25.5697 31.7255 20.7344 26.8901 14.7697 26.8901C8.80505 26.8901 3.96973 31.7255 3.96973 37.6901C3.96973 43.6548 8.80505 48.4901 14.7697 48.4901Z"
                    className="fill-[#B0C372] if:fill-[#C5DB66]"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M14.5605 32V42.79" stroke="#2D2D2D" strokeLinecap="round" strokeLinejoin="round" />
                <path
                    d="M17.6704 40.1299L14.5004 43.9399L11.4404 40.1299"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.4404 35.2499L14.6104 31.4399L17.6704 35.2499"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_5262_62492">
                    <rect width="60" height="59.49" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
}

ChangePlanIcon.propTypes = {
    width: PropTypes.number,
};

ChangePlanIcon.defaultProps = {
    width: 60,
};

export default ChangePlanIcon;
