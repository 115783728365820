import {
    ALCOHOL_SERVICE_FEE,
    MIN_WINE_BOTTLE_QTY_FOR_PERCENT_DISCOUNT_TIER_1_COPY,
    MIN_WINE_BOTTLE_QTY_FOR_PERCENT_DISCOUNT_TIER_2,
    MIN_WINE_BOTTLE_QTY_TO_SHIP_COPY,
    MIN_WINE_BOTTLE_QTY_TO_WAIVE_FEE_COPY,
    WINE_PERCENT_DISCOUNT_TIER_1,
    WINE_PERCENT_DISCOUNT_TIER_2,
} from 'constants/shop';

const creditsFaqs = [
    {
        id: 1,
        question: 'What is my credit balance?',
        answer: (
            <p>
                Your credit balance is a reflection of all available credits, which can be spent on upcoming order(s).
                Once credits are used or expire, they will automatically be deducted from your credit balance.
            </p>
        ),
    },
    {
        id: 2,
        question: 'What are the types of credits and how can I earn them?',
        answer: (
            <>
                <p>
                    We currently have four ways to earn credit: Welcome credit, Referrer credit, Order credit, and
                    Promotional credit.
                </p>
                <ul className="list-disc pl-20">
                    <li>
                        <p>Welcome and Referrer credit can be earned through our referral program.</p>
                    </li>
                    <li>
                        <p>
                            Order credit reflects a discount given by our Customer Care team for use on a future order
                            following a service issue.
                        </p>
                    </li>
                    <li>
                        <p>
                            Promotional credit is earned by applying a discount code to your account. These discount
                            codes include one-time discounts, special offers, and promotions.
                        </p>
                    </li>
                </ul>
            </>
        ),
    },
    {
        id: 3,
        question: 'How do I spend credits?',
        answer: (
            <p>
                Your credit balance will be automatically applied to the purchase of your next order(s) until they are
                exhausted or expired. A max of $250 in credits may be applied to any single order. If your credits do
                not cover the cost of your entire order, the remaining balance will be charged to your credit card on
                file. Credits will only be applied to orders that are fulfilled and not to donated or skipped orders.
                Credits cannot be applied to purchases of alcoholic beverages or gift cards. Our system will apply the
                credits in the order of soonest to latest expirations.
            </p>
        ),
    },
    {
        id: 4,
        question: 'Do my credits expire?',
        answer: (
            <p>
                Most credits have an expiration date and can be viewed on your account page under &apos;Credits&apos;.
                If a credit expires before an order is charged, the credit cannot be used. If a credit expires within 30
                days of its redemption, but the order it&apos;s applied to is ultimately refunded, we will apply a new
                courtesy expiration date to those credits that are 30 days from the date of refund.
            </p>
        ),
    },
];

const discountFaqs = [
    {
        id: 1,
        question: 'Lorem ipsum dolor sit amet consectetur adipiscing?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    },
    {
        id: 2,
        question: 'Lorem ipsum dolor sit amet consectetur adipiscing?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    },
    {
        id: 3,
        question: 'Lorem ipsum dolor sit amet consectetur adipiscing?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    },
    {
        id: 4,
        question: 'Lorem ipsum dolor sit amet consectetur adipiscing?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    },
];

const perksFaqs = [
    {
        id: 1,
        headline: 'Want to see some plain text content?',
        content:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Erat nulla sit leo proin. Neque nisi nullam felis egestas sociis. Faucibus sit morbi tincidunt mattis lorem molestie venenatis vitae. Vehicula sit vehicula a quisque placerat.',
    },
    {
        id: 2,
        headline: 'What about rich content?',
        content: (
            <>
                <p>Answers to very important questions</p>
                <p className="font-grotesk-bold">more things and stuff</p>
            </>
        ),
    },
    {
        id: 3,
        headline:
            'What about a really long headline that wraps on small screens lorem ipsum, dolor sit amet consectetur adipisicing elit?',
        content:
            'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellat inventore aut ab voluptates quae eum aliquam quasi! Ratione, tenetur dolorem architecto quod quae odit impedit. Animi ipsa delectus ea possimus.',
    },
];

const wineFaqs = [
    {
        id: 1,
        headline: 'What’s the philosophy or approach to wine assortment and curation?',
        content:
            'We’ve sourced wines with a focus on organic, low winemaking intervention, sustainable practices, vegan, and/or with low sulfites. Our curated bundles highlight each wine’s unique flavor profile.',
    },
    {
        id: 2,
        headline: 'Where do you ship wine?',
        content: (
            <>
                Wine is available in all states in the lower 48 except Alabama, Arkansas, Delaware, Kentucky,
                Mississippi, Montana, Rhode Island, North Dakota, South Dakota, Utah, and Wyoming. Delivery is
                unavailable in Alaska, Hawaii, and other U.S. territories. No delivery to P.O. boxes, APO addresses, and
                select addresses in the delivery area.
                <p className="mt-20 font-grotesk-bold">Shipped Straight to Your Door</p>
                We&apos;ll ship your wine in recycled cardboard boxes that are 100% recyclable with eco-friendly padding
                for safety. For any order of {MIN_WINE_BOTTLE_QTY_TO_WAIVE_FEE_COPY} bottles or more, we&apos;ll waive
                the ${ALCOHOL_SERVICE_FEE} alcohol service fee.
            </>
        ),
    },
    {
        id: 3,
        headline: 'How is your wine packaged?',
        content:
            'When you order wine, it will ship in a dedicated bottle package and separately from your grocery order.',
    },
    {
        id: 4,
        headline: `What is the $${ALCOHOL_SERVICE_FEE} alcohol service fee?`,
        content:
            'This fee covers our operating and delivery costs, including the additional costs associated with ID verification and compliant delivery of alcohol to your door.',
    },
    {
        id: 5,
        headline: 'Is there a wine order minimum?',
        content:
            'Nope! All wine bundles added to your cart will be charged and shipped when your shopping window closes.',
    },
    {
        id: 6,
        headline: 'How long does it take for an order to process?',
        content: (
            <>
                When your shopping window closes, your wine order will be charged and will be delivered to you typically
                within 1-2 business days. Longer delivery times are possible around the following holidays:
                <ul className="ml-20 list-disc">
                    <li>Thanksgiving Day – order by Friday, November 17th, 11:59 pm</li>
                    <li>Hanukkah – order by Thursday, November 30th, 11:59 pm</li>
                    <li>Christmas – order by Sunday, December 17th, 11:59 pm</li>
                    <li>New Years Eve – order by Friday, December 22nd, 11:59 pm</li>
                </ul>
            </>
        ),
    },
];

const unbundledWineFaqs = [
    {
        id: 1,
        headline: 'What’s the philosophy or approach to wine assortment and curation?',
        content:
            'We’ve sourced wines with a focus on organic, low winemaking intervention, sustainable practices, vegan, and/or with low sulfites. Our curated bundles highlight each wine’s unique flavor profile.',
    },
    {
        id: 2,
        headline: 'Where do you ship wine?',
        content: (
            <>
                Wine is available in all states in the lower 48 except Alabama, Arkansas, Delaware, Kentucky,
                Mississippi, Montana, Rhode Island, North Dakota, South Dakota, Utah, and Wyoming. Delivery is
                unavailable in Alaska, Hawaii, and other U.S. territories. No delivery to P.O. boxes, APO addresses, and
                select addresses in the delivery area.
                <p className="mt-20 font-grotesk-bold">Shipped Straight to Your Door</p>
                We&apos;ll ship your wine in recycled cardboard boxes that are 100% recyclable with eco-friendly padding
                for safety. For any order of {MIN_WINE_BOTTLE_QTY_TO_WAIVE_FEE_COPY} bottles or more, we&apos;ll waive
                the ${ALCOHOL_SERVICE_FEE} alcohol service fee.
            </>
        ),
    },
    {
        id: 3,
        headline: 'How is your wine packaged?',
        content:
            'When you order wine, it will ship in a dedicated bottle package and separately from your grocery order.',
    },
    {
        id: 4,
        headline: `What is the $${ALCOHOL_SERVICE_FEE} alcohol service fee?`,
        content: `This fee covers our operating and delivery expenses, including the additional costs associated with ID verification and compliant delivery of alcohol to your door. But the fee is waived when you buy ${MIN_WINE_BOTTLE_QTY_TO_WAIVE_FEE_COPY} or more bottles!`,
    },
    {
        id: 5,
        headline: 'Is there a wine order minimum?',
        content: `There’s a ${MIN_WINE_BOTTLE_QTY_TO_SHIP_COPY}-bottle minimum—but the more you order, the more you save! All wine added to your cart will be charged and shipped when your shopping window closes. To order groceries, you still need to meet your order minimum (wine doesn’t count toward that).`,
    },
    {
        id: 6,
        headline: 'How long does it take for an order to process?',
        content: (
            <>
                When your shopping window closes, your wine order will be charged and will be delivered to you typically
                within 1-2 business days. Longer delivery times are possible around the following holidays:
                <ul className="ml-20 list-disc">
                    <li>Thanksgiving Day – order by Friday, November 17th, 11:59 pm</li>
                    <li>Hanukkah – order by Thursday, November 30th, 11:59 pm</li>
                    <li>Christmas – order by Sunday, December 17th, 11:59 pm</li>
                    <li>New Years Eve – order by Friday, December 22nd, 11:59 pm</li>
                </ul>
            </>
        ),
    },
    {
        id: 7,
        headline: 'Do you offer any discounts on wine?',
        content: `Yep! Any order of ${MIN_WINE_BOTTLE_QTY_TO_WAIVE_FEE_COPY} or more bottles gets free shipping. Also, if you order ${MIN_WINE_BOTTLE_QTY_FOR_PERCENT_DISCOUNT_TIER_1_COPY} or more, you’ll get ${WINE_PERCENT_DISCOUNT_TIER_1}% off, and with ${MIN_WINE_BOTTLE_QTY_FOR_PERCENT_DISCOUNT_TIER_2} or more, you’ll get ${WINE_PERCENT_DISCOUNT_TIER_2}% off!`,
    },
];

const perksProgramFaqList = [
    {
        id: 1,
        headline: 'How does the Misfits Perks loyalty program work?',
        content:
            'Misfits Perks is our loyalty program that helps you get rewarded with free groceries for shopping regularly at Misfits Market. On top of the everyday savings you’ve come to expect from us, you’ll automatically earn points for every grocery purchase. You can also earn additional points for simple actions like downloading our app.',
    },
    {
        id: 2,
        headline: 'Do I have to enroll into the program?',
        content:
            'Nope! Sit back and enjoy earning points with every order—you’re automatically enrolled for free when you become a Misfits Market customer.',
    },
];

const perksPointsFaqList = [
    {
        id: 1,
        headline: 'How do I earn points?',
        content: (
            <>
                When you buy more, you earn more! Customers enrolled in a weekly grocery plan will be eligible to earn 2
                points for every $1 spent on grocery purchases. Customers who shop a la carte will earn 1 point for
                every $1 spent on grocery purchases. Please note that dollar amounts are calculated based on the
                subtotal of products purchased, excluding tax and shipping. Eligible grocery purchases also exclude
                alcoholic beverages and gift cards. <br />
                <br /> Plus, there are many other easy ways to get points, like by signing up for Misfits Market or by
                downloading our easy-to-use mobile app. A list of all the ways to earn points can be found in the{' '}
                <a href="/account/misfits-perks" target="_blank" className="link" rel="noreferrer noopener">
                    Perks Hub
                </a>{' '}
                on your account page.
            </>
        ),
    },
    {
        id: 2,
        headline: 'Can I earn points from donating a skipped order?',
        content:
            'You sure can! If you are on a weekly grocery plan, you can donate your order instead of skipping it, you’ll earn points on the amount of your donation as usual.',
    },
    {
        id: 3,
        headline: 'How can I track my points balance and view available perks?',
        content: (
            <>
                You can track your points balance on the{' '}
                <a href="/account/misfits-perks" target="_blank" className="link" rel="noreferrer noopener">
                    Perks Hub
                </a>{' '}
                within your account page on the Misfits Market website or through the mobile app. You can view your
                available perks during your active shopping window by visiting the “Shop with Points” aisle in our
                store.
            </>
        ),
    },
];

const perksShoppingFaqList = [
    {
        id: 1,
        headline: 'How can I redeem my points for perks?',
        content:
            'Once you’ve accumulated enough points, log in during your next shopping window and go to the “Shop with Points” aisle to redeem your points for a free grocery item. Once you’ve redeemed your free perk and added it to your cart, you’ll get it in your next order (as long as your cart subtotal is above the order minimum!).',
    },
    {
        id: 2,
        headline: 'Can I buy multiple items with my points?',
        content:
            'Yes! You can buy multiple items with your points. However, please note that you can only use up to 1000 points per order.',
    },
    {
        id: 3,
        headline: 'If I redeem a perk but skip my next order, will I lose that perk?',
        content:
            'If you redeem a free item but don’t place your next order, the perk will not be shipped and the points you redeemed will be returned back to you.',
    },
    {
        id: 4,
        headline: 'Can I use my points along with other promotional discounts or codes?',
        content: 'Yes, you can! We always want you to save more.',
    },
];

const perksPlanFaqList = [
    {
        id: 1,
        headline: 'Are there any membership levels or tiers in the program?',
        content:
            'Not at this time. However, the ratio of points you earn will depend on whether you are shopping with a weekly grocery plan or ordering a la carte. Weekly grocery plan members earn points twice as quickly from their purchases.',
    },
    {
        id: 2,
        headline: 'What if I cancel my weekly grocery plan and downgrade to ordering a la carte?',
        content:
            'Good news! If you cancel your weekly grocery plan and downgrade to an a la carte shopping plan, you’ll still retain your existing points. You’ll only lose your points if you fully cancel your account with Misfits Market, if you do not place an order for 60 days, or if you don’t use earned points within 365 days.',
    },
];

const perksRefundsFaqList = [
    {
        id: 1,
        headline: 'What happens to my points if I return or cancel an order?',
        content:
            'If your full order is refunded, your points will not be subtracted, and any points spent on that order will be credited back to you. However, if you cancel an order, the points spent on that order will be deducted from your balance.',
    },
];

const perksRestrictionsFaqList = [
    {
        id: 1,
        headline: 'Do my points have an expiration date?',
        content: (
            <>
                Yes, your points balance will automatically expire if:{' '}
                <ul className="list-disc pl-15">
                    <li>You don’t place an order with Misfits Market for 60 days;</li>
                    <li>You don’t use points within 365 days of the date they are earned; or </li>
                    <li>You completely cancel your Misfits Market account.</li>
                </ul>
            </>
        ),
    },
    {
        id: 2,
        headline: 'What happens if I don’t shop at Misfits Market for a long time?',
        content:
            'Points only expire if you don’t place an order for 60 days, if you don’t use earned points within 365 days, or if you completely cancel your account. As long as you place orders every 60 days, you can always keep accruing points.',
    },
    {
        id: 3,
        headline: 'Can I earn points from wine purchases? What about gift cards?',
        content:
            'At this time, you can only earn points from grocery purchases (including Cold Pack items!), not from purchasing wine or gift cards. Please also note that perks are not eligible to be applied to wine or gift card orders.',
    },
];

export {
    creditsFaqs,
    discountFaqs,
    perksFaqs,
    perksPlanFaqList,
    perksPointsFaqList,
    perksProgramFaqList,
    perksRefundsFaqList,
    perksRestrictionsFaqList,
    perksShoppingFaqList,
    unbundledWineFaqs,
    wineFaqs,
};
