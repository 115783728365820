import PropTypes from 'prop-types';

function MMDeliveryTruckIcon({ className, width }) {
    return (
        <svg width={width} className={className} viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M35.3304 58.4603C50.025 51.497 63.2848 39.2839 59.993 23.3654C56.7013 7.44696 41.7704 0.407724 23.3958 0.804419C7.1397 1.15047 -0.0345786 18.259 0.530923 35.5617C1.0711 51.8431 21.3195 65.1028 35.3304 58.4603Z"
                fill="#F0C14B"
            />
            <path
                d="M32.3342 35.9163L22.6616 36.6422L6.37173 37.8745C6.35499 35.6451 6.18012 33.4197 5.84843 31.215C5.73027 30.4554 5.27449 23.8635 5.25761 23.0954C5.13944 18.0988 4.41357 11.912 4.41357 11.912C4.41357 11.912 11.3768 11.4984 13.6979 11.2115C19.7665 10.4603 30.3845 9.95386 30.3845 9.95386"
                fill="white"
            />
            <path
                d="M32.3342 35.9163L22.6616 36.6422L6.37173 37.8745C6.35499 35.6451 6.18012 33.4197 5.84843 31.215C5.73027 30.4554 5.27449 23.8635 5.25761 23.0954C5.13944 18.0988 4.41357 11.912 4.41357 11.912C4.41357 11.912 11.3768 11.4984 13.6979 11.2115C19.7665 10.4603 30.3845 9.95386 30.3845 9.95386"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M32.3341 35.9162C32.3341 35.9162 31.9881 28.9698 31.6336 26.6825C30.8167 21.1433 30.3964 15.5528 30.376 9.95376L35.82 9.54018L39.9051 9.23633C39.9051 9.23633 40.2427 12.3761 40.268 14.0051C40.268 15.4737 40.7069 19.8627 40.7069 19.8627L38.4787 20.0231C38.4787 20.0231 38.8923 24.0744 39.0189 27.2142C39.1961 31.3669 39.635 35.376 39.635 35.376L32.3341 35.9162Z"
                fill="#B0C372"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M51.5274 34.4728L50.4217 19.8036C50.3964 19.6158 50.298 19.4456 50.1478 19.3302C49.9975 19.2148 49.8078 19.1634 49.6198 19.1874C47.8701 19.1682 46.122 19.3009 44.3953 19.5841L37.9131 20.0736L35.2291 20.2762C35.0731 20.3088 34.9361 20.4014 34.8477 20.534C34.7593 20.6666 34.7265 20.8286 34.7564 20.9852C34.7473 23.2601 34.9195 25.5321 35.2713 27.7797C35.5414 29.7294 35.8621 35.6545 35.8621 35.6545C35.8621 35.6545 41.7281 35.3928 42.6144 35.2916C46.691 34.8442 51.5274 34.4728 51.5274 34.4728Z"
                fill="white"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M38.0986 42.3141C38.1349 42.8962 38.2864 43.4653 38.5443 43.9884C38.8023 44.5115 39.1615 44.9782 39.6012 45.3614C40.0408 45.7446 40.5522 46.0367 41.1057 46.2207C41.6591 46.4047 42.2435 46.4771 42.8251 46.4335C43.4067 46.3899 43.9739 46.2313 44.4937 45.9668C45.0136 45.7024 45.4757 45.3374 45.8534 44.8929C46.231 44.4485 46.5167 43.9335 46.6938 43.3778C46.8709 42.8221 46.9359 42.2368 46.885 41.6558"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M37.9724 42.1704C38.0609 43.3362 38.6083 44.4193 39.4944 45.182C40.3805 45.9447 41.533 46.3247 42.699 46.2386C45.1298 46.0529 46.5984 44.0863 46.7673 41.512C46.9276 39.0812 44.463 37.2919 42.0407 37.4438C40.8749 37.5323 39.7918 38.0797 39.0291 38.9658C38.2664 39.8519 37.8864 41.0044 37.9724 42.1704Z"
                fill="#B0C372"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M29.0761 42.8455C29.2618 45.2679 31.4056 47.3104 33.8027 46.9053C36.6049 46.4411 38.0229 44.6095 37.8709 42.1787C37.7825 41.0124 37.2344 39.9291 36.3472 39.1669C35.46 38.4048 34.3064 38.0263 33.1401 38.1147C31.9739 38.2031 30.8905 38.7512 30.1283 39.6384C29.3662 40.5256 28.9877 41.6792 29.0761 42.8455Z"
                fill="white"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.3188 43.8584C17.3615 44.4373 17.5183 45.002 17.7802 45.5199C18.0422 46.0379 18.4041 46.4989 18.845 46.8763C19.286 47.2537 19.7973 47.5401 20.3495 47.7189C20.9017 47.8977 21.4838 47.9655 22.0623 47.9182C25.0164 47.6988 25.5482 45.9685 26.1221 43.1917"
                fill="#B0C372"
            />
            <path
                d="M17.3188 43.8584C17.3615 44.4373 17.5183 45.002 17.7802 45.5199C18.0422 46.0379 18.4041 46.4989 18.845 46.8763C19.286 47.2537 19.7973 47.5401 20.3495 47.7189C20.9017 47.8977 21.4838 47.9655 22.0623 47.9182C25.0164 47.6988 25.5482 45.9685 26.1221 43.1917"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.6993 47.8085C24.1326 47.8085 26.1052 45.836 26.1052 43.4027C26.1052 40.9694 24.1326 38.9968 21.6993 38.9968C19.266 38.9968 17.2935 40.9694 17.2935 43.4027C17.2935 45.836 19.266 47.8085 21.6993 47.8085Z"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.32147 44.407C8.31176 44.9947 8.44496 45.576 8.7096 46.1009C8.97424 46.6258 9.36241 47.0785 9.84073 47.4202C10.771 48.1029 11.8942 48.4724 13.0481 48.4752C15.9768 48.3824 17.2682 46.171 17.1079 43.7487C17.0716 43.1666 16.9201 42.5975 16.6621 42.0744C16.4042 41.5513 16.045 41.0846 15.6053 40.7014C15.1656 40.3182 14.6543 40.0261 14.1008 39.8421C13.5474 39.658 12.9629 39.5857 12.3813 39.6293C11.7997 39.6728 11.2326 39.8315 10.7128 40.0959C10.1929 40.3604 9.73079 40.7254 9.35313 41.1698C8.97547 41.6143 8.68981 42.1293 8.51271 42.685C8.33562 43.2407 8.27061 43.826 8.32147 44.407Z"
                fill="white"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M38.7231 35.4437L45.1462 34.9542L51.1304 34.5068"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M39.5841 35.376C39.5841 35.376 26.873 36.5661 22.6191 36.6589C16.7108 36.7855 6.3208 37.8828 6.3208 37.8828L6.82722 44.635L8.20299 44.5253C8.11457 43.3523 8.49574 42.1923 9.26264 41.3003C10.0295 40.4084 11.1194 39.8576 12.2923 39.7692C13.4653 39.6808 14.6254 40.0619 15.5173 40.8288C16.4093 41.5957 16.96 42.6855 17.0485 43.8585L28.9831 42.9216C28.8947 41.7487 29.2758 40.5886 30.0427 39.6966C30.8096 38.8047 31.8994 38.2539 33.0724 38.1655C34.2454 38.0771 35.4055 38.4583 36.2974 39.2252C37.1894 39.9921 37.7401 41.0819 37.8285 42.2548C37.8285 42.2548 42.2091 41.9932 43.6608 41.8075C47.5687 41.318 52.0336 41.1829 52.0336 41.1829L51.7804 37.8068L51.5272 34.4307L39.5841 35.376Z"
                fill="white"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.6058 19.39V19.2212V19.39ZM12.6935 25.8215C12.6577 25.6868 12.6067 25.5565 12.5416 25.4333C12.345 25.1353 12.1674 24.8252 12.0098 24.5048C11.935 24.1064 11.9666 23.6952 12.1014 23.3129C12.2363 22.9305 12.4696 22.5905 12.7779 22.3272C14.0062 20.8192 15.7176 19.7816 17.6227 19.39C18.855 19.2212 19.8425 19.7614 20.9988 19.6348C21.4465 19.5693 21.9035 19.6416 22.309 19.8421C22.7146 20.0426 23.0495 20.3618 23.2692 20.7573C23.4543 21.4008 23.7061 22.0231 24.0204 22.6142C24.2525 22.924 24.4105 23.283 24.4822 23.6634C24.5539 24.0438 24.5374 24.4356 24.434 24.8087C24.2818 25.161 24.0952 25.4974 23.8769 25.8131C23.2355 26.8935 23.1342 28.4127 22.3492 29.3833C21.4798 30.2027 20.3579 30.7026 19.1672 30.8013C18.3663 30.9062 17.5523 30.8457 16.7756 30.6236C15.999 30.4015 15.2761 30.0225 14.6517 29.51C13.9089 28.9782 13.259 28.7503 12.9636 27.8219C12.7695 27.1804 12.8623 26.4799 12.6766 25.8384"
                fill="#F0C14B"
            />
            <path
                d="M18.5761 20.8251C18.5761 20.8251 17.4029 17.5165 21.4374 16.9932C21.4374 16.9932 22.2814 20.1161 19.6565 20.6985"
                fill="#2E8540"
            />
            <path
                d="M43.2304 39.9169C43.9623 39.9169 44.5555 39.3236 44.5555 38.5917C44.5555 37.8599 43.9623 37.2666 43.2304 37.2666C42.4986 37.2666 41.9053 37.8599 41.9053 38.5917C41.9053 39.3236 42.4986 39.9169 43.2304 39.9169Z"
                fill="#2D2D2D"
            />
            <path
                d="M48.4975 39.4696C49.2293 39.4696 49.8226 38.8763 49.8226 38.1445C49.8226 37.4126 49.2293 36.8193 48.4975 36.8193C47.7656 36.8193 47.1724 37.4126 47.1724 38.1445C47.1724 38.8763 47.7656 39.4696 48.4975 39.4696Z"
                fill="#2D2D2D"
            />
            <path
                d="M46.3282 27.1215L40.3271 27.5942C40.1938 27.5991 40.0636 27.5529 39.9632 27.4651C39.8628 27.3772 39.7998 27.2543 39.787 27.1215L39.4915 23.661C39.4858 23.5911 39.4939 23.5208 39.5154 23.4541C39.5369 23.3874 39.5714 23.3255 39.6169 23.2722C39.6624 23.2189 39.718 23.175 39.7805 23.1433C39.843 23.1115 39.9112 23.0924 39.9811 23.0871L45.9737 22.6144C46.1135 22.6027 46.2523 22.6467 46.3598 22.7368C46.4673 22.8269 46.5348 22.9558 46.5477 23.0955L46.8431 26.556C46.8476 26.6273 46.8378 26.6987 46.8142 26.766C46.7906 26.8334 46.7537 26.8953 46.7056 26.948C46.6576 27.0008 46.5994 27.0433 46.5346 27.0732C46.4697 27.103 46.3995 27.1194 46.3282 27.1215Z"
                fill="#2D2D2D"
            />
        </svg>
    );
}

MMDeliveryTruckIcon.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number,
};

MMDeliveryTruckIcon.defaultProps = {
    className: '',
    width: 60,
};

export default MMDeliveryTruckIcon;
