import PropTypes from 'prop-types';

import IFColdPackActive from './IFColdPackActive';
import IFColdPackInactive from './IFColdPackInactive';

function IFColdPack({ className, isActive, width }) {
    return isActive ? (
        <IFColdPackActive className={className} width={width} />
    ) : (
        <IFColdPackInactive className={className} width={width} />
    );
}

export default IFColdPack;

IFColdPack.propTypes = {
    isActive: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

IFColdPack.defaultProps = {
    isActive: true,
    width: 28,
};
