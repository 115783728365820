import { useEffect } from 'react';
import { Auth } from 'aws-amplify';

import Loader from 'honeydew/Loader';

function Logout() {
    useEffect(() => {
        async function signOut() {
            await Auth.signOut();
            window.location.href = '/login';
        }
        signOut();
    }, []);

    return (
        <div className="mt-80 flex justify-center p-20">
            <Loader />
        </div>
    );
}

export { Logout as Component };
