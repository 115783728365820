/**
 * Create an object containing estimated tax content block data.
 * @param {Object} options
 * @param {number} options.value - The estimated tax value (USD).
 * @returns Estimated tax content block data.
 */
const createEstimatedTaxBlockData = ({ value }) => ({
    heading: 'Estimated Taxes',
    value,
    body: 'We estimate sales tax based on your address. After your shopping window closes, the final tax amount is calculated (based on state, county, city and district rates) and charged with your order.',
});

/**
 * Create an object containing operations fee content block data.
 * @param {Object} options
 * @param {number} options.value - The operations fee value (USD).
 * @returns Operations fee content block data.
 */
const createOperationsFeeBlockData = ({ value }) => ({
    heading: 'Operations Fee',
    value,
    body: 'This fee helps cover operating costs of sourcing and packing fresh groceries using more sustainable, zero-waste practices in our facilities.',
});

export { createEstimatedTaxBlockData, createOperationsFeeBlockData };
