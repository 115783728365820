import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

function SEO({ canonicalURL, description, image, noIndex, title }) {
    return (
        <Helmet title={title}>
            <meta name="description" content={description} />
            <meta name="image" content={image} />

            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:title" content={title} />

            {canonicalURL && <link rel="canonical" href={canonicalURL} />}

            {noIndex && <meta name="robots" content="noindex" />}
        </Helmet>
    );
}

export default SEO;

SEO.propTypes = {
    canonicalURL: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    noIndex: PropTypes.bool,
    title: PropTypes.string,
};

SEO.defaultProps = {
    canonicalURL: '',
    description:
        "We deliver organic produce boxes and sustainable pantry staples straight to your door. Fight food waste with Misfits Market's online grocery delivery.",
    image: 'https://img-cdn.misfitsmarket.com/images/MM_home-box3.png',
    noIndex: false,
    title: 'Misfits Market - Save up to 30% off your groceries',
};
