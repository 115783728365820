import PropTypes from 'prop-types';

function PlanPausedIcon({ width }) {
    return (
        <svg width={width} viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M26.7486 53.2739C40.9628 49.8161 54.8768 41.477 54.8768 26.8746C54.8768 12.2722 43.0126 3.33266 26.7486 0.330389C12.3687 -2.32507 2.93749 11.4388 0.266502 26.8125C-2.22331 41.2286 13.2073 56.5919 26.7486 53.2998"
                className="fill-malt if:fill-off-white"
            />
            <path
                d="M32.3885 42.0025C32.3885 42.0025 26.8993 41.548 25.106 41.2208C21.6559 40.6391 16.8038 40.3118 16.8038 40.3118C15.8856 40.2111 15.0448 39.7516 14.4649 39.0337C13.8849 38.3158 13.613 37.3978 13.7088 36.4804C13.7088 36.4804 14.2276 32.0037 14.3551 30.5084C14.5872 27.6542 14.9787 24.6863 14.9787 24.6863L15.5065 19.8096L23.7905 20.7186C26.927 20.8871 30.0522 21.2224 33.153 21.723C35.0965 22.0457 40.9863 22.5638 40.9863 22.5638C40.9863 22.5638 40.2808 28.1086 40.1898 29.9675C40.0395 32.9354 39.1885 39.2347 39.1885 39.2347"
                fill="white"
            />
            <path
                d="M32.3885 42.0025C32.3885 42.0025 26.8993 41.548 25.106 41.2208C21.6559 40.6391 16.8038 40.3118 16.8038 40.3118C15.8856 40.2111 15.0448 39.7516 14.4649 39.0337C13.8849 38.3158 13.613 37.3978 13.7088 36.4804C13.7088 36.4804 14.2276 32.0037 14.3551 30.5084C14.5872 27.6542 14.9787 24.6863 14.9787 24.6863L15.5065 19.8096L23.7905 20.7186C26.927 20.8871 30.0522 21.2224 33.153 21.723C35.0965 22.0457 40.9863 22.5638 40.9863 22.5638C40.9863 22.5638 40.2808 28.1086 40.1898 29.9675C40.0395 32.9354 39.1885 39.2347 39.1885 39.2347"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M34.2676 36.7254C34.1833 38.6354 33.5216 40.4751 32.3696 42.0021C32.3696 42.0021 38.8556 41.034 39.1514 39.2297C39.3927 37.8753 34.3541 35.0756 34.2676 36.7254Z"
                className="fill-mango if:fill-[#F7D46D]"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M40.9679 22.5634L15.479 19.8364L15.6884 17.9002C15.7892 16.9837 16.2494 16.1444 16.9685 15.5661C17.6876 14.9877 18.6069 14.7174 19.5252 14.8142C19.5252 14.8142 26.3162 15.7232 28.5966 15.7868C32.0786 15.855 38.0729 16.8049 38.0729 16.8049C38.9911 16.9056 39.8321 17.3651 40.412 18.0831C40.992 18.801 41.2639 19.7189 41.1681 20.6363L40.9679 22.5634Z"
                className="fill-mango if:fill-[#F7D46D]"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M20.6554 12.1558L20.0591 17.6961" stroke="#2D2D2D" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M37.546 13.9736L36.9497 19.5139" stroke="#2D2D2D" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25.2377 26.4585L24.4185 34.0395" stroke="#2D2D2D" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M29.8445 26.9541L29.0298 34.5351" stroke="#2D2D2D" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

PlanPausedIcon.propTypes = {
    width: PropTypes.number,
};

PlanPausedIcon.defaultProps = {
    width: 55,
};

export default PlanPausedIcon;
