import { CheckIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';

import Tag from 'honeydew/Tag';
import OrderBanner from 'shared/OrderBanner';

function CartOrderCard({ cardRef, children, percentOff, title, willShip }) {
    return (
        <div className="scroll-mt-10 rounded-lg bg-white" ref={cardRef}>
            <OrderBanner marginClasses="m-0" paddingClasses="p-5" variant={willShip ? 'mango' : 'error'}>
                {willShip ? (
                    <CheckIcon className="h-15 w-15 stroke-[3px]" />
                ) : (
                    <ExclamationTriangleIcon className="h-15 w-15 stroke-[3px]" />
                )}
                <p className="text-body-sm tracking-wider">{willShip ? 'UPCOMING ORDER' : 'ORDER WILL NOT SHIP'}</p>
            </OrderBanner>
            <div className="flex flex-col px-20 pb-25 pt-10">
                <div className="flex justify-between">
                    <p className="text-body-md font-grotesk-bold">{title}</p>
                    <div>
                        {percentOff > 0 && (
                            <span className="mr-10">
                                <Tag bgColor="special" variant="dark">
                                    {percentOff}%OFF
                                </Tag>
                            </span>
                        )}
                        <Tag bgColor={willShip ? 'default' : 'error'} variant={willShip ? 'dark' : 'medium'}>
                            {willShip ? 'READY TO SHIP' : 'INCOMPLETE'}
                        </Tag>
                    </div>
                </div>
                {children}
            </div>
        </div>
    );
}

export default CartOrderCard;

CartOrderCard.propTypes = {
    cardRef: PropTypes.shape({
        current: PropTypes.instanceOf(Element),
    }),
    children: PropTypes.node.isRequired,
    percentOff: PropTypes.number,
    title: PropTypes.string.isRequired,
    willShip: PropTypes.bool.isRequired,
};

CartOrderCard.defaultProps = {
    cardRef: undefined,
    percentOff: 0,
};
