import PropTypes from 'prop-types';

function IFBuyItAgain({ className, width }) {
    return (
        <svg className={className} width={width} viewBox="0 0 61 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.946 22.0552L16.7986 18.3117C18.0068 17.9252 43.3414 15.5533 45.5071 15.5306C47.6727 15.5079 48.5466 16.6824 48.653 18.0692C48.7594 19.456 46.9053 31.2246 46.4949 32.3158C46.0846 33.407 45.2411 34.862 43.8277 35.1424C42.4143 35.4228 26.8823 36.9232 25.0509 37.0445C23.2196 37.1657 18.2804 23.6466 17.946 22.0552Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.1102 17.7739C22.1102 17.7739 23.1892 20.3277 25.5829 26.4734C27.9765 32.6192 29.5191 36.6431 29.5191 36.6431"
                fill="white"
            />
            <path
                d="M22.1102 17.7739C22.1102 17.7739 23.1892 20.3277 25.5829 26.4734C27.9765 32.6192 29.5191 36.6431 29.5191 36.6431"
                stroke="#2D2D2D"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M32.5586 16.7129C32.5586 16.7129 32.9234 19.6153 33.4325 25.2684C33.9416 30.9216 34.3596 32.4599 34.5647 36.1277"
                fill="white"
            />
            <path
                d="M32.5586 16.7129C32.5586 16.7129 32.9234 19.6153 33.4325 25.2684C33.9416 30.9216 34.3596 32.4599 34.5647 36.1277"
                stroke="#2D2D2D"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M41.5937 15.8186C41.5937 15.8186 41.8216 15.2275 41.7076 22.9343C41.5754 27.1872 41.0951 31.4222 40.2715 35.5971"
                fill="white"
            />
            <path
                d="M41.5937 15.8186C41.5937 15.8186 41.8216 15.2275 41.7076 22.9343C41.5754 27.1872 41.0951 31.4222 40.2715 35.5971"
                stroke="#2D2D2D"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.8807 24.8516C19.8913 24.4727 24.823 23.4269 31.662 23.0708C38.5009 22.7146 45.1879 21.8052 48.3794 21.5552"
                fill="white"
            />
            <path
                d="M18.8807 24.8516C19.8913 24.4727 24.823 23.4269 31.662 23.0708C38.5009 22.7146 45.1879 21.8052 48.3794 21.5552"
                stroke="#2D2D2D"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.829 32.2097C23.0829 31.952 28.7288 31.2321 37.3839 30.6335C46.039 30.0348 47.0876 29.5347 47.0876 29.5347"
                fill="white"
            />
            <path
                d="M21.829 32.2097C23.0829 31.952 28.7288 31.2321 37.3839 30.6335C46.039 30.0348 47.0876 29.5347 47.0876 29.5347"
                stroke="#2D2D2D"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.946 22.0555C17.946 22.0555 16.0083 14.6518 14.5873 13.9622C13.1663 13.2726 10.9931 13.7727 9.4353 14.1895"
                stroke="#2D2D2D"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M25.051 37.0444C24.3891 37.1795 23.7189 37.2706 23.0449 37.3172C20.7652 37.5522 21.092 41.6291 23.478 41.5912C25.8641 41.5533 42.4372 40.1893 43.3414 39.9923"
                stroke="#2D2D2D"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M43.6377 45.8654C45.2661 45.8654 46.5861 44.549 46.5861 42.9251C46.5861 41.3013 45.2661 39.9849 43.6377 39.9849C42.0094 39.9849 40.6894 41.3013 40.6894 42.9251C40.6894 44.549 42.0094 45.8654 43.6377 45.8654Z"
                fill="#F6A685"
                stroke="#2D2D2D"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M27.7941 47.4645C29.4225 47.4645 30.7425 46.1481 30.7425 44.5242C30.7425 42.9004 29.4225 41.584 27.7941 41.584C26.1658 41.584 24.8458 42.9004 24.8458 44.5242C24.8458 46.1481 26.1658 47.4645 27.7941 47.4645Z"
                fill="#F6A685"
                stroke="#2D2D2D"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.0988 11.6613L3.25286 12.1522C2.00864 12.2782 1.10245 13.3862 1.22884 14.627L1.25418 14.8758C1.38056 16.1166 2.49166 17.0203 3.73588 16.8943L8.58182 16.4034C9.82604 16.2774 10.7322 15.1693 10.6058 13.9285L10.5805 13.6798C10.4541 12.4389 9.34302 11.5352 8.0988 11.6613Z"
                fill="#F6A685"
                stroke="#2D2D2D"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M53.8137 25.8903C57.7837 20.173 56.3543 12.3339 50.6259 8.37925C44.8974 4.42456 37.0452 5.85593 33.0752 11.5732"
                stroke="#2D2D2D"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M33.4338 7.28613L32.7431 11.8954L37.6034 11.7654"
                stroke="#2D2D2D"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default IFBuyItAgain;

IFBuyItAgain.propTypes = {
    className: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

IFBuyItAgain.defaultProps = {
    className: null,
    width: 28,
};
