import { IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN } from './theme';

export const TRIAL_LENGTH_DAYS = 14;
export const MEMBERSHIP_COST = 69;
export const CARRIER_SHIP_FEE_REDUCTION = 3;

export const BRANDING_NAME = {
    [MISFITS_THEME_DOMAIN]: 'Misfits+',
    [IMPERFECT_THEME_DOMAIN]: 'Imperfect+',
};

export const SHORT_BRANDING_NAME = {
    [MISFITS_THEME_DOMAIN]: 'M+',
    [IMPERFECT_THEME_DOMAIN]: 'IF+',
};

export const MIN_MEMBERSHIP_SAVINGS = 1;

export const AVERAGE_MEMBERSHIP_SAVINGS = {
    dbi: {
        year: 333.32,
        month: 25.64,
    },
    cs: {
        year: 425.88,
        month: 32.76,
    },
};

export const CART_SAVINGS_BANNER_DISMISSED = 'CART-SAVINGS-BANNER-DISMISSED';
