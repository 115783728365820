import PropTypes from 'prop-types';

import IFDeliveryTruckIcon from './IFDeliveryTruckIcon';
import MMDeliveryTruckIcon from './MMDeliveryTruckIcon';

function DeliveryTruckIcon({ className, isMisfits, width }) {
    return isMisfits ? (
        <MMDeliveryTruckIcon className={className} width={width} />
    ) : (
        <IFDeliveryTruckIcon className={className} width={width} />
    );
}

export default DeliveryTruckIcon;

DeliveryTruckIcon.propTypes = {
    className: PropTypes.string,
    isMisfits: PropTypes.bool,
    width: PropTypes.number,
};

DeliveryTruckIcon.defaultProps = {
    className: '',
    isMisfits: true,
    width: 60,
};
