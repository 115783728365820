import PropTypes from 'prop-types';

function DowngradePlanIcon({ width }) {
    return (
        <svg width={width} viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4538_167691)">
                <mask
                    id="mask0_4538_167691"
                    style={{ maskType: 'luminance' }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="60"
                    height="61"
                >
                    <path d="M60 0.984863H0V60.4549H60V0.984863Z" fill="white" />
                </mask>
                <g mask="url(#mask0_4538_167691)">
                    <path
                        d="M35.1102 58.7049C49.6402 51.7349 62.7502 39.5349 59.5002 23.5749C56.2402 7.62491 41.4902 0.604913 23.3202 1.00491C7.2502 1.34491 0.170203 18.4749 0.690203 35.8249C1.2202 52.0949 21.2402 65.3549 35.0902 58.7249"
                        className="fill-mango if:fill-[#FFCBDC]"
                    />
                    <path
                        d="M9.82031 17.7151C9.82031 17.7151 16.5603 14.4651 19.7103 13.2851C22.0103 12.4251 28.6503 9.26514 28.6503 9.26514C28.6503 9.26514 35.1303 12.3951 38.6503 13.4851C41.2403 14.2851 48.6503 17.7051 48.6503 17.7051C48.6503 17.7051 42.3403 20.4551 38.6503 21.5351C36.0903 22.2951 28.6503 25.3651 28.6503 25.3651L18.1303 21.1051L9.82031 17.6951V17.7151Z"
                        fill="white"
                        stroke="#2D2D2D"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M9.82016 17.7148C9.82016 17.7148 10.2102 24.6348 9.82016 28.4948C9.46016 32.0348 9.82016 42.7448 9.82016 42.7448L28.6402 50.3748V25.4048L9.82016 17.7248V17.7148Z"
                        fill="white"
                        stroke="#2D2D2D"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M48.6304 17.7151C48.6304 17.7151 48.7704 26.7151 48.6304 29.7051C48.4304 34.0351 48.6304 42.3551 48.6304 42.3551C48.6304 42.3551 43.7904 44.0551 40.5704 45.5851C37.6704 46.9551 28.6504 50.3751 28.6504 50.3751C28.6504 50.3751 28.7704 41.3651 28.6504 38.3351C28.4604 33.3151 28.6504 25.3751 28.6504 25.3751L48.6304 17.7051V17.7151Z"
                        className="fill-[#F2D79A] if:fill-oat"
                        stroke="#2D2D2D"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <path
                    d="M14.2702 49.475C20.2349 49.475 25.0702 44.6397 25.0702 38.675C25.0702 32.7103 20.2349 27.875 14.2702 27.875C8.30554 27.875 3.47021 32.7103 3.47021 38.675C3.47021 44.6397 8.30554 49.475 14.2702 49.475Z"
                    className="fill-[#B0C372] if:fill-[#C5DB66]"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M14.2197 34.335V41.875" stroke="#2D2D2D" strokeLinecap="round" strokeLinejoin="round" />
                <path
                    d="M17.3899 39.2051L14.2199 43.0151L11.1499 39.2051"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_4538_167691">
                    <rect width="60" height="59.49" fill="white" transform="translate(0 0.984863)" />
                </clipPath>
            </defs>
        </svg>
    );
}

DowngradePlanIcon.propTypes = {
    width: PropTypes.number,
};

DowngradePlanIcon.defaultProps = {
    width: 60,
};

export default DowngradePlanIcon;
