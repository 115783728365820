import PropTypes from 'prop-types';

import { IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN } from 'constants/theme';

export function Bakery({ brand, isActive, width }) {
    const breadColor1 = brand === MISFITS_THEME_DOMAIN ? '#DEA455' : '#F6A685';
    const breadColor2 = brand === MISFITS_THEME_DOMAIN ? '#F1C34A' : '#F7D46D';
    const breadColor3 = brand === MISFITS_THEME_DOMAIN ? '#F2EBD1' : '#ECE7E4';
    const activeColor = brand === MISFITS_THEME_DOMAIN ? '#D7E1B8' : '#F0D3E3';

    return isActive ? (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.6505 28.7652C21.902 26.9646 29 22.6358 29 14.9992C29 7.3625 22.9452 2.7363 14.6505 1.1728C7.31233 -0.213857 2.48978 6.96057 1.13553 14.967C-0.132106 22.475 7.73356 30.4815 14.6505 28.7652Z"
                fill={activeColor}
            />
            <path
                d="M15.889 15.8641C15.2296 14.313 14.0221 10.8485 12.2481 7.69981C10.4461 4.47681 8.54206 3.26934 6.32218 3.65945C6.48008 4.15172 6.52652 4.67186 6.36863 5.15485C6.08998 6.00007 5.17973 6.64096 4.31593 6.46448C4.44597 7.03106 4.28807 7.66266 3.89796 8.09921C3.5543 8.48002 3.03416 8.69365 2.51402 8.69365C2.50473 9.65962 2.68121 10.607 3.0063 11.3872C3.86081 13.4585 4.20447 14.0715 5.41194 17.1552C5.41194 17.1552 5.69059 17.8425 7.29745 21.307C7.54823 21.8643 7.91047 24.2978 10.8455 25.7561C14.0871 27.3629 16.9107 25.301 17.403 22.9325C18.0996 19.598 16.5485 17.4246 15.889 15.8734V15.8641Z"
                fill={breadColor1}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.57856 6.01867C3.46094 6.09297 4.29688 5.3592 4.47336 4.48611C4.57553 3.98455 4.47336 3.4737 4.25973 3C4.02752 3.06502 3.79532 3.14861 3.56311 3.25078C1.45469 4.21675 0.832382 6.40877 1.03672 8.4336C1.54757 8.37787 2.03985 8.1178 2.34636 7.69054C2.69002 7.21684 2.77362 6.57596 2.57856 6.01867Z"
                fill={breadColor1}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.18555 15.9756C7.59423 15.0375 9.0339 11.6009 13.2693 12.8548C11.8761 14.536 7.18555 15.9756 7.18555 15.9756Z"
                fill={breadColor3}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.1543 20.9355C9.55369 19.9974 11.0026 16.5607 15.2381 17.8146C13.8448 19.4865 9.1543 20.9355 9.1543 20.9355Z"
                fill={breadColor3}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.77051 11.2015C5.1699 10.2634 6.61886 6.8268 10.8543 8.09C9.47034 9.76187 4.77051 11.2015 4.77051 11.2015Z"
                fill={breadColor3}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.2688 18.5299C13.1481 20.0068 15.1915 21.3443 16.1574 21.4743C16.7797 21.5672 21.5817 21.9852 23.2072 22.1988C24.8326 22.4124 25.3899 22.2174 25.4828 21.1306C25.5664 20.0439 25.9472 16.4029 25.9379 15.5484C25.9379 14.6939 26.393 14.6475 26.8946 14.3317C27.7955 13.7837 27.5726 11.7681 27.0896 11.0251C26.6067 10.282 26.0494 9.47395 23.3651 9.00954C22.2412 8.81448 21.108 8.69374 20.5693 8.63801C18.851 8.49868 14.9314 8.26648 14.9314 8.26648C14.7735 10.0963 13.3524 13.6536 13.9561 14.1831C14.2348 14.4153 13.6217 15.7156 13.6032 16.1057C13.566 16.9231 13.4546 17.7312 13.2874 18.5299H13.2688Z"
                fill={breadColor2}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26.0869 14.8704C26.0869 14.8704 23.254 14.6753 19.1765 14.5824C17.2724 14.5453 17.8947 18.4277 18.0526 21.6043C18.3406 21.725 22.3252 22.1059 23.8206 22.2359C25.316 22.3752 25.3903 22.013 25.5203 20.6197C25.6504 19.2265 25.6597 17.471 26.0869 14.8704Z"
                fill={breadColor1}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.5699 17.536C11.4492 19.0128 10.7804 21.0098 11.7464 21.1398C12.3687 21.2327 16.632 21.4556 18.2667 21.6692C19.9014 21.8736 20.4494 21.6878 20.5423 20.6104C20.6259 19.5237 21.0067 15.8827 20.9974 15.0189C20.9882 14.1551 21.4526 14.1179 21.9541 13.8114C22.8551 13.2634 22.6322 11.2479 22.1399 10.5048C21.6476 9.76176 21.0996 8.96298 18.406 8.48928C16.8735 8.24779 15.3224 8.17348 13.7712 8.26636C12.0529 8.42426 10.6783 9.39023 10.5668 10.7463C10.4182 12.5668 10.8454 12.7711 11.4492 13.3006C11.5792 13.4399 11.6721 13.6071 11.7371 13.7928C11.7928 13.9786 11.8114 14.1644 11.7928 14.3594C11.7464 15.539 11.5699 17.5453 11.5699 17.5453V17.536Z"
                fill={breadColor3}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ) : (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.89986 16.561C4.6924 13.4773 4.34873 12.8643 3.49422 10.793C2.49109 8.36882 2.85333 4.30058 6.09492 3.25102C8.62131 2.44294 10.7483 3.54824 12.736 7.11491C14.5007 10.2729 15.7175 13.7281 16.377 15.2792C17.0364 16.8304 18.5876 19.0038 17.8909 22.3383C17.3987 24.7068 14.575 26.7687 11.3335 25.1619C8.40768 23.7036 8.04544 21.2794 7.78537 20.7128C6.16922 17.239 5.89986 16.561 5.89986 16.561Z"
                fill={breadColor1}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.67383 15.3907C8.08251 14.4526 9.52218 11.0159 13.7576 12.2698C12.3644 13.951 7.67383 15.3907 7.67383 15.3907Z"
                fill={breadColor3}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.64258 20.3506C10.042 19.4125 11.4909 15.9758 15.7264 17.2297C14.3331 18.9016 9.64258 20.3506 9.64258 20.3506Z"
                fill={breadColor3}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.25879 10.6166C5.65818 9.67847 7.10714 6.24184 11.3426 7.50503C9.95862 9.17691 5.25879 10.6166 5.25879 10.6166Z"
                fill={breadColor3}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.7581 17.945C13.6373 19.4218 15.6807 20.7593 16.6467 20.8893C17.269 20.9822 22.071 21.4002 23.6964 21.6138C25.3219 21.8275 25.8792 21.6324 25.972 20.5457C26.0556 19.459 26.4365 15.818 26.4272 14.9635C26.4272 14.109 26.8823 14.0625 27.3838 13.7467C28.2848 13.1987 28.0619 11.1832 27.5789 10.4401C27.0959 9.69706 26.5386 8.88899 23.8543 8.42457C22.7305 8.22952 21.5973 8.10878 21.0586 8.05305C19.3403 7.91372 15.4206 7.68152 15.4206 7.68152C15.2627 9.51129 13.8416 13.0687 14.4454 13.5981C14.724 13.8303 14.111 15.1307 14.0924 15.5208C14.0553 16.3381 13.9438 17.1462 13.7766 17.945H13.7581Z"
                fill={breadColor2}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26.585 14.2854C26.585 14.2854 23.7521 14.0903 19.6745 13.9975C17.7705 13.9603 18.3928 17.8428 18.5507 21.0193C18.8386 21.1401 22.8232 21.5209 24.3186 21.6509C25.814 21.781 25.8884 21.428 26.0184 20.0348C26.1484 18.6416 26.1577 16.8861 26.585 14.2854Z"
                fill={breadColor1}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.0582 16.9511C11.9375 18.4279 11.2687 20.4249 12.2347 20.5549C12.857 20.6478 17.1203 20.8707 18.755 21.0843C20.3897 21.2887 20.9377 21.1029 21.0306 20.0255C21.1142 18.9388 21.495 15.2978 21.4857 14.434C21.4764 13.5702 21.9408 13.533 22.4424 13.2265C23.3434 12.6785 23.1204 10.663 22.6282 9.91992C22.1359 9.17686 21.5879 8.37808 18.8943 7.90438C17.3618 7.66289 15.8106 7.58858 14.2595 7.68146C12.5412 7.83936 11.1665 8.80533 11.0551 10.1614C10.9065 11.9819 11.3337 12.1862 11.9375 12.7157C12.0675 12.855 12.1604 13.0222 12.2254 13.2079C12.2811 13.3937 12.2997 13.5795 12.2811 13.7745C12.2347 14.9541 12.0582 16.9604 12.0582 16.9604V16.9511Z"
                fill={breadColor1}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

Bakery.propTypes = {
    brand: PropTypes.oneOf([IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN]).isRequired,
    isActive: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Bakery.defaultProps = {
    isActive: false,
    width: 30,
};
