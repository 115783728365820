import { string } from 'yup';

import { FULL_MONTHS } from './dateUtils';

/**
 * Transforms a customer's stored birthday, which is in a YYYY-MM-DD format, to a MM/DD/YYYY format.
 * @param {string} birthday - The customer's stored birthday
 * @example
 * const shortBirthday = getShortBirthday('1980-12-25');
 * @return {string} The formatted string, for example 12/25/1980
 */
export const getShortBirthday = (birthday = '') => {
    const birthdayParts = birthday.split('-');
    return `${birthdayParts[1]}/${birthdayParts[2]}/${birthdayParts[0]}`;
};

/**
 * Transforms a customer's stored birthday, which is in a YYYY-MM-DD format, to a format with full month name.
 * @param {string} birthday - The customer's stored birthday
 * @example
 * const longBirthday = getLongBirthday('1980-12-25');
 * @return {string} The formatted string, for example December 25, 1980
 */
export const getLongBirthday = (birthday = '') => {
    const birthdayParts = birthday.split('-');
    return `${FULL_MONTHS[birthdayParts[1] - 1]} ${birthdayParts[2]}, ${birthdayParts[0]}`;
};

/** Regex to test if string is in a MM/DD/YYYY format. */
export const MMDDYYYY_REGEX = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;

/** Regex to test if string is a 'valid' birthday MM/DD/YYYY format. */
export const VALID_BIRTHDAY_REGEX = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;

export const birthdayValidation = string()
    .trim()
    .required('Please enter a birthday.')
    .matches(MMDDYYYY_REGEX, 'Please enter your birthday in a MM/DD/YYYY format.')
    .matches(VALID_BIRTHDAY_REGEX, 'Please enter a valid birthday.');

export const drinkingAgeValidation = string().test(
    'dob',
    'You are not old enough to purchase this item.',
    (value, ctx) => {
        const dob = new Date(value);
        const validDate = new Date();
        const valid = validDate.getFullYear() - dob.getFullYear() >= 21;
        return !valid ? ctx.createError() : valid;
    }
);

/**
 * Calculates how old the customer is based on their saved birthday
 * @param {string} birthday - The customer's stored birthday
 * @example
 * const age = calculateAge('1980-12-25');
 * @return {number} The number of years old, in this case, 42
 */
export const calculateAge = (birthday) => {
    if (!birthday) return 0;

    const today = new Date();
    const birthDate = new Date(birthday);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age -= 1;
    }

    return age;
};
