import { useContext } from 'react';

import { GlobalContext } from 'contexts/GlobalContext';

function useGlobalStore() {
    const globalStore = useContext(GlobalContext);
    return globalStore;
}

export default useGlobalStore;
