import clsx from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

export const fontSizeClassGroup = [
    'text-body-xxs',
    'text-body-xs',
    'text-body-sm',
    'text-body-md',
    'text-body-lg',
    'text-body-mobile-xxs',
    'text-body-mobile-xs',
    'text-body-mobile-md',
    'text-body-mobile-lg',
    'text-body-desktop-xxs',
    'text-body-desktop-xs',
    'text-body-desktop-md',
    'text-body-desktop-lg',
];

const customTwMerge = extendTailwindMerge({
    extend: {
        classGroups: {
            'font-size': fontSizeClassGroup,
        },
    },
});

function twclsx(...inputs) {
    return customTwMerge(clsx(...inputs));
}

export default twclsx;
