import PropTypes from 'prop-types';

function GlutenFreeIcon({ className, width }) {
    return (
        <svg width={width} className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_759_23846)">
                <path
                    d="M4.83785 4.97486C4.83785 4.97486 10.2893 20.7784 11.5446 22.8594C11.7715 23.2256 13.3341 23.8777 13.2921 22.1367C13.2628 21.2143 6.60041 4.29565 6.60041 4.29565L4.83785 4.97486Z"
                    className="fill-[#F8E1A4] if:fill-[#FFDE7C]"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2.90389 10.7346C2.90389 10.7346 3.13942 12.4591 5.52317 13.5012C7.90692 14.5432 9.03153 13.4955 9.03153 13.4955C8.67965 12.3884 7.91965 11.4566 6.90582 10.8894C5.15932 9.95877 2.90389 10.7346 2.90389 10.7346Z"
                    className="fill-[#F8E1A4] if:fill-[#FFDE7C]"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.9046 7.34742C11.9046 7.34742 10.1799 7.58723 9.13783 9.97098C8.09577 12.3547 9.14349 13.4793 9.14349 13.4793C10.2517 13.1268 11.1837 12.3651 11.7499 11.3494C12.6804 9.60285 11.9046 7.34742 11.9046 7.34742Z"
                    className="fill-[#F8E1A4] if:fill-[#FFDE7C]"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M13.3425 11.3116C13.3425 11.3116 11.618 11.5472 10.5759 13.9309C9.49666 16.3772 10.5814 17.4436 10.5814 17.4436C11.6896 17.0911 12.6216 16.3293 13.1878 15.3136C14.1179 13.5756 13.3425 11.3116 13.3425 11.3116Z"
                    className="fill-[#F8E1A4] if:fill-[#FFDE7C]"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M1.27871 6.33157C1.27871 6.33157 1.51851 8.05625 3.90226 9.09831C6.28602 10.1404 7.41062 9.09266 7.41062 9.09266C7.05813 7.9845 6.29641 7.05241 5.28065 6.48629C3.53454 5.54716 1.27871 6.33157 1.27871 6.33157Z"
                    className="fill-[#F8E1A4] if:fill-[#FFDE7C]"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.34123 14.7031C4.34123 14.7031 4.57676 16.4275 6.96031 17.4739C9.34386 18.5202 10.4687 17.4682 10.4687 17.4682C10.1156 16.3593 9.3543 15.4261 8.3389 14.8576C6.61356 13.9323 4.34123 14.7031 4.34123 14.7031Z"
                    className="fill-[#F8E1A4] if:fill-[#FFDE7C]"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.2838 2.94472C10.2838 2.94472 8.55917 3.18452 7.51283 5.56807C6.4665 7.95162 7.52276 9.07663 7.52276 9.07663C8.63092 8.72414 9.56301 7.96242 10.1291 6.94666C11.0597 5.20015 10.2838 2.94472 10.2838 2.94472Z"
                    className="fill-[#F8E1A4] if:fill-[#FFDE7C]"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M3.935 0.96146C3.935 0.96146 2.98822 2.41593 4.10643 4.76892C5.22465 7.1219 6.76969 7.05779 6.76969 7.05779C7.22612 5.98771 7.25846 4.78409 6.86015 3.69104C6.14611 1.84974 3.935 0.96146 3.935 0.96146Z"
                    className="fill-[#F8E1A4] if:fill-[#FFDE7C]"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17.1476 10.7563C17.1476 10.7563 18.8809 10.85 20.3633 8.70996C21.8851 6.51375 21.0246 5.26076 21.0246 5.26076C19.8697 5.39675 18.8099 5.96712 18.0602 6.85607C16.8265 8.38411 17.1476 10.7563 17.1476 10.7563Z"
                    className="fill-[#F8E1A4] if:fill-[#FFDE7C]"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M13.7894 4.01093C13.7894 4.01093 13.1085 5.59392 14.6103 7.73272C16.1121 9.87153 17.6264 9.52312 17.6264 9.52312C17.8936 8.39105 17.7198 7.19973 17.1404 6.19114C16.1203 4.50371 13.7894 4.01093 13.7894 4.01093Z"
                    className="fill-[#F8E1A4] if:fill-[#FFDE7C]"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.2247 7.70852L15.2023 6.28379"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.523 9.24284L19.6064 7.26575"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <circle
                    cx="17.5378"
                    cy="18"
                    r="5.72"
                    className="fill-[#DC6B31] if:fill-[#DD3D96]"
                    stroke="#2D2D2D"
                    strokeWidth="0.56"
                />
                <path
                    d="M17.5693 19.1089L16.3924 21H14.5378L16.5845 17.829L14.7492 15H16.5149L17.5517 16.665L18.5876 15H20.3709L18.4995 17.8282L20.5378 20.9992H18.7453L17.5685 19.1081L17.5693 19.1089Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_759_23846">
                    <rect width="23" height="24" fill="white" transform="translate(0.537842)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default GlutenFreeIcon;

GlutenFreeIcon.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number.isRequired,
};

GlutenFreeIcon.defaultProps = {
    className: null,
};
