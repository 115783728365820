import { useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router';

import useUpSellsCarouselQuery from 'api/queries/useUpSellsCarouselQuery';
import CartProductCard from 'features/shop/cart/CartProductCard';
import Carousel from 'honeydew/Carousel';
import useGlobalStore from 'hooks/useGlobalStore';
import useTailwindTheme from 'hooks/useTailwindTheme';
import { capitalizeWords } from 'utils/globalUtils';

function UpSellsCarousel({ name, pdpId, position, setUpsellsVisible, type }) {
    const navigate = useNavigate();
    const { addCounter, productAddedID, setAddCounter, setCartOpen } = useGlobalStore();
    const tailwindTheme = useTailwindTheme();

    const mobile = useMediaQuery({ maxWidth: tailwindTheme?.screens?.md });
    const phone = useMediaQuery({ maxWidth: 575 });
    const tablet = useMediaQuery({ maxWidth: 910 });
    const desktop = useMediaQuery({ maxWidth: 1440 });
    const largeDesktop = useMediaQuery({ maxWidth: 1920 });

    const getSellableItemID = () => {
        if (position === 'inline') {
            return pdpId;
        }
        if (position === 'fixed' || position === 'sheet') {
            return productAddedID;
        }
        return null;
    };

    const upsellsCarouselQuery = useUpSellsCarouselQuery({
        position,
        sellableItemID: getSellableItemID(),
        type,
    });
    const { data: carousel } = upsellsCarouselQuery;
    const { dsSource, items } = carousel?.results ?? {};

    const checkDismissCounter =
        localStorage.getItem('UPSELL_CAROUSEL_DISMISSED') === 'true' ? addCounter > 5 : addCounter > 0;
    const canShowCarousel =
        (((position === 'fixed' || position === 'sheet') && checkDismissCounter) ||
            position === 'inline' ||
            position === 'cart') &&
        items?.length > 0;

    const [showCarousel, setShowCarousel] = useState(canShowCarousel);

    useEffect(() => {
        setShowCarousel(canShowCarousel);
    }, [canShowCarousel]);

    const navigateToPDP = ({ productId }) => {
        if (window.isNativeApp) {
            window.ReactNativeWebView?.postMessage(JSON.stringify({ navigate: 'PDP', pdpId: productId }));
        } else {
            setCartOpen(false);
            navigate(`/shop/product/${productId}`);
        }
    };

    useEffect(() => {
        const carouselDismissed = localStorage.getItem('UPSELL_CAROUSEL_DISMISSED') === 'true' ?? false;
        if (((carouselDismissed && addCounter > 5) || !carouselDismissed) && addCounter > 0) {
            setShowCarousel(true);
            localStorage.setItem('UPSELL_CAROUSEL_DISMISSED', false);
        }
    }, [addCounter]);

    useEffect(() => {
        if ((position === 'sheet' || position === 'fixed') && upsellsCarouselQuery.refetch) {
            upsellsCarouselQuery.refetch();
        }
    }, [productAddedID]);

    const handleCarouselClose = () => {
        setShowCarousel(false);
        setAddCounter(0);
        localStorage.setItem('UPSELL_CAROUSEL_DISMISSED', true);
    };

    useEffect(() => {
        setUpsellsVisible(showCarousel);
    }, [showCarousel]);

    if (!showCarousel) return null;

    const carouselTitle = type.split('_').join(' ');

    const carouselData = {
        id: type,
        name: name || capitalizeWords(carouselTitle),
        items,
        featured: false,
    };

    const getUpsellsItemSource = () => {
        if (position === 'sheet' || position === 'fixed') {
            return 'plp_upsell';
        }
        if (position === 'cart') {
            return 'cart_upsell';
        }
        if (position === 'inline') {
            return 'pdp_upsell';
        }
        return 'default_upsell';
    };

    const getSlideOverride = () => {
        if (position === 'sheet') {
            if (phone) {
                return 1.08;
            }
            return 2.25;
        }
        if (!mobile && (position === 'fixed' || position === 'inline')) {
            if (tablet) {
                return 2.25;
            }
            if (desktop) {
                return 3.25;
            }
            if (largeDesktop) {
                return 4.25;
            }
            return 5.25;
        }
        return 1.08;
    };

    const carouselProductCards = items?.map((item) => (
        <div
            className={clsx('flex w-full items-center justify-center rounded-md bg-white', {
                'p-10': mobile,
            })}
            key={item.id}
        >
            <CartProductCard
                onClick={() => navigateToPDP({ productId: item.id })}
                product={item}
                upSellsItem={getUpsellsItemSource()}
                dsSource={dsSource}
                position={position}
            />
        </div>
    ));

    return (
        <div
            className={clsx({
                'fixed bottom-[-20px] left-0 z-[12] w-full': position === 'fixed',
                'relative mt-5': position === 'sheet',
                '[&>div>div]:mb-5': position === 'inline',
                'shadow-base': !mobile && position === 'fixed',
            })}
            data-testid={`upsells-${position}`}
        >
            {((!mobile && position === 'fixed') || position === 'sheet') && (
                <button
                    className={clsx('bg-malt-off-white absolute z-[11] rounded-full p-[2px]', {
                        'right-5 top-5': position === 'fixed',
                        'right-10 top-10': position === 'sheet',
                    })}
                    onClick={handleCarouselClose}
                    type="button"
                    aria-label="close"
                >
                    <XMarkIcon className="h-25 w-25" />
                </button>
            )}
            {carouselProductCards?.length > 0 && (
                <Carousel bgColor="brand" carousel={carouselData} upSellsCarousel slideOverride={getSlideOverride()}>
                    {carouselProductCards}
                </Carousel>
            )}
        </div>
    );
}

UpSellsCarousel.propTypes = {
    name: PropTypes.string,
    pdpId: PropTypes.number,
    position: PropTypes.oneOf(['cart', 'fixed', 'inline', 'sheet']),
    setUpsellsVisible: PropTypes.func,
    type: PropTypes.oneOf(['reach_order_minimum', 'reach_free_shipping_threshold', 'top_sellers', 'empty_cart']),
};

UpSellsCarousel.defaultProps = {
    name: '',
    pdpId: null,
    position: 'inline',
    setUpsellsVisible: () => {},
    type: 'top_sellers',
};

export default UpSellsCarousel;
