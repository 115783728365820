import PropTypes from 'prop-types';

function NeverIcon({ brandColors, width }) {
    return (
        <svg width={width} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1_7331_830" fill="white">
                <path d="M10.3233 19.8316C15.8063 18.5457 21.1764 15.4423 21.1764 10.0001C21.1764 4.55784 16.5963 1.23933 10.3233 0.123037C4.7721 -0.866694 1.12903 4.25662 0.102781 9.97731C-0.857854 15.3411 5.09231 21.0593 10.3207 19.8316" />
            </mask>
            <path
                d="M10.3233 0.123037L10.0702 1.54244L10.0707 1.54252L10.3233 0.123037ZM0.102781 9.97731L-1.31635 9.72273L-1.31642 9.72314L0.102781 9.97731ZM10.6525 21.2353C13.5188 20.5631 16.4539 19.3964 18.7048 17.6018C20.9845 15.7842 22.6182 13.2648 22.6182 10.0001H19.7346C19.7346 12.1776 18.6832 13.9311 16.9071 15.3472C15.1022 16.7862 12.6107 17.8142 9.9941 18.4279L10.6525 21.2353ZM22.6182 10.0001C22.6182 6.821 21.2599 4.24225 19.0392 2.34349C16.8553 0.476264 13.8751 -0.709349 10.5759 -1.29645L10.0707 1.54252C13.0445 2.07172 15.4908 3.10351 17.1653 4.53517C18.8028 5.9353 19.7346 7.73693 19.7346 10.0001H22.6182ZM10.5764 -1.29637C7.23857 -1.89147 4.46642 -0.615318 2.4581 1.55438C0.489253 3.68143 -0.769046 6.67186 -1.31635 9.72273L1.52191 10.2319C2.00086 7.56207 3.07722 5.1305 4.57427 3.51315C6.03185 1.93845 7.85684 1.14781 10.0702 1.54244L10.5764 -1.29637ZM-1.31642 9.72314C-1.9048 13.0084 -0.370103 16.2372 1.89066 18.4147C4.14929 20.5901 7.42267 21.9931 10.6503 21.2352L9.9911 18.428C7.99032 18.8978 5.67443 18.0555 3.89104 16.3378C2.10979 14.6222 1.14972 12.31 1.52199 10.2315L-1.31642 9.72314Z"
                className={brandColors ? 'fill-kale if:fill-beet' : 'fill-black'}
                mask="url(#path-1-inside-1_7331_830)"
            />
            <path
                d="M14.3979 6.52344L6.60653 14.3148"
                className={brandColors ? 'stroke-kale if:stroke-beet' : 'stroke-black'}
                strokeWidth="1.44179"
                strokeLinecap="round"
            />
            <path
                d="M6.60648 6.27222L14.3978 14.0636"
                className={brandColors ? 'stroke-kale if:stroke-beet' : 'stroke-black'}
                strokeWidth="1.44179"
                strokeLinecap="round"
            />
        </svg>
    );
}

NeverIcon.propTypes = {
    brandColors: PropTypes.bool,
    width: PropTypes.number,
};

NeverIcon.defaultProps = {
    brandColors: false,
    width: 22,
};

export default NeverIcon;
