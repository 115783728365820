import PropTypes from 'prop-types';

function PausePlanIcon({ width }) {
    return (
        <svg width={width} viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4539_158716)">
                <mask
                    id="mask0_4539_158716"
                    style={{ maskType: 'luminance' }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="60"
                    height="61"
                >
                    <path d="M60 0.642578H0V60.1126H60V0.642578Z" fill="white" />
                </mask>
                <g mask="url(#mask0_4539_158716)">
                    <path
                        d="M35.11 58.3626C49.64 51.3926 62.75 39.1926 59.5 23.2326C56.24 7.28263 41.49 0.262627 23.32 0.662627C7.24999 1.00263 0.169989 18.1326 0.689989 35.4826C1.21999 51.7526 21.24 65.0126 35.09 58.3826"
                        className="fill-mango if:fill-[#FFCBDC]"
                    />
                    <path
                        d="M9.81998 17.3729C9.81998 17.3729 16.56 14.1229 19.71 12.9429C22.01 12.0829 28.65 8.92285 28.65 8.92285C28.65 8.92285 35.13 12.0529 38.65 13.1429C41.24 13.9429 48.65 17.3629 48.65 17.3629C48.65 17.3629 42.34 20.1129 38.65 21.1929C36.09 21.9529 28.65 25.0229 28.65 25.0229L18.13 20.7629L9.81998 17.3529V17.3729Z"
                        fill="white"
                        stroke="#2D2D2D"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M9.82 17.3726C9.82 17.3726 10.21 24.2926 9.82 28.1526C9.46 31.6926 9.82 42.4026 9.82 42.4026L28.64 50.0326V25.0626L9.82 17.3826V17.3726Z"
                        fill="white"
                        stroke="#2D2D2D"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M48.63 17.3728C48.63 17.3728 48.77 26.3728 48.63 29.3628C48.43 33.6928 48.63 42.0128 48.63 42.0128C48.63 42.0128 43.79 43.7128 40.57 45.2428C37.67 46.6128 28.65 50.0328 28.65 50.0328C28.65 50.0328 28.77 41.0228 28.65 37.9928C28.46 32.9728 28.65 25.0328 28.65 25.0328L48.63 17.3628V17.3728Z"
                        className="fill-[#F2D79A] if:fill-oat"
                        stroke="#2D2D2D"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <path
                    d="M14.27 49.1327C20.2347 49.1327 25.07 44.2974 25.07 38.3327C25.07 32.368 20.2347 27.5327 14.27 27.5327C8.30533 27.5327 3.47 32.368 3.47 38.3327C3.47 44.2974 8.30533 49.1327 14.27 49.1327Z"
                    className="fill-[#B0C372] if:fill-[#C5DB66]"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M11.45 34.7324V42.3924" stroke="#2D2D2D" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M16.54 34.7324V42.3924" stroke="#2D2D2D" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_4539_158716">
                    <rect width="60" height="59.49" fill="white" transform="translate(0 0.642578)" />
                </clipPath>
            </defs>
        </svg>
    );
}

PausePlanIcon.propTypes = {
    width: PropTypes.number,
};

PausePlanIcon.defaultProps = {
    width: 60,
};

export default PausePlanIcon;
