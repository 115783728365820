import PropTypes from 'prop-types';

function MMFallFlavorsInactive({ className, width }) {
    return (
        <svg width={width} className={className} viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.44547 10.0878C9.44547 10.0878 9.11308 5.6612 10.2633 3.52968C11.4161 1.4008 13.1492 1.19768 13.5265 1.02357C13.9486 0.828355 14.9563 1.90467 14.8059 2.08669C14.6556 2.26871 12.4554 3.42944 13.0833 5.76145C13.5766 7.59487 15.1251 7.521 15.1251 7.521L14.0409 8.59995L11.8091 8.97191L10.506 10.4598L9.44283 10.0852L9.44547 10.0878Z"
                fill="#FFF9EA"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M6.20596 11.7467C6.20596 11.7467 5.15867 11.3747 4.04279 11.8654C2.92691 12.3561 -0.594844 15.6852 1.84004 22.2434C4.27493 28.8015 8.24251 29.891 10.6246 28.4242C11.7775 28.8358 14.8296 28.6432 15.2333 27.0498C16.8266 27.865 18.3857 27.2477 18.8948 25.9577C20.1373 26.6673 22.0235 26.1925 22.9996 24.7363C24.4953 25.3694 26.7719 23.4859 27.5924 21.8476C28.3468 20.3413 29.5577 14.907 26.0491 10.5121C21.765 5.14373 18.7286 7.39923 18.5018 7.56015C18.0137 7.53641 15.2544 6.58409 14.0356 8.72088C12.0413 8.53094 11.3079 9.17726 10.6906 10.4118C9.91501 10.1164 7.61466 9.34609 6.2086 11.7467H6.20596Z"
                fill="#FFF9EA"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.20597 11.7462C6.20597 11.7462 5.05052 13.6614 5.33015 16.1411"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.6221 28.4223C10.6221 28.4223 7.56459 27.6203 5.49902 21.1519"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.688 10.4126C10.688 10.4126 9.35318 12.829 11.6773 17.7964"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.2332 27.0497C15.2332 27.0497 13.3945 25.9681 11.9225 22.9318"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.8948 25.9581C18.8948 25.9581 20.2797 23.4177 19.1691 17.4188"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.033 8.72195C14.033 8.72195 16.3597 9.07017 17.6101 12.1909"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.4991 7.55997C18.4991 7.55997 22.9284 9.73633 24.0812 15.2181"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.9969 24.7361C22.9969 24.7361 23.9123 23.6572 24.3714 20.1169"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default MMFallFlavorsInactive;

MMFallFlavorsInactive.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number.isRequired,
};

MMFallFlavorsInactive.defaultProps = {
    className: null,
};
