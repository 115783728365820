import clsx from 'clsx';
import PropTypes from 'prop-types';

function Card({ bgColor, children, extendedStyles, flush, marginClasses, section, topGradientBorder, ...props }) {
    // Allow custom attributes on div, except className
    // eslint-disable-next-line no-unused-vars
    const { className, ...cardProps } = props; // eslint-disable-line react/prop-types
    const TagName = section ? 'section' : 'div';

    return (
        <TagName
            className={clsx(
                ['relative rounded-lg', bgColor, marginClasses, extendedStyles?.padding ?? { 'p-20 lg:p-30': !flush }],
                {
                    'overflow-hidden': topGradientBorder,
                }
            )}
            {...cardProps}
        >
            {topGradientBorder && <div className="absolute left-0 top-0 h-[8px] w-full bg-gradient-berry-blend" />}
            {children}
        </TagName>
    );
}

Card.propTypes = {
    bgColor: PropTypes.string,
    children: PropTypes.node.isRequired,
    extendedStyles: PropTypes.shape({
        padding: PropTypes.string,
    }),
    flush: PropTypes.bool,
    marginClasses: PropTypes.string,
    section: PropTypes.bool,
    topGradientBorder: PropTypes.bool,
};

Card.defaultProps = {
    bgColor: 'bg-white',
    extendedStyles: {},
    flush: false,
    marginClasses: '',
    section: false,
    topGradientBorder: false,
};

export default Card;
