import PropTypes from 'prop-types';

function HelpIcon({ width }) {
    return (
        <svg width={width} viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5316_23611)">
                <path
                    d="M29.5177 48.5828C41.7648 42.7848 52.8205 32.6019 50.0774 19.3365C47.3342 6.07104 34.8931 0.210692 19.598 0.536267C6.0555 0.827206 0.0704571 15.0832 0.548429 29.5332C0.991765 43.0688 17.8662 54.1176 29.5385 48.5828"
                    className="fill-[#AFC372] if:fill-[#E95CA2]"
                />
                <path
                    d="M40.1438 41.5588C41.4467 41.3878 42.6434 40.7503 43.5121 39.7645C44.3807 38.7786 44.8626 37.5112 44.8681 36.1972C44.8681 36.1972 44.9721 30.7109 44.8681 27.7184C44.7642 24.7259 44.8681 21.9758 44.8681 21.9758C44.8645 20.5439 44.2941 19.1717 43.2816 18.1592C42.2691 17.1467 40.8969 16.5763 39.465 16.5726H19.598C18.1661 16.5763 16.7939 17.1467 15.7814 18.1592C14.7689 19.1717 14.1985 20.5439 14.1948 21.9758V36.1972C14.1985 37.6291 14.7689 39.0013 15.7814 40.0138C16.7939 41.0263 18.1661 41.5967 19.598 41.6003C19.598 41.6003 23.0615 41.6904 24.1699 41.6003C26.1854 41.4557 28.2087 41.4557 30.2242 41.6003L37.844 47.142C38.0206 47.2713 38.2295 47.3491 38.4476 47.3668C38.6657 47.3846 38.8845 47.3416 39.0796 47.2426C39.2748 47.1436 39.4387 46.9924 39.5532 46.806C39.6676 46.6195 39.7282 46.4049 39.7282 46.1861V41.6142L40.1438 41.5588Z"
                    fill="#F2D79A"
                    className="fill-[#F2D79A] if:fill-oat"
                    stroke="#2D2D2D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.5464 36.204V40.7759C12.5473 40.9936 12.6081 41.2068 12.7223 41.3922C12.8364 41.5775 12.9994 41.7278 13.1934 41.8266C13.3873 41.9253 13.6048 41.9688 13.8218 41.9521C14.0388 41.9354 14.247 41.8592 14.4236 41.7319L22.0434 36.1902C22.0434 36.1902 26.1443 36.301 27.502 36.1902C29.2212 36.0376 30.9505 36.0376 32.6697 36.1902C34.1015 36.1865 35.4737 35.6161 36.4862 34.6036C37.4987 33.5911 38.0692 32.2189 38.0728 30.787C38.0728 30.787 37.7957 28.979 38.0728 23.0841C38.149 21.4562 38.0728 16.5656 38.0728 16.5656C38.0692 15.1338 37.4987 13.7616 36.4862 12.7491C35.4737 11.7366 34.1015 11.1661 32.6697 11.1625C32.6697 11.1625 24.8351 11.398 19.1548 11.1625C17.5685 11.1001 12.8027 11.1625 12.8027 11.1625C11.3708 11.1661 9.99859 11.7366 8.98609 12.7491C7.97359 13.7616 7.40316 15.1338 7.3995 16.5656C7.24802 18.4098 7.24802 20.2632 7.3995 22.1073C7.60039 24.2617 7.3995 30.7593 7.3995 30.7593C7.40377 32.1175 7.9181 33.4245 8.84056 34.4214C9.76303 35.4183 11.0263 36.0323 12.3801 36.1417L12.5464 36.204Z"
                    fill="white"
                    stroke="#2D2D2D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M22.3968 26.326C21.8486 26.2901 21.2995 26.3777 20.7897 26.5823C20.7562 26.3807 20.7377 26.177 20.7343 25.9727C20.7114 25.798 20.6975 25.6223 20.6927 25.4462C20.6927 23.7075 21.8634 23.1603 22.9024 22.7239C23.8099 22.3706 24.6204 22.0312 24.6204 21.1376C24.6204 20.244 24.0385 19.7521 22.7708 19.7521C21.3161 19.7521 20.7689 20.4448 20.7689 21.6017V21.9965H17.2568V21.4146C17.2568 18.4291 19.1272 16.8081 22.7985 16.8081C26.4699 16.8081 28.1463 18.7477 28.1463 20.9644C28.2048 21.6686 28.034 22.3728 27.6595 22.9721C27.285 23.5713 26.7268 24.0334 26.0681 24.2894C25.0983 24.802 24.1216 25.0652 23.99 26.0281L23.9346 26.5961C23.4475 26.396 22.9229 26.3039 22.3968 26.326ZM20.3186 28.9721C20.3186 27.5867 20.9005 27.1572 22.3968 27.1572C23.893 27.1572 24.4749 27.5729 24.4749 28.9721C24.4749 30.3714 23.9069 30.787 22.3968 30.787C20.8867 30.787 20.3186 30.3714 20.3186 28.9721Z"
                    fill="#2D2D2D"
                />
            </g>
            <defs>
                <clipPath id="clip0_5316_23611">
                    <rect width="50" height="49.5151" fill="white" transform="translate(0.5 0.522461)" />
                </clipPath>
            </defs>
        </svg>
    );
}

HelpIcon.propTypes = {
    width: PropTypes.number,
};

HelpIcon.defaultProps = {
    width: 51,
};

export default HelpIcon;
