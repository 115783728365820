export const mmLinks = {
    appStore: 'https://apps.apple.com/us/app/misfits-market-groceries/id1564147180',
    accountHelp: 'https://help.misfitsmarket.com/en_us/categories/my-account-ryEdE9jQC',
    authenticationPage: 'https://www.misfitsmarket.com/login',
    customerCare: 'https://help.misfitsmarket.com/contact/contact-us-Sy0sjXCuh',
    helpCenter: 'https://help.misfitsmarket.com/',
    faqs: 'https://www.misfitsmarket.com/faq',
    googlePlayStore: 'https://play.google.com/store/apps/details?id=com.misfitsmarket&hl=en_US&gl=US&pli=1',
    privacyPolicy: 'https://www.misfitsmarket.com/privacy',
    referralProgram: 'https://www.misfitsmarket.com/referral-program',
    terms: 'https://www.misfitsmarket.com/terms-of-service',
};

export const ifLinks = {
    appStore: 'https://apps.apple.com/us/app/imperfect-foods/id1577380836',
    accountHelp: 'https://help.imperfectfoods.com/en_us/categories/my-account-SyrMTJWOj',
    authenticationPage: 'https://www.imperfectfoods.com/login',
    customerCare: 'https://www.imperfectfoods.com/contact',
    helpCenter: 'https://help.imperfectfoods.com/',
    faqs: 'https://help.imperfectfoods.com/en_us/categories/frequently-asked-questions-S1_ayZus',
    googlePlayStore: 'https://play.google.com/store/apps/details?id=com.imperfectfoods',
    privacyPolicy: 'https://www.imperfectfoods.com/privacy',
    referralProgram: 'https://www.imperfectfoods.com/referral-program',
    terms: 'https://www.imperfectfoods.com/terms-and-conditions',
};
