import PropTypes from 'prop-types';

function CertifiedKosherIcon({ className, width }) {
    return (
        <svg
            width={width}
            height={width}
            className={className}
            viewBox="0 0 14 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.10515 1.14942L4.24982 1.11023C4.28217 1.10323 4.31311 1.12982 4.3117 1.16201L4.17247 8.50126C4.17106 8.55165 4.23576 8.57404 4.26529 8.53345L9.23263 1.02066C9.2481 0.999663 9.27763 0.994065 9.30013 1.00666L11.8935 2.50138C11.9202 2.51678 11.9273 2.55177 11.909 2.57696L6.67583 10.616C6.66317 10.6342 6.66176 10.658 6.67583 10.6762L13.3435 19.4891C13.3674 19.5241 13.3435 19.5717 13.2999 19.5703C12.5165 19.5507 9.83456 19.6445 9.00198 19.6739C8.85572 19.6795 8.7193 19.6053 8.64476 19.4779C7.65326 17.7887 5.26945 15.0848 4.35671 13.1128C4.3328 13.061 4.25685 13.0806 4.25826 13.138C4.37077 16.2674 4.65908 17.9398 4.69424 19.7677C4.69424 19.7956 4.67314 19.8194 4.64501 19.8208L1.38362 19.9706C1.35549 19.9706 1.33018 19.951 1.33018 19.9216C1.26689 17.9958 1.25282 14.6453 1.18672 12.7447C1.15438 11.8098 0.840755 1.2026 1.10515 1.14942Z"
                className="fill-[#DC6B31] if:fill-[#E95CA2]"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default CertifiedKosherIcon;

CertifiedKosherIcon.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number.isRequired,
};

CertifiedKosherIcon.defaultProps = {
    className: null,
};
