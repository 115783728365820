import PropTypes from 'prop-types';

import MMColdPackActive from './MMColdPackActive';
import MMColdPackInactive from './MMColdPackInactive';

function MMColdPack({ className, isActive, width }) {
    return isActive ? (
        <MMColdPackActive className={className} width={width} />
    ) : (
        <MMColdPackInactive className={className} width={width} />
    );
}

export default MMColdPack;

MMColdPack.propTypes = {
    isActive: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

MMColdPack.defaultProps = {
    isActive: true,
    width: 28,
};
