import { useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';

import useReferralCreditsQuery from 'api/queries/useReferralCreditsQuery';
import { getIsBoostedReferral } from 'contexts/PromoCodeContext/utils';
import useAuth from 'hooks/useAuth';

// returns true if there is a valid live boosted referrals campaign
export default function useValidReferralsCampaign() {
    const { authStatus } = useAuth();
    const [showNanoBanner, setShowNanoBanner] = useState(false);
    const isShopRoute = !!useMatch('/shop/*');
    const isSignupRoute = !!useMatch('/join/*');
    const isCartPage = !!useMatch('/cart');

    const { data: referrals = {}, isLoading: isReferralsLoading } = useReferralCreditsQuery();
    const { newCustValue, referrerValue } = referrals?.referralRewards ?? {};
    const boosted = getIsBoostedReferral({ newCustValue, referrerValue });

    useEffect(() => {
        setShowNanoBanner(
            authStatus === 'authenticated' &&
                !isShopRoute &&
                !isSignupRoute &&
                !isCartPage &&
                boosted &&
                !isReferralsLoading
        );
    }, [authStatus, isShopRoute, isSignupRoute, boosted, isReferralsLoading]);

    return showNanoBanner;
}
