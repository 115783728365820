import PropTypes from 'prop-types';

function ActivateMembershipPricing({ annualPlusMembershipCost, availableCredits, availableTrialMembership }) {
    if (availableTrialMembership) {
        return (
            <div className="justify-between gap-10 md:flex">
                <span className="text-plus-membership font-grotesk-bold">
                    Free {availableTrialMembership?.trialDurationDays}-day trial
                </span>
                <p>Then ${annualPlusMembershipCost}/year</p>
            </div>
        );
    }

    if (availableCredits?.credits > 0) {
        return (
            <div>
                <p className="text-plus-membership font-grotesk-bold">
                    Get ${availableCredits.credits} when you join, use within {availableCredits.creditsExpirationDays}{' '}
                    days
                </p>
                <p>
                    <span className="font-grotesk-bold">${annualPlusMembershipCost} per year</span> | Billed annually
                </p>
            </div>
        );
    }

    return (
        <div className="justify-between gap-10 md:flex">
            <p>
                <span className="font-grotesk-bold">${annualPlusMembershipCost} per year</span> | Billed annually
            </p>
            <p className="ml-auto text-grey">
                That&apos;s just ${(annualPlusMembershipCost / 12)?.toFixed(2)} per month!
            </p>
        </div>
    );
}

ActivateMembershipPricing.propTypes = {
    annualPlusMembershipCost: PropTypes.number.isRequired,
    availableCredits: PropTypes.shape({
        credits: PropTypes.number.isRequired,
        creditsExpirationDays: PropTypes.number.isRequired,
    }),
    availableTrialMembership: PropTypes.shape({
        trialDurationDays: PropTypes.number,
    }),
};

ActivateMembershipPricing.defaultProps = {
    availableCredits: undefined,
    availableTrialMembership: undefined,
};

export { ActivateMembershipPricing };
