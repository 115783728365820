import PropTypes from 'prop-types';

import { IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN } from 'constants/theme';

export function ShopBy({ brand, isActive, width }) {
    const boxColor = brand === MISFITS_THEME_DOMAIN ? '#F2EBD1' : '#ECE7E4';
    const checkedColor = brand === MISFITS_THEME_DOMAIN ? '#B0C472' : '#C5DB66';
    const activeColor = brand === MISFITS_THEME_DOMAIN ? '#D7E1B8' : '#F0D3E3';

    return isActive ? (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.6505 28.7652C21.902 26.9646 29 22.6358 29 14.9992C29 7.3625 22.9452 2.7363 14.6505 1.1728C7.31233 -0.213857 2.48978 6.96057 1.13553 14.967C-0.132106 22.475 7.73356 30.4815 14.6505 28.7652Z"
                fill={activeColor}
            />
            <path
                d="M12.2932 4.8175H6.73649C5.78142 4.8175 5 5.59892 5 6.56364V12.1783C5 13.143 5.77177 13.9244 6.73649 13.9244H12.2932C13.2483 13.9244 14.0297 13.143 14.0297 12.1783V6.56364C14.0297 5.59892 13.258 4.8175 12.2932 4.8175Z"
                fill={boxColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.8958 4.8175H17.339C16.384 4.8175 15.6025 5.59892 15.6025 6.56364V12.1783C15.6025 13.143 16.3743 13.9244 17.339 13.9244H22.8958C23.8508 13.9244 24.6323 13.143 24.6323 12.1783V6.56364C24.6323 5.59892 23.8605 4.8175 22.8958 4.8175Z"
                fill={checkedColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.2932 15.574H6.73649C5.78142 15.574 5 16.3554 5 17.3298V22.9444C5 23.9091 5.77177 24.7002 6.73649 24.7002H12.2932C13.2483 24.7002 14.0297 23.9188 14.0297 22.9444V17.3298C14.0297 16.365 13.258 15.574 12.2932 15.574Z"
                fill={boxColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.8958 15.574H17.339C16.384 15.574 15.6025 16.3554 15.6025 17.3298V22.9444C15.6025 23.9091 16.3743 24.7002 17.339 24.7002H22.8958C23.8508 24.7002 24.6323 23.9188 24.6323 22.9444V17.3298C24.6323 16.365 23.8605 15.574 22.8958 15.574Z"
                fill={boxColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.8018 19.6064C20.8018 19.6064 21.1298 26.2533 21.5253 26.4077C21.9208 26.562 23.4451 24.4686 23.7924 24.9124C24.13 25.3658 25.1333 27.5267 25.5674 27.3531C26.1463 27.1312 26.6383 26.7357 26.9856 26.2147C27.1978 25.7999 24.7474 24.1599 25.1237 23.7065C25.6736 23.0601 27.603 22.5392 26.8119 22.134C26.0209 21.7192 20.8018 19.6064 20.8018 19.6064Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26.002 2.93621L20.8215 11.4739C20.7732 11.5608 20.7153 11.6283 20.6285 11.6862C20.4259 11.7923 20.1944 11.609 20.04 11.445L17.7344 8.96568C17.7344 8.96568 18.0141 7.98167 18.3036 8.09744C18.593 8.2132 20.1462 10.1233 20.2619 9.87251C20.3777 9.62168 24.661 1.93291 25.0083 2.00044C25.3556 2.06797 25.9827 2.94586 25.9827 2.94586L26.002 2.93621Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ) : (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.2932 5H6.73649C5.77177 5 5 5.78142 5 6.74613V12.3608C5 13.3255 5.77177 14.1069 6.73649 14.1069H12.2932C13.2483 14.1069 14.0297 13.3255 14.0297 12.3608V6.74613C14.0297 5.78142 13.258 5 12.2932 5Z"
                fill={boxColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.8958 5H17.339C16.384 5 15.6025 5.78142 15.6025 6.74613V12.3608C15.6025 13.3255 16.3743 14.1069 17.339 14.1069H22.8958C23.8508 14.1069 24.6323 13.3255 24.6323 12.3608V6.74613C24.6323 5.78142 23.8605 5 22.8958 5Z"
                fill={boxColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.2932 15.7566H6.73649C5.78142 15.7566 5 16.538 5 17.5124V23.127C5 24.0917 5.77177 24.8828 6.73649 24.8828H12.2932C13.2483 24.8828 14.0297 24.1014 14.0297 23.127V17.5124C14.0297 16.5477 13.258 15.7566 12.2932 15.7566Z"
                fill={boxColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.8958 15.7566H17.339C16.384 15.7566 15.6025 16.538 15.6025 17.5124V23.127C15.6025 24.0917 16.3743 24.8828 17.339 24.8828H22.8958C23.8508 24.8828 24.6323 24.1014 24.6323 23.127V17.5124C24.6323 16.5477 23.8605 15.7566 22.8958 15.7566Z"
                fill={boxColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.8018 10.7208C20.8018 10.7208 21.1298 17.3677 21.5253 17.5221C21.9208 17.6764 23.4451 15.583 23.7924 16.0268C24.13 16.4802 25.1333 18.6411 25.5674 18.4675C26.1463 18.2456 26.6383 17.8501 26.9856 17.3291C27.1978 16.9143 24.7474 15.2743 25.1237 14.8209C25.6736 14.1745 27.603 13.6536 26.8119 13.2484C26.0209 12.8335 20.8018 10.7208 20.8018 10.7208Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

ShopBy.propTypes = {
    brand: PropTypes.oneOf([IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN]).isRequired,
    isActive: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ShopBy.defaultProps = {
    isActive: false,
    width: 30,
};
