import PropTypes from 'prop-types';

export function SocialImpactIcon({ width }) {
    return (
        <svg width={width} height={width} viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3875_39552)">
                <path
                    d="M70.5 35.4194C70.5 16.0895 54.83 0.419434 35.5 0.419434C16.17 0.419434 0.5 16.0895 0.5 35.4194C0.5 54.7494 16.17 70.4194 35.5 70.4194C54.83 70.4194 70.5 54.7494 70.5 35.4194Z"
                    fill="url(#paint0_linear_3875_39552)"
                />
                <path
                    d="M53.1389 24.975C56.2978 29.6363 56.6455 35.3744 54.9099 40.5459C53.2231 45.5696 49.7406 49.9947 44.9559 53.0579C39.6086 56.4818 33.0367 56.8465 27.6456 54.7423C22.9006 52.8906 19.773 51.1747 16.6524 45.674C15.2738 43.2438 14.4993 40.6382 14.1767 38.0173C13.6335 33.6003 14.8078 30.608 16.3536 26.4056C17.7315 22.6596 21.193 19.9568 24.8353 17.591C29.0771 14.8358 34.1535 14.2922 38.7369 15.0577C44.5969 16.0358 49.4656 19.5556 53.1389 24.975Z"
                    fill="white"
                    stroke="#2D2D2D"
                    strokeWidth="1.09383"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M46.7303 27.9446C47.8217 30.1669 48.7211 32.4456 48.5473 34.7499C48.2003 39.3507 45.8862 44.0736 41.4849 46.9421C38.4542 48.9183 34.6779 49.5419 31.5199 48.4104C27.8755 47.1043 24.499 45.2443 22.4866 41.6971C20.4454 38.0991 20.4992 34.0398 21.4094 30.3459C22.2224 27.0493 24.6348 24.4556 27.7315 22.6987C31.4595 20.5839 35.7116 20.1275 39.4733 21.7605C42.3763 23.022 45.2103 24.8488 46.7303 27.9446Z"
                    stroke="#2D2D2D"
                    strokeWidth="1.09383"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M28.9787 47.3321C27.003 44.9637 22.6981 40.2908 22.8658 35.698C23.0334 31.1052 24.0036 29.2151 26.3843 27.0034C28.7651 24.7917 31.1371 23.2731 34.9021 22.7801C38.53 22.3053 40.9602 22.5099 40.9602 22.5099L37.233 21.0924C37.233 21.0924 32.0185 20.1059 28.7458 22.1342C25.473 24.1626 23.4504 24.8266 22.0361 28.6967C20.6219 32.567 20.6492 37.3489 21.5927 39.465C22.5362 41.5812 23.0818 42.5966 24.3905 43.9673C25.699 45.3381 28.9787 47.3321 28.9787 47.3321Z"
                    fill="#2D2D2D"
                />
                <path
                    d="M37.0115 46.7105C36.9683 45.572 36.8774 44.3195 36.0684 43.507C35.2359 42.6709 33.8564 42.5395 33.0679 41.6628C32.5118 41.0441 32.3607 40.1759 32.2535 39.3555C31.8866 36.5491 31.7578 32.2218 31.8888 29.3969C31.9116 28.908 31.9772 28.3799 32.3278 28.0351C32.7057 27.6636 33.6219 27.472 33.7484 28.2818C33.9 29.2518 34.0361 32.2708 34.0891 33.5051C34.1343 34.5432 34.3754 38.0188 35.2625 37.9967C36.508 37.9494 35.0758 30.811 35.4232 28.3078C35.8138 27.0624 37.2591 27.3906 37.2113 28.704C37.1637 30.0086 37.1529 37.7969 38.7568 37.724C39.8459 37.5623 38.8783 31.3553 38.8982 29.6954C38.9027 29.3092 38.8415 27.9758 39.4915 27.7847C40.0718 27.6141 40.8799 27.9366 40.9206 28.7315C40.9507 29.3304 41.2313 31.7808 41.2667 32.3801C41.5016 36.3572 42.2742 39.3818 39.8422 43.2503C38.8727 44.7921 41.3929 54.8622 41.6502 59.4932C41.7584 61.4373 42.0822 63.1566 40.3064 63.1998C38.5305 63.2431 38.0792 61.2398 37.9127 59.6353C37.4895 55.5699 37.066 48.1573 37.0115 46.7105Z"
                    fill="white"
                    stroke="#2D2D2D"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M44.731 34.112C44.5356 35.9773 44.2904 37.1354 47.2284 38.1987C46.849 39.3606 44.5965 53.0855 44.508 54.6722C44.3757 57.051 46.8976 56.9285 47.2114 55.4421C48.3185 50.1881 49.8913 40.0691 50.5137 34.9105C51.2651 28.6834 53.7373 15.6544 52.3688 15.9029C47.5405 16.7815 45.179 29.8255 44.731 34.112Z"
                    fill="white"
                    stroke="#2D2D2D"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_3875_39552"
                    x1="-0.499954"
                    y1="2.91937"
                    x2="72.0001"
                    y2="61.4194"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F594A4" />
                    <stop offset="0.464323" stopColor="#A13051" />
                    <stop offset="1" stopColor="#6784AC" />
                </linearGradient>
                <clipPath id="clip0_3875_39552">
                    <rect width="70" height="71.1667" fill="white" transform="translate(0.5 0.416504)" />
                </clipPath>
            </defs>
        </svg>
    );
}

SocialImpactIcon.propTypes = {
    width: PropTypes.number,
};

SocialImpactIcon.defaultProps = {
    width: 72,
};
