import PropTypes from 'prop-types';

import { IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN } from 'constants/theme';

export function Snacks({ brand, isActive, width }) {
    const bagColor = brand === MISFITS_THEME_DOMAIN ? '#395A81' : '#63ACDE';
    const logoColor = brand === MISFITS_THEME_DOMAIN ? '#B0C472' : '#C5DB66';
    const chipColor = brand === MISFITS_THEME_DOMAIN ? '#DEA455' : '#F6A685';
    const activeColor = brand === MISFITS_THEME_DOMAIN ? '#D7E1B8' : '#F0D3E3';

    return isActive ? (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.6505 28.7652C21.902 26.9646 29 22.6358 29 14.9992C29 7.3625 22.9452 2.7363 14.6505 1.1728C7.31233 -0.213857 2.48978 6.96057 1.13553 14.967C-0.132106 22.475 7.73356 30.4815 14.6505 28.7652Z"
                fill={activeColor}
            />
            <path
                d="M3.52657 10.8841L21.1366 5.20411C20.6766 0.244113 3.49657 7.96411 3.02657 9.37411C2.95657 9.91411 3.02657 10.4641 3.21657 10.9841L3.53657 10.8841H3.52657Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.23704 8.43415C3.68704 6.21415 5.24704 3.67415 8.64704 6.33415C12.047 8.99415 9.11704 11.5442 9.11704 11.5442L5.88704 11.4642C5.88704 11.4642 4.43704 9.27415 4.23704 8.43415Z"
                fill={chipColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M5.95618 6.87416C4.44618 5.17416 4.11618 2.37416 8.40618 2.64416C12.6962 2.91416 13.1562 7.78416 13.1562 7.78416L9.49618 9.78416C9.49618 9.78416 6.72618 7.73416 5.96618 6.88416L5.95618 6.87416Z"
                fill={chipColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M13.7764 7.91418C11.9664 6.52418 11.5964 3.24418 15.1564 1.38418C18.9664 -0.615824 19.1464 5.84418 19.1464 5.84418L17.1864 8.38418L13.7764 7.92418V7.91418Z"
                fill={chipColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M11.8263 8.47421C10.5763 6.55421 10.3163 2.70421 14.6063 2.98421C18.8963 3.25421 17.5963 8.28421 17.5963 8.28421L14.9063 10.0442L11.8263 8.47421Z"
                fill={chipColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M7.00615 11.1542C6.46615 8.9242 7.49614 5.2142 11.4461 6.8942C15.4061 8.5742 12.5061 12.8942 12.5061 12.8942L9.38614 13.6542L7.00615 11.1542Z"
                fill={chipColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M14.086 10.4842C12.836 8.56417 13.566 5.34418 17.556 4.76418C21.816 4.15418 19.856 10.2942 19.856 10.2942L17.166 12.0542L14.086 10.4842Z"
                fill={chipColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M3.20703 10.9841C3.93703 13.1241 5.22703 15.0241 6.93703 16.4941C10.267 19.3941 8.89703 25.9641 10.927 26.8941C12.957 27.8241 29.767 24.6341 26.957 18.5941C24.717 13.7641 21.567 10.1641 21.127 5.2041L3.20703 10.9841Z"
                fill={bagColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M3.20703 10.9841L21.137 5.2041C21.207 5.8141 21.297 6.4241 21.437 7.0141L3.75703 12.3641L3.20703 10.9841Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.6566 13.3942C11.7266 14.9042 9.70659 19.0342 14.2066 20.8442C18.7066 22.6542 20.8666 18.9942 21.0966 17.4542C21.3366 15.6142 20.8766 13.3842 19.2866 12.4742C17.6966 11.5642 13.9966 11.2242 12.6566 13.4042V13.3942Z"
                fill={logoColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ) : (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.52657 10.538L21.1366 4.85801C20.7866 1.60801 3.44657 8.60801 3.02657 9.02801C2.95657 9.56801 3.02657 10.118 3.21657 10.638L3.53657 10.538H3.52657Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.20703 10.638C3.93703 12.778 5.22703 14.678 6.93703 16.148C10.267 19.048 8.89703 25.618 10.927 26.548C12.957 27.478 29.767 24.288 26.957 18.248C24.717 13.418 21.567 9.81803 21.127 4.85803L3.20703 10.638Z"
                fill={bagColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M3.20703 10.638L21.137 4.85803C21.207 5.46803 21.297 6.07803 21.437 6.66803L3.75703 12.018L3.20703 10.638Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.6566 13.048C11.7266 14.558 9.70659 18.688 14.2066 20.498C18.7066 22.308 20.8666 18.648 21.0966 17.108C21.3366 15.268 20.8766 13.038 19.2866 12.128C17.6966 11.218 13.9966 10.878 12.6566 13.058V13.048Z"
                fill={logoColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

Snacks.propTypes = {
    brand: PropTypes.oneOf([IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN]).isRequired,
    isActive: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Snacks.defaultProps = {
    isActive: false,
    width: 30,
};
