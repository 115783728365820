import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';

import { usePreviouslyPurchasedQuery } from 'api/queries/usePreviouslyPurchasedQuery';
import Button from 'honeydew/Button';
import { useAccountPlusMembershipHook } from 'hooks/useAccountPlusMembershipHook';
import { getPrevAndNextAisles } from 'utils/aislesUtils';
import { trackEvent } from 'utils/analyticsUtils';
import { getAislePath, getDecodedPathSegment } from 'utils/pathUtils';

function trackLinearAisleNavigation(direction, fromAisle, toAisle) {
    trackEvent('linear aisle navigate', {
        direction,
        fromAisle,
        toAisle,
    });
}

export function LinearAisleNav({ aisles }) {
    const { inPlusMembershipTreatment, plusMembershipBranding } = useAccountPlusMembershipHook();

    const { data: prevPurchasedData, isLoading: prevPurchasedIsLoading } = usePreviouslyPurchasedQuery();

    const prevPurchasedItems = prevPurchasedData?.items?.[0] ?? [];

    const showBuyItAgainAisle = !prevPurchasedIsLoading && prevPurchasedItems.length > 0;

    const location = useLocation();
    const params = useParams();

    if (!aisles || !aisles.length) {
        return null;
    }

    const aisleDisplayName = getDecodedPathSegment(params?.category);
    const currentAisleIndex = aisles?.findIndex(
        (aisle) => aisle.name.toLowerCase() === aisleDisplayName?.toLowerCase()
    );

    const { currentAisle, nextAisle, prevAisle } = getPrevAndNextAisles(
        aisles,
        currentAisleIndex,
        location.pathname,
        showBuyItAgainAisle,
        inPlusMembershipTreatment
    );

    const memberDealsAisleName = `${plusMembershipBranding} Deals`;

    return (
        <nav className="mb-[180px] mt-20 flex items-center justify-center gap-20 px-10 md:mb-[150px]">
            {prevAisle && (
                <span className="max-w-[250px] flex-1">
                    <Button
                        as="link"
                        size="lg"
                        variant="secondary"
                        to={prevAisle.url || getAislePath(prevAisle.name)}
                        onClick={() => trackLinearAisleNavigation('previous', currentAisle.name, prevAisle.name)}
                    >
                        <div className="flex items-center">
                            <span className="sr-only">Previous aisle: </span>
                            <span aria-hidden="true">&lt; </span>
                            {/* Ideally we'd like for this to be more dynamic, however scope and timeline constraints for the Paid Membership project prevent a more elegant solution at the time of this implementation. */}
                            {prevAisle.id === 'member-deals' ? (
                                <span className="ml-10 line-clamp-2 py-[2px]">{memberDealsAisleName}</span>
                            ) : (
                                <span className="ml-10 line-clamp-2 py-[2px]">{prevAisle.name}</span>
                            )}
                        </div>
                    </Button>
                </span>
            )}
            {nextAisle && (
                <span className="max-w-[250px] flex-1">
                    <Button
                        as="link"
                        size="lg"
                        variant="secondary"
                        to={nextAisle.url || getAislePath(nextAisle.name)}
                        onClick={() => trackLinearAisleNavigation('next', currentAisle.name, nextAisle.name)}
                    >
                        <div className="flex items-center">
                            <span className="sr-only">Next aisle: </span>
                            {/* Ideally we'd like for this to be more dynamic, however scope and timeline constraints for the Paid Membership project prevent a more elegant solution at the time of this implementation. */}
                            {nextAisle.id === 'member-deals' ? (
                                <span className="mr-10 line-clamp-2 py-[2px]">{memberDealsAisleName}</span>
                            ) : (
                                <span className="mr-10 line-clamp-2 py-[2px]">{nextAisle.name}</span>
                            )}
                            <span aria-hidden="true"> &gt;</span>
                        </div>
                    </Button>
                </span>
            )}
        </nav>
    );
}

LinearAisleNav.propTypes = {
    aisles: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        })
    ),
};

LinearAisleNav.defaultProps = {
    aisles: undefined,
};
