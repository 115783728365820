import PropTypes from 'prop-types';

function WinePLPIcon({ height, width }) {
    return (
        <svg width={width} height={height} viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.72861 1.85239C10.6559 2.18 12.2571 2.21748 12.3049 1.9361C12.3528 1.65472 10.8291 1.16103 8.90182 0.833423C6.97451 0.505814 5.37334 0.468339 5.32551 0.74972C5.27768 1.0311 6.8013 1.52479 8.72861 1.85239Z"
                fill="white"
            />
            <path
                d="M5.32508 0.749591C5.32508 0.749591 2.70977 3.82989 3.08553 6.54252C3.46129 9.25515 6.10311 9.91322 6.20776 10.7329C6.31241 11.5526 6.56941 12.5022 6.14947 14.7669C5.72953 17.0317 5.38981 19.0302 5.16881 19.3066C4.9474 19.5829 4.20952 20.0269 3.16094 19.8397C2.44765 19.7124 2.23281 19.8999 2.0092 20.2568C1.88093 20.4619 3.48328 20.9143 5.60807 21.2793C7.73077 21.644 9.25722 21.7776 9.25169 21.6043C9.24617 21.4311 9.031 20.7146 8.09823 20.6691C7.68455 20.6487 7.0425 20.3634 6.73693 20.131C6.28137 19.7849 6.07849 19.2872 6.20519 18.5418C6.33189 17.7964 6.89264 13.8828 7.28582 12.7993C7.679 11.7157 7.9886 11.1264 8.67211 10.7965C9.35563 10.4665 11.1687 10.0607 11.9484 9.09234C12.6134 8.26658 12.8909 7.51189 12.858 5.44956C12.8251 3.38724 12.3048 1.93687 12.3048 1.93687"
                fill="white"
            />
            <path
                d="M5.32508 0.749591C5.32508 0.749591 2.70977 3.82989 3.08553 6.54252C3.46129 9.25515 6.10311 9.91322 6.20776 10.7329C6.31241 11.5526 6.56941 12.5022 6.14947 14.7669C5.72953 17.0317 5.38981 19.0302 5.16881 19.3066C4.9474 19.5829 4.20952 20.0269 3.16094 19.8397C2.44765 19.7124 2.23281 19.8999 2.0092 20.2568C1.88093 20.4619 3.48328 20.9143 5.60807 21.2793C7.73077 21.644 9.25722 21.7776 9.25169 21.6043C9.24617 21.4311 9.031 20.7146 8.09823 20.6691C7.68455 20.6487 7.0425 20.3634 6.73693 20.131C6.28137 19.7849 6.07849 19.2872 6.20519 18.5418C6.33189 17.7964 6.89264 13.8828 7.28582 12.7993C7.679 11.7157 7.9886 11.1264 8.67211 10.7965C9.35563 10.4665 11.1687 10.0607 11.9484 9.09234C12.6134 8.26658 12.8909 7.51189 12.858 5.44956C12.8251 3.38724 12.3048 1.93687 12.3048 1.93687C11.4725 1.29044 7.30486 0.876006 5.32508 0.749591Z"
                stroke="#2D2D2D"
                strokeWidth="0.366667"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.26032 8.47011C4.7663 8.11279 4.42218 7.64329 4.17136 7.0874C3.95192 6.60057 3.95256 6.00827 4.0324 5.51858C4.07196 5.27594 4.12981 5.06578 4.18454 4.91616C4.2121 4.84085 4.23744 4.78478 4.2573 4.74896C4.25967 4.74469 4.26183 4.74094 4.26378 4.73767C4.81566 4.5473 5.25234 4.51594 5.62001 4.57722C5.99688 4.64004 6.31667 4.8027 6.62678 5.0189C6.83468 5.16384 7.02839 5.32503 7.23222 5.49465C7.33995 5.5843 7.45051 5.6763 7.56749 5.7695C7.89805 6.03287 8.26311 6.29065 8.70549 6.47023C9.57527 6.82332 10.3019 6.84626 10.8695 6.76598C11.1519 6.72602 11.3927 6.66083 11.5883 6.60058C11.6452 6.58305 11.6962 6.56667 11.7424 6.55169C11.6464 6.96556 11.4359 7.69987 11.0598 8.26272C10.8309 8.60534 10.3534 8.89391 9.68307 9.06788C9.0186 9.24034 8.19043 9.29294 7.29819 9.1868C6.41094 9.08125 5.75484 8.82779 5.26032 8.47011ZM4.27562 4.71973C4.27781 4.71703 4.2776 4.71781 4.27494 4.72059L4.27562 4.71973Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.366667"
            />
        </svg>
    );
}

export default WinePLPIcon;

WinePLPIcon.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
};

WinePLPIcon.defaultProps = {
    height: 23,
    width: 14,
};
