import React from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

// Individual dropdown links must be wrapped in <NavigationMenu.Link> component - see associated story for example
// https://www.radix-ui.com/docs/primitives/components/navigation-menu
export function DropdownLink({ children, disabled, linkUrl, title, ...props }) {
    return (
        <NavigationMenu.Link asChild>
            <li>
                {disabled ? (
                    <p
                        className={clsx('cursor-not-allowed px-20 py-5 text-grey', {
                            'text-brand-primary': window.location.pathname === linkUrl,
                        })}
                        {...props}
                    >
                        {children}
                    </p>
                ) : (
                    <NavLink
                        className={({ isActive }) =>
                            clsx({ 'text-brand-primary': isActive }, 'block px-20 py-5 no-underline hover:bg-off-white')
                        }
                        to={linkUrl}
                        end
                    >
                        {children}
                    </NavLink>
                )}
            </li>
        </NavigationMenu.Link>
    );
}

DropdownLink.propTypes = {
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    linkUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

DropdownLink.defaultProps = {
    disabled: false,
};

export function Dropdown({ children, linkPath, triggerName }) {
    return (
        <NavigationMenu.Root className="relative z-[1] whitespace-nowrap">
            <NavigationMenu.List>
                <NavigationMenu.Item>
                    <NavigationMenu.Trigger
                        className={clsx('group justify-between gap-[2px] font-grotesk-bold leading-none')}
                        data-testid="dropdown-trigger"
                    >
                        <NavLink
                            to={linkPath}
                            className={({ isActive }) =>
                                clsx(
                                    { 'border-kale if:border-beet': isActive },
                                    { 'border-white': !isActive },
                                    'flex items-center rounded-full px-10 py-5 hover:bg-off-white'
                                )
                            }
                            end
                        >
                            <span>{triggerName}</span>
                            <ChevronDownIcon
                                className="ml-10 h-[24px] w-[24px] rounded-full bg-off-white p-[3px] text-black transition-transform duration-[250] ease-in group-data-[state=open]:rotate-180"
                                aria-hidden
                            />
                        </NavLink>
                    </NavigationMenu.Trigger>

                    <NavigationMenu.Content className="data-[motion=from-start]:animate-enterFromLeft data-[motion=from-end]:animate-enterFromRight data-[motion=to-start]:animate-exitToLeft data-[motion=to-end]:animate-exitToRight absolute left-0 top-0 w-auto rounded-lg bg-white shadow-base">
                        {children}
                    </NavigationMenu.Content>
                </NavigationMenu.Item>
            </NavigationMenu.List>

            <div className="absolute right-0 top-full">
                <NavigationMenu.Viewport
                    data-testid="dropdown-menu"
                    className="data-[state=open]:animate-scaleIn data-[state=closed]:animate-scaleOut relative mt-10 h-[var(--radix-navigation-menu-viewport-height)] w-full origin-[top_center] overflow-hidden rounded-md bg-white shadow-base transition-[width,_height] duration-300 sm:w-[var(--radix-navigation-menu-viewport-width)]"
                />
            </div>
        </NavigationMenu.Root>
    );
}

Dropdown.propTypes = {
    children: PropTypes.node.isRequired,
    linkPath: PropTypes.string,
    triggerName: PropTypes.string.isRequired,
};

Dropdown.defaultProps = {
    linkPath: null,
};
