import { useMutation, useQueryClient } from '@tanstack/react-query';

import { removePreference, setV2Preference } from 'api/jalapeno';
import useAccountQuery from 'api/queries/useAccountQuery';
import usePreferencesQuery, { PREFERENCES_QUERY_KEY } from 'api/queries/usePreferencesQuery';
import logError from 'utils/errorUtils';

/**
 * Enum for mutation operations.
 * @readonly
 * @enum {string}
 */
const OperationTypes = Object.freeze({
    REMOVE: 'remove',
    ADD_LIKE: 'add_like',
    ADD_DISLIKE: 'add_dislike',
    ADD_RECURRING: 'add_recurring',
});

/**
 * Optimistic updates for mutating a preferences.
 * @param {string} preferenceID - The ID of the preferences to mutate.
 * @returns {Object} - Functions for mutating a preference's quantities.
 */
function useUpdatePreference({ preferenceID }) {
    const queryClient = useQueryClient();
    const { data: account = {} } = useAccountQuery();
    const { data: preferences = {} } = usePreferencesQuery();

    const { subID: subscriptionID } = account;
    const queryKey = [PREFERENCES_QUERY_KEY];

    /**
     * Build an updated list of preferences, including the mutated preference.
     *   - Can add or remove preferences from likes, dislikes and recurring lists
     * @param {OperationTypes} operation - The operation to perform on the preference.
     * @param {Selection[]} preferences - The relevant list of preferences.
     */
    const updatePreferences = ({ operation }) => {
        const allItems = [];
        const { packPreferences = {}, recurring = [] } = preferences;
        const removePreferenceItem = (array) => array.filter((item) => item.id !== preferenceID);

        const updatedPackPreferences = packPreferences?.map((pack) => {
            const packPreference = pack;
            const { dislikes, favorites, neutral } = pack || {};
            const itemToAdd = neutral.filter((item) => item.id === preferenceID)[0];

            if (operation === 'remove') {
                packPreference.dislikes = removePreferenceItem(packPreference.dislikes);
                packPreference.favorites = removePreferenceItem(packPreference.favorites);
                packPreference.neutral = neutral;
            } else {
                allItems.push(...neutral);
                if (itemToAdd) {
                    if (operation === 'add_dislike') packPreference.dislikes = [...dislikes, itemToAdd];
                    if (operation === 'add_like') packPreference.favorites = [...favorites, itemToAdd];
                }
                packPreference.neutral = removePreferenceItem(neutral);
            }

            return packPreference;
        });

        const updatedRecurring = recurring;

        if (operation === 'remove') {
            updatedRecurring[0].recurring = removePreferenceItem(recurring[0].recurring);
        } else if (operation === 'add_recurring') {
            updatedRecurring[0].recurring.push(allItems.filter((item) => item.id === preferenceID)[0]);
        }

        return {
            packPreferences: updatedPackPreferences,
            recurring: updatedRecurring,
        };
    };

    /**
     * Determines the appropriate API endpoint to call based on the passed in operation.
     * @param {OperationTypes} operation - The operation to perform on the preference's qty.
     */
    const getMutationFn = async ({ operation }) => {
        if (operation === OperationTypes.REMOVE) {
            return removePreference(subscriptionID, { itemID: preferenceID });
        }

        const preferenceType = operation.split('_')[1].toUpperCase();
        return setV2Preference(subscriptionID, { itemID: preferenceID, preferenceType });
    };

    /**
     * TanStack Query hook to optimistically update the cache directly.
     * Reference: https://tanstack.com/query/v4/docs/react/guides/optimistic-updates
     */
    const preferenceMutation = useMutation({
        // API call:
        mutationFn: getMutationFn,

        // When mutate is called:
        onMutate: async (args) => {
            // Cancel any outgoing refetches, such that
            // they don't overwrite our optimistic update.
            await queryClient.cancelQueries({ queryKey });

            // Snapshot the previous preferences.
            const previousPreferences = queryClient.getQueryData(queryKey);

            // _Optimistically_ update the preferences.
            queryClient.setQueryData(queryKey, () => updatePreferences({ operation: args.operation }));

            // Return a context object with the snapshot of previous preferences.
            return { previousPreferences };
        },

        // If the mutation fails:
        onError: (err, context) => {
            // Use the context returned from onMutate to roll back.
            queryClient.setQueryData(queryKey, context.previousPreferences);
            logError(err);
            console.error(err);
        },

        // After error or success:
        onSettled: () => {
            // Refetch preferences.
            queryClient.invalidateQueries({ queryKey });
        },
    });

    /*
     * mutationOptions
     *
     * https://tanstack.com/query/v4/docs/framework/react/reference/useMutation
     *
     * { onSuccess, onSettled, onError }
     *
     */

    // Removes preference from likes/dislikes list
    const removeUserPreference = (mutationOptions) => {
        preferenceMutation.mutate({ subscriptionID, operation: OperationTypes.REMOVE }, mutationOptions);
    };

    // Adds preference to liked list
    const addLikedUserPreference = (mutationOptions) => {
        preferenceMutation.mutate({ subscriptionID, operation: OperationTypes.ADD_LIKE }, mutationOptions);
    };

    // Adds preference to disliked list
    const addDislikedUserPreference = (mutationOptions) => {
        preferenceMutation.mutate({ subscriptionID, operation: OperationTypes.ADD_DISLIKE }, mutationOptions);
    };

    // Adds preference to recurring list
    const addRecurringUserPreference = (mutationOptions) => {
        preferenceMutation.mutate({ subscriptionID, operation: OperationTypes.ADD_RECURRING }, mutationOptions);
    };

    return {
        removeUserPreference,
        addLikedUserPreference,
        addDislikedUserPreference,
        addRecurringUserPreference,
    };
}

export default useUpdatePreference;
