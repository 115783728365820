import { v4 as uuid } from '@lukeed/uuid';

import { IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN } from 'constants/theme';

import { getBrandDomain, getIsLocalDev } from '.';

let GLOBAL_SCROLL_POSITION = 0;

/**
 * Disables document scrolling
 * @param {mobile} boolean - True is the screen width is within mobile range
 * @example
 * const disableBodyScroll = disableBodyScroll(false); --> disables body scroll
 */
const disableBodyScroll = (mobile) => {
    const body = document.getElementsByTagName('BODY')[0];
    if (mobile) {
        const scrollPosition = window.pageYOffset;
        setTimeout(() => {
            GLOBAL_SCROLL_POSITION = scrollPosition;
            body.style.overflowY = 'hidden';
            body.style.height = '100vh';
        }, 400);
    }
};

/**
 * Enables document scrolling
 * @param {mobile} boolean - True is the screen width is within mobile range
 * @example
 * const enableBodyScroll = enableBodyScroll(false); --> enables body scroll
 */
const enableBodyScroll = (mobile) => {
    const body = document.getElementsByTagName('BODY')[0];
    if (mobile && body.style.overflowY === 'hidden') {
        body.style.overflow = null;
        body.style.height = null;

        const root = document.querySelector(':root');
        root.setAttribute('style', 'scroll-behavior: auto;');
        window.scrollTo(0, GLOBAL_SCROLL_POSITION);
        root.removeAttribute('style');
    }
};

/**
 * Opens modal related to desired callback and disables scrolling
 * @param {callback} function - The desired function to call when opening the modal
 * @example
 * const openModal = openModal(setCreditsModal); --> will open modal attached to creditsModal
 */
const openModal = (callback) => {
    callback(true);
    disableBodyScroll();
};

/**
 * Closes modal related to desired callback and enables scrolling
 * @param {callback} function - The desired function to call when closing the modal
 * @example
 * const closeModal = closeModal(setCreditsModal); --> will close modal attached to creditsModal
 */
const closeModal = (callback) => {
    callback(false);
    enableBodyScroll();
};

const handleSetShippingStatus = (shippingStatus) => {
    const defaultStatus = {
        shippingStatus: 'Scheduled',
        color: 'lime',
        variant: 'dark',
    };

    if (shippingStatus === 'DELIVERED') {
        return {
            ...defaultStatus,
            shippingStatus: 'Delivered',
            color: 'default',
        };
    }
    if (shippingStatus === 'IN TRANSIT' || shippingStatus === 'OUT FOR DELIVERY' || shippingStatus === 'SHIPPED') {
        return {
            ...defaultStatus,
            shippingStatus: 'Shipped',
        };
    }
    if (shippingStatus === 'CANCELLED') {
        return {
            shippingStatus: 'Canceled',
            color: 'error',
            variant: 'light',
        };
    }
    if (shippingStatus === 'PROCESSING') {
        return {
            ...defaultStatus,
            shippingStatus: 'Processing',
        };
    }
    if (shippingStatus === 'CHARGED' || shippingStatus === 'CONFIRMED') {
        return {
            ...defaultStatus,
            shippingStatus: 'Confirmed',
        };
    }
    if (shippingStatus === 'DONATED') {
        return {
            ...defaultStatus,
            shippingStatus: 'Donated',
        };
    }
    return {
        ...defaultStatus,
    };
};

/**
 * Capitalized first letter of inputted string
 * @param {string} - The string to capitalize
 * @example
 * const capitalizedWord = capitalize('hello'); --> 'Hello'
 * @returns {string}
 */
const capitalize = (string) => {
    const firstLetter = string.charAt(0).toUpperCase();
    const rest = string.substring(1);
    return `${firstLetter}${rest}`;
};

/**
 * Capitalizes every word in string.
 * @param {string} - The string of words to capitalize
 * @example
 * // returns 'Lorem Ipsum Dolor'
 * const capitalizedWords  = capitalizeWords('Lorem ipsum dOLOR');
 * @returns {string}
 */
const capitalizeWords = (string = '') =>
    string.split(' ').reduce((a, b, idx) => `${a}${idx > 0 ? ' ' : ''}${capitalize(b.toLowerCase())}`, '');

/**
 * Plulurizes a word if qty is greater than one
 * @param {string} - The word to plulurize
 * @param {number} - The qty of the word
 * @example
 * const plulurizesWord = plulurizeWord('bottle', 1); // returns bottle
 * const plulurizesWord = plulurizeWord('bottle', 2); // returns bottles
 * @returns {string}
 */
const plulurizeWord = (word = '', qty = 0) => `${word}${qty > 1 ? 's' : ''}`;

/**
 * Lowercase and kebab-case a given string
 * @param {string} name – The string to turn into an ID
 * @param {string} joinChar – Char to replace spaces
 * @param {string} splitChar – Char to split based on
 * @example
 * const myID = formatString('Hello there friend', '-'); --> 'hello-there-friend'
 * const myBrand = formatString('Hello there friend', '+'); --> 'hello+there+friend'
 * @returns {string}
 */
const formatString = (name, joinChar, splitChar = ' ') =>
    name?.toLowerCase().replace(/\./g, '').split(splitChar).join(joinChar) || '';

/**
 * Gets a user's anonymous id from local storage.
 * Creates a new id and sets it if one does not already exist.
 * @example
 * const anonymousId = getAnonymousId(); --> '1fbf4c2b-15bc-40db-8d05-3a838b012286'
 * @returns {string}
 */
const getAnonymousId = () => {
    const brandDomain = getBrandDomain();

    const existingId =
        (brandDomain === IMPERFECT_THEME_DOMAIN && localStorage.getItem('ajs_anonymous_id')?.replace(/"/g, '')) ||
        (brandDomain === MISFITS_THEME_DOMAIN && localStorage.getItem('anonymousId')) ||
        (getIsLocalDev() && localStorage.getItem('anonymousId'));

    if (existingId) {
        return existingId;
    }

    const newId = uuid();

    if (brandDomain === IMPERFECT_THEME_DOMAIN) {
        localStorage.setItem('ajs_anonymous_id', `"${newId}"`);
    }

    if (brandDomain === MISFITS_THEME_DOMAIN || getIsLocalDev()) {
        localStorage.setItem('anonymousId', newId);
    }

    return newId;
};

/**
 * Returns the top position of a given element on the page.
 *  @param {string} id – ID of element to scroll to
 * @example
 * scrollToElement('element-id'); --> 512
 * @returns {string}
 */
const getElementTopPosition = (id) => {
    const el = document.getElementById(id);
    if (el !== null) {
        const bodyRect = document.body.getBoundingClientRect().top;
        // this corrects potential scrolling issues due to auto zooming of content in firefox
        const elRect = el.getBoundingClientRect().top;
        const elPos = elRect - bodyRect;
        return elPos;
    }
    return 0;
};

/**
 * Returns string of sorted array of numbers
 *  @param {string} array – The array of numbers to sort and stringify
 * @example
 * getSortedNumberArrayString([1, 2, 3]); --> '1,2,3'
 * @returns {string}
 */
const getSortedNumberArrayString = (array = []) =>
    Array.isArray(array) ? array?.sort((a, b) => a - b).toString() : '';

/**
 * Convert string from kebab case to camel case
 *  @param {string} string – String to convert
 * @example
 * getCamelCaseFromKebabCase('gluten-free'); --> 'glutenFree'
 * @returns {string}
 */
const getCamelCaseFromKebabCase = (string = '') => string.replace(/-./g, (x) => x[1].toUpperCase());

/**
 * Returns first common item in array
 *  @param {string} array1 – First array
 *  @param {string} array2 – Second array
 * @example
 * haveCommonItems([1,2 3], [3, 4, 5]); --> true
 * @returns {string}
 */
const haveCommonItems = ({ array1 = [], array2 = [] }) => !!array1?.find((id) => array2.includes(id));

/**
 * resize a cdn asset by adding width and height to url
 * NOTE: do not include px in width and height values
 * @param {object} args - named arguments
 * @param {string} args.url - the asset's url, example: https://img-cdn.misfitsmarket.com/boysenberry/plusmembership/ribbon-with-coin-icon.png
 * @param {number|string} args.width - the width for the asset
 * @param {number|string} args.height - the height for the asset
 * @returns {string} - the new url for the asset
 */
const getResizedCDNAsset = ({ height = 'auto', url = '', width = 'auto' }) => {
    if (!url) return '';
    const size = `w_${width},h_${height}`;
    const folders = url.split('/');
    folders.splice(folders.length - 1, 0, size);
    const urlWithSizes = folders.join('/');
    return urlWithSizes;
};

export {
    capitalize,
    capitalizeWords,
    closeModal,
    disableBodyScroll,
    enableBodyScroll,
    getAnonymousId,
    getCamelCaseFromKebabCase,
    getResizedCDNAsset,
    getSortedNumberArrayString,
    handleSetShippingStatus,
    haveCommonItems,
    openModal,
    plulurizeWord,
    formatString,
    getElementTopPosition,
};
