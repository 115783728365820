import { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Sheet from 'react-modal-sheet';

import useAccountQuery from 'api/queries/useAccountQuery';
import useSelectionsQuery from 'api/queries/useSelectionsQuery';
import {
    MIN_WINE_BOTTLE_QTY_FOR_PERCENT_DISCOUNT_TIER_1,
    MIN_WINE_BOTTLE_QTY_FOR_PERCENT_DISCOUNT_TIER_2,
    MIN_WINE_BOTTLE_QTY_TO_SHIP,
    MIN_WINE_BOTTLE_QTY_TO_WAIVE_FEE,
    WINE_PERCENT_DISCOUNT_TIER_1,
    WINE_PERCENT_DISCOUNT_TIER_2,
} from 'constants/shop';
import CartButton from 'features/shop/cart/CartButton';
import FreeShippingProgress from 'features/shop/FreeShippingProgress';
import OrderMinProgress from 'features/shop/OrderMinProgress';
import { WinePLPIcon } from 'honeydew/icons/Wine';
import { useAccountPlusMembershipHook } from 'hooks/useAccountPlusMembershipHook/useAccountPlusMembershipHook';
import { FEATURE_FLAG_NAMES, useAmplitudeFeatureFlag } from 'hooks/useFeatureFlag';
import useGlobalStore from 'hooks/useGlobalStore';
import UpSellsCarousel from 'shared/UpSellsCarousel';
import { plulurizeWord } from 'utils/globalUtils';
import { getGroceryItems, getItemsTotalCost, getOrderMin, getTotalWineBottleQty } from 'utils/orderUtils';
import { getProductsSubtotal, PriceType } from 'utils/priceUtils';

function WineCartSheet({ totalWineBottleQty, unbundleWineFeatureFlag, winePercentOff }) {
    const { setCartOpen } = useGlobalStore();

    const [prevBottleAmt, setPrevBottleAmt] = useState();
    const [transitionMsg, setTransitionMsg] = useState('');

    const bottlesToMeetMin = MIN_WINE_BOTTLE_QTY_TO_SHIP - totalWineBottleQty;
    const bottlesToWaiveFee = MIN_WINE_BOTTLE_QTY_TO_WAIVE_FEE - totalWineBottleQty;
    const bottlesFor10OFF = MIN_WINE_BOTTLE_QTY_FOR_PERCENT_DISCOUNT_TIER_1 - totalWineBottleQty;
    const bottlesFor15OFF = MIN_WINE_BOTTLE_QTY_FOR_PERCENT_DISCOUNT_TIER_2 - totalWineBottleQty;
    const wineMinMet = totalWineBottleQty >= MIN_WINE_BOTTLE_QTY_TO_SHIP;

    useEffect(() => {
        if (typeof prevBottleAmt !== 'undefined' && prevBottleAmt > 0 && prevBottleAmt < totalWineBottleQty) {
            if (
                prevBottleAmt < MIN_WINE_BOTTLE_QTY_FOR_PERCENT_DISCOUNT_TIER_2 &&
                totalWineBottleQty >= MIN_WINE_BOTTLE_QTY_FOR_PERCENT_DISCOUNT_TIER_2 &&
                unbundleWineFeatureFlag
            ) {
                setTransitionMsg(`${WINE_PERCENT_DISCOUNT_TIER_2}%OFF discount applied!`);
            } else if (
                prevBottleAmt < MIN_WINE_BOTTLE_QTY_FOR_PERCENT_DISCOUNT_TIER_1 &&
                totalWineBottleQty >= MIN_WINE_BOTTLE_QTY_FOR_PERCENT_DISCOUNT_TIER_1 &&
                unbundleWineFeatureFlag
            ) {
                setTransitionMsg(`${WINE_PERCENT_DISCOUNT_TIER_1}%OFF discount applied!`);
            } else if (
                prevBottleAmt < MIN_WINE_BOTTLE_QTY_TO_WAIVE_FEE &&
                totalWineBottleQty >= MIN_WINE_BOTTLE_QTY_TO_WAIVE_FEE
            ) {
                setTransitionMsg('Your Alcohol Service Fee is waived!');
            } else if (prevBottleAmt < MIN_WINE_BOTTLE_QTY_TO_SHIP && wineMinMet) {
                setTransitionMsg('Wine order minimum met!');
            }
        }
        setPrevBottleAmt(totalWineBottleQty);
    }, [totalWineBottleQty]);

    useEffect(() => {
        if (transitionMsg !== '') {
            setTimeout(() => {
                setTransitionMsg('');
            }, 3000);
        }
    }, [transitionMsg]);

    return (
        <div className="bg-malt-off-white flex w-full justify-between p-10">
            {transitionMsg !== '' ? (
                <p className="text-body-sm pt-[2px] font-grotesk-bold text-success">{transitionMsg}</p>
            ) : (
                <p className="text-body-sm pt-[2px]">
                    {totalWineBottleQty < MIN_WINE_BOTTLE_QTY_TO_WAIVE_FEE ? (
                        <span>
                            {totalWineBottleQty < MIN_WINE_BOTTLE_QTY_TO_SHIP ? (
                                <>
                                    Add{' '}
                                    <span className="font-grotesk-bold text-error">
                                        {bottlesToMeetMin} {plulurizeWord('bottle', bottlesToMeetMin)}
                                    </span>{' '}
                                    to ship Wine order
                                </>
                            ) : (
                                <>
                                    Add{' '}
                                    <span className="font-grotesk-bold text-success">
                                        {bottlesToWaiveFee} {plulurizeWord('bottle', bottlesToWaiveFee)}
                                    </span>{' '}
                                    to waive Alcohol Service Fee
                                </>
                            )}
                        </span>
                    ) : (
                        <span>
                            {unbundleWineFeatureFlag ? (
                                <span>
                                    {totalWineBottleQty < MIN_WINE_BOTTLE_QTY_FOR_PERCENT_DISCOUNT_TIER_1 ? (
                                        <>
                                            Add{' '}
                                            <span className="font-grotesk-bold text-success">
                                                {bottlesFor10OFF} {plulurizeWord('bottle', bottlesFor10OFF)}
                                            </span>{' '}
                                            to save {WINE_PERCENT_DISCOUNT_TIER_1}%
                                        </>
                                    ) : (
                                        <span>
                                            {totalWineBottleQty < MIN_WINE_BOTTLE_QTY_FOR_PERCENT_DISCOUNT_TIER_2 ? (
                                                <>
                                                    Add{' '}
                                                    <span className="font-grotesk-bold text-success">
                                                        {bottlesFor15OFF} {plulurizeWord('bottle', bottlesFor15OFF)}
                                                    </span>{' '}
                                                    to save {WINE_PERCENT_DISCOUNT_TIER_2}%
                                                </>
                                            ) : (
                                                <>
                                                    Wine order{' '}
                                                    <span className="font-grotesk-bold text-success">
                                                        {winePercentOff}%OFF
                                                    </span>{' '}
                                                </>
                                            )}
                                        </span>
                                    )}
                                </span>
                            ) : (
                                <span className="font-grotesk-bold text-success">
                                    Your Alcohol Service Fee is waived!
                                </span>
                            )}
                        </span>
                    )}
                </p>
            )}
            <button
                className={clsx('flex rounded-full border-2 pb-[2px] pl-[12px] pr-[17px] pt-[2px]', {
                    'bg-white': totalWineBottleQty < MIN_WINE_BOTTLE_QTY_TO_SHIP,
                    'border-kale bg-kale text-white if:border-beet if:bg-beet': wineMinMet,
                })}
                onClick={() => setCartOpen((currVal) => !currVal)}
                type="button"
            >
                <span className="mr-5">
                    <WinePLPIcon height={23} width={14} />
                </span>
                <p className="text-body-sm font-grotesk-bold">{totalWineBottleQty}</p>
            </button>
        </div>
    );
}

WineCartSheet.propTypes = {
    totalWineBottleQty: PropTypes.number.isRequired,
    unbundleWineFeatureFlag: PropTypes.bool.isRequired,
    winePercentOff: PropTypes.number.isRequired,
};

function GroceryCartSheet({ showFreeShippingProgress, showOrderMinProgress }) {
    if (!showOrderMinProgress && !showFreeShippingProgress) return false;

    return (
        <div className="w-full bg-white p-10">
            {showOrderMinProgress && <OrderMinProgress />}
            {showFreeShippingProgress && <FreeShippingProgress />}
        </div>
    );
}

GroceryCartSheet.propTypes = {
    showFreeShippingProgress: PropTypes.bool.isRequired,
    showOrderMinProgress: PropTypes.bool.isRequired,
};

function CartSheetContent({
    groceryItemsTotalCost,
    hasUpSellsCarousel,
    hideGroceryCartSheet,
    hideWineCartSheet,
    mobile,
    productAdded,
    showFreeShippingProgress,
    showOrderMinProgress,
    totalWineBottleQty,
    unbundleWineFeatureFlag,
    winePercentOff,
}) {
    const showWineSheet = !hideWineCartSheet && totalWineBottleQty > 0 && productAdded;
    const showGrocerySheet = !hideGroceryCartSheet && groceryItemsTotalCost > 0 && productAdded;

    return (
        <div
            className={clsx('z-[1100] transition-all', {
                'w-full': mobile,
                'absolute right-40 top-[80px] -z-10 w-[375px]': !mobile,
                'bottom-0': hasUpSellsCarousel && mobile,
                'fixed bottom-0': !hasUpSellsCarousel && mobile,
            })}
        >
            <div
                className={clsx('shadow-base', {
                    '[&>div:first-child]:rounded-t-lg': mobile && !hasUpSellsCarousel,
                    'rounded-lg [&>div:last-child]:rounded-b-lg': mobile,
                    'rounded-b-lg [&>div:last-child]:rounded-b-lg': !mobile,
                })}
            >
                {showWineSheet && (
                    <WineCartSheet
                        showFreeShippingProgress={showFreeShippingProgress}
                        showOrderMinProgress={showOrderMinProgress}
                        totalWineBottleQty={totalWineBottleQty}
                        unbundleWineFeatureFlag={unbundleWineFeatureFlag}
                        winePercentOff={winePercentOff}
                    />
                )}
                {showGrocerySheet && (
                    <GroceryCartSheet
                        showFreeShippingProgress={showFreeShippingProgress}
                        showOrderMinProgress={showOrderMinProgress}
                        totalWineBottleQty={totalWineBottleQty}
                    />
                )}
                {mobile && (
                    <div
                        className={clsx('bg-white', {
                            'px-10 pb-10': showWineSheet || showGrocerySheet,
                            'mx-10 mb-20 !rounded-full': !showWineSheet && !showGrocerySheet,
                        })}
                    >
                        <CartButton />
                    </div>
                )}
            </div>
        </div>
    );
}

CartSheetContent.propTypes = {
    groceryItemsTotalCost: PropTypes.number.isRequired,
    hasUpSellsCarousel: PropTypes.bool,
    hideGroceryCartSheet: PropTypes.bool.isRequired,
    hideWineCartSheet: PropTypes.bool.isRequired,
    mobile: PropTypes.bool,
    productAdded: PropTypes.bool,
    showFreeShippingProgress: PropTypes.bool.isRequired,
    showOrderMinProgress: PropTypes.bool.isRequired,
    totalWineBottleQty: PropTypes.number.isRequired,
    unbundleWineFeatureFlag: PropTypes.bool.isRequired,
    winePercentOff: PropTypes.number.isRequired,
};

CartSheetContent.defaultProps = {
    hasUpSellsCarousel: false,
    mobile: false,
    productAdded: false,
};

function CartSheet({ mobile, orderMinOnly }) {
    const { inPlusMembershipTreatment, plusMembershipEligibleOrder } = useAccountPlusMembershipHook();

    const { data: account } = useAccountQuery();
    const { data: selections } = useSelectionsQuery();
    const { addCounter, productAdded } = useGlobalStore();

    const { freeShippingEligible = false } = account.nextOrder.cartExperience ?? {};
    const { unbundledWine: unbundleWineFeatureFlag } = account?.featureFlags ?? {};
    const winePercentOff = selections?.alcoholDiscountPercentage;

    const orderMin = getOrderMin(account);
    const groceryItems = getGroceryItems(selections) ?? [];

    const priceType = plusMembershipEligibleOrder ? PriceType.PLUS_MEMBER : PriceType.DEFAULT;

    const groceryItemsTotalCost = inPlusMembershipTreatment
        ? getProductsSubtotal({
              options: { priceType },
              products: groceryItems,
          })
        : getItemsTotalCost(groceryItems);

    const totalWineBottleQty = getTotalWineBottleQty(selections);
    const isOrderMinMet = groceryItemsTotalCost >= orderMin;
    const showOrderMinProgress =
        groceryItems?.length > 0 && (orderMinOnly || !isOrderMinMet || (isOrderMinMet && !freeShippingEligible));
    const showFreeShippingProgress = groceryItems?.length > 0 && isOrderMinMet && freeShippingEligible && !orderMinOnly;

    const [hideWineCartSheet, setHideWineCartSheet] = useState(true);
    const [hideWineCartSheetTimeout, setHideWineCartSheetTimeout] = useState(undefined);
    const [hideGroceryCartSheet, setHideGroceryCartSheet] = useState(true);
    const [hideGroceryCartSheetTimeout, setHideGroceryCartSheetTimeout] = useState(undefined);

    const shouldWineCartSheetBeHidden = useMemo(
        () => totalWineBottleQty >= MIN_WINE_BOTTLE_QTY_FOR_PERCENT_DISCOUNT_TIER_2,
        [totalWineBottleQty]
    );

    const shouldGroceryCartSheetBeHidden = useMemo(() => isOrderMinMet, [selections, isOrderMinMet]);

    const checkHideCartSheet = (
        shouldCartSheetBeHidden,
        setHideCartSheet,
        hideCartSheet,
        setHideCartSheetTimeout,
        hideCartSheetTimeout
    ) => {
        if (shouldCartSheetBeHidden && !hideCartSheet && hideCartSheetTimeout === undefined) {
            setHideCartSheetTimeout(
                setTimeout(() => {
                    setHideCartSheet(true);
                    setHideCartSheetTimeout(undefined);
                }, 7000)
            );
        } else if (!shouldCartSheetBeHidden && hideCartSheetTimeout !== undefined) {
            clearTimeout(hideCartSheetTimeout);
            setHideCartSheetTimeout(undefined);
        } else if (!shouldCartSheetBeHidden && hideCartSheet) {
            setHideCartSheet(false);
        }
    };

    checkHideCartSheet(
        shouldWineCartSheetBeHidden,
        setHideWineCartSheet,
        hideWineCartSheet,
        setHideWineCartSheetTimeout,
        hideWineCartSheetTimeout
    );
    checkHideCartSheet(
        shouldGroceryCartSheetBeHidden,
        setHideGroceryCartSheet,
        hideGroceryCartSheet,
        setHideGroceryCartSheetTimeout,
        hideGroceryCartSheetTimeout
    );

    const { isAssignedVariant: hasUpSellsCarousel } = useAmplitudeFeatureFlag({
        flagName: FEATURE_FLAG_NAMES.UP_SELLS_CAROUSEL_PLP,
        variant: 'on',
    });

    const sheetRef = useRef();
    const [upsellsVisible, setUpsellsVisible] = useState(true);

    let snapPoints = [248, 198, 78];
    const snapTo = (i) => sheetRef.current?.snapTo(i);

    if (upsellsVisible) {
        const wineVisible = !hideWineCartSheet && totalWineBottleQty > 0;
        const groceryVisible = !hideGroceryCartSheet && groceryItemsTotalCost > 0;

        if (wineVisible && groceryVisible) {
            snapPoints = [370, 320, 200];
        } else if (wineVisible && !groceryVisible) {
            snapPoints = [300, 250, 130];
        } else if (!wineVisible && groceryVisible) {
            snapPoints = [320, 270, 150];
        }
    }

    useEffect(() => {
        const carouselDismissed = localStorage.getItem('UPSELL_CAROUSEL_DISMISSED') === 'true' ?? false;
        if (((carouselDismissed && addCounter > 5) || !carouselDismissed) && addCounter > 0) {
            setUpsellsVisible(true);
        }
    }, [addCounter]);

    if (!account || !selections) return false;

    return mobile && hasUpSellsCarousel && upsellsVisible ? (
        <Sheet
            ref={sheetRef}
            isOpen
            snapPoints={snapPoints}
            initialSnap={0}
            disableScrollLocking
            onClose={() => {
                snapTo(2);
            }}
        >
            <Sheet.Container>
                <Sheet.Content>
                    <div className="bg-brand-secondary mx-10 flex flex-col items-center rounded-t-lg p-5">
                        <span className="bg-brand-primary h-5 w-[45px] rounded-full" />
                    </div>
                    <CartSheetContent
                        mobile={mobile}
                        orderMinOnly={orderMinOnly}
                        hasUpSellsCarousel
                        hideGroceryCartSheet={hideGroceryCartSheet}
                        hideWineCartSheet={hideWineCartSheet}
                        groceryItemsTotalCost={groceryItemsTotalCost}
                        totalWineBottleQty={totalWineBottleQty}
                        showFreeShippingProgress={showFreeShippingProgress}
                        showOrderMinProgress={showOrderMinProgress}
                        unbundleWineFeatureFlag={unbundleWineFeatureFlag}
                        winePercentOff={winePercentOff}
                    />
                    <UpSellsCarousel
                        name="Nice pick! Bet you'd like these too..."
                        position="sheet"
                        type="top_sellers"
                        setUpsellsVisible={setUpsellsVisible}
                    />
                </Sheet.Content>
            </Sheet.Container>
        </Sheet>
    ) : (
        <CartSheetContent
            mobile={mobile}
            orderMinOnly={orderMinOnly}
            hideGroceryCartSheet={hideGroceryCartSheet}
            hideWineCartSheet={hideWineCartSheet}
            groceryItemsTotalCost={groceryItemsTotalCost}
            productAdded={productAdded}
            totalWineBottleQty={totalWineBottleQty}
            showFreeShippingProgress={showFreeShippingProgress}
            showOrderMinProgress={showOrderMinProgress}
            unbundleWineFeatureFlag={unbundleWineFeatureFlag}
            winePercentOff={winePercentOff}
        />
    );
}

CartSheet.propTypes = {
    mobile: PropTypes.bool,
    orderMinOnly: PropTypes.bool,
};

CartSheet.defaultProps = {
    mobile: false,
    orderMinOnly: false,
};

export default CartSheet;
