import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

import { getIsProduction } from 'utils';

const env = getIsProduction() ? 'PROD' : 'DEV';
const client = window.isNativeApp ? 'NATIVE' : 'WEB';

const AWS_COGNITO_USER_POOL_ID = import.meta.env[`VITE_${env}_AWS_COGNITO_USER_POOL_ID`];
const AWS_COGNITO_CLIENT_ID = import.meta.env[`VITE_${env}_AWS_COGNITO_${client}_CLIENT_ID`];

const authorizeByToken = async (authData) => {
    const AccessToken = new AmazonCognitoIdentity.CognitoAccessToken({
        AccessToken: authData.accessToken,
    });

    const IdToken = new AmazonCognitoIdentity.CognitoIdToken({
        IdToken: authData.idToken,
    });

    const RefreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({
        RefreshToken: authData.refreshToken,
    });

    const sessionData = {
        IdToken,
        AccessToken,
        RefreshToken,
    };

    const session = new AmazonCognitoIdentity.CognitoUserSession(sessionData);

    const poolData = {
        UserPoolId: AWS_COGNITO_USER_POOL_ID,
        ClientId: AWS_COGNITO_CLIENT_ID,
    };

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    const userData = {
        Username: session.accessToken.payload.username,
        Pool: userPool,
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    cognitoUser.setSignInUserSession(session);

    return session;
};

export default authorizeByToken;
