import PropTypes from 'prop-types';

import { IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN } from 'constants/theme';

export function GlutenFree({ brand, isActive, width }) {
    const grainColor = brand === MISFITS_THEME_DOMAIN ? '#F2EBD1' : '#F8E1A4';
    const noSignColor = brand === MISFITS_THEME_DOMAIN ? '#DC6B31' : '#D9291F';
    const activeColor = brand === MISFITS_THEME_DOMAIN ? '#D7E1B8' : '#F0D3E3';

    return isActive ? (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.6505 28.7652C21.902 26.9646 29 22.6358 29 14.9992C29 7.3625 22.9452 2.7363 14.6505 1.1728C7.31233 -0.213857 2.48978 6.96057 1.13553 14.967C-0.132106 22.475 7.73356 30.4815 14.6505 28.7652Z"
                fill={activeColor}
            />
            <path
                d="M6.7207 11.9012C6.7207 11.9012 7.0138 13.4674 9.23953 14.3193C11.4653 15.1711 12.4453 14.1635 12.4453 14.1635C12.0789 13.1652 11.337 12.35 10.3845 11.8737C8.74492 11.0952 6.7207 11.9012 6.7207 11.9012Z"
                fill={grainColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.808 8.4115C14.808 8.4115 13.2417 8.7046 12.3899 10.9303C11.5381 13.1561 12.5456 14.1361 12.5456 14.1361C13.544 13.7697 14.3683 13.0278 14.8354 12.0752C15.614 10.4357 14.808 8.4115 14.808 8.4115Z"
                fill={grainColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.24121 6.55219C8.24121 6.55219 15.468 23.7993 16.7045 25.6495C16.9243 25.9792 18.3898 26.5013 18.2707 24.9167C18.2066 24.0741 9.82578 5.86523 9.82578 5.86523L8.24121 6.56135V6.55219Z"
                fill={grainColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.6746 14.9696C17.6746 14.9696 16.1083 15.2627 15.2565 17.4884C14.3772 19.7691 15.4122 20.7034 15.4122 20.7034C16.4106 20.337 17.2349 19.5951 17.702 18.6425C18.4806 17.0121 17.6746 14.9788 17.6746 14.9788V14.9696Z"
                fill={grainColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.6433 3.72192C12.3443 3.72192 10.0911 4.39972 8.17676 5.68203C6.26245 6.96434 4.76947 8.7779 3.89017 10.9029C3.01087 13.0278 2.77273 15.3726 3.22154 17.6258C3.67035 19.8882 4.77863 21.9582 6.409 23.5886C8.03937 25.219 10.1094 26.3273 12.3718 26.7761C14.6341 27.2249 16.9698 26.9959 19.0947 26.1166C21.2197 25.2373 23.0424 23.7443 24.3156 21.83C25.5979 19.9157 26.2757 17.6625 26.2757 15.3635C26.2757 12.2768 25.0483 9.3183 22.8684 7.13837C20.6885 4.95844 17.73 3.73108 14.6433 3.73108V3.72192ZM16.9514 13.669C18.2704 12.6065 22.1265 8.90613 22.1265 8.90613C22.3463 9.17175 23.1981 10.3258 23.2714 10.4724C24.1324 11.9837 24.572 13.6873 24.5629 15.4276C24.5537 17.1587 24.0866 18.8624 23.2164 20.3645C22.3463 21.8666 21.0915 23.1123 19.5893 23.9733C18.0872 24.8434 16.3836 25.2922 14.6433 25.2922C11.8039 25.2922 8.99194 23.6344 7.19671 21.7292C7.05932 21.5919 10.6498 18.8807 10.6498 18.8807C10.6498 18.8807 15.3577 14.9696 16.9423 13.669H16.9514ZM6.02431 19.8699C5.2824 18.8624 4.72368 16.8748 4.72368 15.3635C4.72368 12.7347 5.76784 10.2068 7.6272 8.34741C9.48655 6.48805 12.0145 5.44389 14.6433 5.44389C16.8598 5.44389 19.113 6.59797 20.5694 7.90776L6.02431 19.8699Z"
                fill={noSignColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.04395 7.94444C5.04395 7.94444 5.33705 9.51069 7.56277 10.3625C9.7885 11.2143 10.7686 10.2068 10.7686 10.2068C10.4022 9.20843 9.66027 8.38409 8.70769 7.91696C7.06817 7.12925 5.04395 7.94444 5.04395 7.94444Z"
                fill={grainColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.58691 18.4594C9.58691 18.4594 9.88001 20.0256 12.1057 20.8774C14.3315 21.7293 15.3115 20.7217 15.3115 20.7217C14.9451 19.7234 14.2032 18.899 13.2507 18.4227C11.6295 17.6534 9.58691 18.4502 9.58691 18.4502V18.4594Z"
                fill={grainColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.1322 4.45471C13.1322 4.45471 11.566 4.74781 10.7141 6.97354C9.86231 9.19927 10.879 10.1793 10.879 10.1793C11.8774 9.81294 12.7017 9.07103 13.1688 8.11846C13.9474 6.47893 13.1414 4.45471 13.1414 4.45471H13.1322Z"
                fill={grainColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.24217 2.91589C7.24217 2.91589 6.43614 4.2898 7.56274 6.39646C8.68935 8.50311 10.0999 8.37488 10.0999 8.37488C10.4754 7.37651 10.4479 6.27738 10.0358 5.28817C9.30302 3.63032 7.24217 2.91589 7.24217 2.91589Z"
                fill={grainColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.2467 18.441C21.2467 18.441 22.5382 17.5159 22.3733 15.1345C22.2084 12.6889 20.8711 12.2859 20.8711 12.2859C20.1201 13.0461 19.6896 14.0628 19.6621 15.1253C19.6621 16.9205 21.2375 18.441 21.2375 18.441H21.2467Z"
                fill={grainColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.9209 21.0332C18.9209 21.0332 20.4872 21.2163 21.971 19.3387C23.4548 17.461 22.7587 16.2245 22.7587 16.2245C21.6962 16.2886 20.6978 16.7466 19.9559 17.5251C18.7377 18.8624 18.9209 21.0332 18.9209 21.0332Z"
                fill={grainColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.5308 17.9281L20.5508 19.2013"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.366 16.5724L21.0088 14.5391"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ) : (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.58887 10.2159C8.58887 10.2159 8.57971 11.8097 10.6039 13.0645C12.6282 14.3193 13.7822 13.5225 13.7822 13.5225C13.6082 12.4691 13.0403 11.5257 12.1977 10.8846C10.7413 9.81291 8.58887 10.2159 8.58887 10.2159Z"
                fill={grainColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.1898 8.32916C17.1898 8.32916 15.596 8.32 14.3412 10.3442C13.0864 12.3684 13.8832 13.5225 13.8832 13.5225C14.9366 13.3485 15.88 12.7806 16.5211 11.938C17.5928 10.4725 17.1898 8.32916 17.1898 8.32916Z"
                fill={grainColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.0986 5.25158C11.0986 5.25158 14.9181 23.552 15.7791 25.6037C15.9348 25.9609 17.272 26.7578 17.4552 25.1824C17.5468 24.3397 12.784 4.86688 12.784 4.86688L11.0986 5.25158Z"
                fill={grainColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.7556 15.3086C18.7556 15.3086 17.1619 15.2994 15.9071 17.3237C14.6064 19.4028 15.4491 20.5111 15.4491 20.5111C16.5024 20.3371 17.4459 19.7692 18.087 18.9265C19.1587 17.4702 18.7556 15.3178 18.7556 15.3178V15.3086Z"
                fill={grainColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.6433 3.46545C12.3443 3.46545 10.0911 4.14325 8.17676 5.42556C6.26245 6.70787 4.76947 8.52143 3.89017 10.6464C3.01087 12.7714 2.77273 15.1162 3.22154 17.3694C3.67035 19.6317 4.77863 21.7018 6.409 23.3321C8.03937 24.9625 10.1094 26.0708 12.3718 26.5196C14.6341 26.9684 16.9698 26.7394 19.0947 25.8601C21.2197 24.9808 23.0424 23.4878 24.3156 21.5735C25.5979 19.6592 26.2757 17.406 26.2757 15.107C26.2757 12.0203 25.0483 9.06183 22.8684 6.8819C20.6885 4.70197 17.73 3.47461 14.6433 3.47461V3.46545ZM16.9514 13.4125C18.2704 12.35 22.1265 8.64966 22.1265 8.64966C22.3463 8.91528 23.1981 10.0694 23.2714 10.2159C24.1324 11.7272 24.572 13.4309 24.5629 15.1711C24.5537 16.9023 24.0866 18.6059 23.2164 20.108C22.3463 21.6102 21.0915 22.8558 19.5893 23.7168C18.0872 24.587 16.3836 25.0358 14.6433 25.0358C11.8039 25.0358 8.99194 23.3779 7.19671 21.4728C7.05932 21.3354 10.6498 18.6242 10.6498 18.6242C10.6498 18.6242 15.3577 14.7132 16.9423 13.4125H16.9514ZM6.02431 19.6134C5.2824 18.6059 4.72368 16.6183 4.72368 15.107C4.72368 12.4783 5.76784 9.95029 7.6272 8.09094C9.48655 6.23158 12.0145 5.18742 14.6433 5.18742C16.8598 5.18742 19.113 6.3415 20.5694 7.65129L6.02431 19.6134Z"
                fill={noSignColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.69141 6.0118C7.69141 6.0118 7.68225 7.60553 9.70647 8.86036C11.7307 10.1152 12.8848 9.31833 12.8848 9.31833C12.7107 8.265 12.1429 7.32159 11.3002 6.68043C9.84386 5.59963 7.69141 6.0118 7.69141 6.0118Z"
                fill={grainColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.1553 17.1954C10.1553 17.1954 10.1461 18.7891 12.1612 20.0531C14.1854 21.3171 15.3395 20.5111 15.3395 20.5111C15.1655 19.4578 14.5976 18.5144 13.7549 17.864C12.3169 16.8016 10.1553 17.1954 10.1553 17.1954Z"
                fill={grainColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.3015 4.12494C16.3015 4.12494 14.7078 4.11578 13.4438 6.14C12.1798 8.16422 12.995 9.3183 12.995 9.3183C14.0483 9.14427 14.9917 8.57639 15.6329 7.73373C16.7045 6.27739 16.3015 4.12494 16.3015 4.12494Z"
                fill={grainColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.8682 2C11.8682 2 10.6317 2.98921 10.9248 5.36149C11.2179 7.72461 12.5827 8.11846 12.5827 8.11846C13.2879 7.32159 13.6635 6.27743 13.6268 5.21494C13.5352 3.41054 11.8682 2 11.8682 2Z"
                fill={grainColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

GlutenFree.propTypes = {
    brand: PropTypes.oneOf([IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN]).isRequired,
    isActive: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

GlutenFree.defaultProps = {
    isActive: false,
    width: 30,
};
