/**
 * Get a formatted plus membership preview price for a product in the cart.
 * @param {Object} - product data
 * @returns {string} - formatted price
 */
const getPlusMembershipPreviewPrice = (product = {}) => {
    const { plusMemberPrice, qty = 1 } = product;
    const plusMemberPriceWithQty = plusMemberPrice * qty;
    return plusMemberPriceWithQty?.toFixed(2);
};

export { getPlusMembershipPreviewPrice };
