import PropTypes from 'prop-types';

function AllPreferencesIcon({ className, width }) {
    return (
        <svg width={width} className={className} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.1479 25.6779C10.5286 26.5557 12.0088 29.4842 12.0788 30.1274C12.1489 30.7707 10.7611 31.2685 11.4308 32.2226C12.1004 33.1767 13.6387 32.7664 13.8892 32.0361C14.664 32.0242 16.3251 31.9108 16.1508 30.432C15.9765 28.9532 14.3691 29.5399 14.0714 29.2277C13.7736 28.9156 12.0726 24.8716 12.0726 24.8716L10.1479 25.6779Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.716027"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.8276 15.7619C14.0254 18.5197 13.4907 20.9203 13.4351 22.22C13.3388 24.3927 13.1182 25.6719 11.6418 26.3133C10.3616 26.8767 8.84425 26.0863 7.19543 24.6732C6.20507 23.8277 4.20614 22.474 3.12211 19.9787C1.5069 16.2699 2.37278 12.3135 5.05317 11.1496C7.73357 9.98581 11.2141 12.0637 12.8276 15.7619Z"
                fill="#DEA455"
                stroke="#2D2D2D"
                strokeWidth="0.716027"
                strokeMiterlimit="10"
            />
            <path
                d="M16.8849 1.17596C17.3158 0.932053 18.8048 -0.192728 20.1762 1.0416C21.7346 2.44032 20.5161 5.39651 20.6435 7.05468C20.7708 8.71284 21.5146 10.706 20.0411 13.2505C19.0458 14.9729 15.766 16.2763 13.1537 15.4163C10.5413 14.5562 16.8849 1.17596 16.8849 1.17596Z"
                fill="#2E8540"
                stroke="#2D2D2D"
                strokeWidth="0.716027"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.3283 1.55293C15.9095 1.93442 13.1425 4.07129 12.0523 4.74947C10.9622 5.42765 7.85787 7.31268 8.07483 10.0976C8.29179 12.8825 9.76387 14.5179 11.9157 15.1722C14.0676 15.8265 16.5639 15.3475 17.8591 12.9476C19.1543 10.5477 18.8154 8.82641 18.9061 6.86693C18.9702 5.56416 19.9076 3.91941 19.9633 2.60533C20.019 1.29125 18.1714 -0.0444989 16.3283 1.55293Z"
                fill="#B0C472"
                stroke="#2D2D2D"
                strokeWidth="0.716027"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.1199 7.00526C12.6317 7.13442 10.1602 8.41351 10.825 10.5277C11.4899 12.6419 13.5303 12.9874 14.7049 12.3112C15.8795 11.635 16.6758 10.2144 16.1372 8.37267C15.5985 6.53095 14.3368 6.68335 13.1199 7.00526Z"
                fill="#DC6B31"
                stroke="#2D2D2D"
                strokeWidth="0.716027"
                strokeMiterlimit="10"
            />
            <path
                d="M9.46478 15.9191C9.98237 15.4708 14.029 13.0935 17.7668 14.6839C21.5046 16.2742 23.1115 18.3821 24.4122 21.6655C26.2793 20.859 28.071 20.7095 30.1366 22.7804C28.9156 22.4993 27.0379 23.7115 26.4561 24.8018C25.7536 26.0877 26.0746 27.4652 26.9782 28.9088C25.0259 28.6641 22.6501 27.6682 22.9065 24.7463C21.3815 25.6436 19.6194 26.055 17.8547 25.9259C16.09 25.7967 14.4066 25.1331 13.0284 24.0234C8.97858 20.823 9.46478 15.9191 9.46478 15.9191Z"
                fill="#F1C34B"
                stroke="#2D2D2D"
                strokeWidth="0.716027"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.3468 14.1494C15.3468 14.1494 16.3975 13.7293 17.6977 13.3795C18.9979 13.0297 20.9177 13.9518 22.109 14.9112C23.3003 15.8706 24.0988 16.6501 24.1952 17.0324C24.2915 17.4146 23.5624 19.6123 23.5624 19.6123C23.5624 19.6123 21.7401 16.5278 19.3558 15.4733C18.0769 14.8741 16.731 14.4296 15.3468 14.1494Z"
                fill="#F2EBD1"
                stroke="#2D2D2D"
                strokeWidth="0.716027"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.4024 22.3601C11.7661 22.2824 13.9569 22.0793 15.8227 19.6697C17.6884 17.2601 16.6406 14.4574 16.6406 14.4574C16.6406 14.4574 13.2785 13.3791 9.47024 15.9161C9.17095 19.4251 11.4024 22.3601 11.4024 22.3601Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.716027"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.5812 17.1441C13.8618 17.2372 14.1649 17.0851 14.258 16.8045C14.3511 16.5238 14.1991 16.2207 13.9184 16.1276C13.6377 16.0345 13.3347 16.1865 13.2415 16.4672C13.1484 16.7479 13.3005 17.0509 13.5812 17.1441Z"
                fill="#F1C34B"
                stroke="#2D2D2D"
                strokeWidth="0.716027"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.7882 17.6021C16.7882 17.6021 18.5903 18.2247 19.0985 21.0282C16.5521 22.4246 14.6371 20.8996 14.6371 20.8996C14.6371 20.8996 15.8014 20.5202 16.7882 17.6021Z"
                fill="#F2EBD1"
                stroke="#2D2D2D"
                strokeWidth="0.716027"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.0094 19.215C21.5915 19.6561 21.0452 20.8357 20.4366 20.7547C20.9295 21.2871 20.4325 22.4954 19.6147 22.4659C19.7141 22.6065 19.772 22.7721 19.782 22.944C19.792 23.1159 19.7536 23.2872 19.6712 23.4383C19.5887 23.5895 19.4656 23.7145 19.3157 23.7992C19.1658 23.8839 18.9951 23.9249 18.8231 23.9175"
                stroke="#2D2D2D"
                strokeWidth="0.716027"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.1611 20.8591C22.7087 21.2547 22.1932 22.4817 21.4394 22.3711C21.8329 22.979 21.4202 23.9147 20.6478 23.8227"
                stroke="#2D2D2D"
                strokeWidth="0.716027"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default AllPreferencesIcon;

AllPreferencesIcon.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number.isRequired,
};

AllPreferencesIcon.defaultProps = {
    className: null,
};
