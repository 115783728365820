import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const baseClasses = 'transition-all leading-none flex items-center justify-center';

const colorClasses = {
    primary: 'text-white font-grotesk-bold',
    secondary: 'bg-white border-2 font-grotesk-bold',
    offWhite: 'bg-off-white pt-[2px]',
    plusMembership: 'text-white bg-blueberry font-grotesk-bold',
    plusMembershipLight: 'bg-gradient-berry-blend-light font-grotesk-bold',
    error: 'bg-error bg-opacity-50 font-grotesk-bold',
};

export const sizeClasses = {
    sm: 'px-15 text-body-xs h-30',
    md: 'px-15 w-full text-body-sm h-[44px]',
    lg: 'px-15 w-full text-[1.125rem] h-[56px]',
    custom: '',
};

export const roundedClasses = {
    md: 'rounded-md',
    full: 'rounded-full',
};

export const enabledClasses = {
    primary: 'bg-kale if:bg-beet hover:bg-kale-hover hover:if:bg-beet-hover hover:text-white',
    secondary: 'hover:bg-off-white text-black hover:text-black',
    plusMembership: 'hover:bg-blueberry-hover',
    error: 'text-black',
};

export const disabledClasses = {
    primary: 'cursor-not-allowed bg-grey-light !text-grey',
    secondary: 'cursor-not-allowed border-grey-light !text-grey',
    plusMembership: 'cursor-not-allowed bg-grey-light !text-grey',
    error: 'cursor-not-allowed !text-grey',
};

const wrapperClasses = '-mt-[3px] block';

function renderExternalLink({ as, children, customClass, disabled, rounded, size, to, variant, ...attrs }) {
    return (
        <a
            className={clsx([
                baseClasses,
                colorClasses[variant],
                roundedClasses[rounded],
                sizeClasses[size],
                {
                    [enabledClasses[variant]]: !disabled,
                    [disabledClasses[variant]]: disabled,
                },
                customClass,
            ])}
            href={!disabled ? to : null}
            {...(disabled && {
                'aria-disabled': true,
                role: 'link',
            })}
            {...attrs}
        >
            <span className={variant !== 'offWhiite' && wrapperClasses}>{children}</span>
        </a>
    );
}

function Button({
    as,
    children,
    customClass,
    disabled,
    disabledVisuallyOnly,
    rounded,
    size,
    submitType,
    to,
    variant,
    ...attrs
}) {
    switch (as) {
        case 'link': {
            // RR Link can't be disabled, so render a normal link in this case
            if (disabled) return renderExternalLink({ as, children, disabled, size, to, variant, ...attrs });
            return (
                <Link
                    className={clsx([
                        baseClasses,
                        colorClasses[variant],
                        roundedClasses[rounded],
                        sizeClasses[size],
                        {
                            [enabledClasses[variant]]: !disabled,
                        },
                        customClass,
                    ])}
                    to={to}
                    {...attrs}
                >
                    <span className={variant !== 'offWhiite' && wrapperClasses}>{children}</span>
                </Link>
            );
        }
        case 'a': {
            return renderExternalLink({ as, children, customClass, disabled, size, rounded, to, variant, ...attrs });
        }
        // useful when nesting a <Button /> (presentation) inside an element that's already interactive
        case 'span': {
            return (
                <span
                    className={clsx([
                        baseClasses,
                        colorClasses[variant],
                        roundedClasses[rounded],
                        sizeClasses[size],
                        {
                            [enabledClasses[variant]]: !disabled,
                            [disabledClasses[variant]]: disabled,
                        },
                        customClass,
                    ])}
                    {...attrs}
                >
                    <span className={wrapperClasses}>{children}</span>
                </span>
            );
        }
        case 'button':
        default: {
            return (
                <button
                    className={clsx([
                        baseClasses,
                        colorClasses[variant],
                        roundedClasses[rounded],
                        sizeClasses[size],
                        {
                            [enabledClasses[variant]]: !disabled,
                            [disabledClasses[variant]]: disabled,
                        },
                        customClass,
                    ])}
                    type={submitType ? 'submit' : 'button'}
                    disabled={disabled && !disabledVisuallyOnly}
                    {...attrs}
                >
                    <span className={variant !== 'offWhiite' && wrapperClasses}>{children}</span>
                </button>
            );
        }
    }
}

Button.propTypes = {
    as: PropTypes.oneOf(['button', 'link', 'a', 'span']),
    children: PropTypes.node.isRequired,
    customClass: PropTypes.string,
    disabled: PropTypes.bool,
    disabledVisuallyOnly: PropTypes.bool,
    rounded: PropTypes.oneOf(Object.keys(roundedClasses)),
    size: PropTypes.oneOf(Object.keys(sizeClasses)),
    submitType: PropTypes.bool,
    to: PropTypes.string,
    variant: PropTypes.oneOf(Object.keys(colorClasses)),
};

Button.defaultProps = {
    as: 'button',
    customClass: undefined,
    disabled: false,
    disabledVisuallyOnly: false,
    rounded: 'md',
    size: 'lg',
    submitType: false,
    to: null,
    variant: 'primary',
};

export default Button;
