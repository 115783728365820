import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

import useCustomerExistsQuery from 'api/queries/useCustomerExistsQuery';
import { LoginErrors } from 'constants/login';
import useAuth from 'hooks/useAuth';
import useGlobalStore from 'hooks/useGlobalStore';
import logError from 'utils/errorUtils';
import { getIsSisterBrand, validatePassword, validConfirmationCode } from 'utils/loginUtils';
import { setSignupLocalStorage } from 'utils/signupUtils';

import ConfirmationCodeForm from '../shared/ConfirmationCodeForm/ConfirmationCodeForm';
import ForgotPasswordForm from '../shared/ForgotPasswordForm/ForgotPasswordForm';
import LoginError from '../shared/LoginError/LoginError';

function ForgotPassword() {
    const { isImperfectTheme } = useGlobalStore();

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [sent, setSent] = useState(false);
    const [resent, setResent] = useState(false);
    const [code, setCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(LoginErrors.NoError);
    const [signInSuccess, setSignInSuccess] = useState(false);

    const { authStatus } = useAuth();

    const customerExistsQuery = useCustomerExistsQuery({
        email,
        extendedQueryKeys: ['forgot-password'],
        queryProps: { enabled: false },
    });

    const { data: customerExists } = customerExistsQuery;

    /**
     * Redirect, after successful password reset & sign in.
     */
    useEffect(() => {
        if (authStatus === 'authenticated' && signInSuccess) {
            if (customerExists?.subExists) {
                window.location.href = '/shop';
            } else {
                setSignupLocalStorage({ path: '/join/delivery' });
                navigate('/join/delivery');
            }
        }
    }, [authStatus, signInSuccess]);

    const submitForgotPassword = async () => {
        try {
            setLoading(true);
            await Auth.forgotPassword(email.trim().toLowerCase());
            setSent(true);
            setError(LoginErrors.NoError);
            setLoading(false);
        } catch (e) {
            logError(e);
            setError(LoginErrors.CodeSubmit);
            setLoading(false);
        }
    };

    const confirmForgotPassword = async () => {
        try {
            setLoading(true);
            await Auth.forgotPasswordSubmit(email.trim().toLowerCase(), code, newPassword);

            const authResp = await Auth.signIn({
                username: email.trim().toLowerCase(),
                password: newPassword,
            });

            if (authResp?.signInUserSession?.idToken?.jwtToken) {
                setEmail('');
                setNewPassword('');
                setError(LoginErrors.NoError);
                setSignInSuccess(true);
            } else {
                setError(LoginErrors.PasswordSubmit);
            }
            setLoading(false);
        } catch (e) {
            logError(e);
            if (e?.message?.includes('Invalid verification code')) {
                setError(LoginErrors.InvalidCode);
            } else {
                setError(LoginErrors.PasswordSubmit);
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        if (customerExistsQuery?.fetchStatus === 'fetching' || typeof customerExists === 'undefined') {
            return;
        }

        if (getIsSisterBrand(email, customerExists?.brand, isImperfectTheme)) {
            setError(LoginErrors.WrongSite);
        } else {
            submitForgotPassword();
        }
    }, [customerExistsQuery?.fetchStatus]);

    const resetSubmit = async (e) => {
        e.preventDefault();

        if (email !== '') {
            customerExistsQuery?.refetch();
        } else {
            setError(LoginErrors.NoEmail);
        }
    };

    const codeSubmit = async (e) => {
        e.preventDefault();

        if (!validConfirmationCode(code)) {
            setError(LoginErrors.NoCode);
        } else if (newPassword === '') {
            setError(LoginErrors.NoPassword);
        } else if (!validatePassword(newPassword)) {
            setError(LoginErrors.InvalidPassword);
        } else if (newPassword !== confirmPassword) {
            setError(LoginErrors.PasswordMatch);
        } else {
            confirmForgotPassword();
        }
    };

    const resendCode = async () => {
        await submitForgotPassword();
        setResent(true);
    };

    return (
        <>
            <h1 className="text-body-lg mb-20 text-center font-grotesk-bold">Reset Password</h1>

            <LoginError error={error} />

            {sent ? (
                <ConfirmationCodeForm
                    code={code}
                    codeSubmit={codeSubmit}
                    confirmPassword={confirmPassword}
                    email={email}
                    error={error}
                    loading={loading}
                    newPassword={newPassword}
                    resendCode={resendCode}
                    resent={resent}
                    setCode={setCode}
                    setConfirmPassword={setConfirmPassword}
                    setError={setError}
                    setNewPassword={setNewPassword}
                    setSent={setSent}
                    setShowConfirmPassword={setShowConfirmPassword}
                    setShowNewPassword={setShowNewPassword}
                    showConfirmPassword={showConfirmPassword}
                    showNewPassword={showNewPassword}
                />
            ) : (
                <ForgotPasswordForm
                    email={email}
                    loading={loading}
                    resetSubmit={resetSubmit}
                    setEmail={setEmail}
                    setError={setError}
                />
            )}
        </>
    );
}

export { ForgotPassword as Component };
