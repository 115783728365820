import { useQuery } from '@tanstack/react-query';

import { getOrders } from 'api/jalapeno';

import useAccountQuery from './useAccountQuery';

export const ORDERS_QUERY_KEY = 'orders';

const useOrdersQuery = (page = 0, pageSize = 20) => {
    const { data: account } = useAccountQuery();

    const query = useQuery({
        queryKey: [ORDERS_QUERY_KEY, account?.customerID, page, pageSize],
        queryFn: async () => {
            const response = await getOrders(account?.customerID, page, pageSize);
            return response?.data;
        },
        enabled: !!account?.customerID,
    });

    return query;
};

export default useOrdersQuery;
