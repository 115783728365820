import { MinusIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import PropTypes from 'prop-types';

function QuantityButton({
    disabled,
    fullWidth,
    label,
    maxQuantity,
    minQuantity,
    noAdd,
    onAdd,
    onKeyDown,
    onRemove,
    points,
    priceStepper,
    quantity,
    removeAdd,
    removeSingle,
    renderAddText,
    size,
    variant,
}) {
    const isPerk = !(points === undefined || points === null);

    if ((quantity <= 0 || (quantity > 0 && !onAdd && removeSingle)) && !noAdd) {
        return (
            <button
                className={clsx(
                    'group flex items-center gap-5 px-10 font-grotesk-bold transition-all duration-200',
                    {
                        'h-30 w-min': size === 'sm',
                        'text-body-sm rounded-l-full rounded-r-full': size === 'sm' || size === 'md',
                        'w-full': fullWidth,
                        'h-[42px] justify-between md:w-min': size === 'md',
                        'text-body-lg h-[56px] w-full justify-center rounded-[5px]': size === 'lg',
                        'bg-kale text-white hover:bg-kale-hover if:bg-beet if:hover:bg-beet-hover':
                            (variant === 'primary' || priceStepper) && !disabled,
                        'bg-off-white text-black hover:bg-oat': variant === 'secondary' && !priceStepper && !disabled,
                        'border-2 border-black bg-white text-black hover:bg-mango': variant === 'bordered' && !disabled,
                        'justify-between px-[16px]': isPerk,
                        'cursor-not-allowed bg-grey-light': disabled,
                    },
                    { '!w-[42px] !justify-center !rounded-full': !renderAddText }
                )}
                disabled={disabled}
                onClick={!onAdd ? onRemove : onAdd}
                onKeyDown={onKeyDown}
                type="button"
                aria-label={`${!onAdd ? 'Remove' : 'Add'} ${label}`}
            >
                {!onAdd && <TrashIcon className="z-10 mx-15 w-15 stroke-[2px]" />}
                {(size !== 'lg' || !isPerk) && <PlusIcon className="w-15 stroke-[3px] group-disabled:text-grey" />}
                {!!onAdd && isPerk && (
                    <>
                        <span>Redeem</span>
                        {size === 'lg' && <span>{points} pts</span>}
                    </>
                )}
                {renderAddText && !!onAdd && !isPerk && <span className={clsx({ 'text-grey': disabled })}>Add</span>}
                <span className="sr-only" aria-label={`${label} value`}>
                    {quantity} {label}
                </span>
            </button>
        );
    }

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
        <div
            className={clsx('flex cursor-auto items-center overflow-hidden font-grotesk-bold', {
                'h-30 w-min': size === 'sm',
                'text-body-sm  gap-5 rounded-l-full rounded-r-full': size === 'sm' || size === 'md',
                'w-full': fullWidth,
                'h-[42px] justify-between': size === 'md',
                'text-body-lg h-[56px] w-full gap-[10%] rounded-[5px]': size === 'lg',
                'bg-kale text-white if:bg-beet': variant === 'primary' && !disabled,
                'bg-off-white text-black': variant === 'secondary' && !disabled,
                'border-2 border-black bg-white text-black': variant === 'bordered' && !disabled,
                'cursor-not-allowed bg-grey-light': disabled,
            })}
            onClick={(e) => {
                // prevent parent event from firing
                e.stopPropagation();
            }}
        >
            <button
                className={clsx(
                    'relative flex h-full items-center after:absolute after:left-0 after:h-full after:w-full after:bg-gradient-to-r after:opacity-0 after:transition-all after:duration-200 after:content-[""]',
                    {
                        'w-30 justify-center rounded-l-full': size === 'sm' || size === 'md',
                        'grow justify-start rounded-l-[5px] pl-20': size === 'lg',
                        'opacity-0': quantity <= minQuantity,
                        'hover:after:opacity-100': !disabled,
                        'after:from-kale-hover if:after:from-beet-hover': variant === 'primary',
                        'after:from-oat': variant === 'secondary' && !priceStepper,
                        'after:from-mango': variant === 'bordered',
                    }
                )}
                disabled={disabled || quantity <= minQuantity}
                onClick={onRemove}
                onKeyDown={onKeyDown}
                type="button"
                aria-label={`Remove ${label}`}
            >
                <div
                    className={clsx({
                        'bg-brand-primary rounded-full p-5 ': noAdd,
                        'z-10 hover:bg-kale-hover if:hover:bg-beet-hover': priceStepper,
                    })}
                >
                    {quantity === 1 && !priceStepper ? (
                        <TrashIcon className="z-10 w-15 stroke-[2px]" />
                    ) : (
                        <MinusIcon
                            className={clsx('z-10 stroke-[2px]', { 'w-25 text-white': noAdd, 'w-15': !noAdd })}
                        />
                    )}
                </div>
            </button>
            <span className={clsx({ 'text-grey': disabled, '-ml-20': noAdd })} aria-label={`${label} value`}>
                {priceStepper ? `$${quantity?.toFixed(2)}` : quantity} <span className="sr-only">{label}</span>
            </span>
            <button
                aria-label={`Add ${label}`}
                className={clsx(
                    'relative flex h-full w-30 items-center after:absolute after:right-0 after:h-full after:w-full after:bg-gradient-to-l after:opacity-0 after:transition-all after:duration-200 after:content-[""]',
                    {
                        'justify-center rounded-r-full': size === 'sm' || size === 'md',
                        'grow justify-end rounded-r-[5px] pr-20': size === 'lg',
                        'opacity-0': typeof maxQuantity === 'number' && quantity >= maxQuantity,
                        'hover:after:opacity-100': !disabled,
                        'after:from-kale-hover if:after:from-beet-hover': variant === 'primary',
                        'after:from-oat': variant === 'secondary' && !priceStepper,
                        'after:from-mango': variant === 'bordered',
                        'after:from-transparent': removeAdd,
                    }
                )}
                disabled={disabled || (maxQuantity && quantity >= maxQuantity) || removeAdd}
                onClick={onAdd}
                onKeyDown={onKeyDown}
                type="button"
            >
                <div
                    className={clsx({
                        'bg-brand-primary rounded-full p-5': noAdd,
                        'z-10 hover:bg-kale-hover if:hover:bg-beet-hover': priceStepper,
                    })}
                >
                    {!removeAdd && (
                        <PlusIcon className={clsx('z-10 stroke-[3px]', { 'w-25 text-white': noAdd, 'w-15': !noAdd })} />
                    )}
                </div>
            </button>
        </div>
    );
}

export default QuantityButton;

QuantityButton.propTypes = {
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    maxQuantity: PropTypes.number,
    minQuantity: PropTypes.number,
    noAdd: PropTypes.bool,
    onAdd: PropTypes.func,
    onKeyDown: PropTypes.func,
    onRemove: PropTypes.func.isRequired,
    points: PropTypes.number,
    priceStepper: PropTypes.bool,
    quantity: PropTypes.number.isRequired,
    removeAdd: PropTypes.bool,
    removeSingle: PropTypes.bool,
    renderAddText: PropTypes.bool,
    size: PropTypes.oneOf(['lg', 'md', 'sm']),
    variant: PropTypes.oneOf(['primary', 'secondary', 'bordered']),
};

QuantityButton.defaultProps = {
    disabled: false,
    fullWidth: true,
    label: 'quantity',
    maxQuantity: null,
    minQuantity: 0,
    noAdd: false,
    onAdd: null,
    onKeyDown: () => {},
    points: null,
    priceStepper: false,
    removeAdd: false,
    removeSingle: false,
    renderAddText: true,
    size: 'sm',
    variant: 'primary',
};
