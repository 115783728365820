import { useEffect, useMemo } from 'react';
import clsx from 'clsx';
import useEmblaCarousel from 'embla-carousel-react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import Button from 'honeydew/Button';
import useCarouselSlideWidth from 'hooks/useCarouselSlideWidth';
import useTailwindTheme from 'hooks/useTailwindTheme';
import { trackEvent } from 'utils/analyticsUtils';
import { formatCarouselID } from 'utils/shopUtils';
import twclsx from 'utils/twclsx';

import { NextButton, PrevButton, usePrevNextButtons } from './PrevNextButtons';

import styles from './carousel.module.css';

const bgColorClasses = {
    brand: 'bg-lime if:bg-strawberry',
    default: 'bg-malt if:bg-oat',
    white: 'bg-white',
};

function Carousel({
    bgColor,
    carousel,
    carouselIndex,
    children,
    className,
    ctaOnClick,
    ctaText,
    ctaTo,
    ctaVariant,
    onSeeAllClick,
    onSlideChange,
    restoreScrollPosition,
    slideOverride,
    upSellsCarousel,
    v2ProductCards,
}) {
    const tailwindTheme = useTailwindTheme();
    const mobile = useMediaQuery({ maxWidth: tailwindTheme?.screens?.md });
    const { description, featured, header, linkTitle, linkUrl, name, photoUrlLarge, photoUrlSmall } = carousel;
    const validLink = linkTitle && linkUrl;
    const tabletOrLargerScreen = useMediaQuery({ minWidth: tailwindTheme?.screens?.md });
    const carouselID = formatCarouselID(name);

    // custom breakpoints for carousel slides
    const smDesktopScreen = useMediaQuery({ maxWidth: '910px' });
    const mdDesktopScreen = useMediaQuery({ maxWidth: '1110px' });
    const showCarouselImage = featured && photoUrlLarge && photoUrlSmall;
    const carouselLength = carousel.items?.length;

    const slidesToShow = useCarouselSlideWidth({ override: slideOverride });

    const displayArrows = slidesToShow < carousel.items?.length;
    const displaySeeAllButton = carousel.items?.length >= 8 && !upSellsCarousel && typeof onSeeAllClick === 'function';
    const displayArrowSize = upSellsCarousel ? 30 : 40;

    const getSmallCarouselBannerWidthClasses = () => {
        if (carouselLength === 1) {
            if (smDesktopScreen) return 'col-span-2';
            if (mdDesktopScreen) return 'col-span-3';
            return 'col-span-4';
        }

        if (carouselLength === 2) {
            if (smDesktopScreen) return 'col-span-1';
            if (mdDesktopScreen) return 'col-span-2';
            return 'col-span-3';
        }

        return '';
    };

    const getSmallCarouselWidthClasses = () => {
        if (carouselLength === 1) return 'col-span-1';
        if (carouselLength === 2) return 'col-span-2';
        return '';
    };

    const getCarouselGridWidthClasses = () => {
        if (smDesktopScreen) return 'grid-cols-3';
        if (mdDesktopScreen) return 'grid-cols-4';
        return 'grid-cols-5';
    };

    const carouselBannerWidthClasses = getSmallCarouselBannerWidthClasses();
    const carouselWidthClasses = getSmallCarouselWidthClasses();
    const carouselGridWidthClasses =
        carouselLength <= 2 && !mobile && showCarouselImage && !upSellsCarousel
            ? `wrapper-small grid ${getCarouselGridWidthClasses()}`
            : '';

    const carouselSingleItemWidthClasses = carouselLength === 1 && mobile ? `wrapper-small` : '';

    const restoredSlideIndex = useMemo(() => {
        if (name) {
            const carouselLocations =
                window.history.state && window.history.state.carouselLocations
                    ? window.history.state.carouselLocations
                    : {};
            return Object.prototype.hasOwnProperty.call(carouselLocations, name) ? carouselLocations[name] : undefined;
        }
        return undefined;
    }, [name]);

    const trackSlideChange = (slideIndex) => {
        window.history.replaceState(
            {
                ...window.history.state,
                carouselLocations: {
                    ...(window.history.state ? window.history.state.carouselLocations : {}),
                    [name]: slideIndex,
                },
            },
            ''
        );
    };

    const handleLinkClick = () => {
        trackEvent('carousel link click', {
            carouselLinkCTA: linkTitle,
            carouselLinkURL: linkUrl,
        });

        if (window.isNativeApp) {
            window.ReactNativeWebView?.postMessage(
                JSON.stringify({
                    navigate: 'ShopLink',
                    url: linkUrl,
                })
            );
        }
    };

    const [emblaRef, emblaApi] = useEmblaCarousel({
        loop: false,
        skipSnaps: true,
        align: 'start',
        slidesToScroll: 'auto',
        startIndex: restoredSlideIndex ?? 0,
    });

    const { nextBtnDisabled, onNextButtonClick, onPrevButtonClick, prevBtnDisabled } = usePrevNextButtons(emblaApi);

    useEffect(() => {
        if (emblaApi && name) {
            emblaApi.on('settle', () => {
                onSlideChange({ name, slideNumber: emblaApi.selectedScrollSnap() });
                if (restoreScrollPosition) {
                    trackSlideChange(emblaApi.selectedScrollSnap());
                }
            });
        }
    }, [emblaApi, name, restoredSlideIndex]);

    const headerText = header ?? name;

    // TEMPORARY: to facilitate testing
    const slideSize = v2ProductCards ? `0 0 145px` : `0 0 ${100 / slidesToShow}%`;

    return (
        <div className={className}>
            {showCarouselImage && carouselLength > 2 && (
                <img
                    className="w-full"
                    alt=""
                    height={mobile ? '300' : '500'}
                    width={mobile ? '750' : '1839'}
                    aria-hidden="true"
                    src={tabletOrLargerScreen ? photoUrlLarge : photoUrlSmall}
                    loading={carouselIndex > 2 ? 'lazy' : 'eager'}
                />
            )}
            <div
                className={clsx([`relative ${bgColorClasses[bgColor]}`], {
                    'pb-10': upSellsCarousel && mobile,
                    'pb-5': upSellsCarousel && !mobile,
                    'py-10': !upSellsCarousel,
                    'mb-20': bgColor !== 'white',
                })}
                id={carouselID}
            >
                <div className={clsx(carouselGridWidthClasses, carouselSingleItemWidthClasses)}>
                    <div className={carouselBannerWidthClasses}>
                        {showCarouselImage && carouselLength <= 2 && (
                            <div>
                                <img
                                    className={clsx(
                                        '-mt-10 w-full object-cover',
                                        { 'aspect-[4/1]': carouselLength === 1 },
                                        { 'aspect-[2.35/1]': carouselLength === 2 }
                                    )}
                                    alt=""
                                    height={mobile ? '300' : '500'}
                                    width={mobile ? '750' : '1839'}
                                    aria-hidden="true"
                                    src={tabletOrLargerScreen ? photoUrlLarge : photoUrlSmall}
                                    loading={carouselIndex > 2 ? 'lazy' : 'eager'}
                                />
                            </div>
                        )}
                        <div
                            className={twclsx('p-10', {
                                'p-5': upSellsCarousel && !mobile,
                                'md:px-0': v2ProductCards,
                            })}
                        >
                            <div className="flex items-center justify-between gap-20">
                                {headerText &&
                                    (upSellsCarousel ? (
                                        <p className="text-body-md -mb-10 ml-10 font-grotesk-bold md:ml-0">{name}</p>
                                    ) : (
                                        <h2 className="text-body-lg font-grotesk-bold">{headerText}</h2>
                                    ))}

                                <div className="flex gap-20">
                                    {displaySeeAllButton && (
                                        <button
                                            type="button"
                                            className="link mr-10 whitespace-nowrap"
                                            onClick={onSeeAllClick}
                                        >
                                            See All
                                        </button>
                                    )}
                                    {displayArrows && (
                                        <>
                                            <PrevButton
                                                className={clsx({ 'absolute left-10 top-[55%] z-10': upSellsCarousel })}
                                                onClick={onPrevButtonClick}
                                                disabled={prevBtnDisabled}
                                                size={displayArrowSize}
                                            />
                                            <NextButton
                                                className={clsx({
                                                    'absolute right-10 top-[55%] z-10': upSellsCarousel,
                                                })}
                                                onClick={onNextButtonClick}
                                                disabled={nextBtnDisabled}
                                                size={displayArrowSize}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="mt-10 flex flex-col gap-10">
                                {description && <p className="text-body-md">{description}</p>}
                                {validLink && (
                                    <Button
                                        {...(!window.isNativeApp && { as: 'a' })}
                                        className="link text-body-sm text-left"
                                        href={linkUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        size="md"
                                        onClick={handleLinkClick}
                                    >
                                        {linkTitle}
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>

                    <div
                        className={clsx(
                            styles.wrapper,
                            carouselWidthClasses,
                            upSellsCarousel && mobile && styles.upsells
                        )}
                    >
                        <div className="embla embla__viewport overflow-hidden" ref={emblaRef}>
                            <div className={clsx('embla__container mr-10 flex', { 'gap-15': v2ProductCards })}>
                                {children.map((child) => (
                                    <div
                                        className="embla__slide flex h-auto min-w-0"
                                        key={child.key}
                                        style={carouselLength <= 2 ? {} : { flex: slideSize }}
                                    >
                                        <div
                                            className={clsx('ml-10 flex w-full', {
                                                'shrink-0 md:ml-0': v2ProductCards && carouselLength > 2,
                                            })}
                                        >
                                            {child}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {ctaText && (ctaOnClick || ctaTo) && (
                        <div className="mx-auto w-fit pb-5 pt-20">
                            <Button as={ctaTo ? 'link' : 'button'} onClick={ctaOnClick} to={ctaTo} variant={ctaVariant}>
                                {ctaText}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

Carousel.propTypes = {
    bgColor: PropTypes.oneOf(Object.keys(bgColorClasses)),
    carousel: PropTypes.shape({
        description: PropTypes.string,
        featured: PropTypes.bool.isRequired,
        header: PropTypes.string,
        linkTitle: PropTypes.string,
        linkUrl: PropTypes.string,
        name: PropTypes.string,
        photoUrlLarge: PropTypes.string,
        photoUrlSmall: PropTypes.string,
    }).isRequired,
    carouselIndex: PropTypes.number,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    ctaOnClick: PropTypes.func,
    ctaText: PropTypes.string,
    ctaTo: PropTypes.string,
    ctaVariant: PropTypes.oneOf(['primary', 'secondary']),
    onSeeAllClick: PropTypes.func,
    onSlideChange: PropTypes.func,
    restoreScrollPosition: PropTypes.bool,
    slideOverride: PropTypes.number,
    upSellsCarousel: PropTypes.bool,
    v2ProductCards: PropTypes.bool,
};

Carousel.defaultProps = {
    bgColor: 'default',
    carouselIndex: 0,
    className: undefined,
    ctaOnClick: undefined,
    ctaText: undefined,
    ctaTo: undefined,
    ctaVariant: 'primary',
    onSeeAllClick: undefined,
    onSlideChange: () => {},
    restoreScrollPosition: false,
    slideOverride: 0,
    upSellsCarousel: false,
    v2ProductCards: false,
};

export default Carousel;
