import { TextField as MuiTextField } from '@mui/material';
import PropTypes from 'prop-types';

import styles from './textfield.module.css';

// be sure to include label, id, and name props (which are passed through ...rest)
// to render a textarea component, pass in the multiline prop
function TextField({ error, errorMsg, inputProps, maxLength, pattern, width, ...rest }) {
    return (
        <div className={styles['text-field']}>
            <MuiTextField
                InputLabelProps={{ shrink: true }}
                style={{ width }}
                inputProps={{ maxLength, pattern, ...inputProps }}
                error={error}
                {...rest}
            />
            {error && errorMsg !== '' && <p className="text-body-md mt-5 font-grotesk-bold text-error">{errorMsg}</p>}
        </div>
    );
}

TextField.propTypes = {
    error: PropTypes.bool,
    errorMsg: PropTypes.string,
    inputProps: PropTypes.shape({}),
    maxLength: PropTypes.number,
    pattern: PropTypes.string,
    width: PropTypes.string,
};

TextField.defaultProps = {
    error: false,
    errorMsg: '',
    inputProps: {},
    maxLength: null,
    pattern: null,
    width: '100%',
};
export default TextField;
