function IFUpgrade() {
    // old IF app users that need to download hazelnut, TODO: remove when all app users are updated

    const modal = document.createElement('div');
    const text = document.createElement('h1');
    modal.appendChild(text);
    text.innerText = 'You will have to update your app to the latest version to continue.';
    text.style.marginLeft = '15px';
    text.style.marginRight = '15px';
    text.style.marginTop = '20px';
    text.style.marginBottom = '20px';
    modal.style.textAlign = 'center';
    modal.style.transform = 'translate(-50%, -50%)';
    modal.style.zIndex = 2147483002;
    modal.style.position = 'absolute';
    modal.style.top = '45%';
    modal.style.left = '50%';
    modal.style.width = '220px';
    modal.style.backgroundColor = 'white';
    modal.style.borderWidth = '1px;';
    modal.style.borderColor = 'gray';
    modal.style.borderRadius = '4px';
    const bg = document.createElement('div');
    bg.style.width = '100%';
    bg.style.height = '100%';
    bg.style.position = 'absolute';
    bg.style.top = '0';
    bg.style.left = '0';
    bg.style.zIndex = 2147483001;
    bg.style.background = 'rgba(0,0,0,0.5)';
    document.body.appendChild(modal);
    document.body.appendChild(bg);
}

export default IFUpgrade;
