import PropTypes from 'prop-types';

function ShoppingCartIcon({ width }) {
    return (
        <svg width={width} viewBox="0 0 61 57" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5262_63463)">
                <path
                    d="M31.0516 56.1723C45.9166 52.5499 60.5078 43.8186 60.5078 28.4998C60.5078 13.181 48.076 3.84731 31.0516 0.702179C15.9909 -2.07524 6.10179 12.336 3.31655 28.4372C0.711252 43.5291 16.8594 59.6225 31.0516 56.1723Z"
                    className="fill-mango if:fill-[#FFCBDC]"
                />
                <path
                    d="M18.3068 21.5602L17.1254 17.6952C18.3693 17.2962 44.4536 14.8474 46.6833 14.8239C48.9131 14.8005 49.8128 16.0131 49.9223 17.4449C50.0319 18.8766 48.1229 31.0268 47.7004 32.1535C47.2779 33.2801 46.4095 34.7822 44.9543 35.0717C43.4991 35.3612 27.5074 36.9103 25.6219 37.0354C23.7364 37.1606 18.651 23.2031 18.3068 21.5602Z"
                    fill="white"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M22.5941 17.1398C22.5941 17.1398 23.7051 19.7764 26.1696 26.1214C28.634 32.4664 30.2222 36.6208 30.2222 36.6208"
                    fill="white"
                />
                <path
                    d="M22.5941 17.1398C22.5941 17.1398 23.7051 19.7764 26.1696 26.1214C28.634 32.4664 30.2222 36.6208 30.2222 36.6208"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M33.3517 16.0444C33.3517 16.0444 33.7272 19.0409 34.2514 24.8774C34.7756 30.7139 35.2059 32.3021 35.4171 36.0888"
                    fill="white"
                />
                <path
                    d="M33.3517 16.0444C33.3517 16.0444 33.7272 19.0409 34.2514 24.8774C34.7756 30.7139 35.2059 32.3021 35.4171 36.0888"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M42.6542 15.1213C42.6542 15.1213 42.8889 14.511 42.7715 22.4677C42.6354 26.8585 42.1408 31.2309 41.2928 35.5411"
                    fill="white"
                />
                <path
                    d="M42.6542 15.1213C42.6542 15.1213 42.8889 14.511 42.7715 22.4677C42.6354 26.8585 42.1408 31.2309 41.2928 35.5411"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19.269 24.4471C20.3096 24.0559 25.3872 22.9763 32.4285 22.6086C39.4699 22.2409 46.3547 21.302 49.6407 21.0438"
                    fill="white"
                />
                <path
                    d="M19.269 24.4471C20.3096 24.0559 25.3872 22.9763 32.4285 22.6086C39.4699 22.2409 46.3547 21.302 49.6407 21.0438"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M22.3047 32.0439C23.5956 31.7779 29.4086 31.0346 38.3198 30.4165C47.231 29.7985 48.3107 29.2821 48.3107 29.2821"
                    fill="white"
                />
                <path
                    d="M22.3047 32.0439C23.5956 31.7779 29.4086 31.0346 38.3198 30.4165C47.231 29.7985 48.3107 29.2821 48.3107 29.2821"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.3067 21.5602C18.3067 21.5602 16.3117 13.9164 14.8487 13.2045C13.3856 12.4925 11.148 13.0089 9.54419 13.4392"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M25.6219 37.0354C24.9404 37.1748 24.2504 37.2689 23.5564 37.3171C21.2093 37.5596 21.5457 41.7687 24.0024 41.7296C26.459 41.6905 43.5225 40.2822 44.4535 40.0788"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M44.7587 46.1422C46.4353 46.1422 47.7943 44.7832 47.7943 43.1066C47.7943 41.4301 46.4353 40.071 44.7587 40.071C43.0822 40.071 41.7231 41.4301 41.7231 43.1066C41.7231 44.7832 43.0822 46.1422 44.7587 46.1422Z"
                    className="fill-[#B0C372] if:fill-[#C5DB66]"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M28.4462 47.793C30.1228 47.793 31.4818 46.4339 31.4818 44.7574C31.4818 43.0809 30.1228 41.7218 28.4462 41.7218C26.7697 41.7218 25.4106 43.0809 25.4106 44.7574C25.4106 46.4339 26.7697 47.793 28.4462 47.793Z"
                    className="fill-[#B0C372] if:fill-[#C5DB66]"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.1681 10.8291L3.17878 11.3359C1.89774 11.466 0.964739 12.61 1.09486 13.8911L1.12095 14.1479C1.25108 15.429 2.39505 16.362 3.67609 16.2318L8.66541 15.725C9.94645 15.5949 10.8795 14.4509 10.7493 13.1699L10.7232 12.913C10.5931 11.632 9.44914 10.699 8.1681 10.8291Z"
                    className="fill-[#B0C372] if:fill-[#C5DB66]"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_5262_63463">
                    <rect width="60" height="56.2837" fill="white" transform="translate(0.5 0.35791)" />
                </clipPath>
            </defs>
        </svg>
    );
}

ShoppingCartIcon.propTypes = {
    width: PropTypes.number,
};

ShoppingCartIcon.defaultProps = {
    width: 61,
};

export default ShoppingCartIcon;
