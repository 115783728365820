import {
    IMPERFECT_THEME_BODY_CLASS,
    IMPERFECT_THEME_DOMAIN,
    MISFITS_THEME_BODY_CLASS,
    MISFITS_THEME_DOMAIN,
} from 'constants/theme';

import { getBrandDomain } from './utils';

// https://stackoverflow.com/a/73208485
window.global ||= window;

// Init brand theme
const brandDomain = getBrandDomain();
let bodyThemeClass = '';

if (brandDomain === MISFITS_THEME_DOMAIN) {
    bodyThemeClass = MISFITS_THEME_BODY_CLASS;
} else if (brandDomain === IMPERFECT_THEME_DOMAIN) {
    bodyThemeClass = IMPERFECT_THEME_BODY_CLASS;
} else {
    bodyThemeClass = MISFITS_THEME_BODY_CLASS;
    // eslint-disable-next-line no-console
    console.warn('Unrecognized brand domain: falling back to Misfits Market theme.');
}

document.body.classList.add(bodyThemeClass);
