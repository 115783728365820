import { Drawer as MuiDrawer } from '@mui/material';
import PropTypes from 'prop-types';

import styles from './drawer.module.css';

export function Drawer({ anchor, children, open, toggleDrawer, ...rest }) {
    return (
        <MuiDrawer
            className={styles.drawer}
            role="presentation"
            anchor={anchor}
            open={open}
            onClose={() => toggleDrawer(false)}
            transitionDuration={window.isNativeApp ? 0 : 225}
            {...rest}
        >
            {children}
        </MuiDrawer>
    );
}

Drawer.propTypes = {
    anchor: PropTypes.oneOf(['left', 'right', 'top', 'bottom']).isRequired,
    children: PropTypes.node.isRequired,
    open: PropTypes.bool.isRequired,
    toggleDrawer: PropTypes.func.isRequired,
};
