import {
    BOX_WITH_HEART_ICON_CROPPED_URL,
    COIN_WITH_STAR_ICON_URL,
    HAND_HOLDING_BOWL_ICON_CROPPED_URL,
    RIBBON_WITH_COIN_ICON_URL,
    TRUCK_ICON_CROPPED_URL,
} from 'honeydew/icons/PlusMembership';
import { getResizedCDNAsset } from 'utils/globalUtils';

export const boxWithHeartBg = `url(${getResizedCDNAsset({
    url: BOX_WITH_HEART_ICON_CROPPED_URL,
    width: 240,
})}) left bottom / contain no-repeat`;

export const coinWithStar = `url(${getResizedCDNAsset({
    url: COIN_WITH_STAR_ICON_URL,
    width: 200,
})}) right -4px center / 75px auto no-repeat`;

export const ribbonWithCoinBg = `url(${getResizedCDNAsset({
    url: RIBBON_WITH_COIN_ICON_URL,
    width: 206,
})}) right -4px center / 103px auto no-repeat`;

export const truckBg = `url(${getResizedCDNAsset({
    url: TRUCK_ICON_CROPPED_URL,
    width: 240,
})}) right bottom / 120px auto no-repeat`;

export const handHoldingBowlBg = `url(${getResizedCDNAsset({
    url: HAND_HOLDING_BOWL_ICON_CROPPED_URL,
    width: 200,
})}) right bottom -10px / contain no-repeat`;
