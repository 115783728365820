import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Outlet, useParams } from 'react-router-dom';

import { getCatalogItem } from 'api/jalapeno';
import Loader from 'honeydew/Loader';
import useTrackPageView from 'hooks/useTrackPageView';
import SEO from 'shared/SEO';

export function AcquisitionPDPLayoutSEO({ productData }) {
    // Strips the size by removing the last comma and everything after it
    // Ex: Organic Dark Maple Syrup, Pure 100% Grade A, Robust Flavor, 16 Fl Oz -> Organic Dark Maple Syrup, Pure 100% Grade A, Robust Flavor
    const productNameWithoutSize = productData.name.replace(/,([^,]+)$/, '');

    const pageTitle = productData.brand
        ? `Get ${productData.brand} ${productNameWithoutSize} Delivered | Misfits Market`
        : `Get ${productNameWithoutSize} Delivered | Misfits Market`;

    return (
        <SEO
            title={pageTitle}
            description={`Order ${productData.name} near you with Misfits Market’s online grocery delivery. Get high-quality groceries delivered straight to your door.`}
        />
    );
}

function AcquisitionPDPLayout() {
    const params = useParams();

    const productSlug = params?.slug;

    const productQuery = useQuery({
        queryKey: ['acquisition-product', productSlug],
        queryFn: () => getCatalogItem({ slug: productSlug }),
        enabled: !!productSlug,
    });

    const productData = productQuery?.data?.data?.item;

    useTrackPageView(
        'ungated product page view',
        true,
        {
            product_brand: productData?.brand,
            product_name: productData?.name,
            external_sku: productData?.externalSku,
            l1_taxonomy_name: productData?.l1TaxonomyName,
        },
        (productQuery.isSuccess && !!productData?.id) || productQuery.isError
    );

    useEffect(() => {
        if (!productQuery.isLoading) {
            window.scrollTo({ top: 0 });
        }
    }, [productQuery]);

    if (!productSlug || productQuery?.data?.msg === 'NotFound') {
        return (
            <div className="bg-[#f2ebd1] p-20 text-center">
                <img className="mx-auto" src="https://img-cdn.misfitsmarket.com/images/404error.gif" alt="404" />
                <p className="text-body-md my-10">Sorry, we could not find that product.</p>
            </div>
        );
    }

    if (productQuery.isLoading) {
        return (
            <div className="flex h-[500px] items-center justify-center bg-white md:h-[600px]">
                <Loader />
            </div>
        );
    }

    if (productQuery.isError) {
        return (
            <div className="my-40 px-10 text-center">
                <p>Sorry, there was an error.</p>
            </div>
        );
    }

    const pdpStructuredData = {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: productData.name,
        image: [productData?.photoMed],
        description: productData.desc,
        url: `https://misfitsmarket.com/${params.slug}/p`,
        offers: {
            '@type': 'Offer',
            url: `https://misfitsmarket.com/${params.slug}/p`,
            priceCurrency: 'USD',
        },
        brand: { '@type': 'Brand', name: productData.brand },
        productId: productData.id,
    };

    if (productData.price) {
        pdpStructuredData.offers.price = productData.price;
        pdpStructuredData.offers.priceValidUntil = 'Sat, 01 Feb 2025 20:15:39 GMT'; // TODO: need date from BE
    }

    return (
        <>
            {/* eslint-disable react/no-danger */}
            <script
                data-testid="ldjson"
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(pdpStructuredData),
                }}
            />
            {/* eslint-enablereact/no-danger */}
            <AcquisitionPDPLayoutSEO productData={productData} />
            <Outlet context={{ productData }} />
        </>
    );
}

export default AcquisitionPDPLayout;
