import PropTypes from 'prop-types';

function DoubleArrowNextIcon({ disabled, width }) {
    return disabled ? (
        <svg width={width} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.06397 11.7941L1.05753 11.8311L1.0539 11.8685C0.657329 15.9492 2.49566 18.877 4.87 20.6657C7.17206 22.3999 10.0718 23.1333 12.042 22.7889C17.7592 22.0599 23.3459 18.1227 23.3459 11.9883C23.3459 8.97042 22.107 6.51654 20.0492 4.69078C18.0153 2.88615 15.2142 1.71923 12.0732 1.13942C8.99362 0.570724 6.4466 1.7875 4.59239 3.86541C2.76543 5.91277 1.58218 8.8113 1.06397 11.7941Z"
                fill="#C3C3C3"
                stroke="#C3C3C3"
                strokeWidth="2"
            />
            <path
                d="M11.9336 7.69922L17.3481 11.9291L11.9336 16.1591"
                stroke="#2D2D2D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.93359 7.69922L12.3481 11.9291L6.93359 16.1591"
                stroke="#2D2D2D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ) : (
        <svg width={width} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.06397 11.7941L1.05753 11.8311L1.0539 11.8685C0.657329 15.9492 2.49566 18.877 4.87 20.6657C7.17206 22.3999 10.0718 23.1333 12.042 22.7889C17.7592 22.0599 23.3459 18.1227 23.3459 11.9883C23.3459 8.97042 22.107 6.51654 20.0492 4.69078C18.0153 2.88615 15.2142 1.71923 12.0732 1.13942C8.99362 0.570724 6.4466 1.7875 4.59239 3.86541C2.76543 5.91277 1.58218 8.8113 1.06397 11.7941Z"
                fill="#F1C34A"
                stroke="#2D2D2D"
                strokeWidth="2"
            />
            <path
                d="M11.9336 7.69922L17.3481 11.9291L11.9336 16.1591"
                stroke="#2D2D2D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.93359 7.69922L12.3481 11.9291L6.93359 16.1591"
                stroke="#2D2D2D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default DoubleArrowNextIcon;

DoubleArrowNextIcon.propTypes = {
    disabled: PropTypes.bool,
    width: PropTypes.number,
};

DoubleArrowNextIcon.defaultProps = {
    disabled: false,
    width: 23,
};
