import { useQuery } from '@tanstack/react-query';

import { getPreviouslyPurchased } from 'api/jalapeno';

import useAccountQuery from './useAccountQuery';

const PREVIOUSLY_PURCHASED_QUERY_KEY = 'previously-purchased';

const usePreviouslyPurchasedQuery = ({ queryProps } = {}) => {
    const { data: account } = useAccountQuery();
    const { chargeID } = account?.nextOrder ?? {};

    const query = useQuery({
        queryKey: [PREVIOUSLY_PURCHASED_QUERY_KEY, chargeID],
        queryFn: async () => {
            const response = await getPreviouslyPurchased({ chargeID });
            return response?.data;
        },
        enabled: !!chargeID,
        ...queryProps,
    });

    return query;
};

export { usePreviouslyPurchasedQuery, PREVIOUSLY_PURCHASED_QUERY_KEY };
