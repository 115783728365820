import PropTypes from 'prop-types';

import MMSpiceUpYourRoutineActive from './MMSpiceUpYourRoutineActive';
import MMSpiceUpYourRoutineInactive from './MMSpiceUpYourRoutineInactive';

function MMSpiceUpYourRoutine({ className, isActive, width }) {
    return isActive ? (
        <MMSpiceUpYourRoutineActive className={className} width={width} />
    ) : (
        <MMSpiceUpYourRoutineInactive className={className} width={width} />
    );
}

export default MMSpiceUpYourRoutine;

MMSpiceUpYourRoutine.propTypes = {
    className: PropTypes.string,
    isActive: PropTypes.bool,
    width: PropTypes.number,
};

MMSpiceUpYourRoutine.defaultProps = {
    className: '',
    isActive: true,
    width: 24,
};
