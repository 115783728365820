import { useEffect, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useMediaQuery } from 'react-responsive';

import { seedBox } from 'api/jalapeno';
import useAccountQuery, { ACCOUNT_QUERY_KEY } from 'api/queries/useAccountQuery';
import { SELECTIONS_QUERY_KEY } from 'api/queries/useSelectionsQuery';
import { BOYSENBERRY_S3_IMAGES } from 'constants';
import { Drawer } from 'honeydew/Drawer';
import Tag from 'honeydew/Tag';
import useGlobalStore from 'hooks/useGlobalStore';
import useTailwindTheme from 'hooks/useTailwindTheme';
import logError from 'utils/errorUtils';

function CartSeed() {
    const { data: account = {} } = useAccountQuery();
    const [isSeeding, setIsSeeding] = useState(false);
    const tailwindTheme = useTailwindTheme();
    const mobile = useMediaQuery({ maxWidth: tailwindTheme?.screens?.md });
    const { isMisfitsTheme } = useGlobalStore();
    const queryClient = useQueryClient();
    const seedBoxRef = useRef(false);

    useEffect(() => {
        if (seedBoxRef.current || !account?.nextOrder?.chargeID || !account.seedBox) return;
        (async () => {
            seedBoxRef.current = true;
            setIsSeeding(true);
            try {
                const response = await seedBox(account.nextOrder.chargeID);

                if (response?.msg && response?.msg !== 'Success') {
                    throw new Error(response.msg);
                }

                await Promise.all([
                    queryClient.invalidateQueries({
                        queryKey: [ACCOUNT_QUERY_KEY],
                    }),
                    queryClient.invalidateQueries({
                        queryKey: [SELECTIONS_QUERY_KEY],
                    }),
                ]);
            } catch (error) {
                const message = `Cart seed error for customerID ${account.customerID}: ${error}`;
                logError(message);
            } finally {
                seedBoxRef.current = false;
                setIsSeeding(false);
            }
        })();
    }, [account?.nextOrder?.chargeID, account.seedBox]);

    if (!isSeeding) return null;

    return (
        <Drawer open toggleDrawer={() => {}} anchor={mobile ? 'bottom' : 'top'}>
            <div className="flex items-center justify-center gap-20 p-20">
                <img
                    src={`${BOYSENBERRY_S3_IMAGES}${isMisfitsTheme ? 'mm' : 'if'}-box-animation-250.gif`}
                    width={80}
                    height={80}
                    alt={`Groceries coming out of a ${isMisfitsTheme ? 'Misfits Market' : 'Imperfect Foods'} box.`}
                />
                <div>
                    <Tag variant="dark">Hang tight!</Tag>
                    <h3 className="text-body-lg mt-5 font-grotesk-bold">
                        We&apos;re putting the finishing touches on your cart
                    </h3>
                    <p className="mt-5 text-grey">A little bit of this, a little bit of that</p>
                </div>
            </div>
        </Drawer>
    );
}

export default CartSeed;
