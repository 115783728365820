import { MEMBERSHIP_COST } from 'constants/plusMembership';

const howDoesPlusMembershipWorkFaq = (brandingName) => ({
    id: 1,
    headline: `How does ${brandingName} work?`,
    content: `${brandingName} is our exclusive grocery shopping membership that offers the very best value. Members pay an annual $${MEMBERSHIP_COST} fee to get access to members-only discounts of up to 50% off grocery store prices, enjoy reduced shipping fees, and help fight hunger with every order. Our ${brandingName} Deals aisle gets updated with new steals each week, in addition to our everyday savings of up to 30%.`,
});

const howToCancelPlusMembershipFaq = (brandingName) => ({
    id: 2,
    headline: `How do I cancel my ${brandingName} membership?`,
    content: (
        <>
            You may cancel your {brandingName} membership via your&nbsp;
            <a href="/account/membership" target="_blank" className="link-blueberry" rel="noreferrer noopener">
                {brandingName} page
            </a>
            &nbsp;prior to the start of your next billing cycle to avoid further charges. If you cancel your{' '}
            {brandingName} membership at any other time during a billing cycle, you will not receive a refund, but you
            will still have access to your membership benefits until the end of your then-current subscription term.
        </>
    ),
});

const whatIfICancelMyTrialFaq = {
    id: 7,
    headline: 'What if I cancel my trial, but then decide I want to join after all?',
    content:
        'You can sign back up in your account anytime! Just remember: you’ll only get access to the 14-day free trial once, so make the most of it!',
};

export const nonMemberFaqList = (brandingName) => [
    howDoesPlusMembershipWorkFaq(brandingName),
    howToCancelPlusMembershipFaq(brandingName),
    {
        id: 3,
        headline: `Would I still have my grocery plan with ${brandingName}?`,
        content: (
            <>
                Yes! Your grocery plan stays the same when you join {brandingName} and can always be managed&nbsp;
                <a href="/account/plan" target="_blank" className="link-blueberry" rel="noreferrer noopener">
                    here.
                </a>
                &nbsp;With a {brandingName} membership, you’d have access to our very best savings.
            </>
        ),
    },
];

export const activeMemberFaqList = (brandingName) => [
    howDoesPlusMembershipWorkFaq(brandingName),
    howToCancelPlusMembershipFaq(brandingName),
    {
        id: 4,
        headline: 'Do I still have my grocery plan?',
        content: (
            <>
                Yes! Your grocery plan has not been impacted and can be managed&nbsp;
                <a href="/account/plan" target="_blank" className="link-blueberry" rel="noreferrer noopener">
                    here.
                </a>
                &nbsp;With your {brandingName} membership on top of that, you have access to our very best savings.
            </>
        ),
    },
];

export const trialAvailableFaqList = (brandingName, isMisfits) => [
    {
        id: 5,
        headline: `Is there a free trial for ${brandingName}?`,
        content: (
            <p>
                If you sign up with our free trial, you have 14 days to try out all the benefits of being a{' '}
                {brandingName}
                member, including access to weekly discounts via the {brandingName} Deals aisle, reduced shipping fees
                on every order, and the ability to help fight hunger with every order.
                <br />
                <br />
                If you decide you want to stick with your usual {isMisfits ? 'Misfits Market' : 'Imperfect Foods'}{' '}
                grocery experience, all you have to do is cancel before the trial ends to avoid being charged the annual
                fee. (If your trial ends before your shopping window closes, your {brandingName} benefits will still
                apply to that order!).
            </p>
        ),
    },
    {
        id: 6,
        headline: 'How do I cancel my trial?',
        content: (
            <p>
                You can cancel your free trial anytime via your&nbsp;
                <a href="/account/membership" target="_blank" className="link-blueberry" rel="noreferrer noopener">
                    {brandingName} page.
                </a>
                &nbsp;If you do not cancel by the time your free trial ends, your payment method will automatically be
                charged the ${MEMBERSHIP_COST} annual membership fee (plus any applicable taxes) and you will be
                enrolled in an annual {brandingName} membership that will automatically continue each year unless you
                cancel via your&nbsp;
                <a href="/account/membership" target="_blank" className="link-blueberry" rel="noreferrer noopener">
                    {brandingName} page.
                </a>
            </p>
        ),
    },
    whatIfICancelMyTrialFaq,
];

export const trialActiveFaqList = (brandingName, isMisfits) => [
    {
        id: 8,
        headline: `What’s the ${brandingName} free trial?`,
        content: (
            <p>
                If you sign up with our free trial, you have 14 days to try out all the benefits of being a{' '}
                {brandingName} member, including access to weekly discounts via the {brandingName} Deals aisle, reduced
                shipping fees on every order, and feel-good donations that fight hunger.
                <br />
                <br />
                If you decide you want to stick with your usual {isMisfits ? 'Misfits Market' : 'Imperfect Foods'}{' '}
                grocery experience, all you have to do is cancel before the trial ends to avoid being charged the annual
                fee. (If your trial ends before your shopping window closes, your {brandingName} benefits will still
                apply to that order!).
            </p>
        ),
    },
    {
        id: 9,
        headline: 'How do I cancel my trial?',
        content: `If you decide to cancel your trial and opt out of membership, you can navigate to this same page (${brandingName} Membership) at any time and click “Cancel free trial.” It’s that easy!`,
    },
    whatIfICancelMyTrialFaq,
];
