import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useMatch, useNavigate } from 'react-router';

import { MIN_WINE_BOTTLE_QTY_TO_SHIP } from 'constants/shop';
import { GlobalContext } from 'contexts/GlobalContext';
import Button from 'honeydew/Button';
import { useAccountPlusMembershipHook } from 'hooks/useAccountPlusMembershipHook/useAccountPlusMembershipHook';
import { trackEvent } from 'utils/analyticsUtils';
import { plulurizeWord } from 'utils/globalUtils';
import { getGroceryItems, getItemsTotalCost, getOrderMin, getTotalWineBottleQty } from 'utils/orderUtils';
import { getProductsSubtotal, PriceType } from 'utils/priceUtils';

function CartCTAs({ account, selections }) {
    const navigate = useNavigate();
    const location = useLocation();
    const isShopRoute = useMatch('/shop/*');

    const { setCartOpen } = useContext(GlobalContext);

    const { inPlusMembershipTreatment, plusMembershipEligibleOrder } = useAccountPlusMembershipHook();

    const groceryItems = getGroceryItems(selections);
    const orderMin = getOrderMin(account);

    const priceType = plusMembershipEligibleOrder ? PriceType.PLUS_MEMBER : PriceType.DEFAULT;

    const groceryItemsTotalCost = inPlusMembershipTreatment
        ? getProductsSubtotal({
              options: { priceType },
              products: groceryItems,
          })
        : getItemsTotalCost(groceryItems);

    const diffToOrderMin = orderMin - groceryItemsTotalCost;
    const totalWineBottleQty = getTotalWineBottleQty(selections);
    const bottlesToMeetMin = MIN_WINE_BOTTLE_QTY_TO_SHIP - totalWineBottleQty;

    const orderMinNotMet = groceryItemsTotalCost < orderMin;

    const navigateToAccount = () => {
        if (window.isNativeApp) {
            window.ReactNativeWebView?.postMessage(JSON.stringify({ navigate: 'Details' }));
        } else {
            setCartOpen(false);
            navigate('/account/orders/details');
        }
        trackEvent('order details click', { path: location.pathname });
    };

    const navigateToShopping = () => {
        if (window.isNativeApp) {
            window.ReactNativeWebView?.postMessage(JSON.stringify({ navigate: 'Shop' }));
        } else {
            setCartOpen(false);
            if (!isShopRoute) {
                navigate('/shop');
            }
        }
    };

    return (
        <div className="mb-10 flex flex-col gap-10 bg-white px-15 py-20">
            <Button onClick={navigateToShopping} size="md" variant={orderMinNotMet ? 'primary' : 'secondary'}>
                Return to Shop
            </Button>

            {orderMinNotMet && totalWineBottleQty < MIN_WINE_BOTTLE_QTY_TO_SHIP ? (
                <>
                    {orderMinNotMet && (
                        <p className="text-body-xs text-center">
                            Add <span className="font-grotesk-bold text-error">${diffToOrderMin.toFixed(2)}</span> to
                            ship Groceries order
                        </p>
                    )}
                    {totalWineBottleQty > 0 && totalWineBottleQty < MIN_WINE_BOTTLE_QTY_TO_SHIP && (
                        <p className="text-body-xs text-center">
                            Add{' '}
                            <span className="font-grotesk-bold text-error">
                                {bottlesToMeetMin} {plulurizeWord('bottle', bottlesToMeetMin)}
                            </span>{' '}
                            to ship Wine order
                        </p>
                    )}
                </>
            ) : (
                <>
                    <Button onClick={navigateToAccount} size="md" variant="secondary">
                        View Details
                    </Button>
                    <p className="text-body-xs text-center text-grey">
                        All done? Your order is automatically saved, no checkout needed!
                    </p>
                </>
            )}
        </div>
    );
}

export default CartCTAs;

CartCTAs.propTypes = {
    account: PropTypes.shape({
        cartMinimum: PropTypes.number,
        nextOrder: PropTypes.shape({
            cartExperience: PropTypes.shape({
                orderMinimum: PropTypes.number,
                orderMinimumOverridden: PropTypes.bool,
            }),
        }),
    }).isRequired,
    selections: PropTypes.shape({
        selected: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
};
