import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';

import { DUPLICATE_ITEMS_AISLE_IDS } from 'constants/shop';
import Button from 'honeydew/Button/Button';
import { NextIcon, PreviousIcon } from 'honeydew/icons/Carousels';
import useGlobalStore from 'hooks/useGlobalStore';
import useStickyShopNavigation, { STICKY_SHOP_NAVIGATION_STYLES } from 'hooks/useStickyShopNavigation';
import useTailwindTheme from 'hooks/useTailwindTheme';
import { scrollToL2AisleElement } from 'utils/aislesUtils';
import { trackAisleNavigation } from 'utils/analyticsUtils';
import { getDecodedPathSegment } from 'utils/pathUtils';

function L2NavigationWrapper({ children }) {
    const tailwindTheme = useTailwindTheme();
    const mobile = useMediaQuery({ maxWidth: tailwindTheme?.screens?.md });
    const navRef = useRef();

    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);
    const [stuck, setStuck] = useState(false);

    const checkArrows = () => {
        if (!navRef?.current) return;
        if (!mobile && navRef.current.scrollWidth > navRef.current.clientWidth + 20) {
            setShowLeftArrow(navRef.current.scrollLeft !== 0);
            setShowRightArrow(navRef.current.scrollLeft + navRef.current.clientWidth < navRef.current.scrollWidth);
        }
    };

    useEffect(() => {
        checkArrows();
    }, []);

    const scrollLeft = () => {
        if (typeof navRef?.current?.scrollLeft === 'number') {
            navRef.current.scrollLeft -= 400;
        }
    };

    const scrollRight = () => {
        if (typeof navRef?.current?.scrollLeft === 'number') {
            navRef.current.scrollLeft += 400;
        }
    };

    const { entry, ref } = useInView({
        threshold: [1],
        rootMargin: '-81px 0px 0px 0px',
    });

    useEffect(() => {
        setStuck(entry?.intersectionRatio < 1);
    }, [entry]);

    return (
        <div
            ref={ref}
            className={clsx('relative bg-white', {
                [STICKY_SHOP_NAVIGATION_STYLES.desktopSticky]: !mobile,
                'border-b border-off-white': stuck && !mobile,
            })}
        >
            {showLeftArrow && (
                <button className="absolute left-10 top-[20px]" type="button" onClick={scrollLeft}>
                    <PreviousIcon width={30} />
                </button>
            )}
            <nav
                className={clsx('flex overflow-auto py-[8px]', {
                    'scroll-mx-[20px] scroll-px-[20px] pr-10': mobile,
                    'scroll-mx-[30px] scroll-px-[30px] pl-30 pr-25': !mobile,
                })}
                ref={navRef}
                onScroll={checkArrows}
            >
                {children}
            </nav>
            {showRightArrow && (
                <button className="absolute right-10 top-[18px]" type="button" onClick={scrollRight}>
                    <NextIcon width={30} />
                </button>
            )}
        </div>
    );
}

L2NavigationWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export function L2Navigation({ aisles }) {
    const tailwindTheme = useTailwindTheme();
    const mobile = useMediaQuery({ maxWidth: tailwindTheme?.screens?.md });
    const params = useParams();
    const stickyShopNavigation = useStickyShopNavigation();
    const aisleDisplayName = getDecodedPathSegment(params?.category);
    const currentAisle = aisles?.find((aisle) => aisle.name.toLowerCase() === aisleDisplayName?.toLowerCase());
    const displayAisles = currentAisle
        ? currentAisle.aisles
        : aisles?.filter((aisle) => !DUPLICATE_ITEMS_AISLE_IDS.includes(aisle.id));
    const { currentAisleId, setCurrentAisleId } = useGlobalStore();

    useEffect(() => {
        if (stickyShopNavigation.sticky) {
            const element = document.querySelector(`#nav-aisle-${currentAisleId}`);
            element?.scrollIntoView({ behavior: 'instant', block: 'nearest', inline: 'start' });
        }
    }, [currentAisleId, stickyShopNavigation]);

    return (
        <L2NavigationWrapper>
            {displayAisles?.map(({ id, name }, i) => (
                <span
                    id={`nav-aisle-${id}`}
                    key={id}
                    className={clsx({
                        'pl-10': (i !== 0 && !mobile) || mobile,
                    })}
                >
                    <Button
                        size="sm"
                        rounded="md"
                        variant="offWhite"
                        customClass={`whitespace-nowrap h-[35px] pt-[3px] ${!mobile && 'hover:bg-mango'} ${
                            currentAisleId === id && '!bg-mango'
                        }`}
                        onClick={() => {
                            scrollToL2AisleElement({ mobile, selector: `#aisle-${id}` });
                            trackAisleNavigation({
                                aisleName: name,
                                level: 2,
                                mobile,
                                uiElement: 'L2',
                            });
                            setCurrentAisleId(id);
                        }}
                    >
                        <p className="text-body-xs">{name}</p>
                    </Button>
                </span>
            ))}
        </L2NavigationWrapper>
    );
}

L2Navigation.propTypes = {
    aisles: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
};
