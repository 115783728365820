import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

export default function useTrailingSlashRedirect() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname.match('/.*/$')) {
            navigate(`${location.pathname.replace(/\/+$/, '')}${location.search}`, { replace: true });
        }
    }, [location.pathname]);
}
