import { useState } from 'react';
import clsx from 'clsx';

import {
    IMPERFECT_THEME_BODY_CLASS,
    IMPERFECT_THEME_DOMAIN,
    MISFITS_THEME_BODY_CLASS,
    MISFITS_THEME_DOMAIN,
} from 'constants/theme';
import useGlobalStore from 'hooks/useGlobalStore';

import { PillSection } from './PillSection';

const bodyClasses = {
    [IMPERFECT_THEME_DOMAIN]: IMPERFECT_THEME_BODY_CLASS,
    [MISFITS_THEME_DOMAIN]: MISFITS_THEME_BODY_CLASS,
};

export function DevBar() {
    const { setTheme, theme } = useGlobalStore();

    const [isOpen, setIsOpen] = useState(false);

    const onThemeClick = (overrideTheme) => {
        setTheme(overrideTheme);
        document.body.classList.remove(bodyClasses[theme]);
        document.body.classList.add(bodyClasses[overrideTheme]);
    };

    return (
        <div className="width-[200px] fixed bottom-0 right-0 z-[10000001]">
            <div className="flex w-full flex-row-reverse">
                <button
                    type="button"
                    className="border-l border-t border-dashed bg-white p-10 py-5"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    👨‍💻{isOpen ? '⏬' : '⏫'}
                </button>
            </div>
            <div className={clsx('bg-white', { hidden: !isOpen })}>
                <PillSection
                    items={[
                        {
                            active: theme === IMPERFECT_THEME_DOMAIN,
                            name: 'IF theme',
                            onClick: () => onThemeClick(IMPERFECT_THEME_DOMAIN),
                        },
                        {
                            active: theme === MISFITS_THEME_DOMAIN,
                            name: 'MM theme',
                            onClick: () => onThemeClick(MISFITS_THEME_DOMAIN),
                        },
                    ]}
                />
            </div>
        </div>
    );
}
