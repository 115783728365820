import PropTypes from 'prop-types';

import { IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN } from 'constants/theme';

export function Deli({ brand, isActive, width }) {
    const breadColor = brand === MISFITS_THEME_DOMAIN ? '#DEA455' : '#F6A685';
    const lettuceColor = brand === MISFITS_THEME_DOMAIN ? '#B0C472' : '#C5DB66';
    const cheeseColor = brand === MISFITS_THEME_DOMAIN ? '#F1C34A' : '#F7D46D';
    const meatColor = brand === MISFITS_THEME_DOMAIN ? '#DC6B31' : '#D9291F';
    const activeColor = brand === MISFITS_THEME_DOMAIN ? '#D7E1B8' : '#F0D3E3';

    return isActive ? (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.6505 28.7652C21.902 26.9646 29 22.6358 29 14.9992C29 7.3625 22.9452 2.7363 14.6505 1.1728C7.31233 -0.213857 2.48978 6.96057 1.13553 14.967C-0.132106 22.475 7.73356 30.4815 14.6505 28.7652Z"
                fill={activeColor}
            />
            <g clipPath="url(#clip0_10935_21402)">
                <path
                    d="M24.0679 8.02645C23.1942 6.38112 20.7587 5.92563 18.1187 6.4183C15.9528 6.82731 10.1523 7.54308 6.55489 8.33321C6.74081 8.86307 6.77799 9.48588 6.61996 10.0808C6.3318 11.1591 5.65321 11.4566 4.22168 11.5588C4.79801 11.7075 5.51378 12.9067 5.65321 13.5853L24.8581 10.6664C24.8581 10.6664 24.9324 9.66249 24.0586 8.01716L24.0679 8.02645Z"
                    fill={breadColor}
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2.53 18.1587C2.53 18.1587 2.13029 18.4376 2.26043 19.2649C2.44634 20.4547 3.35732 19.7948 4.9097 19.7204L24.9512 16.5134C24.9512 16.5134 27.0892 16.3275 26.9869 15.4444C26.8847 14.5613 25.8528 14.8216 25.8528 14.8216L2.53 18.1587Z"
                    fill={meatColor}
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M3.54297 19.9342C3.60804 20.3339 3.88691 22.5184 5.01169 23.2156C6.13646 23.9127 8.72066 23.104 12.8201 22.444C16.9194 21.7933 23.9563 20.9381 24.8579 20.6221C25.3041 20.4733 25.6853 20.1852 25.9548 19.8041C26.2244 19.4229 26.3638 18.9674 26.3546 18.4934C26.3174 17.7683 26.1965 17.0432 26.0106 16.3461L3.54297 19.9342Z"
                    fill={breadColor}
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.5539 7.94284C8.5539 7.94284 6.53674 9.97859 8.30292 11.6146C8.53531 10.2203 8.88855 8.61213 11.0173 7.52454C9.59502 7.8127 8.5539 7.94284 8.5539 7.94284Z"
                    fill="#F2EBD1"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.4758 7.31071C12.4758 7.31071 10.4586 9.34646 12.2248 10.9825C12.4572 9.58815 12.8104 8.00788 14.9391 6.92029L12.4758 7.31071Z"
                    fill="#F2EBD1"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.1569 6.75293C16.1569 6.75293 14.2699 8.7422 16.0547 10.3782C16.2871 8.98389 16.4637 7.4687 18.5738 6.3811C17.1516 6.5949 16.1569 6.75293 16.1569 6.75293Z"
                    fill="#F2EBD1"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M26.5773 12.3583C25.8244 11.7076 21.6599 11.4845 13.5448 12.8138C10.0124 13.3901 7.42824 14.0687 5.63417 14.6543C5.3553 15.7047 4.36066 16.4949 3.48687 16.3461C3.96095 16.9039 3.8587 17.8706 3.45898 18.6422C7.2981 18.0658 12.2806 16.8388 14.2513 16.8481C16.287 16.8667 16.8169 18.9582 18.1276 19.6833C19.6428 20.5199 20.3306 18.9303 20.9534 15.965C21.2323 14.7659 23.8537 15.4723 25.8151 14.8309C27.3675 14.3383 27.5627 13.2228 26.5773 12.3583Z"
                    fill={cheeseColor}
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M27.703 11.7076C27.0709 10.3504 24.8678 10.6758 24.8678 10.6758L5.66296 13.5946C5.72803 13.92 5.73733 14.2732 5.65367 14.6264C5.39339 15.6861 4.38016 16.4949 3.49707 16.3461C3.68298 16.5692 3.77594 16.8481 3.80383 17.1549C3.8689 17.1549 3.92467 17.1363 3.98974 17.127C4.95649 17.0154 5.88606 16.6436 6.6483 16.0394C7.0945 15.6583 7.45703 15.1935 7.71731 14.6822C7.71731 14.6822 8.30293 16.2718 11.0637 15.9371C12.5232 15.7605 13.4992 15.147 14.0941 13.669C15.0702 14.9332 16.4459 15.2957 18.0727 14.8681C19.4949 14.4963 20.4152 12.7394 20.4152 12.7394C21.3541 13.3157 22.4045 13.669 23.5014 13.7898C25.1467 13.92 28.2607 13.7805 27.703 11.7076Z"
                    fill={lettuceColor}
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_10935_21402">
                    <rect width="26" height="17.7269" fill="white" transform="translate(2 6)" />
                </clipPath>
            </defs>
        </svg>
    ) : (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.79717 18.4339C3.79717 18.4339 3.41386 18.7013 3.53866 19.4947C3.71694 20.6358 4.59055 20.0028 6.07924 19.9315L25.2985 16.8561C25.2985 16.8561 27.3488 16.6778 27.2508 15.8309C27.1527 14.9841 26.1632 15.2337 26.1632 15.2337L3.79717 18.4339Z"
                fill={meatColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.76855 20.1366C4.83095 20.5199 5.09838 22.6148 6.17702 23.2834C7.25565 23.9519 9.73384 23.1764 13.6651 22.5435C17.5963 21.9195 24.3444 21.0994 25.2091 20.7963C25.637 20.6536 26.0025 20.3773 26.261 20.0118C26.5195 19.6463 26.6532 19.2095 26.6443 18.7549C26.6087 18.0596 26.4928 17.3643 26.3145 16.6957L4.76855 20.1366Z"
                fill={breadColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.60937 16.3658C3.60937 16.3658 2.87839 19.156 4.34926 18.942C8.06653 18.4161 13.0942 17.1591 15.0465 17.177C16.9987 17.1948 17.5068 19.2005 18.7637 19.8958C20.2168 20.6981 20.8764 19.1738 21.4737 16.3301C21.7411 15.1802 24.2549 15.8577 26.1359 15.2426C27.6246 14.7701 27.8118 13.7004 26.8668 12.8714C26.1448 12.2474 22.1512 12.0334 14.369 13.3082C6.58675 14.5829 3.61828 16.3658 3.61828 16.3658H3.60937Z"
                fill={cheeseColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.87694 14.5027C3.87694 14.5027 2.33476 14.6186 2.04059 15.8042C1.70185 17.1592 3.54711 17.7208 5.19626 17.4534C6.12335 17.3464 7.01479 16.9898 7.74576 16.4104C8.17365 16.0449 8.52131 15.5992 8.77091 15.1089C8.77091 15.1089 9.33251 16.6333 11.9801 16.3123C13.3796 16.143 14.3156 15.5546 14.8861 14.1373C15.8221 15.3496 17.1415 15.6973 18.7015 15.2872C20.0654 14.9306 20.9479 13.2458 20.9479 13.2458C21.8482 13.7985 22.8555 14.1373 23.9074 14.2531C25.4853 14.3779 28.4716 14.2442 27.9367 12.2563C27.3305 10.9548 25.2178 11.2668 25.2178 11.2668L3.87694 14.5027Z"
                fill={lettuceColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.87738 14.5027C3.76149 13.4151 3.03943 10.4734 5.80288 9.51957C8.57523 8.55682 16.2237 7.65648 18.7554 7.18402C21.2781 6.70264 23.6226 7.14836 24.4606 8.7262C25.2985 10.304 25.2272 11.2668 25.2272 11.2668L3.87738 14.5027Z"
                fill={breadColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.57344 8.63711C9.57344 8.63711 7.63903 10.5893 9.33276 12.1583C9.55561 10.8211 9.89436 9.27894 11.9357 8.23596C10.5718 8.51231 9.57344 8.63711 9.57344 8.63711Z"
                fill="#F2EBD1"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.3439 8.03096C13.3439 8.03096 11.4095 9.98319 13.1033 11.5521C13.3261 10.215 13.6649 8.69953 15.7062 7.65656L13.3439 8.03096Z"
                fill="#F2EBD1"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.8654 7.49604C16.8654 7.49604 15.0558 9.4037 16.7673 10.9726C16.9902 9.63548 17.1596 8.18244 19.1831 7.13947C17.8192 7.34449 16.8654 7.49604 16.8654 7.49604Z"
                fill="#F2EBD1"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

Deli.propTypes = {
    brand: PropTypes.oneOf([IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN]).isRequired,
    isActive: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Deli.defaultProps = {
    isActive: false,
    width: 30,
};
