import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import * as RadixPopover from '@radix-ui/react-popover';
import PropTypes from 'prop-types';

import twclsx from 'utils/twclsx';

function Popover({ children, classNames, heading, label, trigger }) {
    const contentClassName = twclsx(
        'w-[260px] md:w-auto md:max-w-[375px] rounded-xl bg-black p-10 text-off-white',
        classNames?.content
    );
    const arrowClassName = twclsx('w-20 h-10 fill-black', classNames?.arrow);

    return (
        <RadixPopover.Root>
            <RadixPopover.Trigger asChild>
                <button className="" aria-label={label} type="button">
                    {trigger}
                </button>
            </RadixPopover.Trigger>
            <RadixPopover.Portal>
                <RadixPopover.Content className={contentClassName} sideOffset={2}>
                    {heading && <h6 className="text-body-xs mb-5 mr-20 font-grotesk-bold text-off-white">{heading}</h6>}
                    {children}
                    <RadixPopover.Close className="absolute right-10 top-10" aria-label="Close">
                        <XMarkIcon className="size-20" />
                    </RadixPopover.Close>
                    <RadixPopover.Arrow className={arrowClassName} />
                </RadixPopover.Content>
            </RadixPopover.Portal>
        </RadixPopover.Root>
    );
}

Popover.propTypes = {
    children: PropTypes.node,
    classNames: PropTypes.shape({
        arrow: PropTypes.string,
        content: PropTypes.string,
    }),
    heading: PropTypes.string,
    label: PropTypes.string.isRequired,
    trigger: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

Popover.defaultProps = {
    children: undefined,
    classNames: undefined,
    heading: undefined,
};

export default Popover;
