import PropTypes from 'prop-types';

import IFThanksgivingEssentialsActive from './IFThanksgivingEssentialsActive';
import IFThanksgivingEssentialsInactive from './IFThanksgivingEssentialsInactive';

function IFThanksgivingEssentials({ className, isActive, width }) {
    return isActive ? (
        <IFThanksgivingEssentialsActive className={className} width={width} />
    ) : (
        <IFThanksgivingEssentialsInactive className={className} width={width} />
    );
}

export default IFThanksgivingEssentials;

IFThanksgivingEssentials.propTypes = {
    className: PropTypes.string,
    isActive: PropTypes.bool,
    width: PropTypes.number,
};

IFThanksgivingEssentials.defaultProps = {
    className: '',
    isActive: true,
    width: 24,
};
