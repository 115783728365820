import PropTypes from 'prop-types';

function MisfitsPlusIcon({ width }) {
    return (
        <svg width={width} height={width} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="10" fill="url(#paint0_linear_2143_14376)" />
            <g clipPath="url(#clip0_2143_14376)">
                <path
                    d="M15.6767 10.7615V12.0531H14.401V10.7615H13.0854V9.46189H14.401V8.17822H15.6767V9.46189H17.0002V10.7615H15.6767Z"
                    fill="white"
                />
                <path
                    d="M12.7415 13.2051C12.7761 13.3047 12.8053 13.6737 12.6778 13.6737C12.6314 13.6737 9.40958 13.6551 8.94363 13.6511C8.9131 13.6511 8.88921 13.6259 8.88921 13.5954V13.1825C8.88921 13.1347 8.93965 13.1387 8.97549 13.1308C9.05647 13.1148 9.7149 12.9993 9.79986 12.0157C9.88481 11.032 9.80915 7.78234 9.80915 7.78234C9.80915 7.78234 9.7149 7.87261 9.6379 8.13944C9.31665 9.26381 8.39406 12.3754 8.24538 12.8931C8.2321 12.9409 8.1883 12.9728 8.13918 12.9728H7.89227C7.84846 12.9728 7.80997 12.9476 7.79138 12.9077C7.53385 12.3396 5.67272 8.22439 5.6714 8.17793C5.6714 8.12749 5.66609 10.3656 5.66609 11.1422C5.66609 11.6387 5.7683 12.5002 6.11079 12.8679C6.41611 13.1958 6.87675 13.1825 7.03737 13.1931C7.08782 13.1958 7.10109 13.2051 7.10109 13.2356C7.10109 13.302 7.10375 13.5702 7.10375 13.5702C7.10375 13.5702 7.10773 13.6538 7.03074 13.6538C6.66037 13.6538 4.53109 13.6684 4.08373 13.6724C4.0386 13.6724 4.00143 13.6365 4.0001 13.5914C4.0001 13.4852 3.99877 13.2834 4.0001 13.1985C4.0001 13.1759 4.0147 13.156 4.03594 13.148C4.14214 13.1082 4.45144 12.9701 4.58552 12.8453C4.75411 12.6887 5.01828 12.3834 5.10855 11.5391C5.20678 10.6178 5.13244 8.54432 5.13244 8.31068C5.13244 7.88323 4.79128 7.62703 4.54437 7.50756C4.3718 7.42393 4.24967 7.42924 4.17135 7.41331C4.1209 7.40269 4.08506 7.35888 4.08373 7.30711L4.07444 6.64735C4.07444 6.58629 4.12356 6.53584 4.18462 6.53584H4.37976L7.03339 6.53982C7.07852 6.53982 7.11968 6.5677 7.13561 6.61018L8.51353 10.1413C8.51353 10.1413 9.37639 7.12657 9.4348 7.00311C9.49454 6.87966 9.63923 6.55177 9.91667 6.5385C10.1623 6.52655 11.8933 6.51593 12.1097 6.50664C12.2198 6.50133 12.3181 6.5 12.3858 6.5C12.4455 6.5 12.4933 6.54646 12.4946 6.6062C12.4973 6.73629 12.4999 6.95267 12.4999 7.04294C12.4999 7.11728 11.9013 7.06816 11.8787 7.785C11.8561 8.50184 11.876 10.8594 11.876 11.4077C11.876 12.0794 12.0393 12.913 12.3128 13.0644C12.5185 13.1785 12.7057 13.1042 12.7402 13.2038"
                    fill="white"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_2143_14376"
                    x1="0.28169"
                    y1="0.437158"
                    x2="23.4222"
                    y2="6.58175"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.19" stopColor="#A13051" />
                    <stop offset="0.81" stopColor="#44638D" />
                </linearGradient>
                <clipPath id="clip0_2143_14376">
                    <rect width="13" height="7.1737" fill="white" transform="translate(4 6.5)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export { MisfitsPlusIcon };

MisfitsPlusIcon.propTypes = {
    width: PropTypes.number,
};

MisfitsPlusIcon.defaultProps = {
    width: 20,
};
