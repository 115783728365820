import PropTypes from 'prop-types';

function OrderIssueIcon({ width }) {
    return (
        <svg width={width} className="inline-block" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2661_14265)">
                <path
                    d="M5.05977 10.4069L6.22111 16.52C6.64858 18.7898 8.59298 17.8289 8.22225 16.3385C7.88558 14.9691 7.08739 10.2367 7.08739 10.2367C7.08739 10.2367 6.19463 5.2395 5.87309 3.91549C5.55154 2.59148 3.60336 2.18672 3.86816 4.10085C4.13297 6.01499 5.05977 10.4069 5.05977 10.4069Z"
                    className="fill-[#DF8253] if:fill-error"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M3.54639 2.39466C3.54639 2.39466 4.12517 6.00352 4.96875 9.90367C5.43909 9.77129 5.92007 9.68016 6.40624 9.6313C7.08338 9.57456 8.04423 9.8734 9.31527 9.57834C10.5863 9.28328 12.3681 8.30729 14.2557 7.89874C16.1434 7.49019 16.6578 7.6869 16.6578 7.6869C16.6578 7.6869 15.0728 6.32507 13.2759 4.69086C13.6921 3.80945 15.3225 0.631836 15.3225 0.631836C13.5667 0.693799 11.8393 1.09323 10.2345 1.80831C7.79456 2.96209 4.77204 2.96965 3.54639 2.39466Z"
                    className="fill-[#DF8253] if:fill-error"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2661_14265">
                    <rect width="18.5029" height="18.5029" fill="white" transform="translate(0.850586)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default OrderIssueIcon;

OrderIssueIcon.propTypes = {
    width: PropTypes.number,
};

OrderIssueIcon.defaultProps = {
    width: 20,
};
