/**
 * Calculates a customer's loyalty points balance.
 * @param {number} currentLoyaltyPoints - The account's currentLoyaltyPoints.
 * @param {object} selections - The customer's selections in their shopping cart.
 * @returns {number} The loyalty points balance.
 */
export const getLoyaltyPointsBalance = (currentLoyaltyPoints = 0, selections = {}) =>
    currentLoyaltyPoints -
    (selections?.selected
        ?.filter((selection) => selection.isPerk)
        ?.reduce((a, { pointPrice = 0, qty }) => a + pointPrice * qty, 0) ?? 0);

/**
 * Gets a list of individual perks items
 * @param {array} perkItems - A list of perkItems.
 * @example
 * getIndividualPerkItems([{name: 'Coffee', qty: 2}]);
 * // returns
 * [{name: 'Coffee', qty: 2}, {name: 'Coffee', qty: 2}]
 * @returns {array} A new list of individual perkItems.
 */
export const getIndividualPerkItems = (perkItems) => {
    const individualPerkItems = [];
    perkItems.forEach((perkItem) => {
        for (let i = 0; i < perkItem.qty; i += 1) {
            individualPerkItems.push(perkItem);
        }
    });
    return individualPerkItems;
};

/**
 * Generate carousel ID by formatting carousel name to kebab case
 * @param {string} name - Name of carousel
 * @example
 * formatCarouselID('Weekly Picks');
 * // returns 'weekly-picks'
 * @returns {string} Carousel name formatted in kebab case
 */
export const formatCarouselID = (name) => name.toLowerCase().split(' ').join('-');
