import PropTypes from 'prop-types';

function AccountDetailsIcon({ width }) {
    return (
        <svg width={width} className="inline-block" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.97005 13.4692C2.97005 13.4692 6.32185 13.8209 7.4274 14.0121C9.21651 14.322 10.7639 14.344 11.8607 14.555C13.2317 14.8123 14.6178 14.9819 16.0104 15.0627C16.4318 15.1136 16.8561 14.9952 17.1903 14.7336C17.5244 14.4719 17.7412 14.0883 17.7929 13.667C17.7929 13.667 18.0984 11.3812 18.1665 10.6141C18.3006 9.07556 18.6303 6.82048 18.6303 6.82048C18.6812 6.3991 18.5628 5.97473 18.3011 5.64054C18.0395 5.30635 17.6559 5.08964 17.2346 5.03797C17.2346 5.03797 15.151 4.73685 13.6805 4.59838C12.3838 4.4797 10.1286 4.06208 8.51316 3.96757C7.42739 3.90383 4.19652 3.44007 4.19652 3.44007C3.77536 3.38854 3.35101 3.5064 3.01674 3.76772C2.68247 4.02903 2.46556 4.41242 2.41392 4.83356C2.41392 4.83356 2.15237 7.52602 1.97433 8.40959C1.73256 9.62064 1.57435 11.6823 1.57435 11.6823C1.54823 11.8913 1.56368 12.1035 1.61975 12.3065C1.67581 12.5096 1.77136 12.6996 1.90103 12.8656C2.03071 13.0316 2.19195 13.1704 2.37539 13.274C2.55883 13.3776 2.76091 13.4439 2.97005 13.4692Z"
                className="fill-mango if:fill-[#E95CA2]"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.3865 8.79196L14.9665 8.37436L7.2254 7.42925L2.17017 6.81163L2.40981 4.8335L11.6015 5.95664L18.6349 6.81603L18.3865 8.79196Z"
                fill="#2D2D2D"
            />
            <path
                d="M7.24504 10.8007C7.24504 10.8007 5.74855 10.6762 5.26247 10.5613C4.61118 10.4206 3.94827 10.3404 3.28223 10.3218L3.50496 8.50439C3.50496 8.50439 4.98714 8.7175 5.4852 8.74384C6.14968 8.79023 6.81135 8.87015 7.46777 8.98329L7.24504 10.8007Z"
                fill="#FFF9EA"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.98193 11.7896C2.98193 11.7896 5.53813 12.1764 6.39752 12.2072C7.06598 12.2356 7.73108 12.3172 8.3866 12.4511"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default AccountDetailsIcon;

AccountDetailsIcon.propTypes = {
    width: PropTypes.number,
};

AccountDetailsIcon.defaultProps = {
    width: 20,
};
