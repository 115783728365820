import { useQuery } from '@tanstack/react-query';

import { getAisles } from 'api/jalapeno';

import useAccountQuery from './useAccountQuery';

export const AISLES_QUERY_KEY = 'aisles';

const useAislesQuery = () => {
    const { data: account } = useAccountQuery();

    const query = useQuery({
        queryKey: [AISLES_QUERY_KEY, account?.nextOrder?.chargeID],
        queryFn: async () => {
            const response = await getAisles(account?.nextOrder?.chargeID);
            return response.data;
        },
        enabled: !!account?.nextOrder?.chargeID,
    });

    const aislesWithValidCount = query?.data?.aisles?.filter((aisle) => aisle?.count !== 0);

    if (query?.data?.aisles) query.data.aisles = aislesWithValidCount;

    return query;
};

export default useAislesQuery;
