import PropTypes from 'prop-types';

import { IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN } from 'constants/theme';

export function StaffPicks({ brand, isActive, width }) {
    const heartColor = brand === MISFITS_THEME_DOMAIN ? '#DC6C31' : '#DD3D96';
    const handColor = brand === MISFITS_THEME_DOMAIN ? '#F1D699' : '#F8E1A4';
    const activeColor = brand === MISFITS_THEME_DOMAIN ? '#D7E1B8' : '#F0D3E3';

    return (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            {isActive && (
                <path
                    d="M14.6505 28.7652C21.902 26.9646 29 22.6358 29 14.9992C29 7.3625 22.9452 2.7363 14.6505 1.1728C7.31233 -0.213857 2.48978 6.96057 1.13553 14.967C-0.132106 22.475 7.73356 30.4815 14.6505 28.7652Z"
                    fill={activeColor}
                />
            )}
            <path
                d="M24.1949 16.7019C25.1034 16.5071 25.7848 15.9592 25.6413 14.9609C25.4979 14.0235 24.685 13.8043 24.0992 13.7556C24.0395 13.7556 24.0275 13.6582 24.0992 13.6461C24.7687 13.4634 25.8207 13.2565 25.6174 11.8807C25.462 10.8337 24.7926 10.4563 23.6569 10.5902C21.9714 10.7972 18.7079 11.4181 18.3612 11.3085C18.0145 11.199 18.4449 9.62843 18.5166 8.76403C18.5883 7.94832 18.947 6.40214 18.6361 5.33078C18.3492 4.32028 17.2973 3.74807 16.3051 3.93069C16.3051 3.93069 15.3965 8.25269 15.0379 9.48233C14.3326 11.8807 12.5873 12.2703 11.9896 12.3921C11.5114 12.4895 10.5312 12.4773 9.40746 12.0634C9.37159 12.0512 9.43136 12.2703 9.43136 12.5625C9.43136 18.6255 9.80195 22.3509 9.80195 22.3509C9.9454 22.777 12.934 23.3979 13.3643 23.4953C14.6315 23.7754 16.6637 23.6901 17.4407 23.5806C18.9111 23.3858 22.7006 22.7162 23.2624 22.6309C23.8243 22.5457 25.2469 22.3266 25.0317 20.8656C24.8165 19.4047 23.119 19.6603 23.107 19.6725C23.7048 19.5751 24.2307 19.4899 24.3981 19.4534C24.9002 19.3194 25.8087 19.149 25.6174 17.8707C25.4142 16.4949 23.8841 16.7384 23.8841 16.7384L24.1829 16.6775L24.1949 16.7019Z"
                fill={handColor}
            />
            <path
                d="M23.609 13.7557C23.609 13.7557 25.4739 13.4757 25.689 14.9488C25.8325 15.9471 25.1511 16.495 24.2426 16.6898C23.334 16.8846 21.0508 17.1768 21.0508 17.1768"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M23.9319 16.7506C23.9319 16.7506 25.4621 16.4949 25.6653 17.8828C25.8566 19.149 24.9361 19.3316 24.446 19.4655C23.9558 19.5995 20.5967 20.0621 20.5967 20.0621"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M23.1549 19.6725C23.1549 19.6725 24.8643 19.4047 25.0795 20.8657C25.2947 22.3266 23.8721 22.5458 23.3103 22.631C22.7484 22.7162 18.9589 23.3736 17.4886 23.5806C16.5681 23.7024 12.5754 23.9702 9.80196 22.3753L9.40747 12.0877C10.7344 12.5991 11.5353 12.5017 12.0374 12.4043C12.6351 12.2825 14.3924 11.8929 15.0857 9.49454C15.5041 8.05793 16.3529 3.9429 16.3529 3.9429C17.3571 3.74811 18.3014 4.66121 18.5166 5.69605C19.0904 8.3623 17.5483 11.3329 18.409 11.3207C18.7677 11.3207 22.0192 10.8094 23.7048 10.6024C24.8404 10.4685 25.5099 10.8338 25.6653 11.8929C25.8685 13.2687 24.8165 13.4756 24.1471 13.6583C23.4776 13.8409 21.7562 14.0235 21.7562 14.0235"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            {isActive && (
                <>
                    <path
                        d="M19.3146 3.53712C19.7299 3.01601 20.6313 1.84204 20.6313 1.84204"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M19.9999 5.11105C20.6004 4.8757 21.9562 4.32949 21.9562 4.32949"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M17.5349 2.41269C17.6547 1.97779 17.8989 1 17.8989 1"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </>
            )}
            <path
                d="M9.19222 16.7977C8.58256 16.2742 7.7099 15.1419 5.91676 15.7263C4.13557 16.2985 2.85646 18.3438 3.43027 20.4135C4.00407 22.4832 5.54617 24.1025 7.33931 25.1738C9.13245 26.2574 10.3876 26.6713 10.9256 26.7078C11.4157 26.4643 13.9859 24.3703 14.9064 22.4832C15.8268 20.5962 16.843 19.0013 15.4443 16.3229C14.0457 13.6444 10.818 14.9593 10.4833 15.4341C10.1486 15.9089 9.19222 16.8098 9.19222 16.8098V16.7977Z"
                fill={heartColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

StaffPicks.propTypes = {
    brand: PropTypes.oneOf([IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN]).isRequired,
    isActive: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

StaffPicks.defaultProps = {
    isActive: false,
    width: 30,
};
