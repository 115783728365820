import { useContext, useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { GlobalContext } from 'contexts/GlobalContext';
import FreeShippingProgress from 'features/shop/FreeShippingProgress';
import OrderMinProgress from 'features/shop/OrderMinProgress';
import { useAccountPlusMembershipHook } from 'hooks/useAccountPlusMembershipHook';
import { calculateCountdown, formatDateString } from 'utils/dateUtils';
import {
    getGroceryItems,
    getItemsTotalCost,
    getOrderMin,
    getRemainingTimeToEditOrderFormatted,
} from 'utils/orderUtils';
import { getProductsSubtotal, PriceType } from 'utils/priceUtils';

function CartHeader({ account, selections }) {
    const { setCartOpen } = useContext(GlobalContext);

    const { inPlusMembershipTreatment, plusMembershipEligibleOrder } = useAccountPlusMembershipHook();

    const { marketCloseDate, marketStatus, nextOrder = {} } = account ?? {};
    const { selected, selectedBundles } = selections;

    const orderMin = getOrderMin(account);
    const chargeDate = formatDateString(nextOrder?.orderChargeDate?.date);
    const orderScheduledDate = formatDateString(nextOrder?.orderScheduledDate?.date);
    const groceryItems = getGroceryItems(selections);

    const priceType = plusMembershipEligibleOrder ? PriceType.PLUS_MEMBER : PriceType.DEFAULT;

    const groceryItemsTotalCost = inPlusMembershipTreatment
        ? getProductsSubtotal({
              options: { priceType },
              products: groceryItems,
          })
        : getItemsTotalCost(groceryItems);

    const title = orderMin <= 0 || groceryItemsTotalCost < orderMin ? 'Cart' : `${orderScheduledDate} Order`;
    const cartEmpty = selected.length <= 0 && selectedBundles.length <= 0;

    const [remainingTimeToEditOrder, setRemainingTimeToEditOrder] = useState('');

    const initCountdown = () => {
        let interval;

        if (marketStatus === 'Open') {
            interval = setInterval(() => {
                const countdownTimer = calculateCountdown(interval, marketCloseDate.date);
                setRemainingTimeToEditOrder(getRemainingTimeToEditOrderFormatted(countdownTimer));
            }, 1000);
        }
    };

    useEffect(() => {
        initCountdown();
    }, []);

    return (
        <div className={clsx(['flex flex-col bg-white px-15 pt-10', { 'pb-10': cartEmpty, 'pb-5': !cartEmpty }])}>
            <div className="flex items-center justify-between">
                <p className="font-grotesk-bold uppercase">{title}</p>
                {!window.isNativeApp && (
                    <button onClick={() => setCartOpen(false)} type="button">
                        <XMarkIcon className="h-25 w-25" />
                    </button>
                )}
            </div>
            {!cartEmpty && (
                <div className="flex justify-between">
                    <p className="text-body-sm">Edit box within</p>
                    <p className="text-body-sm font-grotesk-bold">{remainingTimeToEditOrder}</p>
                </div>
            )}
            {groceryItems.length > 0 && groceryItemsTotalCost < orderMin && <OrderMinProgress />}
            {groceryItems.length > 0 && groceryItemsTotalCost >= orderMin && (
                <div className="flex justify-between">
                    <p className="text-body-sm">Charge on</p>
                    <p className="text-body-sm font-grotesk-bold">{chargeDate}</p>
                </div>
            )}
            {groceryItems.length > 0 &&
                groceryItemsTotalCost >= orderMin &&
                nextOrder?.cartExperience?.freeShippingEligible && <FreeShippingProgress />}
        </div>
    );
}

export default CartHeader;

CartHeader.propTypes = {
    account: PropTypes.shape({
        cartMinimum: PropTypes.number,
        marketCloseDate: PropTypes.shape({ date: PropTypes.string }),
        nextOrder: PropTypes.shape({
            cartExperience: PropTypes.shape({
                freeShippingEligible: PropTypes.bool,
                freeShippingThreshold: PropTypes.number,
                orderMinimum: PropTypes.number,
                orderMinimumOverridden: PropTypes.bool,
            }),
            orderChargeDate: PropTypes.shape({ date: PropTypes.string }),
            orderScheduledDate: PropTypes.shape({ date: PropTypes.string }),
        }),
    }).isRequired,
    selections: PropTypes.shape({
        selected: PropTypes.arrayOf(PropTypes.shape({})),
        selectedBundles: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
};
