/**
 * Transforms a customer's stored phone, which is in a 5555555555 format, to a format (555) 555-5555 for display.
 * @param {string} phone - The customer's stored phone
 * @example
 * const formattedPhoneForDisplay = getFormattedPhoneForDisplay('5555555555');
 * @return {string} the formatted phone number, (555) 555-5555 for example
 */
export const getFormattedPhoneForDisplay = (phone = '') => {
    const parts = phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    return !parts[2] ? parts[1] : `(${parts[1]}) ${parts[2]}${parts[3] ? `-${parts[3]}` : ''}`;
};

/**
 * Transforms a customer's displayed phone, which is in a (555) 555-5555 format, to a format 555-555-5555 for submission to API.
 * @param {string} phone - The customer's displayed phone
 * @example
 * const formattedPhoneForAPI = getFormattedPhoneForAPI('(555) 555-5555');
 * @return {string} the formatted phone number, 555-555-5555 for example
 */
export const getFormattedPhoneForAPI = (phone = '') => {
    const phoneNumber = phone.replace(/[- )(]/g, '');
    return `${phoneNumber.substring(0, 3)}-${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6, 10)}`;
};

/**
 * Check if customer has a phone number.
 * Note: this is not a validator.
 * @param {string} phone - The customer's stored phone in a 5555555555 format.
 * @example
 * const hasPhoneNumber = getHasPhoneNumber('9999999999');
 * @return {boolean} whether or not it is a phone number
 */
export const getHasPhoneNumber = (phone = '') => phone !== '' && phone !== '9999999999';

/** Regex to test if phone is in a valid 555-555-5555 format for submission to API. */
export const VALID_PHONE_FORMAT_REGEX = /^([0-9]{3})-([0-9]{3})-([0-9]{4})$/;

/**
 * Check if a zip code is valid.
 * @param {string} zip - The zip code to validate.
 * @returns {boolean} Whether or not a zip code is valid.
 */
export const isZipValidFormat = (zip) =>
    zip && zip !== '' && zip.length === 5 && !Number.isNaN(zip) && /^[0-9]*$/.test(zip);

/**
 * Checks if street address is valid - currently only checks for presence of @ symbol
 * @param {Object} street - street name for passed address
 * @example
 * const invalidAddress = isInvalidStreetAddress({ addressResult: { street: "123 fake st"} });
 * @returns {Boolean} whether or not address is valid based on conditions
 */
export const isInvalidStreetAddress = ({ street = '' }) => !street || street?.includes('@');
