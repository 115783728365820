import PropTypes from 'prop-types';

import { IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN } from 'constants/theme';

export function Dairy({ brand, isActive, width }) {
    const eggColor = brand === MISFITS_THEME_DOMAIN ? '#DEA455' : '#F6A685';
    const cartonColor = brand === MISFITS_THEME_DOMAIN ? '#F2EBD1' : '#ECE7E4';
    const activeColor = brand === MISFITS_THEME_DOMAIN ? '#D7E1B8' : '#F0D3E3';

    return isActive ? (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.6505 28.7652C21.902 26.9646 29 22.6358 29 14.9992C29 7.3625 22.9452 2.7363 14.6505 1.1728C7.31233 -0.213857 2.48978 6.96057 1.13553 14.967C-0.132106 22.475 7.73356 30.4815 14.6505 28.7652Z"
                fill={activeColor}
            />
            <path
                d="M11.1926 5.3988L6.52148 8.22152L15.4904 10.6163L19.3876 7.62966L11.1835 5.3988H11.1926Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.52114 8.22156C6.52114 8.22156 3.83501 18.01 4.00801 18.265C4.11728 18.4289 9.17995 20.0041 10.3364 20.4594C11.3106 20.8509 12.7675 21.2425 12.7675 21.2425L15.4992 10.6345L6.52114 8.22156Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.4989 10.6345C15.4989 10.6345 22.2916 11.4813 22.4009 11.6999C22.5102 11.9184 20.5616 20.7781 20.5161 20.9784C20.3977 21.5429 20.1974 21.8525 18.522 21.7068C16.455 21.4974 14.7978 21.7068 12.7764 21.2698L15.4989 10.6345Z"
                fill={cartonColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.499 10.6345L19.3962 7.66614L22.401 11.7181L15.499 10.6345Z"
                fill={cartonColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.1927 5.39885C11.2746 5.03463 11.6571 3.72343 11.6571 3.72343C11.6571 3.72343 11.7846 2.88572 12.3309 3.0132L19.2511 4.86162C19.2511 4.86162 20.1343 4.98 20.0251 5.48991C19.9158 5.99982 19.3877 7.6206 19.3877 7.6206L11.1836 5.38974L11.1927 5.39885Z"
                fill={cartonColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.1212 26.5328C9.57918 24.5933 10.3531 17.6185 13.1212 16.89C16.7999 15.9157 20.2235 21.2425 18.885 24.4567C18.0109 26.5419 15.2337 27.6983 13.1212 26.5419V26.5328Z"
                fill={eggColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
            />
            <path
                d="M20.4146 17.664L23.6107 16.7534L24.6487 19.0936C25.6503 16.972 25.6594 14.3405 23.8838 12.9746C22.436 11.8638 19.495 12.9473 17.7012 14.905L19.9776 14.7684L20.4237 17.6731L20.4146 17.664Z"
                fill={eggColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.8369 18.0647L20.0415 19.8585L18.7667 17.2179L16.6269 18.0101C15.9713 19.3759 15.7528 20.9056 16.3993 22.2259C17.4464 24.3931 20.3875 25.0578 22.2997 23.8649C23.5107 23.1092 24.2665 21.6068 24.5032 19.9951L22.8369 18.0556V18.0647Z"
                fill={eggColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ) : (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.1926 5.3988L6.52148 8.22152L15.4904 10.6163L19.3876 7.62966L11.1835 5.3988H11.1926Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.52114 8.22156C6.52114 8.22156 3.83501 18.01 4.00801 18.265C4.11728 18.4289 9.17995 20.0041 10.3364 20.4594C11.3106 20.8509 12.7675 21.2425 12.7675 21.2425L15.4992 10.6345L6.52114 8.22156Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.4989 10.6345C15.4989 10.6345 22.2916 11.4813 22.4009 11.6999C22.5102 11.9184 20.5616 20.7781 20.5161 20.9784C20.3977 21.5429 20.1974 21.8525 18.522 21.7068C16.455 21.4974 14.7978 21.7068 12.7764 21.2698L15.4989 10.6345Z"
                fill={cartonColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.499 10.6345L19.3962 7.66614L22.401 11.7181L15.499 10.6345Z"
                fill={cartonColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.1927 5.39885C11.2746 5.03463 11.6571 3.72343 11.6571 3.72343C11.6571 3.72343 11.7846 2.88572 12.3309 3.0132L19.2511 4.86162C19.2511 4.86162 20.1343 4.98 20.0251 5.48991C19.9158 5.99982 19.3877 7.6206 19.3877 7.6206L11.1836 5.38974L11.1927 5.39885Z"
                fill={cartonColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.1212 26.5328C9.57918 24.5933 10.3531 17.6185 13.1212 16.89C16.7999 15.9157 20.2235 21.2425 18.885 24.4567C18.0109 26.5419 15.2337 27.6983 13.1212 26.5419V26.5328Z"
                fill={eggColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
            />
            <path
                d="M16.3986 22.235C14.6412 18.6019 19.3033 13.3662 21.9894 14.3678C25.5497 15.7063 25.2674 22.0256 22.299 23.874C20.3868 25.0668 17.4457 24.4021 16.3986 22.235Z"
                fill={eggColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
            />
        </svg>
    );
}

Dairy.propTypes = {
    brand: PropTypes.oneOf([IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN]).isRequired,
    isActive: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Dairy.defaultProps = {
    isActive: false,
    width: 30,
};
