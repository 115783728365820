import PropTypes from 'prop-types';

function NonGMOIcon({ className, width }) {
    return (
        <svg width={width} className={className} viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.0505 11.2868C10.9249 11.5411 9.85653 11.9581 8.71956 12.1724C7.37119 12.4266 5.72287 12.4495 4.35736 12.1295C5.1201 11.064 5.85999 10.0242 6.51417 9.09286C6.75128 8.75577 6.99125 8.40725 7.10551 8.01017C7.19693 7.69593 7.21407 6.47611 7.21978 6.14759C7.25692 3.55941 7.25121 3.61675 7.28834 1.02857C8.82526 1.02285 10.8192 1.00571 12.3562 1C12.3476 3.17967 12.3647 4.05933 12.3562 6.23901C12.3562 6.77892 12.3533 7.32741 12.4933 7.85019C12.6818 8.55294 13.116 9.16142 13.5417 9.75276C13.9245 10.2841 14.3101 10.8097 14.6958 11.3382C13.8302 11.1383 12.8675 11.104 12.0533 11.2897L12.0505 11.2868Z"
                className="fill-[#F2EBD1] if:fill-[#F8F2ED]"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.35721 12.1295C5.72272 12.4495 7.37104 12.4266 8.71941 12.1724C9.85638 11.9581 10.9276 11.5439 12.0503 11.2868C12.8674 11.1011 13.8301 11.1354 14.6928 11.3354C16.164 13.3579 17.6438 15.3748 19.1436 17.3745C13.1673 17.5373 6.51117 17.6887 0.537781 17.4801C1.66332 15.8775 3.04312 13.9607 4.35435 12.1267L4.35721 12.1295Z"
                className="fill-[#DEA455] if:fill-[#F7D46D]"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.62281 16.4054C7.62281 16.4054 12.595 9.45532 3.36298 5.50536C3.36298 5.50536 -0.69598 13.5508 5.47136 15.8767C5.99694 16.0759 7.62281 16.4054 7.62281 16.4054Z"
                className="fill-[#B0C472] if:fill-[#C5DB66]"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.69687 14.0896C6.69687 14.0896 6.60094 10.5474 4.46919 7.96461L6.69687 14.0896Z"
                className="fill-[#B0C472] if:fill-[#C5DB66]"
            />
            <path
                d="M6.69687 14.0896C6.69687 14.0896 6.60094 10.5474 4.46919 7.96461"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.69663 14.09C6.69663 14.09 6.47813 12.5083 4.03543 11.2254L6.69663 14.09Z"
                className="fill-[#B0C472] if:fill-[#C5DB66]"
            />
            <path
                d="M6.69663 14.09C6.69663 14.09 6.47813 12.5083 4.03543 11.2254"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="17.5378"
                cy="15"
                r="5.72"
                className="fill-[#DC6B31] if:fill-[#DD3D96]"
                stroke="#2D2D2D"
                strokeWidth="0.56"
            />
            <path
                d="M17.5693 16.1089L16.3924 18H14.5378L16.5845 14.829L14.7492 12H16.5149L17.5517 13.665L18.5876 12H20.3709L18.4995 14.8282L20.5378 17.9992H18.7453L17.5685 16.1081L17.5693 16.1089Z"
                fill="white"
            />
        </svg>
    );
}

export default NonGMOIcon;

NonGMOIcon.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number.isRequired,
};

NonGMOIcon.defaultProps = {
    className: null,
};
