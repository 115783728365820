import { Outlet } from 'react-router-dom';

import Loader from 'honeydew/Loader';
import useAuth from 'hooks/useAuth';

function AuthRequiredLayout() {
    const { authStatus } = useAuth();

    if (authStatus === 'configuring')
        return (
            <div className="flex h-[360px] items-center justify-center">
                <Loader />
            </div>
        );

    if (authStatus === 'authenticated') return <Outlet />;

    localStorage.setItem('URL_REDIRECT', `${window.location.pathname}${window.location.search}`);
    window.location.href = '/login';

    return null;
}

export default AuthRequiredLayout;
