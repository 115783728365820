import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import * as RadixAccordion from '@radix-ui/react-accordion';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const textSizeVariants = {
    md: {
        headline: 'text-body-md',
        content: 'text-body-sm',
    },
    sm: {
        headline: 'text-body-sm',
        content: 'text-body-xs',
    },
};

const generateAccordionDefaultValues = (value) => {
    if (value) {
        if (Array.isArray(value)) {
            return value.map((item) => `item-${item}`);
        }

        return `item-${value}`;
    }

    return null;
};

export function Accordion({ className, defaultValue: defaultId, items, textBold, textSize, type, ...rootProps }) {
    const defaultValue = generateAccordionDefaultValues(defaultId);
    const textClasses = textSizeVariants[textSize];

    return (
        <RadixAccordion.Root
            className={className}
            defaultValue={defaultValue}
            collapsible="true"
            type={type}
            {...rootProps}
        >
            {items.map(({ content, headline, id }) => (
                <RadixAccordion.Item key={`item-${id}`} value={`item-${id}`} className="border-b border-off-white">
                    <RadixAccordion.Header>
                        <RadixAccordion.Trigger className="group my-20 flex w-full items-center gap-20">
                            {/* TODO: use forthcoming link (and theme) styles */}
                            <span
                                className={clsx(
                                    'text-left',
                                    {
                                        'font-grotesk-bold': textBold,
                                    },
                                    textClasses.headline
                                )}
                            >
                                {headline}
                            </span>
                            <div className="ml-auto">
                                <MinusIcon className="text-heading-sm hidden h-25 w-25 text-grey group-data-[state='open']:block" />
                                <PlusIcon className="text-heading-sm hidden h-25 w-25 text-grey group-data-[state='closed']:block" />
                            </div>
                        </RadixAccordion.Trigger>
                    </RadixAccordion.Header>
                    <RadixAccordion.Content className={clsx('pb-20 text-grey', textClasses.content)}>
                        {content}
                    </RadixAccordion.Content>
                </RadixAccordion.Item>
            ))}
        </RadixAccordion.Root>
    );
}

Accordion.propTypes = {
    className: PropTypes.string,
    defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ]),
    items: PropTypes.arrayOf(
        PropTypes.shape({
            content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
            headline: PropTypes.string.isRequired,
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        })
    ).isRequired,
    textBold: PropTypes.bool,
    textSize: PropTypes.oneOf(['md', 'sm']),
    type: PropTypes.oneOf(['single', 'multiple']),
};

Accordion.defaultProps = {
    className: null,
    defaultValue: null,
    textBold: true,
    textSize: 'md',
    type: 'single',
};
