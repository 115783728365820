import { Auth } from 'aws-amplify';
import { redirect } from 'react-router';

import { ACCOUNT_QUERY_KEY, accountQueryFn } from 'api/queries/useAccountQuery';
import { trackExistingUserRedirect } from 'utils/analyticsUtils';

export default function signupLoader(queryClient) {
    return async () => {
        let authUser;
        try {
            authUser = await Auth.currentAuthenticatedUser();
        } catch {
            authUser = null;
        }

        const account = authUser
            ? await queryClient.ensureQueryData({
                  queryKey: [ACCOUNT_QUERY_KEY, authUser?.username],
                  queryFn: accountQueryFn,
              })
            : null;

        const subscriptionConfirmation =
            account?.subStatus === 'ACTIVE' && window.location.pathname === '/join/confirmation';

        if (account?.subStatus && account?.subStatus !== 'NONE' && !subscriptionConfirmation) {
            trackExistingUserRedirect({ destination: '/account' });
            return redirect('/account');
        }

        return null;
    };
}
