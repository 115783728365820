/** @type {import('tailwindcss').Config} */
const plugin = require('tailwindcss/plugin');
const defaultTheme = require('tailwindcss/defaultTheme');
const containerQueryPlugin = require('@tailwindcss/container-queries');

module.exports = {
    content: ['./index.html', './src/**/*.{js,ts,jsx,tsx}'],
    darkMode: 'class',
    plugins: [
        plugin(({ addVariant }) => {
            addVariant('if', '.theme-imperfectfoods &');
        }),
        containerQueryPlugin,
    ],
    theme: {
        borderRadius: {
            ...defaultTheme.borderRadius,
            md: '0.3125rem', // 5px
            lg: '0.625rem', // 10px
        },
        boxShadow: {
            base: '0px 0px 25px rgba(45, 45, 45, 0.1)',
        },
        fontFamily: {
            grotesk: ['Grotesk Regular', 'sans-serif'],
            'grotesk-bold': ['Grotesk Bold', 'sans-serif'],
        },
        fontSize: {
            // [fontSize, lineHeight]
            // body text mobile
            'body-mobile-xxs': ['0.65rem', '1.125rem'], // 10px, 18px
            'body-mobile-xs': ['0.75rem', '1.25rem'], // 12px, 20px
            'body-mobile-sm': ['0.875rem', '1.5rem'], // 14px, 24px
            'body-mobile-md': ['1rem', '1.625rem'], // 16px, 26px
            'body-mobile-lg': ['1.25rem', '1.875rem'], // 20px, 30px
            // body text desktop
            'body-desktop-xxs': ['0.65rem', '1.125rem'], // 10px, 18px
            'body-desktop-xs': ['0.75rem', '1.25rem'], // 12px, 20px
            'body-desktop-sm': ['0.875rem', '1.5rem'], // 14px, 24px
            'body-desktop-md': ['1rem', '1.625rem'], // 16px, 26px
            'body-desktop-lg': ['1.25rem', '1.875rem'], // 20px, 30px
            // heading text mobile
            'heading-mobile-xs': ['1.5rem', '2.125rem'], // 24px, 34px
            'heading-mobile-sm': ['1.875rem', '2.5rem'], // 30px, 40px
            'heading-mobile-md': ['2.25rem', '2.875rem'], // 36px, 46px
            'heading-mobile-lg': ['2.625rem', '3.25rem'], // 42px, 52px
            'heading-mobile-xl': ['3.125rem', '3.75rem'], // 50px, 60px
            // heading text desktop
            'heading-desktop-xs': ['1.75rem', '2.375rem'], // 28px, 38px
            'heading-desktop-sm': ['2.25rem', '2.875rem'], // 36px, 46px
            'heading-desktop-md': ['2.625rem', '3.25rem'], // 42px, 52px
            'heading-desktop-lg': ['3.125rem', '3.75rem'], // 50px, 60px
            'heading-desktop-xl': ['4rem', '4.625rem'], //  64px, 74px
            // other text
            compact: ['0.75rem', '1rem'], // 12px, 16px
        },
        screens: {
            sm: '375px',
            md: '700px',
            lg: '1024px',
            xl: '1240px',
        },
        // spacing keys represent px values
        spacing: {
            0: '0',
            5: '0.3125rem',
            10: '0.625rem',
            15: '0.9375rem',
            20: '1.25rem',
            25: '1.5625rem',
            30: '1.875rem',
            35: '2.1875rem',
            40: '2.5rem',
            50: '3.125rem',
            60: '3.75rem',
            70: '4.375rem',
            80: '5rem',
        },
        colors: {
            // Misfits Market brand colors
            kale: '#2E8540', // primary
            'kale-hover': '#1E582A', // primary hover
            lime: '#D7E1B8', // secondary
            malt: '#FFF9EA', // neutral
            // Imperfect Foods brand colors
            beet: '#B32274', // primary
            'beet-hover': '#7D1851', // primary hover
            strawberry: '#F0D3E3', // secondary
            oat: '#F4F0F2', // neutral
            // shared neutral
            mango: '#F1C34A',
            black: '#2D2D2D',
            grey: '#666666',
            'grey-light': '#C3C3C3',
            'grey-dark': '#969696',
            'off-white': '#F8F2ED',
            white: '#ffffff',
            // shared alert
            success: '#75B543',
            warning: '#DEA455',
            error: '#D9291F',
            info: '#63ACDE',
            perks: '#E5F2E0',
            transparent: 'transparent',
            // M+
            blueberry: '#44638D',
            'blueberry-hover': '#304563',
            concord: '#6784AC',
            cranberry: '#A13051',
            guava: '#F594A4',
        },
        extend: {
            containers: {
                md: '410px',
                lg: '520px',
                '3xl': '730px',
                '4xl': '900px',
            },
            keyframes: {
                slideRight: {
                    from: { transform: 'translateX(100%)' },
                    to: { transform: 'translateX(0)' },
                },
                fadeIn: { from: { opacity: 0 }, to: { opacity: 1 } },
            },
            animation: { slideRight: 'slideRight 500ms', fadeIn: 'fadeIn 200ms' },
            backgroundImage: {
                'gradient-berry-blend': 'linear-gradient(89.97deg, #F594A4 -7.94%, #A13051 15.8%, #6784AC 99.98%)',
                'gradient-berry-blend-light':
                    'linear-gradient(89.97deg, #F594A44D -7.94%, #A130514D 15.8%, #6784AC4D 99.98%)',
                'plus-membership-gradient':
                    'linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, #FFF 130%), linear-gradient(90deg, #F594A4 0.55%, #A13051 18.8%, #6784AC 53.26%)',
                'plus-membership-gradient-50':
                    'linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, #FFF 50%), linear-gradient(90deg, #F594A4 0.55%, #A13051 18.8%, #6784AC 53.26%)',
                'plus-membership-gradient-70':
                    'linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, #FFF 70%), linear-gradient(90deg, #F594A4 0.55%, #A13051 18.8%, #6784AC 53.26%)',
            },
        },
    },
};
