import {
    BellIcon,
    CheckCircleIcon,
    ExclamationTriangleIcon,
    InformationCircleIcon,
    XMarkIcon,
} from '@heroicons/react/20/solid';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const baseStyles = 'border rounded-md p-5 lg:p-10 flex items-center gap-5 lg:gap-[13px]';

const variantStyles = {
    success: 'border-success bg-success/[0.05]',
    'info-bell': 'border-info bg-info/[0.05]',
    'info-circle': 'border-info bg-info/[0.05]',
    warning: 'border-warning bg-warning/[0.05]',
    error: 'border-error bg-error/[0.05]',
};

const iconStyles = 'h-20 w-20 lg:h-25 lg:w-25';

const iconColors = {
    success: 'text-success',
    'info-bell': 'text-info',
    'info-circle': 'text-info',
    warning: 'text-warning',
    error: 'text-error',
};

export function Alert({ children, onClose, variant }) {
    const containerStyles = clsx(baseStyles, variantStyles[variant ?? 'info-bell']);
    const variantClasses = `${iconStyles} ${iconColors[variant]}`;

    const getIcon = () => {
        if (variant === 'success') return <CheckCircleIcon className={variantClasses} />;
        if (variant === 'info-bell') return <BellIcon className={variantClasses} />;
        if (variant === 'info-circle') return <InformationCircleIcon className={variantClasses} />;
        if (variant === 'error' || variant === 'warning') return <ExclamationTriangleIcon className={variantClasses} />;
        return null;
    };

    return (
        <div className={containerStyles} role="alert">
            <div className="shrink-0">{getIcon()}</div>

            <p className="text-body-sm min-w-0 flex-1 break-words">
                <span className="sr-only">{variant}:</span>
                {children}
            </p>

            {onClose && (
                <button type="button" onClick={onClose} className="shrink-0" aria-label="Close alert">
                    <XMarkIcon className={`h-[22px] w-[22px] ${iconColors[variant]}`} />
                </button>
            )}
        </div>
    );
}

Alert.propTypes = {
    children: PropTypes.node.isRequired,
    onClose: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    variant: PropTypes.oneOf(Object.keys(variantStyles)),
};

Alert.defaultProps = {
    onClose: false,
    variant: 'info-bell',
};
