import PropTypes from 'prop-types';

import { IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN } from 'constants/theme';

export function Featured({ brand, isActive, width }) {
    const roofColor = brand === MISFITS_THEME_DOMAIN ? '#DEA455' : '#C5DB66';
    const houseColor = brand === MISFITS_THEME_DOMAIN ? '#F2EBD1' : '#ECE7E4';
    const activeColor = brand === MISFITS_THEME_DOMAIN ? '#D7E1B8' : '#F0D3E3';

    const getWindowColor = () => {
        if (brand === MISFITS_THEME_DOMAIN) {
            return isActive ? '#DC6B31' : '#EED099';
        }
        return isActive ? '#DD3D96' : '#F7D46D';
    };

    return (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            {isActive && (
                <path
                    d="M14.6505 28.7652C21.902 26.9646 29 22.6358 29 14.9992C29 7.3625 22.9452 2.7363 14.6505 1.1728C7.31233 -0.213857 2.48978 6.96057 1.13553 14.967C-0.132106 22.475 7.73356 30.4815 14.6505 28.7652Z"
                    fill={activeColor}
                />
            )}
            <path
                d="M3.28593 13.7695C3.0272 16.4728 2.75954 23.9137 3.28593 25.9122C3.42868 26.4475 25.3765 26.8668 25.3587 25.9122C25.1892 19.0512 25.3587 11.4498 25.3587 11.4498C25.3587 11.4498 3.01828 9.70111 3.04504 10.9769C3.07181 12.2438 3.29486 13.7695 3.29486 13.7695H3.28593Z"
                fill={houseColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.24615 10.5308C8.264 10.9501 8.19262 11.3694 8.04095 11.762C7.88928 12.1545 7.66623 12.5114 7.37181 12.8148C7.07739 13.1181 6.72943 13.359 6.34579 13.5196C5.96215 13.6802 5.54282 13.7694 5.12349 13.7694C4.70416 13.7694 4.28484 13.6802 3.90119 13.5196C3.51755 13.359 3.16068 13.1181 2.87518 12.8148C2.58075 12.5114 2.35771 12.1545 2.20604 11.762C2.05436 11.3694 1.99191 10.9501 2.00083 10.5308H8.24615Z"
                fill={roofColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.5987 10.5308C14.6165 10.9501 14.5452 11.3694 14.3935 11.762C14.2418 12.1545 14.0188 12.5114 13.7243 12.8148C13.4299 13.1181 13.082 13.359 12.6983 13.5196C12.3147 13.6802 11.8954 13.7694 11.476 13.7694C11.0567 13.7694 10.6374 13.6802 10.2537 13.5196C9.87009 13.359 9.51322 13.1181 9.22772 12.8148C8.93329 12.5114 8.71025 12.1545 8.55857 11.762C8.4069 11.3694 8.34445 10.9501 8.35337 10.5308H14.5987Z"
                fill={roofColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.8442 10.5308C20.8442 11.3605 20.5141 12.1545 19.9253 12.7434C19.3364 13.3322 18.5424 13.6623 17.7126 13.6623C16.8829 13.6623 16.0889 13.3322 15.5 12.7434C14.9112 12.1545 14.5811 11.3605 14.5811 10.5308H20.8264H20.8442Z"
                fill={roofColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M27.0005 10.6467C27.0005 11.4765 26.6704 12.2705 26.0815 12.8594C25.4927 13.4482 24.6986 13.7783 23.8689 13.7783C23.0392 13.7783 22.2451 13.4482 21.6563 12.8594C21.0674 12.2705 20.7373 11.4765 20.7373 10.6467H26.9826H27.0005Z"
                fill={roofColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.60567 4C4.48151 4.32119 1.55513 10.5933 2.06368 10.5844C5.15065 10.5576 27.1877 11.4587 26.9914 10.5844C26.6792 9.19254 25.0465 4.47286 23.4494 4.31227C21.8524 4.15167 5.60567 4 5.60567 4Z"
                fill={roofColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M7.25619 26.3136L12.4844 26.4653C12.4844 26.4653 12.5469 19.0691 12.4844 18.1858C12.3506 16.0891 8.05916 15.5628 7.45247 17.9538C7.21158 20.5233 7.25619 26.3136 7.25619 26.3136Z"
                fill={getWindowColor()}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M21.6652 17.3739C21.3172 17.3739 16.4369 16.9724 16.1514 17.3114C15.8659 17.6505 15.5447 21.4334 16.1514 21.6475C16.7581 21.8527 21.7008 22.2185 21.9328 21.6475C22.1648 21.0765 22.6912 17.4274 21.6652 17.3739Z"
                fill={getWindowColor()}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />

            <path d="M8.26367 10.9234L10.8421 4" stroke="#2D2D2D" strokeWidth="0.5" strokeMiterlimit="10" />
            <path d="M14.5986 11.1999L14.8752 4.17834" stroke="#2D2D2D" strokeWidth="0.5" strokeMiterlimit="10" />
            <path d="M20.755 11.2534L19.042 4.17834" stroke="#2D2D2D" strokeWidth="0.5" strokeMiterlimit="10" />
        </svg>
    );
}

Featured.propTypes = {
    brand: PropTypes.oneOf([IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN]).isRequired,
    isActive: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Featured.defaultProps = {
    isActive: false,
    width: 30,
};
