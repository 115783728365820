import PropTypes from 'prop-types';

export function EatItAllIconMM({ className, width }) {
    return (
        <svg width={width} height={width} fill="none" className={className}>
            <path
                d="M15.2674 38.2611C15.8272 39.5521 18.004 43.8587 18.107 44.8046C18.21 45.7506 16.1692 46.4826 17.154 47.8857C18.1388 49.2888 20.401 48.6854 20.7694 47.6115C21.9087 47.594 24.3515 47.4272 24.0952 45.2525C23.8388 43.0778 21.4751 43.9405 21.0372 43.4815C20.5993 43.0225 18.0979 37.0755 18.0979 37.0755L15.2674 38.2611Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="1.05298"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.211 23.6791C20.9723 27.7346 20.186 31.265 20.1043 33.1763C19.9626 36.3714 19.6382 38.2526 17.4671 39.1958C15.5844 40.0243 13.353 38.862 10.9283 36.784C9.4719 35.5406 6.5323 33.5498 4.93813 29.8802C2.56282 24.4261 3.83618 18.6079 7.77793 16.8963C11.7197 15.1848 16.8381 18.2405 19.211 23.6791Z"
                fill="#DEA455"
                stroke="#2D2D2D"
                strokeWidth="1.05298"
                strokeMiterlimit="10"
            />
            <path
                d="M25.1666 2.2295C25.8002 1.87081 27.99 0.216721 30.0068 2.03191C32.2985 4.08885 30.5067 8.43619 30.6939 10.8747C30.8812 13.3131 31.975 16.2443 29.8081 19.9862C28.3444 22.5191 23.5212 24.4359 19.6795 23.1711C15.8378 21.9064 25.1666 2.2295 25.1666 2.2295Z"
                fill="#2E8540"
                stroke="#2D2D2D"
                strokeWidth="1.05298"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M24.3496 2.78397C23.7337 3.34498 19.6646 6.48744 18.0614 7.48477C16.4583 8.4821 11.8931 11.2542 12.2122 15.3497C12.5312 19.4451 14.6961 21.8501 17.8606 22.8123C21.0251 23.7746 24.6961 23.0701 26.6008 19.5409C28.5055 16.0116 28.0071 13.4803 28.1405 10.5987C28.2348 8.68284 29.6133 6.26409 29.6952 4.33162C29.7772 2.39914 27.0601 0.434811 24.3496 2.78397Z"
                fill="#B0C472"
                stroke="#2D2D2D"
                strokeWidth="1.05298"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.6194 10.8022C18.9014 10.9922 15.2669 12.8732 16.2446 15.9823C17.2223 19.0915 20.2229 19.5995 21.9502 18.6051C23.6776 17.6108 24.8486 15.5216 24.0565 12.8131C23.2645 10.1047 21.409 10.3288 19.6194 10.8022Z"
                fill="#DC6B31"
                stroke="#2D2D2D"
                strokeWidth="1.05298"
                strokeMiterlimit="10"
            />
            <path
                d="M14.2774 23.9103C15.0385 23.2511 20.9895 19.7551 26.4862 22.0939C31.9829 24.4327 34.3461 27.5325 36.2589 32.361C39.0046 31.175 41.6395 30.9551 44.6772 34.0006C42.8815 33.5872 40.1202 35.3698 39.2645 36.9732C38.2315 38.8642 38.7036 40.8899 40.0324 43.0129C37.1614 42.653 33.6676 41.1884 34.0446 36.8916C31.802 38.2111 29.2106 38.8162 26.6155 38.6262C24.0204 38.4363 21.5447 37.4604 19.5181 35.8285C13.5624 31.1219 14.2774 23.9103 14.2774 23.9103Z"
                fill="#F1C34B"
                stroke="#2D2D2D"
                strokeWidth="1.05298"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.9228 21.3077C22.9228 21.3077 24.468 20.6899 26.38 20.1754C28.2921 19.661 31.1153 21.0171 32.8672 22.428C34.6192 23.8389 35.7934 24.9852 35.9351 25.5473C36.0768 26.1095 35.0046 29.3414 35.0046 29.3414C35.0046 29.3414 32.3247 24.8054 28.8185 23.2546C26.9377 22.3734 24.9585 21.7198 22.9228 21.3077Z"
                fill="#F2EBD1"
                stroke="#2D2D2D"
                strokeWidth="1.05298"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.1216 33.3826C17.6564 33.2683 20.8782 32.9696 23.6219 29.4261C26.3657 25.8826 24.8247 21.7609 24.8247 21.7609C24.8247 21.7609 19.8805 20.1753 14.2801 23.9061C13.84 29.0664 17.1216 33.3826 17.1216 33.3826Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="1.05298"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.3267 25.7119C20.7394 25.8488 21.1851 25.6252 21.322 25.2125C21.459 24.7997 21.2354 24.354 20.8226 24.2171C20.4098 24.0801 19.9642 24.3037 19.8272 24.7165C19.6903 25.1293 19.9139 25.5749 20.3267 25.7119Z"
                fill="#F1C34B"
                stroke="#2D2D2D"
                strokeWidth="1.05298"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M25.0488 26.3854C25.0488 26.3854 27.699 27.3011 28.4464 31.4239C24.7017 33.4774 21.8854 31.2347 21.8854 31.2347C21.8854 31.2347 23.5977 30.6768 25.0488 26.3854Z"
                fill="#F2EBD1"
                stroke="#2D2D2D"
                strokeWidth="1.05298"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M31.2504 28.7571C32.1065 29.4059 31.303 31.1405 30.4081 31.0214C31.133 31.8044 30.4021 33.5814 29.1994 33.5379C29.3455 33.7447 29.4308 33.9883 29.4455 34.2411C29.4602 34.4938 29.4037 34.7457 29.2825 34.968C29.1612 35.1903 28.9801 35.3742 28.7597 35.4987C28.5392 35.6233 28.2882 35.6835 28.0353 35.6726"
                stroke="#2D2D2D"
                strokeWidth="1.05298"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M32.9464 31.1752C33.7517 31.757 32.9935 33.5614 31.885 33.3987C32.4637 34.2928 31.8568 35.6687 30.7209 35.5335"
                stroke="#2D2D2D"
                strokeWidth="1.05298"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

EatItAllIconMM.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number.isRequired,
};

EatItAllIconMM.defaultProps = {
    className: null,
};
