import PropTypes from 'prop-types';

function PlanCanceledIcon({ width }) {
    return (
        <svg width={width} viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M26.7486 53.2739C40.9628 49.8161 54.8768 41.477 54.8768 26.8746C54.8768 12.2722 43.0126 3.33266 26.7486 0.330389C12.3687 -2.32507 2.93749 11.4388 0.266502 26.8125C-2.22331 41.2286 13.2073 56.5919 26.7486 53.2998"
                className="fill-malt if:fill-off-white"
            />
            <path
                d="M32.2879 41.4036C32.2879 41.4036 26.7987 40.9484 25.0054 40.6207C21.5553 40.0381 16.7032 39.7104 16.7032 39.7104C15.785 39.6095 14.9442 39.1494 14.3643 38.4304C13.7843 37.7114 13.5125 36.7922 13.6082 35.8734C13.6082 35.8734 14.127 31.3901 14.2545 29.8927C14.4866 27.0343 14.8781 24.0621 14.8781 24.0621L15.4059 19.1782L23.6899 20.0885C26.8264 20.2574 29.9516 20.5931 33.0524 21.0944C34.996 21.4176 40.8859 21.9365 40.8859 21.9365C40.8859 21.9365 40.1804 27.4894 40.0894 29.351C39.9392 32.3232 39.0879 38.6317 39.0879 38.6317"
                fill="white"
            />
            <path
                d="M32.2879 41.4036C32.2879 41.4036 26.7987 40.9484 25.0054 40.6207C21.5553 40.0381 16.7032 39.7104 16.7032 39.7104C15.785 39.6095 14.9442 39.1494 14.3643 38.4304C13.7843 37.7114 13.5125 36.7922 13.6082 35.8734C13.6082 35.8734 14.127 31.3901 14.2545 29.8927C14.4866 27.0343 14.8781 24.0621 14.8781 24.0621L15.4059 19.1782L23.6899 20.0885C26.8264 20.2574 29.9516 20.5931 33.0524 21.0944C34.996 21.4176 40.8859 21.9365 40.8859 21.9365C40.8859 21.9365 40.1804 27.4894 40.0894 29.351C39.9392 32.3232 39.0879 38.6317 39.0879 38.6317"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M34.1865 36.1192C34.1022 38.032 33.4405 39.8743 32.2886 41.4036C32.2886 41.4036 38.7745 40.4341 39.0704 38.6272C39.2934 37.2708 34.2548 34.467 34.1865 36.1192Z"
                className="fill-[#DE8255] if:fill-[#E95CA2]"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M40.8673 21.9365L15.3784 19.2056L15.5878 17.2666C15.6886 16.3487 16.1488 15.5082 16.8679 14.929C17.587 14.3498 18.5064 14.0791 19.4246 14.1761C19.4246 14.1761 26.2156 15.0864 28.5005 15.1501C31.9779 15.2184 37.9723 16.1697 37.9723 16.1697C38.8905 16.2705 39.7315 16.7307 40.3115 17.4497C40.8914 18.1686 41.1633 19.0879 41.0675 20.0067L40.8673 21.9365Z"
                className="fill-[#DE8255] if:fill-[#E95CA2]"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M20.5573 11.5137L19.9565 17.0621" stroke="#2D2D2D" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M37.4501 13.334L36.8491 18.8824" stroke="#2D2D2D" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M24.355 26.2788L29.744 32.9651" stroke="#2D2D2D" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M30.3955 26.9297L23.7046 32.3187" stroke="#2D2D2D" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

PlanCanceledIcon.propTypes = {
    width: PropTypes.number,
};

PlanCanceledIcon.defaultProps = {
    width: 55,
};

export default PlanCanceledIcon;
