import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';

import useCustomerExistsQuery from 'api/queries/useCustomerExistsQuery';
import { LoginErrors } from 'constants/login';
import useAuth from 'hooks/useAuth';
import useGlobalStore from 'hooks/useGlobalStore';
import { trackExistingUserRedirect } from 'utils/analyticsUtils';
import logError from 'utils/errorUtils';
import { getIsSisterBrand } from 'utils/loginUtils';

import LoginError from '../shared/LoginError/LoginError';
import LoginForm from '../shared/LoginForm';
import ResetPasswordForm from '../shared/ResetPasswordForm/ResetPasswordForm';

function Login() {
    const { isImperfectTheme } = useGlobalStore();
    const { authStatus } = useAuth();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [newPasswordRequired, setNewPasswordRequired] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(LoginErrors.NoError);
    const [cognitoUser, setCognitoUser] = useState(null);

    const customerExistsQuery = useCustomerExistsQuery({
        email,
        extendedQueryKeys: ['login'],
        queryProps: { enabled: false },
    });

    const { data: customerExists } = customerExistsQuery;

    const login = async () => {
        try {
            if (newPasswordRequired) {
                await Auth.completeNewPassword(cognitoUser, newPassword);
            }

            const authResp = await Auth.signIn({
                username: email.trim().toLowerCase(),
                password: newPasswordRequired ? newPassword : password,
            });

            if (authResp?.challengeName === 'NEW_PASSWORD_REQUIRED') {
                setCognitoUser(authResp);
                setNewPasswordRequired(true);
                setLoading(false);
            }
        } catch (e) {
            setError(LoginErrors.Invalid);
            setLoading(false);
            if (e.message !== 'Incorrect username or password.' && e.message !== 'Password attempts exceeded') {
                logError(e);
            }
        }
    };

    useEffect(() => {
        if (authStatus === 'authenticated') {
            const urlRedirect = localStorage.getItem('URL_REDIRECT');
            const destination = urlRedirect ?? '/shop';
            trackExistingUserRedirect({ destination });
            window.location.href = destination;
            localStorage.removeItem('URL_REDIRECT');
        }
    }, [authStatus]);

    useEffect(() => {
        if (customerExistsQuery?.fetchStatus === 'fetching' || typeof customerExists === 'undefined') {
            return;
        }

        if (getIsSisterBrand(email, customerExists?.brand, isImperfectTheme)) {
            setError(LoginErrors.WrongSite);
            setLoading(false);
        } else {
            login();
        }
    }, [customerExistsQuery?.fetchStatus]);

    const loginSubmit = async (e) => {
        e.preventDefault();

        const passwordIsValid =
            (!newPasswordRequired && password !== '') || (newPasswordRequired && newPassword !== '');

        if (newPassword !== confirmPassword) {
            setError(LoginErrors.PasswordMatch);
        }

        if (email !== '' && passwordIsValid) {
            setLoading(true);
            customerExistsQuery?.refetch();
        } else {
            setError(LoginErrors.Invalid);
        }
    };

    return (
        <>
            <h1 className="text-body-lg mb-20 text-center font-grotesk-bold">
                {newPasswordRequired ? 'Create New Password' : 'Welcome back!'}
            </h1>

            <LoginError error={error} />

            {newPasswordRequired ? (
                <form onSubmit={loginSubmit}>
                    <ResetPasswordForm
                        confirmPassword={confirmPassword}
                        loading={loading}
                        newPassword={newPassword}
                        setConfirmPassword={setConfirmPassword}
                        setError={setError}
                        setNewPassword={setNewPassword}
                        setShowConfirmPassword={setShowConfirmPassword}
                        setShowNewPassword={setShowNewPassword}
                        showConfirmPassword={showConfirmPassword}
                        showNewPassword={showNewPassword}
                    />
                </form>
            ) : (
                <LoginForm
                    email={email}
                    loading={loading}
                    loginSubmit={loginSubmit}
                    password={password}
                    setEmail={setEmail}
                    setPassword={setPassword}
                    setShowPassword={setShowPassword}
                    showPassword={showPassword}
                />
            )}
        </>
    );
}

export { Login as Component };
