import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

function BenefitBase({
    bgColor,
    headline,
    horizontalImage,
    horizontalImagePosition,
    layout,
    subheadline,
    verticalImage,
}) {
    const withinHorizontalBreakpoint = useMediaQuery({ maxWidth: 539 });

    if (layout === 'horizontal' || withinHorizontalBreakpoint) {
        return (
            <div
                className={clsx([
                    bgColor,
                    'grid grid-cols-5 items-center justify-between gap-5 overflow-hidden rounded-xl bg-opacity-50',
                ])}
            >
                <div className="col-span-3 p-10">
                    <h5 className="text-body-sm font-grotesk-bold">{headline}</h5>
                    {subheadline && <p className="text-body-xs text-grey">{subheadline}</p>}
                </div>
                <div className="relative col-span-2 flex justify-end">
                    <div className={clsx('absolute flex items-center justify-end', horizontalImagePosition)}>
                        {horizontalImage}
                    </div>
                </div>
            </div>
        );
    }

    // vertical layout
    return (
        <div className={clsx([bgColor, 'flex flex-col items-center rounded-xl bg-opacity-50 p-10 text-center'])}>
            <div className="flex h-[120px] items-center justify-end">{verticalImage}</div>
            <div className="p-10">
                <h5 className="font-grotesk-bold text-body-mobile-sm md:text-body-desktop-md">{headline}</h5>
                {subheadline && <p className="text-body-mobile-xs text-grey md:text-body-desktop-sm">{subheadline}</p>}
            </div>
        </div>
    );
}

BenefitBase.propTypes = {
    bgColor: PropTypes.string,
    headline: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    horizontalImage: PropTypes.node.isRequired,
    horizontalImagePosition: PropTypes.string,
    layout: PropTypes.oneOf(['horizontal', 'responsive']),
    subheadline: PropTypes.string,
    verticalImage: PropTypes.node,
};

BenefitBase.defaultProps = {
    bgColor: 'bg-white',
    horizontalImagePosition: undefined,
    layout: 'responsive',
    subheadline: undefined,
    verticalImage: null,
};

export default BenefitBase;
