import PropTypes from 'prop-types';

function MMDeliveryTruckIcon({ className, width }) {
    return (
        <svg width={width} viewBox="0 0 37 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path
                d="M21.6079 21.3377L14.2954 21.8865L1.98036 22.8181C1.96771 21.1327 1.8355 19.4503 1.58474 17.7836C1.49541 17.2093 1.15085 12.2258 1.13809 11.6452C1.04875 7.86773 0.5 3.19056 0.5 3.19056C0.5 3.19056 5.7642 2.8779 7.51894 2.66095C12.1068 2.09306 20.1339 1.71021 20.1339 1.71021"
                fill="white"
            />
            <path
                d="M21.6079 21.3377L14.2954 21.8865L1.98036 22.8181C1.96771 21.1327 1.8355 19.4503 1.58474 17.7836C1.49541 17.2093 1.15085 12.2258 1.13809 11.6452C1.04875 7.86773 0.5 3.19056 0.5 3.19056C0.5 3.19056 5.7642 2.8779 7.51894 2.66095C12.1068 2.09306 20.1339 1.71021 20.1339 1.71021"
                stroke="#2D2D2D"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.6073 21.3375C21.6073 21.3375 21.3457 16.086 21.0777 14.3568C20.4601 10.1692 20.1424 5.94285 20.127 1.70998L24.2426 1.39731L27.3309 1.1676C27.3309 1.1676 27.5862 3.54128 27.6053 4.77278C27.6053 5.88305 27.9371 9.2011 27.9371 9.2011L26.2526 9.32233C26.2526 9.32233 26.5652 12.3851 26.6609 14.7588C26.7949 17.8982 27.1267 20.9291 27.1267 20.9291L21.6073 21.3375Z"
                fill="#B0C372"
                stroke="#2D2D2D"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M36.1186 20.2466L35.2827 9.15669C35.2636 9.01474 35.1892 8.8861 35.0756 8.79883C34.962 8.71157 34.8186 8.67276 34.6765 8.69089C33.3537 8.67636 32.0322 8.77671 30.7267 8.99079L25.8263 9.36088L23.7971 9.51402C23.6792 9.53865 23.5756 9.60864 23.5088 9.70889C23.442 9.80915 23.4172 9.93166 23.4398 10.05C23.433 11.7698 23.5631 13.4874 23.829 15.1866C24.0332 16.6606 24.2757 21.1399 24.2757 21.1399C24.2757 21.1399 28.7104 20.9421 29.3804 20.8656C32.4623 20.5274 36.1186 20.2466 36.1186 20.2466Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M25.9668 26.1739C25.9942 26.6139 26.1088 27.0442 26.3038 27.4396C26.4987 27.8351 26.7703 28.1879 27.1027 28.4776C27.4351 28.7673 27.8217 28.9881 28.2401 29.1272C28.6585 29.2664 29.1003 29.321 29.54 29.2881C29.9797 29.2552 30.4085 29.1352 30.8015 28.9353C31.1944 28.7354 31.5438 28.4594 31.8293 28.1234C32.1148 27.7874 32.3308 27.3981 32.4647 26.978C32.5986 26.5579 32.6477 26.1154 32.6093 25.6761"
                stroke="#2D2D2D"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M25.8704 26.0657C25.9373 26.947 26.3511 27.7658 27.021 28.3424C27.6909 28.919 28.5622 29.2063 29.4437 29.1412C31.2814 29.0009 32.3916 27.5141 32.5192 25.568C32.6405 23.7303 30.7773 22.3775 28.946 22.4924C28.0646 22.5593 27.2458 22.9731 26.6692 23.643C26.0926 24.3129 25.8053 25.1842 25.8704 26.0657V26.0657Z"
                fill="#B0C372"
                stroke="#2D2D2D"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.1443 26.5761C19.2847 28.4074 20.9054 29.9516 22.7176 29.6453C24.836 29.2944 25.908 27.9097 25.7932 26.072C25.7263 25.1903 25.312 24.3713 24.6412 23.7951C23.9705 23.219 23.0984 22.9328 22.2167 22.9997C21.335 23.0665 20.516 23.4809 19.9398 24.1516C19.3636 24.8223 19.0775 25.6944 19.1443 26.5761V26.5761Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.2578 27.3416C10.29 27.7792 10.4086 28.2061 10.6066 28.5977C10.8046 28.9893 11.0782 29.3378 11.4116 29.6231C11.745 29.9084 12.1315 30.1249 12.549 30.2601C12.9664 30.3953 13.4065 30.4465 13.8439 30.4108C16.0771 30.2449 16.4791 28.9368 16.913 26.8375"
                fill="#B0C372"
            />
            <path
                d="M10.2578 27.3416C10.29 27.7792 10.4086 28.2061 10.6066 28.5977C10.8046 28.9893 11.0782 29.3378 11.4116 29.6231C11.745 29.9084 12.1315 30.1249 12.549 30.2601C12.9664 30.3953 13.4065 30.4465 13.8439 30.4108C16.0771 30.2449 16.4791 28.9368 16.913 26.8375"
                stroke="#2D2D2D"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.5691 30.3285C15.4086 30.3285 16.8999 28.8372 16.8999 26.9977C16.8999 25.1581 15.4086 23.6669 13.5691 23.6669C11.7295 23.6669 10.2383 25.1581 10.2383 26.9977C10.2383 28.8372 11.7295 30.3285 13.5691 30.3285Z"
                stroke="#2D2D2D"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.45409 27.7569C3.44676 28.2012 3.54745 28.6407 3.74752 29.0375C3.94759 29.4343 4.24104 29.7765 4.60265 30.0348C5.30594 30.551 6.15503 30.8303 7.02737 30.8325C9.24153 30.7623 10.2178 29.0905 10.0966 27.2592C10.0691 26.8191 9.95459 26.3889 9.7596 25.9934C9.56461 25.598 9.29304 25.2451 8.96065 24.9555C8.62826 24.6658 8.24166 24.4449 7.82327 24.3058C7.40488 24.1667 6.96302 24.112 6.52334 24.1449C6.08365 24.1779 5.65488 24.2978 5.2619 24.4977C4.86891 24.6976 4.51953 24.9736 4.23402 25.3096C3.94851 25.6456 3.73255 26.0349 3.59867 26.455C3.46479 26.8751 3.41565 27.3176 3.45409 27.7569V27.7569Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26.4395 20.9801L31.2953 20.61L35.8193 20.2719"
                stroke="#2D2D2D"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M27.0883 20.9291C27.0883 20.9291 17.4788 21.8288 14.2628 21.899C9.79624 21.9947 1.94141 22.8242 1.94141 22.8242L2.32426 27.9289L3.36434 27.846C3.29749 26.9592 3.58565 26.0822 4.16542 25.4079C4.7452 24.7336 5.56909 24.3172 6.45586 24.2504C7.34263 24.1835 8.21963 24.4717 8.89394 25.0515C9.56825 25.6312 9.98462 26.4551 10.0515 27.3419L19.074 26.6336C19.0071 25.7468 19.2953 24.8698 19.8751 24.1955C20.4549 23.5212 21.2787 23.1049 22.1655 23.038C23.0523 22.9712 23.9293 23.2593 24.6036 23.8391C25.2779 24.4189 25.6943 25.2428 25.7611 26.1295C25.7611 26.1295 29.0728 25.9317 30.1703 25.7913C33.1246 25.4213 36.5001 25.3192 36.5001 25.3192L36.3087 22.7668L36.1173 20.2145L27.0883 20.9291Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.4714 8.84405V8.71643V8.84405ZM6.75775 13.7063C6.73065 13.6044 6.69212 13.5059 6.64289 13.4127C6.49426 13.1875 6.35999 12.953 6.2409 12.7108C6.1843 12.4096 6.20818 12.0988 6.31013 11.8097C6.41208 11.5207 6.58849 11.2636 6.82155 11.0646C7.75012 9.92452 9.04392 9.14012 10.4842 8.84405C11.4158 8.71643 12.1623 9.12481 13.0365 9.02909C13.3749 8.97957 13.7204 9.03423 14.027 9.18583C14.3336 9.33742 14.5868 9.57876 14.753 9.87775C14.8929 10.3642 15.0832 10.8347 15.3209 11.2815C15.4963 11.5158 15.6157 11.7871 15.6699 12.0747C15.7242 12.3623 15.7117 12.6585 15.6335 12.9406C15.5184 13.2069 15.3774 13.4612 15.2124 13.6999C14.7274 14.5166 14.6509 15.6652 14.0574 16.399C13.4001 17.0184 12.552 17.3963 11.6519 17.471C11.0463 17.5503 10.431 17.5045 9.84381 17.3366C9.25664 17.1687 8.71015 16.8821 8.2381 16.4947C7.67659 16.0927 7.18526 15.9204 6.96193 15.2185C6.81517 14.7336 6.88536 14.204 6.74498 13.719"
                fill="#F1C34A"
            />
            <path
                d="M11.2078 9.92816C11.2078 9.92816 10.3209 7.42686 13.3709 7.03125C13.3709 7.03125 14.009 9.39217 12.0246 9.83244"
                fill="#2E8540"
            />
            <path
                d="M29.8455 24.3621C30.3988 24.3621 30.8473 23.9136 30.8473 23.3603C30.8473 22.807 30.3988 22.3585 29.8455 22.3585C29.2923 22.3585 28.8438 22.807 28.8438 23.3603C28.8438 23.9136 29.2923 24.3621 29.8455 24.3621Z"
                fill="#2D2D2D"
            />
            <path
                d="M33.828 24.024C34.3812 24.024 34.8298 23.5755 34.8298 23.0222C34.8298 22.4689 34.3812 22.0204 33.828 22.0204C33.2747 22.0204 32.8262 22.4689 32.8262 23.0222C32.8262 23.5755 33.2747 24.024 33.828 24.024Z"
                fill="#2D2D2D"
            />
            <path
                d="M32.1874 14.6887L27.6506 15.046C27.5499 15.0497 27.4514 15.0148 27.3755 14.9484C27.2996 14.882 27.252 14.7891 27.2423 14.6887L27.0189 12.0725C27.0146 12.0197 27.0207 11.9666 27.037 11.9161C27.0532 11.8657 27.0793 11.8189 27.1137 11.7786C27.1481 11.7383 27.1902 11.7051 27.2374 11.6811C27.2846 11.6571 27.3362 11.6427 27.389 11.6386L31.9194 11.2813C32.0251 11.2725 32.13 11.3057 32.2113 11.3738C32.2925 11.4419 32.3436 11.5394 32.3533 11.645L32.5767 14.2612C32.5801 14.315 32.5727 14.369 32.5548 14.4199C32.537 14.4708 32.5091 14.5176 32.4727 14.5575C32.4364 14.5974 32.3924 14.6296 32.3434 14.6521C32.2944 14.6746 32.2414 14.6871 32.1874 14.6887V14.6887Z"
                fill="#2D2D2D"
            />
        </svg>
    );
}

MMDeliveryTruckIcon.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number,
};

MMDeliveryTruckIcon.defaultProps = {
    className: '',
    width: 23,
};

export default MMDeliveryTruckIcon;
