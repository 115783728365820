import PropTypes from 'prop-types';

function FoodPreferencesIcon({ width }) {
    return (
        <svg width={width} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3601_40910)">
                <path
                    d="M10.0025 19.5441C15.2722 19.5441 19.5441 15.2722 19.5441 10.0025C19.5441 4.73285 15.2722 0.460938 10.0025 0.460938C4.73285 0.460938 0.460938 4.73285 0.460938 10.0025C0.460938 15.2722 4.73285 19.5441 10.0025 19.5441Z"
                    stroke="#2D2D2D"
                    strokeWidth="0.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <path
                d="M13.0369 3.21472L7.4856 16.3667C6.90948 17.7316 8.51211 17.9454 8.83533 17.1504C9.09391 16.5143 10.9799 12.0384 11.7654 10.3854C12.1113 10.4559 12.9171 10.9864 13.3904 10.3854C14.4242 9.07288 14.5221 5.59134 14.4242 4.24092C14.2625 2.0106 13.3905 2.19788 13.0369 3.21472Z"
                className="fill-mango if:fill-[#F7D46D]"
                stroke="#2D2D2D"
                strokeWidth="0.5"
            />
            <path
                d="M9.81902 14.0562C9.67049 13.7157 9.24752 12.5163 8.90649 12.1558C8.56546 11.7954 7.9829 11.4986 7.24586 11.2521C6.50882 11.0056 6.05954 10.6219 5.75572 10.2428C5.37826 9.77166 4.9812 8.8184 4.8285 8.37171C4.67581 7.92501 3.93501 6.05441 3.8994 5.82719C3.8321 5.39785 4.46278 5.15858 4.68295 5.53667C4.84395 5.81313 5.77099 7.91236 6.09967 8.55925C6.42836 9.20613 6.85569 9.66942 7.30296 9.46998C7.75024 9.27054 5.91208 5.79598 5.73422 5.07384C5.55636 4.3517 6.34843 4.12412 6.68915 4.73015C7.02988 5.33619 8.42036 8.91452 8.97395 8.66473C9.52755 8.41493 9.3166 8.02901 8.9378 7.02795C8.85035 6.79683 7.72525 4.61963 7.67973 4.32923C7.46549 3.74209 8.25165 3.47661 8.52287 3.88676C8.67483 4.03107 10.3186 7.43699 10.4005 7.61473C10.9447 8.79434 10.5754 9.66985 10.3056 11.1346C10.198 11.7184 11.4037 14.0147 12.1697 15.1443C12.9357 16.2739 13.4814 17.0388 12.5444 17.5349C11.2869 18.0553 11.2988 17.4715 10.7515 16.2898C10.2041 15.1081 10.0077 14.489 9.81902 14.0562Z"
                className="fill-[#F8E1A4] if:fill-off-white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <clipPath id="clip0_3601_40910">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

FoodPreferencesIcon.propTypes = {
    width: PropTypes.number,
};

FoodPreferencesIcon.defaultProps = {
    width: 20,
};

export default FoodPreferencesIcon;
