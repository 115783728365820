import { ALCOHOL_SERVICE_FEE, MIN_WINE_BOTTLE_QTY_TO_SHIP, MIN_WINE_BOTTLE_QTY_TO_WAIVE_FEE } from 'constants/shop';
import {
    getColdPackItems,
    getDiscountDollarValue,
    getGroceryItems,
    getOrderMin,
    getTotalWineBottleQty,
    getWineItems,
} from 'utils/orderUtils';

/**
 * @typedef ProductPriceType - A product's price type.
 *
 * @property {string} DEFAULT - The default price value.
 * @property {string} MSRP - The MSRP value.
 * @property {string} PLUS_MEMBER - The plus member price value.
 */

/**
 * @readonly
 * @enum {ProductPriceType}
 */
const PriceType = Object.freeze({
    DEFAULT: 'default',
    MSRP: 'msrp',
    PLUS_MEMBER: 'plus-member',
});

/**
 * Returns the price of a product.
 *
 * @param {Object} [options] - Additional configuration.
 * @param {ProductPriceType} [options.priceType] - The product price type.
 * @param {Selection} product - The product to return a price for.
 * @returns {number} The price of the product.
 */
const getProductPrice = ({ options = {}, product }) => {
    const { priceType } = options;

    if (priceType === PriceType.MSRP && product.msrp && product.portion) {
        return Math.max(product.msrp * product.portion, product.nonPlusMemberPrice);
    }

    if (priceType === PriceType.PLUS_MEMBER && product.plusMemberPriceEligible && product.plusMemberPrice) {
        return Math.min(product.plusMemberPrice, product.nonPlusMemberPrice);
    }

    return product.nonPlusMemberPrice;
};

/**
 * Returns the subtotal of an array of products.
 *
 * @param {Object} [options] - Additional configuration.
 * @param {boolean} [options.filterPerks] - Filter perks from the list of products.
 * @param {ProductPriceType} [options.priceType] - The product price type.
 * @param {Selection[]} products - An array of products.
 * @returns {number} The subtotal of the array of products.
 */
const getProductsSubtotal = ({ options = {}, products }) => {
    const { filterPerks = true, priceType } = options;

    let filteredProducts = products;

    if (filterPerks) {
        filteredProducts = filteredProducts.filter((product) => !product.isPerk);
    }

    return filteredProducts.reduce((total, product) => {
        const productPrice = getProductPrice({
            options: { priceType },
            product,
        });

        return total + productPrice * product.qty;
    }, 0);
};

/**
 * Returns the estimated tax for the given selections data.
 *
 * @param {Object} [options] - Additional configuration.
 * @param {boolean} [options.grocery] - Add the estimated grocery tax.
 * @param {boolean} [options.wine] - Add the estimated alcohol tax.
 * @param {Selections} selections - Selections data from the back-end.
 * @returns {number} The estimated tax for the given selections data.
 */
const getEstimatedTax = ({ options = {}, selections }) => {
    const { grocery = true, wine = true } = options;
    const { estimatedAlcoholTax = 0, estimatedGroceryTax = 0 } = selections;

    let estimatedTax = 0;

    if (grocery) {
        estimatedTax += estimatedGroceryTax;
    }

    if (wine) {
        estimatedTax += estimatedAlcoholTax;
    }

    return estimatedTax;
};

/**
 * Returns whether or not the cold pack fee should be waived for
 * the provided selections data.
 *
 * @param {Account} account - Account data from the back-end.
 * @param {Object} [options] - Additional configuration.
 * @param {ProductPriceType} [options.priceType] - The product price type.
 * @param {Selections} selections - Selections data from the back-end.
 * @returns {boolean} Should the cold pack fee be waived.
 */
const getWaiveColdPackFee = ({ account, options = {}, selections }) => {
    const { proteinMinimum = 0 } = account;
    const { priceType } = options;

    const coldPackProducts = getColdPackItems(selections);
    const coldPackSubtotal = getProductsSubtotal({
        options: { priceType },
        products: coldPackProducts,
    });

    return coldPackSubtotal >= proteinMinimum;
};

/**
 * Returns whether or not the given selections data meets a user's
 * grocery minimum.
 *
 * @param {Account} account - Account data from the back-end.
 * @param {Object} [options] - Additional configuration.
 * @param {ProductPriceType} [options.priceType] - The product price type.
 * @param {Selections} selections - Selections data from the back-end.
 * @returns {boolean} Does the selections data meet the user's grocery minimum.
 */
const getGroceryMinMet = ({ account, options = {}, selections }) => {
    const { priceType } = options;

    const groceryMin = getOrderMin(account);

    const groceryProducts = getGroceryItems(selections);
    const grocerySubtotal = getProductsSubtotal({
        options: { priceType },
        products: groceryProducts,
    });

    return grocerySubtotal >= groceryMin;
};

/**
 * Returns whether or not the given selections data meets the minimum
 * wine bottle quantity required to ship.
 *
 * @param {Selections} selections - Selections data from the back-end.
 * @returns {boolean} Does the selections data meet the wine bottle quantity minimum.
 */
const getAlcoholMinMet = ({ selections }) => {
    const totalWineBottleQty = getTotalWineBottleQty(selections);
    return totalWineBottleQty >= MIN_WINE_BOTTLE_QTY_TO_SHIP;
};

/**
 * Returns whether or not the given selections data meets a user's
 * free shipping threshold (FST).
 *
 * @param {Account} account - Account data from the back-end.
 * @param {Object} [options] - Additional configuration.
 * @param {ProductPriceType} [options.priceType] - The product price type.
 * @param {Selections} selections - Selections data from the back-end.
 * @returns {boolean} Does the selections data meet the user's free shipping threshold.
 */
const getFreeShippingThresholdMet = ({ account, options = {}, selections }) => {
    const { freeShippingEligible = false, freeShippingThreshold = 0 } = account?.nextOrder?.cartExperience ?? {};
    const { priceType } = options;

    const groceryProducts = getGroceryItems(selections);
    const grocerySubtotal = getProductsSubtotal({
        options: { priceType },
        products: groceryProducts,
    });

    return freeShippingEligible && grocerySubtotal >= freeShippingThreshold;
};

/**
 * Returns the subtotal of all selections to be added to the cart total.
 *
 * @param {Account} account - Account data from the back-end.
 * @param {Object} [options] - Additional configuration.
 * @param {ProductPriceType} [options.priceType] - The product price type.
 * @param {boolean} [options.ignoreGroceryMin] - Add the grocery subtotal to the cart subtotal,
 *                                               even if it's below the grocery min.
 * @param {Selections} selections - Selections data from the back-end.
 * @returns {number} The subtotal of all selections to be added.
 */
const getCartSubtotal = ({ account, options = {}, selections }) => {
    const { ignoreGroceryMin, priceType } = options;

    const groceryProducts = getGroceryItems(selections);
    const grocerySubtotal = getProductsSubtotal({
        options: { priceType },
        products: groceryProducts,
    });

    const wineProducts = getWineItems(selections);
    const wineSubtotal = getProductsSubtotal({
        options: { priceType },
        products: wineProducts,
    });

    const groceryMinMet = getGroceryMinMet({
        account,
        options: { priceType },
        selections,
    });

    const totalWineBottleQty = getTotalWineBottleQty(selections);

    let subtotal = 0;

    if (groceryMinMet || ignoreGroceryMin) {
        subtotal += grocerySubtotal;
    }

    if (totalWineBottleQty >= MIN_WINE_BOTTLE_QTY_TO_SHIP) {
        subtotal += wineSubtotal;
    }

    return subtotal;
};

/**
 * Returns the estimated tax value to be added to the cart total.
 *
 * @param {Account} account - Account data from the back-end.
 * @param {Object} [options] - Additional configuration.
 * @param {ProductPriceType} [options.priceType] - The product price type.
 * @param {Selections} selections - Selections data from the back-end.
 * @returns {number} The estimated tax value to be added.
 */
const getCartEstimatedTax = ({ account, options = {}, selections }) => {
    const { priceType } = options;

    const totalWineBottleQty = getTotalWineBottleQty(selections);

    const groceryMinMet = getGroceryMinMet({
        account,
        options: { priceType },
        selections,
    });

    const estimatedTax = getEstimatedTax({
        options: {
            grocery: groceryMinMet,
            wine: totalWineBottleQty >= MIN_WINE_BOTTLE_QTY_TO_SHIP,
        },
        selections,
    });

    return estimatedTax;
};

/**
 * Returns the estimated tax and fees to be added to the cart total.
 *
 * @param {Account} account - Account data from the back-end.
 * @param {Object} [options] - Additional configuration.
 * @param {ProductPriceType} [options.priceType] - The product price type.
 * @param {Selections} selections - Selections data from the back-end.
 * @returns {number} The estimated tax and fees value to be added.
 */
const getCartEstimatedTaxAndFees = ({ account, options, selections }) => {
    const { operationsFee = 0 } = account?.nextOrder?.cartExperience ?? {};
    const cartEstimatedTax = getCartEstimatedTax({ account, options, selections });

    return cartEstimatedTax + operationsFee;
};

/**
 * Returns the cold keeper fee to be added to the cart total.
 *
 * @param {Account} account - Account data from the back-end.
 * @param {Object} [options] - Additional configuration.
 * @param {ProductPriceType} [options.priceType] - The product price type.
 * @param {Selections} selections - Selections data from the back-end.
 * @returns {number} The cold keeper fee to be added.
 */
const getCartColdKeeperFee = ({ account, options = {}, selections }) => {
    const { priceType } = options;
    const { coldKeeperFee = 0 } = account?.nextOrder?.cartExperience ?? 0;

    const coldPackProducts = getColdPackItems(selections);
    const waiveColdPackFee = getWaiveColdPackFee({
        account,
        options: { priceType },
        selections,
    });

    return coldPackProducts.length < 1 || waiveColdPackFee ? 0 : coldKeeperFee;
};

/**
 * Returns the shipping fee to be added to the cart total.
 *
 * @param {Account} account - Account data from the back-end.
 * @param {Object} [options] - Additional configuration.
 * @param {ProductPriceType} [options.priceType] - The product price type.
 * @param {Selections} selections - Selections data from the back-end.
 * @param {PlusMembership} [plusMembership] - Plus Membership data.
 * @returns {number} The shipping fee to be added.
 */
const getCartShippingFee = ({ account, options = {}, plusMembership = {}, selections }) => {
    const { priceType } = options;
    const { plusMembershipShippingFee } = plusMembership;
    const { shipping } = selections;

    const groceryMinMet = getGroceryMinMet({
        account,
        options: { priceType },
        selections,
    });

    const freeShippingThresholdMet = getFreeShippingThresholdMet({
        account,
        options: { priceType },
        selections,
    });

    const shouldApplyFee = groceryMinMet && !freeShippingThresholdMet;

    if (shouldApplyFee && priceType === PriceType.PLUS_MEMBER && plusMembershipShippingFee !== undefined) {
        return plusMembershipShippingFee;
    }

    return shouldApplyFee ? shipping : 0;
};

/**
 * Returns the charge tip amount to be added to the cart total.
 *
 * @param {Account} account - Account data from the back-end.
 * @param {Object} [options] - Additional configuration.
 * @param {ProductPriceType} [options.priceType] - The product price type.
 * @param {Selections} selections - Selections data from the back-end.
 * @returns {number} The charge tip amount to be added.
 */
const getCartChargeTipAmount = ({ account, options = {}, selections }) => {
    const { priceType } = options;
    const { chargeTipAmount = 0 } = selections;

    const groceryMinMet = getGroceryMinMet({
        account,
        options: { priceType },
        selections,
    });

    return groceryMinMet ? chargeTipAmount : 0;
};

/**
 * Returns the total value of all applied discounts to be subtracted from the cart total.
 *
 * @param {Account} account - Account data from the back-end.
 * @param {Discounts} discounts - Discounts data from the back-end.
 * @param {Object} [options] - Additional configuration.
 * @param {ProductPriceType} [options.priceType] - The product price type.
 * @param {Selections} selections - Selections data from the back-end.
 * @returns {number} The total value of all applied discounts to be substracted.
 */
const getCartAppliedDiscounts = ({ account, discounts, options = {}, selections }) => {
    const { thresholdDiscounts = [] } = discounts;
    const { priceType } = options;
    const { shipping } = selections;

    const groceryMinMet = getGroceryMinMet({
        account,
        options: { priceType },
        selections,
    });

    if (!groceryMinMet) {
        return {
            appliedDiscounts: [],
            appliedDiscountsTotal: 0,
        };
    }

    const cartSubtotal = getCartSubtotal({
        account,
        options: { priceType },
        selections,
    });

    const freeShippingThresholdMet = getFreeShippingThresholdMet({
        account,
        options: { priceType },
        selections,
    });

    const validDiscounts = thresholdDiscounts.filter(
        (discount) =>
            discount.thresholdDollars <= cartSubtotal &&
            !(discount.percentShippingDiscount > 0 && freeShippingThresholdMet)
    );

    const appliedDiscounts = validDiscounts.map((discount) => ({
        ...discount,
        dollarValue: getDiscountDollarValue(discount, cartSubtotal, shipping),
    }));

    const appliedDiscountsTotal = validDiscounts.reduce(
        (total, discount) => total + getDiscountDollarValue(discount, cartSubtotal, shipping),
        0
    );

    return {
        appliedDiscounts,
        appliedDiscountsTotal,
    };
};

/**
 * Returns the applied credits to be subtracted from the cart total.
 *
 * @param {Account} account - Account data from the back-end.
 * @param {Credits} credits - Credits data from the back-end.
 * @param {Discounts} discounts - Discounts data from the back-end.
 * @param {Object} [options] - Additional configuration.
 * @param {ProductPriceType} [options.priceType] - The product price type.
 * @param {PlusMembership} [plusMembership] - Plus Membership data.
 * @param {Selections} selections - Selections data from the back-end.
 * @returns {number} The applied credits to be substracted.
 */
const getCartAppliedCredits = ({ account, credits, discounts, options = {}, plusMembership, selections }) => {
    const { operationsFee = 0, operationsFeeEnabled = false } = account?.nextOrder?.cartExperience ?? {};
    const { sumCreditBalance = 0 } = credits;
    const { priceType } = options;

    const groceryMinMet = getGroceryMinMet({
        account,
        options: { priceType },
        selections,
    });

    if (!groceryMinMet) {
        return 0;
    }

    const groceryProducts = getGroceryItems(selections);

    const grocerySubtotal = getProductsSubtotal({
        options: { priceType },
        products: groceryProducts,
    });

    const estimatedGroceryTax = getEstimatedTax({
        options: { wine: false },
        selections,
    });

    const { appliedDiscountsTotal } = getCartAppliedDiscounts({
        account,
        discounts,
        credits,
        options: { priceType },
        selections,
    });

    const shippingFee = getCartShippingFee({
        account,
        options: { priceType },
        plusMembership,
        selections,
    });

    let creditValidTotal = 0;

    creditValidTotal += grocerySubtotal;
    creditValidTotal += estimatedGroceryTax;
    creditValidTotal -= appliedDiscountsTotal;
    creditValidTotal += shippingFee;

    if (operationsFeeEnabled) {
        creditValidTotal += operationsFee;
    }

    return Math.min(creditValidTotal, sumCreditBalance);
};

/**
 * Returns the alcohol service fee to be added to the cart total.
 *
 * @param {Selections} selections - Selections data from the back-end.
 * @returns {number} The alcohol service fee to be added.
 */
const getCartAlcoholServiceFee = ({ selections }) => {
    const totalWineBottleQty = getTotalWineBottleQty(selections);

    const minBottleQtyToShipMet = totalWineBottleQty >= MIN_WINE_BOTTLE_QTY_TO_SHIP;
    const minBottleQtyToWaiveFeeMet = totalWineBottleQty >= MIN_WINE_BOTTLE_QTY_TO_WAIVE_FEE;

    return minBottleQtyToShipMet && !minBottleQtyToWaiveFeeMet ? ALCOHOL_SERVICE_FEE : 0;
};

/**
 * Returns the alcohol discount amount to be subtracted from the cart total.
 *
 * @param {Selections} selections - Selections data from the back-end.
 * @returns {number} The alcool discount amount to be subtracted.
 */
const getCartAlcoholDiscount = ({ selections }) => {
    const { alcoholDiscountAmount = 0 } = selections;

    const totalWineBottleQty = getTotalWineBottleQty(selections);

    return totalWineBottleQty >= MIN_WINE_BOTTLE_QTY_TO_SHIP ? alcoholDiscountAmount : 0;
};

/**
 * Returns the total cost of the cart, including discounts, fees, shipping, tax, etc.
 *
 * @param {Account} account - Account data from the back-end.
 * @param {Credits} credits - Credits data from the back-end.
 * @param {Discounts} discounts - Discounts data from the back-end.
 * @param {Object} [options] - Additional configuration.
 * @param {ProductPriceType} [options.priceType] - The product price type.
 * @param {PlusMembership} [plusMembership] - Plus Membership data.
 * @param {Selections} selections - Selections data from the back-end.
 * @returns {number} The cart total.
 */
const getCartTotal = ({ account, credits, discounts, options = {}, plusMembership, selections }) => {
    const { operationsFeeEnabled = false } = account?.nextOrder?.cartExperience ?? {};
    const { priceType } = options;

    let cartTotal = 0;

    cartTotal += getCartSubtotal({
        account,
        options: { priceType },
        selections,
    });

    if (operationsFeeEnabled) {
        cartTotal += getCartEstimatedTaxAndFees({
            account,
            options: { priceType },
            selections,
        });
    } else {
        cartTotal += getCartEstimatedTax({
            account,
            options: { priceType },
            selections,
        });
    }

    cartTotal += getCartColdKeeperFee({
        account,
        options: { priceType },
        selections,
    });

    cartTotal += getCartShippingFee({
        account,
        options: { priceType },
        plusMembership,
        selections,
    });

    cartTotal += getCartChargeTipAmount({
        account,
        options: { priceType },
        plusMembership,
        selections,
    });

    cartTotal -= getCartAppliedDiscounts({
        account,
        discounts,
        options: { priceType },
        selections,
    }).appliedDiscountsTotal;

    cartTotal -= getCartAppliedCredits({
        account,
        credits,
        discounts,
        options: { priceType },
        plusMembership,
        selections,
    });

    cartTotal += getCartAlcoholServiceFee({ selections });
    cartTotal -= getCartAlcoholDiscount({ selections });

    return Math.max(0, cartTotal);
};

/**
 * Returns the cart's non-member savings; the difference between the
 * default price cart subtotal and the MSRP cart subtotal.
 *
 * @param {Account} account - Account data from the back-end.
 * @param {Selections} selections - Selections data from the back-end.
 * @returns {number} The non-member savings total.
 */
const getCartNonMemberSavings = ({ account, selections }) => {
    const cartMSRPSubtotal = getCartSubtotal({
        account,
        options: { priceType: PriceType.MSRP },
        selections,
    });
    const cartSubtotal = getCartSubtotal({ account, selections });

    return Math.max(cartMSRPSubtotal - cartSubtotal, 0);
};

/**
 * Returns the cart's member savings; the difference between the default
 * price cart subtotal and the plus member price cart subtotal.
 *
 * @param {Account} account - Account data from the back-end.
 * @param {Selections} selections - Selections data from the back-end.
 * @returns {number} The member savings total.
 */
const getCartMemberSavings = ({ account, selections }) => {
    const cartSubtotal = getCartSubtotal({ account, selections });
    const cartMemberSubtotal = getCartSubtotal({
        account,
        options: {
            ignoreGroceryMin: true,
            priceType: PriceType.PLUS_MEMBER,
        },
        selections,
    });

    return Math.max(cartSubtotal - cartMemberSubtotal, 0);
};

/**
 * Returns a referral discount object if one exists and the cart's subtotal is below its dollar threshold.
 *
 * @param {Account} account - Account data from the back-end.
 * @param {Discounts} discounts - Discounts data from the back-end.
 * @param {Object} [options] - Additional configuration.
 * @param {ProductPriceType} [options.priceType] - The product price type.
 * @param {Selections} selections - Selections data from the back-end.
 * @returns {Discount|null} A referral discount object or null.
 */
const getUnmetReferralDiscount = ({ account, discounts, options = {}, selections }) => {
    const { thresholdDiscounts = [] } = discounts;
    const { priceType } = options;

    const referralDiscount = thresholdDiscounts.find(({ discountCode }) => /^COOKWME-/.test(discountCode));

    const cartSubtotal = getCartSubtotal({
        account,
        options: { priceType },
        selections,
    });

    return cartSubtotal < referralDiscount?.thresholdDollars ? referralDiscount : null;
};

export {
    PriceType,
    getProductPrice,
    getProductsSubtotal,
    getEstimatedTax,
    getWaiveColdPackFee,
    getGroceryMinMet,
    getAlcoholMinMet,
    getFreeShippingThresholdMet,
    getCartSubtotal,
    getCartEstimatedTax,
    getCartEstimatedTaxAndFees,
    getCartColdKeeperFee,
    getCartShippingFee,
    getCartChargeTipAmount,
    getCartAppliedDiscounts,
    getCartAppliedCredits,
    getCartAlcoholServiceFee,
    getCartAlcoholDiscount,
    getCartTotal,
    getCartNonMemberSavings,
    getCartMemberSavings,
    getUnmetReferralDiscount,
};
