import { useQuery } from '@tanstack/react-query';

import { getTipSuggestions } from 'api/jalapeno';

import useAccountQuery from './useAccountQuery';

export const TIPS_QUERY_KEY = 'tips';

const useTipsQuery = () => {
    const { data: account } = useAccountQuery();

    const query = useQuery({
        queryKey: [TIPS_QUERY_KEY, account?.subID],
        queryFn: async () => {
            const response = await getTipSuggestions(account?.subID);
            return response?.data;
        },
        enabled: !!account?.subID,
    });

    return query;
};

export default useTipsQuery;
