import PropTypes from 'prop-types';

import BackToSchoolActive from './BackToSchoolActive';
import BackToSchoolInactive from './BackToSchoolInactive';

function BackToSchool({ className, isActive, width }) {
    return isActive ? (
        <BackToSchoolActive className={className} width={width} />
    ) : (
        <BackToSchoolInactive className={className} width={width} />
    );
}

export default BackToSchool;

BackToSchool.propTypes = {
    className: PropTypes.string,
    isActive: PropTypes.bool,
    width: PropTypes.number,
};

BackToSchool.defaultProps = {
    className: '',
    isActive: true,
    width: 24,
};
