import PropTypes from 'prop-types';

import { IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN } from 'constants/theme';

export function Beverages({ brand, isActive, width }) {
    const bagColor = brand === MISFITS_THEME_DOMAIN ? '#DEA455' : '#F6A685';
    const mugColor = brand === MISFITS_THEME_DOMAIN ? '#F1C34A' : '#F7D46D';
    const activeColor = brand === MISFITS_THEME_DOMAIN ? '#D7E1B8' : '#F0D3E3';

    return (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            {isActive && (
                <path
                    d="M14.6505 28.7652C21.902 26.9646 29 22.6358 29 14.9992C29 7.3625 22.9452 2.7363 14.6505 1.1728C7.31233 -0.213857 2.48978 6.96057 1.13553 14.967C-0.132106 22.475 7.73356 30.4815 14.6505 28.7652Z"
                    fill={activeColor}
                />
            )}
            <path
                d="M4.90385 7.07636L4.6123 5.01839L13.7531 3.94653L14.1647 5.7644L4.90385 7.07636Z"
                fill={bagColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.90384 7.07642C4.90384 7.07642 3.93488 10.1376 4.00348 11.4239C4.07208 12.7101 6.14719 24.8007 6.19864 25.1265C6.25009 25.4609 9.41422 26.4127 9.41422 26.4127C9.41422 26.4127 8.6339 14.5966 7.24478 10.4549C6.91036 9.44308 4.89526 7.08499 4.89526 7.08499L4.90384 7.07642Z"
                fill="#F2EBD1"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.9043 7.0764C4.9043 7.0764 6.65357 9.52881 6.94511 10.8322C7.23666 12.1356 9.07168 26.4556 9.39753 26.4127C9.72337 26.3698 18.7613 23.8831 18.7956 23.6602C18.8299 23.4458 16.8491 10.4892 16.6004 9.90611C16.3432 9.32302 14.3195 5.73015 14.148 5.75587L4.9043 7.0764Z"
                fill={bagColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.42266 17.8378L9.92 21.3192C9.93715 21.4392 9.98002 21.5507 10.04 21.6536C10.1001 21.7565 10.1858 21.8422 10.2801 21.9108C10.3745 21.9794 10.4859 22.0223 10.606 22.048C10.726 22.0738 10.8461 22.0652 10.9576 22.048L16.0939 20.9162C16.2997 20.8733 16.4883 20.7447 16.6084 20.5732C16.737 20.4017 16.7885 20.1873 16.7627 19.9729L16.3511 16.4572C16.334 16.3372 16.2997 16.2171 16.2397 16.1142C16.1796 16.0113 16.0939 15.917 15.9996 15.8484C15.9053 15.7798 15.7852 15.7284 15.6652 15.7026C15.5451 15.6769 15.4251 15.6769 15.305 15.7026L10.0829 16.886C9.87712 16.9374 9.68848 17.0575 9.56843 17.229C9.44838 17.4005 9.38836 17.6148 9.41408 17.8292L9.42266 17.8378Z"
                fill="#2D2D2D"
            />
            <path
                d="M20.6645 19.9986L23.4599 19.9643C24.549 19.9471 25.6037 19.6385 26.5383 19.0725C27.4044 18.558 27.7217 17.0145 27.7474 15.9084C27.7903 15.4453 27.7302 14.9737 27.5845 14.5364C27.4387 14.0905 27.1986 13.6875 26.8813 13.3445C26.101 12.487 25.3207 12.367 24.4804 12.1697C23.64 11.9725 20.5273 12.1011 20.5273 12.1011L20.5874 13.5589C21.1276 13.5246 23.7086 13.1816 24.9691 13.7475C26.2296 14.3135 26.504 15.0766 26.2639 16.7058C26.0753 17.9578 24.5318 18.3608 23.4514 18.3694L20.6645 18.4122V19.9986Z"
                fill={mugColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.8819 9.61453C21.8819 9.61453 22.1049 13.6619 22.122 15.1196C22.1392 16.5773 22.122 18.3352 22.0963 19.0726C22.062 19.6643 22.2592 21.4907 22.0534 21.7823C21.8476 22.0824 20.2784 23.18 16.4798 23.0256C12.6811 22.8713 11.7207 22.554 11.5406 21.9709C11.3606 21.3878 11.4892 18.3695 11.4549 15.7113L11.3691 9.75173L21.8819 9.60596V9.61453Z"
                fill={mugColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.737 10.5749C19.6525 10.5234 22.002 10.0689 21.9934 9.56302C21.9848 9.0571 19.6182 8.67981 16.7027 8.73126C13.7873 8.78271 11.4377 9.23718 11.4463 9.74309C11.4549 10.249 13.8215 10.6263 16.737 10.5749Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            {isActive && (
                <>
                    <path
                        d="M13.0243 10.3775C12.4927 10.1117 15.1681 9.79445 16.0513 9.7773C16.9345 9.76872 18.598 9.7087 19.1982 9.88877C19.5755 10.0088 19.9443 10.1546 20.3044 10.3175C19.6184 10.4461 18.9238 10.5319 18.2293 10.5833C17.5947 10.5748 13.6246 10.6862 13.0243 10.3775Z"
                        fill="#2D2D2D"
                    />
                    <path
                        d="M17.9286 6.60473C18.1258 6.43323 18.2544 6.21029 18.3059 5.96161C18.3573 5.71294 18.323 5.44712 18.2115 5.2156C17.9886 4.80401 17.8085 4.37526 17.6885 3.9208C17.6885 3.52635 17.8428 3.15763 18.1172 2.86609"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M19.2842 6.58755C19.4814 6.35603 19.6186 6.08163 19.6701 5.77294C19.7215 5.47282 19.6872 5.16412 19.5672 4.88115C19.3356 4.37524 19.1641 3.8436 19.0355 3.30338C19.0269 2.83176 19.1727 2.37729 19.4471 2"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </>
            )}
        </svg>
    );
}

Beverages.propTypes = {
    brand: PropTypes.oneOf([IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN]).isRequired,
    isActive: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Beverages.defaultProps = {
    isActive: false,
    width: 30,
};
