import PropTypes from 'prop-types';

function IFColdPackActive({ className, width }) {
    return (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path
                d="M15.4844 2.03637C15.4844 2.03637 19.3536 1.68679 20.7123 3.15519C22.304 5.06976 21.4671 5.89553 22.3269 13.4773C23.0194 19.6072 23.5314 22.2576 22.1563 23.6394C21.3063 24.4985 18.8482 26.0968 18.8482 26.0968L15.4844 2.03637Z"
                fill="#F4F0F2"
                stroke="#371F2F"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.4732 26.7729L7.48999 27.5986C6.90838 27.6456 6.33212 27.4568 5.8874 27.0737C5.44268 26.6905 5.16574 26.1441 5.11722 25.5542L3.47632 5.27632C3.43002 4.68623 3.61608 4.10153 3.99375 3.65033C4.37142 3.19912 4.90993 2.91818 5.49136 2.86895L15.4746 2.04319C16.0562 1.99622 16.6325 2.18499 17.0772 2.56818C17.5219 2.95136 17.7989 3.49769 17.8474 4.08759L19.4883 24.3655C19.5346 24.9556 19.3485 25.5402 18.9708 25.9914C18.5932 26.4426 18.0547 26.7237 17.4732 26.7729V26.7729Z"
                fill="white"
                stroke="#371F2F"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.028 9.60156C18.028 9.60156 13.2497 10.4607 4.34283 10.7337"
                stroke="#371F2F"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.2005 13.1777L6.60088 18.1789"
                stroke="#371F2F"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.65887 6.45801L5.83281 8.60234"
                stroke="#371F2F"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.0637 16.0745C21.6873 13.2276 22.4749 9.66818 22.7046 8.80912C23.0952 7.41065 23.0492 5.94887 24.7262 6.27518C26.4032 6.60149 26.6953 7.16087 26.459 8.51273C26.2982 9.39843 25.3038 13.4307 24.5588 16.6772L21.0637 16.0745Z"
                fill="white"
                stroke="#371F2F"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M22.5242 9.64453L24.2504 10.0441Z" fill="white" />
            <path
                d="M22.5242 9.64453L24.2504 10.0441"
                stroke="#371F2F"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.8514 12.918L23.0952 13.2063"
                stroke="#371F2F"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.2595 19.7569L21.0636 16.0742L24.5718 16.6736C24.5718 16.6736 23.8892 19.8201 23.7645 20.566C24.1911 21.0721 25.4743 22.8602 24.2929 24.6582C23.1114 26.4562 21.1358 26.4229 19.659 25.5872C18.1822 24.7514 17.8408 22.7703 18.2215 21.8579C18.2215 21.8579 18.7401 20.1331 20.2595 19.7569Z"
                fill="#C5DB66"
                stroke="#371F2F"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default IFColdPackActive;

IFColdPackActive.propTypes = {
    className: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

IFColdPackActive.defaultProps = {
    className: null,
    width: 28,
};
