import PropTypes from 'prop-types';

function VeganIcon({ className, width }) {
    return (
        <svg width={width} className={className} viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1026_28108)">
                <path
                    d="M12.5025 9.81981C11.8825 9.71981 12.1225 10.6598 11.8925 10.7698C10.9825 11.1598 11.2625 11.9798 10.8325 12.2798C10.5325 12.4898 9.59247 12.5798 9.03247 12.6198C9.62247 12.7298 9.79247 13.5098 9.71247 14.1098C9.68247 14.3298 9.63247 14.5498 9.64247 14.7698C9.65247 15.0598 9.76247 15.3398 9.87247 15.6098C10.5025 17.1998 11.1325 18.7898 11.7525 20.3698C12.5125 21.0798 13.7525 20.8298 14.7125 20.4298C15.2425 20.2198 15.7725 19.9398 16.1725 19.5198C16.5925 19.0898 16.7125 18.7398 16.4725 18.1998C15.5525 16.2098 15.4925 16.1598 14.6825 15.1598C14.0625 14.3898 13.8825 12.7498 13.8425 12.5298C13.7825 12.1498 13.5825 10.6898 14.2625 10.0998L13.5425 10.2898C12.8225 10.4798 13.1125 9.91981 12.4925 9.80981L12.5025 9.81981Z"
                    fill="#B0C472"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinejoin="round"
                />
                <path
                    d="M17.4998 5.5C17.2798 4.82 16.6624 4.27979 16.0024 3.99979C14.8124 3.49979 14.5224 2.62979 13.5924 2.16979C12.8824 1.81979 12.0624 2.25979 11.2924 2.07979C10.3124 1.85979 9.79244 1.42979 8.79244 1.50979C7.61244 1.60979 6.97244 2.26979 5.90244 2.77979C5.51244 2.96979 4.65244 3.00979 4.32244 3.29979C4.00244 3.58979 3.76244 4.00979 3.78244 4.43979L3.59244 4.47979C3.43244 4.50979 3.31244 4.64979 3.30244 4.80979C3.26244 5.37979 2.90244 5.90979 2.50244 6.34979C2.04244 6.84979 1.51244 7.29979 1.21244 7.90979C0.75244 8.86979 1.06244 10.1298 1.91244 10.7698C1.66244 10.5998 1.34244 10.9798 1.41244 11.2698C1.48244 11.5598 1.74244 11.7698 1.91244 12.0098C2.05244 12.1998 2.12244 12.4698 2.04244 12.6698C1.98244 12.8198 2.00244 12.9898 2.12244 13.1098C2.35244 13.3398 2.69244 13.5198 2.97244 13.5098C3.36244 13.5098 3.71244 13.1898 4.06244 13.3698C4.44244 13.5698 4.61244 13.9998 4.88244 14.3298C5.60244 15.2298 7.17244 15.3198 7.92244 14.3898C8.10244 14.1698 8.33244 13.9998 8.60244 13.9298C8.76244 13.8898 8.89244 13.8798 9.01244 13.9098C9.56244 13.8698 10.5124 13.7698 10.8124 13.5698C11.2424 13.2698 10.9624 12.4498 11.8624 12.0598C12.1024 11.9598 11.8624 11.0098 12.4724 11.1098C13.0924 11.2098 12.8124 11.7698 13.5224 11.5898L14.2424 11.3998C15.5624 12.1998 16.5024 11.6898 17.0224 10.9098C18.1324 9.22979 17.5124 8.79979 17.0824 7.96979C17.7424 7.69979 17.9624 6.81979 17.7524 6.14979L17.4998 5.5Z"
                    fill="#2E8540"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_1026_28108">
                    <rect width="19" height="21" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default VeganIcon;

VeganIcon.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number.isRequired,
};

VeganIcon.defaultProps = {
    className: null,
};
