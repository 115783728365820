import PropTypes from 'prop-types';

function CancelPlanIcon({ width }) {
    return (
        <svg width={width} viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4539_158582)">
                <mask
                    id="mask0_4539_158582"
                    style={{ maskType: 'luminance' }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="60"
                    height="61"
                >
                    <path d="M60 0.642578H0V60.1126H60V0.642578Z" fill="white" />
                </mask>
                <g mask="url(#mask0_4539_158582)">
                    <path
                        d="M35.1107 58.3626C49.6407 51.3926 62.7507 39.1926 59.5007 23.2326C56.2407 7.28263 41.4907 0.262627 23.3207 0.662627C7.25069 1.00263 0.170691 18.1326 0.690691 35.4826C1.22069 51.7526 21.2407 65.0126 35.0907 58.3826"
                        className="fill-mango if:fill-[#FFCBDC]"
                    />
                    <path
                        d="M9.82031 17.3719C9.82031 17.3719 16.5603 14.1219 19.7103 12.9419C22.0103 12.0819 28.6503 8.92188 28.6503 8.92188C28.6503 8.92188 35.1303 12.0519 38.6503 13.1419C41.2403 13.9419 48.6503 17.3619 48.6503 17.3619C48.6503 17.3619 42.3403 20.1119 38.6503 21.1919C36.0903 21.9519 28.6503 25.0219 28.6503 25.0219L18.1303 20.7619L9.82031 17.3519V17.3719Z"
                        fill="white"
                        stroke="#2D2D2D"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M9.82016 17.373C9.82016 17.373 10.2102 24.293 9.82016 28.153C9.46016 31.693 9.82016 42.403 9.82016 42.403L28.6402 50.033V25.063L9.82016 17.383V17.373Z"
                        fill="white"
                        stroke="#2D2D2D"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M48.6309 17.3733C48.6309 17.3733 48.7709 26.3733 48.6309 29.3633C48.4309 33.6933 48.6309 42.0133 48.6309 42.0133C48.6309 42.0133 43.7909 43.7133 40.5709 45.2433C37.6709 46.6133 28.6509 50.0333 28.6509 50.0333C28.6509 50.0333 28.7709 41.0233 28.6509 37.9933C28.4609 32.9733 28.6509 25.0333 28.6509 25.0333L48.6309 17.3633V17.3733Z"
                        className="fill-[#F2D79A] if:fill-oat"
                        stroke="#2D2D2D"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <path
                    d="M14.2707 49.1332C20.2354 49.1332 25.0707 44.2979 25.0707 38.3332C25.0707 32.3685 20.2354 27.5332 14.2707 27.5332C8.30603 27.5332 3.4707 32.3685 3.4707 38.3332C3.4707 44.2979 8.30603 49.1332 14.2707 49.1332Z"
                    className="fill-[#B0C372] if:fill-[#C5DB66]"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17.3404 35.8633L10.6504 42.5433"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.6504 35.8633L17.3404 42.5433"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_4539_158582">
                    <rect width="60" height="59.49" fill="white" transform="translate(0 0.642578)" />
                </clipPath>
            </defs>
        </svg>
    );
}

CancelPlanIcon.propTypes = {
    width: PropTypes.number,
};

CancelPlanIcon.defaultProps = {
    width: 60,
};

export default CancelPlanIcon;
