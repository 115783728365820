import * as ProgressComponent from '@radix-ui/react-progress';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const colorClasses = {
    mango: 'from-[#F1C34A4D] to-mango',
    grey: 'from-grey-light to-grey',
};

const widthClasses = {
    default: 'w-[200px]',
    full: 'w-full',
};

function Progress({ color, progress, width }) {
    const progressValue = 100 - progress;
    const translateXValue = `${progressValue > 0 ? -progressValue : 0}`;

    return (
        <ProgressComponent.Root
            className={clsx(
                'relative h-[10px] overflow-hidden rounded-full border-[.5px] border-grey-light bg-white',
                widthClasses[width]
            )}
            style={{ transform: 'translateZ(0)' }}
            value={progress}
            title="Progress Indicator"
        >
            <ProgressComponent.Indicator
                className={clsx(
                    'ease-[cubic-bezier(0.65, 0, 0.35, 1)] ProgressIndicator h-full w-full bg-gradient-to-r from-[#F1C34A4D] to-mango transition-transform duration-[660ms]',
                    colorClasses[color]
                )}
                style={{ transform: `translateX(${translateXValue}%)` }}
            />
        </ProgressComponent.Root>
    );
}

Progress.propTypes = {
    color: PropTypes.oneOf(Object.keys(colorClasses)),
    progress: PropTypes.number.isRequired,
    width: PropTypes.oneOf(Object.keys(widthClasses)),
};

Progress.defaultProps = {
    color: 'mango',
    width: 'default',
};

export default Progress;
