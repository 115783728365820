import PropTypes from 'prop-types';

import { IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN } from 'constants/theme';

export function VitaminsAndSupplements({ brand, isActive, width }) {
    const orangeColor = brand === MISFITS_THEME_DOMAIN ? '#DC6B31' : '#F6A685';
    const activeColor = brand === MISFITS_THEME_DOMAIN ? '#D7E1B8' : '#F0D3E3';

    return isActive ? (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.6505 28.7652C21.902 26.9646 29 22.6358 29 14.9992C29 7.3625 22.9452 2.7363 14.6505 1.1728C7.31233 -0.213857 2.48978 6.96057 1.13553 14.967C-0.132106 22.475 7.73356 30.4815 14.6505 28.7652Z"
                fill={activeColor}
            />
            <path
                d="M19.4598 7.43845C19.4598 8.87845 19.6698 8.79845 20.1698 9.28845C20.6698 9.78845 21.3398 10.1085 21.7398 10.6885C22.1798 11.3285 22.2198 12.1585 22.2298 12.9385C22.3098 16.9785 22.0098 21.0285 22.0898 25.0685C22.0898 25.5085 22.0898 26.0085 21.7798 26.3185C21.4698 26.6285 20.9798 26.6485 20.5398 26.6485C16.7598 26.6285 18.3298 26.6685 14.5498 26.6485H13.0498C9.26985 26.6685 12.3498 26.6285 8.56985 26.6485C8.12985 26.6485 7.63985 26.6285 7.32985 26.3185C7.01985 26.0085 7.00985 25.5085 7.01985 25.0685C7.09985 21.0285 7.17985 16.9785 7.25985 12.9385C7.27985 12.1585 7.30985 11.3285 7.74985 10.6885C8.14985 10.1085 8.81985 9.78845 9.31985 9.28845C9.81985 8.78845 10.1098 8.47845 10.1098 7.03845H19.4798V7.42845L19.4598 7.43845Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.6504 2.32847C11.9804 2.19847 10.9804 1.90847 10.4104 2.02847C9.70035 2.17847 9.76035 2.81847 9.65035 3.26847C9.35035 4.52847 9.36035 4.81847 9.99035 4.96847C10.5504 5.10847 11.2104 5.21847 11.9004 5.34847C14.1304 5.77847 16.7404 6.28847 18.9704 6.70847C19.4704 6.80847 19.5704 6.87847 20.0104 6.88847C20.7204 6.88847 20.6804 6.27847 20.8104 5.79847C20.9104 5.43847 21.0404 4.64847 20.9504 4.26847C20.8404 3.76847 19.8504 3.69847 18.6404 3.45847C17.1004 3.15847 14.2704 2.63847 12.6504 2.30847V2.32847Z"
                fill="#F8E1A4"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M12.3603 2.33838C12.0203 3.29838 11.8603 4.30838 11.8903 5.33838"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.56 2.95837L14.96 5.95837"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.5505 3.46838L18.2305 6.55838"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.01 24.6384C11.86 24.9684 17.24 24.8884 22.09 24.4184C22.09 24.4184 21.96 23.4584 22.21 17.3084C22.46 11.1584 21.92 11.4784 21.92 11.4784C17.08 11.9584 12.2 12.0284 7.34 11.6984C7.34 11.6984 7.24 13.3484 7.19 15.6884C7.14 18.0284 7 24.6384 7 24.6384H7.01Z"
                fill="#F8E1A4"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M18.4096 13.0585C19.6896 14.7285 20.2996 16.9085 19.8196 18.8885C19.3396 20.8785 17.6996 22.5985 15.6096 23.0585C14.4096 23.3185 13.1096 23.1585 12.0096 22.6185C10.8996 22.0785 9.34961 20.6285 8.84961 19.5485C11.0896 17.9085 13.9096 16.6085 16.1496 14.9685C16.9296 14.3985 17.7296 13.7385 18.4096 13.0485V13.0585Z"
                fill={orangeColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.53027 19.1984C10.3803 20.3084 11.2903 21.2484 12.6503 21.6884C14.0103 22.1284 15.5903 22.0084 16.7303 21.2184C17.5103 20.6784 18.0403 19.8584 18.3203 18.9784C18.6003 18.0984 18.6503 17.1584 18.5703 16.2384C18.5003 15.3284 18.3103 14.4084 17.7903 13.6284"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.8496 21.3584C12.1196 19.9684 13.0296 17.9384 13.5796 16.6384"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.5801 16.6384C14.2301 18.1084 15.3401 20.1784 15.9901 21.6484"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.5801 16.6384C14.9401 16.8484 17.1601 16.9184 18.5101 16.7584"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ) : (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.4298 6.82849C19.4298 8.26849 19.6798 9.06849 20.1698 9.56849C20.6698 10.0685 21.3398 10.3885 21.7398 10.9685C22.1798 11.6085 22.2198 12.4385 22.2298 13.2185C22.3098 17.2585 22.0098 21.3085 22.0898 25.3485C22.0898 25.7885 22.0898 26.2885 21.7798 26.5985C21.4698 26.9085 20.9798 26.9285 20.5398 26.9285C16.7598 26.9085 18.3298 26.9485 14.5498 26.9285H13.0498C9.26985 26.9485 12.3498 26.9085 8.56985 26.9285C8.12985 26.9285 7.63985 26.9085 7.32985 26.5985C7.01985 26.2885 7.00985 25.7885 7.01985 25.3485C7.09985 21.3085 7.17985 17.2585 7.25985 13.2185C7.27985 12.4385 7.30985 11.6085 7.74985 10.9685C8.14985 10.3885 8.81985 10.0685 9.31985 9.56849C9.81985 9.06849 10.0598 8.26849 10.0598 6.82849H19.4398H19.4298Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.6599 4.05855C10.9799 4.05855 9.93985 3.97855 9.39985 4.20855C8.72985 4.49855 8.90985 5.10855 8.88985 5.57855C8.83985 6.87855 8.90985 7.14855 9.55985 7.17855C10.1299 7.20855 10.7999 7.17855 11.5099 7.17855C13.7799 7.16855 16.4399 7.14855 18.7099 7.13855C19.2199 7.13855 19.3299 7.18855 19.7599 7.10855C20.4599 6.97855 20.2999 6.37855 20.3299 5.88855C20.3499 5.51855 20.3299 4.71855 20.1699 4.35855C19.9599 3.89855 18.9799 4.00855 17.7499 4.01855C16.1799 4.01855 13.2999 4.05855 11.6499 4.05855H11.6599Z"
                fill="#F8E1A4"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M11.3896 4.12854C11.2396 5.13854 11.2796 6.15854 11.5096 7.15854"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.6396 4.11853V7.17853"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.6699 4.03857L17.9599 7.13857"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.01 24.9186C11.86 25.2486 17.24 25.1686 22.09 24.6986C22.09 24.6986 21.96 23.7386 22.21 17.5886C22.46 11.4386 21.92 11.7586 21.92 11.7586C17.08 12.2386 12.2 12.3086 7.34 11.9786C7.34 11.9786 7.24 13.6286 7.19 15.9686C7.14 18.3086 7 24.9186 7 24.9186H7.01Z"
                fill="#F8E1A4"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M18.4096 13.3385C19.6896 15.0085 20.2996 17.1885 19.8196 19.1685C19.3396 21.1585 17.6996 22.8785 15.6096 23.3385C14.4096 23.5985 13.1096 23.4385 12.0096 22.8985C10.8996 22.3585 9.34961 20.9085 8.84961 19.8285C11.0896 18.1885 13.9096 16.8885 16.1496 15.2485C16.9296 14.6785 17.7296 14.0185 18.4096 13.3285V13.3385Z"
                fill={orangeColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.53027 19.4686C10.3803 20.5786 11.2903 21.5186 12.6503 21.9586C14.0103 22.3986 15.5903 22.2786 16.7303 21.4886C17.5103 20.9486 18.0403 20.1286 18.3203 19.2486C18.6003 18.3686 18.6503 17.4286 18.5703 16.5086C18.5003 15.5986 18.3103 14.6786 17.7903 13.8986"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.8496 21.6386C12.1196 20.2486 13.0296 18.2186 13.5796 16.9186"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.5801 16.9186C14.2301 18.3886 15.3401 20.4586 15.9901 21.9286"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.5801 16.9186C14.9401 17.1286 17.1601 17.1986 18.5101 17.0386"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

VitaminsAndSupplements.propTypes = {
    brand: PropTypes.oneOf([IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN]).isRequired,
    isActive: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

VitaminsAndSupplements.defaultProps = {
    isActive: false,
    width: 30,
};
