import { formatString } from 'utils/globalUtils';

export default function useProductSource({
    aisleID = null,
    carouselID = null,
    carouselName = '',
    customAisle = null,
    searchAutocomplete,
    searchRecommendation,
    upSellsSource,
}) {
    const { pathname } = window.location;

    if (upSellsSource) return upSellsSource;

    if (searchRecommendation) return 'search_recommendation';

    if (searchAutocomplete) return 'search_autocomplete';

    if (pathname.includes('search')) return 'search';

    if ((pathname === '/shop' || pathname.match(/\/shop\/[0-9]+/)) && customAisle) {
        const formattedCustomAisle = formatString(customAisle, '_');
        return `${formattedCustomAisle}_hp_carousel`;
    }

    if (pathname === '/shop' || pathname.match(/\/shop\/[0-9]+/)) return 'hp_carousel';

    if (pathname.includes('buy-it-again')) return 'buy_it_again_aisle';

    if (pathname.includes('recipe-inspiration')) return 'recipe_inspiration';

    if (pathname.includes('misfits+perks')) return 'shop_with_points';

    if (pathname.includes('/shop/aisle/all') || pathname.match(/\/shop\/aisle(\/)*$/)) return 'all_items';

    if (pathname.includes('/brand')) return 'brand_page';

    if (pathname.includes('product') && customAisle) {
        const formattedCustomAisle = formatString(customAisle, '_');
        return `${formattedCustomAisle}_pdp`;
    }

    if (pathname.includes('product')) return 'pdp';

    if (carouselName === 'Frequently bought together') return 'carousel_upsell_freq_bought';

    if (aisleID && carouselID) return 'aisle_carousel';

    if (aisleID) return 'aisle';

    return null;
}
