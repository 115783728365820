import PropTypes from 'prop-types';

import MMThanksgivingEssentialsActive from './MMThanksgivingEssentialsActive';
import MMThanksgivingEssentialsInactive from './MMThanksgivingEssentialsInactive';

function MMThanksgivingEssentials({ className, isActive, width }) {
    return isActive ? (
        <MMThanksgivingEssentialsActive className={className} width={width} />
    ) : (
        <MMThanksgivingEssentialsInactive className={className} width={width} />
    );
}

export default MMThanksgivingEssentials;

MMThanksgivingEssentials.propTypes = {
    className: PropTypes.string,
    isActive: PropTypes.bool,
    width: PropTypes.number,
};

MMThanksgivingEssentials.defaultProps = {
    className: '',
    isActive: true,
    width: 24,
};
