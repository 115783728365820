import PropTypes from 'prop-types';

import { IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN } from 'constants/theme';

export function Sale({ brand, isActive, width }) {
    const tagColor = brand === MISFITS_THEME_DOMAIN ? '#DE8255' : '#F6A685';
    const activeColor = brand === MISFITS_THEME_DOMAIN ? '#D7E1B8' : '#F0D3E3';

    return isActive ? (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10935_21802)">
                <path
                    d="M14.6505 28.7652C21.902 26.9646 29 22.6358 29 14.9992C29 7.3625 22.9452 2.7363 14.6505 1.1728C7.31233 -0.213857 2.48978 6.96057 1.13553 14.967C-0.132106 22.475 7.73356 30.4815 14.6505 28.7652Z"
                    fill={activeColor}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.2848 22.7151C24.07 21.9459 24.7639 20.9594 24.0905 18.6936C23.464 16.5906 20.4392 9.0912 19.615 7.16127C18.9059 5.53388 17.4877 4.6353 15.8648 4.16838L10.6385 2.73797C10.0449 2.60234 9.30462 2.79351 8.95112 3.28927L6.50495 7.01228C5.70012 8.22167 4.81265 9.90663 5.18388 11.1205C5.92135 13.5351 8.77676 21.9947 9.95497 24.6181C11.0682 27.0705 12.9582 26.3594 14.4278 25.7102L22.2939 22.7192L22.2848 22.7151ZM11.1554 10.0167C12.1358 10.4569 13.1227 9.98377 13.5099 9.12137C13.8972 8.25898 13.477 7.15404 12.6146 6.76681C11.6342 6.32659 10.5384 6.75081 10.1511 7.61321C9.7639 8.47561 10.184 9.58054 11.1554 10.0167Z"
                    fill={tagColor}
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeMiterlimit="10"
                />
                <path
                    d="M16.0018 22.7628L15.6306 21.549C13.981 21.7246 13.1074 21.0705 12.5072 19.4921L14.6102 18.8656C14.9592 19.5459 15.346 19.8505 16.0773 19.6552C16.7087 19.4151 17.0133 19.0283 16.8332 18.5548C16.5931 17.9234 15.7439 18.0656 14.9526 18.103C13.7276 18.2075 12.2449 18.3271 11.6936 16.6397C11.4313 15.4747 11.9164 14.3945 13.217 13.5386L12.6768 12.118L14.5221 11.5066L15.0622 12.9273C16.3962 12.8717 17.3186 13.4168 17.9077 14.7285L15.9136 15.4039C15.5646 14.7236 15.3468 14.6258 14.6553 14.708C14.024 14.9481 13.8772 15.2749 14.1062 15.6395C14.3463 16.2709 15.1955 16.1287 15.9268 15.9334C17.1518 15.829 18.7434 15.7582 19.2458 17.5546C19.617 18.7684 19.192 20.0066 17.9403 20.7535L18.3115 21.9674L16.0059 22.7538L16.0018 22.7628Z"
                    fill="#2D2D2D"
                />
            </g>
            <defs>
                <clipPath id="clip0_10935_21802">
                    <rect width="30" height="30" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ) : (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.0034 18.3729C26.3169 16.9399 26.5458 15.7557 25.0034 13.9646C23.5704 12.3027 17.7391 6.70034 16.1967 5.27734C14.8832 4.08322 13.2214 3.8444 11.5496 4.08322L6.19598 4.91911C5.59892 5.03852 5.00186 5.51617 4.88245 6.11323L4.17592 10.5116C3.9371 11.9445 3.81769 13.8452 4.65357 14.8004C6.31539 16.7011 12.3855 23.2488 14.5349 25.1594C16.555 26.9407 17.9879 25.5177 19.0626 24.3236L25.0133 18.3729H25.0034ZM9.64897 11.3475C10.7237 11.3475 11.4302 10.5116 11.4302 9.56622C11.4302 8.62088 10.5943 7.78499 9.64897 7.78499C8.57427 7.78499 7.74833 8.62088 7.74833 9.56622C7.74833 10.5116 8.58422 11.3475 9.64897 11.3475Z"
                fill={tagColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M19.2911 20.99L18.4552 20.0347C17.0222 20.8706 15.9575 20.6318 14.7633 19.4376L16.4252 18.0047C17.0222 18.4823 17.4999 18.6018 18.087 18.1241C18.5646 17.6465 18.684 17.1688 18.3258 16.8106C17.8482 16.3329 17.1317 16.8106 16.4252 17.1688C15.3505 17.7659 14.0469 18.4823 12.8528 17.1688C12.1363 16.2135 12.1363 15.0293 12.9722 13.7158L11.8975 12.6411L13.3304 11.3276L14.4051 12.4023C15.5992 11.8052 16.664 11.9246 17.7387 12.8799L16.1963 14.3129C15.5992 13.8352 15.3604 13.8352 14.7633 14.1935C14.2857 14.6711 14.2857 15.0293 14.6439 15.2682C15.1216 15.7458 15.8381 15.2682 16.4252 14.7905C17.4999 14.1935 18.9229 13.477 20.117 14.9099C20.9529 15.8652 21.0723 17.1688 20.2364 18.3629L21.0723 19.3182L19.2911 20.98V20.99Z"
                fill="#2D2D2D"
            />
        </svg>
    );
}

Sale.propTypes = {
    brand: PropTypes.oneOf([IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN]).isRequired,
    isActive: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Sale.defaultProps = {
    isActive: false,
    width: 30,
};
