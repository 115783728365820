import { useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import { JoinModal } from 'features/paid-membership/JoinModal';
import Button from 'honeydew/Button';
import { useAccountPlusMembershipHook } from 'hooks/useAccountPlusMembershipHook';
import { trackEvent } from 'utils/analyticsUtils';

function PromotionalBannerBase({
    ctaCopy,
    heading,
    hideCta,
    iconLeft,
    iconRight,
    itemsCenter,
    subheading,
    trackingPosition,
}) {
    const [showJoinModal, setShowJoinModal] = useState(false);
    const { availableCredits, availableTrialMembership } = useAccountPlusMembershipHook();

    let defaultCtaCopy = 'Start saving today';

    if (availableCredits?.credits > 0) {
        defaultCtaCopy = `Get $${availableCredits?.credits} when you join`;
    }

    if (availableTrialMembership) {
        defaultCtaCopy = 'Start free trial today';
    }

    const bigMobileScreen = useMediaQuery({ minWidth: '580px' });
    const smallTabletScreen = useMediaQuery({ minWidth: '700px', maxWidth: '880px' });
    const renderBothIcons = bigMobileScreen && !smallTabletScreen && iconLeft && iconRight;

    let backgroundIcons = '';

    if (renderBothIcons) {
        backgroundIcons = `${iconLeft}, ${iconRight}`;
    } else if (!itemsCenter) {
        backgroundIcons = iconRight;
    }

    const headingRef = useRef('');

    function handleClick(e) {
        if (e.type !== 'keydown' || e.code === 'Enter' || e.key === 'Enter' || e.keyCode === 13) {
            setShowJoinModal(true);

            const trackingCopy = headingRef.current?.textContent?.replace(/\s+/g, ' ').trim();

            trackEvent('member deals banner click', {
                copy: trackingCopy,
                position: trackingPosition,
            });
        }
    }

    return (
        <>
            <div
                className={clsx('justify-between overflow-hidden rounded-lg bg-plus-membership-gradient', {
                    'cursor-pointer': !hideCta,
                })}
                {...(!hideCta && {
                    role: 'button',
                    tabIndex: '0',
                    onClick: handleClick,
                    onKeyDown: handleClick,
                })}
            >
                <div style={{ background: backgroundIcons }}>
                    <div
                        className={clsx('flex min-h-[92px] flex-col p-10', {
                            'items-center justify-center text-center': renderBothIcons || itemsCenter,
                        })}
                    >
                        <h4
                            ref={headingRef}
                            className="mt-[-6px] font-grotesk-bold text-body-mobile-sm !leading-[20px] lg:text-body-desktop-md"
                        >
                            {heading}
                        </h4>
                        {subheading && <p className="text-body-mobile-sm lg:text-body-desktop-md">{subheading}</p>}
                        {!hideCta && (
                            <div className="mt-5 flex">
                                <Button size="sm" as="span" rounded="full" variant="plusMembership">
                                    {ctaCopy || defaultCtaCopy}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {showJoinModal && <JoinModal open={showJoinModal} onClose={() => setShowJoinModal(false)} />}
        </>
    );
}

PromotionalBannerBase.propTypes = {
    ctaCopy: PropTypes.string,
    heading: PropTypes.node.isRequired,
    hideCta: PropTypes.bool,
    iconLeft: PropTypes.string,
    iconRight: PropTypes.string,
    itemsCenter: PropTypes.bool,
    subheading: PropTypes.node,
    trackingPosition: PropTypes.number,
};

PromotionalBannerBase.defaultProps = {
    ctaCopy: undefined,
    hideCta: false,
    iconLeft: undefined,
    iconRight: undefined,
    itemsCenter: false,
    subheading: undefined,
    trackingPosition: undefined,
};

export default PromotionalBannerBase;
