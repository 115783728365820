import { useQuery } from '@tanstack/react-query';

import { getCustomerExists } from 'api/jalapeno';

const fetchCustomerExists = async ({ email }) => {
    const response = await getCustomerExists({ sendParams: { email } });

    return {
        ...response.data,
    };
};

export const CUSTOMER_EXISTS_QUERY_KEY = 'customer-exists';

const useCustomerExistsQuery = ({ email, extendedQueryKeys = [], queryProps }) => {
    const query = useQuery({
        queryKey: [CUSTOMER_EXISTS_QUERY_KEY, ...extendedQueryKeys],
        queryFn: () => fetchCustomerExists({ email }),
        ...queryProps,
    });

    return query;
};

export default useCustomerExistsQuery;
