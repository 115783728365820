import useAccountQuery from 'api/queries/useAccountQuery';
import useSelectionsQuery from 'api/queries/useSelectionsQuery';
import Progress from 'honeydew/Progress';
import { useAccountPlusMembershipHook } from 'hooks/useAccountPlusMembershipHook/useAccountPlusMembershipHook';
import { filterPerkSelections, getGroceryItems, getItemsTotalCost, getOrderMin } from 'utils/orderUtils';
import { getProductsSubtotal, PriceType } from 'utils/priceUtils';

function OrderMinProgress() {
    const { data: account } = useAccountQuery();
    const { data: selections } = useSelectionsQuery();

    const { inPlusMembershipTreatment, plusMembershipEligibleOrder } = useAccountPlusMembershipHook();

    if (!account || !selections) return false;

    const filteredSelections = filterPerkSelections(selections);

    const orderMin = getOrderMin(account);
    const groceryItems = getGroceryItems(filteredSelections);

    const priceType = plusMembershipEligibleOrder ? PriceType.PLUS_MEMBER : PriceType.DEFAULT;

    const groceryItemsTotalCost = inPlusMembershipTreatment
        ? getProductsSubtotal({
              options: { priceType },
              products: groceryItems,
          })
        : getItemsTotalCost(groceryItems);

    const orderMinProgress = orderMin > 0 ? (groceryItemsTotalCost / orderMin) * 100 : 100;

    return groceryItemsTotalCost >= orderMin ? (
        <p className="text-body-sm text-brand-primary font-grotesk-bold">Grocery order min met!</p>
    ) : (
        <div>
            <div className="mt-5 flex items-center justify-between">
                <div className="mr-30 grow">
                    <Progress color="grey" progress={orderMinProgress} width="full" />
                </div>
                <p className="text-body-sm font-grotesk-bold">
                    <span className="text-error">${groceryItemsTotalCost.toFixed(2)} </span> / ${orderMin.toFixed(2)}{' '}
                    min
                </p>
            </div>
            <p className="text-body-sm">
                Add{' '}
                <span className="font-grotesk-bold text-error">${(orderMin - groceryItemsTotalCost)?.toFixed(2)}</span>{' '}
                to ship Groceries order
            </p>
        </div>
    );
}

export default OrderMinProgress;
