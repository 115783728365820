import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';

import useAccountQuery from 'api/queries/useAccountQuery';
import {
    ALCOHOL_SERVICE_FEE,
    MIN_WINE_BOTTLE_QTY_FOR_PERCENT_DISCOUNT_TIER_1,
    MIN_WINE_BOTTLE_QTY_FOR_PERCENT_DISCOUNT_TIER_2,
    MIN_WINE_BOTTLE_QTY_TO_SHIP,
    MIN_WINE_BOTTLE_QTY_TO_WAIVE_FEE,
    WINE_PERCENT_DISCOUNT_TIER_1,
    WINE_PERCENT_DISCOUNT_TIER_2,
} from 'constants/shop';
import { GlobalContext } from 'contexts/GlobalContext';
import CartOrderCard from 'features/shop/cart/CartOrderCard';
import CartProductCard from 'features/shop/cart/CartProductCard';
import { Alert } from 'honeydew/Alert';
import Button from 'honeydew/Button';
import { useAccountPlusMembershipHook } from 'hooks/useAccountPlusMembershipHook/useAccountPlusMembershipHook';
import { plulurizeWord } from 'utils/globalUtils';
import { getTotalProductQty, getTotalWineBottleQty, getWineItems } from 'utils/orderUtils';

function CartWineOrderCard({ selections, wineOrderCardRef }) {
    const navigate = useNavigate();
    const { setCartOpen } = useContext(GlobalContext);

    const { inPlusMembershipTreatment, plusMembershipEligibleOrder, plusMembershipShortBranding } =
        useAccountPlusMembershipHook();

    const { data: account } = useAccountQuery();

    const { unbundledWine: unbundleWineFeatureFlag } = account?.featureFlags ?? {};

    const wineItems = getWineItems(selections);
    const totalWineProductQty = getTotalProductQty(wineItems);
    const totalWineBottleQty = getTotalWineBottleQty(selections);
    const bottlesToMeetMin = MIN_WINE_BOTTLE_QTY_TO_SHIP - totalWineBottleQty;
    const bottlesToWaiveFee = MIN_WINE_BOTTLE_QTY_TO_WAIVE_FEE - totalWineBottleQty;
    const bottlesFor10OFF = MIN_WINE_BOTTLE_QTY_FOR_PERCENT_DISCOUNT_TIER_1 - totalWineBottleQty;
    const bottlesFor15OFF = MIN_WINE_BOTTLE_QTY_FOR_PERCENT_DISCOUNT_TIER_2 - totalWineBottleQty;
    const winePercentOff = selections?.alcoholDiscountPercentage;

    const navigateToPDP = (productId) => {
        if (window.isNativeApp) {
            window.ReactNativeWebView?.postMessage(JSON.stringify({ navigate: 'PDP', pdpId: productId }));
        } else {
            setCartOpen(false);
            navigate(`/shop/product/${productId}`);
        }
    };

    const navigateToWineAisle = () => {
        if (window.isNativeApp) {
            window.ReactNativeWebView?.postMessage(JSON.stringify({ navigate: 'Wine' }));
        } else {
            setCartOpen(false);
            navigate('/shop/aisle/wine+shop/all');
        }
    };

    return (
        <CartOrderCard
            cardRef={wineOrderCardRef}
            percentOff={winePercentOff}
            title={`Wine (${totalWineProductQty})`}
            willShip={totalWineBottleQty >= MIN_WINE_BOTTLE_QTY_TO_SHIP}
        >
            {totalWineBottleQty < MIN_WINE_BOTTLE_QTY_TO_WAIVE_FEE ? (
                <div className="mt-10">
                    {totalWineBottleQty < MIN_WINE_BOTTLE_QTY_TO_SHIP ? (
                        <Alert>
                            Add{' '}
                            <span className="font-grotesk-bold">
                                {bottlesToMeetMin} {plulurizeWord('bottle', bottlesToMeetMin)}
                            </span>{' '}
                            to meet order minimum
                        </Alert>
                    ) : (
                        <Alert>
                            Add{' '}
                            <span className="font-grotesk-bold">
                                {bottlesToWaiveFee} {plulurizeWord('bottle', bottlesToWaiveFee)}
                            </span>{' '}
                            to waive alcohol service fee
                        </Alert>
                    )}
                </div>
            ) : (
                <div className="mt-10">
                    <Alert variant="success">
                        Your Alcohol Service Fee is waived!
                        {unbundleWineFeatureFlag && (
                            <span>
                                {totalWineBottleQty < MIN_WINE_BOTTLE_QTY_FOR_PERCENT_DISCOUNT_TIER_1 ? (
                                    <>
                                        <br />
                                        Add{' '}
                                        <span className="font-grotesk-bold">
                                            {bottlesFor10OFF} {plulurizeWord('bottle', bottlesFor10OFF)}
                                        </span>{' '}
                                        to save {WINE_PERCENT_DISCOUNT_TIER_1}%
                                    </>
                                ) : (
                                    totalWineBottleQty < MIN_WINE_BOTTLE_QTY_FOR_PERCENT_DISCOUNT_TIER_2 && (
                                        <>
                                            <br />
                                            Add{' '}
                                            <span className="font-grotesk-bold">
                                                {bottlesFor15OFF} {plulurizeWord('bottle', bottlesFor15OFF)}
                                            </span>{' '}
                                            to save {WINE_PERCENT_DISCOUNT_TIER_2}%
                                        </>
                                    )
                                )}
                            </span>
                        )}
                    </Alert>
                </div>
            )}
            <p className="text-body-xs mt-10 text-grey">
                ${ALCOHOL_SERVICE_FEE} alcohol service fee applies; purchase at least {MIN_WINE_BOTTLE_QTY_TO_WAIVE_FEE}{' '}
                {plulurizeWord('bottle', MIN_WINE_BOTTLE_QTY_TO_WAIVE_FEE)} of Wine to waive the fee
            </p>
            <div className="mb-20 mt-10 flex flex-col gap-30">
                {wineItems.map((product) => (
                    <CartProductCard
                        inPlusMembershipTreatment={inPlusMembershipTreatment}
                        plusMembershipEligibleOrder={plusMembershipEligibleOrder}
                        plusMembershipShortBranding={plusMembershipShortBranding}
                        key={product.id}
                        onClick={() => navigateToPDP(product.id)}
                        product={product}
                        usePlusMemberPrice
                    />
                ))}
            </div>
            <Button onClick={navigateToWineAisle} size="md" variant="secondary">
                Shop Wine
            </Button>
            <p className="text-body-xs mt-10 text-grey">
                Requires a {MIN_WINE_BOTTLE_QTY_TO_SHIP}-bottle minimum to ship. Wine is sold and shipped separately.
                Does not apply to your grocery order minimum.
            </p>
        </CartOrderCard>
    );
}

export default CartWineOrderCard;

CartWineOrderCard.propTypes = {
    selections: PropTypes.shape({
        selectedBundles: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    wineOrderCardRef: PropTypes.shape({
        current: PropTypes.instanceOf(Element),
    }),
};

CartWineOrderCard.defaultProps = {
    wineOrderCardRef: undefined,
};
