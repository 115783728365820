import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Custom hook to manage smart banner behavior for mobile app promotion
 * @returns {Object} - Object containing addSmartbannerPadding boolean to adjust layout spacing
 */
function useSmartBanner() {
    const topOffset = '60px';
    const marginTop = '84px';

    const navigate = useNavigate();

    const [addSmartbannerPadding, setAddSmartbannerPadding] = useState(false);

    useEffect(() => {
        const smartbanner = document.querySelector('.smartbanner');
        const smartbannerElement = document.querySelector('[data-smartbanner-original-margin-top]');

        setAddSmartbannerPadding(!!smartbannerElement && smartbannerElement.style.marginTop !== marginTop);

        if (smartbanner) {
            smartbanner.style.top = topOffset;
        }
    }, [navigate, window.location.pathname]);

    useEffect(() => {
        const handleSmartbannerExit = () => {
            setAddSmartbannerPadding(false);
        };

        document.addEventListener('smartbanner.exit', handleSmartbannerExit);

        return () => {
            document.removeEventListener('smartbanner.exit', handleSmartbannerExit);
        };
    }, []);

    return {
        addSmartbannerPadding,
    };
}

export default useSmartBanner;
