import PropTypes from 'prop-types';

import { IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN } from 'constants/theme';

export function PlantBased({ brand, isActive, width }) {
    const cornColor = brand === MISFITS_THEME_DOMAIN ? '#F1C34A' : '#F7D46D';
    const leafColor = brand === MISFITS_THEME_DOMAIN ? '#B0C472' : '#C5DB66';
    const activeColor = brand === MISFITS_THEME_DOMAIN ? '#D7E1B8' : '#F0D3E3';

    return isActive ? (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.6505 28.7652C21.902 26.9646 29 22.6358 29 14.9992C29 7.3625 22.9452 2.7363 14.6505 1.1728C7.31233 -0.213857 2.48978 6.96057 1.13553 14.967C-0.132106 22.475 7.73356 30.4815 14.6505 28.7652Z"
                fill={activeColor}
            />
            <path
                d="M8.27968 25.7875C10.4597 24.5175 11.8197 22.2475 12.9597 19.9075C14.0997 17.5675 14.5897 14.8475 14.3397 12.1675C14.1197 9.8275 13.3597 7.5175 13.4497 5.1875C4.35967 11.3475 4.89967 18.5175 5.06967 20.3575C5.23967 22.1975 6.38967 24.3575 7.82967 25.4575L8.26968 25.7775L8.27968 25.7875Z"
                fill={leafColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinejoin="round"
            />
            <path
                d="M10.4494 12.0375C12.1394 9.79754 13.8394 7.56754 15.5294 5.32754C16.1394 4.52754 16.7394 3.72754 17.4694 3.01754C17.7094 2.77754 18.3994 1.87754 19.3694 2.41754C20.2094 1.82754 21.4694 1.86754 22.2694 2.50754C23.2594 3.29754 23.4194 4.68754 23.4294 5.92754C23.4594 10.0775 19.5694 19.9275 15.8894 24.7075C14.8894 25.9975 13.2494 26.7475 11.5894 26.7175C9.91943 26.6775 8.29943 25.8275 7.34943 24.5075C6.27943 23.0075 6.10943 21.0375 6.51943 19.2575C6.91943 17.4775 7.83943 15.8475 8.79943 14.2775C9.22943 13.5675 9.96943 12.7175 10.4694 12.0475L10.4494 12.0375Z"
                fill={cornColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M22.1293 2.38757C22.1893 3.65757 21.1693 6.98757 20.7693 8.14757C17.9493 16.0876 14.9193 20.5476 10.5293 26.5776"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M19.3595 2.4176C15.0595 9.2976 10.6095 17.4476 7.72949 24.9776"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M23.4493 6.5876C22.7893 6.2476 22.2493 5.7276 21.6193 5.3476C20.0493 4.3876 18.0993 4.2976 16.2393 4.3476"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M22.7995 9.93756C22.0095 9.52756 21.3295 8.93756 20.5695 8.48756C18.6795 7.35756 16.3995 7.09756 14.2695 6.99756"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M21.8997 12.7775C21.0197 12.3175 20.2497 11.6875 19.3997 11.1775C17.2797 9.91753 14.7997 9.54753 12.4697 9.34753"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M20.6892 15.9076C19.7292 15.4076 18.8792 14.7276 17.9492 14.1776C15.6292 12.8176 12.9492 12.3376 10.4492 12.0376"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M16.7797 23.4175C15.8197 22.9175 14.9697 22.2375 14.0397 21.6875C11.7197 20.3275 8.92969 19.9075 6.42969 19.6075"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M18.8795 19.8276C17.9195 19.3276 16.7695 18.2976 15.8295 17.7476C13.5095 16.3876 10.4795 15.9176 7.97949 15.6176"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M7.70996 25.5875C7.77996 23.0175 9.22996 20.7975 10.86 18.7575C12.49 16.7175 14.83 15.1375 17.51 14.2675C19.85 13.5075 22.45 13.2775 24.63 12.2275C23.51 23.3175 16.39 25.7675 14.55 26.3675C12.71 26.9675 10.05 26.7675 8.23996 25.8675L7.70996 25.5775V25.5875Z"
                fill={leafColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinejoin="round"
            />
            <path
                d="M20.9093 17.1075C14.9593 24.3875 11.4993 24.8075 8.06934 25.7775"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
        </svg>
    ) : (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.99404 26.4375C10.684 25.4475 12.214 22.9775 13.954 20.5775C15.694 18.1875 16.774 15.2375 17.014 12.1875C17.234 9.52751 16.834 6.78751 17.394 4.19751C6.02404 9.31751 5.21404 17.4475 5.03404 19.5375C4.85404 21.6375 5.32404 24.6375 7.99404 26.4375Z"
                fill={leafColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinejoin="round"
            />
            <path
                d="M10.9143 12.0375C12.6043 9.79754 14.3043 7.56754 15.9943 5.32754C16.6043 4.52754 17.2043 3.72754 17.9343 3.01754C18.1743 2.77754 18.8643 1.87754 19.8343 2.41754C20.6743 1.82754 21.9343 1.86754 22.7343 2.50754C23.7243 3.29754 23.8843 4.68754 23.8943 5.92754C23.9243 10.0775 20.0343 19.9275 16.3543 24.7075C15.3543 25.9975 13.7143 26.7475 12.0543 26.7175C10.3843 26.6775 8.76428 25.8275 7.81428 24.5075C6.74427 23.0075 6.57428 21.0375 6.98428 19.2575C7.38428 17.4775 8.30428 15.8475 9.26428 14.2775C9.69428 13.5675 10.4343 12.7175 10.9343 12.0475L10.9143 12.0375Z"
                fill={cornColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M22.5844 2.38757C22.6444 3.65757 21.6244 6.98757 21.2244 8.14757C18.4044 16.0876 15.3744 20.5476 10.9844 26.5776"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M19.8236 2.4176C15.5236 9.2976 11.0736 17.4476 8.18359 24.9776"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M23.9141 6.5876C23.2541 6.2476 22.7141 5.7276 22.0841 5.3476C20.5141 4.3876 18.5641 4.2976 16.7041 4.3476"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M23.2644 9.93756C22.4744 9.52756 21.7944 8.93756 21.0344 8.48756C19.1444 7.35756 16.8644 7.09756 14.7344 6.99756"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M22.3636 12.7775C21.4836 12.3175 20.7136 11.6875 19.8636 11.1775C17.7436 9.91753 15.2636 9.54753 12.9336 9.34753"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M21.1541 15.9076C20.1941 15.4076 19.3441 14.7276 18.4141 14.1776C16.0941 12.8176 13.4141 12.3376 10.9141 12.0376"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M17.2445 23.4175C16.2845 22.9175 15.4345 22.2375 14.5045 21.6875C12.1845 20.3275 9.39453 19.9075 6.89453 19.6075"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M19.3443 19.8276C18.3843 19.3276 17.2343 18.2976 16.2943 17.7476C13.9743 16.3876 10.9443 15.9176 8.44434 15.6176"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M7.99354 26.4375C2.30354 15.3875 14.3535 11.9675 17.5035 10.3375C20.2435 8.91753 23.4235 8.08753 25.8935 6.34753C26.7835 20.3075 18.4835 24.8075 16.3235 25.9275C14.1635 27.0575 10.8335 27.3475 8.41354 26.6075L8.00354 26.4275L7.99354 26.4375Z"
                fill={leafColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinejoin="round"
            />
            <path
                d="M22.2841 13.1576C16.4141 23.3976 12.2141 24.6276 8.16406 26.5376"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
        </svg>
    );
}

PlantBased.propTypes = {
    brand: PropTypes.oneOf([IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN]).isRequired,
    isActive: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PlantBased.defaultProps = {
    isActive: false,
    width: 30,
};
