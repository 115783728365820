import PropTypes from 'prop-types';

import { IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN } from 'constants/theme';

export function Wine({ brand, isActive, width }) {
    const wineColor = brand === MISFITS_THEME_DOMAIN ? '#B22624' : '#D9291F';
    const activeColor = brand === MISFITS_THEME_DOMAIN ? '#D7E1B8' : '#F0D3E3';

    return isActive ? (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.6505 28.7652C21.902 26.9646 29 22.6358 29 14.9992C29 7.3625 22.9452 2.7363 14.6505 1.1728C7.31233 -0.213857 2.48978 6.96057 1.13553 14.967C-0.132106 22.475 7.73356 30.4815 14.6505 28.7652Z"
                fill={activeColor}
            />
            <path
                d="M12 2C12 2 8.29 6.37 8.82 10.22C9.35 14.07 12.71 15.13 13.25 16.17C13.82 17.27 12.8 23.53 12.49 23.92C12.18 24.31 11.13 24.94 9.64 24.68C8.63 24.5 8.32 24.77 8.01 25.27C7.83 25.56 10.1 26.2 13.12 26.72C16.13 27.24 18.3 27.43 18.29 27.18C18.29 26.93 17.98 25.92 16.65 25.85C16.06 25.82 15.15 25.42 14.72 25.09C14.07 24.6 13.79 23.89 13.97 22.83C14.15 21.77 14.23 20.63 14.79 19.09C15.35 17.55 15.79 16.72 16.76 16.25C17.73 15.78 20.3 15.21 21.41 13.83C22.35 12.66 22.75 11.59 22.7 8.66C22.65 5.73 21.91 3.67 21.91 3.67C20.73 2.77 14.81 2.18 12 2Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.3503 7.77002C10.1503 7.84002 9.4403 9.92002 10.1203 11.44C10.8603 13.08 12.1803 14.26 14.7603 14.57C17.3503 14.88 19.5903 14.27 20.3503 13.14C21.1003 12.01 21.4003 10.45 21.4403 10.19C20.8203 10.32 19.2603 11.17 16.8803 10.21C14.5003 9.25002 13.7103 6.57002 10.3303 7.77002H10.3503Z"
                fill={wineColor}
            />
        </svg>
    ) : (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.2891 3.17084C10.2891 3.17084 7.1791 7.99084 8.2091 11.7408C9.2391 15.4908 12.7091 16.1008 13.3791 17.0608C14.0791 18.0708 13.8991 24.4208 13.6391 24.8508C13.3791 25.2808 12.4191 26.0408 10.9091 25.9708C9.8791 25.9208 9.6191 26.2308 9.3691 26.7708C9.2291 27.0808 11.5691 27.4208 14.6191 27.5408C17.6691 27.6608 19.8491 27.5608 19.8091 27.3208C19.7691 27.0808 19.3291 26.1108 18.0091 26.2208C17.4191 26.2708 16.4691 25.9908 15.9891 25.7208C15.2791 25.3208 14.9091 24.6508 14.9491 23.5808C14.9891 22.5108 14.9191 21.3708 15.2691 19.7708C15.6191 18.1708 15.9491 17.2908 16.8491 16.6908C17.7491 16.1008 20.2291 15.1908 21.1391 13.6808C21.9191 12.3908 22.1691 11.2808 21.7391 8.38084C21.3091 5.48084 20.3091 3.54084 20.3091 3.54084C19.0391 2.79084 13.0991 2.98084 10.2891 3.17084Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.40943 9.10081C9.21943 9.20081 8.78943 11.3508 9.66943 12.7708C10.6194 14.3008 12.0794 15.3008 14.6794 15.2708C17.2794 15.2408 19.4294 14.3408 20.0294 13.1308C20.6294 11.9208 20.7194 9.36081 20.7294 9.10081C19.2894 9.30081 11.9094 8.80081 9.40943 9.11081V9.10081Z"
                fill={wineColor}
            />
        </svg>
    );
}

Wine.propTypes = {
    brand: PropTypes.oneOf([IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN]).isRequired,
    isActive: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Wine.defaultProps = {
    isActive: false,
    width: 30,
};
