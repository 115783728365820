import { useEffect, useRef, useState } from 'react';

import { NANO_BANNER_SCROLL_Y } from 'constants';
import useValidReferralsCampaign from 'hooks/useValidReferralsCampaign';

// returns true if the nano banner is scrolled into view at the top of a given page
function useHandleReferralNanoBannerScroll() {
    const validReferralsCampaign = useValidReferralsCampaign();
    const scrollThrottleInProgress = useRef();
    const [referralNanoBannerVisible, setReferralNanoBannerVisible] = useState(true);

    const urlParams = new URLSearchParams(window.location.search);
    const nativeApp = window.isNativeApp || urlParams.get('app') === 'true';

    const handleReferralNanoBannerScroll = () => {
        if (scrollThrottleInProgress.current) return null;
        scrollThrottleInProgress.current = true;

        setTimeout(() => {
            setReferralNanoBannerVisible(window.scrollY <= NANO_BANNER_SCROLL_Y);
            scrollThrottleInProgress.current = false;
        }, 300);

        return null;
    };

    useEffect(() => {
        if (validReferralsCampaign && !nativeApp) {
            window.addEventListener('scroll', handleReferralNanoBannerScroll);
        }

        return () => window.removeEventListener('scroll', handleReferralNanoBannerScroll);
    }, [validReferralsCampaign]);

    return referralNanoBannerVisible;
}

export default useHandleReferralNanoBannerScroll;
