import { createContext, useEffect, useMemo, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { PropTypes } from 'prop-types';

const AuthContext = createContext(null);

function AuthProvider({ children }) {
    const [authUser, setAuthUser] = useState({});
    const [authStatus, setAuthStatus] = useState('configuring');

    const getAuthUser = async () => {
        setAuthStatus('configuring');
        try {
            const user = await Auth.currentAuthenticatedUser();
            setAuthUser(user);
            setAuthStatus('authenticated');
        } catch (error) {
            setAuthUser({});
            setAuthStatus('unauthenticated');
        }
    };

    useEffect(() => {
        getAuthUser();
    }, []);

    useEffect(() => {
        Hub.listen('auth', ({ payload }) => {
            const { event } = payload;
            if (event === 'signIn') {
                const user = payload.data;
                setAuthUser(user);
                setAuthStatus('authenticated');
            } else if (event === 'signIn_failure') {
                setAuthUser({});
                setAuthStatus('unauthenticated');
            } else if (event === 'signOut') {
                setAuthUser({});
                setAuthStatus('unauthenticated');
            }
        });
    }, []);

    const value = useMemo(
        () => ({
            authUser,
            authStatus,
            getAuthUser,
        }),
        [authUser, authStatus]
    );
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { AuthContext, AuthProvider };
