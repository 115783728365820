import PropTypes from 'prop-types';

import { IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN } from 'constants/theme';

export function Pet({ brand, isActive, width }) {
    const boneColor = brand === MISFITS_THEME_DOMAIN ? '#DC6B31' : '#DD3D96';
    const foodColor = brand === MISFITS_THEME_DOMAIN ? '#DC6B31' : '#F6A685';
    const activeColor = brand === MISFITS_THEME_DOMAIN ? '#D7E1B8' : '#F0D3E3';

    return (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            {isActive && (
                <path
                    d="M14.6505 28.7652C21.902 26.9646 29 22.6358 29 14.9992C29 7.3625 22.9452 2.7363 14.6505 1.1728C7.31233 -0.213857 2.48978 6.96057 1.13553 14.967C-0.132106 22.475 7.73356 30.4815 14.6505 28.7652Z"
                    fill={activeColor}
                />
            )}
            <path
                d="M3.3448 12.3103C3.3448 12.3103 1.54908 23.8329 2.10775 24.3018C2.65644 24.7707 7.22557 26.9255 15.8351 24.9502C22.7686 23.364 26.6094 20.7303 27.9662 18.4657C28.5149 17.8272 22.1999 7.92078 22.1999 7.92078L3.3448 12.3103Z"
                fill="#F8E1A4"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M13.5008 13.2979C18.6984 12.0908 22.5891 9.69646 22.1801 7.94064C21.7711 6.18482 17.2319 5.73589 12.0343 6.94302C6.82666 8.15014 2.93591 10.5444 3.34494 12.3003C3.75397 14.0561 8.29317 14.505 13.4908 13.2979H13.5008Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.2589 17.5877C18.5782 17.5179 18.8675 17.3483 19.0869 17.1089C19.3064 16.8694 19.4561 16.5701 19.5159 16.2509C19.5658 15.9317 19.5259 15.6025 19.3962 15.3032C19.2665 15.0039 19.047 14.7545 18.7677 14.5849C18.4884 14.4153 18.1691 14.3255 17.8399 14.3355C17.5107 14.3454 17.2014 14.4552 16.9321 14.6447C16.6627 14.8343 16.4632 15.0937 16.3535 15.4029C16.2337 15.7122 16.2138 16.0414 16.2936 16.3606C16.3135 16.4504 16.3435 16.5402 16.3834 16.63L11.6846 17.7174C11.6846 17.6276 11.6646 17.5279 11.6447 17.4381C11.5748 17.1288 11.4052 16.8495 11.1758 16.63C10.9463 16.4105 10.657 16.2609 10.3477 16.201C10.0385 16.1412 9.70927 16.1711 9.41995 16.2908C9.13064 16.4105 8.87126 16.6101 8.69169 16.8694C8.51212 17.1288 8.41235 17.4381 8.3924 17.7573C8.37245 18.0766 8.46223 18.3858 8.62185 18.6652C8.78147 18.9345 9.0209 19.154 9.31022 19.2937C9.59953 19.4333 9.90879 19.4932 10.228 19.4533C9.92874 19.553 9.65938 19.7426 9.45986 19.992C9.26033 20.2414 9.14062 20.5507 9.11069 20.8599C9.08076 21.1792 9.1506 21.4984 9.30024 21.7778C9.44988 22.0571 9.67934 22.2965 9.95867 22.4462C10.238 22.5958 10.5572 22.6756 10.8765 22.6457C11.1957 22.6257 11.495 22.506 11.7544 22.3165C12.0138 22.1269 12.2033 21.8576 12.3131 21.5583C12.4228 21.259 12.4428 20.9298 12.363 20.6205C12.343 20.5307 12.3131 20.4409 12.2732 20.3512L16.972 19.2637C16.972 19.3535 16.9919 19.4533 17.0119 19.5431C17.0817 19.8623 17.2513 20.1516 17.4907 20.3711C17.7302 20.5906 18.0295 20.7402 18.3487 20.8001C18.6679 20.85 18.9972 20.8101 19.2965 20.6804C19.5957 20.5507 19.8451 20.3312 20.0147 20.0519C20.1843 19.7725 20.2741 19.4533 20.2641 19.1241C20.2542 18.7949 20.1444 18.4856 19.9549 18.2162C19.7653 17.9469 19.506 17.7473 19.1967 17.6276C18.8874 17.5079 18.5582 17.488 18.239 17.5678H18.2589V17.5877Z"
                fill={boneColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            {isActive && (
                <>
                    <path
                        d="M6.80664 13.9265C7.20569 13.1483 11.7948 8.86853 19.1872 11.0533C13.371 14.3255 6.80664 13.9265 6.80664 13.9265Z"
                        fill="#2D2D2D"
                    />
                    <path
                        d="M20.6341 10.365C20.8037 9.46709 20.5244 8.21008 19.8161 7.67136C19.1078 7.13264 18.0902 7.07279 17.332 7.52172C17.1225 6.62385 16.484 5.85568 15.6759 5.51649C14.8679 5.1773 13.8902 5.27706 13.1519 5.7659C12.5334 5.08751 11.5258 4.81815 10.6678 5.12742C9.80989 5.43668 9.17141 6.28466 9.08162 7.22243C8.38328 7.39203 7.77473 7.94072 7.51535 8.64903C7.25597 9.35735 7.36571 10.1954 7.78471 10.8039C7.14623 10.764 6.49777 11.0533 6.08875 11.5621C5.67972 12.0709 5.51012 13.1084 5.66974 13.7569C5.66974 13.7569 8.57283 14.4851 13.4612 13.4376C18.6489 12.3303 20.6242 10.345 20.6242 10.345L20.6341 10.365Z"
                        fill={foodColor}
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M9.20117 11.8014C9.70996 11.9411 10.2786 11.8513 10.7275 11.552C11.1765 11.2527 11.4758 10.7739 11.5456 10.2451C12.194 10.305 12.8724 9.98573 13.2515 9.45699C13.6306 8.92825 13.7005 8.19001 13.4311 7.59143"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M14.668 10.5845C15.1967 10.794 15.8152 10.7641 16.3141 10.4947C16.8129 10.2254 17.192 9.73653 17.3117 9.17786"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </>
            )}
        </svg>
    );
}

Pet.propTypes = {
    brand: PropTypes.oneOf([IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN]).isRequired,
    isActive: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Pet.defaultProps = {
    isActive: false,
    width: 30,
};
