import { useQuery } from '@tanstack/react-query';

import { getSelections } from 'api/jalapeno';

import useAccountQuery from './useAccountQuery';

export const SELECTIONS_QUERY_KEY = 'selections';

const useSelectionsQuery = () => {
    const { data: { nextOrder, pendingOrder } = {} } = useAccountQuery();

    const chargeID = pendingOrder?.status === 'SCHEDULED' ? pendingOrder?.chargeID : nextOrder?.chargeID;

    const query = useQuery({
        queryKey: [SELECTIONS_QUERY_KEY, chargeID],
        queryFn: async () => {
            const response = await getSelections(chargeID);
            return response.data;
        },
        enabled: !!chargeID,
    });

    return query;
};

export default useSelectionsQuery;
