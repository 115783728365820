import Tag from 'honeydew/Tag';

import { BEST_SELLER_TITLE, GIFT_TITLE, LAST_CHANCE_TITLE, NEW_TITLE, SALE_TITLE, SOLD_OUT_TITLE } from './constants';

function ProductTag({ bestSeller, exitSku, isGift, newProduct, sale, soldOut }) {
    if (isGift) {
        return (
            <Tag bgColor="success" variant="dark">
                {GIFT_TITLE}
            </Tag>
        );
    }
    if (soldOut)
        return (
            <Tag bgColor="grey" variant="dark">
                {SOLD_OUT_TITLE}
            </Tag>
        );
    if (sale)
        return (
            <Tag bgColor="error" variant="dark">
                {SALE_TITLE}
            </Tag>
        );
    if (exitSku)
        return (
            <Tag bgColor="lastChance" variant="dark">
                {LAST_CHANCE_TITLE}
            </Tag>
        );
    if (newProduct)
        return (
            <Tag bgColor="new" variant="dark">
                {NEW_TITLE}
            </Tag>
        );
    if (bestSeller)
        return (
            <Tag bgColor="bestSeller" variant="dark">
                {BEST_SELLER_TITLE}
            </Tag>
        );
    return null;
}

export default ProductTag;
