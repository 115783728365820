import React from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const backdropStyles = 'inset-0 z-20 fixed h-full w-full overflow-y-auto bg-black bg-opacity-30';

// ownerState error fix
// https://github.com/mui/material-ui/issues/32882#issuecomment-1135784140
const Backdrop = React.forwardRef((props, ref) => {
    const { className, open, ownerState, ...other } = props;

    return <div className={clsx('MuiModal-backdrop', { 'MuiBackdrop-open': open }, className)} {...other} ref={ref} />;
});

function Modal({
    bgColor,
    centered,
    children,
    flush,
    headline,
    icon,
    includeHorizontalPadding,
    onClose,
    open,
    topGradientBorder,
    xToClose,
    ...rest
}) {
    const handleCloseButtonClick = (e) => {
        if (e.type !== 'keydown' || e.code === 'Enter' || e.key === 'Enter' || e.keyCode === 13) onClose();
    };

    return (
        <ModalUnstyled
            open={open}
            onClose={onClose}
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    className: backdropStyles,
                },
            }}
            className="fixed inset-0 z-[5000] flex md:items-center md:justify-center"
            {...rest}
        >
            <div
                className={clsx([
                    bgColor,
                    'relative z-40 flex grow flex-col overflow-hidden pb-30 md:max-h-[90%] md:max-w-[640px] md:rounded-md md:shadow-base lg:max-w-[800px]',
                    { 'px-10 md:px-20': includeHorizontalPadding },
                    { 'flex items-center': centered },
                    { 'pt-50 md:pt-70': xToClose },
                    { 'pt-30': !xToClose },
                ])}
                data-testid="modal-content-root"
            >
                {topGradientBorder && <div className="absolute left-0 top-0 h-[8px] w-full bg-gradient-berry-blend" />}
                {xToClose && (
                    <button
                        type="button"
                        onKeyDown={handleCloseButtonClick}
                        onClick={handleCloseButtonClick}
                        className="absolute right-10 top-15 rounded-full bg-white md:right-20"
                        aria-label="Close modal"
                    >
                        <XMarkIcon className="h-30 w-30 text-black" />
                    </button>
                )}
                {icon && icon}
                {headline && (
                    <h2 className={clsx(['text-heading-md mb-20', { 'text-center': centered }])}>{headline}</h2>
                )}
                <div className={clsx({ 'overflow-y-auto': !flush })}>{children}</div>
            </div>
        </ModalUnstyled>
    );
}

Backdrop.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool.isRequired,
    ownerState: PropTypes.shape({}),
};

Backdrop.defaultProps = {
    className: null,
    ownerState: null,
};

Modal.propTypes = {
    bgColor: PropTypes.string,
    centered: PropTypes.bool,
    children: PropTypes.node.isRequired,
    flush: PropTypes.bool,
    headline: PropTypes.string,
    icon: PropTypes.node,
    includeHorizontalPadding: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    topGradientBorder: PropTypes.bool,
    xToClose: PropTypes.bool,
};

Modal.defaultProps = {
    bgColor: 'bg-white',
    centered: false,
    flush: false,
    headline: null,
    icon: null,
    includeHorizontalPadding: true,
    topGradientBorder: false,
    xToClose: true,
};

export default Modal;
