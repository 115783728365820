import { useState } from 'react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { IconButton, InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';

import { LoginErrors } from 'constants/login';
import PasswordRequirements from 'features/signup/PasswordRequirements';
import Button from 'honeydew/Button';
import TextField from 'honeydew/TextField';
import { validatePassword } from 'utils/loginUtils';

function ResetPasswordForm({
    codeRequired,
    confirmPassword,
    loading,
    newPassword,
    setConfirmPassword,
    setError,
    setNewPassword,
    setShowConfirmPassword,
    setShowNewPassword,
    showConfirmPassword,
    showNewPassword,
}) {
    const [passwordFocus, setPasswordFocus] = useState(false);

    return (
        <div className="relative">
            <div className="mb-20">
                <TextField
                    id="password-field"
                    name="password"
                    label="New password"
                    placeholder="Password"
                    type={showNewPassword ? 'text' : 'password'}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    onBlur={() => {
                        if (newPassword === '') {
                            setError(LoginErrors.NoPassword);
                        } else if (!validatePassword(newPassword)) {
                            setError(LoginErrors.InvalidPassword);
                        } else {
                            setError(LoginErrors.NoError);
                        }
                        setPasswordFocus(false);
                    }}
                    onFocus={() => setPasswordFocus(true)}
                    required
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Toggle password visibility"
                                    disableRipple
                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                    onMouseDown={(e) => e.preventDefault()}
                                >
                                    {showNewPassword ? (
                                        <EyeSlashIcon className="h-20 w-20" />
                                    ) : (
                                        <EyeIcon className="h-20 w-20" />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>

            {passwordFocus && (
                <div className="absolute right-0 top-0 z-50 flex -translate-y-[98%] transform flex-col items-end">
                    <div className="rounded-lg bg-white py-20 pl-15 pr-30 shadow-base">
                        <PasswordRequirements password={newPassword} />
                    </div>
                    <div className="mr-20 h-0 w-0 border-l-[12px] border-r-[12px] border-t-[12px] border-l-transparent border-r-transparent border-t-[white]" />
                </div>
            )}

            <div className="mb-20">
                <TextField
                    id="password-field"
                    name="password"
                    label="Confirm password"
                    placeholder="Password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={confirmPassword}
                    onChange={(e) => {
                        setConfirmPassword(e.target.value);
                        setError(newPassword !== e.target.value ? LoginErrors.PasswordMatch : LoginErrors.NoError);
                    }}
                    onBlur={() => {
                        if (newPassword === '') {
                            setError(LoginErrors.NoPassword);
                        } else if (!validatePassword(newPassword)) {
                            setError(LoginErrors.InvalidPassword);
                        } else if (newPassword !== confirmPassword) {
                            setError(LoginErrors.PasswordMatch);
                        } else {
                            setError(LoginErrors.NoError);
                        }
                    }}
                    required
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Toggle password visibility"
                                    disableRipple
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    onMouseDown={(e) => e.preventDefault()}
                                >
                                    {showConfirmPassword ? (
                                        <EyeSlashIcon className="h-20 w-20" />
                                    ) : (
                                        <EyeIcon className="h-20 w-20" />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>

            {!codeRequired && (
                <div className="mb-25 mt-10">
                    <Button
                        size="md"
                        type="submit"
                        disabled={loading || !validatePassword(newPassword) || newPassword !== confirmPassword}
                    >
                        Save Password & Sign In
                    </Button>
                </div>
            )}
        </div>
    );
}

ResetPasswordForm.propTypes = {
    codeRequired: PropTypes.bool.isRequired,
    confirmPassword: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    newPassword: PropTypes.string.isRequired,
    setConfirmPassword: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    setNewPassword: PropTypes.func.isRequired,
    setShowConfirmPassword: PropTypes.func.isRequired,
    setShowNewPassword: PropTypes.func.isRequired,
    showConfirmPassword: PropTypes.bool.isRequired,
    showNewPassword: PropTypes.bool.isRequired,
};

export default ResetPasswordForm;
