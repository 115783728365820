import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { debounce } from 'debounce';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

import { getStorefrontPicks, searchStorefront } from 'api/jalapeno';
import useAccountQuery from 'api/queries/useAccountQuery';
import useSelectionsQuery from 'api/queries/useSelectionsQuery';
import Button from 'honeydew/Button';
import Loader from 'honeydew/Loader';
import useGlobalStore from 'hooks/useGlobalStore';
import useTailwindTheme from 'hooks/useTailwindTheme';
import { sortSoldOutProductsLast } from 'utils/aislesUtils';
import { trackEvent } from 'utils/analyticsUtils';
import logError from 'utils/errorUtils';
import { disableBodyScroll, enableBodyScroll } from 'utils/globalUtils';

import { AutocompleteItem } from './AutocompleteItem';

export function SearchAutocomplete({ isShopRoute }) {
    const tailwindTheme = useTailwindTheme();
    const mobile = useMediaQuery({ maxWidth: tailwindTheme?.screens?.md });
    const { autocompleteOpen, searchQuery, setAutocompleteOpen, setCartOpen, setSearchQuery, setSearchResultsLength } =
        useGlobalStore();

    const { data: account = {} } = useAccountQuery();
    const { data: selections = {} } = useSelectionsQuery();

    const [noResults, setNoResults] = useState(false);
    const [autocompleteLoading, setAutocompleteLoading] = useState(true);
    const [autocompleteItems, setAutocompleteItems] = useState([]);

    const { chargeID } = account?.nextOrder ?? {};
    const { selected = [], selectedBundles = [] } = selections;
    const selectedItems = selected?.concat(selectedBundles);
    const { pathname } = window.location ?? {};

    const closeAutocomplete = () => {
        setAutocompleteOpen(false);
        enableBodyScroll(true);
        setAutocompleteLoading(false);
        setSearchQuery('');
        setCartOpen(false);
    };

    const filterAutocompleteItems = (array = []) => sortSoldOutProductsLast(array).slice(0, 6);

    const handleNoResults = async (id) => {
        setNoResults(true);

        try {
            const response = await getStorefrontPicks(id);
            const { recommendations = [] } = response?.data ?? {};
            if (recommendations.length > 0) {
                setAutocompleteItems(filterAutocompleteItems(recommendations));
            }
            setSearchResultsLength(0);
            setAutocompleteLoading(false);
        } catch (e) {
            logError(e);
            console.error(e);
        }
    };

    const loadAutocomplete = async (id, query) => {
        if (!id) return;

        setAutocompleteLoading(true);
        setAutocompleteItems([]);
        setNoResults(false);

        try {
            const response = await searchStorefront(id, query);
            const { items = [] } = response?.data ?? {};
            if (items?.length > 0) {
                const filteredItems = filterAutocompleteItems(items);
                setAutocompleteItems(filteredItems);
                setSearchResultsLength(filteredItems.length);
                setAutocompleteLoading(false);
            } else {
                handleNoResults(id);
            }
            trackEvent('search bar result preview', { pathname });
        } catch (e) {
            logError(e);
            console.error(e);
        }
    };

    const debouncedCallback = useCallback(debounce(loadAutocomplete, 500), []);

    useEffect(() => {
        if (searchQuery && searchQuery !== '') {
            debouncedCallback(chargeID, searchQuery);
        } else {
            setAutocompleteItems([]);
        }
    }, [searchQuery]);

    useEffect(() => {
        if (!isShopRoute && autocompleteOpen) closeAutocomplete();
    }, [isShopRoute]);

    useEffect(() => {
        if (autocompleteOpen && searchQuery !== '') {
            disableBodyScroll(mobile);
        } else {
            enableBodyScroll(true);
        }
    }, [autocompleteOpen, searchQuery]);

    return (
        autocompleteOpen &&
        searchQuery !== '' &&
        isShopRoute && (
            <div>
                {!mobile && (
                    <div
                        role="button"
                        tabIndex={0}
                        onClick={closeAutocomplete}
                        onKeyDown={closeAutocomplete}
                        aria-label="Close Autocomplete"
                        className="fixed inset-0 z-20 h-full w-full overflow-y-auto bg-black bg-opacity-50"
                    />
                )}
                <div
                    className={clsx(
                        'fixed left-0 right-0 z-20 overflow-y-scroll bg-white px-30 pb-[100px] pt-15 md:bottom-auto md:pb-30 md:shadow-base',
                        {
                            'bottom-0 top-[100px]': !window.isNativeApp && mobile,
                            'bottom-0 top-[56px]': window.isNativeApp,
                            'top-70 z-[1200] m-auto max-w-[700px] rounded-lg': !mobile,
                        }
                    )}
                >
                    {autocompleteLoading && searchQuery !== '' ? (
                        <div className="flex h-[400px] items-center justify-center">
                            <Loader width="50px" />
                        </div>
                    ) : (
                        autocompleteItems.length > 0 && (
                            <div>
                                <div className="flex justify-between align-bottom">
                                    <div>
                                        {noResults && (
                                            <>
                                                <p className="mb-10 font-grotesk-bold uppercase text-grey">
                                                    No results for &quot;{searchQuery}&quot;
                                                </p>
                                                <p className="mb-10 font-grotesk-bold uppercase text-grey">
                                                    You may like
                                                </p>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="grid gap-40 md:grid-cols-2">
                                    {autocompleteItems?.map((item) => {
                                        const updatedItem = {
                                            ...item,
                                            qty: 0,
                                        };
                                        selectedItems.forEach((sel) => {
                                            updatedItem.qty = item.id === sel.id ? sel.qty : updatedItem.qty;
                                        });
                                        return (
                                            <AutocompleteItem
                                                key={updatedItem.id}
                                                item={updatedItem}
                                                closeAutocomplete={closeAutocomplete}
                                                searchRecommendation={noResults}
                                            />
                                        );
                                    })}
                                </div>
                                {!mobile && (
                                    <div className="mt-20">
                                        <Link
                                            to={`/shop/search?query=${searchQuery}`}
                                            className="text-body-sm float-right font-grotesk-bold"
                                            onClick={closeAutocomplete}
                                        >
                                            View All
                                        </Link>
                                    </div>
                                )}
                            </div>
                        )
                    )}
                </div>
                {mobile && (
                    <div className="fixed bottom-0 left-0 right-0 z-[2000] bg-white p-20">
                        <Button size="md" as="a" to={`/shop/search?query=${searchQuery}`}>
                            View more
                        </Button>
                    </div>
                )}
            </div>
        )
    );
}

SearchAutocomplete.propTypes = {
    isShopRoute: PropTypes.bool.isRequired,
};
