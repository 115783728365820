import PropTypes from 'prop-types';

function PaleoIcon({ className, width }) {
    return (
        <svg width={width} className={className} viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.527 6.55555H18.9071C19.31 6.53713 19.7044 6.43294 20.0639 6.24995C20.4234 6.06696 20.7397 5.80937 20.9917 5.4944C22.0065 4.20419 21.7658 2.20654 21.7658 2.20654C18.9245 2.20654 17.93 3.52864 17.6053 4.71447C17.4494 5.31574 17.4227 5.94322 17.527 6.55555Z"
                className="fill-[#B0C472] if:fill-[#C5DB66]"
                stroke="#2D2D2D"
                strokeWidth="0.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M23.195 13.8474C23.4386 13.5284 23.685 13.2327 23.9576 12.9399C23.9784 12.9089 23.9919 12.8736 23.9973 12.8367C24.0027 12.7998 23.9997 12.7621 23.9885 12.7265C23.9774 12.6909 23.9584 12.6582 23.933 12.6309C23.9076 12.6036 23.8763 12.5824 23.8416 12.5688C23.3951 12.4209 22.9834 12.1744 22.9834 11.5888C22.983 11.4911 22.9947 11.3937 23.0182 11.2988C22.8498 11.1556 22.714 10.9781 22.62 10.778C22.526 10.578 22.4759 10.3601 22.4731 10.1391C22.4755 9.91744 22.526 9.69893 22.6211 9.4987C22.7162 9.29846 22.8536 9.12126 23.024 8.97937C22.772 8.72572 22.6258 8.38577 22.6152 8.02839C22.6152 7.66017 22.9051 7.32385 23.1544 7.05421C23.1544 7.05421 22.2643 6.24819 20.9944 7.38183C19.7245 8.51547 20.374 13.0703 21.5946 13.5835C22.8152 14.0967 23.195 13.8474 23.195 13.8474Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.6411 12.044C21.6411 11.9454 21.7049 11.8468 21.6788 11.7541C21.5426 11.3221 21.0149 11.1742 21.0149 10.7248C21.0209 10.3719 21.1525 10.0327 21.386 9.768C21.4306 9.7156 21.4565 9.64984 21.4597 9.58108C21.4628 9.51232 21.443 9.44446 21.4034 9.38819C21.2418 9.16018 21.1547 8.88775 21.154 8.60827C21.154 7.99651 22.192 7.46593 22.9777 7.20209C23.0236 7.18687 23.0654 7.16131 23.0999 7.12739C23.1344 7.09347 23.1606 7.05209 23.1766 7.00644C23.1925 6.96078 23.1978 6.91207 23.192 6.86406C23.1862 6.81604 23.1694 6.77001 23.143 6.7295C22.9134 6.40356 22.619 6.12849 22.2783 5.92146C21.9376 5.71443 21.5578 5.5799 21.1627 5.52627C21.109 5.51727 21.0538 5.52274 21.0029 5.5421C20.9519 5.56146 20.907 5.59402 20.8728 5.63644C20.3674 6.18383 19.6684 6.51251 18.9244 6.55264H17.7966C17.72 6.55571 17.6448 6.53055 17.5855 6.48191C17.5262 6.43328 17.4868 6.36456 17.4748 6.2888C17.4338 5.871 17.4504 5.44956 17.5241 5.03628C17.532 4.9951 17.5315 4.95274 17.5227 4.91173C17.514 4.87073 17.497 4.8319 17.473 4.79756C17.4489 4.76321 17.4182 4.73405 17.3826 4.71181C17.3471 4.68957 17.3074 4.67469 17.266 4.66806C17.0264 4.62824 16.784 4.60788 16.5412 4.60718C14.1057 4.60718 12.6677 5.3784 10.8469 6.92665C10.0641 7.5906 9.91329 7.5935 9.4233 8.60827C9.19474 9.14714 9.05191 9.71845 9 10.3015C9 10.5914 9 11.8033 9 12.1513C9.08034 13.0519 9.30756 13.9334 9.67265 14.7607C10.1481 15.9204 10.2525 15.9929 11.0962 16.7902C12.6648 18.2892 14.2855 19.1706 16.5383 19.1706C17.8517 19.1706 19.7682 18.8806 20.7655 17.9354C21.9253 16.8453 22.2993 14.9636 23.288 13.7169C23.3372 13.6531 23.3923 13.5893 23.4445 13.5256C22.1137 13.5864 21.6411 12.6673 21.6411 12.044Z"
                className="fill-[#DC6B31] if:fill-[#E95CA2]"
                stroke="#2D2D2D"
                strokeWidth="0.6"
                strokeMiterlimit="10"
            />
            <path
                d="M7.61157 17.0839C7.85894 18.0475 8.90009 21.2965 8.86686 21.9684C8.83363 22.6404 7.32729 22.9247 7.85894 24.0138C8.39059 25.103 10.0372 24.9331 10.4138 24.2243C11.2113 24.3387 12.9355 24.4938 12.9982 22.9468C13.061 21.3999 11.3147 21.7395 11.0599 21.3703C10.8052 21.0011 9.71972 16.5707 9.71972 16.5707L7.61157 17.0839Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.553805"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.9867 7.34057C12.7658 10.368 11.8243 12.7457 11.5548 14.0711C11.1007 16.2864 10.665 17.5638 9.04419 17.981C7.63752 18.3502 6.20871 17.2906 4.74666 15.5701C3.86796 14.54 2.03671 12.8232 1.33153 10.0837C0.279305 6.01144 1.81519 2.0905 4.75774 1.33363C7.70029 0.576766 10.9345 3.27933 11.9867 7.34057Z"
                className="fill-[#DEA455] if:fill-[#F6A685]"
                stroke="#2D2D2D"
                strokeWidth="0.553805"
                strokeMiterlimit="10"
            />
            <path
                d="M9.4392 7.1966C9.83794 6.21082 9.86009 3.2572 6.15698 2.6554C7.37764 4.07348 8.47566 5.59267 9.4392 7.1966Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.553805"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default PaleoIcon;

PaleoIcon.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number.isRequired,
};

PaleoIcon.defaultProps = {
    className: null,
};
