import { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { LoginErrors } from 'constants/login';
import { Alert } from 'honeydew/Alert';
import Button from 'honeydew/Button';
import TextField from 'honeydew/TextField';
import { validConfirmationCode } from 'utils/loginUtils';

import ResetPasswordForm from '../ResetPasswordForm/ResetPasswordForm';

function ConfirmationCodeForm({
    code,
    codeSubmit,
    confirmPassword,
    email,
    error,
    loading,
    newPassword,
    resendCode,
    resent,
    setCode,
    setConfirmPassword,
    setError,
    setNewPassword,
    setSent,
    setShowConfirmPassword,
    setShowNewPassword,
    showConfirmPassword,
    showNewPassword,
}) {
    const [stillWaiting, setStillWaiting] = useState(false);

    return (
        <form onSubmit={codeSubmit}>
            {resent && (
                <div className="mb-20">
                    <Alert variant="success">
                        <span className="font-grotesk-bold">Email sent again.</span> Please give it 30 minutes to arrive
                        in your inbox.
                    </Alert>
                </div>
            )}

            <p className="text-body-sm mb-30">
                If your email <span className="font-grotesk-bold">{email}</span> matches an account, you&apos;ll receive
                a 6-digit confirmation code to reset your password.
            </p>

            <div className="mb-20">
                <TextField
                    id="code"
                    name="code"
                    label="Confirmation code"
                    placeholder="Confirmation code"
                    pattern="[0-9]*"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    required
                />
            </div>

            <ResetPasswordForm
                codeRequired
                confirmPassword={confirmPassword}
                loading={loading}
                newPassword={newPassword}
                setConfirmPassword={setConfirmPassword}
                setError={setError}
                setNewPassword={setNewPassword}
                setShowConfirmPassword={setShowConfirmPassword}
                setShowNewPassword={setShowNewPassword}
                showConfirmPassword={showConfirmPassword}
                showNewPassword={showNewPassword}
            />

            <div className="mb-25 mt-10">
                <Button size="md" type="submit" disabled={loading || !validConfirmationCode(code)}>
                    Continue
                </Button>
            </div>

            <Button
                className={clsx(
                    {
                        'link mb-40 cursor-pointer': !stillWaiting,
                        'cursor-text': stillWaiting,
                    },
                    'text-body-sm font-grotesk-bold'
                )}
                onClick={() => setStillWaiting(true)}
            >
                Still waiting on the email?
            </Button>

            {stillWaiting && (
                <ul className="text-body-sm mb-20 list-disc pl-25">
                    <li>Hang tight-it can take up to 30 minutes to arrive.</li>
                    <li>
                        Double check that the email above is correct. If not,{' '}
                        <Button className="link" onClick={() => setSent(false)}>
                            try a different one
                        </Button>
                        .
                    </li>
                    {error !== LoginErrors.NoAccount && (
                        <li>
                            Still haven&apos;t received an email? You can{' '}
                            <Button className="link" onClick={resendCode}>
                                resend it
                            </Button>
                            !
                        </li>
                    )}
                </ul>
            )}
        </form>
    );
}

ConfirmationCodeForm.propTypes = {
    code: PropTypes.string.isRequired,
    codeSubmit: PropTypes.func.isRequired,
    confirmPassword: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    newPassword: PropTypes.string.isRequired,
    resendCode: PropTypes.func.isRequired,
    resent: PropTypes.bool.isRequired,
    setCode: PropTypes.func.isRequired,
    setConfirmPassword: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    setNewPassword: PropTypes.func.isRequired,
    setSent: PropTypes.func.isRequired,
    setShowConfirmPassword: PropTypes.func.isRequired,
    setShowNewPassword: PropTypes.func.isRequired,
    showConfirmPassword: PropTypes.bool.isRequired,
    showNewPassword: PropTypes.bool.isRequired,
};

export default ConfirmationCodeForm;
