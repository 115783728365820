import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { PLACEHOLDER_IMAGE } from 'constants/shop';
import QuantityButton from 'honeydew/QuantityButton';
import Tag from 'honeydew/Tag/Tag';
import { useAccountPlusMembershipHook } from 'hooks/useAccountPlusMembershipHook';
import useUpdateProductQty from 'hooks/useUpdateProductQty';
import ProductTag from 'shared/ProductTag';
import { getProductOriginalPrice, getShowOriginalPrice } from 'utils/productUtils';

export function AutocompleteItem({ closeAutocomplete, item, searchRecommendation }) {
    const navigate = useNavigate();

    const { inPlusMembershipTreatment, plusMembershipEligibleOrder, plusMembershipShortBranding } =
        useAccountPlusMembershipHook();

    const {
        decrementProductQty,
        incrementProductQty,
        isLoading: updateProductQtyLoading,
    } = useUpdateProductQty({
        productID: item.id,
        productName: item.name,
        searchAutocomplete: true,
        searchRecommendation,
        plusMemberPriceEligible: item.plusMemberPriceEligible,
    });

    const itemLocation = searchRecommendation ? 'search_recommendations' : 'search_autocomplete';

    const handleAddClick = (e) => {
        e.stopPropagation();
        incrementProductQty(itemLocation);
    };

    const handleRemoveClick = (e) => {
        e.stopPropagation();
        decrementProductQty(itemLocation);
    };

    const navigateToPDP = () => {
        navigate(`/shop/product/${item.id}${item.isPerk ? '?perk=true' : ''}`);
        closeAutocomplete();
    };

    const showMembershipPrice =
        inPlusMembershipTreatment && item.plusMemberPriceEligible && plusMembershipEligibleOrder;

    return (
        <div key={item.id} className="grid grid-cols-[80px_1fr] items-center gap-20">
            <button type="button" className="relative" onClick={navigateToPDP}>
                {item.soldOut && (
                    <div className="!text-body-xs absolute z-[1]">
                        <ProductTag soldOut={item.soldOut} />
                    </div>
                )}
                <img
                    className={clsx('h-80 w-80', {
                        'opacity-50': item.soldOut,
                    })}
                    src={item.photoSmall || PLACEHOLDER_IMAGE}
                    alt={item.name}
                />
            </button>
            <div className="flex items-start justify-between gap-20">
                <div>
                    <button type="button" className="mb-10 text-left" onClick={navigateToPDP}>
                        <p className="text-body-desktop-sm text-grey">{item.brand}</p>
                        <p className="line-clamp-2 text-body-desktop-sm">{item.name}</p>
                    </button>

                    <QuantityButton
                        disabled={updateProductQtyLoading || item.soldOut}
                        maxQuantity={item.soldOut ? item.qty : item.maxQuantity}
                        onAdd={handleAddClick}
                        onRemove={handleRemoveClick}
                        quantity={item.qty}
                        variant="primary"
                    />
                </div>
                <button type="button" className="shrink-0 text-right" onClick={navigateToPDP}>
                    <div className="flex items-baseline gap-x-5">
                        {showMembershipPrice && (
                            <Tag bgColor="cranberry" variant="dark">
                                {plusMembershipShortBranding}
                            </Tag>
                        )}
                        <p
                            id="itemPrice"
                            className={clsx('text-body-xs font-grotesk-bold', {
                                'text-cranberry': showMembershipPrice,
                            })}
                        >
                            ${showMembershipPrice ? item.plusMemberPrice?.toFixed(2) : item.price?.toFixed(2)}
                        </p>
                    </div>

                    {getShowOriginalPrice(item, 0, showMembershipPrice) && (
                        <p className="text-body-xs ml-5 inline-block text-grey line-through">
                            ${getProductOriginalPrice(item)?.toFixed(2)}
                        </p>
                    )}
                </button>
            </div>
        </div>
    );
}

AutocompleteItem.propTypes = {
    closeAutocomplete: PropTypes.func.isRequired,
    item: PropTypes.shape({
        brand: PropTypes.string,
        giftItem: PropTypes.bool,
        id: PropTypes.number.isRequired,
        isPerk: PropTypes.bool,
        maxQuantity: PropTypes.number.isRequired,
        msrp: PropTypes.number,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        qty: PropTypes.number.isRequired,
        soldOut: PropTypes.bool.isRequired,
    }).isRequired,
    searchRecommendation: PropTypes.bool,
};

AutocompleteItem.defaultProps = {
    searchRecommendation: false,
};
