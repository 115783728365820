import {
    captureException,
    captureMessage,
    init as initSentry,
    setUser,
    thirdPartyErrorFilterIntegration,
    withScope,
} from '@sentry/react';

import determineEnvironment from './environmentUtils';

const ignoreErrors = [
    // Auth errors...
    // BOYSENBERRY-J
    /Refresh Token has been revoked/,
    // BOYSENBERRY-BK & BOYSENBERRY-4R Missing username on forgot password form
    'Username cannot be empty',
    // BOYSENBERRY-2
    /No current user/,
    // BOYSENBERRY-48 Fetch error
    /Temporary password has expired and must be reset by an administrator/,
    // BOYSENBERRY-S Cognito Trying to Sign Up with existing email. We're tracking this in Amplitude.
    'UsernameExistsException: An account with the given email already exists.',
    // BOYSENBERRY-14 Forgot password flow
    /Invalid verification code provided, please try again./,
    // BOYSENBERRY-1B Forgot password flow
    'Invalid code provided, please request a code again.',
    // BOYSENBERRY-J
    /Access Token has expired/,
    // BOYSENBERRY-70
    'Access Token has been revoked',
    // BOYSENBERRY-13G Expired session?
    /illegal access/,
    // BOYSENBERRY-A6 No need to collect these
    'Password did not conform with policy: Password not long enough',
    // BOYSENBERRY-M Determined to be non-actionable network error
    'Network error',

    // Network errors...
    // BOYSENBERRY-3R Dynamic import. Suspect network blip, not missing asset from old builds
    'Unable to preload CSS for',
    // BOYSENBERY-B Dynamic import. Suspect network blip, not missing asset
    'Failed to fetch dynamically imported module',
    // BOYSENBERRY-7C Dynamic import (just the Firefox version). Suspect network blip, not missing asset from old builds
    'error loading dynamically imported module',
    // BOYSENBERRY-15 Suspect network blip, not missing asset from old builds
    'Importing a module script failed.',
    // BOYSENBERRY-G unhandled promise rejection in global scope. Prefer ignore over fix.
    'Event `CustomEvent` (type=unhandledrejection) captured as promise rejection',
    // BOYSENBERRY-48 Problem fetching api/subscription/v4 in Join Payment step
    'undefined',
    // BOYSENBERRY-3E
    'Non-Error promise rejection captured with value: undefined',
    'Non-Error promise rejection captured with value: Object Not Found Matching Id:',
    // BOYSENBERRY-D
    /Load failed/,
    // BOYSENBERRY-N Not actionable, resource unknown. Use APM Traces to monitor instead.
    'NetworkError when attempting to fetch resource',

    // Amplitude errors...
    // BOYSENBERRY-1R2 Suspect Amplitude Replay, not customer-facing
    'Database deleted by request of the user',
    // We are tracking amplitude experiment timeouts as a warning
    'Request timeout after',
    // BOYSENBERRY-1R8 Suspect Session Replay
    'UnknownError: An internal error was encountered in the Indexed Database server',
    // BOYSENBERRY-1RG Suspect Session Replay
    'The operation failed for an unknown transient reason (e.g. out of memory).',
    // BOYSENBERRY-1R7 Suspect Session Replay
    "undefined is not an object (evaluating 't.objectStoreNames')",
    // BOYSENBERRY-1R8 Suspect Session Replay
    'Encountered disk full while committing transaction.',
    // BOYSENBERRY-1Z4 Session Replay
    /The database is not running a version change transaction/,
    // BOYSENBERRY-1RW Session Replay
    "undefined is not an object (evaluating 'n.result.version')",
    // BOYSENBERRY-1TE Session Replay
    "Can't select any node with this selector: .conversationsHeader__content___V8xis",

    // Smarty Streets errors...
    // BOYSENBERRY-SD Almost exclusively seen on Join Delivery step.
    'Unauthorized: The credentials were provided incorrectly or did not match any existing active credentials.',

    // General errors...
    // Suspect form auto-fill, instagram browser and the like, not fixable.
    '_AutofillCallbackHandler',
    // Suspect form auto-fill, instagram browser and the like, not fixable.
    '__AutoFillPopupClose__',
    // BOYSENBERRY-1Z6 BOYSENBERRY-1Z5 local storage access. Us, Amplitude, etc.
    'SecurityError: The operation is insecure.',
    // BOYSENBERRY-1Q8 Sentry client, no actionable or known to be an issue for us or customers
    'Invalid call to runtime.sendMessage(). Tab not found.',
    // BOYSENBERRY-1Z0 Some reports of this being Evernote Web Clipper extension
    'Error: There is no clipping info for given tab',
    // BOYSENBERRY-SQ
    'Failed to insert value 2 for key devicePixelRatio',
    // BOYSENBERRY-1SF
    'Extension API is being called from a webpage that does not have extension content.',
    // BOYSENBERRY-1T4 Suspect local storage.
    'Error: QuotaExceededError',
];

/**
 * Customizes ui.click breadcrumbs to include the target element text and page path
 * See https://docs.sentry.io/platforms/javascript/enriching-events/breadcrumbs/#customize-breadcrumbs
 * @param {object} breadcrumb
 * @param {object} hint
 * @returns  {object} breadcrumb
 */
export const beforeBreadcrumb = (breadcrumb, hint) => {
    try {
        if (breadcrumb.category === 'ui.click') {
            const target = hint?.event?.target;
            const elementContent = target?.innerText
                ? `with innerText ${target.innerText.slice(0, 50)}`
                : 'with no innerText';
            // eslint-disable-next-line no-param-reassign
            breadcrumb.message = `click on ${target?.tagName?.toLowerCase()} element ${elementContent} on page ${
                window?.location?.pathname
            }`;
        }
        // eslint-disable-next-line no-empty
    } catch (e) {}

    return breadcrumb;
};

/**
 * Initializes Sentry with dynamic environment setting
 */
function initialize() {
    const { VITE_APP_VERSION: viteAppVersion } = import.meta.env;
    const release = viteAppVersion ?? 'development';
    const environment = determineEnvironment();

    initSentry({
        dsn: 'https://4dabafa8ef08f240cef3b35c51f67b8e@o4506614985261056.ingest.sentry.io/4506615092215808',
        tracesSampleRate: 0,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 0,
        environment,
        release,
        ignoreErrors,
        integrations: [
            thirdPartyErrorFilterIntegration({
                filterKeys: ['mm-sentry-boysenberry'],
                behaviour: 'drop-error-if-contains-third-party-frames',
            }),
        ],
        beforeBreadcrumb,
    });
}

/**
 * Logs an error to Sentry
 * @param {Error} error - The error object to log
 * @param {Object} [context=null] - Additional context information for the error
 */
function logError(error, context = null) {
    withScope((scope) => {
        if (context) {
            Object.keys(context).forEach((key) => {
                scope.setExtra(key, context[key]);
            });
        }
        captureException(error);
    });
}

/**
 * Logs a message to Sentry
 * Additional data can be included using the extra property.
 * @param {String} message - The message to log
 * @param {Object} context Additional context information for the message
 * @param {string} context.level The level of the message, "fatal", "error", "warning", "log", "info", and "debug"
 * @param {Object} context.extra Additional data to include in the message
 * @example logMessage('This is a message', { level: 'warning', extra: { foo: 'bar' } })
 */
function logMessage(message, context = { level: 'info' }) {
    captureMessage(message, context);
}

/**
 * Sets the user ID for Sentry
 * @param {string} id - The ID of the user
 */
function setUserId(id) {
    setUser({ id });
}

/**
 * Clears the user information in Sentry
 */
function clearUser() {
    setUser(null);
}

export { initialize, logError, logMessage, setUserId, clearUser, ignoreErrors };
