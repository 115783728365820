import PropTypes from 'prop-types';

const sizeClasses = {
    50: 'min-w-50',
};

function PlusBenefitsShippingIcon({ width }) {
    return (
        <svg
            width={width}
            height={width}
            className={sizeClasses[width]}
            viewBox="0 0 51 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse
                cx="25.5"
                cy="25"
                rx="25"
                ry="25"
                transform="rotate(-90 25.5 25)"
                fill="url(#paint0_linear_2218_88254)"
            />
            <path
                d="M33.8628 36.2858L35.1306 36.1912C35.5091 36.1691 35.8797 36.0726 36.2209 35.907C36.562 35.7415 36.8672 35.5102 37.1188 35.2265C37.3704 34.9427 37.5636 34.6121 37.6872 34.2536C37.8107 33.895 37.8623 33.5156 37.8389 33.1371L36.5712 33.2318C36.5945 33.6103 36.543 33.9897 36.4194 34.3482C36.2959 34.7068 36.1028 35.0374 35.8512 35.3211C35.5995 35.6049 35.2943 35.8362 34.9531 36.0017C34.6119 36.1672 34.2414 36.2638 33.8628 36.2858Z"
                fill="#2D2D2D"
            />
            <path
                d="M28.2528 29.8853L22.6016 30.2969L13.073 31.0172C13.0647 29.7124 12.963 28.4097 12.7684 27.1194C12.6984 26.679 12.4309 22.8223 12.4227 22.3737C12.3527 19.4554 11.9287 15.8374 11.9287 15.8374C11.9287 15.8374 16.0036 15.5946 17.3537 15.4258C20.8975 14.9854 27.1086 14.6891 27.1086 14.6891"
                fill="white"
            />
            <path
                d="M28.2528 29.8853L22.6016 30.2969L13.073 31.0172C13.0647 29.7124 12.963 28.4097 12.7684 27.1194C12.6984 26.679 12.4309 22.8223 12.4227 22.3737C12.3527 19.4554 11.9287 15.8374 11.9287 15.8374C11.9287 15.8374 16.0036 15.5946 17.3537 15.4258C20.8975 14.9854 27.1086 14.6891 27.1086 14.6891"
                stroke="#2D2D2D"
                strokeWidth="1.07143"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M28.2529 29.8854C28.2529 29.8854 28.0512 25.827 27.8413 24.4893C27.3618 21.2499 27.1155 17.9803 27.1045 14.7056L30.282 14.4669L32.6776 14.2858C32.6776 14.2858 32.871 16.1256 32.8875 17.0764C32.8875 17.9367 33.1468 20.5009 33.1468 20.5009L31.842 20.5956C31.842 20.5956 32.0807 22.9623 32.159 24.798C32.2577 27.2265 32.517 29.5685 32.517 29.5685L28.2529 29.8854Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="1.07143"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M31.6118 33.6144C31.6283 33.9583 31.7136 34.2954 31.8625 34.6058C32.0115 34.9162 32.2211 35.1936 32.479 35.4216C32.737 35.6496 33.038 35.8236 33.3643 35.9333C33.6907 36.043 34.0358 36.0862 34.3791 36.0604C34.7224 36.0345 35.0569 35.9401 35.3631 35.7827C35.6694 35.6254 35.9409 35.4082 36.1618 35.1442C36.3827 34.8801 36.5484 34.5745 36.6492 34.2453C36.75 33.9161 36.7838 33.57 36.7486 33.2275"
                stroke="#2D2D2D"
                strokeWidth="1.07143"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M31.55 33.5445C31.6016 34.2256 31.9214 34.8583 32.4393 35.3036C32.9572 35.749 33.6307 35.9704 34.3118 35.9194C35.7318 35.8124 36.588 34.664 36.6868 33.1576C36.7814 31.7376 35.345 30.688 33.9249 30.7785C33.5873 30.804 33.258 30.8958 32.9559 31.0487C32.6538 31.2015 32.3847 31.4124 32.1642 31.6693C31.9436 31.9262 31.7759 32.224 31.6705 32.5458C31.5651 32.8675 31.5242 33.2069 31.55 33.5445Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="1.07143"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26.3516 33.9355C26.4586 35.3555 27.7099 36.5451 29.1134 36.3105C30.7598 36.0388 31.583 34.9686 31.4884 33.5486C31.4197 32.8809 31.0933 32.2664 30.5784 31.8357C30.0636 31.4051 29.4011 31.1923 28.7318 31.2428C28.0625 31.2932 27.4392 31.6028 26.9947 32.1057C26.5502 32.6086 26.3194 33.2651 26.3516 33.9355Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="1.07143"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M28.9198 34.516C29.3471 34.516 29.6936 34.1696 29.6936 33.7422C29.6936 33.3148 29.3471 32.9684 28.9198 32.9684C28.4924 32.9684 28.146 33.3148 28.146 33.7422C28.146 34.1696 28.4924 34.516 28.9198 34.516Z"
                fill="#2D2D2D"
            />
            <path
                d="M39.4733 29.0417L38.8271 20.4681C38.821 20.4135 38.804 20.3607 38.7772 20.3127C38.7504 20.2648 38.7142 20.2227 38.6709 20.1889C38.6275 20.1552 38.5778 20.1304 38.5248 20.1162C38.4717 20.1019 38.4164 20.0984 38.362 20.1059C37.3393 20.0963 36.3175 20.1734 35.3079 20.3364L31.5129 20.6245L29.9447 20.7397C29.8545 20.7596 29.7756 20.8135 29.7242 20.8901C29.6728 20.9668 29.653 21.0604 29.669 21.1513C29.6641 22.4812 29.7646 23.8093 29.9694 25.1233C30.1299 26.2675 30.3193 29.7291 30.3193 29.7291C30.3193 29.7291 33.7479 29.5768 34.2871 29.5192C36.6703 29.2599 39.4733 29.0417 39.4733 29.0417Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="1.07143"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M37.3164 24.1189L33.7889 24.3494C33.7077 24.3551 33.6275 24.3284 33.5658 24.2752C33.5042 24.222 33.4659 24.1466 33.4596 24.0654L33.3074 22.0403C33.3046 21.9998 33.3098 21.959 33.3228 21.9205C33.3357 21.882 33.3562 21.8464 33.3829 21.8158C33.4097 21.7852 33.4423 21.7602 33.4788 21.7422C33.5153 21.7242 33.5549 21.7137 33.5955 21.7111L37.1023 21.4641C37.1429 21.4607 37.1836 21.4654 37.2224 21.4778C37.2611 21.4903 37.2971 21.5103 37.3281 21.5367C37.3591 21.5631 37.3845 21.5954 37.403 21.6317C37.4214 21.6679 37.4326 21.7075 37.4357 21.7481L37.588 23.7773C37.5972 23.8586 37.5738 23.9403 37.5229 24.0043C37.4719 24.0684 37.3977 24.1096 37.3164 24.1189Z"
                fill="#2D2D2D"
            />
            <path
                d="M19.4736 34.5284C19.5252 35.2095 19.8451 35.8422 20.363 36.2876C20.8808 36.7329 21.5543 36.9544 22.2355 36.9033C23.9683 36.7757 24.2935 35.7673 24.6145 34.1415"
                fill="white"
            />
            <path
                d="M19.4736 34.5284C19.5252 35.2095 19.8451 35.8422 20.363 36.2876C20.8808 36.7329 21.5543 36.9544 22.2355 36.9033C23.9683 36.7757 24.2935 35.7673 24.6145 34.1415"
                stroke="#2D2D2D"
                strokeWidth="1.07143"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.0376 36.8373C23.4606 36.8373 24.6142 35.6837 24.6142 34.2607C24.6142 32.8377 23.4606 31.6841 22.0376 31.6841C20.6145 31.6841 19.4609 32.8377 19.4609 34.2607C19.4609 35.6837 20.6145 36.8373 22.0376 36.8373Z"
                stroke="#2D2D2D"
                strokeWidth="1.07143"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.6172 37.5946L17.8849 37.5C18.2643 37.4807 18.636 37.3859 18.9783 37.2212C19.3205 37.0565 19.6265 36.8252 19.8783 36.5408C20.1301 36.2564 20.3227 35.9247 20.4448 35.565C20.5668 35.2053 20.6158 34.8248 20.5891 34.4459L19.3214 34.5406C19.3448 34.9188 19.2934 35.2979 19.1701 35.6562C19.0468 36.0144 18.854 36.3449 18.6028 36.6286C18.3516 36.9123 18.0469 37.1436 17.7062 37.3094C17.3655 37.4752 16.9954 37.5721 16.6172 37.5946Z"
                fill="#2D2D2D"
            />
            <path
                d="M22.0869 37.183L23.3546 37.0884C23.7339 37.0691 24.1057 36.9743 24.448 36.8096C24.7903 36.6449 25.0962 36.4136 25.3481 36.1292C25.5999 35.8448 25.7925 35.513 25.9145 35.1533C26.0366 34.7936 26.0856 34.4132 26.0588 34.0343L24.7911 34.129C24.8145 34.5072 24.7631 34.8862 24.6398 35.2445C24.5165 35.6028 24.3238 35.9333 24.0726 36.217C23.8214 36.5006 23.5166 36.732 23.1759 36.8978C22.8352 37.0635 22.4652 37.1605 22.0869 37.183Z"
                fill="#2D2D2D"
            />
            <path
                d="M28.7632 36.6974L30.0309 36.5986C30.4107 36.5798 30.7828 36.4855 31.1256 36.321C31.4683 36.1565 31.7748 35.9253 32.027 35.6408C32.2793 35.3564 32.4722 35.0245 32.5945 34.6645C32.7168 34.3045 32.766 33.9238 32.7392 33.5446L31.4715 33.6392C31.4869 34.1193 31.3969 34.597 31.2081 35.0386C30.8952 35.7589 30.2202 36.5204 28.7632 36.685"
                fill="#2D2D2D"
            />
            <path
                d="M14.2133 34.8535C14.2089 35.1964 14.2875 35.5353 14.4423 35.8413C14.5971 36.1474 14.8236 36.4114 15.1024 36.6111C15.6455 37.01 16.3012 37.2262 16.9751 37.2285C18.6874 37.1749 19.4447 35.8825 19.3501 34.4625C19.3325 34.1192 19.2465 33.7828 19.0969 33.4733C18.9474 33.1637 18.7375 32.8872 18.4794 32.6601C18.2214 32.4329 17.9206 32.2596 17.5946 32.1505C17.2686 32.0414 16.9241 31.9987 16.5813 32.0248C16.2385 32.0509 15.9044 32.1453 15.5986 32.3025C15.2929 32.4597 15.0217 32.6765 14.8011 32.9401C14.5804 33.2037 14.4147 33.5088 14.3138 33.8374C14.2128 34.166 14.1787 34.5115 14.2133 34.8535Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="1.07143"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.7816 35.4296C17.2089 35.4296 17.5554 35.0831 17.5554 34.6558C17.5554 34.2284 17.2089 33.882 16.7816 33.882C16.3542 33.882 16.0078 34.2284 16.0078 34.6558C16.0078 35.0831 16.3542 35.4296 16.7816 35.4296Z"
                fill="#2D2D2D"
            />
            <path
                d="M31.9863 29.6095L35.7442 29.3256L39.2387 29.0621"
                stroke="#2D2D2D"
                strokeWidth="1.07143"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M32.4925 29.5686C32.4925 29.5686 25.059 30.2642 22.5729 30.3177C19.132 30.3918 13.0444 31.038 13.0444 31.038L13.3408 34.9564L14.164 34.8988C14.1347 34.5581 14.173 34.215 14.2768 33.8892C14.3806 33.5635 14.5478 33.2615 14.7688 33.0006C14.9898 32.7397 15.2602 32.525 15.5645 32.3691C15.8687 32.2131 16.2009 32.1188 16.5417 32.0917C16.8826 32.0645 17.2253 32.1051 17.5504 32.2109C17.8755 32.3168 18.1765 32.4859 18.436 32.7086C18.6955 32.9312 18.9084 33.203 19.0625 33.5082C19.2165 33.8135 19.3086 34.1462 19.3336 34.4872L26.3103 33.9603C26.2766 33.2849 26.508 32.6231 26.9552 32.1159C27.4025 31.6087 28.0302 31.2963 28.7045 31.2453C29.3788 31.1943 30.0464 31.4087 30.5649 31.8429C31.0833 32.277 31.4117 32.8965 31.4799 33.5693C31.4799 33.5693 34.0442 33.4211 34.8921 33.3141C37.1765 33.0219 39.7861 32.9437 39.7861 32.9437L39.6378 30.9845L39.4897 29.0211L32.4925 29.5686Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="1.07143"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M31.731 20.6039C31.731 20.6039 32.3978 29.3298 32.4554 29.4903"
                stroke="#2D2D2D"
                strokeWidth="1.07143"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M34.6205 32.1533C35.0479 32.1533 35.3943 31.8069 35.3943 31.3795C35.3943 30.9522 35.0479 30.6057 34.6205 30.6057C34.1932 30.6057 33.8467 30.9522 33.8467 31.3795C33.8467 31.8069 34.1932 32.1533 34.6205 32.1533Z"
                fill="#2D2D2D"
            />
            <path
                d="M37.6991 31.9228C38.1264 31.9228 38.4729 31.5764 38.4729 31.1491C38.4729 30.7217 38.1264 30.3752 37.6991 30.3752C37.2717 30.3752 36.9253 30.7217 36.9253 31.1491C36.9253 31.5764 37.2717 31.9228 37.6991 31.9228Z"
                fill="#2D2D2D"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_2218_88254"
                    x1="48.7143"
                    y1="-0.714284"
                    x2="6.92858"
                    y2="51.0714"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F594A4" />
                    <stop offset="0.464323" stopColor="#A13051" />
                    <stop offset="1" stopColor="#6784AC" />
                </linearGradient>
            </defs>
        </svg>
    );
}

PlusBenefitsShippingIcon.propTypes = {
    width: PropTypes.number,
};

PlusBenefitsShippingIcon.defaultProps = {
    width: 100,
};

export default PlusBenefitsShippingIcon;
