import { useQuery } from '@tanstack/react-query';

import { getAccount } from 'api/jalapeno';
import useAuth from 'hooks/useAuth';
import { getOrderSessionStorage, resetOrderSessionStorage } from 'utils/orderSessionStorageUtils';

export const ACCOUNT_QUERY_KEY = 'account';

export const accountQueryFn = async () => {
    const response = await getAccount();

    const { chargeID } = response?.data?.resp?.nextOrder ?? {};
    const { sessionKey } = getOrderSessionStorage();

    if (sessionKey !== chargeID) {
        resetOrderSessionStorage({ sessionKey: chargeID });
    }

    return response?.data?.resp;
};

const useAccountQuery = ({ queryProps } = {}) => {
    const { authUser } = useAuth();

    const query = useQuery({
        queryKey: [ACCOUNT_QUERY_KEY, authUser?.username],
        queryFn: accountQueryFn,
        enabled: !!authUser?.username,
        ...queryProps,
    });

    return query;
};

export default useAccountQuery;
