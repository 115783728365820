import PropTypes from 'prop-types';

function TwitterIcon({ dark }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill={dark ? '#2D2D2D' : '#63ACDE'} />
            <path
                d="M10.3443 16.7732C14.6904 16.7732 17.0682 13.305 17.0682 10.2983C17.0682 10.2008 17.0659 10.1012 17.0614 10.0037C17.524 9.68153 17.9232 9.28254 18.2402 8.82542C17.8094 9.00999 17.352 9.13053 16.8837 9.18292C17.3768 8.89827 17.7461 8.4511 17.923 7.9243C17.459 8.18906 16.9517 8.37582 16.4227 8.47659C16.0662 8.11187 15.5949 7.87038 15.0817 7.78947C14.5684 7.70855 14.0417 7.7927 13.5831 8.02892C13.1245 8.26513 12.7595 8.64026 12.5445 9.09629C12.3294 9.55232 12.2764 10.0639 12.3936 10.5518C11.4542 10.5064 10.5352 10.2714 9.69621 9.86209C8.85719 9.45275 8.11687 8.87818 7.52324 8.17564C7.22153 8.67656 7.1292 9.26931 7.26503 9.83343C7.40086 10.3975 7.75464 10.8907 8.25449 11.2127C7.87924 11.2012 7.5122 11.1039 7.18371 10.9288V10.957C7.18338 11.4827 7.3721 11.9922 7.7178 12.3991C8.06351 12.8059 8.54485 13.0849 9.08001 13.1887C8.7324 13.2802 8.36757 13.2936 8.01374 13.2277C8.16475 13.6797 8.45857 14.0752 8.85418 14.3587C9.2498 14.6422 9.72747 14.7998 10.2205 14.8093C9.38346 15.4425 8.34942 15.7859 7.28496 15.7843C7.09619 15.784 6.90761 15.7729 6.72021 15.751C7.80159 16.419 9.05951 16.7738 10.3443 16.7732Z"
                fill="white"
            />
        </svg>
    );
}

export default TwitterIcon;

TwitterIcon.propTypes = {
    dark: PropTypes.bool,
};

TwitterIcon.defaultProps = {
    dark: false,
};
