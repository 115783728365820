const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat'];
const FULL_DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const FULL_MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

/**
 * Returns day of the month with correct suffix (st, nd, rd, th, ex 3rd of the month)
 * @param {num} day - The day of the month you wish the append the suffix to
 * @example
 * const getOrdinal = getOrdinal('3'); --> 3rd
 */
const getOrdinal = (num) => {
    if (num > 3 && num < 21) return 'th';
    switch (num % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
};

/**
 * Returns new javascript Date from inputted date string
 * @param {dateString} - Date string ex: '2023-04-12 16:00:00.000000'
 * @example
 * const parseDate = parseDate('2023-04-12 16:00:00.000000'); --> Wed Apr 12 2023 00:00:00 GMT-0600 (Mountain Daylight Time)
 */
const parseDate = (dateString) => {
    if (!dateString) return undefined;

    const a = dateString?.split(/[^0-9]/);
    const d = new Date(a[0], a[1] - 1, a[2]);
    return d;
};

/**
 * Returns new javascript Date and time from inputted date string
 * @param {dateString} - Date string ex: '2023-04-12 16:00:00.000000'
 * @example
 * const parseTime = parseTime('2023-04-12 16:00:00.000000'); --> Wed Apr 12 2023 16:00:00 GMT-0600 (Mountain Daylight Time)
 */
const parseTime = (dateString) => {
    if (!dateString) return undefined;

    const a = dateString?.split(/[^0-9]/);
    const d = new Date(a[0], a[1] - 1, a[2], a[3], a[4], a[5]);
    return d;
};

/**
 * Returns milliseconds for inputted day value
 * @param {days} - Number of days
 * @example
 * const milliseconds = daysToMilliseconds(7); --> 604800000
 */
const daysToMilliseconds = (days) => days * 24 * 60 * 60 * 1000;

/**
 * Returns input date string in a MM/DD/YYYY format
 * @param {dateString} date - Date string ex: '2023-04-12 16:00:00.000000'
 * @param {includeYear} bool - true/false
 * @example
 * const formatDate = formatDate('2023-04-12 16:00:00.000000'); --> 4/12
 * const formatDate = formatDate('2023-04-12 16:00:00.000000', true); --> 4/12/2023
 */
const formatDate = (dateString, includeYear) => {
    if (!dateString) return undefined;

    const date = parseDate(dateString);
    let returnDate = `${date.getMonth() + 1}/${date.getDate()}`;

    if (includeYear) {
        returnDate = `${returnDate}/${date.getFullYear()}`;
    }

    return returnDate;
};

/**
 * Returns input date string in a Jan 1, 2023 format
 * @param {dateString} date - Date string ex: '2023-04-12 16:00:00.000000'
 * @param {includeDay} bool - true/false - append day of the week
 * @param {includeYear} bool - true/false - append year
 * @param {fullDay} bool - true/false - include full weekday string
 * @param {fullMonth} bool - true/false - include full month value
 * @example
 * const formatDateString = formatDateString('2023-04-12 16:00:00.000000'); --> Apr 12th
 * const formatDateString = formatDateString('2023-04-12 16:00:00.000000', true); --> Wed, Apr 12th
 * const formatDateString = formatDateString('2023-04-12 16:00:00.000000', true, true); --> Wed, Apr 12th, 2023
 * const formatDateString = formatDateString('2023-04-12 16:00:00.000000', true, true, true); --> Wednesday, Apr 12th, 2023
 * const formatDateString = formatDateString('2023-04-12 16:00:00.000000', true, true, true, true); --> Wed, April 12th, 2023
 */
const formatDateString = (dateString, includeDay, includeYear, fullDay, fullMonth) => {
    if (!dateString) return undefined;

    const date = parseDate(dateString);
    const day = date.getDate();
    const month = fullMonth ? FULL_MONTHS[date.getMonth()] : MONTHS[date.getMonth()];

    let returnDate = `${month} ${day}`;

    if (includeDay) {
        const weekDay = fullDay ? FULL_DAYS[date.getDay()] : DAYS[date.getDay()];
        returnDate = `${weekDay}, ${returnDate}`;
    }

    if (includeYear) {
        returnDate = `${returnDate}, ${date.getFullYear()}`;
    }

    return returnDate;
};

/**
 * return day of the week (Tues, or Tuesday for fullDay flag is passed)
 * @param {dateString} - Date string ex: '2023-04-12 16:00:00.000000'
 * @param {fullMonth} - bool that determines whether full or abbreviated month is returned
 * @example
 * const getDay = getDay('2023-04-12 16:00:00.000000'); --> Apr
 * const getDay = getDay('2023-04-12 16:00:00.000000', true); --> April
 */
const getMonth = (dateString, fullMonth) => {
    if (!dateString) return undefined;

    const date = parseDate(dateString);

    return fullMonth ? FULL_MONTHS[date.getMonth()] : MONTHS[date.getMonth()];
};

/**
 * return day of the week (Tues, or Tuesday for fullDay flag is passed)
 * @param {dateString} - Date string ex: '2023-04-12 16:00:00.000000'
 * @param {fullDay} - bool that determines whether full or abbreviated day is returned
 * @example
 * const getDay = getDay('2023-04-12 16:00:00.000000'); --> Wed
 * const getDay = getDay('2023-04-12 16:00:00.000000', true); --> Wednesday
 */
const getDay = (dateString, fullDay) => {
    if (!dateString) return undefined;

    const date = parseDate(dateString);

    return fullDay ? FULL_DAYS[date.getDay()] : DAYS[date.getDay()];
};

/**
 * check if day (plus optional offset value) is after the second day
 * @param {d1} - Date string ex: '2023-04-12 16:00:00.000000'
 * @param {d2} - Date string ex: '2023-04-12 16:00:00.000000'
 * @param {offset} - Offset in days after start date
 * @example
 * const isDayAfter = isDayAfter('2023-04-12 16:00:00.000000', '2023-04-12 16:00:00.000000'); --> false
 * @returns {boolean}
 */
const isDayAfter = (d1, d2, offset = 0) => {
    if (!d1 || !d2) return undefined;

    const date1 = parseDate(d1);
    const date2 = parseDate(d2);
    return date1.getTime() + daysToMilliseconds(offset) < date2.getTime();
};

/**
 * returns value of timezone offset in milliseconds
 * first it will check if the date includes 'Daylight', if not it will compare todays offset to jan/jun offset
 * @param {Date} d - Date object
 * @example
 * const timeZoneOffset = getTimeZoneOffset('Fri Nov 22 2024 13:38:09 GMT-0700 (Mountain Standard Time)'); --> 7200000
 */
const getTimeZoneOffset = (d) => {
    let isDST = d.toLocaleTimeString('en-us', { timeZoneName: 'long' }).includes('Daylight');
    const todaysOffset = d.getTimezoneOffset();

    if (!isDST) {
        const thisYear = new Date().getFullYear();
        const janOffset = new Date(thisYear, 0, 1).getTimezoneOffset();
        const junOffset = new Date(thisYear, 5, 1).getTimezoneOffset();

        isDST = Math.max(janOffset, junOffset) !== todaysOffset;
    }

    const DSToffset = isDST ? 240 : 300;
    const millisecond = 60000;
    return (todaysOffset - DSToffset) * millisecond;
};

/**
 * get time from date string (includes time zone if excludeZone is not passed)
 * @param {dateString} - Date string ex: '2023-04-12 16:00:00.000000'
 * @param {excludeZone} - bool that excludes timezone from returned value
 * @example
 * const localizeTimeZone = localizeTimeZone('2023-04-12 16:00:00.000000'); --> 2:00 pm MDT
 * const localizeTimeZone = localizeTimeZone('2023-04-12 16:00:00.000000', true); --> 2:00 pm
 */
const localizeTimeZone = (dateString, excludeZone) => {
    if (!dateString) return undefined;

    const d = parseTime(dateString);
    d.setTime(d.getTime() - getTimeZoneOffset(d));

    // get timezone string
    const timeZone = d
        .toLocaleTimeString('en-us', { timeZoneName: 'short', hour: '2-digit', minute: '2-digit', hour12: false })
        .slice(6);

    return (
        d
            .toLocaleString('en-us', {
                hour: 'numeric',
                hour12: true,
                minute: '2-digit',
            })
            .toLowerCase()
            .replace(/\s/g, '') + (excludeZone ? '' : ` ${timeZone}`)
    );
};

/**
 * add x amount of days to certain date
 * @param {dateString} - Date string ex: '2023-04-12 16:00:00.000000'
 * @param {daysToAdd} - number of day to add to inputted date
 * @example
 * const calculateUseByDate = calculateUseByDate('2020-08-17 17:20:32.402937');
 * @returns {string} The date string. For example: 2020-10-17 17:20:32.402937
 */
const calculateUseByDate = (dateString, daysToAdd) => {
    if (!dateString) return undefined;

    const parsedLastOrderDate = parseDate(dateString);
    parsedLastOrderDate.setDate(parsedLastOrderDate.getDate() + daysToAdd + 1);
    return new Date(parsedLastOrderDate).toISOString();
};

/**
 * calculate countdown timer
 * @param {interval} - Timeout interval for iterating countdown
 * @param {open} - Date string ex: '2023-04-12 16:00:00.000000'
 * @example
 * const countdownDate = calculateCountdown(interval, '2020-08-17 17:20:32.402937');
 * @returns {object} {
 *  days: 10,
 *  hours: 10,
 *  minutes: 10,
 *  seconds: 10
 * }
 */
const calculateCountdown = (interval, open) => {
    const a = open.split(/[^0-9]/);
    const d = new Date(a[0], a[1] - 1, a[2], a[3], a[4], a[5]);

    const now = new Date();
    const offset = getTimeZoneOffset(now);
    let duration = new Date(d) - now - offset;
    let countdown = {};

    if (duration <= 0) {
        clearInterval(interval);
    } else {
        const days = Math.floor(duration / (1000 * 60 * 60 * 24));
        duration -= days * (1000 * 60 * 60 * 24);

        const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
        duration -= hours * (1000 * 60 * 60);

        const minutes = Math.floor((duration / (1000 * 60)) % 60);
        duration -= minutes * (1000 * 60);

        const seconds = Math.floor((duration / 1000) % 60);
        duration -= seconds * 1000;

        countdown = {
            days,
            hours,
            minutes,
            seconds,
        };
    }

    return countdown;
};

/**
 * Calculate the number of whole days from now until some future date.
 *
 * @param {Date} toDate - The target date to get number of days remaining until ('2023-04-12 16:00:00.000000')
 * @returns {number} The number of whole days from the current date to the target date
 */
const calculateDaysRemaining = (toDate) => {
    const { days } = calculateCountdown(null, toDate);

    return days;
};

/**
 * calculates the week number of the year for a date
 * @param {date} - the date to be calculated
 * @example
 * const weekNum = getWeekNum(new Date('2020-08-17 17:20:32.402937'));
 * @returns {number} 34
 */
const getWeekNum = (date) => {
    const janFirst = new Date(date.getFullYear(), 0, 1);
    return Math.ceil(((date.getTime() - janFirst.getTime()) / 86400000 + janFirst.getDay() + 1) / 7);
};

/**
 * check if two dates are within the same week
 * @param {dateA} - Date ex: new Date('2023-04-12 16:00:00.000000')
 * @param {dateB} - Date ex: new Date('2023-04-13 16:00:00.000000')
 * @example
 * const isSameWeek = getIsSameWeek(new Date('2023-04-12 16:00:00.000000'), new Date('2023-04-13 16:00:00.000000')); --> true
 * @returns {boolean}
 */
const getIsSameWeek = (dateA, dateB) => {
    if (!dateA || !dateB) return undefined;

    return getWeekNum(dateA) === getWeekNum(dateB);
};

/**
 * returns date info for analytics events for plus membership
 * @example
 * const plusMembershipDates = getPlusMembershipDates()
 * @returns {object}
 * {
 *  free_trial_start_date: "2024-06-18T20:41:57.799Z",
 *  free_trial_end_date: "2024-06-25T20:41:57.799Z",
 *  membership_start_date: "2024-06-25T20:41:57.799Z",
 *  membership_end_date: "2024-07-23T20:41:57.799Z"
 * }
 */
const getPlusMembershipDates = () => {
    const todaysDate = new Date();

    const twoWeeksFromToday = new Date(todaysDate);
    twoWeeksFromToday.setDate(twoWeeksFromToday.getDate() + 14);

    const membershipEndDate = new Date(twoWeeksFromToday);
    membershipEndDate.setFullYear(membershipEndDate.getFullYear() + 1);

    return {
        free_trial_start_date: todaysDate.toISOString(),
        free_trial_end_date: twoWeeksFromToday.toISOString(),
        membership_start_date: twoWeeksFromToday.toISOString(),
        membership_end_date: membershipEndDate.toISOString(),
    };
};

export {
    calculateCountdown,
    calculateDaysRemaining,
    calculateUseByDate,
    daysToMilliseconds,
    formatDate,
    formatDateString,
    getDay,
    getMonth,
    getOrdinal,
    getTimeZoneOffset,
    isDayAfter,
    parseDate,
    parseTime,
    localizeTimeZone,
    getIsSameWeek,
    FULL_MONTHS,
    MONTHS,
    getPlusMembershipDates,
};
