import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { useMatch, useParams } from 'react-router-dom';

import { getAisle } from 'api/jalapeno';
import useAccountQuery from 'api/queries/useAccountQuery';
import useAislesQuery from 'api/queries/useAislesQuery';
import useReferralCreditsQuery from 'api/queries/useReferralCreditsQuery';
import useSubscriptionQuery from 'api/queries/useSubscriptionQuery';
import { DUPLICATE_ITEMS_AISLE_IDS } from 'constants/shop';
import { usePromoCodeContext } from 'contexts/PromoCodeContext';
import { ReferralNanoBanner } from 'features/site/header/nanobars/ReferralNanoBanner/ReferralNanoBanner';
import { SignupNanoBanner } from 'features/site/header/nanobars/SignupNanoBanner/SignupNanoBanner';
import { MainNavigation } from 'features/site/header/navigation/MainNavigation/MainNavigation';
import { CustomAisleNavigation } from 'features/site/header/navigation/ShopNavigation/CustomAisleNavigation';
import { DesktopShopNavigation } from 'features/site/header/navigation/ShopNavigation/DesktopShopNavigation';
import { L1Navigation } from 'features/site/header/navigation/ShopNavigation/L1Navigation';
import { L2Navigation } from 'features/site/header/navigation/ShopNavigation/L2Navigation';
import useGlobalStore from 'hooks/useGlobalStore';
import useHandleReferralNanoBannerScroll from 'hooks/useHandleReferralNanoBannerScroll';
import useStickyShopNavigation, { STICKY_SHOP_NAVIGATION_STYLES } from 'hooks/useStickyShopNavigation';
import useTailwindTheme from 'hooks/useTailwindTheme';
import useValidReferralsCampaign from 'hooks/useValidReferralsCampaign';
import { filterProducts } from 'utils/filterUtils';
import { getDecodedPathSegment } from 'utils/pathUtils';

export function Header() {
    const { data: account = {} } = useAccountQuery();
    const { data: subscription = {} } = useSubscriptionQuery();
    const { data: referrals = {} } = useReferralCreditsQuery();
    const { data: { aisles = [], inStockAllItemsCount = 0 } = {} } = useAislesQuery();
    const { appliedFilters } = useGlobalStore();

    const stickyShopNavigation = useStickyShopNavigation();

    const [showPromoTermsModal, setShowPromoTermsModal] = useState(false);

    const tailwindTheme = useTailwindTheme();
    const mobile = useMediaQuery({ maxWidth: tailwindTheme?.screens?.md });
    const referralNanoBannerVisible = useHandleReferralNanoBannerScroll();
    const validReferralsCampaign = useValidReferralsCampaign();
    const { appliedPromoCodeData, renderSignupNanoBanner } = usePromoCodeContext();
    const isShopRoute = !!useMatch('/shop/*');
    const isAcquisitionPDPRoute = !!useMatch('/:slug/p');
    const isAisleRoute = !!useMatch('/shop/aisle/*');
    const isMemberDealsRoute = !!useMatch('/shop/aisle/member-deals');
    const isSearchRoute = !!useMatch('/shop/search/*');
    const isPDPRoute = !!useMatch('/shop/product/:id');
    const isBuyItAgainRoute = !!useMatch('/shop/aisle/buy-it-again');
    const isShopWithPointsRoute = !!useMatch('/shop/browse/misfits+perks');
    const isSupplierPortalRoute = !!useMatch('/supplier-portal');

    const params = useParams();
    const aisleDisplayName = getDecodedPathSegment(params?.category);

    const currentAisle = aisles?.find((aisle) => aisle.name.toLowerCase() === aisleDisplayName?.toLowerCase());

    const aisleQuery = useQuery({
        queryKey: ['aisle', currentAisle?.id],
        queryFn: () => getAisle(account?.nextOrder?.chargeID, currentAisle?.id),
        enabled: account?.nextOrder?.chargeID > 0 && currentAisle?.id > 0,
    });

    const { data: aisleData } = aisleQuery?.data ?? {};
    const { subAisles } = aisleData ?? {};

    const dietaryFilteredItems = currentAisle?.id
        ? filterProducts(appliedFilters, subAisles, [])
        : aisles?.filter((aisle) => !DUPLICATE_ITEMS_AISLE_IDS.includes(aisle.id));

    return (
        <>
            <div
                className={clsx('z-[1200] w-full', {
                    [STICKY_SHOP_NAVIGATION_STYLES.sticky]: stickyShopNavigation.sticky && mobile && isShopRoute,
                    [STICKY_SHOP_NAVIGATION_STYLES.notSticky]: !stickyShopNavigation.sticky && mobile && isShopRoute,
                    'fixed top-0': !mobile || (mobile && !isShopRoute),
                })}
            >
                {validReferralsCampaign && !isShopRoute && (
                    <ReferralNanoBanner
                        mobile={mobile}
                        referralNanoBannerVisible={referralNanoBannerVisible}
                        referralRewards={referrals?.referralRewards}
                    />
                )}
                {renderSignupNanoBanner && (
                    <SignupNanoBanner
                        mobile={mobile}
                        appliedPromoCodeData={appliedPromoCodeData}
                        setShowPromoTermsModal={setShowPromoTermsModal}
                        showPromoTermsModal={showPromoTermsModal}
                    />
                )}
                {!isSupplierPortalRoute && (
                    <MainNavigation
                        subscription={subscription}
                        account={account}
                        isShopRoute={isShopRoute}
                        isAcquisitionPDPRoute={isAcquisitionPDPRoute}
                    />
                )}
                {isShopRoute &&
                    mobile &&
                    !isMemberDealsRoute &&
                    !isBuyItAgainRoute &&
                    !isShopWithPointsRoute &&
                    !isSearchRoute &&
                    !isPDPRoute && (
                        <div>
                            <L1Navigation aisles={aisles} />
                            {!isAisleRoute && <CustomAisleNavigation />}
                        </div>
                    )}
                {isAisleRoute &&
                    mobile &&
                    !isMemberDealsRoute &&
                    !isBuyItAgainRoute &&
                    !isShopWithPointsRoute &&
                    !isSearchRoute && <L2Navigation aisles={dietaryFilteredItems} />}
            </div>
            {isShopRoute && !mobile && (
                <DesktopShopNavigation aisles={aisles} inStockAllItemsCount={inStockAllItemsCount} />
            )}
        </>
    );
}
