import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { trackAcquisitionPDPCTAClick } from 'utils/analyticsUtils';

function useTrackAcquisitionPDPCTAClick({ location = '' }) {
    const params = useParams();
    const productSlug = params?.slug;
    const queryClient = useQueryClient();
    const queryData = queryClient.getQueryData(['acquisition-product', productSlug]);
    const productData = queryData?.data?.item;

    return () =>
        trackAcquisitionPDPCTAClick({
            location,
            productName: productData?.name,
            productBrand: productData?.brand,
        });
}

export default useTrackAcquisitionPDPCTAClick;
