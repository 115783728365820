import PropTypes from 'prop-types';

function RescuingFoodIcon({ width }) {
    return (
        <svg width={width} viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_8714_4897)">
                <path
                    d="M31.7523 60.7595C47.2282 57.0012 62.37 47.9227 62.37 32.0042C62.37 16.0856 49.4498 6.38077 31.7523 3.11521C16.1009 0.267235 5.81986 15.2003 2.93013 31.9373C0.224137 47.6304 17.0029 64.3508 31.7523 60.7595Z"
                    className="fill-mango if:fill-[#F0D3E3]"
                />
                <path
                    d="M46.1513 6.23879C47.6796 5.83791 53.1918 3.58292 56.4156 8.51049C60.0821 14.0979 53.9101 22.2242 52.9496 27.4441C51.9892 32.664 52.6573 39.4206 46.0343 46.0686C41.5578 50.5703 30.3747 51.9149 23.0167 47.1293C15.6588 42.3437 46.1513 6.23879 46.1513 6.23879Z"
                    className="fill-[#2E8540] stroke-black if:fill-[#547420] if:stroke-[#453D3D]"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M44.1299 6.94871C42.5264 7.78389 32.2453 12.1185 28.3283 13.3212C24.4113 14.5238 13.295 17.8061 11.6915 26.5755C10.0879 35.3449 13.295 41.5921 19.4002 45.3671C25.5054 49.1421 33.5983 49.7017 39.5531 43.3543C45.508 37.0069 45.8671 31.4195 47.7463 25.448C49.0074 21.4809 53.2418 17.1713 54.4862 13.1625C55.7306 9.15359 51.1204 3.52447 44.1299 6.94871Z"
                    className="fill-[#B0C372] stroke-black if:fill-[#C5DB66] if:stroke-[#453D3D]"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M29.7573 21.1468C28.1454 21.1468 19.4762 23.0761 19.8019 30.1417C20.1276 37.2073 26.1409 39.9384 30.3169 38.8109C34.4928 37.6834 38.1091 33.9501 37.9504 27.8282C37.7917 21.7064 33.7745 21.1468 29.7573 21.1468Z"
                    className="fill-[#DC6C31] stroke-black if:fill-[#F6A685] if:stroke-[#453D3D]"
                    strokeMiterlimit="10"
                />
                <path
                    d="M28.2122 24.1534C27.5357 24.4374 20.6455 26.2915 23.4016 32.9062C25.3454 30.189 26.9602 27.2509 28.2122 24.1534Z"
                    className="fill-white stroke-black if:stroke-[#453D3D]"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_8714_4897">
                    <rect width="64" height="64" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
}

RescuingFoodIcon.propTypes = {
    width: PropTypes.number,
};

RescuingFoodIcon.defaultProps = {
    width: 61,
};

export default RescuingFoodIcon;
