import PropTypes from 'prop-types';

function DoubleArrowPreviousIcon({ disabled, width }) {
    return disabled ? (
        <svg width={width} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.2817 12.0775L23.2882 12.0405L23.2918 12.0031C23.6884 7.92234 21.85 4.99455 19.4757 3.20591C17.1736 1.47172 14.2739 0.738317 12.3037 1.08267C6.58653 1.81169 0.999851 5.74893 0.99985 11.8833C0.99985 14.9012 2.23875 17.355 4.29649 19.1808C6.33042 20.9854 9.13151 22.1524 12.2725 22.7322C15.3521 23.3009 17.8991 22.0841 19.7533 20.0062C21.5803 17.9588 22.7635 15.0603 23.2817 12.0775Z"
                fill="#C3C3C3"
                stroke="#C3C3C3"
                strokeWidth="2"
            />
            <path
                d="M12.4121 16.1729L6.99761 11.9429L12.4121 7.71299"
                stroke="#2D2D2D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.4121 16.1729L11.9976 11.9429L17.4121 7.71299"
                stroke="#2D2D2D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ) : (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.2817 12.0775L23.2882 12.0405L23.2918 12.0031C23.6884 7.92234 21.85 4.99455 19.4757 3.20591C17.1736 1.47172 14.2739 0.738317 12.3037 1.08267C6.58653 1.81169 0.999851 5.74893 0.99985 11.8833C0.99985 14.9012 2.23875 17.355 4.29649 19.1808C6.33042 20.9854 9.13151 22.1524 12.2725 22.7322C15.3521 23.3009 17.8991 22.0841 19.7533 20.0062C21.5803 17.9588 22.7635 15.0603 23.2817 12.0775Z"
                fill="#F1C34A"
                stroke="#2D2D2D"
                strokeWidth="2"
            />
            <path
                d="M12.4121 16.1729L6.99761 11.9429L12.4121 7.71299"
                stroke="#2D2D2D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.4121 16.1729L11.9976 11.9429L17.4121 7.71299"
                stroke="#2D2D2D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default DoubleArrowPreviousIcon;

DoubleArrowPreviousIcon.propTypes = {
    disabled: PropTypes.bool,
    width: PropTypes.number,
};

DoubleArrowPreviousIcon.defaultProps = {
    disabled: false,
    width: 23,
};
