import clsx from 'clsx';
import PropTypes from 'prop-types';

import useAccountQuery from 'api/queries/useAccountQuery';
import useSelectionsQuery from 'api/queries/useSelectionsQuery';
import { coinWithStar } from 'features/paid-membership/PromotionalBanners/shared/BannerIcons';
import PromotionalBannerBase from 'features/paid-membership/PromotionalBanners/shared/PromotionalBannerBase';
import { useAccountPlusMembershipHook } from 'hooks/useAccountPlusMembershipHook';
import {
    getCartMemberSavings,
    getCartNonMemberSavings,
    getFreeShippingThresholdMet,
    PriceType,
} from 'utils/priceUtils';

function PlusMembershipMemberBanner() {
    const { data: account = {} } = useAccountQuery();
    const { data: selections = {} } = useSelectionsQuery();

    const { plusMembershipBranding, plusMembershipEligibleOrder, shippingFees } = useAccountPlusMembershipHook();
    const priceType = plusMembershipEligibleOrder ? PriceType.PLUS_MEMBER : PriceType.DEFAULT;
    const freeShippingThresholdMet = getFreeShippingThresholdMet({
        account,
        options: { priceType },
        selections,
    });
    const { plusMembershipShippingSavings } = shippingFees ?? {};
    const freeShippingWithMembership =
        plusMembershipEligibleOrder && !freeShippingThresholdMet && plusMembershipShippingSavings > 0;

    const cartNonMemberSavings = getCartNonMemberSavings({ account, selections });
    const cartMemberShippingSavings = freeShippingWithMembership ? plusMembershipShippingSavings : 0;
    const cartMemberSavings = getCartMemberSavings({ account, selections }) + cartMemberShippingSavings;
    const cartTotalSavings = cartNonMemberSavings + cartMemberSavings;

    const heading = (
        <span className={clsx('relative', cartMemberSavings > 0 ? 'top-10' : 'top-20')}>
            <span className="text-heading-xs">${cartTotalSavings.toFixed(2)}</span>
            <span className="text-body-sm relative bottom-[2px]"> saved on this order</span>
        </span>
    );

    const subheading =
        cartMemberSavings > 0 ? (
            <span className="text-body-xs relative top-[5px]">
                <span className="font-grotesk-bold text-cranberry">${cartMemberSavings.toFixed(2)}</span> is from your{' '}
                {plusMembershipBranding} membership
            </span>
        ) : null;

    if ((cartNonMemberSavings <= 0 && cartMemberSavings <= 0) || !plusMembershipEligibleOrder) {
        return null;
    }

    return <PromotionalBannerBase heading={heading} subheading={subheading} iconRight={coinWithStar} hideCta />;
}

PlusMembershipMemberBanner.propTypes = {
    intersectionRef: PropTypes.shape({}).isRequired,
};

export { PlusMembershipMemberBanner };
