import PropTypes from 'prop-types';

const sizeClasses = {
    50: 'min-w-50',
};

function PlusBenefitsSaveFoodIcon({ width }) {
    return (
        <svg
            width={width}
            height={width}
            className={sizeClasses[width]}
            viewBox="0 0 51 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse
                cx="25.5"
                cy="25"
                rx="25"
                ry="25"
                transform="rotate(-90 25.5 25)"
                fill="url(#paint0_linear_2218_88257)"
            />
            <path
                d="M12.1511 19.1767L10.3682 16.7043L29.3815 18.0261L31.1644 20.4985L12.1511 19.1767Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="1.07143"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M31.1645 20.4993L12.1494 19.1765L10.8266 38.1916L29.8417 39.5145L31.1645 20.4993Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="1.07143"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M38.1493 20.985L31.1675 20.4993L29.8447 39.5144L36.8265 40.0001L38.1493 20.985Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="1.07143"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M40.1601 25.8187L33.1805 25.3338L31.1646 20.4984L38.1489 20.9834L40.1601 25.8187Z"
                fill="#2D2D2D"
                stroke="#2D2D2D"
                strokeWidth="1.07143"
                strokeMiterlimit="10"
                strokeLinejoin="round"
            />
            <path
                d="M26.7524 14.0465C26.7952 12.7771 26.8142 9.95288 26.8142 9.95288"
                stroke="white"
                strokeWidth="1.07143"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M33.1899 15.7724C33.732 15.1401 34.8826 13.6947 34.8826 13.6947"
                stroke="white"
                strokeWidth="1.07143"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.1642 13.8468C18.8219 13.0765 17.9946 11.3887 17.9946 11.3887"
                stroke="white"
                strokeWidth="1.07143"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.9587 34.8935C20.4995 35.2125 21.1744 34.7385 21.7248 34.4727C21.9402 34.3708 22.146 34.2467 22.3422 34.1183C22.8878 33.7639 23.4765 33.4715 24.0748 33.2013C24.2519 33.1215 24.4242 33.0462 24.6013 32.9665C24.8837 32.8425 25.1469 32.683 25.3814 32.4925C25.7978 32.1514 26.1951 31.797 26.5158 31.3717C26.8077 30.9863 27.0087 30.561 27.1092 30.1003C27.1284 30.0029 27.138 29.9054 27.1523 29.808C27.1571 29.7858 27.1619 29.7637 27.1667 29.7415C27.1667 29.7282 27.1667 29.7149 27.1667 29.7016C27.1667 29.6751 27.1619 29.6485 27.1619 29.6263C27.1715 29.2985 27.0997 28.9796 27.0231 28.665C26.9082 28.2043 26.7407 27.7658 26.511 27.3449C26.152 26.6937 25.5872 26.2773 24.8454 26.0602C24.5391 25.9716 24.2232 25.9052 23.9025 25.8742C23.534 25.8387 23.1702 25.821 22.8017 25.8919C22.6581 25.9185 22.5193 25.9628 22.3853 26.0159C22.0503 26.1488 21.7152 26.2862 21.3802 26.4279C20.9255 25.8299 20.2842 25.3647 19.4322 25.1078C18.6999 24.8863 17.7475 25.0236 16.9099 25.3603C15.9288 25.7368 15.4118 26.645 15.5124 27.5398C15.7804 30.0029 18.0347 33.7639 19.9587 34.8935Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="1.07143"
                strokeMiterlimit="10"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_2218_88257"
                    x1="48.7143"
                    y1="-0.714284"
                    x2="6.92858"
                    y2="51.0714"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F594A4" />
                    <stop offset="0.464323" stopColor="#A13051" />
                    <stop offset="1" stopColor="#6784AC" />
                </linearGradient>
            </defs>
        </svg>
    );
}

PlusBenefitsSaveFoodIcon.propTypes = {
    width: PropTypes.number,
};

PlusBenefitsSaveFoodIcon.defaultProps = {
    width: 100,
};

export default PlusBenefitsSaveFoodIcon;
