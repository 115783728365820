import PropTypes from 'prop-types';

import useReferralCreditsQuery from 'api/queries/useReferralCreditsQuery';
import NanoBanner from 'honeydew/NanoBanner';

import { SignupPromoTermsModal } from './SignupPromoTermsModal';
import { TermsAndConditions } from './TermsAndConditions';
import { getNanobarText } from './utils';

export function SignupNanoBanner({ appliedPromoCodeData, setShowPromoTermsModal, showPromoTermsModal }) {
    const { data: referrals = {}, isLoading: isReferralsLoading } = useReferralCreditsQuery();
    const { referralRewards } = referrals ?? {};

    const nanobarText = getNanobarText({ appliedPromoCodeData, referralRewards });
    const NanobarTerms = (
        <TermsAndConditions appliedPromoCodeData={appliedPromoCodeData} referralRewards={referralRewards} />
    );

    if (isReferralsLoading || !nanobarText) {
        return null;
    }

    return (
        <>
            <SignupPromoTermsModal
                showPromoTermsModal={showPromoTermsModal}
                setShowPromoTermsModal={setShowPromoTermsModal}
                termsAndConditions={NanobarTerms}
            />
            {appliedPromoCodeData && (
                <NanoBanner
                    boldCopy={nanobarText}
                    link="See details"
                    onClick={() => setShowPromoTermsModal(true)}
                    signupBanner
                />
            )}
        </>
    );
}

SignupNanoBanner.propTypes = {
    appliedPromoCodeData: PropTypes.shape({
        claimBy: PropTypes.shape({
            date: PropTypes.string,
        }),
        disc: PropTypes.shape({
            code: PropTypes.string,
            nanobarTerms: PropTypes.string,
            nanobarText: PropTypes.string,
            type: PropTypes.string,
            valid: PropTypes.bool,
            value: PropTypes.number,
        }),
    }),
    setShowPromoTermsModal: PropTypes.func.isRequired,
    showPromoTermsModal: PropTypes.bool.isRequired,
};

SignupNanoBanner.defaultProps = {
    appliedPromoCodeData: undefined,
};
