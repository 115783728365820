import PropTypes from 'prop-types';

function KetoIcon({ className, width }) {
    return (
        <svg width={width} className={className} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_759_23868)">
                <path
                    d="M7.61157 16.8773C7.85894 17.8409 8.90009 21.0899 8.86686 21.7619C8.83363 22.4338 7.32729 22.7181 7.85894 23.8073C8.39059 24.8964 10.0372 24.7266 10.4138 24.0177C11.2113 24.1322 12.9355 24.2872 12.9982 22.7403C13.061 21.1933 11.3147 21.533 11.0599 21.1638C10.8052 20.7946 9.71972 16.3641 9.71972 16.3641L7.61157 16.8773Z"
                    fill="white"
                    stroke="#2D2D2D"
                    strokeWidth="0.553805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.9867 7.13403C12.7658 10.1615 11.8243 12.5392 11.5548 13.8646C11.1007 16.0798 10.665 17.3573 9.04419 17.7745C7.63752 18.1437 6.20871 17.0841 4.74666 15.3636C3.86796 14.3335 2.03671 12.6167 1.33153 9.8772C0.279305 5.80489 1.81519 1.88396 4.75774 1.12709C7.70029 0.370223 10.9345 3.07279 11.9867 7.13403Z"
                    className="fill-[#DEA455] if:fill-[#F6A685]"
                    stroke="#2D2D2D"
                    strokeWidth="0.553805"
                    strokeMiterlimit="10"
                />
                <path
                    d="M9.4392 6.99005C9.83794 6.00428 9.86009 3.05065 6.15698 2.44885C7.37764 3.86694 8.47566 5.38613 9.4392 6.99005Z"
                    fill="white"
                    stroke="#2D2D2D"
                    strokeWidth="0.553805"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19.2901 3.35124C19.7198 3.10799 21.2049 1.98622 22.5726 3.21724C24.1268 4.61222 22.9117 7.5605 23.0387 9.21423C23.1657 10.868 23.9075 12.8558 22.4379 15.3935C21.4452 17.1113 18.1742 18.4112 15.5689 17.5535C12.9635 16.6957 19.2901 3.35124 19.2901 3.35124Z"
                    className="fill-[#2E8540] if:fill-[#547420]"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.7363 3.7272C18.3186 4.10767 15.559 6.23882 14.4718 6.91519C13.3845 7.59156 10.2885 9.47154 10.5049 12.249C10.7213 15.0265 12.1894 16.6575 14.3355 17.31C16.4816 17.9626 18.9713 17.4849 20.263 15.0914C21.5548 12.6979 21.2168 10.9812 21.3072 9.02699C21.3712 7.7277 22.306 6.08735 22.3616 4.77679C22.4172 3.46622 20.5745 2.13405 18.7363 3.7272Z"
                    className="fill-[#B0C472] if:fill-[#C5DB66]"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.5289 9.16523C15.0419 9.29405 12.577 10.5697 13.2401 12.6783C13.9032 14.7868 15.9381 15.1313 17.1096 14.457C18.281 13.7826 19.0752 12.3658 18.538 10.529C18.0009 8.69219 16.7425 8.84418 15.5289 9.16523Z"
                    className="fill-[#DC6B31] if:fill-[#F6A685]"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeMiterlimit="10"
                />
            </g>
            <defs>
                <clipPath id="clip0_759_23868">
                    <rect width="25" height="25" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default KetoIcon;

KetoIcon.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number.isRequired,
};

KetoIcon.defaultProps = {
    className: null,
};
