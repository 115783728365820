import clsx from 'clsx';
import PropTypes from 'prop-types';

export default function FaqInfoLine({ backgroundClasses, icon, subtitle, title }) {
    return (
        <div className="flex items-center gap-10 align-middle">
            <div
                className={clsx('flex h-30 w-30 min-w-30 items-center justify-center rounded-full', backgroundClasses)}
            >
                {icon}
            </div>
            <div className="flex flex-col">
                <span className="text-body-sm font-grotesk-bold">{title}</span>
                <span className="text-body-sm text-grey">{subtitle}</span>
            </div>
        </div>
    );
}

FaqInfoLine.propTypes = {
    icon: PropTypes.node,
    subtitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

FaqInfoLine.defaultProps = {
    icon: null,
};
