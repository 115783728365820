import { GiftIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import PropTypes from 'prop-types';

function NanoBanner({ boldCopy, link, mainCopy, onClick, signupBanner }) {
    return (
        <div
            className={clsx('bg-brand-secondary flex w-full justify-center py-10 md:py-20', {
                'px-10': signupBanner,
                'px-10 sm:px-35': !signupBanner,
            })}
        >
            <p className={clsx('text-body-sm max-w-[585px] md:max-w-none', { 'text-center': signupBanner })}>
                {signupBanner && <GiftIcon className="mb-5 mr-10 inline h-[24px] md:mr-20" />}
                {boldCopy && <span className="font-grotesk-bold">{boldCopy}</span>} {mainCopy}{' '}
                {link && (
                    <button className="cursor-pointer font-grotesk-bold underline" onClick={onClick} type="button">
                        {link}
                    </button>
                )}
            </p>
        </div>
    );
}

NanoBanner.propTypes = {
    boldCopy: PropTypes.node,
    link: PropTypes.string,
    mainCopy: PropTypes.string,
    onClick: PropTypes.func,
    signupBanner: PropTypes.bool,
};

NanoBanner.defaultProps = {
    boldCopy: null,
    link: null,
    mainCopy: null,
    onClick: null,
    signupBanner: false,
};

export default NanoBanner;
