import PropTypes from 'prop-types';

import useBrandLinks from 'hooks/useBrandLinks';
import { formatDate } from 'utils/dateUtils';

function TermsAndConditionsLink({ children }) {
    const { terms } = useBrandLinks();

    return (
        <a className="underline" href={terms} target="_blank" rel="noopener noreferrer">
            {children}
        </a>
    );
}

function JoinTermsAndConditions({
    annualPlusMembershipCost,
    availableCredits,
    availableTrialMembership,
    brand,
    membershipRenewalDate,
    plusMembershipBranding,
}) {
    if (availableTrialMembership) {
        return (
            <div className="text-compact text-grey">
                <p>
                    By starting your {plusMembershipBranding} free trial, you agree to the{' '}
                    <TermsAndConditionsLink>{plusMembershipBranding} Terms</TermsAndConditionsLink> and authorize{' '}
                    {brand} to charge an annual ${annualPlusMembershipCost} membership fee (plus applic. tax) to your
                    account’s payment method beginning on {formatDate(membershipRenewalDate?.date, true)} until you
                    cancel. <TermsAndConditionsLink>Cancel</TermsAndConditionsLink> in account to avoid renewal charges.
                </p>
            </div>
        );
    }

    if (availableCredits?.credits > 0) {
        return (
            <div className="text-compact text-grey">
                <p>
                    By joining {plusMembershipBranding}, you agree to the {plusMembershipBranding} Terms and authorize{' '}
                    {plusMembershipBranding} to charge an annual ${annualPlusMembershipCost} membership fee (plus
                    applic. tax) to your account&apos;s payment method until you cancel. Cancel in account to avoid
                    renewal charges. Offer includes ${availableCredits.credits} account credit applied to your account
                    upon enrollment. Credit must be used for an order charged within{' '}
                    {availableCredits.creditsExpirationDays} days of enrollment. Offer subject to applicable order
                    minimum. Exclusions may apply.
                </p>
            </div>
        );
    }

    return (
        <div className="text-compact text-grey">
            <p>
                By joining {plusMembershipBranding}, you agree to the{' '}
                <TermsAndConditionsLink>{plusMembershipBranding} Terms</TermsAndConditionsLink> and authorize {brand} to
                charge an annual ${annualPlusMembershipCost} membership fee (plus applic. tax) to your account’s payment
                method until you cancel. <TermsAndConditionsLink>Cancel</TermsAndConditionsLink> in account to avoid
                renewal charges.
            </p>
        </div>
    );
}

JoinTermsAndConditions.propTypes = {
    annualPlusMembershipCost: PropTypes.number.isRequired,
    availableCredits: PropTypes.shape({
        credits: PropTypes.number.isRequired,
        creditsExpirationDays: PropTypes.number.isRequired,
    }),
    availableTrialMembership: PropTypes.shape({
        trialDurationDays: PropTypes.number,
        variantId: PropTypes.number,
    }),
    brand: PropTypes.string.isRequired,
    membershipRenewalDate: PropTypes.shape({ date: PropTypes.string }),
    plusMembershipBranding: PropTypes.string.isRequired,
};

JoinTermsAndConditions.defaultProps = {
    availableCredits: null,
    availableTrialMembership: null,
    membershipRenewalDate: null,
};

export { JoinTermsAndConditions };
