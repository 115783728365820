import PropTypes from 'prop-types';

function ClosedWindowIcon({ className, width }) {
    return (
        <svg width={width} viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <mask id="path-1-inside-1_1121_5673" fill="white">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M25.2805 4.31274C19.7577 4.31274 15.2805 8.7899 15.2805 14.3127V26.1996H44.7196V14.3127C44.7196 8.7899 40.2424 4.31274 34.7196 4.31274H25.2805ZM26.3085 9.11762C22.9948 9.11762 20.3085 11.8039 20.3085 15.1176V23.528H39.6913V15.1176C39.6913 11.8039 37.005 9.11762 33.6913 9.11762H26.3085Z"
                />
            </mask>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.2805 4.31274C19.7577 4.31274 15.2805 8.7899 15.2805 14.3127V26.1996H44.7196V14.3127C44.7196 8.7899 40.2424 4.31274 34.7196 4.31274H25.2805ZM26.3085 9.11762C22.9948 9.11762 20.3085 11.8039 20.3085 15.1176V23.528H39.6913V15.1176C39.6913 11.8039 37.005 9.11762 33.6913 9.11762H26.3085Z"
                className="fill-[#DEA455] if:fill-[#B32274]"
            />
            <path
                d="M15.2805 26.1996H14.2805V27.1996H15.2805V26.1996ZM44.7196 26.1996V27.1996H45.7196V26.1996H44.7196ZM20.3085 23.528H19.3085V24.528H20.3085V23.528ZM39.6913 23.528V24.528H40.6913V23.528H39.6913ZM16.2805 14.3127C16.2805 9.34218 20.31 5.31274 25.2805 5.31274V3.31274C19.2054 3.31274 14.2805 8.23761 14.2805 14.3127H16.2805ZM16.2805 26.1996V14.3127H14.2805V26.1996H16.2805ZM44.7196 25.1996H15.2805V27.1996H44.7196V25.1996ZM43.7196 14.3127V26.1996H45.7196V14.3127H43.7196ZM34.7196 5.31274C39.6902 5.31274 43.7196 9.34218 43.7196 14.3127H45.7196C45.7196 8.23761 40.7947 3.31274 34.7196 3.31274V5.31274ZM25.2805 5.31274H34.7196V3.31274H25.2805V5.31274ZM21.3085 15.1176C21.3085 12.3562 23.5471 10.1176 26.3085 10.1176V8.11762C22.4425 8.11762 19.3085 11.2516 19.3085 15.1176H21.3085ZM21.3085 23.528V15.1176H19.3085V23.528H21.3085ZM39.6913 22.528H20.3085V24.528H39.6913V22.528ZM38.6913 15.1176V23.528H40.6913V15.1176H38.6913ZM33.6913 10.1176C36.4527 10.1176 38.6913 12.3562 38.6913 15.1176H40.6913C40.6913 11.2516 37.5573 8.11762 33.6913 8.11762V10.1176ZM26.3085 10.1176H33.6913V8.11762H26.3085V10.1176Z"
                mask="url(#path-1-inside-1_1121_5673)"
                className="fill-[#2D2D2D] if:fill-[#453D3D]"
            />
            <rect
                x="9.78369"
                y="22.4011"
                width="40.433"
                height="33.7861"
                rx="5.5"
                className="fill-[#F1C34A] stroke-[#2D2D2D] if:fill-[#E95CA2] if:stroke-[#453D3D]"
            />

            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.3245 38.3575C34.8873 37.2932 35.9136 35.4995 35.9136 33.4661C35.9136 30.1999 33.2659 27.5522 29.9997 27.5522C26.7336 27.5522 24.0859 30.1999 24.0859 33.4661C24.0859 35.4995 25.1122 37.2932 26.675 38.3575L24.9507 49.8884C24.8604 50.4929 25.3286 51.0363 25.9398 51.0363H34.0597C34.6709 51.0363 35.1391 50.4929 35.0487 49.8884L33.3245 38.3575Z"
                className="fill-[#2D2D2D] if:fill-[#453D3D]"
            />
        </svg>
    );
}

ClosedWindowIcon.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number,
};

ClosedWindowIcon.defaultProps = {
    className: '',
    width: 60,
};

export default ClosedWindowIcon;
