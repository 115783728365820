import PropTypes from 'prop-types';

import { EatItAllIconIF } from './EatItAllIconIF';
import { EatItAllIconMM } from './EatItAllIconMM';

export function EatItAllIcon({ className, isMisfits, width }) {
    return isMisfits ? (
        <EatItAllIconMM width={width} className={className} />
    ) : (
        <EatItAllIconIF width={width} className={className} />
    );
}

EatItAllIcon.propTypes = {
    className: PropTypes.string,
    isMisfits: PropTypes.bool,
    width: PropTypes.number.isRequired,
};

EatItAllIcon.defaultProps = {
    className: null,
    isMisfits: true,
};
