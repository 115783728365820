import { string } from 'yup';

import { getValidDeliveryDays, updateAddress, updateBirthday } from 'api/jalapeno';
import { ACCOUNT_QUERY_KEY, accountQueryFn } from 'api/queries/useAccountQuery';
import { SMS_NOTIFICATIONS_QUERY_KEY } from 'api/queries/useSmsNotificationsQuery';
import { SUBSCRIPTION_QUERY_KEY, subscriptionQueryFn } from 'api/queries/useSubscriptionQuery';
import { getFormattedPhoneForAPI, VALID_PHONE_FORMAT_REGEX } from 'utils/addressUtils';
import { identify } from 'utils/analyticsUtils';
import { birthdayValidation } from 'utils/birthdayUtils';
import logError from 'utils/errorUtils';

const phoneValidation = string()
    .trim()
    .matches(VALID_PHONE_FORMAT_REGEX, 'Please enter your phone in a (555) 555-5555 format.');

const fetcherSuccess = { success: true, message: '' };

const action =
    (queryClient) =>
    async ({ request }) => {
        const formData = await request.formData();
        const { _action, addressError, customerID, subscriptionID, ...values } = Object.fromEntries(formData);

        // Several forms update the customer's address
        if (['updateName', 'updatePhone', 'updateAddress'].includes(_action)) {
            let payload = {};

            if (_action === 'updateName') {
                payload = values;
            }

            const isPhoneUpdate = _action === 'updatePhone';

            if (isPhoneUpdate) {
                const phone = getFormattedPhoneForAPI(values.phone);

                try {
                    phoneValidation.validateSync(phone);
                    payload = {
                        ...values,
                        phone,
                        smsMarketing: values.smsMarketing === 'on',
                        smsTransactional: values.smsTransactional === 'on',
                    };
                } catch (err) {
                    return {
                        error: true,
                        message: err.errors[0],
                    };
                }
            }

            if (_action === 'updateAddress') {
                payload = {
                    ...values,
                    confirmPlusMembershipCarrierSwitch: 'CONFIRM_CARRIER_SWITCH',
                };

                if (addressError === 'true') {
                    return {
                        error: true,
                        message: 'Sorry, we could not update your address. Please enter a valid address.',
                    };
                }

                try {
                    const response = await getValidDeliveryDays(subscriptionID, payload.zip);
                    if (response?.data?.days?.length === 0) {
                        return {
                            error: true,
                            message: "Deliveries to your zip code aren't supported yet.",
                        };
                    }
                } catch (err) {
                    return {
                        error: true,
                        message:
                            'Sorry, we could not update your address. Please try again or contact our customer service.',
                    };
                }
            }

            try {
                const response = await updateAddress(subscriptionID, payload);
                if (response.msg === 'Success') {
                    await queryClient.invalidateQueries({ queryKey: [SUBSCRIPTION_QUERY_KEY] });
                    if (isPhoneUpdate) {
                        queryClient.invalidateQueries({ queryKey: [SMS_NOTIFICATIONS_QUERY_KEY] });
                    }
                    try {
                        const account = await queryClient.fetchQuery({
                            queryKey: [ACCOUNT_QUERY_KEY],
                            queryFn: accountQueryFn,
                        });
                        const subscription = await queryClient.fetchQuery({
                            queryKey: [SUBSCRIPTION_QUERY_KEY],
                            queryFn: () => subscriptionQueryFn(account),
                        });
                        identify({ account, subscription });

                        if (window.isNativeApp) {
                            window.ReactNativeWebView?.postMessage(JSON.stringify({ reload: true }));
                        }
                    } catch (error) {
                        logError(error);
                    }

                    return fetcherSuccess;
                }
                throw new Error(response.msg);
            } catch (apiError) {
                return {
                    error: true,
                    message: 'Could not update your address.',
                };
            }
        }

        if (_action === 'updateBirthday') {
            try {
                birthdayValidation.validateSync(values.dob);
            } catch (err) {
                return {
                    error: true,
                    message: err.errors[0],
                };
            }

            try {
                await updateBirthday(customerID, { dob: values.dob });
                await queryClient.invalidateQueries({ queryKey: [SUBSCRIPTION_QUERY_KEY] });
            } catch (err) {
                return {
                    error: true,
                    message: 'Oops! The entered birthday could not be updated. Please try again.',
                };
            }
        }

        return {};
    };

export default action;
