import PropTypes from 'prop-types';

import MMHolidayMustHavesActive from './MMHolidayMustHavesActive';
import MMHolidayMustHavesInactive from './MMHolidayMustHavesInactive';

function MMHolidayMustHaves({ className, isActive, width }) {
    return isActive ? (
        <MMHolidayMustHavesActive className={className} width={width} />
    ) : (
        <MMHolidayMustHavesInactive className={className} width={width} />
    );
}

export default MMHolidayMustHaves;

MMHolidayMustHaves.propTypes = {
    className: PropTypes.string,
    isActive: PropTypes.bool,
    width: PropTypes.number,
};

MMHolidayMustHaves.defaultProps = {
    className: '',
    isActive: true,
    width: 24,
};
