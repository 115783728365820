import React from 'react';
import { UserCircleIcon } from '@heroicons/react/24/outline';

export function SignInLink() {
    return (
        <a href="/login" className="flex items-center gap-5 font-grotesk-bold">
            <UserCircleIcon className="h-20 w-20 text-black" /> Sign in
        </a>
    );
}
