import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import { ExclusiveDealsBenefit, ImpactBenefit, ShippingBenefit } from 'features/paid-membership/Benefits';

function BenefitsListV2({ bgColor, layout }) {
    const withinHorizontalBreakpoint = useMediaQuery({ maxWidth: 539 });

    const listOrientation = layout === 'horizontal' || withinHorizontalBreakpoint ? 'horizontal' : 'responsive';

    return (
        <ul className={clsx('gap-20', listOrientation === 'horizontal' ? 'flex flex-col' : 'grid grid-cols-3')}>
            <li>
                <ExclusiveDealsBenefit layout={listOrientation} bgColor={bgColor} />
            </li>
            <li>
                <ShippingBenefit layout={listOrientation} bgColor={bgColor} />
            </li>
            <li>
                <ImpactBenefit layout={listOrientation} bgColor={bgColor} />
            </li>
        </ul>
    );
}

BenefitsListV2.propTypes = {
    bgColor: PropTypes.string,
    layout: PropTypes.oneOf(['horizontal', 'responsive']),
};

BenefitsListV2.defaultProps = {
    bgColor: '',
    layout: 'responsive',
};

export { BenefitsListV2 };
