/**
 * Checks if a user is logging into the wrong branded site
 * @param {string} email - users email
 * @param {string} brand - brand from the /exists endpoint
 * @param {bool} isImperfectTheme - from useGlobalStore.
 * @returns {bool} true == belongs to the other site
 */
export const getIsSisterBrand = (email, brand, isImperfectTheme) => {
    const testAccounts = [
        'devmisfitsmarket1@gmail.com',
        'devmisfitsmarket2@gmail.com',
        'devmisfitsmarket3@gmail.com',
        'devmisfitsmarket4@gmail.com',
        'devmisfitsmarket5@gmail.com',
    ];

    return (
        ((isImperfectTheme && brand !== 'imperfect_foods') || (!isImperfectTheme && brand === 'imperfect_foods')) &&
        !testAccounts.includes(email)
    );
};

/**
 * Checks for a valid password (1 num, 1 lower case, 1 upper case, length > 7)
 * @param {string} password - users password
 * @returns {bool} true = valid password
 */
export const validatePassword = (pw) => {
    const re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
    return re.test(pw);
};

/**
 * Check for a valid 6-digit forgot password code
 * @param {string} code - confirmation code
 * @returns {bool} true = 6 digit number
 */
export const validConfirmationCode = (code) => /^\d{6}$/.test(code);
