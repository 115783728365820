import PropTypes from 'prop-types';

import useBrandLinks from 'hooks/useBrandLinks';
import { MONTHS } from 'utils/dateUtils';

/**
 * Terms and conditions content for the signup promo terms modal.
 */
function TermsAndConditions({ appliedPromoCodeData, referralRewards }) {
    const { code, nanobarTerms: nanobarTermsFromAPI, type } = appliedPromoCodeData?.disc ?? {};
    const { minimumOrderValue } = referralRewards ?? {};

    const brandLinks = useBrandLinks();

    if (nanobarTermsFromAPI) {
        return nanobarTermsFromAPI;
    }

    if (type === 'REFERRAL') {
        return (
            <>
                Referral offer valid only for new customers who place an order with a minimum subtotal of $
                {minimumOrderValue} within 30 days of account creation. Certain promotional offers may be time-limited
                and require account sign-up by a designated claim-by date. Offer not valid for past purchases and may
                not be combined with other offers. Offer subject to applicable order minimum. Please visit our{' '}
                <a href={brandLinks.referralProgram} target="_blank" rel="noreferrer" className="link">
                    Referral page
                </a>{' '}
                and{' '}
                <a href={brandLinks.terms} target="_blank" rel="noreferrer" className="link">
                    Terms of Service
                </a>{' '}
                for complete details of this referral offer, including any applicable claim-by date information.
                Exclusions may apply.
            </>
        );
    }

    const claimByDate = new Date(appliedPromoCodeData?.claimBy?.date);
    const formattedClaimByDate = `${
        MONTHS[claimByDate.getMonth()]
    } ${claimByDate.getDate()}, ${claimByDate.getFullYear()}`;

    return `Offer valid for orders placed by new customers before ${formattedClaimByDate} at 11:59 p.m. ET with code ${code}. Offer not valid for past purchases and may not be combined with other offers. Offer subject to applicable order minimum. Exclusions may apply.`;
}

TermsAndConditions.propTypes = {
    appliedPromoCodeData: PropTypes.shape({
        code: PropTypes.string,
        nanobarTerms: PropTypes.string,
        type: PropTypes.string,
    }),
    referralRewards: PropTypes.shape({
        minimumOrderValue: PropTypes.number,
        newCustValue: PropTypes.number,
        referrerValue: PropTypes.number,
    }),
};

TermsAndConditions.defaultProps = {
    appliedPromoCodeData: undefined,
    referralRewards: undefined,
};

export { TermsAndConditions };
