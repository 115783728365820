import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { JoinModal } from 'features/paid-membership/JoinModal';
import Button from 'honeydew/Button';
import Card from 'honeydew/Card';
import Tag from 'honeydew/Tag';
import { useAccountPlusMembershipHook } from 'hooks/useAccountPlusMembershipHook';
import { formatDateString } from 'utils/dateUtils';

function getPlusMemberCopy(brandingName) {
    return {
        grocery_subscription: {
            paid: `In addition to your Grocery Subscription, you’re also a ${brandingName} member, which means you have access to our biggest savings.`,
            trial: `In addition to your Grocery Subscription, you’re on a ${brandingName} free trial.`,
        },
        flex_plan: {
            paid: `In addition to your Flex Plan, you’re also a ${brandingName} member, which means you have access to even bigger savings.`,
            trial: `In addition to your Flex Plan, you’re on a ${brandingName} free trial.`,
        },
    };
}

function getPlusNonMemberCopy(brandingName) {
    return {
        grocery_subscription: `Sign up for ${brandingName} in addition to your Grocery Subscription and you’ll get access to our biggest savings.`,
        flex_plan: `Sign up for ${brandingName} in addition to your Flex Plan and you’ll get access to even bigger savings.`,
    };
}

export function PlanModule({ isFlexPlan, paused }) {
    const { availableTrialMembership, membership, plusMembershipBranding, trial } = useAccountPlusMembershipHook();

    const subscriptionType = isFlexPlan ? 'flex_plan' : 'grocery_subscription';
    const hasMembership = !!membership;
    const trialExpirationDate = trial?.endDate;
    const trialAvailable = !!availableTrialMembership;
    const misfitsPlusMemberType = trialExpirationDate ? 'trial' : 'paid';
    const formattedExpirationDate = formatDateString(trialExpirationDate);
    const [isJoinMembershipModalOpen, setIsJoinMembershipModalOpen] = useState(false);

    return (
        <>
            <Card marginClasses="mt-20">
                <div className="grow gap-70 md:flex">
                    <h2 className="text-body-lg mb-20 font-grotesk-bold md:w-[180px] md:min-w-[180px]">
                        {plusMembershipBranding} Membership
                    </h2>
                    <div>
                        <div className="text-body-md flex grow flex-col gap-10 font-grotesk">
                            {(hasMembership || trialExpirationDate) && (
                                <div className="flex gap-10 uppercase">
                                    <Tag bgColor="plusMembership">{plusMembershipBranding} Member</Tag>
                                    {trialExpirationDate && (
                                        <Tag bgColor="blueberry" variant="dark">
                                            FREE TRIAL
                                        </Tag>
                                    )}
                                </div>
                            )}
                            {availableTrialMembership && (
                                <div className="uppercase">
                                    <Tag bgColor="plusMembership">
                                        Try it free for {availableTrialMembership.trialDurationDays} days
                                    </Tag>
                                </div>
                            )}

                            {(hasMembership || trialExpirationDate) && (
                                <p className="mb-20">
                                    {getPlusMemberCopy(plusMembershipBranding)[subscriptionType][misfitsPlusMemberType]}
                                </p>
                            )}
                            {trialExpirationDate && (
                                <p className="mb-20 font-grotesk-bold">
                                    Your free trial ends on {formattedExpirationDate}.
                                </p>
                            )}

                            {!hasMembership && !trialExpirationDate && (
                                <div className="flex flex-col gap-5">
                                    <p className="font-grotesk-bold">Want even more bang for your buck?</p>
                                    <p>
                                        {paused
                                            ? 'Resume your Grocery Subscription to get started.'
                                            : getPlusNonMemberCopy(plusMembershipBranding)[subscriptionType]}
                                    </p>
                                    <ul className="list-disc pl-20">
                                        <li>
                                            <p>Unlock exclusive weekly deals</p>
                                        </li>
                                        <li>
                                            <p>Save on shipping fees</p>
                                        </li>
                                        <li>
                                            <p>Fight hunger with every order</p>
                                        </li>
                                    </ul>
                                    {!paused && (
                                        <div className="text-body-sm mb-10 mt-20 w-[220px]">
                                            <Button
                                                variant="secondary"
                                                size="md"
                                                onClick={() => {
                                                    setIsJoinMembershipModalOpen(true);
                                                }}
                                            >
                                                {trialAvailable
                                                    ? 'Start free trial today'
                                                    : `Join ${plusMembershipBranding} today`}
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            )}

                            <Link className="link-blueberry text-body-sm block" to="/account/membership">
                                {hasMembership || trialExpirationDate
                                    ? `View ${plusMembershipBranding} membership`
                                    : `Get the scoop on ${plusMembershipBranding}`}
                            </Link>
                        </div>
                    </div>
                </div>
            </Card>
            <JoinModal
                open={isJoinMembershipModalOpen}
                onClose={() => {
                    setIsJoinMembershipModalOpen(false);
                }}
            />
        </>
    );
}

PlanModule.propTypes = {
    isFlexPlan: PropTypes.bool,
    paused: PropTypes.bool,
};

PlanModule.defaultProps = {
    isFlexPlan: false,
    paused: false,
};
