import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';

import NanoBanner from 'honeydew/NanoBanner';

export function ReferralNanoBanner({ mobile, referralNanoBannerVisible, referralRewards }) {
    const { newCustValue, referrerValue } = referralRewards ?? {};

    const navigate = useNavigate();

    const handleClick = () => {
        if (window.isNativeApp) {
            window.ReactNativeWebView?.postMessage(JSON.stringify({ navigate: 'Referrals' }));
        } else {
            navigate('/account/referrals');
        }
    };

    return (
        <div
            className={clsx('transition-height w-full overflow-hidden delay-150 duration-300 ease-in-out', {
                'h-[72px]': referralNanoBannerVisible && mobile,
                'h-[66px]': referralNanoBannerVisible && !mobile,
                'h-0': !referralNanoBannerVisible,
            })}
        >
            <NanoBanner
                mainCopy={`Limited time: Give friends $${newCustValue} off their 1st order, get $${referrerValue} in credits.`}
                link="Learn more and refer now."
                onClick={handleClick}
            />
        </div>
    );
}

ReferralNanoBanner.propTypes = {
    mobile: PropTypes.bool.isRequired,
    referralNanoBannerVisible: PropTypes.bool.isRequired,
    referralRewards: PropTypes.shape({
        id: PropTypes.number,
        newCustValue: PropTypes.number,
        referrerValue: PropTypes.number,
        termsAndConditions: PropTypes.string,
    }).isRequired,
};
