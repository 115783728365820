import PropTypes from 'prop-types';

import { IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN } from 'constants/theme';

export function MeatAndSeafood({ brand, isActive, width }) {
    const steakColor = brand === MISFITS_THEME_DOMAIN ? '#DC6B31' : '#D9291F';
    const activeColor = brand === MISFITS_THEME_DOMAIN ? '#D7E1B8' : '#F0D3E3';

    return isActive ? (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.6505 28.7652C21.902 26.9646 29 22.6358 29 14.9992C29 7.3625 22.9452 2.7363 14.6505 1.1728C7.31233 -0.213857 2.48978 6.96057 1.13553 14.967C-0.132106 22.475 7.73356 30.4815 14.6505 28.7652Z"
                fill={activeColor}
            />
            <g clipPath="url(#clip0_10935_21594)">
                <path
                    d="M27.511 14.3738C27.0865 13.3955 26.062 12.7309 25.5266 11.6418C25.0929 10.7558 25.2036 9.19597 25.3236 7.66385C24.8806 7.94074 24.336 8.04226 23.8192 7.93151C23.8007 8.04226 23.7915 8.13456 23.7915 8.18994C23.653 9.85128 23.6992 11.2819 24.216 12.2048C24.7329 13.1278 25.6836 13.7001 26.0343 14.5676C26.8188 16.5336 25.2221 17.4104 24.0314 17.5119C22.7024 17.6227 20.8472 17.0135 18.4659 15.6291C16.8692 14.6876 15.0971 13.7185 12.9374 11.5219C10.7776 9.32519 10.4454 5.67946 13.3066 4.04581C14.8664 3.15976 17.0538 2.76289 18.9828 2.76289C18.7521 2.67982 18.5398 2.5506 18.3829 2.36601C18.1152 2.06143 17.986 1.6461 18.0321 1.23999C16.0755 1.35998 14.008 1.84915 12.3559 2.88287C8.68248 5.19029 9.04244 9.639 11.4514 12.1402C13.3066 14.0877 15.4663 15.7121 17.8476 16.9489C20.598 18.4256 23.5423 19.3486 25.019 18.8964C26.4958 18.4441 28.4894 17.2073 27.5018 14.3738H27.511Z"
                    fill="white"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M22.4718 6.90702C22.3518 6.6855 22.0934 6.08558 22.0934 6.08558C22.0934 6.08558 21.8719 6.10404 21.6227 6.12249C21.6042 6.38093 21.4288 6.63013 21.0873 6.73165C19.4075 7.22083 18.6784 7.28543 18.2631 7.47003C17.7277 7.70077 17.3678 8.62374 20.5336 11.9003C21.8903 13.3032 23.1733 14.503 22.5272 15.0661C22.038 15.4998 20.5797 14.1246 18.8538 12.251C16.0756 9.22367 16.8971 8.41145 16.0018 8.30993C15.1157 8.2084 13.3529 9.02984 13.0483 7.98689C12.8822 7.37773 13.2975 7.17468 15.5865 6.50091C16.9709 6.10403 18.3831 5.79023 19.8044 5.53179C19.6198 5.35643 19.4629 5.15338 19.3614 4.91341C19.0753 4.22118 19.2137 3.37205 19.7029 2.81827C19.4629 2.85519 19.2137 2.83673 18.983 2.75366C17.054 2.75366 14.8665 3.14131 13.3067 4.03659C10.4455 5.66101 10.7778 9.31596 12.9375 11.5126C15.0973 13.7093 16.8694 14.6876 18.4661 15.6198C20.8474 17.0043 22.6933 17.6134 24.0316 17.5027C25.2315 17.4012 26.819 16.5336 26.0345 14.5584C25.6837 13.6908 24.7423 13.1186 24.2162 12.1956C23.6993 11.2727 23.644 9.85128 23.7916 8.18071C23.7916 8.12533 23.8101 8.03304 23.8193 7.92228C23.8193 7.92228 23.8193 7.92228 23.8101 7.92228C23.2471 7.79307 22.7395 7.40542 22.4718 6.89779V6.90702Z"
                    fill={steakColor}
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M13.0569 7.97768C13.3615 9.02986 15.1152 8.19919 16.0104 8.30072C16.8965 8.40224 16.0843 9.21445 18.8624 12.2418C20.5884 14.1154 22.0466 15.4906 22.5358 15.0568C23.1819 14.4938 21.8897 13.294 20.5422 11.8911C17.3764 8.6053 17.7364 7.69156 18.2717 7.46082C18.6963 7.27622 19.4254 7.21161 21.096 6.72244C21.4375 6.62091 21.6128 6.37171 21.6313 6.11328C21.4467 6.12251 21.2529 6.13174 21.1144 6.12251C20.6253 6.09482 20.1638 5.86408 19.8131 5.52258C18.3917 5.78101 16.9795 6.09482 15.5951 6.4917C13.3061 7.16547 12.8908 7.36852 13.0569 7.97768Z"
                    fill="white"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2.34193 12.6109C2.93263 12.1863 7.50132 9.95273 11.387 11.9925C15.2727 14.0322 16.8233 16.4412 17.9401 20.0777C20.0168 19.367 21.9735 19.3485 24.004 21.7759C22.7118 21.3698 20.5982 22.5143 19.8691 23.6403C18.9923 24.9602 19.2322 26.4554 20.0721 28.1075C17.9862 27.6829 15.5127 26.4 16.048 23.2804C14.3313 24.1111 12.4023 24.4064 10.5102 24.1203C8.61811 23.8342 6.86447 22.9758 5.48002 21.6652C1.4005 17.8533 2.34193 12.6109 2.34193 12.6109Z"
                    fill="#F1C34A"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.82954 11.208C9.6879 10.9496 10.5555 10.7373 11.4323 10.5619C12.8629 10.2758 14.8565 11.4572 16.0564 12.5924C17.2655 13.7277 18.05 14.6322 18.1146 15.0568C18.1792 15.4813 17.2101 17.7888 17.2101 17.7888C17.2101 17.7888 15.4934 14.3184 13.029 12.9709C11.7 12.2233 10.2878 11.6326 8.82031 11.208H8.82954Z"
                    fill="#F2EBD1"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M3.87387 19.7178C4.27075 19.6624 6.652 19.6347 8.86712 17.1981C11.0822 14.7615 10.1962 11.6511 10.1962 11.6511C10.1962 11.6511 6.67046 10.2112 2.35097 12.6017C1.73258 16.3582 3.8831 19.7086 3.8831 19.7086L3.87387 19.7178Z"
                    fill="white"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.82694 14.3277C7.14075 14.3646 7.42687 14.1339 7.46379 13.8201C7.50071 13.5062 7.26997 13.2201 6.95616 13.1832C6.64235 13.1463 6.35623 13.377 6.31931 13.6908C6.28239 14.0046 6.51314 14.2908 6.82694 14.3277Z"
                    fill="#F1C34A"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.0852 15.0568C10.0852 15.0568 11.9773 15.8782 12.2726 18.9609C9.41142 20.2438 7.48242 18.4348 7.48242 18.4348C7.48242 18.4348 8.78381 18.121 10.0852 15.0568Z"
                    fill="#F2EBD1"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.4877 17.152C15.0784 17.6781 14.3862 18.9056 13.7401 18.7672C14.2293 19.3763 13.5832 20.6408 12.7064 20.5393C12.7987 20.6962 12.8541 20.8808 12.8449 21.0654C12.8449 21.25 12.7895 21.4345 12.688 21.5915C12.5864 21.7484 12.448 21.8683 12.2726 21.9514C12.1065 22.0345 11.9219 22.0622 11.7373 22.0345"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.5865 19.0255C16.1495 19.4962 15.485 20.7792 14.682 20.5946C15.0512 21.2776 14.5343 22.2374 13.7129 22.0898"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_10935_21594">
                    <rect width="26" height="27.3291" fill="white" transform="translate(2 1)" />
                </clipPath>
            </defs>
        </svg>
    ) : (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10935_21006)">
                <path
                    d="M25.4067 6.68546C25.4528 6.02092 25.5728 3.39969 23.6715 2.1906C21.7609 0.990745 16.0293 0.593869 12.3559 2.91052C8.68248 5.21794 9.04244 9.66664 11.4514 12.1679C13.3066 14.1153 15.4663 15.7398 17.8476 16.9765C20.598 18.4533 23.5423 19.3763 25.019 18.924C26.4958 18.4718 28.4894 17.235 27.5018 14.4015C27.0772 13.4231 26.0527 12.7586 25.5174 11.6695C24.9821 10.5804 25.2682 8.5037 25.3974 6.68546H25.4067Z"
                    fill="white"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M23.7916 8.21759C23.8378 7.68227 24.4377 4.66417 22.7764 3.55661C21.115 2.45828 16.1587 2.43059 13.3067 4.05501C10.4455 5.67943 10.7778 9.33439 12.9375 11.531C15.0973 13.7277 16.8694 14.7061 18.4661 15.6383C20.8474 17.0227 22.6933 17.6319 24.0316 17.5211C25.2315 17.4196 26.819 16.552 26.0345 14.5768C25.6837 13.7093 24.7423 13.137 24.2162 12.214C23.6993 11.2911 23.644 9.86971 23.7916 8.19914V8.21759Z"
                    fill={steakColor}
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeMiterlimit="10"
                />
                <path
                    d="M20.478 5.43022C21.7425 5.19948 21.9917 6.49163 21.0872 6.75929C19.4074 7.24847 18.6782 7.31307 18.2629 7.49767C17.7276 7.72841 17.3676 8.65138 20.5334 11.9371C21.8902 13.3401 23.1731 14.5399 22.527 15.1029C22.0379 15.5367 20.5796 14.1615 18.8536 12.2879C16.0755 9.26054 16.8969 8.44832 16.0016 8.3468C15.1156 8.24527 13.3527 9.06671 13.0481 8.01453C12.882 7.40537 13.2973 7.20232 15.5863 6.52855C17.1923 6.06707 18.8259 5.70711 20.478 5.43022Z"
                    fill="white"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2.34193 12.6386C2.93263 12.2141 7.50132 9.9805 11.387 12.0203C15.2727 14.06 16.8233 16.469 17.9401 20.1055C20.0168 19.3948 21.9735 19.3763 24.004 21.8037C22.7118 21.3976 20.5982 22.5421 19.8691 23.6681C18.9923 24.988 19.2322 26.4832 20.0721 28.1353C17.9862 27.7107 15.5127 26.4278 16.048 23.3082C14.3313 24.1388 12.4023 24.4342 10.5102 24.1481C8.61811 23.8619 6.86447 23.0036 5.48002 21.693C1.4005 17.8903 2.34193 12.6386 2.34193 12.6386Z"
                    fill="#F1C34A"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.82954 11.2449C9.6879 10.9865 10.5555 10.7742 11.4323 10.5988C12.8629 10.3127 14.8565 11.4941 16.0564 12.6294C17.2655 13.7646 18.05 14.6691 18.1146 15.0937C18.1792 15.5183 17.2101 17.8257 17.2101 17.8257C17.2101 17.8257 15.4934 14.3553 13.029 13.0078C11.7 12.2602 10.2878 11.6695 8.82031 11.2449H8.82954Z"
                    fill="#F2EBD1"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M3.87387 19.7455C4.27075 19.6901 6.652 19.6624 8.86712 17.2258C11.0822 14.7891 10.1962 11.6787 10.1962 11.6787C10.1962 11.6787 6.67046 10.2389 2.35097 12.6294C1.73258 16.3859 3.8831 19.7362 3.8831 19.7362L3.87387 19.7455Z"
                    fill="white"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.82694 14.3645C7.14075 14.4015 7.42687 14.1707 7.46379 13.8569C7.50071 13.5431 7.26997 13.257 6.95616 13.2201C6.64235 13.1831 6.35623 13.4139 6.31931 13.7277C6.28239 14.0415 6.51314 14.3276 6.82694 14.3645Z"
                    fill="#F1C34A"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.0852 15.0845C10.0852 15.0845 11.9773 15.9059 12.2726 18.9886C9.41142 20.2716 7.48242 18.4625 7.48242 18.4625C7.48242 18.4625 8.78381 18.1487 10.0852 15.0845Z"
                    fill="#F2EBD1"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.4877 17.1796C15.0784 17.7057 14.3862 18.9333 13.7401 18.7948C14.2293 19.404 13.5832 20.6684 12.7064 20.5669C12.7987 20.7238 12.8541 20.9084 12.8449 21.093C12.8449 21.2776 12.7895 21.4622 12.688 21.6191C12.5864 21.776 12.448 21.896 12.2726 21.9791C12.1065 22.0621 11.9219 22.0898 11.7373 22.0621"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.5865 19.0532C16.1495 19.5239 15.485 20.8069 14.682 20.6223C15.0512 21.3053 14.5343 22.2651 13.7129 22.1175"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_10935_21006">
                    <rect width="26" height="27.3568" fill="white" transform="translate(2 1)" />
                </clipPath>
            </defs>
        </svg>
    );
}

MeatAndSeafood.propTypes = {
    brand: PropTypes.oneOf([IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN]).isRequired,
    isActive: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

MeatAndSeafood.defaultProps = {
    isActive: false,
    width: 30,
};
