import PropTypes from 'prop-types';

import { IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN } from 'constants/theme';

export function PreparedFoods({ brand, isActive, width }) {
    const outerBowlColor = brand === MISFITS_THEME_DOMAIN ? '#DC6B31' : '#DD3D96';
    const innerBowlColor = brand === MISFITS_THEME_DOMAIN ? '#F2EBD1' : '#ECE7E4';
    const activeColor = brand === MISFITS_THEME_DOMAIN ? '#D7E1B8' : '#F0D3E3';

    return isActive ? (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.6505 28.7652C21.902 26.9646 29 22.6358 29 14.9992C29 7.3625 22.9452 2.7363 14.6505 1.1728C7.31233 -0.213857 2.48978 6.96057 1.13553 14.967C-0.132106 22.475 7.73356 30.4815 14.6505 28.7652Z"
                fill={activeColor}
            />
            <path
                d="M4.0092 12.9156C4.0092 12.9156 4.3772 18.6288 8.8392 21.3612L10.7804 22.456C10.7804 22.456 10.44 24.1396 10.7804 24.2224C11.1208 24.3052 19.0788 24.6456 19.2904 24.2224C19.502 23.7992 19.1248 22.41 19.502 22.3732C19.8792 22.3364 22.7036 21.5728 24.47 18.2424C26.2364 14.912 26.172 12.658 26.172 12.658C26.172 12.658 11.5532 7.76356 4 12.9064L4.0092 12.9156Z"
                fill={outerBowlColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.00926 12.9153C3.93566 13.9549 6.66806 14.7553 14.3133 14.8933C14.5893 14.8933 14.8745 14.8933 15.1597 14.9025C16.1901 14.9117 17.1469 14.9025 18.0209 14.8657C24.1481 14.6081 26.4941 13.2833 26.1445 12.5749C25.7397 11.7653 22.0321 9.87015 13.3841 10.1829C13.3841 10.1829 4.23006 9.90695 4.00926 12.9153Z"
                fill={innerBowlColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.6689 14.8566C14.1289 12.5566 16.1253 12.6302 16.9165 12.1702C17.8549 11.6366 23.2461 1.91224 23.8993 1.97664C24.5433 2.04104 25.9233 2.20664 25.7301 2.62984C25.5369 3.05304 19.3361 11.9402 19.1981 12.5566C20.1181 13.9366 20.5781 13.4766 19.6581 14.8566"
                fill="white"
            />
            <path
                d="M13.6689 14.8566C14.1289 12.5566 16.1253 12.6302 16.9165 12.1702C17.8549 11.6366 23.2461 1.91224 23.8993 1.97664C24.5433 2.04104 25.9233 2.20664 25.7301 2.62984C25.5369 3.05304 19.3361 11.9402 19.1981 12.5566C20.1181 13.9366 20.5781 13.4766 19.6581 14.8566C17.8181 15.3166 13.6781 14.8566 13.6781 14.8566H13.6689Z"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.56641 16.1079C6.56641 16.1079 7.23801 18.2975 9.68521 19.6039"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ) : (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.0092 12.9059C4.0092 12.9059 4.3772 18.6191 8.8392 21.3515L10.7804 22.4463C10.7804 22.4463 10.44 24.1299 10.7804 24.2127C11.1208 24.2955 19.0788 24.6359 19.2904 24.2127C19.502 23.7895 19.1248 22.4003 19.502 22.3635C19.8792 22.3267 22.7036 21.5631 24.47 18.2327C26.2364 14.9023 26.172 12.6483 26.172 12.6483C26.172 12.6483 11.5532 7.75391 4 12.8967L4.0092 12.9059Z"
                fill={outerBowlColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.00926 12.9061C3.93566 13.9457 6.66806 14.7461 14.3133 14.8841C14.5893 14.8841 14.8745 14.8841 15.1597 14.8933C16.1901 14.9025 17.1469 14.8933 18.0209 14.8565C24.1481 14.5989 26.4941 13.2741 26.1445 12.5657C25.7397 11.7561 22.0321 9.86087 13.3841 10.1737C13.3841 10.1737 4.23006 9.89767 4.00926 12.9061Z"
                fill={innerBowlColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.82 8.39228C13.9592 6.93064 14.7762 7.74625 15.6184 7.73817C16.6038 7.73817 25.2788 2.61838 25.7673 2.94948C26.2557 3.28057 27.2748 3.99927 26.9295 4.24154C26.5842 4.4838 17.6482 8.61032 17.2608 9.01408C17.7661 12.2039 14.1277 13.617 12.4685 13.9239C10.5061 14.2954 9.26802 12.5188 9.5628 11.4044C10.1018 9.35325 11.8284 8.39228 11.8284 8.39228H11.82Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.56641 16.0892C6.56641 16.0892 7.23801 18.2788 9.68521 19.5852"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

PreparedFoods.propTypes = {
    brand: PropTypes.oneOf([IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN]).isRequired,
    isActive: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PreparedFoods.defaultProps = {
    isActive: false,
    width: 30,
};
