import PropTypes from 'prop-types';

function MajorAllergenFreeIcon({ className, width }) {
    return (
        <svg width={width} className={className} viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.54199 2.62139C6.00213 3.6553 5.92125 4.87046 5.46227 5.94335C4.14284 8.76931 0.394059 10.6179 1.08398 14.1883C1.14148 16.0837 2.9881 17.6194 4.5794 18.363C6.60533 18.9077 9.11069 18.7186 10.6591 17.1585C13.2775 15.2261 12.8897 11.6128 12.8254 8.743C12.993 6.97531 14.1964 5.35769 14.0259 3.59098C13.6556 -0.263044 8.13329 -0.427729 6.54101 2.62139L6.54199 2.62139Z"
                className="fill-[#DEA455] if:fill-[#F6A685]"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.8296 0.527222C10.2547 0.601281 9.8912 0.855618 9.77037 0.94332C8.64486 1.75895 8.09331 3.14562 7.7464 4.49136C7.39948 5.8371 7.18802 7.24619 6.50784 8.45843C5.81305 9.69503 4.68266 10.6295 3.88945 11.8057C2.84287 13.3571 2.43067 15.3226 2.76491 17.1634"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.93579 4.25464C7.13049 4.77403 8.42556 5.05858 9.72745 5.08878"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.64575 7.23743C5.61827 8.1544 6.97376 8.51593 8.2698 8.8453"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.31299 11.7833C2.33423 13.3571 5.0949 14.2526 6.36853 14.2906"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.9397 1.99288C7.96289 1.81747 9.03481 1.94025 9.99076 2.34368"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.576 2.66821C12.9511 3.30746 13.0505 4.08607 12.9404 4.81984C12.8303 5.55362 12.5243 6.24646 12.1365 6.87792"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.17952 7.5C8.47596 9.39339 8.37851 9.41581 7.482 10.7323C7.024 11.4037 6.38085 11.926 5.87022 12.5585C4.94058 13.7113 4.48453 15.2344 4.62875 16.7087"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.24147 14.1055C7.55447 15.2768 6.66965 16.3331 5.63574 17.214"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="13"
                cy="15.5"
                r="5.72"
                className="fill-[#DC6B31] if:fill-[#DD3D96]"
                stroke="#2D2D2D"
                strokeWidth="0.56"
            />
            <path
                d="M13.0315 16.6089L11.8546 18.5L10 18.5L12.0467 15.329L10.2114 12.5L11.9771 12.5L13.0138 14.165L14.0498 12.5L15.8331 12.5L13.9617 15.3282L16 18.4992L14.2075 18.4992L13.0306 16.6081L13.0315 16.6089Z"
                fill="white"
            />
        </svg>
    );
}

export default MajorAllergenFreeIcon;

MajorAllergenFreeIcon.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number.isRequired,
};

MajorAllergenFreeIcon.defaultProps = {
    className: null,
};
