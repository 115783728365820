import PropTypes from 'prop-types';

function NextIcon({ disabled, width }) {
    return disabled ? (
        <svg width={width} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.06397 11.7941L1.05753 11.8311L1.0539 11.8685C0.657329 15.9492 2.49566 18.877 4.87 20.6657C7.17206 22.3999 10.0718 23.1333 12.042 22.7889C17.7592 22.0599 23.3459 18.1227 23.3459 11.9883C23.3459 8.97042 22.107 6.51654 20.0492 4.69078C18.0153 2.88615 15.2142 1.71923 12.0732 1.13942C8.99362 0.570724 6.4466 1.7875 4.59239 3.86541C2.76543 5.91277 1.58218 8.8113 1.06397 11.7941Z"
                fill="#C3C3C3"
                stroke="#C3C3C3"
                strokeWidth="2"
            />
            <path
                d="M9.93457 7.69922L15.3491 11.9291L9.93457 16.1591"
                stroke="#2D2D2D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ) : (
        <svg width={width} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.06397 12.6691L1.05753 12.7061L1.0539 12.7435C0.657329 16.8242 2.49566 19.752 4.87 21.5407C7.17206 23.2749 10.0718 24.0083 12.042 23.6639C17.7592 22.9349 23.3459 18.9977 23.3459 12.8633C23.3459 9.84542 22.107 7.39154 20.0492 5.56578C18.0153 3.76115 15.2142 2.59423 12.0732 2.01442C8.99362 1.44572 6.4466 2.6625 4.59239 4.74041C2.76543 6.78777 1.58218 9.6863 1.06397 12.6691Z"
                fill="#F1C34A"
                stroke="#2D2D2D"
                strokeWidth="2"
            />
            <path
                d="M9.93481 8.57397L15.3493 12.8039L9.93481 17.0338"
                stroke="#2D2D2D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default NextIcon;

NextIcon.propTypes = {
    width: PropTypes.number,
};

NextIcon.defaultProps = {
    width: 23,
};
