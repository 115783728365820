import PropTypes from 'prop-types';

import { IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN } from 'constants/theme';

export function Household({ brand, isActive, width }) {
    const mittColor = brand === MISFITS_THEME_DOMAIN ? '#DEA455' : '#DD3D96';
    const potColor = brand === MISFITS_THEME_DOMAIN ? '#F2EBD1' : '#ECE7E4';
    const activeColor = brand === MISFITS_THEME_DOMAIN ? '#D7E1B8' : '#F0D3E3';

    return isActive ? (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10935_21678)">
                <path
                    d="M14.6505 28.7652C21.902 26.9646 29 22.6358 29 14.9992C29 7.3625 22.9452 2.7363 14.6505 1.1728C7.31233 -0.213857 2.48978 6.96057 1.13553 14.967C-0.132106 22.475 7.73356 30.4815 14.6505 28.7652Z"
                    fill={activeColor}
                />
                <g clipPath="url(#clip1_10935_21678)">
                    <path
                        d="M12.3135 12.9295C13.2629 12.6373 15.8563 12.0684 15.8563 12.0684C15.9813 12.037 16.1146 12.0566 16.2282 12.1209C16.3419 12.1853 16.417 12.2904 16.4464 12.4247L16.7065 13.4751C16.7379 13.6001 16.7184 13.7334 16.654 13.847C16.5896 13.9607 16.4845 14.0358 16.3502 14.0652C16.3502 14.0652 14.2058 14.6937 12.795 14.9825L12.3229 12.9315L12.3135 12.9295Z"
                        fill="white"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M6.314 4.86172C6.24828 4.1786 6.39919 3.49408 6.7273 2.89779C6.94939 2.42561 7.28718 2.00844 7.7022 1.68696C8.11722 1.36548 8.60741 1.14907 9.12174 1.04614C9.71618 0.892037 10.3421 0.862865 10.9505 0.957662C11.5589 1.05246 12.1383 1.27853 12.6649 1.61093C13.8067 2.45263 14.2395 4.01312 14.5337 6.20188C14.7278 5.81203 15.0168 5.48243 15.3757 5.23707C15.6202 5.06481 15.9158 4.97266 16.221 4.97111C16.5261 4.96955 16.8179 5.07321 17.0651 5.24569C17.7804 5.74754 18.4383 6.77752 17.3773 8.2447C16.3185 9.7025 15.4752 10.5392 15.5245 10.7172L15.8861 12.0654L11.3437 13.0895L6.32131 4.87316L6.32544 4.85441L6.314 4.86172Z"
                        fill={mittColor}
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M12.975 15.9071C12.975 15.9071 12.6838 14.3286 12.2007 12.5505C11.6011 10.2748 11.26 8.07573 10.9599 7.34094C10.619 6.56767 10.0919 5.88116 9.42031 5.35945C8.999 5.04042 8.51331 4.83501 7.99344 4.74007C7.47151 4.6545 6.94352 4.68559 6.4376 4.83954C5.83585 4.9822 5.27493 5.25185 4.7798 5.6245C4.28467 5.99714 3.87615 6.46755 3.57188 7.00027C2.96682 8.27296 3.33505 9.85856 4.11964 11.9194C3.76095 11.6732 3.35345 11.5145 2.92322 11.459C2.62633 11.423 2.32021 11.4736 2.05175 11.6209C1.78329 11.7682 1.56806 11.9862 1.43834 12.2624C1.0293 13.0474 0.939682 14.2567 2.56265 15.057C4.18562 15.8572 5.32245 16.1865 5.36026 16.3719C5.39806 16.5572 5.6802 17.7305 5.6802 17.7305L12.975 15.9071Z"
                        fill={mittColor}
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M6.17378 19.7274C6.17378 19.7274 9.72706 18.908 11.1064 18.4943C11.6956 18.3193 13.4779 17.906 13.4779 17.906C13.6029 17.8745 13.71 17.79 13.7723 17.6857C13.8367 17.5721 13.8563 17.4388 13.8248 17.3138L13.5647 16.2634C13.5333 16.1385 13.4581 16.0334 13.3445 15.969C13.2309 15.9046 13.0975 15.8851 12.9726 15.9165C12.9726 15.9165 9.35809 16.8797 8.13473 17.1213C7.15708 17.3188 5.66843 17.7379 5.66843 17.7379C5.54351 17.7693 5.43637 17.8539 5.36468 17.9561C5.3003 18.0697 5.28073 18.203 5.31219 18.328L5.57225 19.3784C5.6037 19.5033 5.68824 19.6105 5.79043 19.6822C5.90406 19.7466 6.03741 19.7661 6.16234 19.7347L6.17378 19.7274Z"
                        fill={mittColor}
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M8.83722 10.5013C8.80148 10.3066 7.2874 7.98659 6.91113 7.19568L8.83722 10.5013Z"
                        fill="white"
                    />
                    <path
                        d="M8.83722 10.5013C8.80148 10.3066 7.2874 7.98659 6.91113 7.19568"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M7.96239 11.7047C7.96239 11.7047 6.47459 8.90863 5.55371 7.55518L7.96239 11.7047Z"
                        fill="white"
                    />
                    <path
                        d="M7.96239 11.7047C7.96239 11.7047 6.47459 8.90863 5.55371 7.55518"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M9.15786 8.24181C8.26514 9.39209 7.31505 10.4904 6.29614 11.544L9.15786 8.24181Z"
                        fill="white"
                    />
                    <path
                        d="M9.15786 8.24181C8.26514 9.39209 7.31505 10.4904 6.29614 11.544"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M8.19437 7.26224C7.40576 8.38631 6.47951 9.42104 5.44473 10.3237L8.19437 7.26224Z"
                        fill="white"
                    />
                    <path
                        d="M8.19437 7.26224C7.40576 8.38631 6.47951 9.42104 5.44473 10.3237"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M9.68367 12.5465L9.54458 14.0694C9.54458 14.0694 9.37635 17.464 9.04331 19.4653C8.87933 20.4321 8.68016 23.3871 8.68016 23.3871C8.68016 23.3871 9.21621 25.0589 11.8007 26.0023C13.189 26.5739 14.6529 26.9359 16.1415 27.097C17.951 27.3189 19.7827 27.1721 21.5347 26.6734C23.4045 26.2203 23.9763 25.0091 24.2103 24.7952C24.3694 24.6533 24.7993 22.6144 24.9579 20.2895C25.1526 17.1761 25.6944 13.5591 25.6944 13.5591"
                        fill="white"
                    />
                    <path
                        d="M9.68367 12.5465L9.54458 14.0694C9.54458 14.0694 9.37635 17.464 9.04331 19.4653C8.87933 20.4321 8.68016 23.3871 8.68016 23.3871C8.68016 23.3871 9.21621 25.0589 11.8007 26.0023C13.189 26.5739 14.6529 26.9359 16.1415 27.097C17.951 27.3189 19.7827 27.1721 21.5347 26.6734C23.4045 26.2203 23.9763 25.0091 24.2103 24.7952C24.3694 24.6533 24.7993 22.6144 24.9579 20.2895C25.1526 17.1761 25.6944 13.5591 25.6944 13.5591"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M25.7045 13.5611C25.601 14.5215 24.5837 15.3002 23.0264 15.7632C21.469 16.2261 19.4871 16.2513 17.28 16.1384C15.7977 16.0378 14.3484 15.6986 12.9789 15.1312C11.2426 14.4436 10.0194 13.5249 9.67509 12.5444C9.58723 12.3186 9.56183 12.077 9.58538 11.8364C9.71251 10.6353 11.2317 9.80994 13.416 9.35738C14.9247 9.07052 16.4775 9.02938 18.0026 9.24762C19.335 9.40369 20.6478 9.69311 21.9222 10.1117C24.5003 10.9062 25.8263 12.2507 25.6952 13.559L25.7045 13.5611Z"
                        fill={potColor}
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M22.3917 15.9184C23.0476 15.7975 20.0751 13.8934 16.6773 13.695C13.2796 13.4966 12.332 14.8511 12.332 14.8511C12.332 14.8511 14.7352 15.725 15.6709 15.9411C17.8889 16.3613 20.1719 16.3533 22.3823 15.9163L22.3917 15.9184Z"
                        fill="white"
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M8.94323 13.3664L7.07861 13.1716C6.72339 13.1326 6.39992 13.3956 6.36094 13.7509L6.31687 14.1738C6.27789 14.529 6.54089 14.8525 6.89611 14.8915L8.76073 15.0862C9.11595 15.1252 9.43942 14.8622 9.4784 14.507L9.52247 14.084C9.56145 13.7288 9.29845 13.4054 8.94323 13.3664Z"
                        fill={potColor}
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M28.0624 15.3985L26.1977 15.2037C25.8425 15.1647 25.5191 15.4277 25.4801 15.783L25.436 16.2059C25.397 16.5611 25.66 16.8846 26.0152 16.9236L27.8799 17.1183C28.2351 17.1573 28.5586 16.8943 28.5975 16.5391L28.6416 16.1161C28.6806 15.7609 28.4176 15.4375 28.0624 15.3985Z"
                        fill={potColor}
                        stroke="#2D2D2D"
                        strokeWidth="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_10935_21678">
                    <rect width="30" height="30" fill="white" />
                </clipPath>
                <clipPath id="clip1_10935_21678">
                    <rect width="28" height="24.9945" fill="white" transform="translate(4 -0.830444) rotate(12.4326)" />
                </clipPath>
            </defs>
        </svg>
    ) : (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10935_21152)">
                <path
                    d="M12.0811 14.6475C12.9453 14.1578 15.3554 13.0439 15.3554 13.0439C15.4706 12.9863 15.6051 12.9767 15.7299 13.0151C15.8547 13.0535 15.9507 13.1399 16.0084 13.2648L16.4885 14.2346C16.5461 14.3498 16.5557 14.4842 16.5173 14.6091C16.4789 14.7339 16.3924 14.8299 16.2676 14.8875C16.2676 14.8875 14.3088 15.963 12.9933 16.5487L12.0907 14.6475H12.0811Z"
                    fill="white"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.48514 8.06038C4.27389 7.40743 4.27389 6.70647 4.46594 6.05352C4.58116 5.54461 4.82122 5.0645 5.15729 4.6612C5.49337 4.25791 5.92547 3.94104 6.40558 3.72979C6.95291 3.45133 7.55784 3.28809 8.17238 3.24968C8.78692 3.21127 9.40146 3.30729 9.9872 3.51854C11.2835 4.09467 12.0421 5.5254 12.8006 7.59947C12.9063 7.17698 13.1175 6.79289 13.4152 6.47602C13.6168 6.25517 13.8857 6.10153 14.1834 6.03432C14.481 5.9671 14.7883 6.00551 15.0668 6.12074C15.8733 6.45681 16.7375 7.32101 16.0174 8.98219C15.2972 10.6338 14.6539 11.6324 14.7403 11.7956L15.3836 13.0343L11.1683 15.0124L4.49474 8.06998V8.05078L4.48514 8.06038Z"
                    fill={mittColor}
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M13.3681 17.4129C13.3681 17.4129 12.7439 15.9342 11.8893 14.3018C10.8139 12.2085 10.0073 10.1345 9.556 9.48151C9.05668 8.79975 8.39413 8.24283 7.62595 7.87794C7.14584 7.65709 6.62733 7.56107 6.09921 7.58028C5.57108 7.60908 5.06217 7.75312 4.60126 8.01237C4.04434 8.28124 3.55462 8.66532 3.15133 9.13583C2.74804 9.60634 2.45037 10.1537 2.26793 10.7394C1.95106 12.1125 2.65202 13.5816 3.86189 15.4253C3.4586 15.262 3.0265 15.1948 2.5944 15.2332C2.29674 15.262 2.00867 15.3773 1.77822 15.5789C1.54777 15.7806 1.38453 16.0398 1.31731 16.3375C1.08686 17.1921 1.2597 18.3923 3.0169 18.8244C4.7741 19.2565 5.95517 19.3334 6.03199 19.5062C6.10881 19.679 6.63693 20.7641 6.63693 20.7641L13.3681 17.4129Z"
                    fill={mittColor}
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.54901 22.6077C7.54901 22.6077 10.8426 21.0425 12.1004 20.3416C12.6382 20.0439 14.2897 19.2565 14.2897 19.2565C14.405 19.1989 14.4914 19.0933 14.5298 18.9781C14.5682 18.8532 14.5586 18.7188 14.501 18.6036L14.0209 17.6338C13.9633 17.5185 13.8673 17.4321 13.7424 17.3937C13.6176 17.3553 13.4832 17.3649 13.3679 17.4225C13.3679 17.4225 10.0456 19.1413 8.90291 19.6406C7.99071 20.0439 6.62719 20.7737 6.62719 20.7737C6.51197 20.8313 6.42555 20.9369 6.37754 21.0521C6.33913 21.177 6.34873 21.3114 6.40634 21.4266L6.88645 22.3965C6.94407 22.5117 7.04969 22.5981 7.16492 22.6461C7.28975 22.6845 7.42418 22.6749 7.5394 22.6173L7.54901 22.6077Z"
                    fill={mittColor}
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.1629 13.0247C8.08609 12.8422 6.10804 10.9026 5.57031 10.2112L8.1629 13.0247Z"
                    fill="white"
                />
                <path
                    d="M8.1629 13.0247C8.08609 12.8422 6.10804 10.9026 5.57031 10.2112"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.56781 14.3882C7.56781 14.3882 5.51294 11.9781 4.32227 10.8546L7.56781 14.3882Z"
                    fill="white"
                />
                <path
                    d="M7.56781 14.3882C7.56781 14.3882 5.51294 11.9781 4.32227 10.8546"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.98993 10.749C7.36578 12.0645 6.67443 13.3416 5.90625 14.5898L7.98993 10.749Z"
                    fill="white"
                />
                <path
                    d="M7.98993 10.749C7.36578 12.0645 6.67443 13.3416 5.90625 14.5898"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M6.83856 10C6.31044 11.2675 5.62869 12.4774 4.8125 13.5816L6.83856 10Z" fill="white" />
                <path
                    d="M6.83856 10C6.31044 11.2675 5.62869 12.4774 4.8125 13.5816"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.43066 14.8395L9.62271 16.3567C9.62271 16.3567 10.1892 19.7078 10.2949 21.7339C10.3429 22.7133 10.7846 25.642 10.7846 25.642C10.7846 25.642 11.668 27.1591 14.395 27.524C15.8737 27.7833 17.3813 27.8217 18.8696 27.6584C20.6844 27.4856 22.4416 26.9479 24.0452 26.0837C25.7736 25.2387 26.0713 23.9328 26.2537 23.6735C26.3785 23.5007 26.3593 21.417 26.0137 19.1125C25.5335 16.0302 25.2839 12.3813 25.2839 12.3813"
                    fill="white"
                />
                <path
                    d="M9.43066 14.8395L9.62271 16.3567C9.62271 16.3567 10.1892 19.7078 10.2949 21.7339C10.3429 22.7133 10.7846 25.642 10.7846 25.642C10.7846 25.642 11.668 27.1591 14.395 27.524C15.8737 27.7833 17.3813 27.8217 18.8696 27.6584C20.6844 27.4856 22.4416 26.9479 24.0452 26.0837C25.7736 25.2387 26.0713 23.9328 26.2537 23.6735C26.3785 23.5007 26.3593 21.417 26.0137 19.1125C25.5335 16.0302 25.2839 12.3813 25.2839 12.3813"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M25.2938 12.3813C25.3995 13.3415 24.5737 14.321 23.1526 15.1083C21.7314 15.8957 19.8014 16.347 17.6217 16.7119C16.1526 16.9328 14.6642 16.9136 13.2047 16.6543C11.3611 16.3566 9.96874 15.7229 9.42141 14.8395C9.28698 14.6378 9.21017 14.4074 9.18136 14.1673C9.04693 12.9671 10.3528 11.834 12.3885 10.9218C13.8 10.3169 15.3076 9.94237 16.8439 9.82714C18.1786 9.69271 19.5229 9.69271 20.8576 9.82714C23.5462 10.048 25.1306 11.0754 25.2842 12.3813H25.2938Z"
                    fill={potColor}
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M22.5662 15.3964C23.1807 15.1371 19.868 13.9177 16.5072 14.4554C13.1464 14.9931 12.5127 16.5199 12.5127 16.5199C12.5127 16.5199 15.0477 16.8559 16.0079 16.8655C18.2644 16.7983 20.4921 16.299 22.5566 15.3964H22.5662Z"
                    fill="white"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.88316 15.7997L7.02033 16.011C6.66505 16.0494 6.40579 16.3759 6.4442 16.7311L6.49221 17.1536C6.53062 17.5089 6.8571 17.7682 7.21238 17.7298L9.0752 17.5185C9.43049 17.4801 9.68974 17.1536 9.65134 16.7984L9.60332 16.3759C9.56492 16.0206 9.23844 15.7613 8.88316 15.7997Z"
                    fill={potColor}
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M27.9916 13.668L26.1287 13.8793C25.7735 13.9177 25.5142 14.2442 25.5526 14.5994L25.6006 15.0219C25.639 15.3772 25.9655 15.6365 26.3208 15.5981L28.1836 15.3868C28.5389 15.3484 28.7981 15.0219 28.7597 14.6666L28.7117 14.2442C28.6733 13.8889 28.3468 13.6296 27.9916 13.668Z"
                    fill={potColor}
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_10935_21152">
                    <rect width="28" height="24.9945" fill="white" transform="translate(1 3)" />
                </clipPath>
            </defs>
        </svg>
    );
}

Household.propTypes = {
    brand: PropTypes.oneOf([IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN]).isRequired,
    isActive: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Household.defaultProps = {
    isActive: false,
    width: 30,
};
