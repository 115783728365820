import PropTypes from 'prop-types';

function MemberDeals({ className, width }) {
    return (
        <svg className={className} width={width} viewBox="-5 -3 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.99213 11.4373L2.97461 8.63965L24.4895 10.1353L26.507 12.9329L4.99213 11.4373Z"
                fill="#6784AC"
                stroke="#2D2D2D"
                strokeWidth="1.7"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26.5076 12.9358L4.99072 11.439L3.49386 32.9558L25.0107 34.4527L26.5076 12.9358Z"
                fill="url(#paint0_linear_2218_86733)"
                stroke="#2D2D2D"
                strokeWidth="1.7"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M34.4102 13.4832L26.5098 12.9336L25.0129 34.4505L32.9133 35.0001L34.4102 13.4832Z"
                fill="url(#paint1_linear_2218_86733)"
                stroke="#2D2D2D"
                strokeWidth="1.7"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M36.6854 18.9539L28.7875 18.4051L26.5063 12.9336L34.4097 13.4824L36.6854 18.9539Z"
                fill="#2D2D2D"
                stroke="#2D2D2D"
                strokeWidth="1.7"
                strokeMiterlimit="10"
                strokeLinejoin="round"
            />
            <path
                d="M21.5142 5.63223C21.5626 4.19575 21.5841 1 21.5841 1"
                stroke="#2D2D2D"
                strokeWidth="1.7"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M28.7983 7.58595C29.4117 6.8704 30.7136 5.23486 30.7136 5.23486"
                stroke="#2D2D2D"
                strokeWidth="1.7"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.9265 5.40698C12.5392 4.53541 11.603 2.62549 11.603 2.62549"
                stroke="#2D2D2D"
                strokeWidth="1.7"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_2218_86733"
                    x1="2.50839"
                    y1="22.354"
                    x2="32.7932"
                    y2="25.9064"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F594A4" />
                    <stop offset="0.579984" stopColor="#A13051" />
                    <stop offset="1" stopColor="#44638D" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_2218_86733"
                    x1="20.3121"
                    y1="24.1165"
                    x2="34.6985"
                    y2="26.024"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A13051" />
                    <stop offset="0.7848" stopColor="#6784AC" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export { MemberDeals };

MemberDeals.propTypes = {
    className: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

MemberDeals.defaultProps = {
    className: null,
    width: 28,
};
