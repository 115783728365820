import { useQuery } from '@tanstack/react-query';

import { getCartUpSellsCarousel, getPDPUpSellsCarousel, getPLPUpSellsCarousel } from 'api/jalapeno';

import useAccountQuery from './useAccountQuery';

export const UP_SELLS_CAROUSEL_QUERY_KEY = 'upsells-carousel';

const useUpSellsCarouselQuery = ({ position, sellableItemID, type }) => {
    const { data: account } = useAccountQuery();

    const query = useQuery({
        queryKey: [UP_SELLS_CAROUSEL_QUERY_KEY, account?.nextOrder?.chargeID, type, position],
        queryFn: async () => {
            let response = {};

            if (sellableItemID) {
                if (position === 'inline') {
                    response = await getPDPUpSellsCarousel({ chargeID: account?.nextOrder?.chargeID, sellableItemID });
                } else {
                    response = await getPLPUpSellsCarousel({ chargeID: account?.nextOrder?.chargeID, sellableItemID });
                }
            } else {
                response = await getCartUpSellsCarousel({ chargeID: account?.nextOrder?.chargeID, type });
            }

            return response?.data;
        },
        enabled: !!account?.nextOrder?.chargeID,
        refetchOnWindowFocus: false,
    });

    return query;
};

export default useUpSellsCarouselQuery;
