import PropTypes from 'prop-types';

function WineABVIcon({ height, width }) {
    return (
        <svg fill="none" height={height} width={width} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4060_68054)">
                <path
                    d="M17.188 2.47916C19.8162 2.9259 21.9996 2.977 22.0648 2.5933C22.13 2.2096 20.0524 1.53639 17.4242 1.08965C14.7961 0.642916 12.6127 0.591814 12.5474 0.975515C12.4822 1.35922 14.5599 2.03242 17.188 2.47916Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M12.5468 0.975694C12.5468 0.975694 8.98048 5.1761 9.49287 8.87515C10.0053 12.5742 13.6078 13.4715 13.7505 14.5893C13.8932 15.707 14.2436 17.002 13.671 20.0902C13.0983 23.1785 12.6351 25.9038 12.3337 26.2807C12.0318 26.6574 11.0256 27.2629 9.59571 27.0076C8.62304 26.8341 8.33008 27.0897 8.02515 27.5764C7.85023 27.8561 10.0353 28.473 12.9327 28.9708C15.8273 29.468 17.9088 29.6503 17.9013 29.414C17.8937 29.1777 17.6003 28.2007 16.3284 28.1387C15.7643 28.1108 14.8887 27.7218 14.4721 27.4049C13.8508 26.9329 13.5742 26.2542 13.747 25.2378C13.9197 24.2214 14.6844 18.8846 15.2205 17.4071C15.7567 15.9295 16.1789 15.126 17.1109 14.676C18.043 14.226 20.5154 13.6726 21.5785 12.3522C22.4854 11.2261 22.8639 10.197 22.819 7.38474C22.7742 4.57248 22.0646 2.59471 22.0646 2.59471"
                    fill="#E5F2E0"
                />
                <path
                    d="M12.5468 0.975694C12.5468 0.975694 8.98048 5.1761 9.49287 8.87515C10.0053 12.5742 13.6078 13.4715 13.7505 14.5893C13.8932 15.707 14.2436 17.002 13.671 20.0902C13.0983 23.1785 12.6351 25.9038 12.3337 26.2807C12.0318 26.6574 11.0256 27.2629 9.59571 27.0076C8.62304 26.8341 8.33008 27.0897 8.02515 27.5764C7.85023 27.8561 10.0353 28.473 12.9327 28.9708C15.8273 29.468 17.9088 29.6503 17.9013 29.414C17.8937 29.1777 17.6003 28.2007 16.3284 28.1387C15.7643 28.1108 14.8887 27.7218 14.4721 27.4049C13.8508 26.9329 13.5742 26.2542 13.747 25.2378C13.9197 24.2214 14.6844 18.8846 15.2205 17.4071C15.7567 15.9295 16.1789 15.126 17.1109 14.676C18.043 14.226 20.5154 13.6726 21.5785 12.3522C22.4854 11.2261 22.8639 10.197 22.819 7.38474C22.7742 4.57248 22.0646 2.59471 22.0646 2.59471C20.9296 1.71322 15.2465 1.14808 12.5468 0.975694Z"
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.9625 6.19686C10.7736 6.26422 10.0897 8.26568 10.7456 9.72075C11.4579 11.2994 12.7227 12.4334 15.2078 12.729C17.6929 13.0246 19.8523 12.4407 20.5747 11.3595C21.2971 10.2783 21.5847 8.77427 21.6266 8.5278C21.0341 8.65683 19.5329 9.47128 17.2504 8.54471C14.968 7.61814 14.2004 5.04714 10.9625 6.19686Z"
                    fill="#B22624"
                />
            </g>
            <defs>
                <clipPath id="clip0_4060_68054">
                    <rect width="30" height="30" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default WineABVIcon;

WineABVIcon.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
};

WineABVIcon.defaultProps = {
    height: 30,
    width: 30,
};
