import { ifLinks, mmLinks } from 'constants/links';
import { SignupQuizSteps, SignupQuizStepsOrdered } from 'constants/signup';

import { getCrossDomainDeviceId } from './analyticsUtils';

export const SIGNUP_LOCAL_STORAGE_KEY = 'signup';

export const removeSignupLocalStorage = () => {
    localStorage.removeItem(SIGNUP_LOCAL_STORAGE_KEY);
};

export const getSignupLocalStorage = () => JSON.parse(localStorage.getItem(SIGNUP_LOCAL_STORAGE_KEY)) ?? {};

export const setSignupLocalStorage = (data) => {
    const signupLocalStorage = getSignupLocalStorage();

    localStorage.setItem(
        SIGNUP_LOCAL_STORAGE_KEY,
        JSON.stringify({
            ...signupLocalStorage,
            ...data,
        })
    );
};

/**
 * Get a list of ordered quiz paths.
 */
export const getQuizStepsOrdered = () => SignupQuizStepsOrdered;

/**
 * Get a list of allowed quiz paths for a given quiz step.
 * @param {Object} quizStep - The quiz step to get allowed paths for.
 * @returns {string[]} A list of allowed quiz paths.
 */
export const getQuizAllowedPaths = (quizStep) => {
    const quizSteps = getQuizStepsOrdered();
    const stepIndex = quizSteps.indexOf(quizStep);

    if (stepIndex === -1) {
        return [];
    }

    const stepsSlice = quizSteps.slice(stepIndex + 1, quizSteps.length);

    const stepsFiltered = stepsSlice.filter((step) => step !== SignupQuizSteps.SUMMARY);

    return stepsFiltered.map((step) => `/join/quiz/${step}`);
};

/**
 * Get the user's selected delivery day; otherwise default to the first available day.
 * @param {Object} validDeliveryDays - Valid delivery days data from the /validDays endpoint.
 * @returns {Object} A delivery day.
 */
export const getSelectedDeliveryDay = ({ validDeliveryDays }) => {
    const signupLocalStorage = getSignupLocalStorage();

    return (
        validDeliveryDays?.availableDays?.find(
            (day) => day.dateOfFirstOrder?.date === signupLocalStorage?.firstDelivery?.deliveryDate
        ) ?? validDeliveryDays?.availableDays?.[0]
    );
};

/**
 * getPackIconName - returns the aisle icon component name for a given pack, with optional brand theming
 *
 * @param {string} packName – the name of the pack derived from /quiz endpoint
 * @example
 * const packIconName = getPackIconName('Deli');
 * @returns {string}
 */
export const getPackIconName = (packName) => {
    switch (packName) {
        case 'Snacks':
            return 'Snacks';
        case 'Meat & seafood':
            return 'MeatAndSeafood';
        case 'Plant based':
            return 'PlantBased';
        case 'Fruit':
            return 'Fruit';
        case 'Vegetables':
        case 'Produce':
            return 'Vegetables';
        case 'Pantry':
            return 'Pantry';
        case 'Dairy':
            return 'Dairy';
        case 'Bakery':
            return 'Bakery';
        case 'Deli':
            return 'Deli';
        case 'Beverages':
            return 'Beverages';
        case 'Pet':
            return 'Pet';

        default:
            return 'Vegetables';
    }
};

/**
 * Get the appropriate error message and sign in link for existing users.
 * @param {string} isImperfectTheme - Is the webpage currently rendered using the Imperfect Foods theme?
 * @param {string} isSisterBrand - Does the customer belong to the sister brand?
 * @returns {Object} Relevant error message data.
 */
export const getExistingCustomerError = ({ isImperfectTheme, isSisterBrand }) => {
    const crossDomainDeviceId = getCrossDomainDeviceId();
    const params = new URLSearchParams({
        deviceId: crossDomainDeviceId,
    }).toString();

    if (isImperfectTheme && isSisterBrand) {
        return {
            message: `You're already a member of our sister brand, Misfits Market!${
                window.isNativeApp ? ' Download the Misfits Market app to sign in.' : ''
            }`,
            signInRoute: `${mmLinks.authenticationPage}?${params}`,
        };
    }

    if (!isImperfectTheme && isSisterBrand) {
        return {
            message: `You're already a member of our sister brand, Imperfect Foods!${
                window.isNativeApp ? ' Download the Imperfect Foods app to sign in.' : ''
            }`,
            signInRoute: `${ifLinks.authenticationPage}?${params}`,
        };
    }

    return {
        message: 'Email already registered.',
        signInRoute: isImperfectTheme ? ifLinks.authenticationPage : mmLinks.authenticationPage,
    };
};

/**
 * Parses utm_params from localStorage and returns whether the user is a Datadog synthetic test.
 * @returns {boolean} Whether the user is a Datadog synthetic test.
 */
export const getIsDatadogSyntheticTest = () => {
    let utmParams;

    try {
        utmParams = JSON.parse(localStorage.getItem('utm_params'));
    } catch (e) {
        utmParams = {};
    }

    return utmParams?.utm_source === 'datadog';
};
