import clsx from 'clsx';
import PropTypes from 'prop-types';

const baseClasses = 'inline-flex items-center text-body-mobile-sm px-5 rounded-md text-black';

export const colorVariants = {
    xLight: 'bg-opacity-10',
    light: 'bg-opacity-30',
    medium: 'bg-opacity-50',
    dark: 'bg-opacity-100',
};

export const bgColors = {
    default: 'bg-mango',
    info: 'bg-info',
    success: 'bg-success',
    blueberry: 'bg-blueberry',
    error: 'bg-error',
    warning: 'bg-warning',
    kale: 'bg-kale',
    lime: 'bg-lime if:bg-strawberry',
    malt: 'bg-malt if:bg-oat',
    offWhite: 'bg-off-white',
    special: 'bg-kale if:bg-beet',
    grey: 'bg-grey-light',
    bestSeller: 'bg-[#C8E2F3]',
    new: 'bg-[#CFE5BD]',
    lastChance: 'bg-[#FAEAC0]',
    white: 'bg-white',
    shopNow: 'bg-[#E3EEE5] if:bg-strawberry',
    cranberry: 'bg-cranberry',
    plusMembership: 'bg-gradient-berry-blend',
};

const fontSizes = {
    default: 'text-body-mobile-sm',
    mobileXS: 'text-body-mobile-xs',
};

const DARK_BACKGROUNDS_REQUIRING_WHITE_TEXT = ['blueberry', 'cranberry', 'error', 'special', 'success'];

function Tag({ bgColor, bold, children, fontSize, variant }) {
    const bgColorClasses = bgColors[bgColor];
    const fontSizeClasses = fontSizes[fontSize];
    const variantClasses = colorVariants[variant];

    return (
        <span
            className={clsx(baseClasses, bgColorClasses, fontSizeClasses, variantClasses, {
                'text-white':
                    (DARK_BACKGROUNDS_REQUIRING_WHITE_TEXT.includes(bgColor) && variant === 'dark') ||
                    bgColor === 'plusMembership',
                'text-grey': bgColor === 'grey',
                'font-grotesk-bold': bold,
            })}
        >
            {children}
        </span>
    );
}

Tag.propTypes = {
    bgColor: PropTypes.oneOf(Object.keys(bgColors)),
    bold: PropTypes.bool,
    children: PropTypes.node.isRequired,
    fontSize: PropTypes.oneOf(Object.keys(fontSizes)),
    variant: PropTypes.oneOf(Object.keys(colorVariants)),
};

Tag.defaultProps = {
    bgColor: 'default',
    bold: true,
    fontSize: 'default',
    variant: 'light',
};

export default Tag;
