import clsx from 'clsx';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { usePreviouslyPurchasedQuery } from 'api/queries/usePreviouslyPurchasedQuery';
import { SHOP_ALL_AISLE } from 'constants/shop';
import Tag from 'honeydew/Tag';
import { useAccountPlusMembershipHook } from 'hooks/useAccountPlusMembershipHook';
import { trackAisleNavigation } from 'utils/analyticsUtils';
import { getAislePath } from 'utils/pathUtils';

const styles = {
    navList: 'mt-20 space-y-10',
    navLink: 'block p-10',
    navLinkNew: 'flex items-center gap-15',
    navLinkActive: 'bg-lime if:bg-strawberry',
    navDivider: 'my-20 -mx-20 h-[3px] border-none bg-lime if:bg-strawberry',
    navHeadline: 'text-body-lg font-grotesk-bold uppercase',
};

export function DesktopShopNavigation({ aisles, inStockAllItemsCount }) {
    const { inPlusMembershipTreatment, plusMembershipBranding } = useAccountPlusMembershipHook();
    const { data: prevPurchasedData, isLoading: prevPurchasedIsLoading } = usePreviouslyPurchasedQuery();

    const prevPurchasedItems = prevPurchasedData?.items?.[0] ?? [];

    const showBuyItAgainAisle = !prevPurchasedIsLoading && prevPurchasedItems.length > 0;

    const allItemsAisle = {
        ...SHOP_ALL_AISLE,
        inStockCount: inStockAllItemsCount,
    };

    const aisleItems = [allItemsAisle, ...aisles].map((aisle) => (
        <li key={aisle.id}>
            <NavLink
                to={aisle.url || getAislePath(aisle.name)}
                className={({ isActive }) => clsx(styles.navLink, { [styles.navLinkActive]: isActive })}
                onClick={() => {
                    trackAisleNavigation({
                        aisleName: aisle.name,
                        level: 1,
                        mobile: false,
                        uiElement: 'desktop L1',
                    });
                }}
            >
                {aisle.name} <span className="text-grey">({aisle.inStockCount})</span>
            </NavLink>
        </li>
    ));

    return (
        <nav className="fixed bottom-0 left-0 top-80 mb-80 w-[250px] shrink-0 overflow-y-scroll bg-malt p-20 if:bg-oat">
            <h3 className={styles.navHeadline}>Curated</h3>
            <ul className={styles.navList}>
                <li>
                    <NavLink
                        to="/shop"
                        end
                        className={({ isActive }) => clsx(styles.navLink, { [styles.navLinkActive]: isActive })}
                        onClick={() =>
                            trackAisleNavigation({
                                aisleName: 'Featured',
                                level: 1,
                                mobile: false,
                                uiElement: 'desktop L1',
                            })
                        }
                    >
                        Featured
                    </NavLink>
                </li>
                {!prevPurchasedIsLoading && (
                    <>
                        {showBuyItAgainAisle && (
                            <li>
                                <NavLink
                                    to="/shop/aisle/buy-it-again"
                                    className={({ isActive }) =>
                                        clsx(styles.navLink, styles.navLinkNew, { 'bg-brand-secondary': isActive })
                                    }
                                    onClick={() =>
                                        trackAisleNavigation({
                                            aisleName: 'Buy It Again',
                                            level: 1,
                                            mobile: false,
                                            uiElement: 'desktop L1',
                                        })
                                    }
                                >
                                    Buy It Again
                                </NavLink>
                            </li>
                        )}
                        <li>
                            <NavLink
                                to="/shop/browse/misfits+perks"
                                className={({ isActive }) =>
                                    clsx(styles.navLink, styles.navLinkNew, { 'bg-brand-secondary': isActive })
                                }
                                onClick={() =>
                                    trackAisleNavigation({
                                        aisleName: 'Shop With Points',
                                        level: 1,
                                        mobile: false,
                                        uiElement: 'desktop L1',
                                    })
                                }
                            >
                                Shop with Points
                            </NavLink>
                        </li>
                    </>
                )}
                {inPlusMembershipTreatment && (
                    <li>
                        <NavLink
                            to="/shop/aisle/member-deals"
                            className={({ isActive }) =>
                                clsx(styles.navLink, styles.navLinkNew, { 'bg-brand-secondary': isActive })
                            }
                            onClick={() =>
                                trackAisleNavigation({
                                    aisleName: `${plusMembershipBranding} Deals`,
                                    level: 1,
                                    mobile: false,
                                    uiElement: 'desktop L1',
                                })
                            }
                        >
                            {plusMembershipBranding} Deals
                            <Tag bgColor="special" variant="dark">
                                NEW
                            </Tag>
                        </NavLink>
                    </li>
                )}
            </ul>
            <hr className={styles.navDivider} />
            <h3 className={styles.navHeadline}>Aisles</h3>
            <ul className={styles.navList}>{aisleItems}</ul>
        </nav>
    );
}

DesktopShopNavigation.propTypes = {
    aisles: PropTypes.arrayOf(PropTypes.shape({})),
};

DesktopShopNavigation.defaultProps = {
    aisles: [],
};
