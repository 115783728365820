import { createContext, useCallback, useContext, useMemo } from 'react';

import useAccountQuery from 'api/queries/useAccountQuery';
import useProductsQuery from 'api/queries/useProductsQuery';
import useSelectionsQuery from 'api/queries/useSelectionsQuery';
import useSubscriptionQuery from 'api/queries/useSubscriptionQuery';
import { useAccountPlusMembershipHook } from 'hooks/useAccountPlusMembershipHook';
import { calculateAge } from 'utils/birthdayUtils';

const ProductCardContext = createContext();

/**
 * Provider component that supplies product card related data and functionality to v2 <ProductCard /> component.
 *
 * @param {Object} props
 * @param {ReactNode} props.children - Child components that will have access to the ProductCard context
 * @returns {JSX.Element} Provider component wrapping children
 */
export function ProductCardProvider({ children }) {
    const { data: accountData = {} } = useAccountQuery();
    const { data: subscriptionData = {} } = useSubscriptionQuery();
    const { data: selectionsData = {} } = useSelectionsQuery();
    const { data: productsData = {} } = useProductsQuery();
    const {
        inPlusMembershipTreatment,
        plusMemberPriceEligible,
        plusMembershipEligibleOrder,
        plusMembershipShortBranding,
    } = useAccountPlusMembershipHook();

    const chargeID = accountData?.nextOrder?.chargeID;
    const isDeliveredByImperfect = accountData?.isDeliveredByImperfect;

    const showMembershipPrice = inPlusMembershipTreatment && plusMemberPriceEligible && plusMembershipEligibleOrder;
    const showMembershipPreviewPrice =
        inPlusMembershipTreatment && plusMemberPriceEligible && !plusMembershipEligibleOrder;

    const ageIsUnder21 = calculateAge(subscriptionData?.dateOfBirth) < 21;

    /**
     * Retrieves the cart selection data for a specific product ID from the selections data.
     * The selection data represents items in the cart and can come from either regular selections
     * or bundle selections. Returns empty object if no selection exists for the product.
     *
     * @param {string} productID - The ID of the product to find cart selection data for
     * @returns {Object} The cart selection data for the product if found, empty object otherwise
     */
    const getSelection = useCallback(
        (productID) => {
            const { selected = [], selectedBundles = [] } = selectionsData ?? {};

            if (selected.length === 0 && selectedBundles.length === 0) return {};

            const productSelectionData = [...selected, ...selectedBundles].find(
                (selection) => selection.id === productID
            );

            return productSelectionData;
        },
        [selectionsData]
    );

    const value = useMemo(
        () => ({
            ageIsUnder21,
            chargeID,
            getSelection,
            isDeliveredByImperfect,
            inPlusMembershipTreatment,
            plusMembershipEligibleOrder,
            plusMemberPriceEligible,
            productsData,
            showMembershipPrice,
            showMembershipPreviewPrice,
            plusMembershipShortBranding,
        }),
        [
            ageIsUnder21,
            selectionsData,
            chargeID,
            isDeliveredByImperfect,
            inPlusMembershipTreatment,
            plusMembershipEligibleOrder,
            plusMemberPriceEligible,
            productsData,
            showMembershipPrice,
            showMembershipPreviewPrice,
            plusMembershipShortBranding,
        ]
    );

    return <ProductCardContext.Provider value={value}>{children}</ProductCardContext.Provider>;
}

export function useProductCardContext() {
    return useContext(ProductCardContext);
}

export default ProductCardContext;
