import PropTypes from 'prop-types';

function CustomizeIcon({ width }) {
    return (
        <svg width={width} viewBox="0 0 61 59" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5262_75014)">
                <path
                    d="M29.75 58.3909C45.2885 54.6135 60.5055 45.494 60.5055 29.5001C60.5055 13.5061 47.5282 3.76512 29.75 0.475248C14.024 -2.42884 3.69358 12.6221 0.789494 29.4358C-1.93242 45.1939 14.9295 61.9969 29.7447 58.3909"
                    className="fill-mango if:fill-[#FFCBDC]"
                />
                <path
                    d="M26.2561 6.25659H11.2749C9.9403 6.25659 8.8584 7.3385 8.8584 8.67309V23.6543C8.8584 24.9889 9.9403 26.0708 11.2749 26.0708H26.2561C27.5907 26.0708 28.6727 24.9889 28.6727 23.6543V8.67309C28.6727 7.3385 27.5907 6.25659 26.2561 6.25659Z"
                    className="fill-[#F1EAD0] if:fill-oat"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M49.5369 6.25659H34.5557C33.2211 6.25659 32.1392 7.3385 32.1392 8.67309V23.6543C32.1392 24.9889 33.2211 26.0708 34.5557 26.0708H49.5369C50.8715 26.0708 51.9534 24.9889 51.9534 23.6543V8.67309C51.9534 7.3385 50.8715 6.25659 49.5369 6.25659Z"
                    className="fill-[#AFC272] if:fill-[#C5DB66]"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M26.2561 29.6232H11.2749C9.9403 29.6232 8.8584 30.7051 8.8584 32.0397V47.0209C8.8584 48.3555 9.9403 49.4374 11.2749 49.4374H26.2561C27.5907 49.4374 28.6727 48.3555 28.6727 47.0209V32.0397C28.6727 30.7051 27.5907 29.6232 26.2561 29.6232Z"
                    className="fill-[#F1EAD0] if:fill-oat"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M49.5369 29.6232H34.5557C33.2211 29.6232 32.1392 30.7051 32.1392 32.0397V47.0209C32.1392 48.3555 33.2211 49.4374 34.5557 49.4374H49.5369C50.8715 49.4374 51.9534 48.3555 51.9534 47.0209V32.0397C51.9534 30.7051 50.8715 29.6232 49.5369 29.6232Z"
                    className="fill-[#F1EAD0] if:fill-oat"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M53.3197 5.49035C53.1001 5.94043 52.8482 6.41194 52.6125 6.85131C49.2101 13.0506 47.0293 16.1744 46.531 17.096C45.9684 18.1408 44.6075 20.1715 43.6537 21.4521C42.6035 22.8667 41.7248 22.5613 40.8889 22.0362C40.5085 21.8004 37.6419 19.3518 36.2917 17.964C36.0506 17.7175 35.9006 17.5943 35.6702 17.3318C34.0681 15.4993 36.4417 13.5918 37.9259 15.0706C38.3331 15.4778 40.9854 17.964 41.4944 17.9319C41.7837 17.9104 49.4726 4.97061 50.3406 3.8347C51.8998 1.79326 54.1235 3.86685 53.3251 5.48499L53.3197 5.49035Z"
                    fill="white"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_5262_75014">
                    <rect width="60" height="58.7676" fill="white" transform="translate(0.5 0.116211)" />
                </clipPath>
            </defs>
        </svg>
    );
}

CustomizeIcon.propTypes = {
    width: PropTypes.number,
};

CustomizeIcon.defaultProps = {
    width: 61,
};

export default CustomizeIcon;
