import PropTypes from 'prop-types';

function WineVarietalIcon({ height, width }) {
    return (
        <svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.3447 4.96683C16.3447 6.61481 17.5701 7.94977 19.0816 7.94977C20.5932 7.94977 22.1474 6.58802 21.8185 4.96683C21.4909 3.35173 20.5932 1.98389 19.0816 1.98389C18.201 1.98389 17.8599 2.77804 17.1267 3.2348C16.592 3.56854 16.3447 4.27986 16.3447 4.96683Z"
                fill="#A13051"
            />
            <path
                d="M11.4897 3.56756C11.4897 5.05598 12.1072 6.32029 14.0414 6.26305C15.4495 6.22163 16.5932 5.05598 16.5932 3.56756C16.5932 2.07913 15.4507 0.87207 14.0414 0.87207C13.3094 0.87207 12.8198 1.33492 12.3545 1.85502C11.9233 2.33735 11.4909 2.85136 11.4909 3.56756H11.4897Z"
                fill="#A13051"
            />
            <path
                d="M19.9257 10.8101C19.9257 12.4581 21.3106 13.793 23.0183 13.793C24.726 13.793 26.1108 12.4569 26.1108 10.8101C26.1108 9.99523 25.542 9.50924 25.2229 8.71874C24.9294 7.99036 23.8806 7.82715 23.0183 7.82715C21.3106 7.82715 19.9257 9.16332 19.9257 10.8101Z"
                fill="#A13051"
            />
            <path
                d="M6.67487 7.57945C6.60544 9.8803 7.89046 10.4187 9.39106 10.4187C10.8917 10.4187 12.1073 9.14705 12.1073 7.57945C12.1073 6.01185 10.8917 4.74023 9.39106 4.74023C7.89046 4.74023 6.72237 6.01185 6.67487 7.57945Z"
                fill="#A13051"
            />
            <path
                d="M11.0781 9.18769C11.0781 10.005 11.7443 10.5019 12.1828 11.1792C12.5287 11.7114 13.1792 11.6542 13.7736 11.6542C15.262 11.6542 16.469 10.5494 16.469 9.18769C16.469 7.82594 15.262 6.72119 13.7736 6.72119C12.2851 6.72119 11.0781 7.82594 11.0781 9.18769Z"
                fill="#A13051"
            />
            <path
                d="M16.0183 9.24483C15.223 10.5298 15.4264 12.0962 16.4727 12.7442C17.5189 13.3922 18.916 12.6991 19.8064 11.5883C20.7516 10.4093 20.3983 8.73691 19.3521 8.08892C18.8295 7.76493 18.1413 7.98417 17.5384 8.19246C16.9355 8.40074 16.4154 8.60171 16.0183 9.24361V9.24483Z"
                fill="#A13051"
            />
            <path
                d="M13.2657 21.5938C14.1988 20.0852 13.9912 18.2659 12.8018 17.5301C11.6124 16.7944 9.89173 17.421 8.95855 18.9296C8.02536 20.4382 8.23304 22.2576 9.42242 22.9933C10.6118 23.729 12.3325 23.1025 13.2657 21.5938Z"
                fill="#A13051"
            />
            <path
                d="M13.1523 13.9888C12.2364 15.4687 12.2924 17.4955 13.5531 17.9413C14.3022 18.2056 15.3156 18.3371 16.0159 18.0278C16.5799 17.7781 16.86 17.1946 17.2681 16.5345C18.184 15.0546 18.0037 13.2848 16.8673 12.582C15.7309 11.8792 14.0671 12.5089 13.1523 13.9888Z"
                fill="#A13051"
            />
            <path
                d="M14.2376 20.3741C13.4799 21.5994 13.8575 23.206 15.0829 23.9636C16.307 24.7212 17.5567 24.0282 18.6724 23.1171C19.7248 22.2572 19.4885 19.6823 18.2631 18.9247C17.039 18.167 14.9952 19.15 14.2376 20.3741Z"
                fill="#A13051"
            />
            <path
                d="M9.45073 25.0952C8.46779 26.6847 8.68947 28.6567 9.99885 29.4143C12.3326 30.7639 13.1243 29.5654 14.1085 27.9758C15.0914 26.3863 14.8466 24.4521 13.5603 23.6567C12.2741 22.8613 10.4349 23.5057 9.45073 25.0952Z"
                fill="#A13051"
            />
            <path
                d="M17.4252 15.9721C17.2814 17.8649 18.5981 19.5068 20.3655 19.642C21.1499 19.7017 21.8198 19.1609 22.4775 18.8515C23.3301 18.452 23.7467 17.5117 23.8259 16.4593C23.9696 14.5665 22.6529 12.9246 20.8856 12.7894C20.1316 12.7322 19.1584 12.7395 18.8344 13.3838C18.4227 14.2011 17.5068 14.8868 17.4239 15.9721H17.4252Z"
                fill="#A13051"
            />
            <path
                d="M6.71625 14.3824C6.37642 16.162 8.18518 17.6626 9.99639 17.6626C10.9464 17.6626 11.5701 17.151 12.1693 16.5067C12.7126 15.922 13.2765 15.2448 13.2765 14.3837C13.2765 12.5725 11.8076 11.1035 9.99639 11.1035C8.18518 11.1035 7.08653 12.447 6.71625 14.3837V14.3824Z"
                fill="#A13051"
            />
            <path
                d="M13.2022 3.01717C13.2022 3.01717 11.19 -1.0827 7.30695 0.579905C3.42389 2.24251 4.48114 4.4447 5.04874 4.69926C5.61755 4.95383 6.21317 5.71022 5.14131 5.72362C4.06823 5.73702 3.89892 7.8101 4.62121 9.22422C5.3435 10.6383 5.41658 12.4751 5.41658 12.4751C5.41658 12.4751 8.60902 12.3204 9.38734 12.7053C10.1657 13.0902 13.173 12.5312 13.0292 11.6676C12.8855 10.804 13.2935 10.3801 14.5688 11.6042C15.8441 12.8284 20.039 7.92337 18.1595 6.03422C16.2789 4.14506 14.7138 4.09634 14.7138 4.09634C14.7138 4.09634 13.6492 5.03422 12.9574 4.51047C12.2655 3.9855 13.2034 3.01717 13.2034 3.01717H13.2022Z"
                stroke="#2D2D2D"
                strokeWidth="0.365407"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.3837 5.12305L6.60541 11.1778"
                stroke="#2D2D2D"
                strokeWidth="0.365407"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default WineVarietalIcon;

WineVarietalIcon.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
};

WineVarietalIcon.defaultProps = {
    height: 30,
    width: 30,
};
