import PropTypes from 'prop-types';

import { HAND_HOLDING_BOWL_ICON_URL } from 'honeydew/icons/PlusMembership';
import Popover from 'honeydew/Popover';
import { useAccountPlusMembershipHook } from 'hooks/useAccountPlusMembershipHook';
import { getResizedCDNAsset } from 'utils/globalUtils';

import BenefitBase from './shared/BenefitBase';

function DonateMeals({ layout }) {
    const { plusMembershipBranding } = useAccountPlusMembershipHook();

    const horizontalImage = (
        <img
            src={getResizedCDNAsset({ url: HAND_HOLDING_BOWL_ICON_URL, width: 300 })}
            alt="ribbon with coin"
            width={150}
        />
    );

    const verticalImage = (
        <img
            src={getResizedCDNAsset({ url: HAND_HOLDING_BOWL_ICON_URL, width: 200 })}
            alt="ribbon with coin"
            width={100}
        />
    );

    const headline = (
        <>
            Each {plusMembershipBranding} order helps feed 2+ meals to those in need{' '}
            <Popover trigger="ⓘ" heading="How does this work?" label="How does Feeding America work?">
                <span className="text-body-xs">
                    90% of our donation will go to the local partner Feeding America food bank based on your zip code;
                    10% of our donation will support the activities of the Feeding America national organization.
                </span>
            </Popover>
        </>
    );

    return (
        <BenefitBase
            headline={headline}
            verticalImage={verticalImage}
            horizontalImage={horizontalImage}
            horizontalImagePosition="bottom-[-20px] left-0 right-[-30px] top-[-20px]"
            layout={layout}
        />
    );
}

DonateMeals.propTypes = {
    layout: PropTypes.oneOf(['horizontal', 'responsive']),
};

DonateMeals.defaultProps = {
    layout: 'responsive',
};

export default DonateMeals;
