import { CheckIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import PropTypes from 'prop-types';

function ListItem({ label, valid }) {
    return (
        <li
            className={clsx('relative flex items-center gap-5 pl-20 text-body-mobile-sm', {
                'text-brand-primary': valid,
            })}
        >
            <div className="notranslate">{valid && <CheckIcon className="absolute left-0 top-[3px] h-15 w-15" />}</div>
            {label}
        </li>
    );
}

ListItem.propTypes = {
    label: PropTypes.string.isRequired,
    valid: PropTypes.bool.isRequired,
};

export default function PasswordRequirements({ password }) {
    return (
        <ul>
            <ListItem label="At least 8 character(s)" valid={password.length > 7} />
            <ListItem label="At least 1 number(s)" valid={/.*[0-9].*/.test(password)} />
            <ListItem label="At least 1 lowercase letter(s)" valid={/.*[a-z].*/.test(password)} />
            <ListItem label="At least 1 uppercase letter(s)" valid={/.*[A-Z].*/.test(password)} />
        </ul>
    );
}

PasswordRequirements.propTypes = {
    password: PropTypes.string.isRequired,
};
