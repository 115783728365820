import PropTypes from 'prop-types';

function OrderPlacedIcon({ width }) {
    return (
        <svg width={width} viewBox="0 0 60 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4353_145851)">
                <path
                    d="M3.56709 44.0606C9.87878 61.8788 28.4762 67.0736 42.8918 61.9394C57.3073 56.8052 63.9221 47.1082 57.6104 29.264C51.2987 11.4199 27.4978 -2.98704 13.0822 2.13851C-1.33335 7.26405 -2.7446 26.2078 3.56709 44.0606Z"
                    className="fill-[#FFF9EA] if:fill-[#F8F2ED]"
                />
                <path
                    d="M35.1688 52.4935L3.974 51.1082L6.1385 19.9134L37.3333 21.2987L35.1688 52.4935Z"
                    fill="white"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M46.6234 54.8571L35.1688 52.4935L37.3333 21.2987L48.7879 23.6537L46.6234 54.8571Z"
                    fill="#F2D79A"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M3.974 51.1082V51.8788L19.645 54.0433L46.6233 55.6017V54.8571L35.1688 52.4935L3.974 51.1082Z"
                    fill="#2D2D2D"
                />
                <path
                    d="M36 13.5584L20.5541 43.3853C20.4334 43.6824 20.2326 43.9402 19.974 44.1298C19.316 44.5454 18.5021 43.9827 17.9307 43.4545L9.56708 35.7229C9.56708 35.7229 10.2857 32.3723 11.2987 32.6926C12.3117 33.013 18.0086 39.0043 18.3376 38.1298C18.6666 37.2554 31.2467 10.5281 32.4329 10.6407C33.619 10.7532 36 13.5584 36 13.5584Z"
                    fill="#B0C372"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M16.6753 11.4632C15.927 10.7944 15.0838 10.2401 14.1732 9.81818Z" fill="#F2D79A" />
                <path
                    d="M16.6753 11.4632C15.927 10.7944 15.0838 10.2401 14.1732 9.81818"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M26.052 4.57143C25.6842 3.2151 25.1764 1.90068 24.5368 0.649353Z" fill="#F2D79A" />
                <path
                    d="M26.052 4.57143C25.6842 3.2151 25.1764 1.90068 24.5368 0.649353"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M37.3333 7.17752C38.3818 5.60102 39.5087 4.07818 40.7099 2.61475Z" fill="#F2D79A" />
                <path
                    d="M37.3333 7.17752C38.3818 5.60102 39.5087 4.07818 40.7099 2.61475"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M44.5281 16.1559C45.8578 15.3848 47.2617 14.7495 48.7186 14.2598Z" fill="#F2D79A" />
                <path
                    d="M44.5281 16.1559C45.8578 15.3848 47.2617 14.7495 48.7186 14.2598"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_4353_145851">
                    <rect width="60" height="63.8095" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

OrderPlacedIcon.propTypes = {
    width: PropTypes.number,
};

OrderPlacedIcon.defaultProps = {
    width: 64,
};

export default OrderPlacedIcon;
