/**
 * Order Session Storage:
 *     Data stored within local storage under the `orderSessionStorage` key.
 *     The motivation is to persist data between sessions, but not between orders.
 *     As such, the `orderSessionStorage` is keyed by `nextOrder.chargeID`.
 */

export const resetOrderSessionStorage = ({ sessionKey }) => {
    const data = {
        hideCuratedCartBanner: false,
        sessionKey,
    };

    localStorage.setItem('orderSessionStorage', JSON.stringify(data));
};

export const getOrderSessionStorage = () => JSON.parse(localStorage.getItem('orderSessionStorage')) ?? {};

export const setOrderSessionStorage = (data) => {
    const orderSessionStorage = getOrderSessionStorage();

    localStorage.setItem(
        'orderSessionStorage',
        JSON.stringify({
            ...orderSessionStorage,
            ...data,
        })
    );
};
