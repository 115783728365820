import PropTypes from 'prop-types';

function RecurringIcon({ brandColors, width }) {
    return (
        <svg width={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.023 9.34841H21.0156V9.34663M2.98438 19.6444V14.6517M2.98438 14.6517L7.97702 14.6517M2.98438 14.6517L6.16527 17.8347C7.15579 18.8271 8.41285 19.58 9.8646 19.969C14.2657 21.1483 18.7895 18.5364 19.9687 14.1353M4.03097 9.86484C5.21024 5.46374 9.73402 2.85194 14.1351 4.03121C15.5869 4.4202 16.8439 5.17312 17.8345 6.1655L21.0156 9.34663M21.0156 4.3558V9.34663"
                className={brandColors ? 'stroke-kale if:stroke-beet' : 'stroke-black'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

RecurringIcon.propTypes = {
    brandColors: PropTypes.bool,
    width: PropTypes.number,
};

RecurringIcon.defaultProps = {
    brandColors: false,
    width: 24,
};

export default RecurringIcon;
