import PropTypes from 'prop-types';

import IFProduceActive from './IFProduceActive';
import IFProduceInactive from './IFProduceInactive';

function IFProduce({ className, isActive, width }) {
    return isActive ? (
        <IFProduceActive className={className} width={width} />
    ) : (
        <IFProduceInactive className={className} width={width} />
    );
}

export default IFProduce;

IFProduce.propTypes = {
    isActive: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

IFProduce.defaultProps = {
    isActive: true,
    width: 28,
};
