import PropTypes from 'prop-types';

import IFDiscoverSomethingNewActive from './IFDiscoverSomethingNewActive';
import IFDiscoverSomethingNewInactive from './IFDiscoverSomethingNewInactive';

function IFDiscoverSomethingNew({ className, isActive, width }) {
    return isActive ? (
        <IFDiscoverSomethingNewActive className={className} width={width} />
    ) : (
        <IFDiscoverSomethingNewInactive className={className} width={width} />
    );
}

export default IFDiscoverSomethingNew;

IFDiscoverSomethingNew.propTypes = {
    className: PropTypes.string,
    isActive: PropTypes.bool,
    width: PropTypes.number,
};

IFDiscoverSomethingNew.defaultProps = {
    className: '',
    isActive: true,
    width: 24,
};
