import { useMediaQuery } from 'react-responsive';

import { usePreviouslyPurchasedQuery } from 'api/queries/usePreviouslyPurchasedQuery';
import { BUY_IT_AGAIN_AISLE, MEMBER_DEALS_AISLE, SHOP_WITH_POINTS_AISLE } from 'constants/shop';
import Button from 'honeydew/Button/Button';
import { useAccountPlusMembershipHook } from 'hooks/useAccountPlusMembershipHook';
import useTailwindTheme from 'hooks/useTailwindTheme';
import { trackAisleNavigation } from 'utils/analyticsUtils';

export function CustomAisleNavigation() {
    const tailwindTheme = useTailwindTheme();
    const mobile = useMediaQuery({ maxWidth: tailwindTheme?.screens?.md });

    const { inPlusMembershipTreatment, plusMembershipBranding } = useAccountPlusMembershipHook();
    const { data: prevPurchasedData, isLoading: prevPurchasedIsLoading } = usePreviouslyPurchasedQuery();

    const prevPurchasedItems = prevPurchasedData?.items?.[0] ?? [];
    const showBuyItAgainAisle = !prevPurchasedIsLoading && prevPurchasedItems.length > 0;

    if (!mobile) return null;

    const gridCols = 1 + (showBuyItAgainAisle ? 1 : 0) + (inPlusMembershipTreatment ? 1 : 0);

    const handleClick = ({ aisle, e }) => {
        e.preventDefault();
        trackAisleNavigation({
            aisleName: aisle.name,
            level: 2,
            mobile: true,
            uiElement: 'mobile L2',
        });
        window.location.href = aisle.url;
    };

    return (
        <div className={`grid w-full grid-cols-${gridCols} content-center gap-5 bg-white p-10`}>
            {showBuyItAgainAisle && (
                <Button
                    as="link"
                    customClass="w-full !px-0"
                    onClick={(e) => handleClick({ aisle: BUY_IT_AGAIN_AISLE, e })}
                    size="sm"
                    rounded="full"
                    variant="offWhite"
                >
                    <p className="text-body-xxs font-grotesk-bold">{BUY_IT_AGAIN_AISLE.name}</p>
                </Button>
            )}
            <Button
                as="link"
                customClass="w-full !px-0"
                onClick={(e) => handleClick({ aisle: SHOP_WITH_POINTS_AISLE, e })}
                size="sm"
                rounded="full"
                variant="offWhite"
            >
                <p className="text-body-xxs font-grotesk-bold">{SHOP_WITH_POINTS_AISLE.name}</p>
            </Button>
            {inPlusMembershipTreatment && (
                <Button
                    as="link"
                    customClass="w-full !px-0"
                    onClick={(e) => handleClick({ aisle: MEMBER_DEALS_AISLE, e })}
                    size="sm"
                    rounded="full"
                    variant="plusMembershipLight"
                >
                    <p className="text-body-xxs">{`${plusMembershipBranding} Deals`}</p>
                </Button>
            )}
        </div>
    );
}
