/**
 * Gets the original referrer from the Optimizely state if available.
 * Returns an empty string if Optimizely or the necessary functions are not defined.
 *
 * @returns {string} The original referrer, or an empty string.
 */
function getRedirectInfo() {
    let referrer = '';

    if (typeof window?.optimizely === 'object' && typeof window.optimizely.get === 'function') {
        const optimizelyState = window.optimizely.get('state');
        const redirectInfo = optimizelyState?.getRedirectInfo?.();
        referrer = redirectInfo?.referrer ?? '';
    }

    return referrer;
}

export { getRedirectInfo };
