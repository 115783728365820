import { updateAddress } from 'api/jalapeno';
import { SUBSCRIPTION_QUERY_KEY } from 'api/queries/useSubscriptionQuery';

const action =
    (queryClient) =>
    async ({ request }) => {
        const formData = await request.formData();
        const { deliveryInstructions, subscriptionID, ...values } = Object.fromEntries(formData);

        const payload = {
            ...values,
            deliveryInstructions,
            confirmPlusMembershipCarrierSwitch: 'CONFIRM_CARRIER_SWITCH',
        };

        try {
            const response = await updateAddress(subscriptionID, payload);
            if (response.msg === 'Success') {
                await queryClient.invalidateQueries({ queryKey: [SUBSCRIPTION_QUERY_KEY] });

                if (window.isNativeApp) {
                    window.ReactNativeWebView?.postMessage(JSON.stringify({ reload: true }));
                }

                return { success: true, message: '' };
            }
            throw new Error(response);
        } catch {
            return {
                error: true,
                message: 'Could not update your delivery instructions.',
            };
        }
    };

export default action;
