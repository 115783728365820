import { updateBirthday } from 'api/jalapeno';
import { SUBSCRIPTION_QUERY_KEY } from 'api/queries/useSubscriptionQuery';
import { birthdayValidation, drinkingAgeValidation } from 'utils/birthdayUtils';

const action =
    (queryClient) =>
    async ({ request }) => {
        const formData = await request.formData();
        const { customerID, dob } = Object.fromEntries(formData);

        try {
            birthdayValidation.validateSync(dob);
            drinkingAgeValidation.validateSync(dob);
        } catch (err) {
            return {
                error: true,
                message: err.errors[0],
            };
        }

        try {
            await updateBirthday(customerID, { dob });
            await queryClient.invalidateQueries({ queryKey: [SUBSCRIPTION_QUERY_KEY] });
        } catch (err) {
            return {
                error: true,
                message: 'Oops! The entered birthday could not be updated. Please try again.',
            };
        }

        return {};
    };

export default action;
