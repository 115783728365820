import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { useMatch } from 'react-router';

import { ifLinks, mmLinks } from 'constants/links';
import { Accordion } from 'honeydew/Accordion';
import { AppleAppStoreBadge, GooglePlayBadge } from 'honeydew/icons/AppStores';
import ImperfectFoodsLogo from 'honeydew/icons/Logos/ImperfectFoodsLogo';
import {
    FacebookIcon,
    InstagramIcon,
    PinterestIcon,
    TikTokIcon,
    TwitterIcon,
    YouTubeIcon,
} from 'honeydew/icons/Social';
import useGlobalStore from 'hooks/useGlobalStore';
import useTailwindTheme from 'hooks/useTailwindTheme';

function AppStores({ isMisfitsTheme }) {
    return (
        <>
            <a
                href={
                    isMisfitsTheme
                        ? 'https://apps.apple.com/us/app/misfits-market-groceries/id1564147180'
                        : 'https://apps.apple.com/us/app/imperfect-foods/id1577380836'
                }
                target="_blank"
                rel="noreferrer"
                className="link-hover"
            >
                <AppleAppStoreBadge width={150} />
            </a>
            <a
                href={
                    isMisfitsTheme
                        ? 'https://play.google.com/store/apps/details?id=com.misfitsmarket&hl=en_US&gl=US&pli=1'
                        : 'https://play.google.com/store/apps/details?id=com.imperfectfoods'
                }
                target="_blank"
                rel="noreferrer"
                className="link-hover"
            >
                <GooglePlayBadge width={150} />
            </a>
        </>
    );
}

AppStores.propTypes = {
    isMisfitsTheme: PropTypes.bool,
};

AppStores.defaultProps = {
    isMisfitsTheme: true,
};

function Socials({ isMisfitsTheme }) {
    return (
        <>
            <a
                href={
                    isMisfitsTheme
                        ? 'https://www.instagram.com/misfitsmarket/'
                        : 'https://www.instagram.com/imperfectfoods'
                }
                target="_blank"
                rel="noreferrer"
            >
                <InstagramIcon dark />
            </a>
            <a
                href={
                    isMisfitsTheme
                        ? 'https://www.facebook.com/misfitsmarket/'
                        : 'https://www.facebook.com/imperfectfoods'
                }
                target="_blank"
                rel="noreferrer"
            >
                <FacebookIcon dark />
            </a>
            {!isMisfitsTheme && (
                <a href="https://twitter.com/imperfect_foods" target="_blank" rel="noreferrer">
                    <TwitterIcon dark />
                </a>
            )}
            <a
                href={
                    isMisfitsTheme ? 'https://www.tiktok.com/@misfitsmarket' : 'https://www.tiktok.com/@imperfectfoods'
                }
                target="_blank"
                rel="noreferrer"
            >
                <TikTokIcon dark />
            </a>
            {isMisfitsTheme && (
                <>
                    <a href="https://www.pinterest.com/misfitsmarket/" target="_blank" rel="noreferrer">
                        <PinterestIcon dark />
                    </a>
                    <a href="https://www.youtube.com/channel/UCKuBayh9HPdX7UGrKqxsrKg" target="_blank" rel="noreferrer">
                        <YouTubeIcon dark />
                    </a>
                </>
            )}
        </>
    );
}

Socials.propTypes = {
    isMisfitsTheme: PropTypes.bool,
};

Socials.defaultProps = {
    isMisfitsTheme: true,
};

function Logos({ isMisfitsTheme }) {
    return isMisfitsTheme ? (
        <>
            {/* TODO: move out of footer directory so this is less tied to footer content. */}
            <img
                src="https://img-cdn.misfitsmarket.com/boysenberry/footer/MM_nav-logo.svg"
                alt="Misfits Market logo"
                width="220"
            />
            {/* TODO: move out of footer directory so this is less tied to footer content. */}
            <img
                src="https://img-cdn.misfitsmarket.com/boysenberry/footer/MM_footer-illustration.png"
                alt="Selection of foods items."
                width="141"
                className="mt-20 max-w-[141px]"
            />
        </>
    ) : (
        <>
            <ImperfectFoodsLogo width={140} />
            {/* TODO: add to Honeydew. */}
            <img
                src="https://img-cdn.misfitsmarket.com/boysenberry/icons/IF_produce-with-eyes.svg"
                alt="Produce with eyes"
                width="141"
                className="mt-20"
            />
        </>
    );
}

Logos.propTypes = {
    isMisfitsTheme: PropTypes.bool,
};

Logos.defaultProps = {
    isMisfitsTheme: true,
};

function Legals({ center, isMisfitsTheme }) {
    return (
        <div className={clsx('text-body-xs mt-20 space-y-5', { 'space-y-10 text-center': center })}>
            <p>Copyright © {isMisfitsTheme ? 'Misfits Market' : 'Imperfect Foods'}. All Rights Reserved</p>
            <p className={clsx('flex', { 'flex-wrap justify-center gap-15': center, 'flex-col gap-5': !center })}>
                {isMisfitsTheme ? (
                    <a
                        href="https://www.misfitsmarket.com/terms-of-service"
                        target="_blank"
                        rel="noreferrer"
                        className="link-hover"
                    >
                        Terms of Service
                    </a>
                ) : (
                    <a
                        href="https://www.imperfectfoods.com/terms-and-conditions"
                        target="_blank"
                        rel="noreferrer"
                        className="link-hover"
                    >
                        Terms and Conditions
                    </a>
                )}
                <a
                    href={
                        isMisfitsTheme
                            ? 'https://www.misfitsmarket.com/privacy'
                            : 'https://www.imperfectfoods.com/privacy'
                    }
                    target="_blank"
                    rel="noreferrer"
                    className="link-hover"
                >
                    Privacy Policy
                </a>
                <a href="https://privacy.misfitsmarket.com" className="link-hover">
                    Do Not Sell or Share My Personal Information
                </a>
            </p>
            <p>
                <a
                    href={
                        isMisfitsTheme
                            ? 'https://www.misfitsmarket.com/privacy#10-additional-privacy-information-for-california-residents'
                            : 'https://www.imperfectfoods.com/privacy#6-california-privacy-rights'
                    }
                    target="_blank"
                    rel="noreferrer"
                    className="link-hover"
                >
                    California Privacy Notice
                </a>
            </p>
        </div>
    );
}

Legals.propTypes = {
    center: PropTypes.bool,
    isMisfitsTheme: PropTypes.bool,
};

Legals.defaultProps = {
    center: true,
    isMisfitsTheme: true,
};

function LinkLists({ as, isMisfitsTheme }) {
    const getLinkList = (links) => (
        <ul className="space-y-5">
            {links
                .filter((link) => link)
                .map(({ href, linkText, openInNewTab = true }) => {
                    const newTab = openInNewTab ? { target: '_blank', rel: 'noreferrer' } : {};

                    return (
                        <li key={href}>
                            <a href={href} {...newTab} className="link-hover">
                                {linkText}
                            </a>
                        </li>
                    );
                })}
        </ul>
    );

    const linksData = [
        {
            id: 1,
            headline: `Shop`,
            content: getLinkList(
                isMisfitsTheme
                    ? [
                          { href: 'https://www.misfitsmarket.com/how-it-works', linkText: 'How It Works' },
                          { href: 'https://www.misfitsmarket.com/whats-in-your-box', linkText: 'Produce' },
                          { href: 'https://www.misfitsmarket.com/wine', linkText: 'Wine' },
                          { href: 'https://www.misfitsmarket.com/referral-program', linkText: 'Refer Friends' },
                          { href: 'https://www.misfitsmarket.com/misfits-perks', linkText: 'Perks' },
                      ]
                    : [
                          { href: 'https://www.imperfectfoods.com/how-it-works', linkText: 'How It Works' },
                          { href: 'https://www.imperfectfoods.com/our-food', linkText: 'Our Food' },
                          {
                              href: 'https://www.imperfectfoods.com/what-imperfect-means',
                              linkText: 'What Imperfect Means',
                          },
                          { href: 'https://www.imperfectfoods.com/wine-delivery', linkText: 'Wine' },
                      ]
            ),
        },
        {
            id: 2,
            headline: 'Reduce Waste',
            content: getLinkList(
                isMisfitsTheme
                    ? [
                          { href: 'https://www.misfitsmarket.com/about-us', linkText: 'Our Mission' },
                          { href: 'https://www.misfitsmarket.com/sourcing', linkText: 'Sourcing Philosophy' },
                          { href: 'https://www.misfitsmarket.com/packaging', linkText: 'Packaging Return' },
                          { href: 'https://www.misfitsmarket.com/careers', linkText: 'Careers' },
                          { href: 'https://blog.misfitsmarket.com/', linkText: 'Blog' },
                      ]
                    : [
                          { href: 'https://blog.imperfectfoods.com/', linkText: 'Blog' },
                          { href: 'https://www.imperfectfoods.com/impact', linkText: 'Impact' },
                          { href: 'https://www.imperfectfoods.com/food-waste', linkText: 'Food Waste' },
                          { href: 'https://www.imperfectfoods.com/packaging', linkText: 'Packaging Return' },
                          { href: 'https://www.imperfectfoods.com/jobs', linkText: 'Jobs' },
                      ]
            ),
        },
        {
            id: 3,
            headline: 'Get Answers',
            content: getLinkList(
                isMisfitsTheme
                    ? [
                          {
                              href: mmLinks.customerCare,
                              linkText: 'Contact Us',
                          },
                          { href: mmLinks.faqs, linkText: 'FAQs' },
                          { href: mmLinks.helpCenter, linkText: 'Help Center' },
                          { href: 'https://www.misfitsmarket.com/reviews', linkText: 'Reviews and Press' },
                          { href: 'https://www.misfitsmarket.com/accessibility', linkText: 'Accessibility' },
                      ]
                    : [
                          { href: ifLinks.customerCare, linkText: 'Contact Us' },
                          { href: ifLinks.faqs, linkText: 'FAQs' },
                      ]
            ),
        },
    ];

    if (as === 'accordion') return <Accordion className="text-left" items={linksData} />;

    return (
        <>
            {linksData.map(({ content, headline, id }) => (
                <div key={id}>
                    <h5 className="mb-10 font-grotesk-bold">{headline}</h5>
                    {content}
                </div>
            ))}
        </>
    );
}

LinkLists.propTypes = {
    as: PropTypes.oneOf(['accordion', 'lists']),
    isMisfitsTheme: PropTypes.bool,
};

LinkLists.defaultProps = {
    as: 'accordion',
    isMisfitsTheme: true,
};

const useShowMinimalFooter = () => {
    const isQuizSummaryRoute = useMatch('/join/quiz/summary');
    const isAccountFormRoute = useMatch('/join/account');
    const isDeliveryFormRoute = useMatch('/join/delivery');
    const isPaymentFormRoute = useMatch('/join/payment');

    return isQuizSummaryRoute || isAccountFormRoute || isDeliveryFormRoute || isPaymentFormRoute;
};

export function Footer() {
    const tailwindTheme = useTailwindTheme();
    const isSmallScreen = useMediaQuery({ maxWidth: tailwindTheme?.screens?.lg });
    const { isImperfectTheme, isMisfitsTheme } = useGlobalStore();
    const showMinimalFooter = useShowMinimalFooter();
    const isAcquisitionPDP = !!useMatch('/:slug/p');

    return (
        <footer
            className={clsx(
                'border-t-4 border-lime bg-malt text-body-desktop-sm if:border-strawberry if:bg-off-white',
                { 'py-30': isSmallScreen, 'py-60': !isSmallScreen },
                { 'pb-[100px]': isSmallScreen && isAcquisitionPDP }
            )}
        >
            <div className="px-30">
                {isSmallScreen ? (
                    <>
                        {!showMinimalFooter && <LinkLists isMisfitsTheme={isMisfitsTheme} />}
                        <div className="my-20 flex flex-col items-center">
                            <div className="mb-50 flex gap-15">
                                <AppStores isMisfitsTheme={isMisfitsTheme} />
                            </div>
                            {!showMinimalFooter && (
                                <div className="mb-50 flex gap-30">
                                    <Socials isMisfitsTheme={isMisfitsTheme} />
                                </div>
                            )}
                            <div className="mb-20 flex flex-col items-center">
                                <Logos isMisfitsTheme={isMisfitsTheme} />
                            </div>
                            <Legals isMisfitsTheme={isMisfitsTheme} />
                        </div>
                    </>
                ) : (
                    <div className="mx-auto flex max-w-[1200px] gap-50">
                        <div className={clsx({ 'flex items-end gap-20': showMinimalFooter })}>
                            {showMinimalFooter ? (
                                <div className="flex flex-col">
                                    <Logos isMisfitsTheme={isMisfitsTheme} />
                                </div>
                            ) : (
                                <Logos isMisfitsTheme={isMisfitsTheme} />
                            )}
                            <Legals isMisfitsTheme={isMisfitsTheme} center={false} />
                        </div>
                        {!showMinimalFooter && (
                            <>
                                <LinkLists isMisfitsTheme={isMisfitsTheme} as="lists" />
                                <div>
                                    <h5 className="text-body-sm mb-15 font-grotesk-bold">Download our app</h5>
                                    <div className="flex gap-20">
                                        <AppStores isMisfitsTheme={isMisfitsTheme} />
                                    </div>
                                    <h5 className="text-body-sm mb-15 mt-35 font-grotesk-bold">
                                        Let&apos;s Get Social
                                    </h5>
                                    <div className="flex gap-20">
                                        <Socials isMisfitsTheme={isMisfitsTheme} />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
            {isImperfectTheme && !(!isSmallScreen && showMinimalFooter) && (
                <div
                    className={clsx({
                        'text-center': isSmallScreen,
                        'mr-30 text-right': !isSmallScreen,
                    })}
                >
                    <img
                        src="https://img-cdn.misfitsmarket.com/boysenberry/logos/b-corp-logo.svg"
                        alt="Imperfect Foods is a B Corp compliant company."
                        className="inline"
                    />
                </div>
            )}
        </footer>
    );
}
