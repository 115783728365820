import useAccountQuery from 'api/queries/useAccountQuery';
import { ifLinks, mmLinks } from 'constants/links';
import { unbundledWineFaqs, wineFaqs } from 'data/faqData';
import { Accordion } from 'honeydew/Accordion';
import useGlobalStore from 'hooks/useGlobalStore';

function WineFaqs() {
    const { isMisfitsTheme } = useGlobalStore();
    const { data: account } = useAccountQuery();
    const { unbundledWine: unbundleWineFeatureFlag } = account?.featureFlags ?? {};

    return (
        <div className="my-40">
            <h3 className="text-heading-sm mb-15">FAQs</h3>
            <Accordion items={unbundleWineFeatureFlag ? unbundledWineFaqs : wineFaqs} />
            <div className="mt-30 flex">
                <img className="mr-20 w-[45px]" src="https://img-cdn.misfitsmarket.com/images/Help.svg" alt="Help" />
                <p className="text-body-sm">
                    Still have more questions?
                    <br />
                    <a
                        className="link mt-10 text-body-mobile-md"
                        target="_blank"
                        rel="noreferrer"
                        href={isMisfitsTheme ? mmLinks.faqs : ifLinks.faqs}
                    >
                        We have even more FAQs here.
                    </a>
                </p>
            </div>
            <p className="text-body-sm mt-30">
                All wine orders are reviewed, accepted, and fulfilled by Hemispheres, LLC, 8418 S Lac Jac, Parlier, CA
                93648. Wine orders will automatically charge when your shopping window closes. Must be 21+ to purchase
                alcoholic beverages.
            </p>
        </div>
    );
}

export default WineFaqs;
