import './init';

import React from 'react';
import ReactDOM from 'react-dom/client';
import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Amplify } from 'aws-amplify';
import * as Loaders from 'loaders';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { SignupQuizSteps } from 'constants/signup';
import { AmplitudeExperimentProvider } from 'contexts/AmplitudeExperimentContext';
import { AuthProvider } from 'contexts/AuthContext';
import { GlobalProvider } from 'contexts/GlobalContext';
import { PromoCodeProvider } from 'contexts/PromoCodeContext';
import { MembershipStateProvider } from 'features/paid-membership/MembershipStateContext';
import Cart from 'features/shop/cart/Cart';
import * as Layouts from 'layouts';
import * as AccountFoodPreferences from 'pages/account/FoodPreferences';
import * as AccountHome from 'pages/account/Home';
import * as AccountMembershipPage from 'pages/account/Membership';
import * as AccountMisfitsPerks from 'pages/account/MisfitsPerks';
import * as AccountMyPlan from 'pages/account/MyPlan';
import * as AccountNotifications from 'pages/account/Notifications';
import * as AccountOrderConfirmation from 'pages/account/OrderConfirmation';
import * as AccountOrderDetails from 'pages/account/OrderDetails';
import * as AccountOrderFeedback from 'pages/account/OrderFeedback';
import * as AccountOrderHistory from 'pages/account/OrderHistory';
import * as AccountOrderTracking from 'pages/account/OrderTracking';
import * as AccountProfile from 'pages/account/Profile';
import * as AccountPromotions from 'pages/account/Promotions';
import * as AccountReferrals from 'pages/account/Referrals';
import * as AccountUpcomingDeliveries from 'pages/account/UpcomingDeliveries';
import * as AcquisitionPDP from 'pages/AcquisitionPDP';
import * as ErrorPage from 'pages/Error';
import * as IFUpgrade from 'pages/IFUpgrade';
import * as ForgotPassword from 'pages/login/ForgotPassword';
import * as Login from 'pages/login/Login';
import * as Logout from 'pages/login/Logout';
import * as NoMatchPage from 'pages/NoMatchPage';
import * as ShopAisle from 'pages/shop/Aisle';
import * as ShopAisleAll from 'pages/shop/AisleAll';
import * as Brand from 'pages/shop/Brand';
import * as BuyItAgain from 'pages/shop/BuyItAgain';
import * as MemberDeals from 'pages/shop/MemberDeals';
import * as ShopProductDetailPage from 'pages/shop/ProductDetailPage';
import * as Search from 'pages/shop/Search';
import * as ShopWithPoints from 'pages/shop/ShopWithPoints';
import * as ShopStorefront from 'pages/shop/Storefront';
import * as SignupAccountForm from 'pages/signup/AccountForm';
import * as SignupConfirmation from 'pages/signup/Confirmation';
import * as SignupDeliveryForm from 'pages/signup/DeliveryForm';
import * as SignupQuizAisleSelection from 'pages/signup/dietary-preference-quiz/AisleSelection';
import * as SignupQuizDietaryPreferenceNeeds from 'pages/signup/dietary-preference-quiz/DietaryPreferenceNeeds';
import * as SignupQuizHouseholdMeals from 'pages/signup/dietary-preference-quiz/HouseholdMeals';
import * as SignupQuizShoppingPlan from 'pages/signup/dietary-preference-quiz/ShoppingPlan';
import * as SignupLeadCaptureForm from 'pages/signup/leadCapture/Form';
import * as SignupPaymentForm from 'pages/signup/PaymentForm';
import * as SupplierPortal from 'pages/SupplierPortal';
import { getIsProduction } from 'utils';
import { initialize as initializeSentry } from 'utils/sentryUtils';

import App from './App';
import { AMPLITUDE_KEY_PROD, AMPLITUDE_KEY_STAGING } from './constants/publicKeys';
import { GTMPlugin } from './utils/analyticsUtils';
import authorizeByToken from './utils/authorizeByToken';
import logError from './utils/errorUtils';

import './index.css';

initializeSentry();

const env = getIsProduction() ? 'PROD' : 'DEV';
const client = window.isNativeApp ? 'NATIVE' : 'WEB';

const AWS_COGNITO_USER_POOL_ID = import.meta.env[`VITE_${env}_AWS_COGNITO_USER_POOL_ID`];
const AWS_COGNITO_CLIENT_ID = import.meta.env[`VITE_${env}_AWS_COGNITO_${client}_CLIENT_ID`];
const AWS_COGNITO_REGION = import.meta.env[`VITE_${env}_AWS_COGNITO_REGION`];

Amplify.configure({
    Auth: {
        region: AWS_COGNITO_REGION,
        userPoolId: AWS_COGNITO_USER_POOL_ID,
        userPoolWebClientId: AWS_COGNITO_CLIENT_ID,
        mandatorySignIn: true,
        authenticationFlowType: 'USER_PASSWORD_AUTH',
    },
});

const urlParams = new URLSearchParams(window.location.search);
const nativeApp = urlParams.get('app') || false;
const ifRedirect = urlParams.get('ifredirect') || false;
const tokens = {
    idToken: urlParams.get('idToken') || '',
    accessToken: urlParams.get('accessToken') || '',
    refreshToken: urlParams.get('refreshToken') || '',
};

const setWebviewAuth = async (t) => {
    await authorizeByToken(t);
};

if ((nativeApp === 'true' || ifRedirect === 'true') && tokens) {
    setWebviewAuth(tokens);
} else if (window.isNativeApp && window.appTokens) {
    setWebviewAuth(window.appTokens);
} else if (window.imperfectFoodsMobile?.isNativeApp) {
    // old IF app users that need to download hazelnut, TODO: remove when all app users are updated
    IFUpgrade.Component();
}

const queryClient = new QueryClient({
    defaultOptions: { queries: { staleTime: 4000 } },
});

try {
    // default setting is "Warn": https://www.docs.developers.amplitude.com/data/sdks/typescript-browser/#debugging
    amplitude.init(env === 'PROD' ? AMPLITUDE_KEY_PROD : AMPLITUDE_KEY_STAGING, {
        // Logging currently disabled
        // logLevel: 'DEBUG',
        defaultTracking: {
            attribution: true,
            pageViews: false,
            sessions: true,
            formInteractions: false,
            fileDownloads: false,
        },
    });

    const sessionReplayTracking = sessionReplayPlugin({
        forceSessionTracking: false,
        sampleRate: 0.05,
    });

    amplitude.add(sessionReplayTracking);
} catch (e) {
    logError(e);
    console.error(`Error loading amplitude. Contact engineering. ${e.toString()}`);
}

amplitude.add(new GTMPlugin());

window.amplitude = amplitude;

const isImperfect = window.location.hostname.includes('imperfectfoods');
const brandName = isImperfect ? 'Imperfect Foods' : 'Misfits Market';

const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <PromoCodeProvider>
                <App />
            </PromoCodeProvider>
        ),
        id: 'customer',
        errorElement: <ErrorPage.Component fullScreen />,
        children: [
            {
                element: <Layouts.AnalyticsLayout />,
                errorElement: <ErrorPage.Component fullScreen />,
                children: [
                    {
                        path: '*',
                        lazy: NoMatchPage.Component,
                    },
                    {
                        path: '/join',
                        loader: Loaders.signupLoader(queryClient),
                        children: [
                            {
                                index: true,
                                lazy: SignupLeadCaptureForm.Component,
                                handle: {
                                    meta: {
                                        pageTitle: `Sign Up For Online Grocery Delivery | ${brandName}`,
                                        pageDescription: `Cut your weekly grocery bill by joining ${
                                            isImperfect ? 'Imperfect Foods' : "Misfits Market's"
                                        } online grocery delivery service. Save up to 30% on organic groceries and fresh produce near you.`,
                                    },
                                },
                            },
                            {
                                path: 'quiz',
                                element: <Layouts.SignupQuizLayout />,
                                children: [
                                    {
                                        path: SignupQuizSteps.HOUSEHOLD_MEALS,
                                        lazy: SignupQuizHouseholdMeals.Component,
                                    },
                                    {
                                        path: SignupQuizSteps.DIETARY_PREFERENCE_NEEDS,
                                        lazy: SignupQuizDietaryPreferenceNeeds.Component,
                                    },
                                    {
                                        path: SignupQuizSteps.AISLE_SELECTION,
                                        lazy: SignupQuizAisleSelection.Component,
                                    },
                                    {
                                        path: SignupQuizSteps.SHOPPING_PLAN,
                                        lazy: SignupQuizShoppingPlan.Component,
                                    },
                                ],
                            },
                            {
                                element: <Layouts.AccountCreationLayout />,
                                children: [
                                    {
                                        path: 'account',
                                        lazy: SignupAccountForm.Component,
                                    },
                                    {
                                        path: 'delivery',
                                        lazy: SignupDeliveryForm.Component,
                                    },
                                    {
                                        path: 'payment',
                                        lazy: SignupPaymentForm.Component,
                                    },
                                ],
                            },
                            {
                                path: 'confirmation',
                                lazy: SignupConfirmation.Component,
                            },
                        ],
                    },
                    {
                        path: '/login',
                        children: [
                            {
                                element: <Layouts.LoginLayout />,
                                children: [
                                    {
                                        index: true,
                                        element: <Login.Component />,
                                        handle: {
                                            meta: {
                                                pageTitle: 'Login',
                                            },
                                        },
                                    },
                                    {
                                        path: 'forgot-password',
                                        element: <ForgotPassword.Component />,
                                        handle: {
                                            meta: {
                                                pageTitle: 'Forgot Password',
                                            },
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        element: <Layouts.AuthRequiredLayout />,
                        loader: Loaders.authRequiredLoader(queryClient),
                        children: [
                            {
                                path: '/shop',
                                children: [
                                    {
                                        element: <Layouts.ShopLayout />,
                                        children: [
                                            {
                                                index: true,
                                                lazy: ShopStorefront.Component,
                                                handle: {
                                                    meta: {
                                                        pageTitle: 'Featured',
                                                    },
                                                },
                                                action: ShopAisle.action(queryClient),
                                                errorElement: <ErrorPage.Component />,
                                            },
                                            {
                                                path: 'browse/misfits+perks',
                                                lazy: ShopWithPoints.Component,
                                                children: [
                                                    {
                                                        path: 'redeemed',
                                                        lazy: ShopWithPoints.Component,
                                                        handle: {
                                                            meta: {
                                                                pageTitle: 'Shop Misfits Perks Redeemed',
                                                            },
                                                        },
                                                    },
                                                ],
                                                handle: {
                                                    meta: {
                                                        pageTitle: 'Shop Misfits Perks',
                                                    },
                                                },
                                                errorElement: <ErrorPage.Component />,
                                            },
                                            {
                                                path: 'brand/:brand',
                                                lazy: Brand.Component,
                                                handle: {
                                                    meta: {
                                                        pageTitle: 'Brand',
                                                    },
                                                },
                                                errorElement: <ErrorPage.Component />,
                                            },
                                            {
                                                path: 'aisle/buy-it-again',
                                                lazy: BuyItAgain.Component,
                                                handle: {
                                                    meta: {
                                                        pageTitle: 'Buy It Again',
                                                    },
                                                },
                                                errorElement: <ErrorPage.Component />,
                                            },
                                            {
                                                path: 'aisle/member-deals',
                                                lazy: MemberDeals.Component,
                                                handle: {
                                                    meta: {
                                                        pageTitle: 'Member Deals',
                                                    },
                                                },
                                                errorElement: <ErrorPage.Component />,
                                            },
                                            {
                                                // this is the old Shop with Points url
                                                path: 'aisle/misfits+perks',
                                                exact: true,
                                                lazy: ShopWithPoints.Component,
                                                handle: {
                                                    meta: {
                                                        pageTitle: '[Deprecated] Shop Misfits Perks',
                                                    },
                                                },
                                                errorElement: <ErrorPage.Component />,
                                            },
                                            {
                                                path: 'aisle/all',
                                                lazy: ShopAisleAll.Component,
                                                handle: {
                                                    meta: {
                                                        pageTitle: 'Shop All Aisle',
                                                    },
                                                },
                                                errorElement: <ErrorPage.Component />,
                                            },
                                            {
                                                path: 'aisle/:category/:subcategory',
                                                lazy: ShopAisle.Component,
                                                action: ShopAisle.action(queryClient),
                                                handle: {
                                                    meta: {
                                                        pageTitle: 'Aisle',
                                                    },
                                                },
                                                errorElement: <ErrorPage.Component />,
                                            },
                                            {
                                                path: 'search',
                                                lazy: Search.Component,
                                                handle: {
                                                    meta: {
                                                        pageTitle: 'Search',
                                                    },
                                                },
                                                errorElement: <ErrorPage.Component />,
                                            },
                                        ],
                                    },
                                    {
                                        element: <Layouts.ProductDetailPageLayout />,
                                        children: [
                                            {
                                                path: 'product/:product',
                                                lazy: ShopProductDetailPage.Component,
                                                action: ShopProductDetailPage.action(queryClient),
                                                handle: {
                                                    meta: {
                                                        pageTitle: 'Product',
                                                    },
                                                },
                                                errorElement: <ErrorPage.Component />,
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'cart',
                                element: <Cart />,
                            },
                            {
                                path: '/account',
                                element: <Layouts.AccountLayout />,
                                children: [
                                    {
                                        index: true,
                                        lazy: AccountHome.Component,
                                        handle: {
                                            meta: {
                                                pageTitle: 'My Account',
                                            },
                                        },
                                        errorElement: <ErrorPage.Component />,
                                    },
                                    {
                                        path: 'profile',
                                        lazy: AccountProfile.Component,
                                        action: AccountProfile.action(queryClient),
                                        handle: {
                                            meta: {
                                                pageTitle: 'My Profile',
                                            },
                                        },
                                        errorElement: <ErrorPage.Component />,
                                    },
                                    // outgoing My Profile page path. can be removed when native app is updated.
                                    {
                                        path: 'details',
                                        lazy: AccountProfile.Component,
                                        action: AccountProfile.action(queryClient),
                                        handle: {
                                            meta: {
                                                pageTitle: 'My Profile',
                                            },
                                        },
                                        errorElement: <ErrorPage.Component />,
                                    },
                                    {
                                        path: 'orders',
                                        lazy: AccountOrderHistory.Component,
                                        handle: {
                                            meta: {
                                                pageTitle: 'Orders',
                                            },
                                        },
                                        errorElement: <ErrorPage.Component />,
                                    },
                                    {
                                        path: 'orders/details',
                                        lazy: AccountOrderConfirmation.Component,
                                        action: AccountOrderConfirmation.action(queryClient),
                                        handle: {
                                            meta: {
                                                pageTitle: 'Order Confirmation',
                                            },
                                        },
                                        errorElement: <ErrorPage.Component />,
                                    },
                                    {
                                        path: 'orders/details/:id/:box/report',
                                        lazy: AccountOrderFeedback.Component,
                                        handle: {
                                            meta: {
                                                pageTitle: 'Order Feedback',
                                            },
                                        },
                                        errorElement: <ErrorPage.Component />,
                                    },
                                    {
                                        path: 'orders/details/:id/:box/track',
                                        lazy: AccountOrderTracking.Component,
                                        handle: {
                                            meta: {
                                                pageTitle: 'Order Tracking',
                                            },
                                        },
                                        errorElement: <ErrorPage.Component />,
                                    },
                                    {
                                        path: 'orders/details/:id',
                                        lazy: AccountOrderDetails.Component,
                                        handle: {
                                            meta: {
                                                pageTitle: 'Order Details',
                                            },
                                        },
                                        errorElement: <ErrorPage.Component />,
                                    },
                                    {
                                        path: 'upcoming-deliveries',
                                        lazy: AccountUpcomingDeliveries.Component,
                                        handle: {
                                            meta: {
                                                pageTitle: 'Upcoming Deliveries',
                                            },
                                        },
                                        errorElement: <ErrorPage.Component />,
                                    },
                                    // Old Upcoming Deliveries page path. Can be removed eventually.
                                    {
                                        path: 'delivery-schedule',
                                        lazy: AccountUpcomingDeliveries.Component,
                                        handle: {
                                            meta: {
                                                pageTitle: 'Upcoming Deliveries',
                                            },
                                        },
                                        errorElement: <ErrorPage.Component />,
                                    },
                                    {
                                        path: 'food-preferences',
                                        lazy: AccountFoodPreferences.Component,
                                        handle: {
                                            meta: {
                                                pageTitle: 'Food Preferences',
                                            },
                                        },
                                        errorElement: <ErrorPage.Component />,
                                    },
                                    {
                                        path: 'promotions',
                                        lazy: AccountPromotions.Component,
                                        action: AccountPromotions.action(queryClient),
                                        handle: {
                                            meta: {
                                                pageTitle: 'Promotions',
                                            },
                                        },
                                        errorElement: <ErrorPage.Component />,
                                    },
                                    // outgoing My Plan page path. can be removed when native app is updated.
                                    {
                                        path: 'my-plan',
                                        lazy: AccountMyPlan.Component,
                                        handle: {
                                            meta: {
                                                pageTitle: 'My Plan',
                                            },
                                        },
                                        errorElement: <ErrorPage.Component />,
                                    },
                                    {
                                        path: 'plan',
                                        lazy: AccountMyPlan.Component,
                                        handle: {
                                            meta: {
                                                pageTitle: 'My Plan',
                                            },
                                        },
                                        errorElement: <ErrorPage.Component />,
                                    },
                                    {
                                        path: 'referrals',
                                        lazy: AccountReferrals.Component,
                                        action: AccountReferrals.action,
                                        handle: {
                                            meta: {
                                                pageTitle: 'Referrals',
                                            },
                                        },
                                        errorElement: <ErrorPage.Component />,
                                    },
                                    {
                                        path: 'misfits-perks',
                                        lazy: AccountMisfitsPerks.Component,
                                        handle: {
                                            meta: {
                                                pageTitle: 'Misfits Perks',
                                            },
                                        },
                                        errorElement: <ErrorPage.Component />,
                                    },
                                    {
                                        path: 'notifications',
                                        lazy: AccountNotifications.Component,
                                        handle: {
                                            meta: {
                                                pageTitle: 'Notifications',
                                            },
                                        },
                                        errorElement: <ErrorPage.Component />,
                                    },
                                    {
                                        path: 'membership',
                                        lazy: AccountMembershipPage.Component,
                                        handle: {
                                            meta: {
                                                pageTitle: 'Membership',
                                            },
                                        },
                                        errorElement: <ErrorPage.Component />,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            // These pages won't fire analytics events
            {
                path: 'logout',
                element: <Logout.Component />,
            },
            {
                path: ':slug/p',
                element: <Layouts.AcquisitionPDPLayout />,
                children: [
                    {
                        index: true,
                        lazy: AcquisitionPDP.Component,
                        errorElement: <ErrorPage.Component />,
                    },
                ],
            },
            {
                path: 'supplier-portal',
                lazy: SupplierPortal.Component,
            },
        ],
    },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <AuthProvider>
            <QueryClientProvider client={queryClient}>
                <GlobalProvider>
                    <MembershipStateProvider>
                        <AmplitudeExperimentProvider>
                            <RouterProvider router={router} />
                            <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
                        </AmplitudeExperimentProvider>
                    </MembershipStateProvider>
                </GlobalProvider>
            </QueryClientProvider>
        </AuthProvider>
    </React.StrictMode>
);
