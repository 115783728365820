import { createContext, useMemo, useState } from 'react';

const MembershipStateContext = createContext(null);

function MembershipStateProvider({ children }) {
    const [joinedPlusMembershipTrial, setJoinedPlusMembershipTrial] = useState(false);
    const [cancelledTrial, setCancelledTrial] = useState(false);
    const [startedPlusMembership, setStartedPlusMembership] = useState(false);

    const reset = () => {
        setJoinedPlusMembershipTrial(false);
        setCancelledTrial(false);
        setStartedPlusMembership(false);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const joinPlusTrial = () => {
        reset();
        setJoinedPlusMembershipTrial(true);
    };

    const cancelPlusTrial = () => {
        reset();
        setCancelledTrial(true);
    };

    const joinPlusMembership = () => {
        reset();
        setStartedPlusMembership(true);
    };

    const contextValue = useMemo(
        () => ({
            joinedPlusMembershipTrial,
            cancelledTrial,
            startedPlusMembership,
            joinPlusTrial,
            cancelPlusTrial,
            joinPlusMembership,
            reset,
        }),
        [joinedPlusMembershipTrial, cancelledTrial, startedPlusMembership]
    );

    return <MembershipStateContext.Provider value={contextValue}>{children}</MembershipStateContext.Provider>;
}

export { MembershipStateContext, MembershipStateProvider };
