import { Suspense, useEffect } from 'react';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';

import useSubscriptionQuery from 'api/queries/useSubscriptionQuery';
import PerksFAQsModule from 'features/account/misfits-perks/PerksFAQsModule';
import { PlusMembershipFaq } from 'features/paid-membership';
import { Divider } from 'honeydew/Divider';
import AvatarIcon from 'honeydew/icons/Avatar';
import Loader from 'honeydew/Loader';
import useGlobalStore from 'hooks/useGlobalStore';
import useSmartBanner from 'hooks/useSmartBanner';
import { STICKY_SHOP_NAVIGATION_STYLES } from 'hooks/useStickyShopNavigation';
import useTailwindTheme from 'hooks/useTailwindTheme';
import useValidReferralsCampaign from 'hooks/useValidReferralsCampaign';
import AccountLinks from 'shared/AccountLinks';
import ServiceDelayBanners from 'shared/ServiceDelayBanners';

function AccountLayout() {
    const { data: subscription } = useSubscriptionQuery();
    const tailwindTheme = useTailwindTheme();
    const mobile = useMediaQuery({ maxWidth: tailwindTheme?.screens?.md });
    const desktop = useMediaQuery({ minWidth: tailwindTheme?.screens?.md });
    const { isMisfitsTheme } = useGlobalStore();
    const isPerksRoute = !!useMatch('/account/misfits-perks');
    const isMembershipRoute = !!useMatch('/account/membership');
    const isAccountHomeRoute = !!useMatch('/account');
    const isFoodPreferencesRoute = !!useMatch('/account/food-preferences');
    const navigate = useNavigate();
    const { addSmartbannerPadding } = useSmartBanner();

    // if there's a valid campaign, the nano banner at the top of the page will hide/show on scroll
    // and the padding on this layout will account for it
    const validReferralsCampaign = useValidReferralsCampaign();

    useEffect(() => {
        // add redirect from old upcoming deliveries page to new url
        if (window.location.pathname === '/account/delivery-schedule') navigate('/account/upcoming-deliveries');
    }, [navigate, window.location.pathname]);

    return (
        <main
            className={clsx('bg-mm-malt pb-40 if:bg-off-white', {
                'pt-[110px]': !window.isNativeApp && !validReferralsCampaign,
                'pt-[182px]': !window.isNativeApp && validReferralsCampaign,
                'pt-[40px]': window.isNativeApp && !validReferralsCampaign,
                'pt-[100px]': window.isNativeApp && validReferralsCampaign,
                [STICKY_SHOP_NAVIGATION_STYLES.paddingTopNoNanobanner]: !validReferralsCampaign && desktop,
                [STICKY_SHOP_NAVIGATION_STYLES.paddingTopNanobanner]: validReferralsCampaign && desktop,
                'pt-[170px]': addSmartbannerPadding,
            })}
        >
            <div className="mx-auto flex max-w-[1140px] px-20 md:gap-20 lg:gap-80">
                {!mobile && (
                    <div id="desktop-navigation-side-menu" className="min-w-[200px]">
                        <div className="flex flex-col items-center">
                            <AvatarIcon isMisfits={isMisfitsTheme} />
                            <p className="mt-20 font-grotesk-bold">
                                {subscription?.shippingAddr?.firstName ? (
                                    <>{subscription.shippingAddr.firstName}&apos;s Account</>
                                ) : (
                                    <>My Account</>
                                )}
                            </p>
                        </div>
                        <Divider height={1} color="grey-light" />
                        <AccountLinks sideMenu />
                    </div>
                )}

                <div className="grow">
                    <Suspense fallback={<Loader />}>
                        {!isAccountHomeRoute && !isFoodPreferencesRoute && <ServiceDelayBanners className="mb-20" />}
                        <Outlet />
                    </Suspense>
                </div>
            </div>

            {!mobile && isPerksRoute && (
                <section className="mt-20 bg-white">
                    <div className="mx-auto w-[784px] px-25">
                        <PerksFAQsModule />
                    </div>
                </section>
            )}

            {isMembershipRoute && (
                <section className="mt-20 bg-white">
                    <div className="mx-auto max-w-[784px] px-25">
                        <PlusMembershipFaq />
                    </div>
                </section>
            )}
        </main>
    );
}

export default AccountLayout;
