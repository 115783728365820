import PropTypes from 'prop-types';

import { IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN } from 'constants/theme';

export function ShopAll({ brand, isActive, width }) {
    const boxColor1 = brand === MISFITS_THEME_DOMAIN ? '#F2EBD1' : '#ECE7E4';
    const boxColor2 = brand === MISFITS_THEME_DOMAIN ? '#B0C472' : '#C5DB66';
    const activeColor = brand === MISFITS_THEME_DOMAIN ? '#D7E1B8' : '#F0D3E3';

    return isActive ? (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.6505 28.7652C21.902 26.9646 29 22.6358 29 14.9992C29 7.3625 22.9452 2.7363 14.6505 1.1728C7.31233 -0.213857 2.48978 6.96057 1.13553 14.967C-0.132106 22.475 7.73356 30.4815 14.6505 28.7652Z"
                fill={activeColor}
            />
            <path
                d="M2 12.65L7.91 13.43L10.27 9.76997L4.5 8.96997L2 12.65Z"
                fill={boxColor1}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.43055 8.96001L3.06055 6.76001L19.1305 8.99001L20.5005 11.19L4.43055 8.96001Z"
                fill={boxColor2}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.5002 11.18L4.4302 8.94995L2.2002 24.9799L18.2702 27.2099L20.5002 11.19V11.18Z"
                fill={boxColor1}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26.3995 12.0001L20.4995 11.1801L18.2695 27.2001L24.1695 28.0201L26.3995 12.0001Z"
                fill={boxColor2}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M27.82 16.2101L21.92 15.3901L20.5 11.1801L26.4 12.0001L27.83 16.2101H27.82Z"
                fill={boxColor1}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.2803 6.46C16.3903 5.39 16.5703 3 16.5703 3"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.6504 8.30001C22.1504 7.80001 23.2104 6.64001 23.2104 6.64001"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.85 5.84995C9.6 5.17995 9 3.69995 9 3.69995"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ) : (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.23035 6.2L2.86035 4L18.9304 6.23L20.3004 8.43L4.23035 6.2Z"
                fill={boxColor2}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.3 8.42995L4.23 6.19995L2 22.2199L18.07 24.45L20.3 8.42995Z"
                fill={boxColor1}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26.2003 9.24004L20.3003 8.42004L18.0703 24.44L23.9703 25.26L26.2003 9.24004Z"
                fill={boxColor2}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M29.1498 7.44L23.2498 6.62L20.2998 8.43L26.1998 9.25L29.1498 7.44Z"
                fill={boxColor1}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

ShopAll.propTypes = {
    brand: PropTypes.oneOf([IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN]).isRequired,
    isActive: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ShopAll.defaultProps = {
    isActive: false,
    width: 30,
};
