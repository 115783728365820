import { useState } from 'react';
import { InformationCircleIcon as InformationCircleIconOutline } from '@heroicons/react/24/outline';
import { BanknotesIcon, CurrencyDollarIcon, InformationCircleIcon, StarIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { ALCOHOL_SERVICE_FEE, MIN_WINE_BOTTLE_QTY_TO_SHIP, MIN_WINE_BOTTLE_QTY_TO_WAIVE_FEE } from 'constants/shop';
import CartDiscountCodeForm from 'features/shop/cart/CartDiscountCodeForm';
import { TaxAndFeesModal } from 'features/shop/cart/TaxAndFeesModal';
import Button from 'honeydew/Button/Button';
import DriverTipsInfoModal from 'shared/driver-tips/DriverTipsInfoModal';
import EditDriverTipModal from 'shared/driver-tips/EditDriverTipModal';
import {
    filterPerkSelections,
    getAppliedDiscounts,
    getCartSubTotal,
    getColdPackItems,
    getGroceryItems,
    getGrocerySubTotal,
    getItemsTotalCost,
    getMSRP,
    getShowOperationsFee,
    getTotalWineBottleQty,
} from 'utils/orderUtils';
import {
    getCartAppliedCredits,
    getCartEstimatedTaxAndFees,
    getCartTotal,
    getUnmetReferralDiscount,
    PriceType,
} from 'utils/priceUtils';

function CartPrices({ account, coldPackSectionRef, credits, discounts, selections, wineOrderCardRef }) {
    const [driverTipsModalOpen, setDriverTipsModalOpen] = useState(false);
    const [editDriverTipsModalOpen, setEditDriverTipsModalOpen] = useState(false);
    const [taxAndFeesModalOpen, setTaxAndFeesModalOpen] = useState(false);

    const filteredSelections = filterPerkSelections(selections);
    const { cartMinimum, featureFlags, loyaltyEligible, nextOrder, preferredTipVal, proteinMinimum, shippingFee } =
        account;
    const { coldKeeperFee, freeShippingEligible, freeShippingThreshold, operationsFee = 0 } = nextOrder.cartExperience;
    const { featureCanAddDriverTips } = featureFlags ?? {};
    const { sumCreditBalance } = credits;
    const { chargeTipAmount, estimatedLoyaltyPoints, selected, selectedBundles, shipping } = filteredSelections;
    const groceryItems = getGroceryItems(selections);
    const groceryItemsTotalCost = getItemsTotalCost(groceryItems);
    const wineDiscount = selections?.alcoholDiscountAmount;
    const winePercentOff = selections?.alcoholDiscountPercentage;
    const groceryMinMet = cartMinimum <= groceryItemsTotalCost;
    const cartMSRP = getMSRP([...selected, ...selectedBundles]);
    const grocerySubtotal = getGrocerySubTotal(account, selections);
    const coldPackItems = getColdPackItems(filteredSelections);
    const coldPackItemsTotalCost = getItemsTotalCost(coldPackItems);
    const totalWineBottleQty = getTotalWineBottleQty(filteredSelections);
    const appliedDiscounts = getAppliedDiscounts(discounts, filteredSelections, account);
    const estimatedTax = groceryMinMet ? filteredSelections.estimatedTax : selections.estimatedAlcoholTax;
    const cartSubtotal = getCartSubTotal(account, filteredSelections);
    const savings = cartMSRP - cartSubtotal;

    const displayOneTimeOrderTip = chargeTipAmount !== preferredTipVal;
    const orderTip = displayOneTimeOrderTip ? chargeTipAmount : preferredTipVal;
    const tipAdded = orderTip > 0;

    const generateTipCopy = () => {
        if (displayOneTimeOrderTip && preferredTipVal === 0) return 'One-Time';

        if (displayOneTimeOrderTip && tipAdded) return 'Updated';

        if (!displayOneTimeOrderTip && tipAdded) return 'Default';

        return '';
    };

    const handleClickWaiveColdPackFee = () => {
        coldPackSectionRef?.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleClickWaveAlcoholFee = () => {
        wineOrderCardRef?.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const estimatedTaxAndFees = getCartEstimatedTaxAndFees({ account, selections });
    const showOperationsFee = getShowOperationsFee({ cartExperience: account?.nextOrder?.cartExperience });
    const unmetReferralDiscount = getUnmetReferralDiscount({ account, discounts, selections });

    const appliedCredits = getCartAppliedCredits({
        account,
        credits,
        discounts,
        options: { priceType: PriceType.DEFAULT },
        selections,
    });

    const cartTotal = getCartTotal({
        account,
        credits,
        discounts,
        options: { priceType: PriceType.DEFAULT },
        selections,
    });

    return (
        <div className="mb-10 bg-white px-15 py-10">
            <CartDiscountCodeForm />
            <div className="mt-10 flex justify-between">
                <p className="text-body-sm">Subtotal</p>
                <p className="text-body-sm">${cartSubtotal.toFixed(2)}</p>
            </div>
            {groceryItems.length > 0 && groceryMinMet && (
                <div className="mt-5 flex justify-between">
                    <p className="text-body-sm">Shipping</p>
                    {freeShippingEligible && (shipping === 0 || grocerySubtotal >= freeShippingThreshold) ? (
                        <div className="flex items-baseline gap-5">
                            <p className="text-body-sm line-through">${shippingFee?.toFixed(2)}</p>
                            <p className="text-body-sm text-brand-primary font-grotesk-bold">FREE</p>
                        </div>
                    ) : (
                        <p className="text-body-sm">${shipping.toFixed(2)}</p>
                    )}
                </div>
            )}
            {coldKeeperFee > 0 && coldPackItems.length > 0 && (
                <div className="mt-5 flex justify-between">
                    {coldPackItemsTotalCost >= proteinMinimum ? (
                        <>
                            <p className="text-body-sm">Cold Pack Fee</p>
                            <div className="flex items-baseline gap-5">
                                <p className="text-body-sm line-through">${coldKeeperFee}</p>
                                <p className="text-body-sm text-brand-primary font-grotesk-bold">WAIVED</p>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="flex items-baseline gap-5">
                                <p className="text-body-sm">Cold Pack Fee</p>
                                <Button className="link text-body-sm" onClick={handleClickWaiveColdPackFee}>
                                    Waive
                                </Button>
                            </div>
                            <p className="text-body-sm">${coldKeeperFee.toFixed(2)}</p>
                        </>
                    )}
                </div>
            )}
            {totalWineBottleQty >= MIN_WINE_BOTTLE_QTY_TO_SHIP && (
                <div className="mt-5 flex justify-between">
                    {totalWineBottleQty >= MIN_WINE_BOTTLE_QTY_TO_WAIVE_FEE ? (
                        <>
                            <p className="text-body-sm">Alcohol Service Fee</p>
                            <div className="flex items-baseline gap-5">
                                <p className="text-body-sm line-through">${ALCOHOL_SERVICE_FEE.toFixed(2)}</p>
                                <p className="text-body-sm text-brand-primary font-grotesk-bold">WAIVED</p>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="flex items-baseline gap-5">
                                <p className="text-body-sm">Alcohol Service Fee</p>
                                <Button className="link text-body-sm" onClick={handleClickWaveAlcoholFee}>
                                    Waive
                                </Button>
                            </div>
                            <p className="text-body-sm">${ALCOHOL_SERVICE_FEE.toFixed(2)}</p>
                        </>
                    )}
                </div>
            )}
            {appliedDiscounts.map((discount) => (
                <div className="mt-5 flex justify-between" key={discount.discountCode}>
                    <p className="text-body-sm text-brand-primary">{discount.discountCode}</p>
                    <p className="text-body-sm text-brand-primary">-${discount.dollarValue.toFixed(2)}</p>
                </div>
            ))}
            {wineDiscount > 0 && (
                <div className="mt-5 flex justify-between">
                    <p className="text-body-sm">Alcohol Savings (-{winePercentOff}%)</p>
                    <p className="text-body-sm">-${wineDiscount.toFixed(2)}</p>
                </div>
            )}
            <div className="mt-5 flex justify-between" data-testid="estimated-tax">
                <div className="flex items-center gap-5">
                    <p className="text-body-sm">{showOperationsFee ? 'Estimated Tax & Fees' : 'Estimated Tax'}</p>
                    <button
                        aria-label="Estimated tax and fees information"
                        onClick={() => setTaxAndFeesModalOpen(true)}
                        type="button"
                    >
                        <InformationCircleIconOutline className="mb-[2px]" width={18} />
                    </button>
                </div>
                <p className="text-body-sm">
                    ${showOperationsFee ? estimatedTaxAndFees.toFixed(2) : estimatedTax.toFixed(2)}
                </p>
            </div>
            {appliedCredits > 0 && groceryMinMet && (
                <div className="mt-5 flex justify-between" data-testid="applied-credits">
                    <p className="text-body-sm text-brand-primary">Credits</p>
                    <p className="text-body-sm text-brand-primary">-${appliedCredits.toFixed(2)}</p>
                </div>
            )}
            {featureCanAddDriverTips && (
                <div className="bg-malt-off-white -mx-15 mt-5  px-15 py-10">
                    <div className="flex justify-between">
                        <p className={clsx('flex', { 'text-body-xs': !tipAdded, 'text-body-sm': tipAdded })}>
                            <span className="notranslate font-grotesk-bold">
                                {generateTipCopy()} Driver Tip
                                {tipAdded && <span> (optional)</span>}
                            </span>
                            <button
                                type="button"
                                className="link ml-10"
                                onClick={() => {
                                    setEditDriverTipsModalOpen(true);
                                }}
                            >
                                {tipAdded
                                    ? 'Edit'
                                    : `Add a ${preferredTipVal === 0 ? 'one-time ' : ''}tip for your driver`}
                            </button>
                            <button type="button" onClick={() => setDriverTipsModalOpen(true)}>
                                <InformationCircleIcon width={18} className="ml-10 text-black" />
                            </button>
                        </p>
                        {tipAdded && <p className="text-body-sm">${orderTip?.toFixed(2)}</p>}
                    </div>
                    {tipAdded && (
                        <p className="text-body-xs w-[calc(100%_-_100px)] text-grey">
                            You can adjust your tip amount up to 24 hours after delivery.
                        </p>
                    )}
                </div>
            )}
            <div className="mt-10 flex justify-between" data-testid="total">
                <p className="text-body-md font-grotesk-bold">TOTAL</p>
                <p className="text-body-md font-grotesk-bold">${cartTotal.toFixed(2)}</p>
            </div>
            {savings > 0 && (
                <div className="mt-10 flex items-center">
                    <CurrencyDollarIcon className="h-15 w-15" />
                    <p className="text-body-sm ml-5">
                        <span className="text-brand-primary font-grotesk-bold">${savings.toFixed(2)}</span> saved
                    </p>
                </div>
            )}
            {sumCreditBalance > 0 && (
                <div className="mt-5 flex items-center">
                    <BanknotesIcon className="h-15 w-15" />
                    <p className="text-body-sm ml-5">
                        <span className="text-brand-primary font-grotesk-bold">${sumCreditBalance.toFixed(2)}</span>{' '}
                        credits available and automatically applied
                    </p>
                </div>
            )}
            {unmetReferralDiscount && (
                <div className="mt-5 flex items-start" data-testid="unmet-referral-discount">
                    <BanknotesIcon className="mt-[6px] size-15 shrink-0" />
                    <p className="text-body-sm ml-5">
                        <span className="text-brand-primary font-grotesk-bold">
                            ${unmetReferralDiscount.dollarDiscount?.toFixed(2)}
                        </span>{' '}
                        referral credits available to spend when your subtotal reaches{' '}
                        <span className="text-brand-primary font-grotesk-bold">
                            ${unmetReferralDiscount.thresholdDollars?.toFixed(2)}
                        </span>
                    </p>
                </div>
            )}
            {loyaltyEligible && estimatedLoyaltyPoints > 0 && (
                <div className="mt-5 flex items-center">
                    <StarIcon className="h-15 w-15" />
                    <p className="text-body-sm ml-5">
                        <span className="text-brand-primary font-grotesk-bold">{estimatedLoyaltyPoints}</span> points
                        earned
                    </p>
                </div>
            )}

            <DriverTipsInfoModal open={driverTipsModalOpen} setOpen={setDriverTipsModalOpen} />
            <TaxAndFeesModal
                estimatedTax={{
                    display: true,
                    value: estimatedTax,
                }}
                handleClose={() => setTaxAndFeesModalOpen(false)}
                open={taxAndFeesModalOpen}
                operationsFee={{
                    display: showOperationsFee,
                    value: operationsFee,
                }}
            />

            {featureCanAddDriverTips && (
                <EditDriverTipModal
                    chargeID={nextOrder.chargeID}
                    driverTipsModal={editDriverTipsModalOpen}
                    orderTotal={cartTotal + orderTip}
                    orderSubtotal={cartSubtotal}
                    closeEditDriverTipsModal={() => {
                        setEditDriverTipsModalOpen(false);
                    }}
                />
            )}
        </div>
    );
}

export default CartPrices;

CartPrices.propTypes = {
    account: PropTypes.shape({
        loyaltyEligible: PropTypes.bool,
        nextOrder: PropTypes.shape({
            cartExperience: PropTypes.shape({
                coldKeeperFee: PropTypes.number,
                freeShippingEligible: PropTypes.bool,
                freeShippingThreshold: PropTypes.number,
            }),
        }),
        proteinMinimum: PropTypes.number,
    }).isRequired,
    coldPackSectionRef: PropTypes.shape({
        current: PropTypes.instanceOf(Element),
    }),
    credits: PropTypes.shape({
        sumCreditBalance: PropTypes.number,
    }).isRequired,
    discounts: PropTypes.shape({}).isRequired,
    selections: PropTypes.shape({
        estimatedLoyaltyPoints: PropTypes.number,
        estimatedTax: PropTypes.number,
        selected: PropTypes.arrayOf(PropTypes.shape({})),
        selectedBundles: PropTypes.arrayOf(PropTypes.shape({})),
        shipping: PropTypes.number,
    }).isRequired,
    wineOrderCardRef: PropTypes.shape({
        current: PropTypes.instanceOf(Element),
    }),
};

CartPrices.defaultProps = {
    coldPackSectionRef: undefined,
    wineOrderCardRef: undefined,
};
