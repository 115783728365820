import { useEffect, useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import useAccountQuery from 'api/queries/useAccountQuery';
import { ActivateMembershipCTA } from 'features/paid-membership/ActivateMembershipCTA';
import { ActivateMembershipPricing } from 'features/paid-membership/ActivateMembershipPricing';
import { BenefitsListV2 } from 'features/paid-membership/BenefitsList';
import { JoinTermsAndConditions } from 'features/paid-membership/JoinTermsAndConditions';
import { Divider } from 'honeydew/Divider';
import Modal from 'honeydew/Modal';
import { useAccountPlusMembershipHook } from 'hooks/useAccountPlusMembershipHook';
import useGlobalStore from 'hooks/useGlobalStore';
import { trackEvent } from 'utils/analyticsUtils';

export function JoinModal({ onClose: onCloseProp, open }) {
    const { isMisfitsTheme } = useGlobalStore();
    const {
        availableCredits,
        availablePlusMembership,
        availableTrialMembership,
        averageMembershipSavings,
        averageMembershipSavingsPeriod,
        membershipRenewalDate,
        plusMembershipBranding,
        price: { annual: annualPlusMembershipCost },
    } = useAccountPlusMembershipHook();

    const { data: accountData = {} } = useAccountQuery();

    const [didTrackAnalyticEvent, setDidTrackAnalyticEvent] = useState(false);

    const memberDealsPath = window.location.pathname === '/shop/aisle/member-deals';

    const ctaCopy = availableTrialMembership ? 'Start free trial today' : `Confirm ${plusMembershipBranding}`;
    const trialDurationDays = availableTrialMembership?.trialDurationDays;

    const onClose = () => {
        setDidTrackAnalyticEvent(false);
        onCloseProp();
    };

    useEffect(() => {
        if (
            didTrackAnalyticEvent ||
            !open ||
            (!availableTrialMembership && !availablePlusMembership) ||
            !averageMembershipSavingsPeriod
        ) {
            return;
        }

        setDidTrackAnalyticEvent(true);

        if (availableTrialMembership) {
            trackEvent('open join free trial modal', {
                path: window.location.pathname,
                averageMembershipSavingsPeriod,
            });
        }

        if (availablePlusMembership) {
            trackEvent('open membership start modal', {
                path: window.location.pathname,
                averageMembershipSavingsPeriod,
            });
        }
    }, [
        didTrackAnalyticEvent,
        availableTrialMembership,
        availablePlusMembership,
        open,
        averageMembershipSavingsPeriod,
    ]);

    let headingCopy = `Save up to 50% with ${plusMembershipBranding}`;

    if (availableTrialMembership) {
        headingCopy = `Start your free trial to save up to 50% with ${plusMembershipBranding}`;
    }

    if (availableCredits?.credits) {
        headingCopy = `Get $${availableCredits?.credits} and save up to 50% with ${plusMembershipBranding}`;
    }

    return (
        <Modal
            onClose={onClose}
            open={open}
            bgColor="bg-plus-membership-gradient-50 md:bg-plus-membership-gradient-70"
            includeHorizontalPadding={false}
        >
            <div className="space-y-20 p-10 md:px-20">
                <div className="md:text-center">
                    {availableTrialMembership && (
                        <p className="font-grotesk-bold">Free {trialDurationDays}-day trial</p>
                    )}
                    <h1 className="text-heading-xs md:text-heading-desktop-xs">{headingCopy}</h1>
                </div>
                <div className="justify-center md:flex">
                    <p className="rounded-lg bg-white px-10 py-5 text-body-mobile-sm text-blueberry md:text-body-desktop-md">
                        Save an avg. of <span className="font-grotesk-bold">${averageMembershipSavings}</span> a{' '}
                        {averageMembershipSavingsPeriod} when shopping weekly
                    </p>
                </div>
                <BenefitsListV2 />
                {accountData?.marketplaceOpen && !memberDealsPath && (
                    <>
                        <Divider height={4} />
                        <Link
                            {...(!window.isNativeApp && { to: '/shop/aisle/member-deals' })}
                            className="flex items-center justify-between gap-10 text-body-mobile-xs text-grey md:text-body-desktop-sm"
                            onClick={() => {
                                trackEvent('membership start modal browse more click', {
                                    path: window.location.pathname,
                                });
                                if (window.isNativeApp) {
                                    window.ReactNativeWebView?.postMessage(JSON.stringify({ navigate: 'MemberDeals' }));
                                }
                            }}
                        >
                            <span>
                                Still considering?{' '}
                                <span className="font-grotesk-bold">Browse the exclusive deals here</span>{' '}
                            </span>

                            <ChevronRightIcon className="size-[24px]" />
                        </Link>
                    </>
                )}
            </div>
            <div className="mt-20 space-y-10 bg-off-white p-10 md:mx-20 md:rounded-lg">
                <ActivateMembershipPricing
                    availableCredits={availableCredits}
                    availableTrialMembership={availableTrialMembership}
                    annualPlusMembershipCost={annualPlusMembershipCost}
                />
                <ActivateMembershipCTA copy={ctaCopy} onActivate={onClose} size="md" />
                <JoinTermsAndConditions
                    annualPlusMembershipCost={annualPlusMembershipCost}
                    brand={isMisfitsTheme ? 'Misfits Market' : 'Imperfect Foods'}
                    plusMembershipBranding={plusMembershipBranding}
                    availableCredits={availableCredits}
                    availableTrialMembership={availableTrialMembership}
                    membershipRenewalDate={membershipRenewalDate}
                />
            </div>
        </Modal>
    );
}

JoinModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};
