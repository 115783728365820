import useAccountQuery from 'api/queries/useAccountQuery';
import { usePlusMembershipOptionsQuery } from 'api/queries/usePlusMembershipOptionsQuery';
import {
    AVERAGE_MEMBERSHIP_SAVINGS,
    BRANDING_NAME,
    CARRIER_SHIP_FEE_REDUCTION,
    MEMBERSHIP_COST,
    SHORT_BRANDING_NAME,
    TRIAL_LENGTH_DAYS,
} from 'constants/plusMembership';
import { IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN } from 'constants/theme';
import { FEATURE_FLAG_NAMES, useAmplitudeFeatureFlag } from 'hooks/useFeatureFlag';
import useGlobalStore from 'hooks/useGlobalStore';

export function useAccountPlusMembershipHook() {
    const { isMisfitsTheme } = useGlobalStore();
    const brandDomain = isMisfitsTheme ? MISFITS_THEME_DOMAIN : IMPERFECT_THEME_DOMAIN;

    const plusMembershipBranding = BRANDING_NAME[brandDomain];
    const plusMembershipShortBranding = SHORT_BRANDING_NAME[brandDomain];

    const { data: account = {} } = useAccountQuery();
    const inPlusMembershipTreatment = account?.featureFlags?.featurePaidMembership ?? false;
    const { data: plusMembershipOptionsQueryData = {}, isLoading: isPlusMembershipOptionsLoading } =
        usePlusMembershipOptionsQuery(inPlusMembershipTreatment);

    const {
        experimentClientIsReady,
        experimentClientIsTimedOut,
        isAssignedVariant: isAssignedAverageMembershipSavingsVariant,
    } = useAmplitudeFeatureFlag({
        flagName: FEATURE_FLAG_NAMES.AVERAGE_MEMBERSHIP_SAVINGS,
        variant: 'on',
    });

    let averageMembershipSavingsPeriod = null;
    if (experimentClientIsReady && !experimentClientIsTimedOut) {
        averageMembershipSavingsPeriod = isAssignedAverageMembershipSavingsVariant ? 'year' : 'month';
    }

    const averageMembershipSavings =
        AVERAGE_MEMBERSHIP_SAVINGS[account?.isDeliveredByImperfect ? 'dbi' : 'cs'][averageMembershipSavingsPeriod];

    const isPlusMembershipAvailable =
        plusMembershipOptionsQueryData?.typeOfMembership === 'PAID' && (account?.plusMemberships ?? []).length === 0;
    const isTrialMembershipAvailable =
        plusMembershipOptionsQueryData?.typeOfMembership === 'FREE_TRIAL' &&
        (account?.plusMemberships ?? []).length === 0;
    const plusMembershipFee = plusMembershipOptionsQueryData?.price ?? MEMBERSHIP_COST;
    const shippingFees =
        account && account.shippingFee
            ? {
                  plusMembershipShippingFee: account.isDeliveredByImperfect
                      ? 0
                      : account.shippingFee - CARRIER_SHIP_FEE_REDUCTION,
                  plusMembershipShippingSavings: account.isDeliveredByImperfect
                      ? account.shippingFee
                      : CARRIER_SHIP_FEE_REDUCTION,
              }
            : null;

    const plusMemberships = account?.plusMemberships ?? [];
    const customerTrial = plusMemberships.find(
        (plusMembership) => plusMembership.term.typeOfMembership === 'FREE_TRIAL'
    );
    const customerPaidMembership = plusMemberships.find(
        (plusMembership) => plusMembership.term.typeOfMembership === 'PAID'
    );

    const genericMembership = customerPaidMembership ?? customerTrial;

    const sharedMembershipValues = !!genericMembership && {
        startDate: `${genericMembership.term.startDate.date}`,
        endDate: `${genericMembership.term.endDate.date}`,
        isAutoRenew: genericMembership.term.isAutoRenew,
        status: genericMembership.term.status,
        price: genericMembership.variant.price,
        paidDurationDays: genericMembership.variant.paidDurationDays,
        variantId: genericMembership.variant.id,
    };

    const trial =
        customerTrial && !customerPaidMembership
            ? {
                  ...sharedMembershipValues,
                  trialDurationDays: customerTrial.variant.freeTrialDurationDays,
              }
            : null;

    const membership = customerPaidMembership
        ? { ...sharedMembershipValues, isAutoRenewEnabled: customerPaidMembership.term.isAutoRenew }
        : null;

    const plusMembershipEligibleOrder = account?.nextOrder?.plusMemberPriceEligible;

    const isPlusMember = !!membership;
    const freeTrialExpirationDate = trial?.endDate;

    let membershipStatus = 'ineligible';

    if (isPlusMember) {
        membershipStatus = 'active';
    } else if (freeTrialExpirationDate) {
        membershipStatus = 'trial';
    } else if (inPlusMembershipTreatment) {
        membershipStatus = 'eligible';
    }

    const availableCredits = {
        credits: plusMembershipOptionsQueryData?.credits,
        creditsExpirationDays: plusMembershipOptionsQueryData?.creditsExpirationDays,
    };

    return {
        averageMembershipSavings,
        averageMembershipSavingsPeriod,
        plusMembershipBranding,
        plusMembershipShortBranding,
        inPlusMembershipTreatment,
        isPlusMembershipOptionsLoading,
        availableCredits,
        availableTrialMembership: isTrialMembershipAvailable
            ? {
                  trialDurationDays: plusMembershipOptionsQueryData?.membershipDuration ?? TRIAL_LENGTH_DAYS,
                  variantId: plusMembershipOptionsQueryData?.plusMembershipVariantID,
              }
            : null,
        availablePlusMembership: isPlusMembershipAvailable
            ? {
                  membershipDurationDays: plusMembershipOptionsQueryData?.membershipDuration ?? TRIAL_LENGTH_DAYS,
                  variantId: plusMembershipOptionsQueryData?.plusMembershipVariantID,
              }
            : null,
        plusMembershipFee,
        shippingFees,
        trial,
        membership,
        price: {
            annual: genericMembership?.variant?.price ?? plusMembershipOptionsQueryData?.price ?? MEMBERSHIP_COST,
        },
        plusMembershipEligibleOrder,
        membershipStatus,
        membershipRenewalDate: plusMembershipOptionsQueryData?.membershipRenewalDate,
        membershipExpirationDate: sharedMembershipValues?.endDate,
    };
}
