import { useEffect } from 'react';

import { checkDiscountCode } from 'api/jalapeno';

import { persistAppliedPromoCode } from './utils';

/**
 * Listen for window.applyOptimizelyPromo event, called by a custom JS snippet in Optimizely,
 * and attempt to apply an Optimizely promo. See index.html for companion snippet
 *
 * Notes:
 * 1. Optimizely promos are always the lowest priority
 * 2. Optimizely can apply a promo on any page (i.e persist to local storage), but we only
 *    show the <SignupNanoBanner /> component on selected routes
 *
 * @param {queriedPromoCodes} ReturnType<typeof userQueries>
 * @param {queriedPromoCodesFetching} boolean
 * @param {setAppliedPromoCodeData} function
 */
function useApplyOptimizelyPromo({ queriedPromoCodes, queriedPromoCodesFetching, setAppliedPromoCodeData }) {
    useEffect(() => {
        const applyOptimizelyPromo = async (e) => {
            if (queriedPromoCodesFetching) {
                return;
            }

            const priorityPromoCode = queriedPromoCodes?.find((query) => query?.data?.data?.disc?.valid);
            if (priorityPromoCode) {
                return;
            }

            const optimizelyCode = e.detail;
            const response = await checkDiscountCode(undefined, optimizelyCode);

            if (response?.data?.disc?.valid) {
                persistAppliedPromoCode(optimizelyCode);
                setAppliedPromoCodeData(response.data);
            }
        };

        window.addEventListener('applyOptimizelyPromo', applyOptimizelyPromo);
        window.BOYSENBERRY_APPLY_OPTIMIZELY_PROMO_READY = true;

        return () => {
            window.removeEventListener('applyOptimizelyPromo', applyOptimizelyPromo);
        };
    }, [queriedPromoCodes, queriedPromoCodesFetching]);
}

export { useApplyOptimizelyPromo };
