import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';

import useAccountQuery from 'api/queries/useAccountQuery';
import useSubscriptionQuery from 'api/queries/useSubscriptionQuery';
import { Alert } from 'honeydew/Alert';
import { parseDate } from 'utils/dateUtils';

import { transformCustomTags } from './transformCustomTags';

/**
 * [TEMPORARY] Hardcoded requirements for displaying specific banners.
 *
 * (1) Filters designated banners whose constraints include checking to see if a user's
 *     subscription was created before a specified date.
 *
 * (2) Filters designated banners based on pathname.
 *
 * This hardcoded filtering method was originally phased out in commit `a358495`.
 * It has temporarily been brought back, as we've reverted the associated `eCommerce`
 * changes in order to optimize a non-performant SQL query.
 *
 * This stopgap is expected to be removed with the relase of CUS-3739.
 *
 * @param {ServiceDelayBanner} banner - The banner to validate.
 * @param {string} pathname - The current pathname.
 * @param {Subscription} subscription - The user's subscription.
 * @returns {boolean} Does the banner meet "subscribed before" requirements.
 */
export const filterStopgapRequirements = ({ banner, pathname, subscription }) => {
    if (banner.id === 40) {
        const subCreationDate = parseDate(subscription.createdAt?.date);
        const signupByDate = new Date('11/8/2023');
        return subCreationDate < signupByDate;
    }

    if (banner.id === 44) {
        const subCreationDate = parseDate(subscription.createdAt?.date);
        const signupByDate = new Date('5/1/2024');
        return subCreationDate < signupByDate;
    }

    if (banner.id === 45) {
        return pathname === '/account';
    }

    return true;
};

/**
 * Filters a list of service delay banners according to the following constraints:
 *     - The banner is active and has no specified routes.
 *     - The banner is active and includes a route value matching the passed in pathname.
 *     - A user's subscription was created before a specified date for designated banners.
 *
 * @param {ServiceDelayBanner[]} banners - A list of service delay banners.
 * @param {string} pathname - The pathname to filter for.
 * @param {Subscription} subscription - The user's subscription, required for the sub date stopgap.
 * @returns A filtered list of valid banners.
 */
const getValidBanners = ({ banners, pathname, subscription }) => {
    if (!subscription || !Array.isArray(banners)) return [];

    return banners.filter((banner) => {
        const isActive = banner.active;
        const hasNoRoutes = !banner.routes;
        const hasRouteMatchingPath = banner.routes?.some((route) => new RegExp(route).test(pathname));
        const hasStopgapRequirements = filterStopgapRequirements({ banner, pathname, subscription });

        return isActive && (hasNoRoutes || hasRouteMatchingPath) && hasStopgapRequirements;
    });
};

function ServiceDelayBanners({ className }) {
    const location = useLocation();

    const { data: account } = useAccountQuery();
    const { data: subscription } = useSubscriptionQuery();

    const validBanners = getValidBanners({
        banners: account?.serviceDelayBanners,
        pathname: location.pathname,
        subscription,
    });

    const transformedBanners = transformCustomTags(validBanners);

    if (transformedBanners.length < 1) return false;

    return (
        <div className={clsx('flex flex-col gap-20', className)} data-testid="service-delay-banner-container">
            {transformedBanners.map((banner) => (
                <React.Fragment key={banner.id}>
                    <Alert variant="info-bell">
                        {/* eslint-disable-next-line react/no-danger */}
                        <span dangerouslySetInnerHTML={{ __html: banner.copy }} />
                    </Alert>
                </React.Fragment>
            ))}
        </div>
    );
}

export default ServiceDelayBanners;

ServiceDelayBanners.propTypes = {
    className: PropTypes.string,
};

ServiceDelayBanners.defaultProps = {
    className: null,
};
