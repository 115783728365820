import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';

import useSubscriptionQuery from 'api/queries/useSubscriptionQuery';
import Button from 'honeydew/Button';
import { DeliveryTruckIcon } from 'honeydew/icons/MyPlan';
import Modal from 'honeydew/Modal';
import useGlobalStore from 'hooks/useGlobalStore';
import { trackEvent } from 'utils/analyticsUtils';

function DriverTipsInfoModal({ open, setOpen }) {
    const { data: subscription = {} } = useSubscriptionQuery();

    const { isMisfitsTheme } = useGlobalStore();
    const location = useLocation();

    const { variant } = subscription ?? {};

    useEffect(() => {
        if (open) {
            trackEvent('driver tip info modal open', {
                path: location.pathname,
            });
        }
    }, [open]);

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <section className="mx-auto max-w-[584px]">
                <div className="text-center">
                    <DeliveryTruckIcon isMisfits={isMisfitsTheme} className="mx-auto mb-10 mt-50" />
                    <h1 className="text-heading-md">How tipping works</h1>
                    <p className="text-body-sm mt-10">
                        With humor and groceries, it&apos;s all in the delivery. Tipping is an easy way to show the
                        drivers doing the heavy lifting (literally!) how grateful you are for all they do.
                    </p>
                </div>
                <ul className="text-body-sm my-30 md:text-center">
                    <li>&bull; Tipping is totally optional</li>
                    <li>
                        &bull; <span className="font-grotesk-bold">100% of tips go directly to our drivers</span>
                    </li>
                    <li>&bull; Adjust your tip in your cart, or for 24 hours after delivery</li>
                    <li>&bull; You can opt out of automatic tips, but still tip per individual order</li>
                </ul>

                <Button size="md" variant="secondary" onClick={() => setOpen(false)}>
                    Back to {window.location.pathname.includes('orders') ? 'order' : 'cart'}
                </Button>

                <p className="text-body-sm mx-auto my-25 max-w-[400px] text-center">
                    Visit the <span className="font-grotesk-bold">My Plan</span>{' '}
                    {variant === 'autoship' && (
                        <span>
                            or <span className="font-grotesk-bold">Upcoming Deliveries</span>{' '}
                        </span>
                    )}
                    page in Account to <span className="font-grotesk-bold">edit your default tip</span> amount.
                </p>
            </section>
        </Modal>
    );
}

export default DriverTipsInfoModal;

DriverTipsInfoModal.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
};
