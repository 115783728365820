import { useContext, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';

import { activatePlusMembership } from 'api/jalapeno';
import useAccountQuery, { ACCOUNT_QUERY_KEY } from 'api/queries/useAccountQuery';
import { PLUS_MEMBERSHIP_OPTIONS_QUERY_KEY } from 'api/queries/usePlusMembershipOptionsQuery';
import { MembershipStateContext } from 'features/paid-membership/MembershipStateContext';
import { Alert } from 'honeydew/Alert';
import Button from 'honeydew/Button';
import { LoadingIcon } from 'honeydew/Loader';
import { useAccountPlusMembershipHook } from 'hooks/useAccountPlusMembershipHook';
import { trackEvent } from 'utils/analyticsUtils';

function ActivateMembershipCTA({ copy, onActivate, size }) {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const membershipJoiningContext = useContext(MembershipStateContext);

    const { data: account } = useAccountQuery();
    const {
        availablePlusMembership,
        availableTrialMembership,
        averageMembershipSavingsPeriod,
        plusMembershipBranding,
    } = useAccountPlusMembershipHook();

    const [activationError, setActivationError] = useState('');
    const [isJoinApiCallInProgress, setIsJoinApiCallInProgress] = useState(false);

    const { customerID } = account ?? {};
    const plusMembershipVariantID = availableTrialMembership?.variantId ?? availablePlusMembership?.variantId;
    const typeOfMembership = availableTrialMembership ? 'FREE_TRIAL' : 'PAID';
    const { joinPlusMembership, joinPlusTrial } = membershipJoiningContext ?? {};

    const activateMembership = async () => {
        setActivationError('');

        try {
            const response = await activatePlusMembership({
                customerID,
                sendParams: { plusMembershipVariantID, typeOfMembership },
            });

            if (response?.msg !== 'Success' || response?.data?.data?.hasError) {
                throw new Error(response.data.data.errorMessage);
            }

            if (response?.msg === 'Success') {
                if (typeOfMembership === 'FREE_TRIAL') {
                    trackEvent('start free trial', {
                        source: 'account',
                        averageMembershipSavingsPeriod,
                    });
                } else {
                    trackEvent('start membership', {
                        source: 'account',
                        averageMembershipSavingsPeriod,
                    });
                }

                queryClient.invalidateQueries({ queryKey: [ACCOUNT_QUERY_KEY] });
                queryClient.invalidateQueries({ queryKey: [PLUS_MEMBERSHIP_OPTIONS_QUERY_KEY] });

                if (typeOfMembership === 'PAID') {
                    joinPlusMembership?.();
                } else {
                    joinPlusTrial?.();
                }

                onActivate();

                if (window.isNativeApp) {
                    window.ReactNativeWebView?.postMessage(JSON.stringify({ reload: true }));
                } else {
                    navigate('/account/membership');
                }
            }
        } catch (error) {
            let message = error?.message || error;

            if (message) {
                if (!message.endsWith('.')) {
                    message = `${message}.`;
                }
                setActivationError(
                    `Failed to join ${plusMembershipBranding}. ${message} Please try again or contact Customer Care.`
                );
            } else {
                setActivationError(
                    `Failed to join ${plusMembershipBranding}. Please try again or contact Customer Care.`
                );
            }
        } finally {
            setIsJoinApiCallInProgress(false);
        }
    };

    const handleCTAClick = async () => {
        if (isJoinApiCallInProgress) {
            return;
        }

        setIsJoinApiCallInProgress(true);
        await activateMembership();
    };

    const ctaButtonEnabled =
        !!customerID && !!plusMembershipVariantID && !!typeOfMembership && !isJoinApiCallInProgress;

    return (
        <>
            {activationError && <Alert variant="error">{activationError}</Alert>}
            <Button disabled={!ctaButtonEnabled} onClick={handleCTAClick} size={size} variant="plusMembership">
                {isJoinApiCallInProgress ? (
                    <div className="mt-[3px] flex">
                        <LoadingIcon />
                    </div>
                ) : (
                    copy
                )}
            </Button>
        </>
    );
}

export { ActivateMembershipCTA };

ActivateMembershipCTA.propTypes = {
    copy: PropTypes.string.isRequired,
    onActivate: PropTypes.func,
    size: PropTypes.string,
};

ActivateMembershipCTA.defaultProps = {
    onActivate: () => {},
    size: 'lg',
};
