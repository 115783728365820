import clsx from 'clsx';
import { Outlet } from 'react-router';

import ImperfectFoodsLogo from 'honeydew/icons/Logos/ImperfectFoodsLogo';
import MisfitsMarketLogo from 'honeydew/icons/Logos/MisfitsMarketLogo';
import useBrandLinks from 'hooks/useBrandLinks';
import useGlobalStore from 'hooks/useGlobalStore';

export default function LoginLayout() {
    const { isImperfectTheme } = useGlobalStore();
    const { customerCare } = useBrandLinks();

    return (
        <main className="flex min-h-screen items-center justify-center bg-lime pt-80 if:bg-strawberry">
            <div className="m-20 w-[470px] rounded-md bg-white">
                <div className="relative flex items-center justify-center">
                    <img
                        className={clsx('absolute', {
                            '-top-[131px]': !isImperfectTheme,
                            '-top-[126px] ml-20': isImperfectTheme,
                        })}
                        src={
                            isImperfectTheme
                                ? 'https://img-cdn.misfitsmarket.com/boysenberry/images/IF_login-illustration.png'
                                : 'https://img-cdn.misfitsmarket.com/boysenberry/images/MM_login-illustration.png'
                        }
                        alt="login illustration"
                        width="240"
                    />
                </div>
                <div className="flex items-center justify-center border-b border-b-lime pb-20 pt-30 if:border-b-strawberry">
                    {isImperfectTheme ? <ImperfectFoodsLogo /> : <MisfitsMarketLogo />}
                </div>
                <div className="flex items-center justify-center">
                    <div className="p-20 sm:w-full lg:max-w-[350px]">
                        <Outlet />
                        <p className="text-body-sm" data-optimizely-id="login-need-help-copy">
                            Need help?{' '}
                            <a href={customerCare} target="_blank" rel="noopener noreferrer" className="link">
                                Contact Customer Care
                            </a>
                        </p>
                        <p className="text-body-sm" data-optimizely-id="login-new-to-copy">
                            New to {isImperfectTheme ? 'Imperfect Foods' : 'Misfits Market'}?{' '}
                            <a href="/join" className="link" data-optimizely-id="login-sign-up-link">
                                Sign up
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </main>
    );
}
