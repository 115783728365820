import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

import useAuth from 'hooks/useAuth';
import useGlobalStore from 'hooks/useGlobalStore';
import { trackEvent } from 'utils/analyticsUtils';

function getUTMParameters(queryString) {
    const utmParameters = {};

    if (queryString) {
        const queryParams = queryString.split('&');

        for (let i = 0; i < queryParams.length; i += 1) {
            const pair = queryParams[i].split('=');
            const key = decodeURIComponent(pair[0]);
            const value = decodeURIComponent(pair[1]);

            utmParameters[key] = value;
        }
    }

    return utmParameters;
}

/**
 * Track page views
 * @param {string} eventName - The name of the page to track.
 * @param {boolean} includeUtmParams - Optionally include utm params as event properties. Note: this will also include the promo param.
 * @param {Object} optionalPageViewProperties - Optionally include custom event properties.
 * @param {Boolean} enabled - Optionally disable tracking until a condition is met. Useful for including event properties that are loaded async.
 */
export default function useTrackPageView(
    eventName = 'page view',
    includeUtmParams = false,
    optionalPageViewProperties = {},
    enabled = true
) {
    const trackedRef = useRef(false);
    const { pathname, search } = useLocation();
    const utmParams = includeUtmParams ? getUTMParameters(search.substring(1)) : {};
    const { isMisfitsTheme } = useGlobalStore();
    const { authStatus } = useAuth();
    const isLoggedIn = authStatus === 'authenticated';

    useEffect(() => {
        if (trackedRef.current === pathname || !enabled) return;

        const domain = window.location.hostname;
        const brand = isMisfitsTheme ? 'misfits_market' : 'imperfect_foods';

        trackEvent(eventName, {
            ...utmParams,
            domain,
            brand,
            pathname,
            is_logged_in: isLoggedIn,
            isNative: !!window.isNativeApp,
            query_params: search,
            url: window.location.href,
            ...optionalPageViewProperties,
        });

        trackedRef.current = pathname;
    }, [enabled, pathname]);
}
