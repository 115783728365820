import { useQuery } from '@tanstack/react-query';

import { getOrder } from 'api/jalapeno';

export const ORDER_QUERY_KEY = 'order';

const useOrderQuery = ({ orderID }) => {
    const query = useQuery({
        queryKey: [ORDER_QUERY_KEY, orderID],
        queryFn: async () => {
            const response = await getOrder(orderID);
            return response?.data;
        },
        enabled: !!orderID,
    });

    return query;
};

export default useOrderQuery;
