import PropTypes from 'prop-types';

function AvatarIcon({ isMisfits, width }) {
    return isMisfits ? (
        <img
            alt="Misfits Market's fruit illustration"
            aria-hidden="true"
            src="https://img-cdn.misfitsmarket.com/boysenberry/icons/AvatarMisfits.svg"
            width={width}
        />
    ) : (
        <img
            alt="Imperfect Foods fruit illustration with eyes"
            aria-hidden="true"
            src="https://img-cdn.misfitsmarket.com/boysenberry/icons/AvatarImperfect.svg"
            width={width}
        />
    );
}

export default AvatarIcon;

AvatarIcon.propTypes = {
    isMisfits: PropTypes.bool,
    width: PropTypes.number,
};

AvatarIcon.defaultProps = {
    isMisfits: true,
    width: 90,
};
