import { STATIC_CONTENT_URL } from 'constants';

import { HeartBoxIcon } from './HeartBoxIcon';
import { HourglassIcon } from './HourglassIcon';
import { MemberDealsAisleBannerIcon } from './MemberDealsAisleBannerIcon';
import PlusBenefitsSaveFoodIcon from './PlusBenefitsSaveFoodIcon';
import PlusBenefitsShippingIcon from './PlusBenefitsShippingIcon';
import PlusBenefitsWeeklyDealsIcon from './PlusBenefitsWeeklyDealsIcon';

export { SocialImpactIcon } from './SocialImpactIcon';

const plusMembershipIconsUrl = `${STATIC_CONTENT_URL}boysenberry/plusmembership/`;

// Full icons
const BOX_WITH_HEART_ICON_URL = `${plusMembershipIconsUrl}box-with-heart-icon.png`;
const HAND_HOLDING_BOWL_ICON_URL = `${plusMembershipIconsUrl}hand-holding-bowl-icon.png`;
const RIBBON_WITH_COIN_ICON_URL = `${plusMembershipIconsUrl}ribbon-with-coin-icon.png`;
const TRUCK_ICON_URL = `${plusMembershipIconsUrl}truck-icon.png`;
const COIN_WITH_STAR_ICON_URL = `${plusMembershipIconsUrl}coin-with-star.png`;

// Cropped icons
const BOX_WITH_HEART_ICON_CROPPED_URL = `${plusMembershipIconsUrl}box-with-heart-icon-cropped2.png`;
const HAND_HOLDING_BOWL_ICON_CROPPED_URL = `${plusMembershipIconsUrl}hand-holding-bowl-icon-cropped.png`;
const RIBBON_WITH_COIN_ICON_CROPPED_URL = `${plusMembershipIconsUrl}ribbon-with-coin-icon-cropped.png`;
const TRUCK_ICON_CROPPED_URL = `${plusMembershipIconsUrl}truck-icon-cropped.png`;

export {
    BOX_WITH_HEART_ICON_URL,
    HAND_HOLDING_BOWL_ICON_URL,
    RIBBON_WITH_COIN_ICON_URL,
    TRUCK_ICON_URL,
    COIN_WITH_STAR_ICON_URL,
    BOX_WITH_HEART_ICON_CROPPED_URL,
    HAND_HOLDING_BOWL_ICON_CROPPED_URL,
    RIBBON_WITH_COIN_ICON_CROPPED_URL,
    TRUCK_ICON_CROPPED_URL,
    PlusBenefitsShippingIcon,
    PlusBenefitsSaveFoodIcon,
    PlusBenefitsWeeklyDealsIcon,
    HeartBoxIcon,
    HourglassIcon,
    MemberDealsAisleBannerIcon,
};
