import PropTypes from 'prop-types';

import { IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN } from 'constants/theme';

export function Fruit({ brand, isActive, width }) {
    const appleColor = brand === MISFITS_THEME_DOMAIN ? '#DC6B31' : '#D9291F';
    const leafColor = brand === MISFITS_THEME_DOMAIN ? '#B0C472' : '#C5DB66';
    const activeColor = brand === MISFITS_THEME_DOMAIN ? '#D7E1B8' : '#F0D3E3';

    return isActive ? (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.6505 28.7652C21.902 26.9646 29 22.6358 29 14.9992C29 7.3625 22.9452 2.7363 14.6505 1.1728C7.31233 -0.213857 2.48978 6.96057 1.13553 14.967C-0.132106 22.475 7.73356 30.4815 14.6505 28.7652Z"
                fill={activeColor}
            />
            <path
                d="M16.0529 8.14621H18.009C18.5747 8.11745 19.1404 7.97362 19.6486 7.71473C20.1568 7.45584 20.6075 7.09148 20.9622 6.65041C22.4005 4.8286 22.0553 2 22.0553 2C18.0377 2 16.6282 3.86975 16.168 5.54774C15.9475 6.40111 15.9091 7.28325 16.0529 8.1558V8.14621Z"
                fill={leafColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M24.0786 18.4634C24.4238 18.0127 24.769 17.5908 25.1621 17.1785C25.1909 17.1306 25.2101 17.0826 25.2197 17.0347C25.2197 16.9867 25.2197 16.9292 25.2101 16.8813C25.1909 16.8333 25.1717 16.7854 25.1334 16.747C25.095 16.7087 25.0567 16.6799 25.0087 16.6607C24.3759 16.4498 23.791 16.1046 23.791 15.2704C23.791 15.1362 23.8102 14.9923 23.8389 14.8581C23.5992 14.6567 23.4074 14.4074 23.2732 14.1198C23.139 13.8321 23.0718 13.5253 23.0623 13.2185C23.0623 12.902 23.139 12.5952 23.2732 12.3172C23.4074 12.0295 23.5992 11.7802 23.8389 11.5788C23.4842 11.2241 23.2732 10.7351 23.2636 10.2365C23.2636 9.71867 23.6759 9.23925 24.0307 8.85571C24.0307 8.85571 22.7746 7.71468 20.972 9.31596C19.1789 10.9172 20.0898 17.3607 21.8253 18.0894C23.5513 18.8181 24.0882 18.4634 24.0882 18.4634H24.0786Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.8729 15.913C21.8729 15.7691 21.9592 15.6349 21.9305 15.5007C21.7387 14.887 20.9908 14.6761 20.9908 14.0432C20.9908 13.5446 21.1826 13.0652 21.5182 12.6912C21.5853 12.6145 21.614 12.5282 21.6236 12.4228C21.6236 12.3269 21.6044 12.231 21.5469 12.1543C21.3168 11.8283 21.1921 11.4447 21.1921 11.0516C21.1921 10.1886 22.6592 9.43116 23.7714 9.05721C23.8386 9.03803 23.8961 8.99968 23.944 8.95174C23.992 8.90379 24.0303 8.84626 24.0495 8.77914C24.0687 8.71202 24.0783 8.6449 24.0687 8.57778C24.0687 8.51066 24.0399 8.44355 24.0016 8.38601C23.6756 7.92577 23.2633 7.53264 22.7742 7.24499C22.2948 6.94774 21.7579 6.76556 21.1921 6.68885C21.1154 6.67927 21.0387 6.68885 20.962 6.70803C20.8853 6.7368 20.8278 6.78474 20.7798 6.84227C20.0607 7.61894 19.0731 8.07918 18.0184 8.13671H16.4267C16.3212 8.13671 16.2157 8.10795 16.1294 8.04083C16.0431 7.97371 15.9856 7.87783 15.976 7.77235C15.9185 7.17787 15.9377 6.58338 16.0431 5.99848C16.0527 5.94095 16.0527 5.88342 16.0431 5.82589C16.0335 5.76836 16.0048 5.71083 15.976 5.66289C15.9377 5.61494 15.8993 5.57659 15.8514 5.53824C15.8034 5.50947 15.7459 5.49029 15.6884 5.48071C15.3528 5.42317 15.0076 5.39441 14.6624 5.39441C11.2201 5.39441 9.17778 6.4875 6.60807 8.67367C5.4958 9.61334 5.28486 9.61334 4.59449 11.0516C4.26848 11.8187 4.06712 12.6241 4 13.4487V16.0664C4.11506 17.3417 4.43148 18.5882 4.94926 19.758C5.62045 21.3976 5.77387 21.5031 6.96284 22.6345C9.17778 24.7535 11.479 26.0001 14.6624 26.0001C16.5226 26.0001 19.2361 25.5877 20.6456 24.2549C22.2852 22.7112 22.8126 20.0456 24.2125 18.2813C24.2796 18.195 24.3563 18.0991 24.433 18.0128C22.5537 18.0991 21.8825 16.7951 21.8825 15.913H21.8729Z"
                fill={appleColor}
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
        </svg>
    ) : (
        <svg width={width} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10935_20986)">
                <path
                    d="M16.061 8.2703H17.9788C18.5334 8.2421 19.0881 8.10109 19.5863 7.84727C20.0845 7.59345 20.5264 7.23622 20.8742 6.80379C22.2843 5.01765 21.9459 2.24445 21.9459 2.24445C18.007 2.24445 16.6251 4.07758 16.1738 5.72271C15.9576 6.55937 15.92 7.42423 16.061 8.2797V8.2703Z"
                    fill={leafColor}
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M22.6605 7.3866C22.2469 7.01997 21.664 6.91657 21.1094 6.84136C21.0342 6.83196 20.959 6.84136 20.8838 6.86016C20.8085 6.88836 20.7521 6.93537 20.7051 6.99177C20.0001 7.75323 19.0318 8.20446 17.9977 8.26087H16.4372C16.3338 8.26087 16.2304 8.23267 16.1458 8.16686C16.0612 8.10106 16.0048 8.00705 15.9954 7.90364C15.939 7.3208 15.9578 6.73795 16.0612 6.16451C16.0706 6.10811 16.0706 6.0517 16.0612 5.9953C16.0518 5.93889 16.0236 5.88249 15.9954 5.83549C15.9578 5.78848 15.9202 5.75088 15.8732 5.71328C15.8262 5.68507 15.7698 5.66627 15.7134 5.65687C15.3843 5.60047 15.0459 5.57227 14.7075 5.57227C11.3326 5.57227 9.33029 6.64395 6.8109 8.78731C5.72042 9.70858 5.5136 9.70858 4.83675 11.1187C4.51713 11.8707 4.31971 12.6604 4.25391 13.4689V16.0352C4.36671 17.2855 4.67694 18.5076 5.18458 19.6545C5.84263 21.262 5.99304 21.3655 7.15872 22.4747C9.33029 24.5523 11.5865 25.7744 14.7075 25.7744C16.5312 25.7744 19.1916 25.3701 20.5735 24.0635C22.087 22.6439 22.6417 20.2656 23.8356 18.5452C25.0201 16.7497 27.342 11.4571 22.6699 7.396L22.6605 7.3866Z"
                    fill={appleColor}
                    stroke="#2D2D2D"
                    strokeWidth="0.5"
                    strokeMiterlimit="10"
                />
            </g>
            <defs>
                <clipPath id="clip0_10935_20986">
                    <rect width="21.6968" height="24" fill="white" transform="translate(4 2)" />
                </clipPath>
            </defs>
        </svg>
    );
}

Fruit.propTypes = {
    brand: PropTypes.oneOf([IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN]).isRequired,
    isActive: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Fruit.defaultProps = {
    isActive: false,
    width: 30,
};
