import PropTypes from 'prop-types';

import { LoginErrors } from 'constants/login';
import { Alert } from 'honeydew/Alert';
import useGlobalStore from 'hooks/useGlobalStore';
import { getCrossDomainDeviceId } from 'utils/analyticsUtils';

function LoginError({ error }) {
    const { isImperfectTheme } = useGlobalStore();

    const crossDomainDeviceId = getCrossDomainDeviceId();
    const crossDomainDeviceIdUrlParam = new URLSearchParams({
        deviceId: crossDomainDeviceId,
    }).toString();

    const misfitsMarketLoginLink = `https://www.misfitsmarket.com/login?${crossDomainDeviceIdUrlParam}`;
    const imperfectFoodsLoginLink = `https://www.imperfectfoods.com/login?${crossDomainDeviceIdUrlParam}`;

    if (error === LoginErrors.NoError) return null;

    return (
        <>
            {error === LoginErrors.Invalid && (
                <div className="mb-20">
                    <Alert variant="error">
                        <span className="font-grotesk-bold">Incorrect email and/or password.</span> Please fix and try
                        again.
                    </Alert>
                </div>
            )}

            {error === LoginErrors.NoEmail && (
                <div className="mb-20">
                    <Alert variant="error">Please enter your email address.</Alert>
                </div>
            )}

            {error === LoginErrors.NoPassword && (
                <div className="mb-20">
                    <Alert variant="error">Please enter your new password.</Alert>
                </div>
            )}

            {error === LoginErrors.InvalidPassword && (
                <div className="mb-20">
                    <Alert variant="error">Password must meet all requirements.</Alert>
                </div>
            )}

            {error === LoginErrors.PasswordMatch && (
                <div className="mb-20">
                    <Alert variant="error">Passwords must match.</Alert>
                </div>
            )}

            {error === LoginErrors.NoCode && (
                <div className="mb-20">
                    <Alert variant="error">Please enter a valid 6-digit confirmation code.</Alert>
                </div>
            )}

            {error === LoginErrors.InvalidCode && (
                <div className="mb-20">
                    <Alert variant="error">Please enter a correct confirmation code.</Alert>
                </div>
            )}

            {error === LoginErrors.CodeSubmit && (
                <div className="mb-20">
                    <Alert variant="error">There was in issue sending your code.</Alert>
                </div>
            )}

            {error === LoginErrors.PasswordSubmit && (
                <div className="mb-20">
                    <Alert variant="error">There was in issue resetting your password.</Alert>
                </div>
            )}

            {error === LoginErrors.WrongSite && (
                <div className="mb-20">
                    <Alert variant="error">
                        You&apos;re already a member of {isImperfectTheme ? 'Misfits Market' : 'Imperfect Foods'},{' '}
                        {isImperfectTheme ? 'an Imperfect Foods' : 'a Misfits Market'} brand. Please log in at{' '}
                        <a
                            href={isImperfectTheme ? misfitsMarketLoginLink : imperfectFoodsLoginLink}
                            className="link text-body-sm"
                        >
                            {isImperfectTheme ? 'misfitsmarket.com/login' : 'imperfectfoods.com/login'}
                        </a>
                    </Alert>
                </div>
            )}
        </>
    );
}

LoginError.propTypes = {
    error: PropTypes.oneOf(Object.keys(LoginErrors).map((key) => LoginErrors[key])).isRequired,
};

export default LoginError;
