import { IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN } from 'constants/theme';

function getSubdomain() {
    const [subdomain] = window.location.hostname.split('.');
    return subdomain;
}

function getIsProduction() {
    const subdomain = getSubdomain();
    return ['www', 'boysenberry', 'imperfectfoods'].includes(subdomain) && import.meta.env.PROD;
}

function getIsStaging() {
    const subdomain = getSubdomain();
    return !!(['staging'].includes(subdomain) && import.meta.env.PROD);
}

function getIsLocalDev() {
    return import.meta.env.DEV && window.location.hostname === 'localhost';
}

function getBrandDomain() {
    return window.location.host
        .split('.')
        ?.reverse()
        ?.find((d) => d === MISFITS_THEME_DOMAIN || d === IMPERFECT_THEME_DOMAIN);
}

export { getIsProduction, getIsStaging, getSubdomain, getIsLocalDev, getBrandDomain };
