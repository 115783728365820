import PropTypes from 'prop-types';

function PescatarianIcon({ className, width }) {
    return (
        <svg width={width} className={className} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_6179_26456)">
                <path
                    d="M2.46988 7.81214C3.30563 7.21326 9.71865 4.08106 15.1748 6.94519C20.6309 9.80931 22.8141 13.1887 24.3754 18.3033C27.2973 17.311 30.0368 17.2824 32.8977 20.6842C31.0858 20.1093 28.1172 21.7257 27.0928 23.2978C25.8628 25.1558 26.2039 27.2562 27.3833 29.5703C24.4527 28.9676 20.9851 27.1709 21.7255 22.7842C19.3123 23.9555 16.5998 24.3648 13.9484 23.9578C11.297 23.5507 8.83223 22.3466 6.88152 20.5053C1.14607 15.1824 2.46988 7.81214 2.46988 7.81214Z"
                    fill="#F1C34B"
                    stroke="#2D2D2D"
                    strokeWidth="0.648213"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.5786 5.84975C12.782 5.48773 14.0027 5.1866 15.2364 4.9475C17.2518 4.55093 20.0408 6.20324 21.7351 7.79504C23.4294 9.38685 24.5365 10.657 24.6302 11.2516C24.7239 11.8462 23.3598 15.0847 23.3598 15.0847C23.3598 15.0847 20.9487 10.2068 17.4865 8.31935C15.6226 7.26377 13.6392 6.43469 11.5786 5.84975Z"
                    fill="#F2EBD1"
                    stroke="#2D2D2D"
                    strokeWidth="0.648213"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.62078 17.7972C5.18244 17.7251 8.5173 17.684 11.6287 14.2617C14.74 10.8393 13.4934 6.47167 13.4934 6.47167C13.4934 6.47167 8.54484 4.44289 2.46993 7.81214C1.5958 13.0921 4.62078 17.7972 4.62078 17.7972Z"
                    fill="white"
                    stroke="#2D2D2D"
                    strokeWidth="0.648213"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.76075 10.2302C9.20618 10.2817 9.609 9.96229 9.66048 9.51686C9.71195 9.07143 9.39259 8.6686 8.94715 8.61713C8.50172 8.56565 8.0989 8.88502 8.04742 9.33045C7.99595 9.77589 8.31531 10.1787 8.76075 10.2302Z"
                    fill="#F1C34B"
                    stroke="#2D2D2D"
                    strokeWidth="0.648213"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M13.3471 11.2474C13.3471 11.2474 16 12.4068 16.4249 16.7251C12.404 18.5296 9.69193 15.9927 9.69193 15.9927C9.69193 15.9927 11.5199 15.5491 13.3471 11.2474Z"
                    fill="#F2EBD1"
                    stroke="#2D2D2D"
                    strokeWidth="0.648213"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19.5288 14.1955C20.3576 14.9308 19.3866 16.6563 18.4802 16.4602C19.1625 17.3208 18.2626 19.09 17.0303 18.9476C17.1636 19.1716 17.2315 19.4285 17.2264 19.6892C17.2212 19.9499 17.1432 20.2039 17.0012 20.4225C16.8592 20.6412 16.6589 20.8157 16.4228 20.9264C16.1868 21.0371 15.9245 21.0795 15.6656 21.0489"
                    stroke="#2D2D2D"
                    strokeWidth="0.648213"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M21.0719 16.8204C21.8581 17.4849 20.9296 19.2812 19.802 19.024C20.3251 19.988 19.5958 21.3353 18.4372 21.1253"
                    stroke="#2D2D2D"
                    strokeWidth="0.648213"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_6179_26456">
                    <rect width="34" height="34" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default PescatarianIcon;

PescatarianIcon.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number.isRequired,
};

PescatarianIcon.defaultProps = {
    className: null,
};
