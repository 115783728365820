import { getIsProduction } from 'utils';
/**
 * Determines the current environment of the application
 * @returns {string} The current environment
 */
function determineEnvironment() {
    const { host } = window.location;

    const reviewSite = host.match(/^(.+)\.boysenberry\.(misfitsmarket\.io|imperfectfoods\.co)$/);

    if (reviewSite) {
        const [, reviewSiteName] = reviewSite;
        return reviewSiteName;
    }

    if (getIsProduction()) {
        return 'production';
    }

    return 'development';
}

export default determineEnvironment;
