import PropTypes from 'prop-types';

function MMWelcomeIcon({ width }) {
    return (
        <svg width={width} viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M26.7486 53.2739C40.9628 49.8161 54.8768 41.477 54.8768 26.8746C54.8768 12.2722 43.0126 3.33266 26.7486 0.330389C12.3687 -2.32507 2.93749 11.4388 0.266502 26.8125C-2.22331 41.2286 13.2073 56.5919 26.7486 53.2998"
                fill="#FFF9EA"
            />
            <path
                d="M13.6621 18.6124L11.9121 15.7988L32.4196 18.6482L34.1696 21.4618L13.6621 18.6124Z"
                fill="#AFC272"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M34.1711 21.4632L13.6641 18.6123L10.8132 39.1194L31.3203 41.9702L34.1711 21.4632Z"
                fill="white"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M41.6988 22.5083L34.168 21.4614L31.3171 41.9685L38.848 43.0154L41.6988 22.5083Z"
                fill="#AFC272"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M43.5188 27.8999L35.9873 26.8525L34.1694 21.4619L41.6973 22.5091L43.5188 27.8999Z"
                fill="white"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M23.0517 26.3206L23.0691 26.1863L23.0517 26.3206ZM17.8052 30.258C17.8047 30.1471 17.7962 30.0316 17.7711 29.919C17.7126 29.6359 17.575 29.3678 17.5538 29.0789C17.5127 28.4234 18.0783 27.8998 18.6362 27.5482C19.9238 26.7336 21.5107 26.1038 23.052 26.3165C24.0442 26.4546 24.6983 27.0907 25.6319 27.2452C26.3503 27.3644 27.0448 27.8925 27.1511 28.6142C27.2352 29.1824 27.1405 29.6644 27.3319 30.2235C27.5336 30.8122 27.5352 31.5061 27.1797 32.0194C27.002 32.274 26.7504 32.4702 26.5222 32.6844C25.7885 33.3852 25.3763 34.5433 24.5558 35.1314C23.8434 35.6407 22.6024 35.658 21.7687 35.5432C20.3334 35.3418 19.392 34.6449 18.5309 33.5552C18.0701 32.9734 17.6114 32.6586 17.5967 31.8571C17.5848 31.3143 17.8055 30.7917 17.8055 30.2539"
                fill="#F1C34A"
            />
            <path
                d="M23.4937 27.6482C23.4937 27.6482 23.2986 24.8144 26.5598 25.2686C26.5598 25.2686 26.5159 27.8765 24.368 27.7699"
                fill="#2E8540"
            />
            <path
                d="M29.8657 14.1451C30.0062 12.7726 30.2416 9.7119 30.2416 9.7119"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M36.7132 16.4919C37.351 15.8465 38.7035 14.3655 38.7035 14.3655"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.6585 13.3602C21.3446 12.5001 20.5757 10.6104 20.5757 10.6104"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

MMWelcomeIcon.propTypes = {
    width: PropTypes.number,
};

MMWelcomeIcon.defaultProps = {
    width: 55,
};

export default MMWelcomeIcon;
