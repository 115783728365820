import PropTypes from 'prop-types';

function EcoShipIcon({ width }) {
    return (
        <svg width={width} viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.92114 16.1493C7.92666 21.2167 14.5011 20.832 18.5977 17.6012C22.6943 14.3705 23.7624 10.4986 19.759 5.43115C15.7535 0.363734 6.47971 -1.67357 2.38104 1.55923C-1.71556 4.78997 -0.0823022 11.0818 3.92114 16.1493Z"
                fill="#B0C372"
            />
            <path
                d="M6.6044 16.5353C6.6044 16.5353 1.95207 3.5443 17.927 1.40015C17.927 1.40015 20.7494 15.5866 10.2118 16.5353C9.31342 16.6176 6.6044 16.5353 6.6044 16.5353V16.5353Z"
                fill="#4B874B"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M9.08398 13.245C9.08398 13.245 10.7536 7.80789 15.1599 4.72876Z" fill="#4B874B" />
            <path
                d="M9.08398 13.245C9.08398 13.245 10.7536 7.80789 15.1599 4.72876"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M9.08398 13.245C9.08398 13.245 10.1012 10.8925 14.4302 9.95752Z" fill="#4B874B" />
            <path
                d="M9.08398 13.245C9.08398 13.245 10.1012 10.8925 14.4302 9.95752"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default EcoShipIcon;

EcoShipIcon.propTypes = {
    width: PropTypes.number,
};

EcoShipIcon.defaultProps = {
    width: 23,
};
