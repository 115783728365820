import { useMatch } from 'react-router-dom';

/**
 * Because the <SignupNanoBanner /> component is rendered in the <App /> component,
 * we need to check if the route is whitelisted for rendering the <SignupNanoBanner /> component.
 * @example const isSignupNanoBannerRoute = useIsSignupNanoBannerRoute();
 * @returns {boolean}
 */
function useIsSignupNanoBannerRoute() {
    const isJoinRoute = !!useMatch('/join/*');
    const isJoinConfirmationRoute = !!useMatch('/join/confirmation');
    const isAcquisitionPDPRoute = !!useMatch('/:slug/p');

    return (isJoinRoute && !isJoinConfirmationRoute) || isAcquisitionPDPRoute;
}

export { useIsSignupNanoBannerRoute };
