import PropTypes from 'prop-types';

export function HeartBoxIcon({ width }) {
    return (
        <svg width={width} height={width} viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.413 28.8386C25.7597 25.3581 32.3934 19.2513 30.7463 11.2899C29.0992 3.32843 21.6353 -0.18663 12.4472 0.00761594C4.32078 0.183236 0.728554 8.73806 1.01593 17.4046C1.28468 25.5311 11.4068 32.1567 18.413 28.8386Z"
                className="fill-[#B0C472] if:fill-[#F0D3E3]"
            />
            <path
                d="M7.08511 9.61382L6 8.10913L17.5716 8.91356L18.6568 10.4183L7.08511 9.61382Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.75"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.6567 10.4186L7.08398 9.61353L6.2789 21.1863L17.8516 21.9913L18.6567 10.4186Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.75"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.9084 10.7141L18.6592 10.4185L17.8541 21.9912L22.1033 22.2868L22.9084 10.7141Z"
                className="fill-[#F8E1A4] if:fill-[#ECE7E4]"
                stroke="#2D2D2D"
                strokeWidth="0.75"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M24.132 13.6562L19.8841 13.361L18.6572 10.4182L22.908 10.7134L24.132 13.6562Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.75"
                strokeMiterlimit="10"
                strokeLinejoin="round"
            />
            <path
                d="M15.9707 6.49142C15.9967 5.71882 16.0083 4 16.0083 4"
                stroke="#2D2D2D"
                strokeWidth="0.75"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.8896 7.54186C20.2195 7.15701 20.9198 6.27734 20.9198 6.27734"
                stroke="#2D2D2D"
                strokeWidth="0.75"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.3525 6.36979C11.1441 5.90102 10.6406 4.87378 10.6406 4.87378"
                stroke="#2D2D2D"
                strokeWidth="0.75"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.6531 18.8208C11.9822 19.0149 12.3929 18.7265 12.7279 18.5647C12.859 18.5027 12.9842 18.4272 13.1037 18.349C13.4357 18.1333 13.794 17.9554 14.1581 17.7909C14.2659 17.7424 14.3708 17.6966 14.4786 17.648C14.6504 17.5725 14.8106 17.4755 14.9534 17.3595C15.2068 17.1519 15.4486 16.9363 15.6437 16.6774C15.8214 16.4429 15.9437 16.1841 16.0049 15.9037C16.0166 15.8443 16.0224 15.785 16.0311 15.7257C16.034 15.7122 16.037 15.6988 16.0399 15.6853C16.0399 15.6772 16.0399 15.6691 16.0399 15.661C16.0399 15.6448 16.037 15.6287 16.037 15.6152C16.0428 15.4157 15.9991 15.2215 15.9525 15.0301C15.8826 14.7497 15.7806 14.4828 15.6408 14.2267C15.4223 13.8304 15.0786 13.5769 14.6271 13.4448C14.4407 13.3909 14.2484 13.3505 14.0533 13.3316C13.829 13.31 13.6076 13.2992 13.3833 13.3424C13.2959 13.3585 13.2115 13.3855 13.1299 13.4179C12.926 13.4987 12.7221 13.5823 12.5182 13.6686C12.2415 13.3046 11.8511 13.0215 11.3326 12.8652C10.887 12.7304 10.3073 12.8139 9.79754 13.0188C9.20039 13.248 8.8858 13.8007 8.94697 14.3453C9.11009 15.8443 10.4821 18.1333 11.6531 18.8208Z"
                className="fill-[#DEA455] if:fill-[#B32274]"
                stroke="#2D2D2D"
                strokeWidth="0.75"
                strokeMiterlimit="10"
            />
        </svg>
    );
}

HeartBoxIcon.propTypes = {
    width: PropTypes.number,
};

HeartBoxIcon.defaultProps = {
    width: 100,
};
