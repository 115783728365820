import PropTypes from 'prop-types';

function TikTokIcon({ dark }) {
    return (
        <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.6098 0H11.4209V16.3478C11.4209 18.2957 9.80985 19.8957 7.80491 19.8957C5.79997 19.8957 4.18887 18.2957 4.18887 16.3478C4.18887 14.4348 5.76417 12.8695 7.69752 12.8V8.69567C3.43702 8.7652 0 12.1391 0 16.3478C0 20.5913 3.50862 24 7.84073 24C12.1728 24 15.6814 20.5565 15.6814 16.3478V7.9652C17.2567 9.07827 19.19 9.73913 21.2308 9.77393V5.66957C18.0802 5.56522 15.6098 3.06087 15.6098 0Z"
                fill={dark ? '#2D2D2D' : '#F1C34A'}
            />
        </svg>
    );
}

export default TikTokIcon;

TikTokIcon.propTypes = {
    dark: PropTypes.bool,
};

TikTokIcon.defaultProps = {
    dark: false,
};
