import PropTypes from 'prop-types';

function MMDeliveryDayIcon({ width }) {
    return (
        <svg width={width} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M31.3433 51.9094C44.5675 45.6446 56.5081 34.6524 53.5433 20.3218C50.5785 5.99118 37.1436 -0.335935 20.605 0.0137087C5.97741 0.329825 -0.488603 15.7285 0.0286782 31.3284C0.512432 45.9559 18.7322 57.8821 31.3433 51.9094Z"
                fill="#D7E1B8"
            />
            <path
                d="M14.1973 6.70654C14.1973 5.87812 14.8688 5.20654 15.6973 5.20654H34.6955C35.524 5.20654 36.1955 5.87812 36.1955 6.70654V40.5237H14.1973V6.70654Z"
                fill="#F8E1A4"
                stroke="#2D2D2D"
            />
            <path
                d="M17.6665 11.4355C17.6665 10.6071 18.3381 9.93555 19.1665 9.93555H22.2474C23.0758 9.93555 23.7474 10.6071 23.7474 11.4355V18.4432C23.7474 19.2716 23.0758 19.9432 22.2474 19.9432H19.1665C18.3381 19.9432 17.6665 19.2716 17.6665 18.4432V11.4355Z"
                fill="#F1C34A"
                stroke="#2D2D2D"
            />
            <path
                d="M17.6665 28.1445C17.6665 27.3161 18.3381 26.6445 19.1665 26.6445H22.2474C23.0758 26.6445 23.7474 27.3161 23.7474 28.1445V35.1522C23.7474 35.9806 23.0758 36.6522 22.2474 36.6522H19.1665C18.3381 36.6522 17.6665 35.9806 17.6665 35.1522V28.1445Z"
                fill="#F1C34A"
                stroke="#2D2D2D"
            />
            <path
                d="M26.6445 11.4355C26.6445 10.6071 27.3161 9.93555 28.1445 9.93555H31.2254C32.0539 9.93555 32.7254 10.6071 32.7254 11.4355V18.4432C32.7254 19.2716 32.0539 19.9432 31.2254 19.9432H28.1445C27.3161 19.9432 26.6445 19.2716 26.6445 18.4432V11.4355Z"
                fill="#F1C34A"
                stroke="#2D2D2D"
            />
            <path
                d="M26.6445 28.1445C26.6445 27.3161 27.3161 26.6445 28.1445 26.6445H31.2254C32.0539 26.6445 32.7254 27.3161 32.7254 28.1445V35.1522C32.7254 35.9806 32.0539 36.6522 31.2254 36.6522H28.1445C27.3161 36.6522 26.6445 35.9806 26.6445 35.1522V28.1445Z"
                fill="#F1C34A"
                stroke="#2D2D2D"
            />
            <ellipse cx="31.5291" cy="23.2469" rx="1.6966" ry="1.6966" fill="#2D2D2D" />
            <rect x="11.5083" y="40.5234" width="27.5122" height="3.58664" rx="1.5" fill="#F1C34A" stroke="#2D2D2D" />
        </svg>
    );
}

MMDeliveryDayIcon.propTypes = {
    width: PropTypes.number,
};

MMDeliveryDayIcon.defaultProps = {
    width: 54,
};

export default MMDeliveryDayIcon;
