import { AISLE_PATH_PREFIX } from 'constants/shop';

/**
 * Gets an encoded path segment
 * @param {string} segement - The segment to encode
 * @example
 * // returns 'Meat+%26+Seafood'
 * const encodedPathSegment = getEncodedPathSegment('Meat & Seafood');
 * @returns {string} The encoded path segment
 */
export const getEncodedPathSegment = (segment) => encodeURIComponent(segment).replace(/%20/g, '+');

/**
 * Gets a decoded path segment
 * @param {string} segement - The segment to decode
 * @example
 * // returns 'meat & seafood'
 * const decodedPathSegment = getDeodedPathSegment('meat+%26+seafood');
 * @returns {string} The decoded path segment
 */
export const getDecodedPathSegment = (segment) => decodeURIComponent(segment).replace(/\+/g, ' ');

/**
 * Gets an aisle path
 * @param {string} aiseName - The aisle name
 * @param {string} subcategoryName - optional, defaults to "all"
 * @example
 * // returns '/shop/aisle/meat+%26+seafood/all'
 * const aislePath = getAislePath('Meat & Seafood');
 * @example
 * // returns '/shop/aisle/meat+%26+seafood/shrimp+%26+scallops'
 * const aislePath = getAislePath('Meat & Seafood', 'Shrimp & Scallops');
 * @returns {string} The aisle path
 */
export const getAislePath = (aisleName, subcategoryName = 'all') => {
    const encodedAisleName = getEncodedPathSegment(aisleName)?.toLowerCase();
    const encodedSubcategoryName = getEncodedPathSegment(subcategoryName)?.toLowerCase();
    return `${AISLE_PATH_PREFIX}/${encodedAisleName}/${encodedSubcategoryName}`;
};
