import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { matchPath, Outlet, ScrollRestoration, useLocation, useMatch } from 'react-router-dom';

import useAccountQuery from 'api/queries/useAccountQuery';
import useSelectionsQuery from 'api/queries/useSelectionsQuery';
import Cart from 'features/shop/cart/Cart';
import CartSeed from 'features/shop/cart/CartSeed';
import CartSheet from 'features/shop/cart/CartSheet';
import { DevBar } from 'features/site/DevBar/DevBar';
import { Footer } from 'features/site/Footer/Footer';
import { Header } from 'features/site/header/Header';
import { useAccountPlusMembershipHook } from 'hooks/useAccountPlusMembershipHook';
import { useCaptureReferrerData } from 'hooks/useCaptureReferrerData';
import useGlobalStore from 'hooks/useGlobalStore';
import useTailwindTheme from 'hooks/useTailwindTheme';
import useTrailingSlashRedirect from 'hooks/useTrailingSlashRedirect';
import { getIsProduction } from 'utils';
import { setUserId, updateProfile } from 'utils/analyticsUtils';
import { getTotalProductQty } from 'utils/orderUtils';

function App() {
    const { data: account = {} } = useAccountQuery();
    const { data: selections } = useSelectionsQuery();
    const { addCounter } = useGlobalStore();
    const numOrders = account?.numOrders;

    const location = useLocation();
    const isShopRoute = !!useMatch('/shop/*');
    const isLeadCapRoute = useMatch('/join');
    const isSignupRoute = !!useMatch('/join/*');
    const isSignupQuizRoute = !!useMatch('/join/quiz/*');
    const isLoginRoute = !!useMatch('/login/*');
    const tailwindTheme = useTailwindTheme();
    const mobile = useMediaQuery({ maxWidth: tailwindTheme?.screens?.md });

    const isCartSeedRoute =
        isShopRoute ||
        ['/account', '/account/upcoming-deliveries'].find((cartSeedPath) => matchPath(cartSeedPath, location.pathname));

    const { availableTrialMembership, isPlusMembershipOptionsLoading, membershipStatus } =
        useAccountPlusMembershipHook();

    const { operationsFee } = account?.nextOrder?.cartExperience ?? {};

    useEffect(() => {
        // we only want to update the profile if the user has completed signup
        // as the useAccountPlusMembershipHook leverages the BE feature flag value,
        // which is not added until the user completes signup.
        if (!location.pathname.includes('/join') && !isPlusMembershipOptionsLoading) {
            if (membershipStatus === 'eligible') {
                updateProfile({
                    membership_status: availableTrialMembership ? 'free_trial_eligible' : 'paid_eligible',
                });
            } else {
                updateProfile({ membership_status: membershipStatus });
            }
        }
    }, [membershipStatus, location.pathname, isPlusMembershipOptionsLoading]);

    useTrailingSlashRedirect();

    useCaptureReferrerData();

    useEffect(() => {
        if (account?.customerID) {
            setUserId(account.customerID);
        }
    }, [account?.customerID]);

    useEffect(() => {
        if (account?.brand) {
            updateProfile({ brand: account.brand });
        }

        /**
         * We're currently omitting signup routes and handling the signup flow
         * explicitly due to the BE being unreliable on the first request to
         * /account after subscription creation.
         * This logic will be removed in CUS-5308.
         */
        if (operationsFee != null && !isSignupRoute) {
            updateProfile({ operationsFee });
        }
    }, [account?.brand, operationsFee]);

    useEffect(() => {
        if (addCounter === 3 && numOrders > 1 && window.isNativeApp) {
            window.ReactNativeWebView?.postMessage(JSON.stringify({ navigate: 'AppReview' }));
        }
    }, [addCounter]);

    useEffect(() => {
        if (window.isNativeApp && selections) {
            const cartQuantity = getTotalProductQty([...selections.selected, ...selections.selectedBundles]);
            window.ReactNativeWebView?.postMessage(JSON.stringify({ cartQuantity }));
        }
    }, [selections]);

    return (
        <>
            {account?.marketplaceOpen && isCartSeedRoute && <CartSeed />}
            {!isLoginRoute && <Header />}
            <Outlet />
            {!window.isNativeApp && !isShopRoute && !isSignupQuizRoute && !isLeadCapRoute && !isLoginRoute && (
                <Footer />
            )}
            {!window.isNativeApp && !getIsProduction() && <DevBar />}
            {!window.isNativeApp && account?.marketplaceOpen && mobile && isShopRoute && (
                <CartSheet mobile orderMinOnly />
            )}
            {account?.marketplaceOpen && isShopRoute && <Cart />}
            <ScrollRestoration
                getKey={(scrollLocation) => {
                    // https://reactrouter.com/en/main/components/scroll-restoration#getkey
                    // this is used to maintain scroll restoration between pages that are not child routes of the same path
                    const paths = ['/join', '/grocery-and-produce-delivery-sneak-peek', '/:slug/p'];
                    return paths.includes(scrollLocation.pathname) ? scrollLocation.pathname : scrollLocation.key;
                }}
            />
        </>
    );
}

export default App;
