import { CheckIcon, ShoppingCartIcon } from '@heroicons/react/24/solid';

import useAccountQuery from 'api/queries/useAccountQuery';
import useSelectionsQuery from 'api/queries/useSelectionsQuery';
import Button from 'honeydew/Button/Button';
import { useAccountPlusMembershipHook } from 'hooks/useAccountPlusMembershipHook/useAccountPlusMembershipHook';
import useGlobalStore from 'hooks/useGlobalStore';
import { filterPerkSelections, getCartSubTotal, getOrderMin, getTotalProductQty } from 'utils/orderUtils';
import { getCartSubtotal, PriceType } from 'utils/priceUtils';

function CartButton() {
    const { productAdded, setCartOpen } = useGlobalStore();

    const { inPlusMembershipTreatment, plusMembershipEligibleOrder } = useAccountPlusMembershipHook();

    const { data: account } = useAccountQuery();
    const { data: selections } = useSelectionsQuery();

    if (!selections) return false;

    const filteredSelections = filterPerkSelections(selections);

    const orderMin = getOrderMin(account);

    const priceType = plusMembershipEligibleOrder ? PriceType.PLUS_MEMBER : PriceType.DEFAULT;

    const cartSubtotal = inPlusMembershipTreatment
        ? getCartSubtotal({
              account,
              options: { priceType },
              selections,
          })
        : getCartSubTotal(account, filteredSelections);

    const cartQuantity = getTotalProductQty([...selections.selected, ...selections.selectedBundles]);
    const orderMinMet = cartSubtotal >= orderMin;

    return (
        <span className="md:w-[180px]">
            <Button
                onClick={() => setCartOpen((currVal) => !currVal)}
                size="md"
                rounded="full"
                variant={orderMinMet ? 'primary' : 'error'}
            >
                <span className="notranslate flex items-center text-body-mobile-md">
                    {productAdded ? (
                        <>
                            <CheckIcon className="mb-[1px] mr-5 w-20" />
                            Added!
                        </>
                    ) : (
                        <>
                            <ShoppingCartIcon className="mr-10 w-20" />
                            <span className="mr-10">{cartQuantity}</span>
                            {orderMinMet ? (
                                <span className="relative top-[2px] font-grotesk">${cartSubtotal.toFixed(2)}</span>
                            ) : (
                                <p className="text-body-mobile-sm">INCOMPLETE</p>
                            )}
                        </>
                    )}
                </span>
            </Button>
        </span>
    );
}

export default CartButton;
