import PropTypes from 'prop-types';

import MMProduceActive from './MMProduceActive';
import MMProduceInactive from './MMProduceInactive';

function MMProduce({ className, isActive, width }) {
    return isActive ? (
        <MMProduceActive className={className} width={width} />
    ) : (
        <MMProduceInactive className={className} width={width} />
    );
}

export default MMProduce;

MMProduce.propTypes = {
    isActive: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

MMProduce.defaultProps = {
    isActive: true,
    width: 28,
};
