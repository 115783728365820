import { string } from 'yup';

import { checkDiscountCode, claimDiscountCode, claimGiftCard } from 'api/jalapeno';
import { ACCOUNT_QUERY_KEY } from 'api/queries/useAccountQuery';

const discountSchema = string().required();

const action =
    (queryClient) =>
    async ({ request }) => {
        const formData = await request.formData();
        const { customerID, discountCode, subscriptionID } = Object.fromEntries(formData);

        const errorResponse = {
            error: true,
            message: 'Unable to process code',
        };

        try {
            discountSchema.validateSync(discountCode);
        } catch {
            errorResponse.message = 'Must provide discount code';
            return errorResponse;
        }

        if (discountCode.startsWith('GIFT-')) {
            try {
                const response = await claimGiftCard(subscriptionID, discountCode);
                if (response.msg !== 'Success') {
                    errorResponse.message = response.msg ?? errorResponse.message;
                    return errorResponse;
                }
            } catch (error) {
                return errorResponse;
            }
        } else {
            try {
                const response = await checkDiscountCode(customerID, discountCode);
                if (!response.data?.disc.valid) {
                    errorResponse.message = response.data.disc.msg ?? errorResponse.message;
                    return errorResponse;
                }
                await queryClient.invalidateQueries({ queryKey: [ACCOUNT_QUERY_KEY] });
            } catch (error) {
                return errorResponse;
            }

            try {
                const claimDiscountResponse = await claimDiscountCode(subscriptionID, discountCode);
                const validCode = claimDiscountResponse.msg === 'Success' && claimDiscountResponse.data.claimed;
                if (!validCode) {
                    return errorResponse;
                }
                await queryClient.invalidateQueries({ queryKey: [ACCOUNT_QUERY_KEY] });
            } catch (error) {
                return errorResponse;
            }
        }

        return {};
    };

export default action;
