import { Auth } from 'aws-amplify';
import { redirect } from 'react-router';

import { ACCOUNT_QUERY_KEY, accountQueryFn } from 'api/queries/useAccountQuery';
import { SignupQuizStepsOrdered } from 'constants/signup';
import { trackExistingUserRedirect } from 'utils/analyticsUtils';
import { getSignupLocalStorage, setSignupLocalStorage } from 'utils/signupUtils';

export default function authRequiredLoader(queryClient) {
    return async () => {
        const signupLocalStorage = getSignupLocalStorage();

        let authUser;
        try {
            authUser = await Auth.currentAuthenticatedUser();
        } catch {
            authUser = null;
        }

        const account = authUser
            ? await queryClient.ensureQueryData({
                  queryKey: [ACCOUNT_QUERY_KEY, authUser?.username],
                  queryFn: accountQueryFn,
              })
            : null;

        const authedNoSub = account?.subStatus && account?.subStatus === 'NONE';

        if (authedNoSub && !signupLocalStorage?.path) {
            const firstQuizStepPath = `/join/quiz/${SignupQuizStepsOrdered[0]}`;
            setSignupLocalStorage({ path: firstQuizStepPath });
            trackExistingUserRedirect({ destination: firstQuizStepPath });
            return redirect(firstQuizStepPath);
        }

        if (authedNoSub && signupLocalStorage?.path) {
            trackExistingUserRedirect({ destination: signupLocalStorage?.path });
            return redirect(signupLocalStorage?.path);
        }

        return null;
    };
}
