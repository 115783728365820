import { useQuery } from '@tanstack/react-query';

import { getPreferences, getV2Preferences } from 'api/jalapeno';

import useAccountQuery from './useAccountQuery';

export const PREFERENCES_QUERY_KEY = 'preferences';

const usePreferencesQuery = () => {
    const { data: account = {} } = useAccountQuery();

    const { planType, subID } = account;

    const query = useQuery({
        queryKey: [PREFERENCES_QUERY_KEY, subID],
        queryFn: async () => {
            let response;
            let preferencesData = {};

            if (planType === 'v2') {
                response = await getV2Preferences(subID);

                const { output, recurring = [] } = response?.data || {};

                preferencesData = {
                    packPreferences: output,
                    recurring: [
                        {
                            name: 'Recurring',
                            recurring,
                        },
                    ],
                };
            } else {
                response = await getPreferences(subID);

                const { dislikes, favorites, neutral, recurring = [] } = response?.data?.output || {};

                preferencesData = {
                    packPreferences: [
                        {
                            dislikes,
                            favorites,
                            name: 'V1 Preferences',
                            neutral,
                        },
                    ],
                    recurring: [
                        {
                            name: 'Recurring',
                            recurring,
                        },
                    ],
                };
            }

            return preferencesData;
        },
        enabled: !!subID,
    });

    return query;
};

export default usePreferencesQuery;
