import PropTypes from 'prop-types';

import Pill from 'honeydew/Pill';

export function PillSection({ items }) {
    return (
        <div className="grid grid-cols-2 gap-5 p-5">
            {items.map((item) => (
                <Pill as="button" variant={item.active ? 'active' : 'default'} onClick={item.onClick} key={item.name}>
                    {item.name}
                </Pill>
            ))}
        </div>
    );
}

PillSection.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            active: PropTypes.bool,
            name: PropTypes.string,
            onClick: PropTypes.func,
        })
    ).isRequired,
};
