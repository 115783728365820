import PropTypes from 'prop-types';

function WaterSavedIcon({ className, width }) {
    return (
        <svg
            width={width}
            height="30"
            viewBox="0 0 31 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M4.48196 13.2357C6.61501 9.40441 8.55395 6.59105 10.2986 3.82014C10.8698 2.91298 11.9685 2.71049 12.6348 3.5503C13.8756 5.11431 15.9329 8.10164 19.5457 13.2357C22.667 17.6712 23.5248 26.8785 11.3032 26.8785C-0.918363 26.8785 2.08566 17.5399 4.48196 13.2357Z"
                className="fill-perks stroke-black if:fill-[#DBF3F5] if:stroke-[#453D3D]"
                strokeWidth="0.5"
                strokeLinejoin="round"
            />
            <path
                d="M16.1957 10.2304C17.6084 7.69301 18.9024 5.7781 20.0776 3.93488C20.6539 3.03098 21.7291 2.85857 22.3759 3.71347C23.289 4.92039 24.7083 6.97299 27.0005 10.2304C29.2392 13.4118 29.8546 20.0159 21.0884 20.0159C12.3223 20.0159 14.4769 13.3176 16.1957 10.2304Z"
                className="fill-[#B0BDCD] stroke-black if:fill-[#C6E8EB] if:stroke-[#453D3D]"
                strokeWidth="0.5"
                strokeLinejoin="round"
            />
            <path
                d="M4.9016 18.8691C4.30942 18.3997 4.14073 23.913 9.33822 24.065C11.4062 24.0705 13.2113 23.3914 13.2113 23.3914C13.2113 23.3914 8.19701 21.4537 4.9016 18.8691Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.8962 13.6971C16.4799 13.3671 16.3613 17.2432 20.0153 17.35C21.4692 17.3539 22.7382 16.8765 22.7382 16.8765C22.7382 16.8765 19.213 15.5142 16.8962 13.6971Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

WaterSavedIcon.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number,
};

WaterSavedIcon.defaultProps = {
    className: '',
    width: 23,
};

export default WaterSavedIcon;
