import { dietaryFilterData } from 'features/shop/filters/DietaryFilters/DietaryFiltersData';

/**
 * add a filter from a list of filters
 * @param {filter} - the filter to add
 * @param {filterList} - the filter list to add to
 * @example
 * addFilterToList('Sale', ['New', 'Best Sellers'])
 * @returns {array} ['New', 'Best Sellers', 'Sale']
 */
const addFilterToList = (filter, filterList) => {
    const filterIndex = filterList?.indexOf(filter);
    if (filterIndex === -1) {
        return [...filterList, filter];
    }
    return filterList;
};

/**
 * removes a filter from a list of filters
 * @param {filter} - the filter to remove
 * @param {filterList} - the filter list to remove from
 * @example
 * removeFilterToList('Sale', ['New', 'Best Sellers', 'Sale'])
 * @returns {array} ['New', 'Best Sellers']
 */
const removeFilterFromList = (filter, filterList) => {
    const filters = [...filterList];
    const filterIndex = filters?.indexOf(filter);
    if (filterIndex !== -1) {
        filters.splice(filterIndex, 1);
    }
    return filters;
};

/**
 * removes multiple filters from a list of filters
 * @param {filters} - the filters to remove
 * @param {filterList} - the filter list to remove from
 * @example
 * clearFiltersFromList(['Sale', 'New'], ['New', 'Best Sellers', 'Sale'])
 * @returns {array} ['Best Sellers']
 */
const clearFiltersFromList = (filters, filterList) => {
    let tempFiltersList = filterList;
    filters.forEach((filter) => {
        tempFiltersList = removeFilterFromList(filter.name, tempFiltersList);
    });
    return tempFiltersList;
};

/**
 * filters out a list of products by filters that are applied
 * @param {appliedFilters} - the filters that are applied
 * @param {subAisles} - unfiltered list of products with L2 categories
 * @param {items} - unfiltered list of products without L2 categories
 * @example
 * filterProducts(['Sale', 'New'], <subAisles>, <items>)
 * @returns {array} filtered subAisles or items
 */
const filterProducts = (appliedFilters, subAisles, items) => {
    const filterItems = (array = []) =>
        array.reduce((filteredItems, item) => {
            const inStockApplied = appliedFilters.includes('In Stock');
            const itemHasAppliedFilter =
                (appliedFilters.includes('Sale') && item?.sale === true) ||
                (appliedFilters.includes('New') && item?.new === true) ||
                (appliedFilters.includes('Best Sellers') && item?.bestSeller === true) ||
                (appliedFilters.includes('Last Chance') && item?.exitSku === true) ||
                (appliedFilters.includes('Rescued') && item?.sourcingReason) ||
                item.attributes?.some((attr) => appliedFilters.includes(attr));

            if (
                ((itemHasAppliedFilter && ((inStockApplied && !item.soldOut) || !inStockApplied)) ||
                    (appliedFilters?.length === 1 && inStockApplied && !item.soldOut)) &&
                !item.isGift
            ) {
                filteredItems.push(item);
            }
            return filteredItems;
        }, []);

    if (appliedFilters.length === 0) {
        return subAisles?.length > 0 ? subAisles : items;
    }

    if (subAisles?.length > 0) {
        return subAisles.reduce((filtered, aisle) => {
            const filteredItemsResult = filterItems(aisle.items);
            if (filteredItemsResult.length > 0) {
                filtered.push({ ...aisle, items: filteredItemsResult });
            }
            return filtered;
        }, []);
    }

    return filterItems(items);
};

/**
 * get a list of filters that can be applied (atleast one product with that attribute)
 * @param {filterOptions} - full list of possible filters
 * @param {subAisles} - list of products with L2 categories
 * @param {items} - list of products without L2 categories
 * @example
 * getAvailableFilters(['Sale', 'New', ...], <subAisles>, <items>)
 * @returns {array} list of filters that can be applied
 */
const getAvailableFilters = (filterOptions, subAisles, items) => {
    const availableAttributes = [];
    const unfilteredItems =
        subAisles?.length > 0 ? [].concat(...subAisles.map(({ items: aisleItems }) => aisleItems || [])) : items;

    unfilteredItems?.forEach((item) => {
        if (item.sale && !availableAttributes.includes('Sale')) {
            availableAttributes.push('Sale');
        }
        if (item.new && !availableAttributes.includes('New')) {
            availableAttributes.push('New');
        }
        if (item.bestSeller && !availableAttributes.includes('Best Sellers')) {
            availableAttributes.push('Best Sellers');
        }
        if (item.exitSku && !availableAttributes.includes('Last Chance')) {
            availableAttributes.push('Last Chance');
        }
        if (item.sourcingReason && !availableAttributes.includes('Rescued')) {
            availableAttributes.push('Rescued');
        }
        if (!item.soldOut && !availableAttributes.includes('In Stock')) {
            availableAttributes.push('In Stock');
        }
        item.attributes?.forEach((attribute) => {
            if (!availableAttributes.includes(attribute)) {
                availableAttributes.push(attribute);
            }
        });
        if (item.sourcingReason) {
            const rescuedIndex = filterOptions?.findIndex((filter) => filter.name === 'Rescued');
            const rescuedOptions = filterOptions[rescuedIndex]?.options;
            const rescuedOptionIndex = rescuedOptions?.findIndex((option) => option.name === item.sourcingReason);

            if (rescuedOptionIndex === -1) {
                filterOptions[rescuedIndex]?.options?.push({ name: item.sourcingReason });
            }
        }
    });

    const result = filterOptions.reduce((filters, filter) => {
        if (filter.options) {
            const optionResults = filter.options.reduce((options, option) => {
                if (availableAttributes.includes(option.name)) {
                    if (
                        option.name === 'Organic' &&
                        (window.location.pathname.includes('/vegetables') ||
                            window.location.pathname.includes('/fruit'))
                    ) {
                        filters.unshift(option);
                    } else {
                        options.push(option);
                    }
                }
                return options;
            }, []);

            if (optionResults?.length) {
                filters.push({ name: filter.name, options: optionResults });
            }
        }
        if (availableAttributes.includes(filter.name)) {
            filters.push(filter);
        }
        return filters;
    }, []);

    return result;
};

/**
 * check if filters are on the filter data list
 * @param {array} - list of possible filters
 * @example
 * checkValidFilters(['Sale', 'New', ...])
 * @returns {array} list of filters that are valid
 */
const checkValidFilters = (filterOptions) =>
    dietaryFilterData.reduce((validOptions, filter) => {
        if (filter.options) {
            filter.options.reduce((options, option) => {
                if (filterOptions.includes(option.name) || filterOptions.includes(option.name.toLowerCase())) {
                    validOptions.push(option.name);
                }
                return options;
            }, []);
        }
        if (filterOptions.includes(filter.name) || filterOptions.includes(filter.name.toLowerCase())) {
            validOptions.push(filter.name);
        }
        return validOptions;
    }, []);

export {
    addFilterToList,
    checkValidFilters,
    filterProducts,
    getAvailableFilters,
    removeFilterFromList,
    clearFiltersFromList,
};
