import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';

import useAccountQuery from 'api/queries/useAccountQuery';
import useReferralCreditsQuery from 'api/queries/useReferralCreditsQuery';
import useSubscriptionQuery from 'api/queries/useSubscriptionQuery';
import { Divider } from 'honeydew/Divider';
import { DropdownLink } from 'honeydew/Dropdown';
import {
    AccountDetailsIcon,
    DeliveryScheduleIcon,
    FoodPreferencesIcon,
    HomeIcon,
    ImperfectPlusIcon,
    MisfitsPlusIcon,
    MyPlanIcon,
    OrdersIcon,
} from 'honeydew/icons/Navigation';
import Tag from 'honeydew/Tag';
import { useAccountPlusMembershipHook } from 'hooks/useAccountPlusMembershipHook';
import useGlobalStore from 'hooks/useGlobalStore';
import useTailwindTheme from 'hooks/useTailwindTheme';

import MisfitsPlusLinkTitle from './MisfitsPlusLinkTitle';

const AISLE_LINKS = [
    {
        id: 1,
        title: 'Account Home',
        path: '/account',
        icon: <HomeIcon />,
    },
    {
        id: 2,
        title: 'Orders',
        path: '/account/orders',
        icon: <OrdersIcon />,
    },
    {
        id: 3,
        title: 'Upcoming Deliveries',
        path: '/account/upcoming-deliveries',
        icon: <DeliveryScheduleIcon />,
    },
    {
        id: 4,
        title: 'My Plan',
        path: '/account/plan',
        icon: <MyPlanIcon />,
    },
    {
        id: 5,
        title: 'Food Preferences',
        path: '/account/food-preferences',
        icon: <FoodPreferencesIcon />,
    },
    {
        id: 7,
        title: 'My Profile',
        path: '/account/profile',
        icon: <AccountDetailsIcon />,
    },
    {
        id: 8,
        title: 'Refer a Friend',
        path: '/account/referrals',
    },
    {
        id: 9,
        title: 'Misfits Perks',
        path: '/account/misfits-perks',
    },
    {
        id: 10,
        title: 'Log Out',
        path: '/logout',
    },
];

function LinkContent({ icon, id, title }) {
    const { data: referrals = {} } = useReferralCreditsQuery();
    const referralLink = title === 'Refer a Friend';

    return (
        <div className={clsx('flex', { 'items-center': id !== 'plus-membership' })}>
            {icon && (id === 'plus-membership' ? <div className="mt-[3px]">{icon}</div> : icon)}
            <span className={clsx({ 'mr-10': referralLink }, { 'ml-10': icon })}>{title}</span>

            {/* check for referrals link to add referral value tag */}
            {referralLink && (
                <span className="ml-auto">
                    <Tag variant="dark" bgColor="lime">{`Earn $${
                        referrals?.referralRewards?.referrerValue ?? 10
                    }`}</Tag>
                </span>
            )}
        </div>
    );
}

LinkContent.propTypes = {
    icon: PropTypes.node,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.string.isRequired,
};

LinkContent.defaultProps = {
    icon: null,
};

// pass in dropdown parameter if the account links are going to live inside a dropdown menu
function AccountLinks({ clickableLink, clickHandler, dropdown, hideLogOut, sideMenu }) {
    const { data: account = {} } = useAccountQuery();
    const { data: subscription = {} } = useSubscriptionQuery();

    const { isMisfitsTheme } = useGlobalStore();
    const tailwindTheme = useTailwindTheme();
    const mobile = useMediaQuery({ maxWidth: tailwindTheme?.screens?.md });

    const {
        availableCredits,
        availableTrialMembership,
        inPlusMembershipTreatment,
        membership,
        plusMembershipBranding,
        trial,
    } = useAccountPlusMembershipHook();

    const misfitsPlusLink = {
        id: 'plus-membership',
        title: (
            <MisfitsPlusLinkTitle
                availableCredits={availableCredits}
                availableTrialMembership={availableTrialMembership}
                membership={membership}
                plusMembershipBranding={plusMembershipBranding}
                trial={trial}
            />
        ),
        path: '/account/membership',
        icon: isMisfitsTheme ? <MisfitsPlusIcon /> : <ImperfectPlusIcon />,
    };

    // check for log out link and remove if hideLogOut is true
    // check for delivery schedule link and remove if user is not autoship
    const filterAisleLinks = () => {
        let aisleLinks = [...AISLE_LINKS];

        if (inPlusMembershipTreatment) {
            aisleLinks.splice(5, 0, misfitsPlusLink);
        }

        if (hideLogOut) {
            aisleLinks = aisleLinks.filter((l) => l.path !== '/logout');
        }

        if (subscription?.variant !== 'autoship') {
            aisleLinks = aisleLinks.filter((l) => l.path !== '/account/upcoming-deliveries');

            if (!account?.prefCenterEligible) {
                aisleLinks = aisleLinks.filter((l) => l.path !== '/account/food-preferences');
            }
        }

        return aisleLinks;
    };

    return (
        <ul className={clsx({ 'py-10': dropdown, 'pb-10': sideMenu }, 'm-0 list-none')}>
            {filterAisleLinks().map(({ icon, id, path, title }) => (
                <React.Fragment key={id}>
                    {dropdown && (
                        <DropdownLink
                            className="link-hover block p-[10px] hover:bg-off-white"
                            linkUrl={path}
                            title={title}
                        >
                            <LinkContent title={title} icon={icon} id={id} />
                        </DropdownLink>
                    )}

                    {!dropdown &&
                        (clickableLink ? (
                            <button
                                type="button"
                                onClick={() => clickHandler(path)}
                                className={clsx(
                                    { 'text-brand-primary': window.location.pathname === path },
                                    'link-hover block w-full py-[8px]'
                                )}
                            >
                                <LinkContent title={title} icon={icon} id={id} />
                            </button>
                        ) : (
                            <NavLink
                                to={path}
                                className={({ isActive }) =>
                                    clsx(
                                        { 'text-brand-primary font-grotesk-bold': isActive },
                                        {
                                            'grid-cols-[1fr_65px] items-center lg:grid-cols-[1fr_74px]':
                                                path === '/account/referrals',
                                        },
                                        'link-hover flex py-[8px] no-underline'
                                    )
                                }
                                end
                            >
                                <LinkContent title={title} icon={icon} id={id} />
                            </NavLink>
                        ))}

                    {/* add Divider after My Profile and after Misfits Perks if sideMenu is true */}
                    {(path === '/account/profile' || (path === '/account/misfits-perks' && sideMenu && !hideLogOut)) &&
                        !mobile && (
                            <Divider
                                height={1}
                                width={dropdown ? 'w-[calc(100%-41px)]' : 'w-full'}
                                color="grey-light"
                                my={dropdown ? 'my-0' : 'my-10'}
                                mx={dropdown ? 'mx-auto' : 'mx-0'}
                            />
                        )}

                    {mobile && <Divider height={2} width="w-full" color="brand-secondary" my="my-10" mx="mx-0" />}
                </React.Fragment>
            ))}
        </ul>
    );
}

AccountLinks.propTypes = {
    clickableLink: PropTypes.bool,
    clickHandler: PropTypes.func,
    dropdown: PropTypes.bool,
    hideLogOut: PropTypes.bool,
    sideMenu: PropTypes.bool,
};

AccountLinks.defaultProps = {
    clickableLink: false,
    clickHandler: () => {},
    dropdown: false,
    hideLogOut: false,
    sideMenu: false,
};

export default AccountLinks;
