import {
    perksPlanFaqList,
    perksPointsFaqList,
    perksProgramFaqList,
    perksRefundsFaqList,
    perksRestrictionsFaqList,
    perksShoppingFaqList,
} from 'data/faqData';
import { Accordion } from 'honeydew/Accordion';

const headingStyles = 'font-grotesk-bold text-body-lg';

function PerksFAQsModule() {
    return (
        <div className="space-y-60 py-40">
            <h3 className="text-heading-sm mb-40 font-grotesk-bold">FAQs</h3>
            <div>
                <h4 className={headingStyles}>Misfits Perks Program Overview</h4>
                <Accordion items={perksProgramFaqList} />
            </div>
            <div>
                <h4 className={headingStyles}>Earning Points</h4>
                <Accordion items={perksPointsFaqList} />
            </div>
            <div>
                <h4 className={headingStyles}>Shopping with Points</h4>
                <Accordion items={perksShoppingFaqList} />
            </div>
            <div>
                <h4 className={headingStyles}>Earning with Your Plan</h4>
                <Accordion items={perksPlanFaqList} />
            </div>
            <div>
                <h4 className={headingStyles}>Refunds and Cancellations</h4>
                <Accordion items={perksRefundsFaqList} />
            </div>
            <div>
                <h4 className={headingStyles}>Program Restrictions</h4>
                <Accordion items={perksRestrictionsFaqList} />
            </div>
        </div>
    );
}

export default PerksFAQsModule;
