import PropTypes from 'prop-types';

function MMColdPackActive({ className, width }) {
    return (
        <svg width={width} viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path
                d="M16.2657 2.03637C16.2657 2.03637 20.135 1.68679 21.4937 3.15519C23.0853 5.06976 22.2485 5.89553 23.1083 13.4773C23.8008 19.6072 24.3127 22.2576 22.9376 23.6394C22.0877 24.4985 19.6296 26.0968 19.6296 26.0968L16.2657 2.03637Z"
                fill="#F2EBD1"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.2546 26.7729L8.27136 27.5986C7.68975 27.6456 7.11349 27.4568 6.66877 27.0737C6.22406 26.6905 5.94711 26.1441 5.8986 25.5542L4.25769 5.27632C4.21139 4.68623 4.39745 4.10153 4.77512 3.65033C5.15279 3.19912 5.6913 2.91818 6.27273 2.86895L16.256 2.04319C16.8376 1.99622 17.4139 2.18499 17.8586 2.56818C18.3033 2.95136 18.5802 3.49769 18.6287 4.08759L20.2696 24.3655C20.3159 24.9556 20.1299 25.5402 19.7522 25.9914C19.3745 26.4426 18.836 26.7237 18.2546 26.7729V26.7729Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.8094 9.60156C18.8094 9.60156 14.031 10.4607 5.12421 10.7337"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.98187 13.1777L7.38225 18.1789"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.44025 6.45801L6.61418 8.60234"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.8451 16.0745C22.4686 13.2276 23.2563 9.66818 23.486 8.80912C23.8765 7.41065 23.8306 5.94887 25.5076 6.27518C27.1846 6.60149 27.4767 7.16087 27.2404 8.51273C27.0796 9.39843 26.0852 13.4307 25.3402 16.6772L21.8451 16.0745Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M23.3056 9.64453L25.0318 10.0441Z" fill="white" />
            <path
                d="M23.3056 9.64453L25.0318 10.0441"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.6328 12.918L23.8766 13.2063"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.0409 19.7569L21.845 16.0742L25.3532 16.6736C25.3532 16.6736 24.6706 19.8201 24.5459 20.566C24.9725 21.0721 26.2557 22.8602 25.0743 24.6582C23.8928 26.4562 21.9172 26.4229 20.4403 25.5872C18.9635 24.7514 18.6222 22.7703 19.0029 21.8579C19.0029 21.8579 19.5214 20.1331 21.0409 19.7569Z"
                fill="#B0C372"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default MMColdPackActive;

MMColdPackActive.propTypes = {
    className: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

MMColdPackActive.defaultProps = {
    className: null,
    width: 28,
};
