import styles from './LoadingIcon.module.css';

function LoadingIcon() {
    return (
        <>
            <span className={styles.loader} />
            <span className="sr-only">Loading...</span>
        </>
    );
}

export default LoadingIcon;
