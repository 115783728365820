import PropTypes from 'prop-types';

function CO2ESavedIcon({ className, width }) {
    return (
        <svg
            width={width}
            height="22"
            viewBox="0 0 18 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M1.84768 21.3559C1.84768 21.3559 -4.40942 3.88375 17.0759 1C17.0759 1 20.8718 20.0799 6.69945 21.3559C5.49115 21.4665 1.84768 21.3559 1.84768 21.3559Z"
                className="fill-[#B0C472] stroke-black if:fill-[#C5DB66] if:stroke-[#453D3D]"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M5.18262 16.9304C5.18262 16.9304 7.42818 9.6178 13.3544 5.47656L5.18262 16.9304Z" fill="#B0C472" />
            <path
                d="M5.18262 16.9304C5.18262 16.9304 7.42818 9.6178 13.3544 5.47656"
                className="stroke-black if:stroke-[#453D3D]"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.18262 16.9313C5.18262 16.9313 6.55078 13.7673 12.3729 12.5098L5.18262 16.9313Z"
                fill="#B0C472"
                className="fill-[#B0C472] if:fill-[#C5DB66]"
            />
            <path
                d="M5.18262 16.9313C5.18262 16.9313 6.55078 13.7673 12.3729 12.5098"
                className="stroke-black if:stroke-[#453D3D]"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

CO2ESavedIcon.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number,
};

CO2ESavedIcon.defaultProps = {
    className: '',
    width: 23,
};

export default CO2ESavedIcon;
