import PropTypes from 'prop-types';

function TimerIcon({ width }) {
    return (
        <svg width={width} viewBox="0 0 57 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M33.0914 58.7837C47.0463 52.1797 59.6467 40.6077 56.5181 25.4863C53.3895 10.3648 39.2122 3.71036 21.7597 4.07893C6.31878 4.41216 -0.479252 20.6444 0.0261782 37.0886C0.531608 52.5079 19.7632 65.0797 33.0712 58.7989"
                className="fill-mango if:fill-[#FFCBDC]"
            />
            <path
                d="M36.4582 50.222L42.1582 41.6413C42.1582 41.6413 42.336 28.1575 42.1582 25.2401C41.9805 22.3227 41.0182 17.9589 37.684 14.7105C34.3498 11.4621 29.4405 9.35986 25.665 9.35986C21.8895 9.35986 17.4215 12.3447 16.7166 12.9576C16.0118 13.5705 13.125 16.7269 13.125 16.7269"
                className="fill-[#AFC272] if:fill-[#F8E1A4]"
            />
            <path
                d="M36.4582 50.222L42.1582 41.6413C42.1582 41.6413 42.336 28.1575 42.1582 25.2401C41.9805 22.3227 41.0182 17.9589 37.684 14.7105C34.3498 11.4621 29.4405 9.35986 25.665 9.35986C21.8895 9.35986 17.4215 12.3447 16.7166 12.9576C16.0118 13.5705 13.125 16.7269 13.125 16.7269"
                stroke="#2D2D2D"
                strokeWidth="0.773197"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.65047 45.1347C7.30822 44.7057 8.29499 30.3086 8.65047 26.2757C9.00596 22.2428 10.4034 18.8167 13.1247 16.7083C15.846 14.6 19.0024 13.9012 22.594 14.2567C26.1856 14.6122 31.2789 15.7461 34.4414 20.7473C37.604 25.7486 36.985 27.1521 37.2485 29.6957C37.5121 32.2392 36.7214 49.6946 36.4579 50.2217C36.1944 50.7488 12.5056 46.3605 8.65047 45.1347Z"
                fill="white"
                stroke="#2D2D2D"
                strokeWidth="0.773197"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M33.9146 32.3134C33.9146 39.0553 29.9001 44.4672 23.1643 44.4672C18.1201 44.4672 10.9062 39.0063 10.9062 32.2705C10.9062 25.5348 14.9575 19.4977 23.0969 20.0738C29.845 20.5519 33.9146 27.9312 33.9146 32.3134Z"
                stroke="#2D2D2D"
                strokeWidth="0.773197"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.0938 20.0737V21.3179"
                stroke="#2D2D2D"
                strokeWidth="0.773197"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M33.9099 32.6006L32.6719 32.6435"
                stroke="#2D2D2D"
                strokeWidth="0.773197"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.4102 44.4235L22.4224 43.3877"
                stroke="#2D2D2D"
                strokeWidth="0.773197"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.0957 30.9219L12.0886 31.069"
                stroke="#2D2D2D"
                strokeWidth="0.773197"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M23.4776 26.4116C23.4776 26.4116 21.5102 25.0939 20.7686 25.5903C20.027 26.0868 18.0412 29.5619 18.4518 33.558C18.5585 34.5167 18.9115 35.4315 19.4764 36.2134C20.0413 36.9952 20.799 37.6176 21.6757 38.0199H23.5818L23.4776 26.4116Z"
                className="fill-[#AFC272] if:fill-[#F8E1A4]"
                stroke="#2D2D2D"
                strokeWidth="0.773197"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M21.9073 6.0809C21.4047 4.49962 20.1973 1 20.1973 1L21.9073 6.0809Z" fill="#F1EAD0" />
            <path
                d="M21.9073 6.0809C21.4047 4.49962 20.1973 1 20.1973 1"
                stroke="#2D2D2D"
                strokeWidth="0.773197"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M30.6113 5.40023C31.0036 4.38281 31.7942 2.09668 31.7942 2.09668L30.6113 5.40023Z"
                fill="#F1EAD0"
            />
            <path
                d="M30.6113 5.40023C31.0036 4.38281 31.7942 2.09668 31.7942 2.09668"
                stroke="#2D2D2D"
                strokeWidth="0.773197"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.8352 9.15162C13.0813 8.34872 11.3223 6.62646 11.3223 6.62646L13.8352 9.15162Z"
                fill="#F1EAD0"
            />
            <path
                d="M13.8352 9.15162C13.0813 8.34872 11.3223 6.62646 11.3223 6.62646"
                stroke="#2D2D2D"
                strokeWidth="0.773197"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M16.9375 21.3052L17.5504 22.6964L16.9375 21.3052Z" fill="#F1EAD0" />
            <path
                d="M16.9375 21.3052L17.5504 22.6964"
                stroke="#2D2D2D"
                strokeWidth="0.773197"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M12.6113 25.9883C13.042 26.031 13.4529 26.1899 13.8004 26.448L12.6113 25.9883Z" fill="#F1EAD0" />
            <path
                d="M12.6113 25.9883C13.042 26.031 13.4529 26.1899 13.8004 26.448"
                stroke="#2D2D2D"
                strokeWidth="0.773197"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M27.9312 21.9673C27.8405 22.384 27.6788 22.7821 27.4531 23.1441L27.9312 21.9673Z" fill="#F1EAD0" />
            <path
                d="M27.9312 21.9673C27.8405 22.384 27.6788 22.7821 27.4531 23.1441"
                stroke="#2D2D2D"
                strokeWidth="0.773197"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M32.2706 27.1826C31.7598 27.2702 31.2603 27.4141 30.7812 27.6116L32.2706 27.1826Z"
                fill="#F1EAD0"
            />
            <path
                d="M32.2706 27.1826C31.7598 27.2702 31.2603 27.4141 30.7812 27.6116"
                stroke="#2D2D2D"
                strokeWidth="0.773197"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M28.7554 42.7924C28.4734 42.3205 28.1792 41.8486 27.8789 41.3828L28.7554 42.7924Z"
                fill="#F1EAD0"
            />
            <path
                d="M28.7554 42.7924C28.4734 42.3205 28.1792 41.8486 27.8789 41.3828"
                stroke="#2D2D2D"
                strokeWidth="0.773197"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M33.2022 36.9408C32.6155 36.9641 32.0311 36.8551 31.4922 36.6221L33.2022 36.9408Z"
                fill="#F1EAD0"
            />
            <path
                d="M33.2022 36.9408C32.6155 36.9641 32.0311 36.8551 31.4922 36.6221"
                stroke="#2D2D2D"
                strokeWidth="0.773197"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M17.2129 42.229L17.9545 41.4629L17.2129 42.229Z" fill="#F1EAD0" />
            <path
                d="M17.2129 42.229L17.9545 41.4629"
                stroke="#2D2D2D"
                strokeWidth="0.773197"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M12.5059 37.5651C12.7406 37.5773 12.9717 37.5032 13.1555 37.3567L13.8481 36.9277" fill="#F1EAD0" />
            <path
                d="M12.5059 37.5651C12.7406 37.5773 12.9717 37.5032 13.1555 37.3567L13.8481 36.9277"
                stroke="#2D2D2D"
                strokeWidth="0.773197"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

TimerIcon.propTypes = {
    width: PropTypes.number,
};

TimerIcon.defaultProps = {
    width: 60,
};

export default TimerIcon;
