import { useQuery } from '@tanstack/react-query';

import { getMembershipOptions } from 'api/jalapeno';

import useAccountQuery from './useAccountQuery';

export const PLUS_MEMBERSHIP_OPTIONS_QUERY_KEY = 'plus_membership_options';

export const usePlusMembershipOptionsQuery = (enabled = true) => {
    const { data: account } = useAccountQuery();

    const query = useQuery({
        queryKey: [PLUS_MEMBERSHIP_OPTIONS_QUERY_KEY, account?.customerID],
        queryFn: async () => {
            const response = await getMembershipOptions({ customerID: account?.customerID });
            return response.data?.data ?? null;
        },
        enabled: enabled && !!account?.customerID,
    });

    return query;
};
