import { useMediaQuery } from 'react-responsive';

export default function useCarouselSlideWidth({ override, scroll = false }) {
    // custom breakpoints for carousel slides
    const smMobileScreen = useMediaQuery({ maxWidth: '600px' });
    const lgMobileScreen = useMediaQuery({ maxWidth: '700px' });
    const smDesktopScreen = useMediaQuery({ maxWidth: '950px' });
    const mdDesktopScreen = useMediaQuery({ maxWidth: '1110px' });
    const lgDesktopScreen = useMediaQuery({ maxWidth: '1440px' });

    if (override !== 0) return override;

    if (smMobileScreen) return scroll ? 1 : 2.5;
    if (lgMobileScreen) return 4;
    // Bumping card count back down to 3 for the reduced container size because of desktop side nav
    if (smDesktopScreen) return 3;
    if (mdDesktopScreen) return 4;
    if (lgDesktopScreen) return 5;
    return 6;
}
