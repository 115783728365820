import {
    getDeviceId,
    Identify,
    identify as amplitudeIdentify, // eslint-disable-line sort-imports
    setUserId as amplitudeSetUserId,
    track as amplitudeTrack,
} from '@amplitude/analytics-browser';

import logError from 'utils/errorUtils';
import { setUserId as setSentryUserId } from 'utils/sentryUtils';

/**
 * Creates and emits an Amplitude Identify event for given user properties
 * @param {object} userPropertiesAndValues - key/value of user property and value to send to analytics identify
 *
 * @example
 * updateProfile({ firstName: "Tom" });
 *
 * @returns {AmplitudeReturn<Result> | void}
 */
function updateProfile(userPropertiesAndValues) {
    try {
        const identifyEvent = new Identify();
        Object.keys(userPropertiesAndValues).forEach((property) => {
            identifyEvent.set(property, userPropertiesAndValues[property]);
        });
        return amplitudeIdentify(identifyEvent);
    } catch (error) {
        return logError(error);
    }
}

/**
 * Emits analytics tracking for a given event and event properties
 * @param {string} event - the name of the event to track
 * @param {object} eventProperties - optional, additional event properties to track
 *
 * @example
 * trackEvent("order edit", { path: "/account/orders" });
 *
 * @returns {AmplitudeReturn<Result>}
 */
function trackEvent(eventName, eventProperties) {
    if (eventProperties?.brand) {
        updateProfile({ brand: eventProperties.brand });
    }

    if (eventProperties?.email) {
        updateProfile({ email: eventProperties.email });
    }

    if (eventProperties?.variant) {
        updateProfile({ variant: eventProperties.variant });
    }

    if (eventProperties?.status) {
        updateProfile({ status: eventProperties.status });
    }

    if (eventProperties?.orderInterval) {
        updateProfile({ orderInterval: eventProperties.orderInterval });
    }

    if (eventProperties?.membership_status) {
        updateProfile({ membership_status: eventProperties.membership_status });
    }

    return amplitudeTrack(eventName, eventProperties);
}

/**
 * Emits analytics tracking for a given page, with optional (strongly suggested!) title
 * @param {object} pageData - this always includes the full location object of the current URL (React Router Location). It can also include additional page data.
 * @param {string} title - optional, the page title to log with the analytics event
 *
 * @example
 * trackPage({ ...location, additionalPageOption: true }, "foo");
 *
 * @returns {AmplitudeReturn<Result>}
 */
function trackPage(pageData, title) {
    const eventTitle = title ? `${title.toLowerCase()} page view` : 'page view';

    // Note: this is temporary and in support of debugging CUS-4740
    if (window.location.pathname?.startsWith('/join') && pageData) {
        // eslint-disable-next-line no-param-reassign
        pageData.signup_local_storage = localStorage.getItem('signup') || 'no signup local storage';
    }

    // TODO: abstract dynamic properties from URL to event props (eg shop/aisle/:category/:subcategory => /shop/aisle/fruit/all => { category: fruit, subcategory: all })
    return amplitudeTrack(eventTitle, pageData);
}

/**
 * Extracts and formats analytics profile information
 * @param {object} { account, subscription } - account and subscription data as fetched from BE queries
 * @returns {object} – formatted object of profile fields to send for analytics identification/update
 */
export function getDefaultProfileFields({ account, subscription }) {
    return {
        brand: account?.brand,
        customerId: account?.customerID,
        email: subscription?.email,
        firstName: subscription?.shippingAddr?.firstName,
        lastName: subscription?.shippingAddr?.lastName,
        phone: subscription?.shippingAddr?.phone,
        address: {
            address1: subscription?.shippingAddr?.address1,
            address2: subscription?.shippingAddr?.address2,
            city: subscription?.shippingAddr?.city,
            province: subscription?.shippingAddr?.province,
            zip: subscription?.shippingAddr?.zip,
            country: subscription?.shippingAddr?.country,
        },
        subscriptionId: subscription?.id,
        signupDate: subscription?.createdAt?.date, // NOTE: this seems to be UTC
        marketOpen: account?.marketOpenDate?.date, // NOTE: timezone not sent
        marketClose: account?.marketCloseDate?.date, // NOTE: timezone not sent
        marketplaceOpen: account?.marketplaceOpen,
        carrier: account?.nextOrder?.carrier,
        orderInterval: subscription?.orderUnitInterval === 1 ? 'weekly' : 'biweekly',
        status: subscription?.status,
        variant: subscription?.variant,
        // NOTE: will need to change logic for "stage" if we ever emit this for anonymous users
        stage: account?.numOrders > 0 ? 'customer' : 'member',
        // fc: // TODO
        membership_status: subscription?.membership_status,
        free_trial_eligible: subscription?.free_trial_eligible,
        free_trial_start_date: subscription?.free_trial_start_date,
        free_trial_end_date: subscription?.free_trial_end_date,
        membership_start_date: subscription?.membership_start_date,
        membership_end_date: subscription?.membership_end_date,
    };
}

/**
 * Emits analytics identification for speciic given user properties, only intended to be fired once, on init
 * @param {object} userProperties - account and subscription as fetched from backend
 *
 * @example
 * trackPage({ account, subscrtiption });
 *
 * @returns {AmplitudeReturn<Result> | void}
 */
function identify(userProperties) {
    try {
        const { account, subscription } = userProperties;

        // use our userID for amplitude userID
        amplitudeSetUserId(account?.customerID);

        const defaultFields = getDefaultProfileFields({ account, subscription });

        return updateProfile(defaultFields);
    } catch (error) {
        return logError(error);
    }
}

function setUserId(userId) {
    amplitudeSetUserId(userId);
    setSentryUserId(userId);
}

function getCrossDomainDeviceId() {
    return getDeviceId();
}

/**
 * Track clicks on Acquisition PDP CTAs.
 * @param {Object} options - named arguments
 * @param {string} options.location - The location of the CTA on the page. For example, 'navbar' or 'page' (default)
 * @param {string} options.productBrand - The product's brand. For example: 'Odds & Ends'
 * @param {string} options.productName - The product's name. For example: 'Organic Syrup'
 */
const trackAcquisitionPDPCTAClick = ({ location = 'page', productBrand = '', productName = '' }) => {
    trackEvent('ungated product cta click', {
        brand: 'misfits_market', // these are only MM PDPs
        location,
        path: window.location.pathname,
        product_name: productName,
        product_brand: productBrand,
    });
};

/**
 * Track aisle navigation.
 * @param {Object} options - named arguments
 * @param {string} options.aisleName - The aisle or subaisle name. For example: 'Pantry' or 'Breakfast Foods'
 * @param {number} options.level - The aisle's level - 1 or 2.
 * @param {Boolean} options.mobile - Whether or not the navigation was on a mobile-sized screen.
 * @param {string} options.uiElement - A short description of the element that was clicked in the UI. For example: 'Desktop vertical navigation'.
 */
const trackAisleNavigation = ({ aisleName, level, mobile, uiElement }) => {
    trackEvent('aisle navigation', {
        destination: aisleName,
        isNative: window.isNativeApp ?? false,
        level,
        mobile,
        path: window.location.pathname,
        query_params: window.location.search,
        ui_element: uiElement,
    });
};

// Requires GTM to be initialized separately!
class GTMPlugin {
    name = 'google-tag-manager';

    type = 'destination';

    // eslint-disable-next-line class-methods-use-this
    async execute(event) {
        window.dataLayer = window.dataLayer || [];
        if (event.event_type === '$identify') {
            const { user_id: customerId, user_properties: userProperties } = event;
            window.dataLayer.push({ customer_id: customerId });
            // NOTE: currently only works for Identify.set properties
            if (userProperties.$set) {
                window.dataLayer.push(userProperties.$set);
            }
        } else {
            const { event_properties: eventProperties, event_type: eventType } = event;
            window.dataLayer.push({ event: eventType, eventData: eventProperties });
        }

        return {
            code: 200,
            event,
            message: 'Event pushed onto GTM Data Layer',
        };
    }
}

/**
 * Track `existing user redirect` event
 * @param {object} args - named arguments
 * @param {string} args.destination
 */
const trackExistingUserRedirect = ({ destination }) => {
    trackEvent('existing user redirect', {
        destination,
        pathname: window.location.pathname,
    });
};

export {
    getCrossDomainDeviceId,
    identify,
    trackAcquisitionPDPCTAClick,
    trackAisleNavigation,
    setUserId,
    trackEvent,
    trackPage,
    updateProfile,
    GTMPlugin,
    trackExistingUserRedirect,
};
