import PropTypes from 'prop-types';

import useAccountQuery from 'api/queries/useAccountQuery';
import { CARRIER_SHIP_FEE_REDUCTION } from 'constants/plusMembership';
import { TRUCK_ICON_URL } from 'honeydew/icons/PlusMembership';
import { getResizedCDNAsset } from 'utils/globalUtils';

import BenefitBase from './shared/BenefitBase';

function ShippingBenefit({ bgColor, layout }) {
    const { data: accountData = {} } = useAccountQuery();

    const headline = accountData?.isDeliveredByImperfect ? "Shipping's on us" : 'Save on shipping';
    const subheadline = accountData?.isDeliveredByImperfect
        ? 'Get free shipping on every order'
        : `Get $${CARRIER_SHIP_FEE_REDUCTION} off shipping with every order`;

    const horizontalImage = (
        <img src={getResizedCDNAsset({ url: TRUCK_ICON_URL, width: 320 })} width={160} alt="ribbon with coin" />
    );

    const verticalImage = <img src={TRUCK_ICON_URL} width="200" className="mt-auto" alt="delivery truck" />;

    return (
        <BenefitBase
            bgColor={bgColor}
            headline={headline}
            subheadline={subheadline}
            verticalImage={verticalImage}
            horizontalImage={horizontalImage}
            horizontalImagePosition="bottom-[-20px] left-0 right-[-40px] top-[-20px]"
            layout={layout}
        />
    );
}

ShippingBenefit.propTypes = {
    bgColor: PropTypes.string,
    layout: PropTypes.oneOf(['horizontal', 'responsive']),
};

ShippingBenefit.defaultProps = {
    bgColor: 'bg-white',
    layout: 'responsive',
};

export default ShippingBenefit;
