import { getSignupLocalStorage, setSignupLocalStorage } from 'utils/signupUtils';

/**
 * Persist a promo code to local storage.
 * Anytime we apply a promo code, we sync both storage locations.
 * Note: incoming promo codes from the CMS are only stored in APPLIED_PROMO_CODE local storage.
 * @param {String|null} appliedPromoCode
 */
export const persistAppliedPromoCode = (appliedPromoCode) => {
    setSignupLocalStorage({ discountCode: appliedPromoCode });

    if (appliedPromoCode) {
        localStorage.setItem('APPLIED_PROMO_CODE', appliedPromoCode);
    } else {
        localStorage.removeItem('APPLIED_PROMO_CODE');
    }
};

/**
 * Looks for promo codes in the following order:
 * 1. ?promo= query param
 * 2. APPLIED_PROMO_CODE local storage
 * 3. discountCode in signup local storage
 * Note: Optimizely can also apply a promo on any page. See useApplyOptimizelyPromo.js
 * This also dedupes promo codes and returns an array of unique promo codes to validate with checkDiscountCode().
 * @returns {Array<String>}
 */
export const getPrioritizedPromoCodes = () => {
    const promoParam = new URLSearchParams(window.location.search)?.get('promo');
    const appliedPromoCode = localStorage.getItem('APPLIED_PROMO_CODE');
    const signupDiscountCode = getSignupLocalStorage()?.discountCode;

    const prioritizedPromoCodes = [];

    if (promoParam) {
        prioritizedPromoCodes.push(promoParam);
    }

    if (appliedPromoCode && !prioritizedPromoCodes.includes(appliedPromoCode)) {
        prioritizedPromoCodes.push(appliedPromoCode);
    }

    if (signupDiscountCode && !prioritizedPromoCodes.includes(signupDiscountCode)) {
        prioritizedPromoCodes.push(signupDiscountCode);
    }

    return prioritizedPromoCodes;
};

/**
 * Returns whether or not a referral is boosted, given the credits rewarded to the referrer and referee.
 *
 * @param {number} referralRewards.newCustValue - The credits rewarded to the referee.
 * @param {number} referralRewards.referrerValue - The credits rewarded to the referrer.
 * @returns {boolean} Whether or not a referral is boosted.
 */
// TODO: [CUS-5759] replace this once we have a valid boosted referrals campaign
// export const getIsBoostedReferral = ({ newCustValue, referrerValue }) => newCustValue > 10 || referrerValue > 10;
export const getIsBoostedReferral = () => false;
