import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import Button from 'honeydew/Button';
import AutoFilledIcon from 'honeydew/icons/FoodPreferences/AutoFilledIcon';
import RecurringIcon from 'honeydew/icons/FoodPreferences/RecurringIcon';
import QuantityButton from 'honeydew/QuantityButton';
import Tag from 'honeydew/Tag';
import useGlobalStore from 'hooks/useGlobalStore';
import useProductSelectionData from 'hooks/useProductSelectionData';
import useTailwindTheme from 'hooks/useTailwindTheme';
import useUpdateProductQty from 'hooks/useUpdateProductQty';
import { getProductPrice, PriceType } from 'utils/priceUtils';
import { getProductOriginalPrice, getShowOriginalPrice } from 'utils/productUtils';
import { addProduct } from 'utils/removedFromCartStorageUtils';

import { getPlusMembershipPreviewPrice } from './utils';

function CartProductCard({
    dsSource,
    inPlusMembershipTreatment,
    onClick,
    plusMembershipEligibleOrder,
    plusMembershipShortBranding,
    position,
    product,
    upSellsItem,
    usePlusMemberPrice,
}) {
    const { setCartOpen } = useGlobalStore();
    const tailwindTheme = useTailwindTheme();
    const mobile = useMediaQuery({ maxWidth: tailwindTheme?.screens?.md });

    const {
        decrementProductQty,
        incrementProductQty,
        isLoading: updateProductQtyLoading,
    } = useUpdateProductQty({
        dsSource,
        productID: product.id,
        productName: product.name,
        cart: !upSellsItem,
        upSellsSource: upSellsItem,
        plusMemberPriceEligible: product.plusMemberPriceEligible,
    });
    const { data: productSelectionData = {} } = useProductSelectionData({ productID: product.id });

    const { qty: productQty = 0 } = productSelectionData;

    const productIsRecurring = product.source === 'recurring';
    const productIsAutomatic = product.source === 'personalization' || product.source === 'autopilot_risk';

    const handleAddClick = (e) => {
        e.stopPropagation();
        incrementProductQty(upSellsItem ?? 'cart');
    };

    const handleRemoveClick = (e) => {
        e.stopPropagation();
        decrementProductQty(upSellsItem ?? 'cart');

        if (product.qty <= 1 && productIsAutomatic) {
            addProduct(product);
        }
    };

    const handleEditClick = (e) => {
        e.stopPropagation();
        if (window.isNativeApp) {
            window.ReactNativeWebView?.postMessage(JSON.stringify({ navigate: 'Perks' }));
        } else {
            setCartOpen(false);
        }
    };
    const showPlusMemberPrice =
        inPlusMembershipTreatment &&
        usePlusMemberPrice &&
        product.plusMemberPriceEligible &&
        plusMembershipEligibleOrder;

    const showMembershipPreviewPrice =
        inPlusMembershipTreatment && product.plusMemberPriceEligible && !plusMembershipEligibleOrder;

    const displayProductQuantity = productQty > 0 ? productQty : 1;

    const priceType = plusMembershipEligibleOrder ? PriceType.PLUS_MEMBER : PriceType.DEFAULT;

    const productPrice =
        (inPlusMembershipTreatment
            ? getProductPrice({
                  options: { priceType },
                  product,
              })
            : product.price) * displayProductQuantity;

    const productOriginalPrice = getProductOriginalPrice(product, showPlusMemberPrice) * displayProductQuantity;
    const showOriginalPrice = getShowOriginalPrice(product, 0, showPlusMemberPrice);

    return (
        <div className="flex items-start gap-20" onClick={onClick} onKeyDown={onClick} role="button" tabIndex={0}>
            <img
                className={clsx('h-80 w-80 rounded-lg', {
                    'h-[105px] w-[105px]': upSellsItem && !mobile,
                })}
                alt={product.name}
                src={product.photoSmall}
            />
            <div
                className={clsx('absolute flex h-[26px] w-[26px] items-center justify-center rounded-full', {
                    'bg-[#CFE5BD] if:bg-strawberry': productIsAutomatic,
                    'bg-[#FAEAC0]': productIsRecurring,
                })}
            >
                {productIsRecurring && <RecurringIcon width={18} />}
                {productIsAutomatic && <AutoFilledIcon width={18} brandColors />}
            </div>
            <div
                className={clsx('flex grow justify-between gap-20 align-top', {
                    'py-5 pr-5': upSellsItem && !mobile,
                })}
            >
                <div className="flex flex-col">
                    {product.isPerk && (
                        <p className="text-body-xs text-brand-primary font-grotesk-bold uppercase">Perk</p>
                    )}
                    {product.brand && (
                        <p
                            className={clsx('text-body-sm text-grey', {
                                'line-clamp-1': position === 'sheet',
                            })}
                        >
                            {product.brand}
                        </p>
                    )}
                    <p
                        className={clsx('text-body-sm', {
                            'mb-10': !product.isPerk,
                            'mb-5': upSellsItem && !mobile,
                            'line-clamp-2': position !== 'sheet',
                            'line-clamp-1': position === 'sheet',
                        })}
                    >
                        {product.name}
                    </p>
                    {showMembershipPreviewPrice && (
                        <p className="text-body-sm mb-10 text-cranberry">
                            ${getPlusMembershipPreviewPrice(product)} with{' '}
                            <Tag bgColor="cranberry" variant="dark">
                                {plusMembershipShortBranding}
                            </Tag>
                        </p>
                    )}
                    {product.isPerk ? (
                        <div className="flex items-center">
                            <p className="text-body-sm mr-10 font-grotesk-bold">Qty: {product.qty}</p>
                            <Button
                                {...(!window.isNativeApp && { as: 'link' })}
                                className="text-brand-primary text-body-sm mt-[3px] font-grotesk-bold underline"
                                to="/shop/browse/misfits+perks/redeemed"
                                onClick={handleEditClick}
                            >
                                Edit
                            </Button>
                        </div>
                    ) : (
                        <QuantityButton
                            disabled={updateProductQtyLoading}
                            maxQuantity={product.soldOut ? productQty : product.maxQuantity}
                            onAdd={handleAddClick}
                            onRemove={handleRemoveClick}
                            quantity={productQty}
                            variant="secondary"
                            fullWidth={false}
                        />
                    )}
                </div>
                <div className="flex flex-col items-end">
                    {product.isPerk ? (
                        <>
                            <p className="text-body-sm font-grotesk-bold">$0.00</p>
                            <p className="text-body-sm text-brand-primary uppercase">Free</p>
                        </>
                    ) : (
                        <>
                            <div className="flex items-baseline gap-5">
                                {showPlusMemberPrice && (
                                    <Tag bgColor="cranberry" variant="dark">
                                        {plusMembershipShortBranding}
                                    </Tag>
                                )}
                                <p
                                    className={clsx('text-body-sm font-grotesk-bold', {
                                        'text-cranberry': showPlusMemberPrice,
                                    })}
                                >
                                    ${productPrice.toFixed(2)}
                                </p>
                            </div>{' '}
                            {showOriginalPrice && (
                                <p className="text-body-sm text-grey line-through">
                                    ${productOriginalPrice.toFixed(2)}
                                </p>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default CartProductCard;

CartProductCard.propTypes = {
    dsSource: PropTypes.string,
    inPlusMembershipTreatment: PropTypes.bool,
    plusMembershipEligibleOrder: PropTypes.bool,
    plusMembershipShortBranding: PropTypes.string,
    position: PropTypes.string,
    product: PropTypes.shape({
        brand: PropTypes.string,
        bundleID: PropTypes.number,
        id: PropTypes.number,
        msrp: PropTypes.number,
        name: PropTypes.string,
        photoSmall: PropTypes.string,
        portion: PropTypes.number,
        price: PropTypes.number,
        qty: PropTypes.number,
        source: PropTypes.string,
    }).isRequired,
    upSellsItem: PropTypes.string,
    usePlusMemberPrice: PropTypes.bool,
};

CartProductCard.defaultProps = {
    dsSource: '',
    inPlusMembershipTreatment: false,
    plusMembershipEligibleOrder: false,
    plusMembershipShortBranding: undefined,
    position: '',
    upSellsItem: null,
    usePlusMemberPrice: false,
};
