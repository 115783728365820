export const REMOVED_FROM_CART_LOCAL_STORAGE_KEY = 'removed_from_cart_local_storage';
const MAX_ITEM_COUNT = 5;

function getProductListObjects() {
    const items = JSON.parse(localStorage.getItem(REMOVED_FROM_CART_LOCAL_STORAGE_KEY)) ?? [];
    const currentTime = new Date().getTime();

    return items.filter((item) => item.ttl > currentTime);
}

export function getProductList() {
    return getProductListObjects().map((item) => item.product);
}

export function addProduct(newProduct) {
    let items = getProductListObjects();
    const numberOfMillisecondsIn15Minutes = 1000 * 60 * 15;
    const ttl = new Date().getTime() + numberOfMillisecondsIn15Minutes;
    items = items.concat({
        product: newProduct,
        ttl,
    });

    if (items.length > MAX_ITEM_COUNT) {
        items = items.slice(items.length - MAX_ITEM_COUNT);
    }

    localStorage.setItem(REMOVED_FROM_CART_LOCAL_STORAGE_KEY, JSON.stringify(items));
}

export function removeProductById(id) {
    const items = getProductListObjects().filter((productObject) => productObject.product.id !== id);

    localStorage.setItem(REMOVED_FROM_CART_LOCAL_STORAGE_KEY, JSON.stringify(items));
}
