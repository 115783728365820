import { useEffect, useState } from 'react';
import { Bars3Icon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { Link, NavLink, useMatch } from 'react-router-dom';

import CartButton from 'features/shop/cart/CartButton/CartButton';
import { MobileNavigationModal } from 'features/site/header/navigation/MainNavigation/MobileNavigationModal/MobileNavigationModal';
import { SearchAutocomplete } from 'features/site/header/navigation/MainNavigation/Search/SearchAutocomplete';
import { SearchInput } from 'features/site/header/navigation/MainNavigation/Search/SearchInput';
import { SignInLink } from 'features/site/header/navigation/MainNavigation/Search/SignInLink';
import Button from 'honeydew/Button';
import { Dropdown } from 'honeydew/Dropdown';
import ImperfectFoodsLogo from 'honeydew/icons/Logos/ImperfectFoodsLogo';
import useAuth from 'hooks/useAuth';
import useGlobalStore from 'hooks/useGlobalStore';
import useInactiveSubscription from 'hooks/useInactiveSubscription';
import useTailwindTheme from 'hooks/useTailwindTheme';
import useTrackAcquisitionPDPCTAClick from 'pages/AcquisitionPDP/useTrackAcquisitionPDPCTAClick';
import AccountLinks from 'shared/AccountLinks';
import { trackEvent } from 'utils/analyticsUtils';
import { formatDate, getDay, localizeTimeZone } from 'utils/dateUtils';
import { disableBodyScroll, enableBodyScroll } from 'utils/globalUtils';
import { getNextActiveCharge } from 'utils/orderUtils';

const linkBaseClasses = 'font-grotesk-bold text-body-mobile-md hover:bg-off-white rounded-full px-10 py-5';

export function MainNavigation({ account, isAcquisitionPDPRoute, isShopRoute, subscription }) {
    const { authStatus } = useAuth();
    const tailwindTheme = useTailwindTheme();
    const mobile = useMediaQuery({ maxWidth: tailwindTheme?.screens?.md });
    const isWidthUnder800 = useMediaQuery({ maxWidth: '800px' });
    const isWidthUnder910 = useMediaQuery({ maxWidth: '910px' });
    const isWidthUnder1040 = useMediaQuery({ maxWidth: '1040px' });
    const { isImperfectTheme, isMisfitsTheme, searchOpen, setSearchOpen, setSearchQuery } = useGlobalStore();
    const inactiveSubscription = useInactiveSubscription();
    const isSignupRoute = useMatch('/join/*');
    const isJoinPage = useMatch('/join');
    const isPDPRoute = !!useMatch('/shop/product/:id');
    const trackAcquisitionPDPCTAClick = useTrackAcquisitionPDPCTAClick({ location: 'navbar' });

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const isLoggedIn = authStatus === 'authenticated';
    const { marketOpenDate, marketplaceOpen } = account;
    const { firstName } = subscription?.shippingAddr ?? {};
    const signupMobileHeader = mobile && isSignupRoute;

    const toggleMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
        if (mobileMenuOpen) {
            enableBodyScroll(true);
        } else {
            disableBodyScroll(true);
            trackEvent('nav click');
        }
    };

    const toggleSearch = () => {
        setSearchOpen(!searchOpen);
    };

    const getShopLink = () => {
        if (inactiveSubscription) return null;

        const nextActiveCharge = getNextActiveCharge(subscription?.charges);

        // pull marketplace open date and next active charge date
        // if they are not the same then next order is skipped/donated
        // default to marketplace closed view (hide shop link)
        const nextActiveChargeDate = nextActiveCharge?.marketOpen?.date?.split(' ')[0];
        const marketplaceOpenDate = account?.marketOpenDate?.date?.split(' ')[0];

        const nextMarketplaceOpenDate = nextActiveCharge?.marketOpen?.date ?? marketOpenDate?.date;

        if (marketplaceOpen && nextActiveChargeDate === marketplaceOpenDate) {
            return (
                <NavLink to="shop" className={linkBaseClasses}>
                    Shop
                </NavLink>
            );
        }

        return (
            <span className="rounded-full bg-off-white px-15 py-[4px] text-body-mobile-sm">
                Shop Opens {getDay(nextMarketplaceOpenDate, true)} {formatDate(nextMarketplaceOpenDate)} at{' '}
                {localizeTimeZone(account?.marketOpenDate?.date)}
            </span>
        );
    };

    const getSearchElements = () =>
        isShopRoute &&
        !isPDPRoute &&
        account?.marketStatus === 'Open' && (
            <>
                <SearchAutocomplete isShopRoute={isShopRoute} />
                <SearchInput />
            </>
        );

    useEffect(() => {
        if (!mobile && mobileMenuOpen) {
            setMobileMenuOpen(false);
            enableBodyScroll(true);
        }
    }, [mobile]);

    useEffect(() => {
        if (!isShopRoute) setSearchQuery('');
    }, [isShopRoute]);

    const whiteBackground = !isSignupRoute || (isSignupRoute && !isJoinPage && !mobile);

    if (window.isNativeApp) return getSearchElements();

    return (
        <>
            <header
                className={clsx({
                    'bg-white': whiteBackground,
                    'bg-malt-off-white': !whiteBackground,
                    'border-b border-off-white': !mobile,
                })}
            >
                <div
                    className={clsx('flex items-center gap-15 px-20 py-15', {
                        'grid h-50 grid-cols-3': mobile,
                        'h-[62px] p-10': signupMobileHeader || (mobile && !isShopRoute),
                        'h-80': !mobile,
                        'px-30': isWidthUnder1040 && !isWidthUnder910,
                        'px-40': !isWidthUnder1040,
                    })}
                >
                    {mobile && !isSignupRoute && (
                        <button type="button" onClick={toggleMenu} className="z-10 w-30 bg-white">
                            {mobileMenuOpen ? <XMarkIcon className="h-30 w-30" /> : <Bars3Icon className="h-30 w-30" />}
                        </button>
                    )}

                    <Link
                        to={isLoggedIn ? '/account' : '/'}
                        className={clsx('justify-self-center', {
                            'min-w-max': !mobile,
                            'w-[180px]': mobile && isMisfitsTheme,
                            'w-[110px]': mobile && isImperfectTheme,
                            'col-start-2': mobile && isSignupRoute,
                        })}
                        reloadDocument={!isLoggedIn}
                    >
                        {isImperfectTheme ? (
                            <div className="justify-self-center">
                                <ImperfectFoodsLogo width={mobile ? 70 : 110} />
                            </div>
                        ) : (
                            <img
                                src="https://img-cdn.misfitsmarket.com/images/MM_nav-logo-desktop.svg"
                                alt="Misfits Market logo"
                            />
                        )}
                    </Link>

                    {mobile && isShopRoute && !mobileMenuOpen && (
                        <button type="button" onClick={toggleSearch} className="z-10 w-30 justify-self-end bg-white">
                            <MagnifyingGlassIcon width={20} />
                        </button>
                    )}

                    {!mobile && !isSignupRoute && (
                        <div className="ml-auto">
                            {isLoggedIn ? (
                                <div className="flex items-center justify-between gap-10">
                                    {!isWidthUnder800 && getSearchElements()}
                                    {((isShopRoute && !isWidthUnder910) || (!isShopRoute && !mobile)) && (
                                        <>
                                            {getShopLink()}
                                            <NavLink
                                                to="account/orders"
                                                className={({ isActive }) =>
                                                    clsx(
                                                        { 'text-brand-primary': isActive },
                                                        { 'border-white': !isActive },
                                                        linkBaseClasses
                                                    )
                                                }
                                            >
                                                Orders
                                            </NavLink>
                                        </>
                                    )}
                                    <div id="desktop-navigation-dropdown-menu" className="z-10 text-body-mobile-md">
                                        <Dropdown
                                            triggerName={`${
                                                firstName && !isWidthUnder1040 ? `${firstName}'s ` : ''
                                            }Account`}
                                            linkPath="/account"
                                        >
                                            <AccountLinks dropdown />
                                        </Dropdown>
                                    </div>
                                    {marketplaceOpen && isShopRoute && <CartButton />}
                                </div>
                            ) : (
                                <>
                                    {/* LOGGED OUT */}
                                    <div className="flex items-center gap-50">
                                        {isMisfitsTheme && (
                                            <div className="mt-[7px]">
                                                <Dropdown triggerName="About Us">
                                                    <ul className="space-y-10 p-25">
                                                        <li>
                                                            <a
                                                                className="font-grotesk-bold hover:underline"
                                                                href="/about-us"
                                                            >
                                                                About Us
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                className="font-grotesk-bold hover:underline"
                                                                href="/how-it-works"
                                                            >
                                                                How It Works
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                className="font-grotesk-bold hover:underline"
                                                                href="/misfits-perks"
                                                            >
                                                                Perks Program
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                className="font-grotesk-bold hover:underline"
                                                                href="/whats-in-your-box"
                                                            >
                                                                Our Food
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                className="font-grotesk-bold hover:underline"
                                                                href="/sourcing"
                                                            >
                                                                Our Sourcing
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                className="font-grotesk-bold hover:underline"
                                                                href="/packaging"
                                                            >
                                                                Packaging Return
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                className="font-grotesk-bold hover:underline"
                                                                href="https://blog.misfitsmarket.com/"
                                                            >
                                                                Our Blog
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                className="font-grotesk-bold hover:underline"
                                                                href="/faq"
                                                            >
                                                                FAQs
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </Dropdown>
                                            </div>
                                        )}
                                        <SignInLink />
                                        <div className="shrink-0">
                                            <Button
                                                as="a"
                                                to="/join"
                                                onClick={() => {
                                                    if (isAcquisitionPDPRoute) {
                                                        trackAcquisitionPDPCTAClick();
                                                    }
                                                }}
                                            >
                                                <span className="px-40">Get started</span>
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>
                {mobile && searchOpen && getSearchElements()}
            </header>
            {mobile && mobileMenuOpen && !isSignupRoute && (
                <MobileNavigationModal
                    account={account}
                    toggleMenu={toggleMenu}
                    inactiveSubscription={inactiveSubscription}
                    isAcquisitionPDPRoute={isAcquisitionPDPRoute}
                    isLoggedIn={isLoggedIn}
                />
            )}
        </>
    );
}

MainNavigation.propTypes = {
    account: PropTypes.shape({
        marketOpenDate: PropTypes.oneOfType([
            PropTypes.shape({
                date: PropTypes.string,
            }),
            PropTypes.string,
        ]),
        marketplaceOpen: PropTypes.bool,
    }),
    isAcquisitionPDPRoute: PropTypes.bool,
    isShopRoute: PropTypes.bool,
    subscription: PropTypes.shape({
        shippingAddr: PropTypes.shape({
            firstName: PropTypes.string,
        }),
    }),
};

MainNavigation.defaultProps = {
    account: {},
    isAcquisitionPDPRoute: false,
    isShopRoute: false,
    subscription: {},
};
