import PropTypes from 'prop-types';

import IFFallFlavorsActive from './IFFallFlavorsActive';
import IFFallFlavorsInactive from './IFFallFlavorsInactive';

function IFFallFlavors({ className, isActive, width }) {
    return isActive ? (
        <IFFallFlavorsActive className={className} width={width} />
    ) : (
        <IFFallFlavorsInactive className={className} width={width} />
    );
}

export default IFFallFlavors;

IFFallFlavors.propTypes = {
    className: PropTypes.string,
    isActive: PropTypes.bool,
    width: PropTypes.number,
};

IFFallFlavors.defaultProps = {
    className: '',
    isActive: true,
    width: 24,
};
