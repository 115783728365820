import { useMemo, useState } from 'react';
import { InformationCircleIcon as InformationCircleIconOutline } from '@heroicons/react/24/outline';
import { BanknotesIcon, CurrencyDollarIcon, InformationCircleIcon, StarIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { CARRIER_SHIP_FEE_REDUCTION } from 'constants/plusMembership';
import { ALCOHOL_SERVICE_FEE, MIN_WINE_BOTTLE_QTY_TO_SHIP } from 'constants/shop';
import CartDiscountCodeForm from 'features/shop/cart/CartDiscountCodeForm';
import { PlusMembershipMemberBanner, PlusMembershipProspectBanner } from 'features/shop/cart/plusMembershipBanners';
import { TaxAndFeesModal } from 'features/shop/cart/TaxAndFeesModal';
import Button from 'honeydew/Button/Button';
import Tag from 'honeydew/Tag';
import DriverTipsInfoModal from 'shared/driver-tips/DriverTipsInfoModal';
import EditDriverTipModal from 'shared/driver-tips/EditDriverTipModal';
import {
    filterPerkSelections,
    getColdPackItems,
    getGroceryItems,
    getShowOperationsFee,
    getTotalWineBottleQty,
} from 'utils/orderUtils';
import {
    getCartAlcoholDiscount,
    getCartAlcoholServiceFee,
    getCartAppliedCredits,
    getCartAppliedDiscounts,
    getCartEstimatedTax,
    getCartEstimatedTaxAndFees,
    getCartNonMemberSavings,
    getCartShippingFee,
    getCartSubtotal,
    getCartTotal,
    getFreeShippingThresholdMet,
    getGroceryMinMet,
    getUnmetReferralDiscount,
    getWaiveColdPackFee,
    PriceType,
} from 'utils/priceUtils';

function CartPricesPlusMembership({
    account,
    coldPackSectionRef,
    credits,
    discounts,
    plusMembershipEligibleOrder,
    plusMembershipShippingFee,
    plusMembershipShortBranding,
    plusMembershipStaticBannerRef,
    selections,
    wineOrderCardRef,
}) {
    const [driverTipsModalOpen, setDriverTipsModalOpen] = useState(false);
    const [editDriverTipsModalOpen, setEditDriverTipsModalOpen] = useState(false);
    const [taxAndFeesModalOpen, setTaxAndFeesModalOpen] = useState(false);

    const { loyaltyEligible, preferredTipVal, shippingFee: baseShippingFee } = account;
    const { chargeID } = account?.nextOrder ?? {};
    const { coldKeeperFee, operationsFee = 0 } = account?.nextOrder?.cartExperience ?? {};
    const { featureCanAddDriverTips } = account?.featureFlags ?? {};
    const { sumCreditBalance } = credits;
    const { alcoholDiscountPercentage } = selections;

    const filteredSelections = filterPerkSelections(selections);
    const groceryItems = getGroceryItems(selections);
    const coldPackItems = getColdPackItems(filteredSelections);
    const totalWineBottleQty = getTotalWineBottleQty(filteredSelections);

    const { chargeTipAmount, estimatedLoyaltyPoints } = filteredSelections;
    const displayOneTimeOrderTip = chargeTipAmount !== preferredTipVal;
    const orderTip = displayOneTimeOrderTip ? chargeTipAmount : preferredTipVal;
    const tipAdded = orderTip > 0;

    const generateTipCopy = () => {
        if (displayOneTimeOrderTip && preferredTipVal === 0) return 'One-Time';

        if (displayOneTimeOrderTip && tipAdded) return 'Updated';

        if (!displayOneTimeOrderTip && tipAdded) return 'Default';

        return '';
    };

    const priceType = plusMembershipEligibleOrder ? PriceType.PLUS_MEMBER : PriceType.DEFAULT;

    const subtotal = getCartSubtotal({
        account,
        options: { priceType },
        selections,
    });

    const estimatedTax = getCartEstimatedTax({
        account,
        options: { priceType },
        selections,
    });

    const estimatedTaxAndFees = getCartEstimatedTaxAndFees({
        account,
        options: { priceType },
        selections,
    });

    const waiveColdPackFee = getWaiveColdPackFee({
        account,
        options: { priceType },
        selections,
    });

    const groceryMinMet = getGroceryMinMet({
        account,
        options: { priceType },
        selections,
    });

    const freeShippingThresholdMet = getFreeShippingThresholdMet({
        account,
        options: { priceType },
        selections,
    });

    const cartShippingFee = getCartShippingFee({
        account,
        options: { priceType },
        plusMembership: { plusMembershipShippingFee },
        selections,
    });

    const { appliedDiscounts } = getCartAppliedDiscounts({
        account,
        discounts,
        options: { priceType },
        selections,
    });

    const cartAppliedCredits = getCartAppliedCredits({
        account,
        credits,
        discounts,
        options: { priceType },
        plusMembership: { plusMembershipShippingFee },
        selections,
    });

    const total = getCartTotal({
        account,
        credits,
        discounts,
        options: { priceType },
        plusMembership: { plusMembershipShippingFee },
        selections,
    });

    const alcoholServiceFee = getCartAlcoholServiceFee({ selections });
    const alcoholDiscount = getCartAlcoholDiscount({ selections });
    const cartNonMemberSavings = getCartNonMemberSavings({ account, selections });

    const unmetReferralDiscount = getUnmetReferralDiscount({
        account,
        discounts,
        options: { priceType },
        selections,
    });

    const freeShippingWithMembership =
        plusMembershipEligibleOrder && !freeShippingThresholdMet && plusMembershipShippingFee === 0;

    const shippingDiscountWithMembership =
        plusMembershipEligibleOrder &&
        !freeShippingThresholdMet &&
        plusMembershipShippingFee > 0 &&
        plusMembershipShippingFee < baseShippingFee;

    const handleClickWaiveColdPackFee = () => {
        coldPackSectionRef?.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleClickWaveAlcoholFee = () => {
        wineOrderCardRef?.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const showOperationsFee = getShowOperationsFee({ cartExperience: account?.nextOrder?.cartExperience });

    const hasPlusMemberSelection = useMemo(
        () => selections?.selected?.some((selection) => selection.plusMemberPriceEligible),
        [selections]
    );

    return (
        <div className="mb-10 bg-white px-15 py-10">
            <CartDiscountCodeForm />
            <div className="mt-10 flex justify-between" data-testid="subtotal">
                <p className="text-body-sm">Subtotal</p>
                <p className="text-body-sm">${subtotal.toFixed(2)}</p>
            </div>
            {groceryItems.length > 0 && groceryMinMet && (
                <div className="mt-5 flex items-baseline justify-between" data-testid="shipping-fee">
                    <div>
                        <p className="text-body-sm">Shipping</p>
                        {!plusMembershipEligibleOrder && !freeShippingThresholdMet && hasPlusMemberSelection && (
                            <p className="text-body-sm text-cranberry">
                                {account?.isDeliveredByImperfect
                                    ? 'Never pay shipping with'
                                    : `Get $${CARRIER_SHIP_FEE_REDUCTION} off shipping per order with`}{' '}
                                <Tag bgColor="cranberry" variant="dark">
                                    {plusMembershipShortBranding}
                                </Tag>
                            </p>
                        )}
                    </div>
                    {!freeShippingWithMembership && cartShippingFee <= 0 && (
                        <div className="flex items-baseline gap-5">
                            <p className="text-body-sm line-through">${baseShippingFee?.toFixed(2)}</p>
                            <p className="text-body-sm text-brand-primary font-grotesk-bold">FREE</p>
                        </div>
                    )}
                    {freeShippingWithMembership && (
                        <div className="flex items-baseline gap-5">
                            <p className="text-body-sm line-through">${baseShippingFee?.toFixed(2)}</p>
                            <p className="text-body-sm font-grotesk-bold text-cranberry">
                                FREE with {plusMembershipShortBranding}
                            </p>
                        </div>
                    )}
                    {!shippingDiscountWithMembership && cartShippingFee > 0 && (
                        <p className="text-body-sm">${cartShippingFee.toFixed(2)}</p>
                    )}
                    {shippingDiscountWithMembership && (
                        <div className="flex items-baseline gap-5">
                            <p className="text-body-sm line-through">${baseShippingFee.toFixed(2)}</p>
                            <p className="text-body-sm font-grotesk-bold text-cranberry">
                                ${plusMembershipShippingFee.toFixed(2)} with {plusMembershipShortBranding}
                            </p>
                        </div>
                    )}
                </div>
            )}
            {coldKeeperFee > 0 && coldPackItems.length > 0 && (
                <div className="mt-5 flex justify-between" data-testid="cold-pack-fee">
                    {waiveColdPackFee ? (
                        <>
                            <p className="text-body-sm">Cold Pack Fee</p>
                            <div className="flex items-baseline gap-5">
                                <p className="text-body-sm line-through">${coldKeeperFee.toFixed(2)}</p>
                                <p className="text-body-sm text-brand-primary font-grotesk-bold">WAIVED</p>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="flex items-baseline gap-5">
                                <p className="text-body-sm">Cold Pack Fee</p>
                                <Button className="link text-body-sm" onClick={handleClickWaiveColdPackFee}>
                                    Waive
                                </Button>
                            </div>
                            <p className="text-body-sm">${coldKeeperFee.toFixed(2)}</p>
                        </>
                    )}
                </div>
            )}
            {totalWineBottleQty >= MIN_WINE_BOTTLE_QTY_TO_SHIP && (
                <div className="mt-5 flex justify-between" data-testid="alcohol-service-fee">
                    {alcoholServiceFee <= 0 ? (
                        <>
                            <p className="text-body-sm">Alcohol Service Fee</p>
                            <div className="flex items-baseline gap-5">
                                <p className="text-body-sm line-through">${ALCOHOL_SERVICE_FEE.toFixed(2)}</p>
                                <p className="text-body-sm text-brand-primary font-grotesk-bold">WAIVED</p>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="flex items-baseline gap-5">
                                <p className="text-body-sm">Alcohol Service Fee</p>
                                <Button className="link text-body-sm" onClick={handleClickWaveAlcoholFee}>
                                    Waive
                                </Button>
                            </div>
                            <p className="text-body-sm">${alcoholServiceFee.toFixed(2)}</p>
                        </>
                    )}
                </div>
            )}
            {appliedDiscounts.map((discount, index) => (
                <div
                    className="mt-5 flex justify-between"
                    data-testid={`applied-discounts-${index}`}
                    key={discount.discountCode}
                >
                    <p className="text-body-sm text-brand-primary">{discount.discountCode}</p>
                    <p className="text-body-sm text-brand-primary">-${discount.dollarValue.toFixed(2)}</p>
                </div>
            ))}
            {alcoholDiscount > 0 && (
                <div className="mt-5 flex justify-between" data-testid="alcohol-discount">
                    <p className="text-body-sm">Alcohol Savings (-{alcoholDiscountPercentage}%)</p>
                    <p className="text-body-sm">-${alcoholDiscount.toFixed(2)}</p>
                </div>
            )}
            <div className="mt-5 flex justify-between" data-testid="estimated-tax">
                <div className="flex items-center gap-5">
                    <p className="text-body-sm">{showOperationsFee ? 'Estimated Tax & Fees' : 'Estimated Tax'}</p>
                    <button
                        aria-label="Estimated tax and fees information"
                        onClick={() => setTaxAndFeesModalOpen(true)}
                        type="button"
                    >
                        <InformationCircleIconOutline className="mb-[2px]" width={18} />
                    </button>
                </div>
                <p className="text-body-sm">
                    ${showOperationsFee ? estimatedTaxAndFees.toFixed(2) : estimatedTax.toFixed(2)}
                </p>
            </div>
            {cartAppliedCredits > 0 && groceryMinMet && (
                <div className="mt-5 flex justify-between" data-testid="credits">
                    <p className="text-body-sm text-brand-primary">Credits</p>
                    <p className="text-body-sm text-brand-primary">-${cartAppliedCredits.toFixed(2)}</p>
                </div>
            )}
            {featureCanAddDriverTips && (
                <div className="bg-malt-off-white -mx-15 mt-5  px-15 py-10">
                    <div className="flex justify-between">
                        <p className={clsx('flex', { 'text-body-xs': !tipAdded, 'text-body-sm': tipAdded })}>
                            <span className="notranslate font-grotesk-bold">
                                {generateTipCopy()} Driver Tip
                                {tipAdded && <span> (optional)</span>}
                            </span>
                            <button
                                type="button"
                                className="link ml-10"
                                onClick={() => {
                                    setEditDriverTipsModalOpen(true);
                                }}
                            >
                                {tipAdded
                                    ? 'Edit'
                                    : `Add a ${preferredTipVal === 0 ? 'one-time ' : ''}tip for your driver`}
                            </button>
                            <button type="button" onClick={() => setDriverTipsModalOpen(true)}>
                                <InformationCircleIcon width={18} className="ml-10 text-black" />
                            </button>
                        </p>
                        {tipAdded && <p className="text-body-sm">${orderTip?.toFixed(2)}</p>}
                    </div>
                    {tipAdded && (
                        <p className="text-body-xs w-[calc(100%_-_100px)] text-grey">
                            You can adjust your tip amount up to 24 hours after delivery.
                        </p>
                    )}
                </div>
            )}
            <div className="mt-10 flex justify-between" data-testid="total">
                <p className="text-body-md font-grotesk-bold">TOTAL</p>
                <p className="text-body-md font-grotesk-bold">${total.toFixed(2)}</p>
            </div>
            {plusMembershipEligibleOrder ? (
                <PlusMembershipMemberBanner />
            ) : (
                <PlusMembershipProspectBanner intersectionRef={plusMembershipStaticBannerRef} />
            )}
            {cartNonMemberSavings > 0 && !plusMembershipEligibleOrder && (
                <div className="mt-10 flex items-center" data-testid="cart-non-member-savings">
                    <CurrencyDollarIcon className="h-15 w-15" />
                    <p className="text-body-sm ml-5">
                        <span className="text-brand-primary font-grotesk-bold">${cartNonMemberSavings.toFixed(2)}</span>{' '}
                        saved
                    </p>
                </div>
            )}
            {sumCreditBalance > 0 && (
                <div className="mt-5 flex items-center" data-testid="available-credits">
                    <BanknotesIcon className="h-15 w-15" />
                    <p className="text-body-sm ml-5">
                        <span className="text-brand-primary font-grotesk-bold">${sumCreditBalance.toFixed(2)}</span>{' '}
                        credits available and automatically applied
                    </p>
                </div>
            )}
            {unmetReferralDiscount && (
                <div className="mt-5 flex items-start" data-testid="unmet-referral-discount">
                    <BanknotesIcon className="mt-[6px] h-15 w-15 shrink-0" />
                    <p className="text-body-sm ml-5">
                        <span className="text-brand-primary font-grotesk-bold">
                            ${unmetReferralDiscount.dollarDiscount?.toFixed(2)}
                        </span>{' '}
                        referral credits available to spend when your subtotal reaches{' '}
                        <span className="text-brand-primary font-grotesk-bold">
                            ${unmetReferralDiscount.thresholdDollars?.toFixed(2)}
                        </span>
                    </p>
                </div>
            )}
            {loyaltyEligible && estimatedLoyaltyPoints > 0 && (
                <div className="mt-5 flex items-center" data-testid="loyalty-points">
                    <StarIcon className="h-15 w-15" />
                    <p className="text-body-sm ml-5">
                        <span className="text-brand-primary font-grotesk-bold">{estimatedLoyaltyPoints}</span> points
                        earned
                    </p>
                </div>
            )}
            <DriverTipsInfoModal open={driverTipsModalOpen} setOpen={setDriverTipsModalOpen} />
            <TaxAndFeesModal
                estimatedTax={{
                    display: true,
                    value: estimatedTax,
                }}
                handleClose={() => setTaxAndFeesModalOpen(false)}
                open={taxAndFeesModalOpen}
                operationsFee={{
                    display: showOperationsFee,
                    value: operationsFee,
                }}
            />
            {featureCanAddDriverTips && (
                <EditDriverTipModal
                    chargeID={chargeID}
                    driverTipsModal={editDriverTipsModalOpen}
                    orderTotal={total + orderTip}
                    orderSubtotal={subtotal}
                    closeEditDriverTipsModal={() => {
                        setEditDriverTipsModalOpen(false);
                    }}
                />
            )}
        </div>
    );
}

export { CartPricesPlusMembership };

CartPricesPlusMembership.propTypes = {
    account: PropTypes.shape({
        loyaltyEligible: PropTypes.bool,
        nextOrder: PropTypes.shape({
            cartExperience: PropTypes.shape({
                coldKeeperFee: PropTypes.number,
                freeShippingEligible: PropTypes.bool,
                freeShippingThreshold: PropTypes.number,
            }),
        }),
        proteinMinimum: PropTypes.number,
    }).isRequired,
    coldPackSectionRef: PropTypes.shape({
        current: PropTypes.instanceOf(Element),
    }),
    credits: PropTypes.shape({
        sumCreditBalance: PropTypes.number,
    }).isRequired,
    discounts: PropTypes.shape({}).isRequired,
    plusMembershipEligibleOrder: PropTypes.bool,
    plusMembershipShippingFee: PropTypes.number,
    plusMembershipShortBranding: PropTypes.string,
    selections: PropTypes.shape({
        estimatedLoyaltyPoints: PropTypes.number,
        estimatedTax: PropTypes.number,
        selected: PropTypes.arrayOf(PropTypes.shape({})),
        selectedBundles: PropTypes.arrayOf(PropTypes.shape({})),
        shipping: PropTypes.number,
    }).isRequired,
    wineOrderCardRef: PropTypes.shape({
        current: PropTypes.instanceOf(Element),
    }),
};

CartPricesPlusMembership.defaultProps = {
    coldPackSectionRef: undefined,
    plusMembershipEligibleOrder: false,
    plusMembershipShippingFee: undefined,
    plusMembershipShortBranding: undefined,
    wineOrderCardRef: undefined,
};
