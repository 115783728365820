import PropTypes from 'prop-types';

function NoCheckoutIcon({ width }) {
    return (
        <svg width={width} viewBox="0 0 61 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5262_65672)">
                <path
                    d="M4.0669 44.6558C10.3786 62.474 28.976 67.6688 43.3916 62.5346C57.8072 57.4004 64.4219 47.7034 58.1102 29.8593C51.7985 12.0151 27.9976 -2.39182 13.5821 2.73372C-0.833529 7.85926 -2.24478 26.803 4.0669 44.6558Z"
                    className="fill-mango if:fill-[#FFCBDC]"
                />
                <path
                    d="M35.6689 53.0886L4.47412 51.7033L6.63862 20.5085L37.8334 21.8938L35.6689 53.0886Z"
                    fill="white"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M47.1235 55.4522L35.6689 53.0886L37.8334 21.8938L49.288 24.2488L47.1235 55.4522Z"
                    className="fill-[#F2D79A] if:fill-oat"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.47412 51.7034V52.4739L20.1451 54.6384L47.1235 56.1969V55.4523L35.6689 53.0887L4.47412 51.7034Z"
                    fill="#2D2D2D"
                />
                <path
                    d="M36.4998 14.1537L21.0539 43.9805C20.9332 44.2777 20.7324 44.5354 20.4738 44.7251C19.8158 45.1407 19.002 44.5779 18.4305 44.0498L10.0669 36.3182C10.0669 36.3182 10.7855 32.9676 11.7985 33.2879C12.8115 33.6082 18.5085 39.5996 18.8375 38.7251C19.1665 37.8507 31.7465 11.1234 32.9327 11.236C34.1188 11.3485 36.4998 14.1537 36.4998 14.1537Z"
                    className="fill-[#B0C372] if:fill-[#C5DB66]"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17.1755 12.0584C16.4272 11.3896 15.584 10.8352 14.6733 10.4133L17.1755 12.0584Z"
                    className="fill-[#F2D79A] if:fill-oat"
                />
                <path
                    d="M17.1755 12.0584C16.4272 11.3896 15.584 10.8352 14.6733 10.4133"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M26.5518 5.16671C26.184 3.81038 25.6762 2.49595 25.0366 1.24463L26.5518 5.16671Z"
                    className="fill-[#F2D79A] if:fill-oat"
                />
                <path
                    d="M26.5518 5.16671C26.184 3.81038 25.6762 2.49595 25.0366 1.24463"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M37.8335 7.77273C38.8819 6.19624 40.0089 4.6734 41.2101 3.20996L37.8335 7.77273Z"
                    className="fill-[#F2D79A] if:fill-oat"
                />
                <path
                    d="M37.8335 7.77273C38.8819 6.19624 40.0089 4.6734 41.2101 3.20996"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M45.0283 16.7511C46.3579 15.98 47.7619 15.3447 49.2188 14.855L45.0283 16.7511Z"
                    className="fill-[#F2D79A] if:fill-oat"
                />
                <path
                    d="M45.0283 16.7511C46.3579 15.98 47.7619 15.3447 49.2188 14.855"
                    stroke="#2D2D2D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_5262_65672">
                    <rect width="60" height="63.8095" fill="white" transform="translate(0.5 0.595215)" />
                </clipPath>
            </defs>
        </svg>
    );
}

NoCheckoutIcon.propTypes = {
    width: PropTypes.number,
};

NoCheckoutIcon.defaultProps = {
    width: 61,
};

export default NoCheckoutIcon;
