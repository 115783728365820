import { useQuery } from '@tanstack/react-query';

import { getSmsNotifications } from 'api/jalapeno';

import useAccountQuery from './useAccountQuery';

export const SMS_NOTIFICATIONS_QUERY_KEY = 'smsNotifications';

const useSmsNotificationsQuery = () => {
    const { data: account } = useAccountQuery();

    const query = useQuery({
        queryKey: [SMS_NOTIFICATIONS_QUERY_KEY],
        queryFn: async () => {
            const response = await getSmsNotifications(account?.customerID);
            return response?.data?.smsSubscriptions;
        },
        enabled: !!account?.customerID,
    });

    return query;
};

export default useSmsNotificationsQuery;
