import PropTypes from 'prop-types';

export function HourglassIcon({ width }) {
    return (
        <svg width={width} height={width} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="25" cy="25" r="25" fill="url(#paint0_linear_2206_125315)" />
            <path
                d="M30.6875 39.7622C31.6845 39.7421 34.0632 39.7772 35 39.7522V37.0159C29.5265 36.9282 23.6616 37.2339 18.2752 37.1888C17.4321 37.1813 16.1908 37.1637 15.3645 37.289C14.6519 37.3968 15.2039 39.905 15.2039 39.905C15.9633 40.0829 20.1119 39.9601 21.2461 39.9401C25.0769 39.8674 26.8601 39.8323 30.6908 39.7597L30.6875 39.7622Z"
                fill="white"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.4667 36.8107C15.544 34.0144 16.4066 30.3944 18.7468 28.3228C19.8392 27.3577 21.1861 26.6436 22.5188 25.9472C23.399 25.4877 24.3429 25.0175 25.3681 25.021C26.5701 25.0246 27.6306 25.675 28.5992 26.3007C29.7906 27.0679 30.9925 27.8456 31.9505 28.8319C33.9337 30.8787 34.4463 33.9578 33.6297 36.542L16.4702 36.8107H16.4667Z"
                fill="white"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M33.5177 13.5001L16.3582 13.2314C15.8315 14.8293 15.888 16.6959 16.4713 18.3927C16.9062 19.6618 17.6379 20.832 18.6384 21.7193C19.7307 22.6844 21.0776 23.3985 22.4104 24.0949C23.2906 24.5545 24.2345 25.0246 25.2597 25.0211C26.4616 25.0176 27.5222 24.3671 28.4908 23.7414C29.6821 22.9743 30.8841 22.1965 31.8421 21.2102C32.4784 20.5562 32.9592 19.7926 33.295 18.976C34.0127 17.2403 34.0728 15.2571 33.5177 13.5001Z"
                fill="white"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26.5294 20.3483C25.3699 20.1821 24.1114 19.316 22.4039 19.468C21.6227 19.5352 20.1909 20.0973 19.498 20.3977C20.5727 21.1649 23.0473 22.388 23.7261 22.7415C24.175 22.9749 24.6523 23.2152 25.1755 23.2117C25.787 23.2117 26.3244 22.8794 26.8158 22.5612C27.4203 22.1724 30.2448 20.7159 30.4181 20.3023C29.4919 20.3765 27.4486 20.4791 26.5294 20.3447V20.3483Z"
                fill="#2D2D2D"
                stroke="#2D2D2D"
                strokeWidth="0.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M33.4544 36.1747C32.7863 33.6683 31.252 33.1097 29.7461 31.2502C28.4487 29.6488 27.1796 28.4398 25.2812 28.56C23.7081 28.6625 22.4248 29.9847 21.2794 31.2326C19.5578 33.1132 17.5 33 16.7227 36.6236L33.4544 36.1711V36.1747Z"
                fill="#2D2D2D"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M25.875 29.7373C26.8825 30.0343 27.7804 30.5999 28.4168 31.3458"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M30.688 11.2424C31.6849 11.2624 34.0633 11.2274 35 11.2524V13.9841C29.5273 14.0717 23.6631 13.7665 18.2773 13.8115C17.4344 13.819 16.1933 13.8365 15.367 13.7115C14.6512 13.5989 15.2031 11.0948 15.2031 11.0948C15.9625 10.9172 20.1105 11.0398 21.2445 11.0598C25.0748 11.1324 26.8578 11.1674 30.688 11.2399V11.2424Z"
                fill="white"
                stroke="#2D2D2D"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_2206_125315"
                    x1="2.66033"
                    y1="50.5204"
                    x2="47.714"
                    y2="2.61253"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.185" stopColor="#A13051" />
                    <stop offset="1" stopColor="#6784AC" />
                </linearGradient>
            </defs>
        </svg>
    );
}

HourglassIcon.propTypes = {
    width: PropTypes.number,
};

HourglassIcon.defaultProps = {
    width: 50,
};
