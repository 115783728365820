import { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import useAccountQuery from 'api/queries/useAccountQuery';
import useValidDeliveryDaysQuery from 'api/queries/useValidDeliveryDaysNoSubQuery';
import { CARRIER_SHIP_FEE_REDUCTION } from 'constants/plusMembership';
import {
    PlusBenefitsSaveFoodIcon,
    PlusBenefitsShippingIcon,
    PlusBenefitsWeeklyDealsIcon,
} from 'honeydew/icons/PlusMembership';
import useGlobalStore from 'hooks/useGlobalStore';
import { getSignupLocalStorage } from 'utils/signupUtils';

const fontSizes = { xs: 'text-body-xs', sm: 'text-body-sm', md: 'text-body-md' };

function BenefitItem({ fontSize, icon, orientation, subtitle, title }) {
    const size = fontSizes[fontSize];

    return (
        <div className={clsx('flex gap-15', { 'flex-col items-center': orientation === 'horizontal' })}>
            <div className="mt-5">{icon}</div>
            <div className={clsx('flex flex-col justify-center', size)}>
                <h6 className={clsx('font-grotesk-bold', { 'text-center': orientation === 'horizontal' })}>{title}</h6>
                <p className={clsx({ 'text-center': orientation === 'horizontal' })}>{subtitle}</p>
            </div>
        </div>
    );
}

BenefitItem.propTypes = {
    fontSize: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    orientation: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

const listOrientations = { horizontal: 'flex-row', vertical: 'flex-col' };

export function BenefitsList({ fontSize, includeAsterisks, orientation }) {
    const { data: account = {} } = useAccountQuery();
    const { isMisfitsTheme } = useGlobalStore();
    const signupLocalStorage = getSignupLocalStorage();
    const deliveryDaysQuery = useValidDeliveryDaysQuery({
        extendedQueryKeys: ['signup'],
        funnelType: isMisfitsTheme ? 'misfits' : 'imperfect',
        zip: signupLocalStorage?.zip,
        orderInterval: 1,
    });
    const deliveryDays = deliveryDaysQuery?.data?.availableDays;
    const onSignupPage = window.location.pathname.includes('join');
    const [isDBI, setIsDBI] = useState(
        (onSignupPage && deliveryDays ? deliveryDays[0]?.isDeliveredByImperfect : account?.isDeliveredByImperfect) ??
            false
    );
    const listOrientation = listOrientations[orientation];

    const shippingPerkTitle = isDBI ? `Shipping's on us` : 'Save on shipping';
    const shippingPerkSubtitle = isDBI
        ? `Get free shipping on every order`
        : `Get $${CARRIER_SHIP_FEE_REDUCTION} off shipping with every order`;

    useEffect(() => {
        if (onSignupPage && deliveryDays) {
            setIsDBI(deliveryDays[0]?.isDeliveredByImperfect);
        } else {
            setIsDBI(account?.isDeliveredByImperfect);
        }
    }, [deliveryDays, signupLocalStorage?.zip]);

    return (
        <div className={clsx('flex gap-20', listOrientation)}>
            <BenefitItem
                title="Exclusive weekly deals"
                subtitle={`Save up to 50%${includeAsterisks ? '**' : ''} with big, members-only discounts`}
                icon={<PlusBenefitsWeeklyDealsIcon width={50} />}
                fontSize={fontSize}
                orientation={orientation}
            />
            <BenefitItem
                title={shippingPerkTitle}
                subtitle={shippingPerkSubtitle}
                icon={<PlusBenefitsShippingIcon width={50} />}
                fontSize={fontSize}
                orientation={orientation}
            />
            <BenefitItem
                title="Save food, fight hunger"
                subtitle="Help people facing hunger in your area with every order"
                icon={<PlusBenefitsSaveFoodIcon width={50} />}
                fontSize={fontSize}
                orientation={orientation}
            />
        </div>
    );
}

BenefitsList.propTypes = {
    fontSize: PropTypes.string,
    includeAsterisks: PropTypes.bool,
    orientation: PropTypes.string,
};

BenefitsList.defaultProps = {
    fontSize: 'sm',
    includeAsterisks: false,
    orientation: 'vertical',
};
