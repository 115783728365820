import PropTypes from 'prop-types';

function OrdersIcon({ width }) {
    return (
        <svg width={width} className="inline-block" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.6876 6.77225L1.59229 5.4585H12.2802L13.3755 6.77225H2.6876Z"
                className="fill-[#E1A654] if:fill-beet"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.3754 6.77222H2.6875V17.4601H13.3754V6.77222Z"
                className="fill-mango if:fill-[#E95CA2]"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.298 6.77222H13.3723V17.4601H17.298V6.77222Z"
                className="fill-[#E1A654] if:fill-beet"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.6118 9.3966H14.6861L13.3755 6.77222H17.298L18.6118 9.3966Z"
                fill="#2D2D2D"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinejoin="round"
            />
            <path
                d="M10.6419 3.33633C10.6419 2.62172 10.5295 1.04272 10.5295 1.04272"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.3242 4.04815C14.6019 3.67369 15.1886 2.82178 15.1886 2.82178"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.4041 3.51758C6.18254 3.10255 5.64893 2.18823 5.64893 2.18823"
                stroke="#2D2D2D"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default OrdersIcon;

OrdersIcon.propTypes = {
    width: PropTypes.number,
};

OrdersIcon.defaultProps = {
    width: 20,
};
