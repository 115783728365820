import { Auth } from 'aws-amplify';

import { getIsLocalDev, getIsProduction } from 'utils';
import logError from 'utils/errorUtils';

const defaultNonProdBackend = 'review-master';
const apiBackend = import.meta.env.VITE_REACT_APP_BACKEND || defaultNonProdBackend;
const isNativeApp = window.isNativeApp && window.appTokens;

let baseURL;

if (getIsProduction()) {
    baseURL = 'https://api.misfitsmarket.com';
} else if (getIsLocalDev()) {
    baseURL = `https://${apiBackend}-dev.misfitsmarket.com`;
} else {
    baseURL = `https://${apiBackend}-dev.misfitsmarket.io`;
}

const defaultOptions = {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
    },
};

class FetchError extends Error {
    constructor(message, statusCode) {
        super(message);
        this.name = 'FetchError';
        this.statusCode = statusCode;
    }
}

const signoutUser = async () => {
    await Auth.signOut({ global: isNativeApp });
    localStorage.removeItem('URL_REDIRECT');
};

export const apiFetchWithResponse = async (
    path,
    method = 'GET',
    data = {},
    fetchOptions = {},
    parseOptions = { parseNonOkResponse: false }
) => {
    let options = { ...defaultOptions, ...fetchOptions };
    const isProtectedRoute =
        window.location.pathname.startsWith('/shop') ||
        window.location.pathname.startsWith('/account') ||
        window.location.pathname.startsWith('/cart');

    try {
        const session = await Auth.currentSession();
        const idToken = session?.getIdToken()?.getJwtToken();
        if (idToken) {
            options.headers['boysenberry-id-token'] = idToken;
        }
    } catch (error) {
        // Only log the error or signoutUser if it's a protected route, otherwise it is noise
        if (isProtectedRoute) {
            logError(error);

            const errorMessage = error.toString();
            console.error(
                `API Fetch Error - Fetch error: problem getting current authenticated session. ${errorMessage}`
            );
            const cognitoAuthError = 'NotAuthorizedException: Refresh Token has different Client';

            if (errorMessage.includes(cognitoAuthError)) {
                await signoutUser();
            }
        }
    }

    if (method !== 'GET') {
        options = {
            ...options,
            method,
            body: JSON.stringify(data),
        };
    }

    let apiResponse;

    try {
        const response = await fetch(`${baseURL}/${path}`, options);

        if (response.ok || parseOptions.parseNonOkResponse) {
            try {
                const json = await response.json();
                apiResponse = json;
            } catch (jsonParseError) {
                throw new FetchError(
                    `apiFetchWithResponse() JSON parse error: ${jsonParseError.message}`,
                    response.status
                );
            }
        }

        if (!response.ok) {
            throw new FetchError('apiFetchWithResponse() Error fetching request', response.status);
        }
    } catch (error) {
        if (error instanceof FetchError) {
            if (error.statusCode === 403) {
                console.error(`API Fetch Error - Forbidden error. ${error.message}`);
                // Do not sign users out for failed order or order tracking requests.
                if (!path.includes('api/order/v4') && !path.includes('tracking')) {
                    await signoutUser();
                }
            } else {
                console.error(error.message);
            }
        } else {
            console.error(`API Fetch Error - Error. Contact engineering. ${error.toString()}`);
        }
    }

    return apiResponse;
};

// ========================================================================================
// api/customer
// ========================================================================================
/**
 * Get customer account.
 */
export const getAccount = () => apiFetchWithResponse('api/customer/v2/account');

/**
 * Get list of past customer orders.
 * @param {string} customerID - Customer ID of the user.
 */
export const getOrders = (customerID, page = 0, pageSize = 20) =>
    apiFetchWithResponse(`api/customer/v3/${customerID}/orders?page=${page}&pageSize=${pageSize}`);

/**
 * Get tracking details for a given orderID.
 * @param {string} orderID - Order ID to track.
 */
export const getTracking = (orderID) => apiFetchWithResponse(`api/customer/v1/${orderID}/tracking`);

/**
 * Report order feedback issue.
 * @param {string} customerID - Customer ID of the user.
 * @param {string} orderID - Order ID of order to submit feedback for.
 * @param {Object} sendParams - The params to send to the server.
 * @param {string} sendParams.automatedRefundType - [Optional] Refund type (ex. ["MM_CREDIT" or "ORIGINAL_PAYMENT_METHOD"])
 * @param {Object[]} sendParams.items - Array of items with issues.
 * @param {string} sendParams.issue - Issue type (ex. ["ITEM_ISSUE", "DELIVERY_ISSUE", "OTHER"]).
 * @param {number} sendParams.orderID - ID of order customer is submitting feedback for.
 * @param {string} sendParams.refundTypeSelection - Refund type, "regular" for non-automated refunds or one of ["MM_CREDIT", "ORIGINAL_PAYMENT_METHOD"] for automated.
 * @param {number} sendParams.shipmentID - ID of shipment customer is submitting feedback for.
 * @param {string} sendParams.source - Device where the submission came from (currently hardcoded to 'WEBSITE').
 */
export const reportIssue = (customerID, orderID, sendParams) =>
    apiFetchWithResponse(`api/customer/v1/${customerID}/customerOrderFeedback/${orderID}`, 'POST', sendParams);

/**
 * Report drinks order feedback issue.
 * @param {string} customerID - Customer ID of the user.
 * @param {string} drinksOrderID - Drinks order ID of order to submit feedback for.
 * @param {Object} sendParams - The params to send to the server.
 * @param {Object[]} sendParams.items - Array of items with issues.
 * @param {string} sendParams.issue - Issue type (ex. ["ITEM_ISSUE", "DELIVERY_ISSUE", "OTHER"]).
 * @param {number} sendParams.orderID - ID of order customer is submitting feedback for.
 * @param {string} sendParams.refundTypeSelection - Refund type - hardcoded to "regular" since wine items are not currently eligible for automated refunds.
 * @param {string} sendParams.source - Device where the submission came from (currently hardcoded to 'WEBSITE').
 */
export const reportWineIssue = (customerID, drinksOrderID, sendParams) =>
    apiFetchWithResponse(
        `api/customer/v1/${customerID}/customerDrinksOrderFeedback/${drinksOrderID}`,
        'POST',
        sendParams
    );

/**
 * Get notifications preferences for a user.
 * @param {string} customerID - Customer ID of the user.
 */
export const getNotifications = (customerID) => apiFetchWithResponse(`api/customer/v1/${customerID}/notifications`);

/**
 * Get SMS notifications preferences for a user.
 * @param {string} customerID - Customer ID of the user.
 * @returns {Object}
 */
export const getSmsNotifications = (customerID) => apiFetchWithResponse(`api/customer/v1/${customerID}/sms`);

/**
 * Update email notifications preferences for a user.
 * @param {string} customerID - Customer ID of the user.
 * @param {Object} sendParams - The params to send to the server.
 * @param {number} sendParams.notifyServiceID - ID of email notification type the user wishes to update
 * @param {boolean} sendParams.optOutFlag - Opt in our out from email notification type
 * @param {boolean} sendParams.unsubscribeAll - True to unsubscribe from all email notifications
 */
export const updateNotifications = (customerID, sendParams) =>
    apiFetchWithResponse(`api/customer/v1/${customerID}/notifications`, 'POST', sendParams);

/**
 * Update sms notifications preferences for a user.
 * @param {string} customerID - Customer ID of the user.
 * @param {Object} sendParams - The params to send to the server.
 * @param {boolean} sendParams.smsTransactional - Opt in our out from transactional text notifications
 * @param {boolean} sendParams.smsMarketing - Opt in our out from marketing text notifications
 */
export const updateSmsNotifications = (customerID, sendParams) =>
    apiFetchWithResponse(`api/customer/v1/${customerID}/sms`, 'PUT', sendParams);

/**
 * Update birthday for a user.
 * @param {string} customerID - Customer ID of the user.
 * @param {string} birthday - Birthday for the user.
 */
export const updateBirthday = (customerID, birthday) =>
    apiFetchWithResponse(`api/customer/v1/${customerID}/dob`, 'PUT', birthday);

/**
 * Refer a new user.
 * @param {string} customerID - Customer ID of the user.
 * @param {Object} sendParams - The params to send to the server.
 * @param {string} sendParams.email - Email of person to be referred.
 */
export const referFriend = (customerID, sendParams) =>
    apiFetchWithResponse(`api/customer/v1/${customerID}/referFriend`, 'POST', sendParams);

/**
 * Get whether or not a customer with the supplied email already exists.
 * @param {Object} sendParams - The params to send to the server.
 * @param {string} sendParams.email - The email to check.
 */
export const getCustomerExists = ({ sendParams }) => apiFetchWithResponse(`api/customer/v1/exists`, 'POST', sendParams);

/**
 * Create a customer lead capture.
 * @param {Object} sendParams - The params to send to the server.
 * @param {string} sendParams.email - The email of the customer.
 * @param {string} sendParams.experiement - The experiment active for the customer [API TODO]: update spelling here.
 * @param {string} sendParams.funnelType - The funnel type. (ex. "imperfect")
 * @param {string} sendParams.source - The source of the lead capture.
 * @param {string} sendParams.zip - The zip code of the customer.
 */
export const leadCapture = ({ sendParams }) =>
    apiFetchWithResponse(`api/customer/v1/create`, 'POST', sendParams, { credentials: 'include' });

// ========================================================================================
// api/subscription
// ========================================================================================
/**
 * Get subscription data for customer.
 * @param {string} customerID - Customer ID of the user.
 */
export const getSubscription = (customerID) => apiFetchWithResponse(`api/subscription/v2/${customerID}`);

/**
 * Update address for a user.
 * @param {string} customerID - Customer ID of the user.
 * @param {Object} sendParams - The params to send to the server.
 * @param {string} sendParams.address1 - The users street name and residence number.
 * @param {string} sendParams.address2 - Apt, suite, etc. (this field is optional or data here can be added to address1)
 * @param {string} sendParams.city - The users city.
 * @param {string} sendParams.zip - The users zip code.
 * @param {string} sendParams.province - The users state/province.
 * @param {string} sendParams.deliveryInstructions - The delivery instructions a user has added (optional).
 * @param {string} sendParams.firstName - The first name of the user.
 * @param {string} sendParams.lastName - The last name of the user
 * @param {string} sendParams.country - The country a user lives in (currently hardcoded to "US").
 * @param {string} sendParams.phone - The phone number of a user.
 * @param {boolean} sendParams.smsTransactional - Current opt in/out preferences for transactional text notifications
 * @param {boolean} sendParams.smsMarketing - Current opt in/out preferences for marketing text notifications
 * @param {string} sendParams.phoneType - [API TODO]: think we can remove this from the payload? (this is an old field we don't user anymore left over from an old integration from when we would try to differentiate home vs. mobile).
 * @param {string} sendParams.internalDriverNotes - [API TODO]: think we can remove this from the payload - looks like there is a spread that is adding all fields form the shippingAddr object on subscription but we don't need to pass all of them
 */
export const updateAddress = (subscriptionID, sendParams) =>
    apiFetchWithResponse(`api/subscription/v1/${subscriptionID}/address`, 'POST', sendParams);

/**
 * Get the valid delivery days for a user.
 * @param {string} subscriptionID - Subscription ID of the user.
 * @param {string} deliveryDay - Preferred delivery day (optional)
 * @param {string} deliveryInterval - Current delivery interval (optional)
 * @param {string} zip - The users zip code.
 */
export const getValidDeliveryDays = (subscriptionID, zip, deliveryDay = '', deliveryInterval = '') =>
    apiFetchWithResponse(
        `api/subscription/v1/${subscriptionID}/validDays?zip=${zip}&deliveryDay=${deliveryDay}&orderInterval=${deliveryInterval}`
    );

/**
 * Update delivery day and/or order interval for a user.
 * @param {string} subscriptionID - Subscription ID of the user.
 * @param {Object} sendParams - The params to send to the server.
 * @param {string} sendParams.prefDeliveryDay - New preferred delivery day.
 * @param {string} sendParams.orderInterval - [Optional] New preferred order unit interval (1 = weekly, 3 = biweekly even weeks, 4 = biweekly odd weeks).
 */
export const changeDeliveryInformation = (subscriptionID, sendParams) =>
    apiFetchWithResponse(`api/subscription/v1/${subscriptionID}`, 'PUT', sendParams);

/**
 * Get client secret for existing customer (for updating billing info).
 * @param {string} customerID - Customer ID of the user.
 * @param {Object} sendParams - The params to send to the server.
 * @param {string} sendParams.email - Email of user.
 */
export const getClientSecretForExistingCustomer = (subscriptionID, sendParams) =>
    apiFetchWithResponse(`api/subscription/v4/${subscriptionID}/billing/secret`, 'POST', sendParams);

/**
 * Gets suggested tip options for user by subscription ID.
 * @param {Number} subscriptionID - Subscription ID for user.
 */
export const getTipSuggestions = (subscriptionID) =>
    apiFetchWithResponse(`api/subscription/v1/${subscriptionID}/tips/suggestions`);

/**
 * Updates users default tip preferences.
 * @param {Object} sendParams - The params to send to the server.
 * @param {Number} sendParams.tipAmount - Users desired default tip value.
 * @param {string} sendParams.tipType - The type of tip to be added, can be "DOLLARS" or "PERCENT".
 */
export const updateDefaultTipPreferences = (subscriptionID, sendParams) =>
    apiFetchWithResponse(`api/subscription/v1/${subscriptionID}/tips/preferences`, 'POST', sendParams);

/**
 * Get a secret for a prospective [non-existing] customer.
 * @param {Object} sendParams - The params to send to the server.
 * @param {string} sendParams.email - The email of the prospective customer.
 */
export const getClientSecret = ({ sendParams }) =>
    apiFetchWithResponse(`api/subscription/v4/secret`, 'POST', sendParams);

/**
 * Check the checkout form needs to be blocked for a particular user/email.
 * @param {Object} sendParams - The params to send to the server.
 * @param {string} sendParams.email - Email of user.
 * @param {string} sendParams.errorMessage - Error message returned by the stripe.confirmSetup endpoint if available.
 */
export const updateCheckoutBlock = (sendParams) =>
    apiFetchWithResponse(`api/subscription/v4/checkoutBlock`, 'POST', sendParams);

/**
 * Cancel billing update request
 * @param {string} subscriptionID - Subscription ID of the user.
 * @param {Object} sendParams - The params to send to the server.
 * @param {string} sendParams.stripeSetupIntentID - ID of stripe SetupIntent // Docs: https://stripe.com/docs/api/setup_intents.
 */
export const cancelBillingEdit = (subscriptionID, sendParams) =>
    apiFetchWithResponse(`api/subscription/v4/${subscriptionID}/billing/cancelBillingEdit`, 'POST', sendParams);

/**
 * Update user payment info
 * @param {string} subscriptionID - Subscription ID of the user.
 * @param {Object} sendParams - The params to send to the server.
 * @param {string} sendParams.stripeSetupIntentID - ID of stripe SetupIntent // Docs: https://stripe.com/docs/api/setup_intents.
 * @param {string} sendParams.paymentMethod - New payment method.
 */
export const updatePaymentInfo = (subscriptionID, sendParams) =>
    apiFetchWithResponse(`api/subscription/v4/${subscriptionID}/billing`, 'PUT', sendParams);

/**
 * Get plan data for a user
 * @param {string} subscriptionID - Subscription ID of the user.
 */
export const getPlanData = (subscriptionID) => apiFetchWithResponse(`api/subscription/v1/${subscriptionID}/autoship`);

/**
 * Change plan for a user (PlansV1)
 * @param {string} subscriptionID - Subscription ID of the user.
 * @param {Object} sendParams - The params to send to the server.
 * @param {number} sendParams.planID - New plan ID (ex. [1, 2])
 */
export const changePlan = (subscriptionID, sendParams) =>
    apiFetchWithResponse(`api/subscription/v1/${subscriptionID}/changePlan`, 'PUT', sendParams);

/**
 * Upgrade plan (membership -> autoship) for a user
 * @param {string} subscriptionID - Subscription ID of the user.
 * @param {Object} sendParams - The params to send to the server.
 * @param {string} sendParams.planType - New plan type.
 */
export const upgradePlan = (subscriptionID, sendParams) =>
    apiFetchWithResponse(`api/subscription/v1/${subscriptionID}/autoship`, 'PUT', sendParams);

/**
 * Downgrade plan (autoship -> membership) for a user
 * @param {string} subscriptionID - Subscription ID of the user.
 * @param {Object} sendParams - The params to send to the server.
 * @param {string} sendParams.reason - Downgrade reason.
 * @param {string} sendParams.secondaryReason - Secondary downgrade reason.
 * @param {string} sendParams.details - Response from "Other" input if added
 */
export const downgradePlan = (subscriptionID, sendParams) =>
    apiFetchWithResponse(`api/subscription/v1/${subscriptionID}/membership`, 'PUT', sendParams);

/**
 * Resume paused or canceled subscription and upgrade plan for a user (membership -> autoship)
 * @param {string} subscriptionID - Subscription ID of the user.
 * @param {Object} sendParams - The params to send to the server.
 * @param {string} sendParams.planType - New plan type.
 */
export const reactivateSub = (subscriptionID, sendParams = {}) =>
    apiFetchWithResponse(`api/subscription/v1/${subscriptionID}/reactivate`, 'POST', sendParams);

/**
 * Resume paused or canceled subscription
 * @param {string} subscriptionID - Subscription ID of the user.
 */
export const resumeSubscription = (subscriptionID) =>
    apiFetchWithResponse(`api/subscription/v1/${subscriptionID}/resume`, 'PUT');

/**
 * Pause subscription
 * @param {string} subscriptionID - Subscription ID of the user.
 * @param {Object} sendParams - The params to send to the server.
 * @param {string} sendParams.months - Month for pause plan
 */
export const pauseSub = (subscriptionID, sendParams) =>
    apiFetchWithResponse(`api/subscription/v1/${subscriptionID}/pause`, 'PUT', sendParams);

/**
 * Cancel subscription
 * @param {string} subscriptionID - Subscription ID of the user.
 */
export const cancelSub = (subscriptionID) =>
    apiFetchWithResponse(`api/subscription/v1/${subscriptionID}/cancel`, 'POST');

/**
 * Get impact stats for a user
 * @param {string} subscriptionID - Subscription ID of the user.
 */
export const getImpactStats = (subscriptionID) =>
    apiFetchWithResponse(`api/subscription/v1/${subscriptionID}/impactStats`);

/**
 * Get the valid delivery days for a zip code.
 * @param {string} deliveryDay - [deliveryDay = 'monday'] Preferred delivery day, should default to Monday if no day is passed
 * @param {number} deliveryInterval - [deliveryInterval = 1] - Preferred order unit interval (1 = weekly)
 * @param {string} funnelType - [Optional] The funnel type. (ex. "imperfect")
 * @param {string} zip - The zip code for the delivery days.
 */
export const getValidDeliveryDaysNoSub = ({ deliveryDay = 'monday', deliveryInterval = 1, funnelType, zip }) =>
    apiFetchWithResponse(
        `api/subscription/v1/validDays?zip=${zip}&deliveryDay=${deliveryDay}&orderInterval=${deliveryInterval}&funnelType=${funnelType}`
    );

/**
 * Create a new subscription for a user.
 * NOTE: This docstring needs cleaning up; I'm unsure of which params are actually
 *       needed, and which can be optional. This was pulled directly from Mango's signup flow.
 * @param {Object} sendParams - The params to send to the server.
 * @param {string} sendParams.address1 - The first delivery address line.
 * @param {string} sendParams.address2 - The second delivery address line. (ex. Apt, unit)
 * @param {string} [sendParams.bucket] - When provided alongside channel and platform, a "How did you hear about us?" record will be created.
 * @param {string} [sendParams.channel] - When provided alongside bucket and platform, a "How did you hear about us?" record will be created.
 * @param {string} sendParams.city - The user's delivery address city.
 * @param {string} [sendParams.comments] - Optional user-input comment for "How did you hear about us?" record.
 * @param {string} [sendParams.content_type] -  Optional param for "How did you hear about us?" record, accompanying comments.
 * @param {string} sendParams.country - The user's delivery address country.
 * @param {string} sendParams.deliveryInstructions - The user's delivery instructions.
 * @param {string} sendParams.discountCode - The applied discount code.
 * @param {string} sendParams.email - The user's email.
 * @param {string} sendParams.experiment - The active signup experiment.
 * @param {string} sendParams.firstName - The user's first name.
 * @param {string} sendParams.funnelType - The funnel type. (ex. "imperfect")
 * @param {string} sendParams.giftCode - The applied gift code.
 * @param {string} sendParams.lastName - The user's last name.
 * @param {string} sendParams.numItems - [numItems = null], this is left over from legacy product types from a few years ago. [API TODO]: possibly remove
 * @param {string} sendParams.paymentMethod - The user's payment method. (ex. "card")
 * @param {string} sendParams.phone - The user's phone number.
 * @param {Object} sendParams.planPreferences - The user's plan preferences
 * @param {string} sendParams.planPreferences.planSize - The user's plan size (ex. "1-2")
 * @param {Object[]} sendParams.planPreferences.plans - The user's plans (ex. [{ id: 3 }, { id: 10 }])
 * @param {string} [sendParams.platform] - When provided alongside bucket and channel, a "How did you hear about us?" record will be created.
 * @param {string} sendParams.prefDeliveryDay - The preferred delivery day.
 * @param {string} sendParams.productID - [productID = 6], this is left over from legacy product types from a few years ago. [API TODO]: possibly remove
 * @param {string} sendParams.province - The user's delivery address state abbreviation. (ex. CA)
 * @param {Object} sendParams.quizResults - The user's preference quiz results.
 * @param {string} sendParams.quizResults.dietaryPreference - The user's dietary preference. (ex. "vegetarian")
 * @param {Object} sendParams.quizResults.household - The user's household members. (ex. { adults: 2, kids: 0, pets: 1 })
 * @param {number[]} sendParams.quizResults.plans - The user's plan ids. (ex. [3, 10])
 * @param {number} sendParams.quizResults.percentOrganic - The user's selected produce type. (0-100)
 * @param {string} sendParams.smsMarketing - Does the user agree to marketing SMS messages?
 * @param {string} sendParams.smsTransactional - Does the user agree to transactional SMS messages?
 * @param {string} sendParams.stripeSetupIntentID - Stripe setup intent ID relating to payment.
 * @param {string} sendParams.subInterval - The subscription's interval. (ex. 1)
 * @param {string} sendParams.variant - Delivery variant. (ex. "autoship")
 * @param {string} sendParams.zip - The user's delivery address zip code.
 */
export const subscribe = ({ sendParams }) =>
    apiFetchWithResponse('api/subscription/v4', 'POST', sendParams, { credentials: 'include' });

// ========================================================================================
// api/charge
// ========================================================================================
/**
 * Updates users tip amount for a specific charge.
 * @param {Number} chargeID - ID of the charge to be updated.
 * @param {Object} sendParams - The params to send to the server.
 * @param {Number} sendParams.tipAmount - Users desired default tip value.
 * @param {string} sendParams.tipType - The type of tip to be added, can be "DOLLARS" or "PERCENT".
 */
export const updateOrderTip = (chargeID, sendParams) =>
    apiFetchWithResponse(`api/charge/v1/${chargeID}/tip`, 'POST', sendParams, {}, { parseNonOkResponse: true });

/**
 * Skip upcoming charge.
 * @param {string} chargeID - ID of charge to skip.
 * @param {Object} sendParams - The params to send to the server.
 * @param {string} sendParams.reason - Reason for skipping. [API TODO]: currently this is a required parameter but always defaults to an empty string, there is a separate endpoint for submitting skip reasons.
 */
export const skipCharge = (chargeID, sendParams) =>
    apiFetchWithResponse(`api/charge/v2/${chargeID}/skip`, 'PUT', sendParams);

/**
 * Un-skip upcoming charge.
 * @param {string} chargeID - ID of charge to un-skip.
 */
export const unSkipCharge = (chargeID) => apiFetchWithResponse(`api/charge/v1/${chargeID}/unskip`);

/**
 * Reason for skipping upcoming charge.
 * @param {string} chargeID - ID of charge skipped.
 * @param {Object} sendParams - The params to send to the server.
 * @param {string} sendParams.reason - Reason for skipping.
 */
export const skipChargeReason = (chargeID, sendParams) =>
    apiFetchWithResponse(`api/charge/v2/${chargeID}/skipReason`, 'PUT', sendParams);

/**
 * Donate upcoming charge.
 * @param {string} chargeID - ID of charge to donate.
 */
export const donateCharge = (chargeID) => apiFetchWithResponse(`api/charge/v2/${chargeID}/donate`, 'PUT');

/**
 * Un-donate upcoming charge.
 * @param {string} chargeID - ID of charge to un-donate.
 */
export const unDonateCharge = (chargeID) => apiFetchWithResponse(`api/charge/v2/${chargeID}/undonate`, 'PUT');

/** Cancel upcoming charge.
 * @param {string} chargeID - ID of charge to cancel.
 * @param {Object} sendParams - The params to send to the server.
 * @param {string} sendParams.reason - Reason for cancelling.
 */
export const cancelCharge = (chargeID, sendParams) =>
    apiFetchWithResponse(`api/charge/v3/${chargeID}/refund/cancel`, 'POST', sendParams);

// api/checkout
// ========================================================================================
/**
 * Get options for the "How did you hear about us?" select field.
 */
export const getHowDidYouHearAboutUsOptions = () => apiFetchWithResponse(`api/checkout/v1/howDidYouHearAboutUs`);

// ========================================================================================
// api/order
// ========================================================================================
/**
 * Get data for an order.
 * @param {string} orderID - ID of order to view data for.
 */
export const getOrder = (orderID) => apiFetchWithResponse(`api/order/v4/${orderID}`);

// ========================================================================================
// api/credits
// ========================================================================================
/**
 * Get available credits for a user
 * @param {string} subscriptionID - Subscription ID of the user.
 */
export const getCredits = (subscriptionID) => apiFetchWithResponse(`api/credits/v1/${subscriptionID}/balance`);

// ========================================================================================
// api/discounts
// ========================================================================================
/**
 * Get available discounts for a user
 * @param {string} subscriptionID - Subscription ID of the user.
 */
export const getDiscounts = (subscriptionID) => apiFetchWithResponse(`api/discounts/v1/${subscriptionID}/ladder`);

/**
 * Check if a discount code is valid, optionally for a given customer.
 * @param {string} customerID - [Optional] The id of the customer.
 * @param {string} discountCode - The discount code.
 */
export const checkDiscountCode = (customerID, discountCode) =>
    apiFetchWithResponse(
        `api/discounts/v1/validate/check?code=${discountCode}${customerID ? `&custID=${customerID}` : ''}`
    );

/**
 * Claims discount code for a given customer.
 * @param {string} subscriptionID - Subscription ID of the user.
 * @param {string} discountCode - The discount code.
 */
export const claimDiscountCode = (subscriptionID, discountCode) =>
    apiFetchWithResponse(`api/discounts/v1/${subscriptionID}?code=${discountCode}`, 'POST', {});

// ========================================================================================
// api/marketing
// ========================================================================================
/**
 * Get referrer and referee credits.
 */
export const getReferralCredits = () => apiFetchWithResponse(`api/marketing/v1/referrals/credit`);

// ========================================================================================
// api/giftCodes
// ========================================================================================
/**
 * Claims gift card code for a given customer.
 * @param {string} subscriptionID - Subscription ID of the user.
 * @param {string} code - The gift code.
 */
export const claimGiftCard = (subscriptionID, code) =>
    apiFetchWithResponse(`api/giftCodes/v1/claim?subID=${subscriptionID}&code=${code}`, 'POST');

/**
 * Check if a gift card code is valid.
 * @param {string} giftCardCode - The gift card giftCardCode to check.
 */
export const checkGiftCard = ({ giftCardCode }) =>
    apiFetchWithResponse(`api/giftCodes/v1/valid/checkout?code=${giftCardCode}`);

// ========================================================================================
// api/loyalty
// ========================================================================================
/**
 * Gets items available to purchase with points.
 * @param {string} chargeID - Charge ID for current active charge.
 * @param {boolean} recommended - [Optional] If true, returns only top 4 recommended shop with points items.
 */
export const getShopWithPointsItems = (chargeID, recommended) =>
    apiFetchWithResponse(`api/loyalty/v2/gifts/${chargeID}${recommended ? '?onlyRecommended=true' : ''}`);

// ========================================================================================
// api/marketplace
// ========================================================================================
/**
 * Get food preferences for V1 user
 * @param {string} subscriptionID - Subscription ID of the user.
 */
export const getPreferences = (subscriptionID) =>
    apiFetchWithResponse(`api/marketplace/v1/autoship/preferences/${subscriptionID}`);

/**
 * Get food preferences for V2 user
 * @param {string} subscriptionID - Subscription ID of the user.
 */
export const getV2Preferences = (subscriptionID) =>
    apiFetchWithResponse(`api/marketplace/v2/autoship/preferences/${subscriptionID}`);

/**
 * Set food preference for an item
 * @param {string} subscriptionID - Subscription ID of the user.
 * @param {Object} sendParams - The params to send to the server.
 * @param {string} sendParams.itemID - Item ID of preference to update.
 * @param {string} sendParams.preferenceType - Preference list to add item to (ex. ["LIKE", "DISLIKE", "RECURRING"]).
 */
export const setV2Preference = (subscriptionID, sendParams) =>
    apiFetchWithResponse(`api/marketplace/v2/autoship/preferences/${subscriptionID}`, 'POST', sendParams);

/**
 * Remove food preference for an item
 * @param {string} subscriptionID - Subscription ID of the user.
 * @param {Object} sendParams - The params to send to the server.
 * @param {string} sendParams.itemID - Item ID of preference to remove.
 */
export const removePreference = (subscriptionID, sendParams) =>
    apiFetchWithResponse(`api/marketplace/v1/autoship/preferences/${subscriptionID}`, 'PUT', sendParams);

/**
 * Get food preference for an item
 * @param {string} subscriptionID - Subscription ID of the user.
 * @param {string} sellableItemID - The internal product ID.
 */
export const getIndividualItemPreferences = (subscriptionID, sellableItemID) =>
    apiFetchWithResponse(`api/marketplace/v2/autoship/preferences/${subscriptionID}/item/${sellableItemID}`);

/**
 * Get dynamic plan ranges based on signup quiz parameters
 * @param {string} cookingFrequency - User response to cooking frequency
 * @param {string} householdSize - User response to household size
 */
export const getDynamicPlanRange = ({ cookingFrequency, householdSize }) =>
    apiFetchWithResponse(
        `api/marketplace/v1/autoship/plans/range?cookingFrequency=${cookingFrequency}&householdSize=${householdSize}`
    );

// ========================================================================================
// api/marketplace
// ========================================================================================
/**
 * Gets list of selected items (items in cart) for a user for the current active charge.
 * @param {string} chargeID - Charge ID for current active charge.
 */
export const getSelections = (chargeID) => apiFetchWithResponse(`api/marketplace/v1/${chargeID}/selections`);

/**
 * Gets list of all available aisles a user.
 * @param {string} chargeID - Charge ID for current active charge.
 */
export const getAisles = (chargeID) => apiFetchWithResponse(`api/marketplace/v1/${chargeID}/aisles`);

/**
 * Gets data for a single aisle.
 * @param {string} chargeID - Charge ID for current active charge.
 * @param {string} aisleID - [Optional] Aisle ID for aisle to view, defaults to null which returns filters for all available items.
 */
export const getAisle = (chargeID, aisleID) =>
    apiFetchWithResponse(`api/marketplace/v1/${chargeID}/aisles/items?id=${aisleID}`);

/**
 * Gets list of all available products for the charge.
 * @param {string} chargeID - Charge ID for current active charge.
 */
export const getProducts = (chargeID) => apiFetchWithResponse(`api/marketplace/v1/${chargeID}/aisles/items`);

/**
 * Gets data for a single item.
 * @param {string} chargeID - Charge ID for current active charge.
 * @param {string} sellableItemID - ID for item to view.
 */
export const getProductItem = (chargeID, sellableItemID) =>
    apiFetchWithResponse(`api/marketplace/v1/${chargeID}/item/${sellableItemID}`);

/**
 * Gets list of all available carousels for the charge.
 * @param {string} chargeID - Charge ID for current active charge.
 */
export const getCarousels = (chargeID) => apiFetchWithResponse(`api/marketplace/v1/${chargeID}/carousels`);

/**
 * Gets list of available V2 plan options (both plan sizes and available packs).
 */
export const getAutoshipPlans = () => apiFetchWithResponse('api/marketplace/v1/autoship/plans');

/**
 * Change autoship V2 plan information (plans size and/or available packs)
 * @param {string} subscriptionID - Subscription ID of the user.
 * @param {Object} sendParams - The params to send to the server.
 * @param {string} sendParams.maxBoxRange - Minimum box amount for new plan.
 * @param {string} sendParams.minBoxRange - Maximum box amount for new plan.
 * @param {string} sendParams.planSize - New plan size.
 * @param {string} sendParams.householdSize - New household size.
 * @param {number} sendParams.cookingFrequency - New cooking frequency.
 * @param {string} sendParams.dietaryPreference - New main dietary preference.
 * @param {number} sendParams.percentOrganic - New organic percentage.
 * @param {boolean} sendParams.kosher - New kosher preference.
 * @param {boolean} sendParams.glutenFree - New gluten free preference.
 * @param {boolean} sendParams.nonGMO- New non GMO preference.
 * @param {Object[]} sendParams.plans - Pack in the users plan.
 */
export const changeAutoshipPlans = (subscriptionID, sendParams) =>
    apiFetchWithResponse(`api/marketplace/v1/autoship/plans/${subscriptionID}`, 'PUT', sendParams);

/**
 * Gets list of available IF legacy plan options (both plan sizes and available packs).
 */
export const getImperfectLegacyPlans = () => apiFetchWithResponse('api/marketplace/v1/autoship/if/plans');

/**
 * Change IF legacy plan information (plans size and/or available packs)
 * @param {string} subscriptionID - Subscription ID of the user.
 * @param {Object} sendParams - The params to send to the server.
 * @param {string} sendParams.basePlanID - ID for new plan.
 * @param {string} sendParams.basePlanSize - Base plan size for the new plan.
 * @param {number[]} sendParams.addOnPlanIDs - IDs for all packs in the users plan.
 */
export const changeImperfectLegacyPlans = (subscriptionID, sendParams) =>
    apiFetchWithResponse(`api/marketplace/v1/autoship/if/plans/${subscriptionID}`, 'PUT', sendParams);

/**
 * Gets filters for a single aisle.
 * @param {string} chargeID - Charge ID for current active charge.
 * @param {string} aisleID - [Optional] Aisle ID for aisle to view, defaults to null which returns filters for all available items.
 */
export const getFilters = (chargeID, aisleID) =>
    apiFetchWithResponse(`api/marketplace/v1/${chargeID}/aisles/filters?id=${aisleID}`);

/**
 * Search list of products in the marketplace.
 * @param {string} chargeID - Charge ID for current active charge.
 * @param {string} query - Query for items to search for.
 */
export const searchStorefront = (chargeID, query) =>
    apiFetchWithResponse(`api/marketplace/v1/${chargeID}/search?query=${query}`);

/**
 * Gets list of recommended items for current charge
 * @param {string} chargeID - Charge ID for current active charge.
 */
export const getStorefrontPicks = (chargeID) => apiFetchWithResponse(`api/marketplace/v1/${chargeID}/storefront-picks`);

/**
 * Clears selection quantity from a user's order. Previously named `removeAllItems`.
 * @param {string} chargeID - The user's charge ID.
 * @param {Object} sendParams - The params to send to the server.
 * @param {Object[]} sendParams.selection - The list of selections to update.
 * @param {string} sendParams.selection.id - The id of a selection to update.
 * @param {number} sendParams.selection.qty - The amount to remove from a selection's qty.
 */
export const clearSelectionQuantity = async ({ chargeID, sendParams }) =>
    apiFetchWithResponse(`api/marketplace/v1/${chargeID}/clear`, 'POST', sendParams);

/**
 * Get a specified bundle item.
 * @param {string} bundleID - The ID of the bundle to get.
 * @param {string} chargeID - The user's charge ID.
 */
export const getBundleItem = ({ bundleID, chargeID }) =>
    apiFetchWithResponse(`api/marketplace/v1/${chargeID}/bundle/${bundleID}/items`);

/**
 * Increment the `qty` of an item inside of a user's selections.
 * @param {string} chargeID - The user's charge ID.
 * @param {Object} sendParams - The params to send to the server.
 * @param {string} sendParams.sellableItemID - The ID of the item to increment.
 */
export const incrementItemSelectionQty = ({ chargeID, sendParams }) =>
    apiFetchWithResponse(`api/marketplace/v2/${chargeID}`, 'POST', sendParams);

/**
 * Decrement the `qty` of an item inside of a user's selections.
 * @param {string} chargeID - The user's charge ID.
 * @param {Object} sendParams - The params to send to the server.
 * @param {string} sendParams.sellableItemID - The ID of the item to decrement.
 */
export const decrementItemSelectionQty = ({ chargeID, sendParams }) =>
    apiFetchWithResponse(`api/marketplace/v2/${chargeID}`, 'PUT', sendParams);

/**
 * Increment the `qty` of a bundle inside of a user's selections.
 * @param {string} chargeID - The user's charge ID.
 * @param {Object} sendParams - The params to send to the server.
 * @param {string} sendParams.sellableItemID - The ID of the bundle to increment.
 */
export const incrementBundleSelectionQty = ({ chargeID, sendParams }) =>
    apiFetchWithResponse(`api/marketplace/v2/${chargeID}/bundle`, 'POST', sendParams);

/**
 * Decrement the `qty` of a bundle inside of a user's selections.
 * @param {string} chargeID - The user's charge ID.
 * @param {Object} sendParams - The params to send to the server.
 * @param {string} sendParams.sellableItemID - The ID of the bundle to decrement.
 */
export const decrementBundleSelectionQty = ({ chargeID, sendParams }) =>
    apiFetchWithResponse(`api/marketplace/v2/${chargeID}/bundle`, 'PUT', sendParams);

/**
 * Get all previously purchased items for a specified customer.
 * @param {string} chargeID - The ID of the latest charge of the customer to get previously purchased items for.
 */
export const getPreviouslyPurchased = ({ chargeID }) =>
    apiFetchWithResponse(`api/marketplace/v1/${chargeID}/previouslypurchased`);

/**
 * Send users quiz preferences
 * @param {Object} sendParams - The params to send to the server.
 * @param {string} sendParams.funnelType - Funnel type ('misfits', 'imperfect').
 * @param {Object} sendParams.quizResults - Users quiz results.
 * @param {string} sendParams.quizResults.dietaryPreference - Users dietary preferences (ex: "vegan")
 * @param {Object} sendParams.quizResults.household - Household size (ex. { adults: 2, kids: 1, pets: 0})
 * @param {number} sendParams.quizResults.percentOrganic - Percentage of organic (ex. 50).
 */
export const postQuiz = (sendParams) => apiFetchWithResponse('api/subscription/v4/quiz', 'POST', sendParams);

/**
 * Get product data for individual items. This is currently used outside the shopping experience, for Acquisition PDPs.
 * @param {string} slug - The product's slug, for example: alaska-wild-caught-sockeye-salmon-12-oz
 */
export const getCatalogItem = ({ slug }) => apiFetchWithResponse(`api/catalog/v1/item/${slug}`);

/**
 * Get cart upsells carousel
 * @param {string} chargeID - The chargeID of the current active order
 * @param {string} count - Number of items to return (default to 10)
 * @param {string} type - Type of carousel ['reach_order_minimum', 'reach_free_shipping_threshold', 'top_sellers', 'empty_cart']
 */

export const getCartUpSellsCarousel = ({ chargeID, count = 10, type }) =>
    apiFetchWithResponse(`api/marketplace/v2/${chargeID}/cart-upsells/${type}/${count}`);

/**
 * Get PLP upsells carousel
 * @param {string} chargeID - The chargeID of the current active order
 * @param {string} count - Number of items to return (default to 10)
 * @param {string} type - Type of carousel ['reach_order_minimum', 'reach_free_shipping_threshold', 'top_sellers', 'empty_cart']
 */
export const getPLPUpSellsCarousel = ({ chargeID, count = 10, sellableItemID }) =>
    apiFetchWithResponse(`api/marketplace/v2/${chargeID}/plp-upsells/${sellableItemID}/${count}`);

/**
 * Get PDP upsells carousel
 * @param {string} chargeID - The chargeID of the current active order
 * @param {string} count - Number of items to return (default to 10)
 * @param {string} type - Type of carousel ['reach_order_minimum', 'reach_free_shipping_threshold', 'top_sellers', 'empty_cart']
 */
export const getPDPUpSellsCarousel = ({ chargeID, count = 10, sellableItemID }) =>
    apiFetchWithResponse(`api/marketplace/v2/${chargeID}/pdp-upsells/${sellableItemID}/${count}`);

/**
 * Get the plus membership options available to the customer
 * @param {string} customerID - Customer ID of the user.
 */
export const getMembershipOptions = ({ customerID }) => apiFetchWithResponse(`api/plus/v1/${customerID}/options`);

/**
 * Subscribe the customer to a plus membership
 * @param {string} customerID - Customer ID of the user.
 * @param {number} sendParams.plusMembershipVariantID - The membership variant to sign the customer up for
 * @param {string} sendParams.typeOfMembership - `FREE_TRIAL` or `PAID`
 */
export const activatePlusMembership = ({ customerID, sendParams }) =>
    apiFetchWithResponse(`api/plus/v1/${customerID}/purchase`, 'POST', sendParams);

/**
 * Enable plus membership for an account
 * @param {string} customerID - Customer ID of the user.
 */
export const enablePlusMembershipFeatureFlag = ({ customerID }) =>
    apiFetchWithResponse(`api/plus/v1/${customerID}/assign`, 'PUT', { assigned: 1 });

/**
 * Cancel a customers trial membership
 * @param {string} customerID - Customer ID of the user.
 */
export const cancelPlusMembershipTrial = ({ customerID, reason, secondaryReason }) =>
    apiFetchWithResponse(`api/plus/v1/${customerID}/cancelTrial`, 'POST', { reason, secondary: secondaryReason });

/**
 * Cancel a customers membership auto renewal
 * @param {string} customerID - Customer ID of the user.
 * @param {bool} autoRenew - Bool, true if the customer should auto renew.
 */
export const changePlusMembershipAutoRenew = ({ autoRenew, customerID, reason, secondaryReason }) =>
    apiFetchWithResponse(`api/plus/v1/${customerID}/autorenew`, 'POST', {
        autoRenewalFlag: autoRenew ? 1 : 0,
        reason,
        secondary: secondaryReason,
    });

/**
 * Get all plus member aisle products.
 * @param {string} chargeID - The ID of the latest charge of the customer to get previously purchased items for.
 */
export const getPlusMemberAisleProducts = ({ chargeID }) =>
    apiFetchWithResponse(`api/marketplace/v1/${chargeID}/plus-member-aisle`);

/**
 * Seed customer box with curated list of items (should meet the order min and should reflect their preferences).
 * @param {string} chargeID - nextOrder.chargeID from account data.
 */
export const seedBox = (chargeID) =>
    apiFetchWithResponse(`api/marketplace/v2/${chargeID}/seed`, 'POST', null, null, {
        parseNonOkResponse: true,
    });

// ========================================================================================
// api/supplierportal
// ========================================================================================

export const authorizeSupplierPortal = ({ sendParams }) =>
    apiFetchWithResponse('api/supplierportal/v1/authorize', 'POST', sendParams);
