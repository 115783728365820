import PropTypes from 'prop-types';

import Modal from 'honeydew/Modal';

export function SignupPromoTermsModal({ setShowPromoTermsModal, showPromoTermsModal, termsAndConditions }) {
    return (
        <Modal
            open={showPromoTermsModal}
            onClose={() => setShowPromoTermsModal(false)}
            headline="Terms & Conditions"
            centered
        >
            <p>{termsAndConditions}</p>
        </Modal>
    );
}

SignupPromoTermsModal.propTypes = {
    setShowPromoTermsModal: PropTypes.func.isRequired,
    showPromoTermsModal: PropTypes.bool.isRequired,
    termsAndConditions: PropTypes.node,
};

SignupPromoTermsModal.defaultProps = {
    termsAndConditions: undefined,
};
