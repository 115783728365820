import PropTypes from 'prop-types';

import { LoginErrors } from 'constants/login';
import Button from 'honeydew/Button';
import TextField from 'honeydew/TextField';

function ForgotPasswordForm({ email, loading, resetSubmit, setEmail, setError }) {
    return (
        <form onSubmit={resetSubmit}>
            <p className="text-body-sm mb-20">Enter your email to reset your password.</p>

            <div className="mb-20">
                <TextField
                    id="email-field"
                    name="email"
                    label="Email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                        setError(LoginErrors.NoError);
                    }}
                    required
                />
            </div>

            <div className="mb-25 mt-10">
                <Button size="md" type="submit" disabled={loading}>
                    Reset
                </Button>
            </div>
        </form>
    );
}

ForgotPasswordForm.propTypes = {
    email: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    resetSubmit: PropTypes.func.isRequired,
    setEmail: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
};

export default ForgotPasswordForm;
