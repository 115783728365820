import { useMutation, useQueryClient } from '@tanstack/react-query';

import { clearSelectionQuantity } from 'api/jalapeno';
import useAccountQuery from 'api/queries/useAccountQuery';
import useSelectionsQuery, { SELECTIONS_QUERY_KEY } from 'api/queries/useSelectionsQuery';
import logError from 'utils/errorUtils';
import { getOrderSessionStorage, setOrderSessionStorage } from 'utils/orderSessionStorageUtils';

/**
 * Optimistic updates for clearing all selections from the user's order.
 */
export default function useClearAllSelections() {
    const queryClient = useQueryClient();

    const { data: { nextOrder, pendingOrder } = {} } = useAccountQuery();
    const { data: selections = { selected: [], selectedBundles: [] } } = useSelectionsQuery();

    const chargeID = pendingOrder?.status === 'SCHEDULED' ? pendingOrder?.chargeID : nextOrder?.chargeID;
    const queryKey = [SELECTIONS_QUERY_KEY, chargeID];

    const selectedData = selections?.selected?.reduce(
        (acc, selection) => [...acc, { id: selection.id, qty: selection.qty }],
        []
    );

    const selectedBundlesData = selections?.selectedBundles?.reduce(
        (acc, selection) => [...acc, { bundleID: selection.bundleID, qty: selection.qty }],
        []
    );

    const selectionData = [...selectedData, ...selectedBundlesData];

    const clearSelectionsMutation = useMutation({
        mutationFn: clearSelectionQuantity,
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey });

            const prevSelections = queryClient.getQueryData(queryKey);

            queryClient.setQueryData(queryKey, (data) => ({
                ...data,
                selected: [],
                selectedBundles: [],
            }));

            const orderSessionsStorage = getOrderSessionStorage();
            const prevHideCuratedCartBanner = orderSessionsStorage.hideCuratedCartBanner;

            if (!prevHideCuratedCartBanner) {
                setOrderSessionStorage({ hideCuratedCartBanner: true });
            }

            return { prevHideCuratedCartBanner, prevSelections };
        },
        onError: (err, _, context) => {
            queryClient.setQueryData(queryKey, context.prevSelections);
            setOrderSessionStorage({ hideCuratedCartBanner: context.prevHideCuratedCartBanner });
            logError(err);
            console.error(err);
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey });
        },
    });

    const clearAllSelections = () =>
        clearSelectionsMutation.mutate({
            chargeID,
            sendParams: { selection: selectionData },
        });

    return { clearAllSelections };
}
