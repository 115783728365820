import React from 'react';

import CO2ESavedIcon from '../Account/CO2ESavedIcon';
import { AllPreferencesIcon, PescatarianIcon } from '../DietaryPreferences';

import BackToSchool from './BackToSchool/BackToSchool';
import IFBuyItAgain from './BuyItAgain/IFBuyItAgain';
import MMBuyItAgain from './BuyItAgain/MMBuyItAgain';
import IFColdPack from './ColdPack/IFColdPack';
import MMColdPack from './ColdPack/MMColdPack';
import IFDiscoverSomethingNew from './DiscoverSomethingNew/IFDiscoverSomethingNew';
import MMDiscoverSomethingNew from './DiscoverSomethingNew/MMDiscoverSomethingNew';
import IFFallFlavors from './FallFlavors/IFFallFlavors';
import MMFallFlavors from './FallFlavors/MMFallFlavors';
import IFHolidayMustHaves from './HolidayMustHaves/IFHolidayMustHaves';
import MMHolidayMustHaves from './HolidayMustHaves/MMHolidayMustHaves';
import { Bakery } from './Icons/Bakery';
import { Beverages } from './Icons/Beverages';
import { Dairy } from './Icons/Dairy';
import { Deli } from './Icons/Deli';
import { Featured } from './Icons/Featured';
import { Fruit } from './Icons/Fruit';
import { GlutenFree } from './Icons/GlutenFree';
import { Household } from './Icons/Household';
import { MeatAndSeafood } from './Icons/MeatAndSeafood';
import { OurBrands } from './Icons/OurBrands';
import { Pantry } from './Icons/Pantry';
import { Pet } from './Icons/Pet';
import { PlantBased } from './Icons/PlantBased';
import { PreparedFoods } from './Icons/PreparedFoods';
import { Rescued } from './Icons/Rescued';
import { Sale } from './Icons/Sale';
import { ShopAll } from './Icons/ShopAll';
import { ShopBy } from './Icons/ShopBy';
import { Snacks } from './Icons/Snacks';
import { StaffPicks } from './Icons/StaffPicks';
import { Vegetables } from './Icons/Vegetables';
import { VitaminsAndSupplements } from './Icons/VitaminsAndSupplements';
import { Wine } from './Icons/Wine';
import { MemberDeals } from './MemberDeals/MemberDeals';
import IFProduce from './Produce/IFProduce';
import MMProduce from './Produce/MMProduce';
import IFSpiceUpYourRoutine from './SpiceUpYourRoutine/IFSpiceUpYourRoutine';
import MMSpiceUpYourRoutine from './SpiceUpYourRoutine/MMSpiceUpYourRoutine';
import IFThanksgivingEssentials from './ThanksgivingEssentials/IFThanksgivingEssentials';
import MMThanksgivingEssentials from './ThanksgivingEssentials/MMThanksgivingEssentials';

const AisleIcons = {
    Bakery,
    Beverages,
    Dairy,
    Deli,
    Featured,
    Fruit,
    GlutenFree,
    Household,
    MeatAndSeafood,
    OurBrands,
    Pantry,
    Pet,
    PlantBased,
    PreparedFoods,
    Rescued,
    Sale,
    ShopAll,
    ShopBy,
    Snacks,
    StaffPicks,
    Vegetables,
    VitaminsAndSupplements,
    Wine,
    IFColdPack,
    MMColdPack,
    IFProduce,
    MMProduce,
    IFBuyItAgain,
    MMBuyItAgain,
    MemberDeals,
    BackToSchool,
    IFSpiceUpYourRoutine,
    MMSpiceUpYourRoutine,
    IFFallFlavors,
    MMFallFlavors,
    IFThanksgivingEssentials,
    MMThanksgivingEssentials,
    IFHolidayMustHaves,
    MMHolidayMustHaves,
    PescatarianIcon,
    CO2ESavedIcon,
    AllPreferencesIcon,
    MMDiscoverSomethingNew,
    IFDiscoverSomethingNew,
};

export function AisleIcon({ aisleIconName, ...props }) {
    const AisleIconName = AisleIcons[aisleIconName];
    return <AisleIconName {...props} />;
}
