import { useEffect, useRef } from 'react';
import { Outlet, useLocation, useMatch, useMatches, useSearchParams } from 'react-router-dom';

import SEO from 'shared/SEO';
import { trackPage } from 'utils/analyticsUtils';
import { capitalize } from 'utils/globalUtils';

function AnalyticsLayout() {
    const location = useLocation();
    const [queryParams] = useSearchParams();
    const trackedRef = useRef('');
    const matches = useMatches();
    const routeMetadata = matches?.pop()?.handle?.meta;
    const domain = window.location.hostname;
    let routePageTitle = routeMetadata?.pageTitle;

    const isPDPRoute = !!useMatch('/shop/product/:id');

    /**
     * Page view tracking here is opt-out (if your route is nested in this layout).
     * You can opt-out of page view tracking here by adding your route to the list below.
     * Useful for pages view events that require page-level data and therefore implement their own page view tracking.
     */
    const trackPageOptOutRoutes = [isPDPRoute];

    const fireTrackPage = !trackPageOptOutRoutes.some((route) => route);

    if (routeMetadata?.pageTitle === 'Aisle') {
        const pageTitle = matches?.pop().params.category;
        const capitalizedRoutePageTitle = pageTitle
            .split('+')
            .map((word) => capitalize(word))
            .join(' ');
        routePageTitle = capitalizedRoutePageTitle;
    }

    // track page event on URL change
    useEffect(() => {
        // NOTE: event properties sent with trackPage() are not the same as useTrackPageView().
        const eventProperties = {
            ...location,
            queryParams: Object.fromEntries(queryParams),
            isNative: !!window.isNativeApp,
            domain,
        };

        if (routeMetadata?.pageTitle === 'Aisle') {
            eventProperties.aisle = routePageTitle;
        }

        if (trackedRef.current === location.pathname) return;

        if (fireTrackPage) {
            trackPage(eventProperties, routeMetadata?.pageTitle || undefined);
        }

        trackedRef.current = location.pathname;
    }, [location, queryParams]);

    return (
        <>
            {routePageTitle && <SEO title={routePageTitle} description={routeMetadata?.pageDescription} />}
            <Outlet />
        </>
    );
}

export default AnalyticsLayout;
