// TODO: move to boysenberry s3
export const PLACEHOLDER_IMAGE = 'https://img-cdn.misfitsmarket.com/images/placeholder.png';

export const AISLE_PATH_PREFIX = '/shop/aisle';

export const FEATURED_AISLE = {
    id: 'featured',
    name: 'Featured',
    url: '/shop',
};

export const BUY_IT_AGAIN_AISLE = {
    id: 'buy-it-again',
    name: 'Buy It Again',
    url: '/shop/aisle/buy-it-again',
};

export const MEMBER_DEALS_AISLE = {
    id: 'member-deals',
    name: 'Member Deals',
    url: '/shop/aisle/member-deals',
};

export const SHOP_WITH_POINTS_AISLE = {
    id: 'shop-with-points',
    name: 'Shop with Points',
    url: '/shop/browse/misfits+perks',
};

export const SHOP_ALL_AISLE = {
    id: 'all-items',
    name: 'Shop All',
    url: `${AISLE_PATH_PREFIX}/all`,
};

export const WINE_AISLE_NAME = 'Wine Shop';
export const FRUIT_AISLE_NAME = 'Fruit';
export const VEGETABLE_AISLE_NAME = 'Vegetables';

export const ALCOHOL_SERVICE_FEE = 8.99;
export const MIN_WINE_BOTTLE_QTY_TO_SHIP = 3;
export const MIN_WINE_BOTTLE_QTY_TO_WAIVE_FEE = 6;
export const MIN_WINE_BOTTLE_QTY_FOR_PERCENT_DISCOUNT_TIER_1 = 9;
export const MIN_WINE_BOTTLE_QTY_FOR_PERCENT_DISCOUNT_TIER_2 = 12;
export const WINE_PERCENT_DISCOUNT_TIER_1 = 10;
export const WINE_PERCENT_DISCOUNT_TIER_2 = 15;

export const MIN_WINE_BOTTLE_QTY_TO_SHIP_COPY = 'three';
export const MIN_WINE_BOTTLE_QTY_TO_WAIVE_FEE_COPY = 'six';
export const MIN_WINE_BOTTLE_QTY_FOR_PERCENT_DISCOUNT_TIER_1_COPY = 'nine';

/*
    IDs for aisles containing duplicated items.
    Used to remove aisles with duplicate items from the Shop All aisle and M+ Deals page.

        - Our Brands (id: 1160)
        - Plant Based (id: 1172)
        - Gluten Free (id: 939)
        - Back to School (id: 1959)
        - Spice Up Your Routine (id: 1970)
        - Fall Flavors (id: 1971)
        - Thanksgiving Essentials (id: 1972)
        - Holiday Must Haves (id: 1973)
        - Saved & Rescued (id: 1925)
        - Black Friday Sale (id: 1655)
        - Try Something New (id: 1998)
        - Staff Picks (id: 2003)
        - Earth Friendly (id: 2013)
*/
export const DUPLICATE_ITEMS_AISLE_IDS = [1160, 1172, 939, 1959, 1970, 1971, 1972, 1973, 1925, 1655, 1998, 2003, 2013];
