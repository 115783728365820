import clsx from 'clsx';
import PropTypes from 'prop-types';
import { matchPath, NavLink, useLocation } from 'react-router-dom';

import { BUY_IT_AGAIN_AISLE, FEATURED_AISLE, MEMBER_DEALS_AISLE, SHOP_ALL_AISLE } from 'constants/shop';
import { IMPERFECT_THEME_DOMAIN, MISFITS_THEME_DOMAIN } from 'constants/theme';
import { AisleIcon } from 'honeydew/icons/Aisles';
import useGlobalStore from 'hooks/useGlobalStore';
import { getAisleIconName, isAisleActive } from 'utils/aislesUtils';
import { trackAisleNavigation } from 'utils/analyticsUtils';
import { getAislePath } from 'utils/pathUtils';

const isHardCodedAisleActive = (aisleURL, location) => {
    switch (aisleURL) {
        case '/shop': {
            const matchHomePath =
                matchPath('/shop', location.pathname) ||
                matchPath('/shop/browse/misfits+perks', location.pathname) ||
                matchPath('/shop/aisle/misfits+perks', location.pathname) ||
                matchPath(BUY_IT_AGAIN_AISLE.url, location.pathname) ||
                matchPath(MEMBER_DEALS_AISLE.url, location.pathname);
            return !!matchHomePath;
        }

        default:
            return !!matchPath(aisleURL, location.pathname);
    }
};

export function L1Navigation({ aisles }) {
    const location = useLocation();
    const { isMisfitsTheme } = useGlobalStore();
    const aislesWithHome = [FEATURED_AISLE, SHOP_ALL_AISLE].concat(aisles);
    const aisleItems = aislesWithHome.map((aisle) => {
        const aisleIconName = getAisleIconName(aisle.name, isMisfitsTheme);
        const isHardCodedAisle = !!aisle.url;
        const to = isHardCodedAisle ? aisle.url : getAislePath(aisle.name);
        // NOTE: matchPath can't be used for Aisle/subcategory pages because the aisle homepage is at /all instead of /
        const isActive = isHardCodedAisle
            ? isHardCodedAisleActive(aisle.url, location)
            : isAisleActive(aisle.name, location);
        return (
            <li key={aisle.id} className="flex">
                <NavLink
                    to={to}
                    className={clsx(
                        'text-body-sm flex flex-col items-center justify-center text-nowrap px-15 !leading-[16px]'
                    )}
                    onClick={() => {
                        trackAisleNavigation({
                            aisleName: aisle.name,
                            level: 1,
                            mobile: true,
                            uiElement: 'mobile L1',
                        });
                    }}
                >
                    <AisleIcon
                        aisleIconName={aisleIconName}
                        isActive={isActive}
                        width={40}
                        brand={isMisfitsTheme ? MISFITS_THEME_DOMAIN : IMPERFECT_THEME_DOMAIN}
                    />
                    <span className="text-body-xxs text-center">{aisle.name}</span>
                </NavLink>
            </li>
        );
    });

    return (
        <nav>
            <ul className="flex h-[70px] shrink-0 overflow-x-auto border-b border-b-off-white bg-white">
                {aisleItems}
            </ul>
        </nav>
    );
}

L1Navigation.propTypes = {
    aisles: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string.isRequired,
        })
    ),
};

L1Navigation.defaultProps = {
    aisles: [],
};
