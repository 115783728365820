import { CheckIcon } from '@heroicons/react/24/outline';

import useAccountQuery from 'api/queries/useAccountQuery';
import useSelectionsQuery from 'api/queries/useSelectionsQuery';
import Progress from 'honeydew/Progress';
import { useAccountPlusMembershipHook } from 'hooks/useAccountPlusMembershipHook';
import { filterPerkSelections, getGroceryItems, getGrocerySubTotal, getOrderMin } from 'utils/orderUtils';
import { getProductsSubtotal, PriceType } from 'utils/priceUtils';

function FreeShippingProgress() {
    const { data: account } = useAccountQuery();
    const { data: selections } = useSelectionsQuery();

    const { inPlusMembershipTreatment, plusMembershipEligibleOrder, shippingFees } = useAccountPlusMembershipHook();
    const { plusMembershipShippingFee } = shippingFees ?? {};

    if (!account || !selections) return false;

    const { freeShippingThreshold } = account.nextOrder.cartExperience ?? {};

    const filteredSelections = filterPerkSelections(selections);
    const groceryItems = getGroceryItems(filteredSelections);

    const orderMin = getOrderMin(account);

    const priceType = plusMembershipEligibleOrder ? PriceType.PLUS_MEMBER : PriceType.DEFAULT;

    const grocerySubtotal = inPlusMembershipTreatment
        ? getProductsSubtotal({
              options: { priceType },
              products: groceryItems,
          })
        : getGrocerySubTotal(account, filteredSelections);

    const freeShippingProgress = freeShippingThreshold > 0 ? (grocerySubtotal / freeShippingThreshold) * 50 + 50 : 100;

    if (plusMembershipEligibleOrder && plusMembershipShippingFee === 0) {
        return false;
    }

    return grocerySubtotal < freeShippingThreshold ? (
        <div>
            <p className="text-body-sm mt-5">
                You are{' '}
                <span className="text-brand-primary font-grotesk-bold">
                    ${(freeShippingThreshold - grocerySubtotal)?.toFixed(2)}{' '}
                </span>{' '}
                away from FREE SHIPPING
            </p>
            <Progress progress={freeShippingProgress} width="full" />
            <div className="grid grid-cols-3">
                <p className="text-brand-primary col-start-2 mt-[3px] flex gap-5 text-center text-body-mobile-sm">
                    <CheckIcon className="w-10" /> ${orderMin?.toFixed(0)} order min
                </p>
                <p className="text-body-sm text-brand-primary text-right font-grotesk-bold">
                    ${freeShippingThreshold?.toFixed(2)}
                </p>
            </div>
        </div>
    ) : (
        <p className="text-body-sm mt-5 font-grotesk-bold text-kale">You earned FREE SHIPPING!</p>
    );
}

export default FreeShippingProgress;
