import { useInfiniteQuery } from '@tanstack/react-query';

import { getOrders } from 'api/jalapeno';

import useAccountQuery from './useAccountQuery';

export const ORDERS_INFINITE_QUERY_KEY = 'infiniteOrders';

const PAGE_SIZE = 5;

const useOrdersInfiniteQuery = () => {
    const { data: account } = useAccountQuery();

    const query = useInfiniteQuery({
        queryKey: [ORDERS_INFINITE_QUERY_KEY, account?.customerID],
        initialPageParam: 0,
        queryFn: async ({ pageParam = 0 }) => {
            const response = await getOrders(account?.customerID, pageParam, PAGE_SIZE);
            return response?.data;
        },
        getNextPageParam: (lastPage) => {
            if (lastPage?.page?.nextPage && lastPage.orders.length === PAGE_SIZE) {
                return lastPage.page.currentPage + 1;
            }
            return undefined;
        },
        enabled: !!account?.customerID,
    });

    return query;
};

export default useOrdersInfiniteQuery;
