import { useReducer, useRef, useState } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

import Card from 'honeydew/Card';
import NeverIcon from 'honeydew/icons/FoodPreferences/NeverIcon';
import useUpdatePreference from 'hooks/useUpdatePreference';
import OrderBanner from 'shared/OrderBanner';
import { trackEvent } from 'utils/analyticsUtils';
import { getProductList, removeProductById } from 'utils/removedFromCartStorageUtils';

function NeverListItem({ product, refresh }) {
    const [highlighted, setHighlighted] = useState(false);
    const productRemovedTimer = useRef();
    const { addDislikedUserPreference } = useUpdatePreference({
        preferenceID: product.extSku,
    });

    return (
        <div className="flex items-center gap-5">
            <img className="h-40 w-40 rounded-lg" width={40} height={40} alt={product.name} src={product.photoSmall} />
            <div className="flex grow justify-between gap-20 align-top">
                <div className="flex flex-col">
                    {product.brand && <p className="text-body-sm text-grey">{product.brand}</p>}
                    <p className="text-body-sm line-clamp-2">{product.name}</p>
                </div>
            </div>
            <button
                className={clsx('flex items-center rounded-full border-2 p-10', {
                    'border-grey-light': !highlighted,
                    'border-color-black bg-mango': highlighted,
                })}
                type="button"
                onClick={() => {
                    addDislikedUserPreference({
                        onSuccess: () => {
                            productRemovedTimer.current = setTimeout(() => {
                                removeProductById(product.id);
                                refresh();
                            }, 5000);
                        },
                        onError: () => {
                            highlighted(false);
                        },
                    });
                    setHighlighted(true);
                    trackEvent('item preference update', {
                        new_preference: 'disliked',
                        source: 'cart',
                        path: window.location.pathname,
                        isNative: !!window.isNativeApp,
                        product_name: product.name,
                        product_id: product.id,
                    });
                }}
                disabled={highlighted}
            >
                <NeverIcon width={22} />
                <span className="ml-5 font-grotesk-bold">Never</span>
            </button>
        </div>
    );
}

export function AddToNeverListSection() {
    const productList = getProductList();
    const [, forceUpdate] = useReducer((x) => x + 1, 0);

    if (productList.length === 0) {
        return null;
    }

    return (
        <Card marginClasses="mb-10 mt-10 mx-15" extendedStyles={{ padding: 'p-20 pb-10' }}>
            <OrderBanner variant="grey" paddingClasses="px-10 py-5" marginClasses="-mx-20 -mt-30 mb-20">
                {' '}
                <TrashIcon className="w-15 stroke-[2px]" />
                PRE-FILLED ITEMS REMOVED
            </OrderBanner>
            <p className="mb-10 font-grotesk-bold">Would you like future orders to be pre-filled with these items?</p>
            {productList.map((product, index) => (
                <div key={product.id} className={clsx('py-10', { 'border-t-[1px] border-grey-light': index !== 0 })}>
                    <NeverListItem product={product} refresh={forceUpdate} />
                </div>
            ))}
        </Card>
    );
}
